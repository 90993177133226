import { Button, Grid, Switch, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { EvaluationPageButtons } from "components/shared/buttonGroups";
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { FC, useEffect, useState } from 'react';
import { generatePhase3FinalResults, getScoresForPhase3Final, selectCurrentUser, selectGrantContestPageLocalization, selectScoresPhase3Final, selectScoresPhase3FinalCount, selectScoresPhase3FinalIsLoading, selectScoresPhase3FinalSortModel, updateUserRenounced, useAppDispatch, useAppSelector } from 'store';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { PhaseNumber } from "store/models/enums/PhaseNumber";
import { UserType } from 'store/models/enums/UserType';
import { setScoresPhase3FinalSortModel } from 'store/slices/grantEvaluationSlice';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';

export const GrantRankingFinalPhase3: FC<{ stage: number }> = ({ stage }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const scoresPhase3Final = useAppSelector(selectScoresPhase3Final);
  const scoresPhase3FinalCount = useAppSelector(selectScoresPhase3FinalCount);
  const scoresPhase3FinalIsLoading = useAppSelector(selectScoresPhase3FinalIsLoading);
  const scoresPhase3FinalSortModel = useAppSelector(selectScoresPhase3FinalSortModel);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const localization = useAppSelector(selectGrantContestPageLocalization);

  const handleGetScores = (pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean, token: string | undefined) => {
    dispatch(
      getScoresForPhase3Final({
        filters: {
          pageNumber: pageNumber,
          columnToSortBy: columnToSortBy,
          sortingOrder: sortingOrder,
        },
        token: token,
      })
    );
  }

  const columns: GridColDef[] = [
    {
      field: "uniqueId", headerName: localization.phase3RankTab.seriesCol, flex: 1, minWidth: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.row.uniqueId ? params.row.uniqueId : "N/A"}>
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {params.row.uniqueId ? params.row.uniqueId : "N/A"}
          </span>
        </Tooltip>
      )
    },
    {
      field: "commentPhase2FirstGrid", headerName: localization.phase3RankTab.phase2Grid1ObsCol, sortable: false, flex: 1, minWidth: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.row.commentPhase2FirstGrid ? params.row.commentPhase2FirstGrid : "N/A"} >
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.commentPhase2FirstGrid ? params.row.commentPhase2FirstGrid : "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "commentPhase2SecondGrid", headerName: localization.phase3RankTab.phase2Grid2ObsCol, sortable: false, flex: 1, minWidth: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.row.commentPhase2SecondGrid ? params.row.commentPhase2SecondGrid : "N/A"} >
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.commentPhase2SecondGrid ? params.row.commentPhase2SecondGrid : "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "finalScorePhase2", headerName: localization.phase3RankTab.phase2ScoreCol, flex: 1, minWidth: 100,
      renderCell: (params: any) => {
        return (
          <span>{params.row.finalScorePhase2 && params.row.finalScorePhase2 > 0 ? params.row.finalScorePhase2 : "N/A"}</span>
        )
      }
    },
    {
      field: "commentPhase3FirstGrid", headerName: localization.phase3RankTab.phase3Grid1ObsCol, sortable: false, flex: 1, minWidth: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.row.commentPhase3FirstGrid ? params.row.commentPhase3FirstGrid : "N/A"} >
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.commentPhase3FirstGrid ? params.row.commentPhase3FirstGrid : "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "commentPhase3SecondGrid", headerName: localization.phase3RankTab.phase3Grid2ObsCol, sortable: false, flex: 1, minWidth: 200,
      renderCell: (params: any) => (
        <Tooltip title={params.row.commentPhase3SecondGrid ? params.row.commentPhase3SecondGrid : "N/A"} >
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.commentPhase3SecondGrid ? params.row.commentPhase3SecondGrid : "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "finalScorePhase3", headerName: localization.phase3RankTab.phase3ScoreCol, flex: 1, minWidth: 100,
      renderCell: (params: any) => {
        return (
          <span>{params.row.finalScorePhase3 && params.row.finalScorePhase3 > 0 ? params.row.finalScorePhase3 : "N/A"}</span>
        )
      }
    },
    {
      field: "score", headerName: localization.phase3RankTab.totalScoreCol, flex: 1, minWidth: 100,
      renderCell: (params: any) => {
        return (
          <span>{params.row.score && params.row.score > 0 ? params.row.score : "N/A"}</span>
        )
      }
    },
    {
      field: "", headerName: localization.phase3RankTab.optionsCol, sortable: false, align: "right", flex: 1, hide: currentUser?.userType === UserType.COMPANY_USER, minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container justifyContent={"end"}>
            {(currentUser?.userType !== UserType.COMPANY_USER && params.row.isAmongTheFirst19) ? <Tooltip title="Retrage">
              <Switch
                color="primary"
                checked={params.row.userRenounced}
                onChange={(e) => { handleRenouncedUser(params.row.userId, e) }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Tooltip> : <></>}
            <EvaluationPageButtons btnSize="small" userId={params.row.userId} phaseNumber={PhaseNumber.Phase3} stage={stage} evaluationBtnDisabled={params.row.grantEvaluationId === 0} />
          </Grid>
        )
      }
    }
  ]
  useEffect(() => {
    handleGetScores(currentPageNumber, DataGridColumnNames.CreatedAt, false, currentUser?.jwtToken);
    //eslint-disable-next-line
  }, [currentPageNumber]);

  const handleRenouncedUser = (userId: number, event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateUserRenounced({
      filter: {
        userThatRenouncedId: userId,
        userRenounced: event.target.checked

      },
      token: currentUser?.jwtToken
    })).then(() => {
      handleGetScores(currentPageNumber, DataGridColumnNames.CreatedAt, false, currentUser?.jwtToken);
    })
  }


  const handlePageChange = (pageNumber: any) => {
    setCurrentPageNumber(pageNumber);
  }

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0) {
      dispatch(setScoresPhase3FinalSortModel({
        columnToSortBy: newModel[0].field,
        sortingOrder: newModel[0].sort
      }));
      let column = mapColumnNameToEnum(newModel[0].field);
      let sort = false;
      if (newModel[0].sort === "asc") sort = true;
      handleGetScores(currentPageNumber, column, sort, currentUser?.jwtToken);

    } else {
      dispatch(setScoresPhase3FinalSortModel({
        columnToSortBy: "createdAt",
        sortingOrder: "desc"
      }));
      handleGetScores(currentPageNumber, DataGridColumnNames.CreatedAt, false, currentUser?.jwtToken);
    }
  };

  const handleGeneratePhase3Results = () => {
    dispatch(generatePhase3FinalResults({
      token: currentUser?.jwtToken,
    }))
  }

  const getRowClassName = (params: any): string => {
    if (params.row.isAmongTheFirst19) return "accepted";
    if (parseInt(params.row.score, 10) < 70 || params.row.score === "") return "rejected";
    return "substitute";
  }

  const getBorderBottomStyle = (): string => {
    var column = mapColumnNameToEnum(scoresPhase3FinalSortModel.columnToSortBy);
    if (column === DataGridColumnNames.CreatedAt) {
      return "4px solid black";
    }
    return "";
  }



  return (
    <Grid >
      {currentUser?.userType !== UserType.COMPANY_USER && <Grid container justifyContent={"flex-end"}>
        <Button variant='contained' onClick={handleGeneratePhase3Results}>
          {localization.phase3RankTab.finalResultsBtn}
        </Button>
      </Grid>}
      <Grid item paddingTop={2}>
        <DataGrid
          components={{
            LoadingOverlay: DataLoadingComponent
          }}
          hideFooter={true}
          loading={scoresPhase3FinalIsLoading}
          rows={scoresPhase3Final}
          columns={columns}
          pageSize={scoresPhase3FinalCount}
          rowsPerPageOptions={[scoresPhase3FinalCount]}
          sx={{
            flexGrow: 1, height: 500,
            "& .accepted": {
              bgcolor: "#98FB98",
              '&:hover': {
                bgcolor: "#98FB98",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#98FB98"
              },
            },
            "& .rejected": {
              bgcolor: "#CD5C5C",
              '&:hover': {
                bgcolor: "#CD5C5C",
              },
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#CD5C5C"
              },
            },
            ".MuiDataGrid-row:nth-of-type(19)": {
              borderBottom: getBorderBottomStyle()
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: "ellipsis",
              whiteSpace: "pre-line",
              lineHeight: 1.5
            }
          }}
          getRowClassName={getRowClassName}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          disableColumnMenu={true}
          onPageChange={handlePageChange}
          page={currentPageNumber}
          rowCount={scoresPhase3FinalCount}
        ></DataGrid>
      </Grid>

    </Grid>
  );
}