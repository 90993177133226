import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Switch,
  DialogContent,
  IconButton,
  DialogTitle,
  Divider,
} from "@mui/material";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { FC, useEffect, useState } from "react";
import { isNotNullOrUndefined } from "utils";

import { useAppDispatch } from "store";
import { FileUploadComponent } from "components";
import { Close } from "@mui/icons-material";
export const ManageDocumentFiles: FC<{
  requiredWarningText: any;
  requiredCondition: any;
  isRequired: boolean;
  display: boolean;
  displayMode: string;
  currentFiles: any;
  newFiles: any;
  onFileDownload: Function;
  onRemoveCurrentFile: Function;
  onCancelRemoveCurrentFile: Function;
  onRemoveNewFile: Function;
  onNewFilesSelect: Function;
  onSave: Function;
  onCancel: Function;
}> = ({
  onCancel,
  onSave,
  onFileDownload,
  currentFiles,
  newFiles,
  onNewFilesSelect,
  onRemoveCurrentFile,
  onCancelRemoveCurrentFile,
  onRemoveNewFile,
  displayMode,
  display,
  isRequired,
  requiredCondition,
  requiredWarningText,
}) => {
  const [initialCurrentFiles, setInitialCurrentFiles] =
    useState<any>(currentFiles);
  const [initialNewFiles, setInitialNewFiles] = useState<any>(newFiles);
  const [mergedInitialFiles, setMergedInitialFiles] = useState<any>([]);
  const [initialNewFilesRemoved, setInitialNewFilesRemoved] = useState<any>([]);
  const [newLocalFiles, setNewLocalFiles] = useState<any>();

  const dispatch = useAppDispatch();
  useEffect(() => {
    mergeInitialFilesList();
    return () => {
      setInitialCurrentFiles([]);
      setInitialNewFiles([]);
      setMergedInitialFiles([]);
    };
    //eslint-disable-next-line
  }, []);

  const removeInitialNewFile = (fileData: any) => {
    setInitialNewFilesRemoved([...initialNewFilesRemoved, fileData]);
  };
  const cancelRemoveInitialNewFile = (fileData: any) => {
    setInitialNewFilesRemoved(
      initialNewFilesRemoved.filter((file: any) => fileData.name !== file.name)
    );
  };
  const mergeInitialFilesList = () => {
    setMergedInitialFiles([]);
    let newMergedInitialFilesList: any[] = [];
    initialCurrentFiles.forEach((element: any) => {
      newMergedInitialFilesList.push(element);
    });
    initialNewFiles.forEach((element: any) => {
      newMergedInitialFilesList.push(element);
    });
    setMergedInitialFiles(newMergedInitialFilesList);
  };

  const handleDownload = (fileData: any) => {
    onFileDownload(fileData.fileId);
  };

  const removeCurrentFile = (fileData: any) => {
    setMergedInitialFiles(
      mergedInitialFiles?.map((file: any) => {
        if (file.fileId === fileData.fileId) {
          return {
            ...file,
            willBeRemoved: true,
          };
        }
        return file;
      })
    );
    onRemoveCurrentFile(fileData);
  };

  const cancelRemoveCurrentFile = (fileData: any) => {
    setMergedInitialFiles(
      mergedInitialFiles?.map((file: any) => {
        if (file.fileId === fileData.fileId) {
          return {
            ...file,
            willBeRemoved: false,
          };
        }
        return file;
      })
    );
    onCancelRemoveCurrentFile(fileData);
  };

  return (
    <Dialog open={display} fullWidth>
      {displayMode === "view" && (
        <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => onCancel(initialCurrentFiles, initialNewFiles)}
          >
            <Close />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent>
        {mergedInitialFiles.length !== 0 && (
          <Divider textAlign="left">Fisiere existente</Divider>
        )}

        {
          mergedInitialFiles.length !== 0 &&
            mergedInitialFiles?.map((file: any, index: number) => {
              return isNotNullOrUndefined(file.fileTitle) ? (
                <>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                      borderBottom:
                        index === mergedInitialFiles.length - 1
                          ? "rgba(0, 0, 0, 0.12)"
                          : "none",
                    }}
                    className="merged-files-container"
                  >
                    <span>{file.fileTitle}</span>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleDownload(file)}
                      >
                        Descarcă
                      </Button>
                      {displayMode === "edit" && (
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Switch
                            checked={
                              isNotNullOrUndefined(file.willBeRemoved) &&
                              file.willBeRemoved
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                removeCurrentFile(file);
                              } else {
                                cancelRemoveCurrentFile(file);
                              }
                            }}
                          ></Switch>
                          <span>Șterge</span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <span>{file.name}</span>
                  <Grid
                    sx={{
                      display: "flex",
                      // justifyContent: "flex-end",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      disabled
                      variant="contained"
                      color="warning"
                      onClick={() => handleDownload(file)}
                      sx={{ width: "105px" }}
                    >
                      Nesalvat
                    </Button>
                    <Grid
                      sx={{
                        display: "flex",
                        // justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Switch
                        // sx={{ marginRight: 1 }}
                        checked={initialNewFilesRemoved.some(
                          (file2: any) => file2.name === file.name
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            removeInitialNewFile(file);
                          } else {
                            cancelRemoveInitialNewFile(file);
                          }
                        }}
                      ></Switch>
                      <span>Șterge</span>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })

          // <Fieldset legend="Fisiere existente">
          //   <DataView
          //     value={mergedInitialFiles}
          //     itemTemplate={getFileTemplate}
          //     layout="list"
          //   ></DataView>
          // </Fieldset>
        }
        {displayMode !== "view" && (
          <>
            <Divider textAlign="left" sx={{ marginBottom: "10px" }}>
              Fisiere noi
            </Divider>

            <FileUploadComponent
              onFilesChange={(files: File[]) => {
                if (files.length > 0) {
                  onNewFilesSelect(files);
                  setNewLocalFiles(files);
                }
              }}
            />
          </>
        )}
      </DialogContent>
      {displayMode !== "view" && (
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            size="small"
            onClick={() => onCancel(initialCurrentFiles, initialNewFiles)}
          >
            Anulare
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => {
              if (
                isRequired &&
                !requiredCondition(
                  newLocalFiles,
                  mergedInitialFiles,
                  initialNewFilesRemoved
                )
              ) {
                dispatch(
                  addAppNotification({
                    message: requiredWarningText,
                    severity: "warning",
                  })
                );
                return;
              }
              onSave(initialNewFilesRemoved);
            }}
            autoFocus
          >
            Salvare
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
