import { Check, CloseOutlined, EditOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import ConfirmationDialog from "components/shared/confirmationDialog/ConfirmationDialog";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import React, { FC, useEffect, useState } from "react";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AllocationModel } from "store/models/mediation/AllocationModel";
import { countiesSelectItems } from "store/models/selectItems";
import { allocationsSortByItems } from "store/models/selectItems/allocationsSortByItems";
import {
  selectAllocations,
  selectAllocationsFilters,
  selectAllocationsLoading,
  selectAllocationsTotalCount,
} from "store/selectors/mediationSelectors";
import { setAllocationsFilters } from "store/slices/mediationSlice";
import {
  changeAllocationTime,
  getPaginatedAllocations,
  unallocateJobPost,
} from "store/thunks/mediationThunks";
import { getDateLabel, onlyDateFormat } from "utils";
import locale from "date-fns/locale/ro";
import { isAdmin, isEmployer, isExpert } from "utils/getUserType";

export const AllocationsTable: FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const allocations = useAppSelector(selectAllocations);
  const filters = useAppSelector(selectAllocationsFilters);
  const loading = useAppSelector(selectAllocationsLoading);
  const totalCount = useAppSelector(selectAllocationsTotalCount);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState<boolean>(false);
  const [newAllocationTime, setNewAllocationTime] = useState<Date | null>(null);
  const [allocationSelectedId, setAllocationSelectedId] = useState<number>();
  const ro = useAppSelector(selectMediationPageLocalization);

  const getAllocations = () => {
    return dispatch(
      getPaginatedAllocations({
        filters: filters,
        token: currentUser?.jwtToken,
      })
    );
  };

  useEffect(() => {
    let promise = getAllocations();
    return () => promise.abort();
    //eslint-disable-next-line
  }, [filters]);

  const handleEditAllocationTime = (allocation: AllocationModel) => {
    setAllocationSelectedId(allocation.allocationId);
    var date = new Date(allocation.allocationTime);

    setNewAllocationTime(date);
  };

  const handleSaveNewAllocationTime = (allocation: AllocationModel) => {
    //@ts-ignore

    dispatch(
      changeAllocationTime({
        model: {
          allocationId: allocation.allocationId,
          allocationTime: newAllocationTime?.toISOString()!,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      setAllocationSelectedId(undefined);
      setNewAllocationTime(null);
      getAllocations();
    });
  };

  const handleStopEditingAllocationTime = () => {
    setAllocationSelectedId(undefined);
    setNewAllocationTime(null);
  };

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: ro.usernameCol,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: ro.emailCol,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: ro.phoneNumberCol,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "allocationTime",
      headerName: ro.allocationTimeCol,
      flex: 1,
      minWidth: 280,
      renderCell: (params: GridRenderCellParams) => {
        if (isEmployer(currentUser?.userType)) {
          return (
            <React.Fragment>
              {getDateLabel(params.row.allocationTime, onlyDateFormat)}
            </React.Fragment>
          );
        }
        if (allocationSelectedId !== params.row.allocationId) {
          return (
            <Box className="edit-date">
              <Grid item xs="auto">
                {getDateLabel(params.row.allocationTime, onlyDateFormat)}
              </Grid>
              {(isExpert(currentUser?.userType) ||
                isAdmin(currentUser?.userType)) && (
                <Grid item>
                  <IconButton
                    size="small"
                    color="warning"
                    onClick={() => handleEditAllocationTime(params.row)}
                  >
                    <EditOutlined />
                  </IconButton>
                </Grid>
              )}
            </Box>
          );
        } else {
          return (
            <Box className="edit-date-open">
              <Grid item xs={7}>
                <DatePicker
                  value={newAllocationTime}
                  onChange={setNewAllocationTime}
                  mask="__.__.____"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      value={newAllocationTime}
                      size="small"
                      sx={{ backgroundColor: "white" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5} justifyContent="flex-end">
                <IconButton
                  color="success"
                  onClick={(e) => handleSaveNewAllocationTime(params.row)}
                >
                  <Check />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    handleStopEditingAllocationTime();
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              </Grid>
            </Box>
          );
        }
      },
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", onlyDateFormat);
        }
      },
    },
    {
      field: "jobTitle",
      headerName: ro.titleCol,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "jobTitle",
      headerName: ro.titleCol,
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "employerName",
      headerName: ro.employerNameCol,
      flex: 1,
      minWidth: 150,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "jobLocality",
      headerName: ro.localityCol,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "jobCounty",
      headerName: ro.countyCol,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (
          countiesSelectItems?.find((x) => x.value === params.value)?.label ??
          ""
        );
      },
    },
    {
      field: "",
      headerName: ro.optionsCol,
      sortable: false,
      hide: isEmployer(currentUser?.userType),
      flex: 1,
      minWidth: 150,
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              displayDeleteConfirmationDialog(params);
            }}
          >
            Dezalocă
          </Button>
        );
      },
    },
  ];

  const displayDeleteConfirmationDialog = (params: any) => {
    setAllocationSelectedId(params.row.allocationId);
    setDisplayConfirmationDialog(true);
  };

  const confirmDialogAction = () => {
    dispatch(
      unallocateJobPost({
        model: {
          allocationId: allocationSelectedId!,
          unallocatedByUserId: currentUser?.userId!,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      getAllocations();
      setDisplayConfirmationDialog(false);
      setAllocationSelectedId(undefined);
    });
  };

  const closeDeleteConfirmationDialog = () => {
    setDisplayConfirmationDialog(false);
    setAllocationSelectedId(undefined);
  };

  const handlePageChange = (page: number) => {
    dispatch(
      setAllocationsFilters({
        ...filters,
        pageNumber: page,
      })
    );
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length === 0) {
      dispatch(
        setAllocationsFilters({
          ...filters,
          allocationsSortBy: undefined,
          descending: false,
          pageNumber: 0,
        })
      );
    } else {
      dispatch(
        setAllocationsFilters({
          ...filters,
          allocationsSortBy: allocationsSortByItems?.find(
            (x) => x.field === newModel[0].field
          )?.value,
          descending: newModel[0].sort === "desc" ? true : false,
          pageNumber: 0,
        })
      );
    }
  };

  return (
    <Grid id="allocations-table">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DataGrid
          className="allocations-data-grid"
          components={{
            LoadingOverlay: DataLoadingComponent,
          }}
          loading={loading}
          page={filters.pageNumber}
          pageSize={filters.pageSize}
          rowsPerPageOptions={[filters.pageSize!]}
          hideFooterSelectedRowCount={true}
          disableSelectionOnClick={true}
          paginationMode="server"
          localeText={{
            noRowsLabel: "Fără rezultate",
          }}
          sx={{
            "& .MuiDataGrid-row:hover": {
              background: "aliceblue",
              cursor: "pointer",
            },
            height: 650,
            boxShadow: 3,
          }}
          rows={allocations}
          columns={columns}
          onPageChange={handlePageChange}
          rowCount={totalCount}
          disableColumnMenu={true}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        ></DataGrid>
      </LocalizationProvider>
      {displayConfirmationDialog && (
        <ConfirmationDialog
          display={displayConfirmationDialog}
          message={ro.confirmUnallocation}
          confirmAction={confirmDialogAction}
          cancelAction={closeDeleteConfirmationDialog}
        />
      )}
    </Grid>
  );
};
