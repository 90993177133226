import { EducationLevel } from "../enums/EducationLevel";

export const educationLevelSelectItems = [
  /*  {
    value: EducationLevel.InscrisProgramEducatie,
    label: "Înscris într-un program de educație",
  },
  {
    value: EducationLevel.InscrisProgramFormare,
    label: "Înscris într-un program de formare",
  }, */
  {
    value: EducationLevel.ISCED0,
    label: "Studii Educație timpurie (ISCED 0)",
  },
  {
    value: EducationLevel.ISCED1,
    label: "Studii primare (ISCED 1)",
  },
  {
    value: EducationLevel.ISCED2,
    label: "Studii gimnaziale (ISCED 2)",
  },
  {
    value: EducationLevel.ISCED3,
    label: "Studii liceale (ISCED 3)",
  },
  {
    value: EducationLevel.ISCED4,
    label: "Studii postliceale (ISCED 4)",
  },
  {
    value: EducationLevel.ISCED5,
    label: "Studii superioare (ISCED 5)",
  },
  {
    value: EducationLevel.ISCED6,
    label: "Studii superioare (ISCED 6)",
  },
  {
    value: EducationLevel.ISCED7,
    label: "Studii superioare (ISCED 7)",
  },
  {
    value: EducationLevel.ISCED8,
    label: "Studii superioare (ISCED 8)",
  },
];
