import { DataGridColumnNames } from "../enums/DataGridColumnNames";

export const counselingFilesDataSortSelectItems = [
  {
    field: "fileName",
    value: DataGridColumnNames.DocumentName,
    label: "Nume fișier",
  },
  {
    field: "contentType",
    value: DataGridColumnNames.ContentType,
    label: "Tip fișier",
  },
  {
    field: "uploadedByUsername",
    value: DataGridColumnNames.UploadedByUser,
    label: "Încărcat de",
  },
  {
    field: "uploadTime",
    value: DataGridColumnNames.UploadTime,
    label: "Data încărcării",
  },
];
