import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect } from "react";
import { useAppSelector } from "store";
import { selectParticipantsNumbers } from "store/selectors/statisticsSelectors";
import { Container, Grid } from "@mui/material";
import "./Trends.styles.scss";

am4core.useTheme(am4themes_animated);
export const Trends = () => {
  const statistics = useAppSelector(selectParticipantsNumbers);

  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.PieChart);
    let chart2 = am4core.create("chartdiv2", am4charts.PieChart);
    let chart3 = am4core.create("chartdiv3", am4charts.PieChart);

    chart.data = [
      {
        label: "Angajati necesari",
        value: statistics.employedRequired,
      },
      {
        label: "Angajati",
        value: statistics.employedNr,
      },
    ];

    chart2.data = [
      {
        label: "Formați necesari",
        value: statistics.formedRequired,
      },
      {
        label: "Formați",
        value: statistics.formedNr,
      },
    ];

    chart3.data = [
      {
        label: "Recrutați necesari",
        value: statistics.recruitedRequired,
      },
      {
        label: "Recrutați",
        value: statistics.recruitedNr,
      },
    ];

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "label";

    let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = "value";
    pieSeries2.dataFields.category = "label";

    let pieSeries3 = chart3.series.push(new am4charts.PieSeries());
    pieSeries3.dataFields.value = "value";
    pieSeries3.dataFields.category = "label";

    return () => {
      chart.dispose();
    };

    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl" className="trends-container">
      <Grid container xs={12} rowSpacing={4}>
        <Grid item xs={12} md={6}>
          <div id="chartdiv" className="chart"></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div id="chartdiv2" className="chart"></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div id="chartdiv3" className="chart"></div>
        </Grid>
      </Grid>
    </Container>
  );
};
