export const MuresSelectItemsLabel = [
  { value: "ABUD", label: "ABUD" },
  { value: "ABUȘ", label: "ABUȘ" },
  { value: "ACĂȚARI", label: "ACĂȚARI" },
  { value: "ADĂMUȘ", label: "ADĂMUȘ" },
  { value: "ADRIAN", label: "ADRIAN" },
  { value: "ADRIANU MARE", label: "ADRIANU MARE" },
  { value: "ADRIANU MIC", label: "ADRIANU MIC" },
  { value: "AGRIȘTEU", label: "AGRIȘTEU" },
  { value: "ALBEȘTI", label: "ALBEȘTI" },
  { value: "ALUNIȘ", label: "ALUNIȘ" },
  { value: "ANDRENEASA", label: "ANDRENEASA" },
  { value: "ANGOFA", label: "ANGOFA" },
  { value: "APALINA", label: "APALINA" },
  { value: "APOLD", label: "APOLD" },
  { value: "ARCHITA", label: "ARCHITA" },
  { value: "ARȘIȚA", label: "ARȘIȚA" },
  { value: "AȚINTIȘ", label: "AȚINTIȘ" },
  { value: "AUREL VLAICU", label: "AUREL VLAICU" },
  { value: "AVRĂMEȘTI", label: "AVRĂMEȘTI" },
  { value: "BĂGACIU", label: "BĂGACIU" },
  { value: "BAHNEA", label: "BAHNEA" },
  { value: "BĂIȚA", label: "BĂIȚA" },
  { value: "BĂLA", label: "BĂLA" },
  { value: "BĂLĂUȘERI", label: "BĂLĂUȘERI" },
  { value: "BALDA", label: "BALDA" },
  { value: "BAND", label: "BAND" },
  { value: "BÂRA", label: "BÂRA" },
  { value: "BĂRBOȘI", label: "BĂRBOȘI" },
  { value: "BĂRDEȘTI", label: "BĂRDEȘTI" },
  { value: "BÂRLIBAȘ", label: "BÂRLIBAȘ" },
  { value: "BÂRLIBĂȘOAIA", label: "BÂRLIBĂȘOAIA" },
  { value: "BÂRZA", label: "BÂRZA" },
  { value: "BATOȘ", label: "BATOȘ" },
  { value: "BEDENI", label: "BEDENI" },
  { value: "BEICA DE JOS", label: "BEICA DE JOS" },
  { value: "BEICA DE SUS", label: "BEICA DE SUS" },
  { value: "BERENI", label: "BERENI" },
  { value: "BERGHIA", label: "BERGHIA" },
  { value: "BERNADEA", label: "BERNADEA" },
  { value: "BEU", label: "BEU" },
  { value: "BEZID", label: "BEZID" },
  { value: "BEZIDU NOU", label: "BEZIDU NOU" },
  { value: "BICAȘU", label: "BICAȘU" },
  { value: "BICHIȘ", label: "BICHIȘ" },
  { value: "BISTRA MUREȘULUI", label: "BISTRA MUREȘULUI" },
  { value: "BLIDIREASA", label: "BLIDIREASA" },
  { value: "BOBOHALMA", label: "BOBOHALMA" },
  { value: "BOGATA", label: "BOGATA" },
  { value: "BOIU", label: "BOIU" },
  { value: "BOLINTINENI", label: "BOLINTINENI" },
  { value: "BOLOGAIA", label: "BOLOGAIA" },
  { value: "BORD", label: "BORD" },
  { value: "BORDOȘIU", label: "BORDOȘIU" },
  { value: "BORZIA", label: "BORZIA" },
  { value: "BOTEI", label: "BOTEI" },
  { value: "BOTEZ", label: "BOTEZ" },
  { value: "BOTORCA", label: "BOTORCA" },
  { value: "BOZED", label: "BOZED" },
  { value: "BOZENI", label: "BOZENI" },
  { value: "BRĂDEȚELU", label: "BRĂDEȚELU" },
  { value: "BRÂNCOVENEȘTI", label: "BRÂNCOVENEȘTI" },
  { value: "BREAZA", label: "BREAZA" },
  { value: "BUDIU MIC", label: "BUDIU MIC" },
  { value: "BUJOR", label: "BUJOR" },
  { value: "BUJOR-HODAIE", label: "BUJOR-HODAIE" },
  { value: "CĂCIULATA", label: "CĂCIULATA" },
  { value: "CĂCUCIU", label: "CĂCUCIU" },
  { value: "CĂLIMĂNEȘTI", label: "CĂLIMĂNEȘTI" },
  { value: "CĂLUGĂRENI", label: "CĂLUGĂRENI" },
  { value: "CĂLUȘERI", label: "CĂLUȘERI" },
  { value: "CÂMPENIȚA", label: "CÂMPENIȚA" },
  { value: "CÂMPU CETĂȚII", label: "CÂMPU CETĂȚII" },
  { value: "CÂNDU", label: "CÂNDU" },
  { value: "CĂPÂLNA DE SUS", label: "CĂPÂLNA DE SUS" },
  { value: "CĂPEȚI", label: "CĂPEȚI" },
  { value: "CĂPUȘU DE CÂMPIE", label: "CĂPUȘU DE CÂMPIE" },
  { value: "CAȘVA", label: "CAȘVA" },
  { value: "CECĂLACA", label: "CECĂLACA" },
  { value: "CEIE", label: "CEIE" },
  { value: "CERGHID", label: "CERGHID" },
  { value: "CERGHIZEL", label: "CERGHIZEL" },
  { value: "CEUAȘ", label: "CEUAȘ" },
  { value: "CEUAȘU DE CÂMPIE", label: "CEUAȘU DE CÂMPIE" },
  { value: "CHENDU", label: "CHENDU" },
  { value: "CHEȚANI", label: "CHEȚANI" },
  { value: "CHIBED", label: "CHIBED" },
  { value: "CHIHERU DE JOS", label: "CHIHERU DE JOS" },
  { value: "CHIHERU DE SUS", label: "CHIHERU DE SUS" },
  { value: "CHINARI", label: "CHINARI" },
  { value: "CHINCIUȘ", label: "CHINCIUȘ" },
  { value: "CHIRILEU", label: "CHIRILEU" },
  { value: "CHISĂLIȚA", label: "CHISĂLIȚA" },
  { value: "CIBA", label: "CIBA" },
  { value: "CIBU", label: "CIBU" },
  { value: "CINTA", label: "CINTA" },
  { value: "CIOARGA", label: "CIOARGA" },
  { value: "CIOBOTANI", label: "CIOBOTANI" },
  { value: "CIPĂIENI", label: "CIPĂIENI" },
  { value: "CIPĂU", label: "CIPĂU" },
  { value: "CIRETEA", label: "CIRETEA" },
  { value: "CIRHAGĂU", label: "CIRHAGĂU" },
  { value: "CIULEA", label: "CIULEA" },
  { value: "CIURGĂU", label: "CIURGĂU" },
  { value: "CLOAȘTERF", label: "CLOAȘTERF" },
  { value: "COASTA GRINDULUI", label: "COASTA GRINDULUI" },
  { value: "COASTA MARE", label: "COASTA MARE" },
  { value: "COMORI", label: "COMORI" },
  { value: "CORBEȘTI", label: "CORBEȘTI" },
  { value: "CORDOȘ", label: "CORDOȘ" },
  { value: "CORNEȘTI / ADĂMUȘ", label: "CORNEȘTI / ADĂMUȘ" },
  { value: "CORNEȘTI / CRĂCIUNEȘTI", label: "CORNEȘTI / CRĂCIUNEȘTI" },
  { value: "COROI", label: "COROI" },
  { value: "COROISÂNMĂRTIN", label: "COROISÂNMĂRTIN" },
  { value: "CORUNCA", label: "CORUNCA" },
  { value: "COTORINĂU", label: "COTORINĂU" },
  { value: "COTUȘ", label: "COTUȘ" },
  { value: "COZMA", label: "COZMA" },
  { value: "CRĂCIUNEȘTI", label: "CRĂCIUNEȘTI" },
  { value: "CRĂIEȘTI / ADĂMUȘ", label: "CRĂIEȘTI / ADĂMUȘ" },
  { value: "CRĂIEȘTI / CRĂIEȘTI", label: "CRĂIEȘTI / CRĂIEȘTI" },
  { value: "CRIȘ", label: "CRIȘ" },
  { value: "CRISTEȘTI", label: "CRISTEȘTI" },
  { value: "CUCERDEA", label: "CUCERDEA" },
  { value: "CUCI", label: "CUCI" },
  { value: "CUIEȘD", label: "CUIEȘD" },
  { value: "CULPIU", label: "CULPIU" },
  { value: "CUND", label: "CUND" },
  { value: "CUREȚE", label: "CUREȚE" },
  { value: "CURTENI", label: "CURTENI" },
  { value: "CUȘTELNIC", label: "CUȘTELNIC" },
  { value: "DAIA / APOLD", label: "DAIA / APOLD" },
  { value: "DAIA / BAHNEA", label: "DAIA / BAHNEA" },
  { value: "DALU", label: "DALU" },
  { value: "DÂMBĂU", label: "DÂMBĂU" },
  { value: "DÂMBU", label: "DÂMBU" },
  { value: "DĂMIENI", label: "DĂMIENI" },
  { value: "DANEȘ", label: "DANEȘ" },
  { value: "DĂTĂȘENI", label: "DĂTĂȘENI" },
  { value: "DEAG", label: "DEAG" },
  { value: "DEAJ", label: "DEAJ" },
  { value: "DEDA", label: "DEDA" },
  { value: "DEDRAD", label: "DEDRAD" },
  { value: "DELENI / IDECIU DE JOS", label: "DELENI / IDECIU DE JOS" },
  { value: "DELENI / POGĂCEAUA", label: "DELENI / POGĂCEAUA" },
  { value: "DELENII", label: "DELENII" },
  { value: "DILEU NOU", label: "DILEU NOU" },
  { value: "DILEU VECHI", label: "DILEU VECHI" },
  { value: "DOBRA", label: "DOBRA" },
  { value: "DRĂCULEA BANDULUI", label: "DRĂCULEA BANDULUI" },
  { value: "DROJDII", label: "DROJDII" },
  { value: "DUBIȘTEA DE PĂDURE", label: "DUBIȘTEA DE PĂDURE" },
  { value: "DULCEA", label: "DULCEA" },
  { value: "DUMBRAVA", label: "DUMBRAVA" },
  { value: "DUMBRĂVIOARA", label: "DUMBRĂVIOARA" },
  { value: "DUMITRENI", label: "DUMITRENI" },
  { value: "DUMITREȘTII", label: "DUMITREȘTII" },
  { value: "DUPĂ DEAL / CUCI", label: "DUPĂ DEAL / CUCI" },
  { value: "DUPĂ DEAL / ICLĂNZEL", label: "DUPĂ DEAL / ICLĂNZEL" },
  { value: "ERCEA", label: "ERCEA" },
  { value: "EREMIENI", label: "EREMIENI" },
  { value: "EREMITU", label: "EREMITU" },
  { value: "ERNEI", label: "ERNEI" },
  { value: "FÂNAȚE / BAND", label: "FÂNAȚE / BAND" },
  { value: "FÂNAȚE / FĂRĂGĂU", label: "FÂNAȚE / FĂRĂGĂU" },
  { value: "FÂNAȚE / ICLĂNZEL", label: "FÂNAȚE / ICLĂNZEL" },
  { value: "FÂNAȚE / TĂURENI", label: "FÂNAȚE / TĂURENI" },
  { value: "FÂNAȚELE CĂPUȘULUI", label: "FÂNAȚELE CĂPUȘULUI" },
  { value: "FÂNAȚELE MĂDĂRAȘULUI", label: "FÂNAȚELE MĂDĂRAȘULUI" },
  { value: "FÂNAȚELE SOCOLULUI", label: "FÂNAȚELE SOCOLULUI" },
  { value: "FÂNTÂNA BABII", label: "FÂNTÂNA BABII" },
  { value: "FÂNTÂNELE", label: "FÂNTÂNELE" },
  { value: "FĂRĂGĂU", label: "FĂRĂGĂU" },
  { value: "FELEAG", label: "FELEAG" },
  { value: "FILEA", label: "FILEA" },
  { value: "FILITELNIC", label: "FILITELNIC" },
  { value: "FILPIȘU MARE", label: "FILPIȘU MARE" },
  { value: "FILPIȘU MIC", label: "FILPIȘU MIC" },
  { value: "FIȚCĂU", label: "FIȚCĂU" },
  { value: "FOI", label: "FOI" },
  { value: "FRUNZENI", label: "FRUNZENI" },
  { value: "FUNDĂTURA", label: "FUNDĂTURA" },
  { value: "FUNDOAIA", label: "FUNDOAIA" },
  { value: "GĂIEȘTI", label: "GĂIEȘTI" },
  { value: "GĂLĂOAIA", label: "GĂLĂOAIA" },
  { value: "GĂLĂȚENI", label: "GĂLĂȚENI" },
  { value: "GĂLEȘTI", label: "GĂLEȘTI" },
  { value: "GÂMBUȚ", label: "GÂMBUȚ" },
  { value: "GĂNEȘTI", label: "GĂNEȘTI" },
  { value: "GAURA SÂNGERULUI", label: "GAURA SÂNGERULUI" },
  { value: "GHEJA", label: "GHEJA" },
  { value: "GHEORGHE DOJA", label: "GHEORGHE DOJA" },
  { value: "GHIDAȘTEU", label: "GHIDAȘTEU" },
  { value: "GHINDARI", label: "GHINDARI" },
  { value: "GHINEȘTI", label: "GHINEȘTI" },
  { value: "GIULUȘ", label: "GIULUȘ" },
  { value: "GIURGIȘ", label: "GIURGIȘ" },
  { value: "GLĂJĂRIE", label: "GLĂJĂRIE" },
  { value: "GLODENI", label: "GLODENI" },
  { value: "GOGAN", label: "GOGAN" },
  { value: "GORENI", label: "GORENI" },
  { value: "GORNEȘTI", label: "GORNEȘTI" },
  { value: "GRĂDINI", label: "GRĂDINI" },
  { value: "GRÂUȘORUL", label: "GRÂUȘORUL" },
  { value: "GREBENIȘU DE CÂMPIE", label: "GREBENIȘU DE CÂMPIE" },
  { value: "GRINDENI", label: "GRINDENI" },
  { value: "GROAPA RĂDĂII", label: "GROAPA RĂDĂII" },
  { value: "GRUIȘOR", label: "GRUIȘOR" },
  { value: "GURGHIU", label: "GURGHIU" },
  { value: "HABIC", label: "HABIC" },
  { value: "HĂDĂRENI", label: "HĂDĂRENI" },
  { value: "HAGĂU", label: "HAGĂU" },
  { value: "HĂRĂNGLAB", label: "HĂRĂNGLAB" },
  { value: "HĂRȚĂU", label: "HĂRȚĂU" },
  { value: "HEREPEA", label: "HEREPEA" },
  { value: "HERGHELIA", label: "HERGHELIA" },
  { value: "HETIUR", label: "HETIUR" },
  { value: "HODAC", label: "HODAC" },
  { value: "HODAIA", label: "HODAIA" },
  { value: "HODOȘA", label: "HODOȘA" },
  { value: "IARA DE MUREȘ", label: "IARA DE MUREȘ" },
  { value: "IBĂNEȘTI", label: "IBĂNEȘTI" },
  { value: "IBĂNEȘTI-PĂDURE", label: "IBĂNEȘTI-PĂDURE" },
  { value: "ICLAND", label: "ICLAND" },
  { value: "ICLANDU MARE", label: "ICLANDU MARE" },
  { value: "ICLĂNZEL", label: "ICLĂNZEL" },
  { value: "IDECIU DE JOS", label: "IDECIU DE JOS" },
  { value: "IDECIU DE SUS", label: "IDECIU DE SUS" },
  { value: "IDICEL", label: "IDICEL" },
  { value: "IDICEL-PĂDURE", label: "IDICEL-PĂDURE" },
  { value: "IDICIU", label: "IDICIU" },
  { value: "IDRIFAIA", label: "IDRIFAIA" },
  { value: "IERNUT", label: "IERNUT" },
  { value: "IERNUȚENI", label: "IERNUȚENI" },
  { value: "IHOD", label: "IHOD" },
  { value: "ILIENI", label: "ILIENI" },
  { value: "ILIEȘI", label: "ILIEȘI" },
  { value: "ILIOARA", label: "ILIOARA" },
  { value: "IOD", label: "IOD" },
  { value: "ISLA", label: "ISLA" },
  { value: "IȘTAN-TĂU", label: "IȘTAN-TĂU" },
  { value: "IȘTIHAZA", label: "IȘTIHAZA" },
  { value: "IVĂNEȘTI", label: "IVĂNEȘTI" },
  { value: "JABENIȚA", label: "JABENIȚA" },
  { value: "JACODU", label: "JACODU" },
  { value: "JACU", label: "JACU" },
  { value: "LĂPUȘNA", label: "LĂPUȘNA" },
  { value: "LARGA / GURGHIU", label: "LARGA / GURGHIU" },
  { value: "LARGA / ORAȘ SARMAȘU", label: "LARGA / ORAȘ SARMAȘU" },
  { value: "LĂSCUD", label: "LĂSCUD" },
  { value: "LASLĂU MARE", label: "LASLĂU MARE" },
  { value: "LASLĂU MIC", label: "LASLĂU MIC" },
  { value: "LĂURENI", label: "LĂURENI" },
  { value: "LECHINCIOARA", label: "LECHINCIOARA" },
  { value: "LECHINȚA", label: "LECHINȚA" },
  { value: "LEFAIA", label: "LEFAIA" },
  { value: "LENIȘ", label: "LENIȘ" },
  { value: "LEORDENI", label: "LEORDENI" },
  { value: "LEORINȚA", label: "LEORINȚA" },
  { value: "LEORINȚA-ȘĂULIA", label: "LEORINȚA-ȘĂULIA" },
  { value: "LEPINDEA", label: "LEPINDEA" },
  { value: "LINȚ", label: "LINȚ" },
  { value: "LIVEZENI", label: "LIVEZENI" },
  { value: "LOGIG", label: "LOGIG" },
  { value: "LOȚU", label: "LOȚU" },
  { value: "LUDUȘ", label: "LUDUȘ" },
  { value: "LUIERIU", label: "LUIERIU" },
  { value: "LUNCA", label: "LUNCA" },
  { value: "LUNCA BRADULUI", label: "LUNCA BRADULUI" },
  { value: "LUNCA MUREȘULUI", label: "LUNCA MUREȘULUI" },
  { value: "MĂCICĂȘEȘTI", label: "MĂCICĂȘEȘTI" },
  { value: "MĂDĂRAȘ", label: "MĂDĂRAȘ" },
  { value: "MĂDĂRĂȘENI", label: "MĂDĂRĂȘENI" },
  { value: "MĂGHERANI", label: "MĂGHERANI" },
  { value: "MĂGHERUȘ", label: "MĂGHERUȘ" },
  { value: "MAIA", label: "MAIA" },
  { value: "MAIAD", label: "MAIAD" },
  { value: "MAIOREȘTI", label: "MAIOREȘTI" },
  { value: "MĂLĂEȘTI", label: "MĂLĂEȘTI" },
  { value: "MALDAOCI", label: "MALDAOCI" },
  { value: "MALEA", label: "MALEA" },
  { value: "MĂRĂȘEȘTI", label: "MĂRĂȘEȘTI" },
  { value: "MĂRCULENI", label: "MĂRCULENI" },
  { value: "MĂTRICI", label: "MĂTRICI" },
  { value: "MERIȘOR", label: "MERIȘOR" },
  { value: "MERIȘORU", label: "MERIȘORU" },
  { value: "MEȘTERA", label: "MEȘTERA" },
  { value: "MICA", label: "MICA" },
  { value: "MIERCUREA NIRAJULUI", label: "MIERCUREA NIRAJULUI" },
  { value: "MIHAI VITEAZU", label: "MIHAI VITEAZU" },
  { value: "MIHEȘU DE CÂMPIE", label: "MIHEȘU DE CÂMPIE" },
  { value: "MILĂȘEL", label: "MILĂȘEL" },
  { value: "MIRIGIOAIA", label: "MIRIGIOAIA" },
  { value: "MITREȘTI", label: "MITREȘTI" },
  { value: "MOARA DE JOS", label: "MOARA DE JOS" },
  { value: "MOGOAIA", label: "MOGOAIA" },
  { value: "MOIȘA", label: "MOIȘA" },
  { value: "MORĂRENI", label: "MORĂRENI" },
  { value: "MOREȘTI", label: "MOREȘTI" },
  { value: "MORUȚ", label: "MORUȚ" },
  { value: "MOȘUNI", label: "MOȘUNI" },
  { value: "MURA MARE", label: "MURA MARE" },
  { value: "MURA MICĂ", label: "MURA MICĂ" },
  { value: "MURENI", label: "MURENI" },
  { value: "MUREȘENI", label: "MUREȘENI" },
  { value: "MURGEȘTI", label: "MURGEȘTI" },
  { value: "NADĂȘA", label: "NADĂȘA" },
  { value: "NADEȘ", label: "NADEȘ" },
  { value: "NANDRA", label: "NANDRA" },
  { value: "NAZNA", label: "NAZNA" },
  { value: "NEAGRA", label: "NEAGRA" },
  { value: "NEAUA", label: "NEAUA" },
  { value: "NEGRENII DE CÂMPIE", label: "NEGRENII DE CÂMPIE" },
  { value: "NICOLEȘTI", label: "NICOLEȘTI" },
  { value: "NIMA MILĂȘELULUI", label: "NIMA MILĂȘELULUI" },
  { value: "NIMA RÂCIULUI", label: "NIMA RÂCIULUI" },
  { value: "OARBA DE MUREȘ", label: "OARBA DE MUREȘ" },
  { value: "OBÂRȘIE", label: "OBÂRȘIE" },
  { value: "ODRIHEI", label: "ODRIHEI" },
  { value: "OGRA", label: "OGRA" },
  { value: "ONUCA", label: "ONUCA" },
  { value: "ORMENIȘ", label: "ORMENIȘ" },
  { value: "OROIU", label: "OROIU" },
  { value: "OROSIA", label: "OROSIA" },
  { value: "ORȘOVA", label: "ORȘOVA" },
  { value: "ORȘOVA-PĂDURE", label: "ORȘOVA-PĂDURE" },
  { value: "OZD", label: "OZD" },
  { value: "PĂCURENI", label: "PĂCURENI" },
  { value: "PĂDUREA", label: "PĂDUREA" },
  { value: "PĂDURENI", label: "PĂDURENI" },
  { value: "PĂINGENI", label: "PĂINGENI" },
  { value: "PĂNET", label: "PĂNET" },
  { value: "PAPIU ILARIAN", label: "PAPIU ILARIAN" },
  { value: "PÂRÂU CRUCII / POGĂCEAUA", label: "PÂRÂU CRUCII / POGĂCEAUA" },
  { value: "PÂRÂU CRUCII / RÂCIU", label: "PÂRÂU CRUCII / RÂCIU" },
  { value: "PÂRÂU MARE", label: "PÂRÂU MARE" },
  { value: "PĂSĂRENI", label: "PĂSĂRENI" },
  { value: "PĂUCIȘOARA", label: "PĂUCIȘOARA" },
  { value: "PĂULOAIA", label: "PĂULOAIA" },
  { value: "PERIȘ", label: "PERIȘ" },
  { value: "PETEA", label: "PETEA" },
  { value: "PETELEA", label: "PETELEA" },
  { value: "PETRILACA", label: "PETRILACA" },
  { value: "PETRILACA DE MUREȘ", label: "PETRILACA DE MUREȘ" },
  { value: "PIETRIȘ", label: "PIETRIȘ" },
  { value: "PIPEA", label: "PIPEA" },
  { value: "POARTA", label: "POARTA" },
  { value: "PODURI", label: "PODURI" },
  { value: "POGĂCEAUA", label: "POGĂCEAUA" },
  { value: "POIENIȚA", label: "POIENIȚA" },
  { value: "PORUMBAC", label: "PORUMBAC" },
  { value: "PORUMBENI", label: "PORUMBENI" },
  { value: "PRIPOARE", label: "PRIPOARE" },
  { value: "PUSTA", label: "PUSTA" },
  { value: "RACAMEȚ", label: "RACAMEȚ" },
  { value: "RÂCIU", label: "RÂCIU" },
  { value: "RANTA", label: "RANTA" },
  { value: "RÂPA DE JOS", label: "RÂPA DE JOS" },
  { value: "RĂSTOLIȚA", label: "RĂSTOLIȚA" },
  { value: "RĂZOARE", label: "RĂZOARE" },
  { value: "RECEA", label: "RECEA" },
  { value: "REGHIN", label: "REGHIN" },
  { value: "REMETEA", label: "REMETEA" },
  { value: "RIGMANI", label: "RIGMANI" },
  { value: "RORA", label: "RORA" },
  { value: "ROȘIORI", label: "ROȘIORI" },
  { value: "ROTENI", label: "ROTENI" },
  { value: "ROUA", label: "ROUA" },
  { value: "RUȘII-MUNȚI", label: "RUȘII-MUNȚI" },
  { value: "SĂBED", label: "SĂBED" },
  { value: "SĂCĂDAT", label: "SĂCĂDAT" },
  { value: "SĂCALU DE PĂDURE", label: "SĂCALU DE PĂDURE" },
  { value: "SĂCĂRENI", label: "SĂCĂRENI" },
  { value: "ȘAEȘ", label: "ȘAEȘ" },
  { value: "SĂLARD", label: "SĂLARD" },
  { value: "SĂLAȘURI", label: "SĂLAȘURI" },
  { value: "SĂLCUD", label: "SĂLCUD" },
  { value: "SÂMBRIAȘ", label: "SÂMBRIAȘ" },
  { value: "SÂNCRAIU DE MUREȘ", label: "SÂNCRAIU DE MUREȘ" },
  { value: "ȘANDRU", label: "ȘANDRU" },
  { value: "SÂNGEORGIU DE CÂMPIE", label: "SÂNGEORGIU DE CÂMPIE" },
  { value: "SÂNGEORGIU DE MUREȘ", label: "SÂNGEORGIU DE MUREȘ" },
  { value: "SÂNGEORGIU DE PĂDURE", label: "SÂNGEORGIU DE PĂDURE" },
  { value: "SÂNGER", label: "SÂNGER" },
  { value: "SÂNGERU DE PĂDURE", label: "SÂNGERU DE PĂDURE" },
  { value: "SÂNIACOB", label: "SÂNIACOB" },
  { value: "SÂNIȘOR", label: "SÂNIȘOR" },
  { value: "SÂNMĂRGHITA", label: "SÂNMĂRGHITA" },
  { value: "SÂNMĂRTINU DE CÂMPIE", label: "SÂNMĂRTINU DE CÂMPIE" },
  { value: "SÂNMIHAI DE PĂDURE", label: "SÂNMIHAI DE PĂDURE" },
  { value: "SÂNPAUL", label: "SÂNPAUL" },
  { value: "SÂNPETRU DE CÂMPIE", label: "SÂNPETRU DE CÂMPIE" },
  { value: "SÂNSIMION", label: "SÂNSIMION" },
  { value: "SÂNTANA DE MUREȘ", label: "SÂNTANA DE MUREȘ" },
  { value: "SÂNTIOANA", label: "SÂNTIOANA" },
  { value: "SÂNTIOANA DE MUREȘ", label: "SÂNTIOANA DE MUREȘ" },
  { value: "SÂNTU", label: "SÂNTU" },
  { value: "SÂNVĂSII", label: "SÂNVĂSII" },
  { value: "ȘAPARTOC", label: "ȘAPARTOC" },
  { value: "SĂRĂȚENI", label: "SĂRĂȚENI" },
  { value: "ȘARDU NIRAJULUI", label: "ȘARDU NIRAJULUI" },
  { value: "SĂRMĂȘEL", label: "SĂRMĂȘEL" },
  { value: "SĂRMĂȘEL-GARĂ", label: "SĂRMĂȘEL-GARĂ" },
  { value: "SARMAȘU", label: "SARMAȘU" },
  { value: "SASCHIZ", label: "SASCHIZ" },
  { value: "SATU NOU / GHEORGHE DOJA", label: "SATU NOU / GHEORGHE DOJA" },
  {
    value: "SATU NOU / SÂNPETRU DE CÂMPIE",
    label: "SATU NOU / SÂNPETRU DE CÂMPIE",
  },
  { value: "ȘĂULIA", label: "ȘĂULIA" },
  { value: "ȘĂULIȚA", label: "ȘĂULIȚA" },
  { value: "ȘĂUȘA", label: "ȘĂUȘA" },
  { value: "SCURTA", label: "SCURTA" },
  { value: "SEBEȘ", label: "SEBEȘ" },
  { value: "SELEUȘ / DANEȘ", label: "SELEUȘ / DANEȘ" },
  { value: "SELEUȘ / ZAGĂR", label: "SELEUȘ / ZAGĂR" },
  { value: "SENEREUȘ", label: "SENEREUȘ" },
  { value: "ȘERBENI", label: "ȘERBENI" },
  { value: "SEUCA", label: "SEUCA" },
  { value: "ȘEULIA DE MUREȘ", label: "ȘEULIA DE MUREȘ" },
  { value: "SFÂNTU GHEORGHE", label: "SFÂNTU GHEORGHE" },
  { value: "SICELE", label: "SICELE" },
  { value: "SIGHIȘOARA", label: "SIGHIȘOARA" },
  { value: "ȘILEA NIRAJULUI", label: "ȘILEA NIRAJULUI" },
  { value: "ȘINCAI", label: "ȘINCAI" },
  { value: "ȘINCAI-FÂNAȚE", label: "ȘINCAI-FÂNAȚE" },
  { value: "ȘOARD", label: "ȘOARD" },
  { value: "SOCOLU DE CÂMPIE", label: "SOCOLU DE CÂMPIE" },
  { value: "ȘOIMUȘ", label: "ȘOIMUȘ" },
  { value: "SOLOCMA", label: "SOLOCMA" },
  { value: "SOLOVĂSTRU", label: "SOLOVĂSTRU" },
  { value: "ȘOMOȘTELNIC", label: "ȘOMOȘTELNIC" },
  { value: "ȘOROMICLEA", label: "ȘOROMICLEA" },
  { value: "SOVATA", label: "SOVATA" },
  { value: "STÂNCENI", label: "STÂNCENI" },
  { value: "ȘTEFANCA", label: "ȘTEFANCA" },
  { value: "ȘTEFĂNEACA", label: "ȘTEFĂNEACA" },
  { value: "STEJĂRENII", label: "STEJĂRENII" },
  { value: "STEJERIȘ", label: "STEJERIȘ" },
  { value: "SUB PĂDURE", label: "SUB PĂDURE" },
  { value: "SUPLAC", label: "SUPLAC" },
  { value: "SUSENI", label: "SUSENI" },
  { value: "SUVEICA", label: "SUVEICA" },
  { value: "TĂBLĂȘENI", label: "TĂBLĂȘENI" },
  { value: "TÂMPA", label: "TÂMPA" },
  { value: "TÂRGU MUREȘ", label: "TÂRGU MUREȘ" },
  { value: "TÂRNĂVENI", label: "TÂRNĂVENI" },
  { value: "TĂU", label: "TĂU" },
  { value: "TĂURENI", label: "TĂURENI" },
  { value: "TELEAC", label: "TELEAC" },
  { value: "ȚIGMANDRU", label: "ȚIGMANDRU" },
  { value: "ȚIPTELNIC", label: "ȚIPTELNIC" },
  { value: "TIREU", label: "TIREU" },
  { value: "TIRIMIA", label: "TIRIMIA" },
  { value: "TIRIMIOARA", label: "TIRIMIOARA" },
  { value: "TISIEU", label: "TISIEU" },
  { value: "TITIANA", label: "TITIANA" },
  { value: "TOACA", label: "TOACA" },
  { value: "TOFALĂU", label: "TOFALĂU" },
  { value: "TOLDAL", label: "TOLDAL" },
  { value: "TONCIU", label: "TONCIU" },
  { value: "ȚOPA", label: "ȚOPA" },
  { value: "TORBA", label: "TORBA" },
  { value: "TREI SATE", label: "TREI SATE" },
  { value: "TROIȚA", label: "TROIȚA" },
  { value: "TUȘINU", label: "TUȘINU" },
  { value: "UILA", label: "UILA" },
  { value: "ULIEȘ", label: "ULIEȘ" },
  { value: "UNGHENI", label: "UNGHENI" },
  { value: "URICEA", label: "URICEA" },
  { value: "URISIU DE JOS", label: "URISIU DE JOS" },
  { value: "URISIU DE SUS", label: "URISIU DE SUS" },
  { value: "URSOAIA", label: "URSOAIA" },
  { value: "VĂDAȘ", label: "VĂDAȘ" },
  { value: "VADU", label: "VADU" },
  { value: "VAIDACUȚA", label: "VAIDACUȚA" },
  { value: "VAIDEIU", label: "VAIDEIU" },
  { value: "VALEA", label: "VALEA" },
  { value: "VALEA ALBEȘTIULUI", label: "VALEA ALBEȘTIULUI" },
  { value: "VALEA DĂII", label: "VALEA DĂII" },
  { value: "VALEA FRĂȚIEI", label: "VALEA FRĂȚIEI" },
  { value: "VALEA GLODULUI", label: "VALEA GLODULUI" },
  { value: "VALEA ICLANDULUI", label: "VALEA ICLANDULUI" },
  { value: "VALEA IZVOARELOR", label: "VALEA IZVOARELOR" },
  { value: "VALEA LARGĂ", label: "VALEA LARGĂ" },
  { value: "VALEA MARE", label: "VALEA MARE" },
  { value: "VALEA PĂDURII", label: "VALEA PĂDURII" },
  { value: "VALEA RECE", label: "VALEA RECE" },
  { value: "VALEA SÂNMĂRTINULUI", label: "VALEA SÂNMĂRTINULUI" },
  {
    value: "VALEA SÂNPETRULUI / GREBENIȘU DE CÂMPIE",
    label: "VALEA SÂNPETRULUI / GREBENIȘU DE CÂMPIE",
  },
  {
    value: "VALEA SÂNPETRULUI / POGĂCEAUA",
    label: "VALEA SÂNPETRULUI / POGĂCEAUA",
  },
  { value: "VALEA ȘAPARTOCULUI", label: "VALEA ȘAPARTOCULUI" },
  { value: "VALEA SASULUI", label: "VALEA SASULUI" },
  { value: "VALEA SEACĂ", label: "VALEA SEACĂ" },
  { value: "VALEA ȘURII", label: "VALEA ȘURII" },
  { value: "VALEA ULIEȘULUI", label: "VALEA ULIEȘULUI" },
  { value: "VALEA UNGURULUI", label: "VALEA UNGURULUI" },
  { value: "VALEA URIEȘULUI", label: "VALEA URIEȘULUI" },
  { value: "VĂLENI", label: "VĂLENI" },
  { value: "VĂLENII", label: "VĂLENII" },
  { value: "VĂLENII DE MUREȘ", label: "VĂLENII DE MUREȘ" },
  { value: "VĂLIȘOARA", label: "VĂLIȘOARA" },
  { value: "VĂLURENI", label: "VĂLURENI" },
  { value: "VÂNĂTORI", label: "VÂNĂTORI" },
  { value: "VĂRGATA", label: "VĂRGATA" },
  { value: "VĂTAVA", label: "VĂTAVA" },
  { value: "VENCHI", label: "VENCHI" },
  { value: "VEȚA", label: "VEȚA" },
  { value: "VEȚCA", label: "VEȚCA" },
  { value: "VIDRASĂU", label: "VIDRASĂU" },
  { value: "VIFOROASA", label: "VIFOROASA" },
  { value: "VIILOR", label: "VIILOR" },
  { value: "VIIȘOARA", label: "VIIȘOARA" },
  { value: "VIȘINELU", label: "VIȘINELU" },
  { value: "VOINICENI", label: "VOINICENI" },
  { value: "VOIVODENI", label: "VOIVODENI" },
  { value: "VULCAN", label: "VULCAN" },
  { value: "ZAGĂR", label: "ZAGĂR" },
  { value: "ZĂPODEA", label: "ZĂPODEA" },
  { value: "ZAU DE CÂMPIE", label: "ZAU DE CÂMPIE" },
  { value: "ZIMȚI", label: "ZIMȚI" },
];
