import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useEffect, useRef } from "react";
import { FormikDatePicker } from "../../../components/shared/formikInputs/FormikDatePicker";
import { FormikTextField } from "../../../components/shared/formikInputs/FormikTextField";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "store";
import { selectDecontSubmitStatus } from "store/selectors/manageDocumentsSelectors";
import { setDecontSubmitStatus } from "store/slices/manageDocumentsSlice";
import "./InputTableDecontCheltuieli.scss";


interface InputTableDecontCheltuieliProps {
    uploadDocJustificativ: Function;
    node: any;
    isExpert: boolean;
    validateDecont: Function;
    editModeId: number | null;

}

export const InputTableDecontCheltuieliComponent: FC<InputTableDecontCheltuieliProps> = ({ uploadDocJustificativ, node, isExpert, validateDecont, editModeId }) => {

    const dispatch = useAppDispatch();
    const decontSubmitStatus = useAppSelector(selectDecontSubmitStatus);
    const formRef = useRef<any>();
    const prevProps = useRef<any>();

    var initialValues = {
        tipCheltuiala: node.data.decontCheltuieliId ? node.data.tipCheltuiala : null,
        nrDocumentJustificativ: node.data.decontCheltuieliId ? node.data.nrDocumentJustificativ : null,
        ajutorMinimis: node.data.decontCheltuieliId ? node.data.ajutorMinimis : null,
        contributieProprie: node.data.decontCheltuieliId ? node.data.contributieProprie : null,
        dataDocumentJustificativ: node.data.decontCheltuieliId ? node.data.dataDocumentJustificativ : null
    }
    useEffect(() => {
        if (prevProps.current && (editModeId || editModeId !== prevProps.current)) {
            formRef.current.resetForm();
        }
        prevProps.current = editModeId;
        //eslint-disable-next-line
    }, [editModeId])
    useEffect(() => {
        if (decontSubmitStatus === "loading") {
            handleSubmit();
        } else {
            dispatch(setDecontSubmitStatus("idle"))
        }
        //eslint-disable-next-line
    }, [decontSubmitStatus])


    const validationSchema = yup.object({
        tipCheltuiala: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        nrDocumentJustificativ: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        ajutorMinimis: yup
            .number().typeError('Doar numere permise!')
            .required("Câmp obligatoriu!").nullable(true),
        contributieProprie: yup
            .number().typeError('Doar numere permise!')
            .required("Câmp obligatoriu!").nullable(true),
        dataDocumentJustificativ: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
    });

    const handleSubmit = () => {
        if (node.data.isNotSaved) {
            if (decontSubmitStatus === "loading") {
                formRef.current.handleSubmit();
                if (formRef.current.errors.length === 0) {
                    uploadDocJustificativ(node, formRef.current.values);

                }
                dispatch(setDecontSubmitStatus("idle"));
            }
        }
        if (editModeId === node.data.decontId) {
            if (decontSubmitStatus === "loading") {
                formRef.current.handleSubmit();
                dispatch(setDecontSubmitStatus("idle"));
            }
        }

    }


    return (
        <Grid id="decont-cheltuieli" container display={"inline-flex"} flexDirection={"row"} width={"90%"}>
            <Formik enableReinitialize
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    uploadDocJustificativ(node, values);
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}>
                {({ values, errors }) => <Form>
                    <Grid sx={{ display: "flex", flexWrap: "wrap" }}>
                        <Grid className="formik-input-container">
                            <FormikTextField label="Tip cheltuială" name="tipCheltuiala" size="small" readOnly={node.data.isNotSaved || editModeId === node.data.decontId ? false : true} />

                        </Grid>
                        <Grid className="formik-input-container" >
                            <FormikTextField label="Denumire document" name="nrDocumentJustificativ" size="small" readOnly={node.data.isNotSaved || editModeId === node.data.decontId ? false : true} />

                        </Grid>

                        <Grid className="formik-input-container">

                            <FormikDatePicker name="dataDocumentJustificativ" label="Dată document" size="small" readOnly={node.data.isNotSaved || editModeId === node.data.decontId ? false : true} />

                        </Grid>
                        <Grid className="formik-input-container">
                            <FormikTextField label="Ajutor minimis" name="ajutorMinimis" size="small" readOnly={node.data.isNotSaved || editModeId === node.data.decontId ? false : true} />

                        </Grid>
                        <Grid className="formik-input-container">
                            <FormikTextField label="Contribuție proprie" name="contributieProprie" size="small" readOnly={node.data.isNotSaved || editModeId === node.data.decontId ? false : true} />
                        </Grid>
                    </Grid>
                </Form>}
            </Formik>

        </Grid>
    )

}