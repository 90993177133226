import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetFromFileShareModel } from "store/models/files";
import {
  AppThunkConfig
} from "..";
import { baseUrl, getAxiosErrorMessage } from "../../utils";
import { addAppNotification } from "../slices/appNotificationSlice";

const axios = require("axios").default;

export const downloadFile = createAsyncThunk<
  any,
  {
    token: string | undefined;
    dto:GetFromFileShareModel;
  },
  AppThunkConfig
>("files/downloadFile", async (requestData, thunkAPI) => {

  try {
    let result = await axios.get(baseUrl + "File/DownloadFile", {
      params: requestData.dto,
      responseType: "blob",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${requestData.token}`,
      },
    });
    var blob: Blob = new Blob([result.data], {
      type: result.headers["content-type"],
    });
    var url = URL.createObjectURL(blob);

    let fileLink = document.createElement("a");
    fileLink.href = url;

    let filename = "";
    let disposition = result.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
  } catch (error: any) {
    let errorMessage = getAxiosErrorMessage(error);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(error));
    } else {
      throw error;
    }
  }
});

export const getPicture = createAsyncThunk<
  any,
  {
    dto:GetFromFileShareModel;
  },
  AppThunkConfig
>("files/getPicture", async ({dto}, thunkAPI) => {

  try {
    let result = await axios.get(baseUrl + "File/DownloadFile", {
      params:dto,
      responseType:"blob",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    var blob: Blob = new Blob([result.data], {
      type: result.headers["content-type"],
    });
    var url = URL.createObjectURL(blob);

    let fileLink = document.createElement("a");
    fileLink.href = url;
    return fileLink.href;
  } catch (error: any) {
    let errorMessage = getAxiosErrorMessage(error);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(error));
    } else {
      throw error;
    }
  }
});

