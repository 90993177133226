import { RootState } from "store";
import { CompanyUserDetailsModel } from "store/models/targetGroups/CompanyUserDetailsModel";
import {
  CompanyUsersFilter,
  ExpertUsersFilters,
  UserModel,
} from "../models/user/UserModel";
import { TargetGroupUserModel } from "store/models/targetGroups/TargetGroupUserModel";
import { ExpertUserModel } from "store/models/targetGroups/ExpertUserModel";

export const selectUsers = (state: RootState): TargetGroupUserModel[] =>
  state.targetGroup?.users ?? [];
export const selectUsersRowCount = (state: RootState): number =>
  state.targetGroup?.rowCount;
export const selectUserDetails = (state: RootState): UserModel =>
  state.targetGroup?.userDetails ?? [];
export const selectUserPersonalDocs = (state: RootState): any[] =>
  state.targetGroup?.userPersonalDocumentsResponse?.userDocuments ?? [];
export const selectUserPersonalDocsRowCount = (state: RootState): number =>
  state.targetGroup?.userPersonalDocumentsResponse?.rowCount ?? 0;
export const selectSortModel = (state: RootState): any =>
  state.targetGroup?.sortModel;
export const selectUsersListIsLoading = (state: RootState): boolean =>
  state.targetGroup?.usersListIsLoading;
export const selectUserDocumentsListIsLoading = (state: RootState): boolean =>
  state.targetGroup?.userDocumentsListIsLoading;
export const selectUserIsDownloadingFile = (state: RootState): boolean =>
  state.targetGroup?.userIsDownloadingFile;
export const selectUserIsUploadingFile = (state: RootState): boolean =>
  state.targetGroup?.userIsUploadingFile;
export const selectDeletingUser = (state: RootState): boolean =>
  state.targetGroup?.deletingUser;
export const selectDeletingUserId = (state: RootState): any =>
  state.targetGroup?.deletingUserId;
export const selectUserDetailsIsLoading = (state: RootState): boolean =>
  state.targetGroup?.userDetailsIsLoading;
export const selectSelectedTargetGroupUser = (state: RootState): any =>
  state.targetGroup?.selectedTargetGroupUser;
export const selectExpertsItems = (state: RootState): ExpertUserModel[] =>
  state.targetGroup?.experts?.expertUsers;
export const selectExpertsRowCount = (state: RootState): number =>
  state.targetGroup?.experts?.rowCount;

export const selectTargetGroupFilters = (
  state: RootState
): CompanyUsersFilter => state.targetGroup.targetGroupFilters;
export const selectExpertUsersFilters = (
  state: RootState
): ExpertUsersFilters => state.targetGroup.expertUsersFilters;

export const selectUpdatedCompanyDetails = (state: RootState): any =>
  state.targetGroup?.updatedCompanyDetails;
export const selectRegisterUserFormValues = (
  state: RootState
): CompanyUserDetailsModel | undefined =>
  state.targetGroup.registerUserFormValues;

export const selectGeneratingUserForm = (state: RootState): boolean =>
  state.targetGroup.generatingUserForm;
