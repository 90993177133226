export const MaramuresSelectItemsLabel = [
  { value: "ARDUSAT", label: "ARDUSAT" },
  { value: "ARDUZEL", label: "ARDUZEL" },
  { value: "ARIEȘU DE CÂMP", label: "ARIEȘU DE CÂMP" },
  { value: "ARIEȘU DE PĂDURE", label: "ARIEȘU DE PĂDURE" },
  { value: "ARINIȘ", label: "ARINIȘ" },
  { value: "ASPRA", label: "ASPRA" },
  { value: "ASUAJU DE JOS", label: "ASUAJU DE JOS" },
  { value: "ASUAJU DE SUS", label: "ASUAJU DE SUS" },
  { value: "BABA", label: "BABA" },
  { value: "BAIA MARE", label: "BAIA MARE" },
  { value: "BAIA SPRIE", label: "BAIA SPRIE" },
  { value: "BĂILE BORȘA", label: "BĂILE BORȘA" },
  { value: "BĂIȚA", label: "BĂIȚA" },
  { value: "BĂIȚA DE SUB CODRU", label: "BĂIȚA DE SUB CODRU" },
  { value: "BĂIUȚ", label: "BĂIUȚ" },
  { value: "BÂRGĂU", label: "BÂRGĂU" },
  { value: "BÂRSANA", label: "BÂRSANA" },
  { value: "BĂSEȘTI", label: "BĂSEȘTI" },
  { value: "BERBEȘTI", label: "BERBEȘTI" },
  { value: "BERCHEZ", label: "BERCHEZ" },
  { value: "BERCHEZOAIA", label: "BERCHEZOAIA" },
  { value: "BERINȚA", label: "BERINȚA" },
  { value: "BICAZ", label: "BICAZ" },
  { value: "BISTRA", label: "BISTRA" },
  { value: "BLIDARI", label: "BLIDARI" },
  { value: "BOCICOEL", label: "BOCICOEL" },
  { value: "BOCICOIU MARE", label: "BOCICOIU MARE" },
  { value: "BOGDAN VODĂ", label: "BOGDAN VODĂ" },
  { value: "BOIERENI", label: "BOIERENI" },
  { value: "BOIU MARE", label: "BOIU MARE" },
  { value: "BONTĂIENI", label: "BONTĂIENI" },
  { value: "BORCUT", label: "BORCUT" },
  { value: "BORȘA", label: "BORȘA" },
  { value: "BOTIZA", label: "BOTIZA" },
  { value: "BOZÂNTA MARE", label: "BOZÂNTA MARE" },
  { value: "BOZÂNTA MICĂ", label: "BOZÂNTA MICĂ" },
  { value: "BREB", label: "BREB" },
  { value: "BREBENI", label: "BREBENI" },
  { value: "BUCIUMI", label: "BUCIUMI" },
  { value: "BUDEȘTI", label: "BUDEȘTI" },
  { value: "BUȘAG", label: "BUȘAG" },
  { value: "BUTEASA", label: "BUTEASA" },
  { value: "BUZEȘTI", label: "BUZEȘTI" },
  { value: "CĂLINEȘTI", label: "CĂLINEȘTI" },
  { value: "CÂMPULUNG LA TISA", label: "CÂMPULUNG LA TISA" },
  { value: "CĂRBUNARI", label: "CĂRBUNARI" },
  { value: "CĂRPINIȘ", label: "CĂRPINIȘ" },
  { value: "CĂTĂLINA", label: "CĂTĂLINA" },
  { value: "CAVNIC", label: "CAVNIC" },
  { value: "CERNEȘTI", label: "CERNEȘTI" },
  { value: "CETĂȚELE", label: "CETĂȚELE" },
  { value: "CHECHIȘ", label: "CHECHIȘ" },
  { value: "CHELINȚA", label: "CHELINȚA" },
  { value: "CHIUZBAIA", label: "CHIUZBAIA" },
  { value: "CICÂRLĂU", label: "CICÂRLĂU" },
  { value: "CIOCOTIȘ", label: "CIOCOTIȘ" },
  { value: "CIOLT", label: "CIOLT" },
  { value: "CIUTA", label: "CIUTA" },
  { value: "COAȘ", label: "COAȘ" },
  { value: "CODRU BUTESII", label: "CODRU BUTESII" },
  { value: "COLTĂU", label: "COLTĂU" },
  { value: "COLȚIREA", label: "COLȚIREA" },
  { value: "COPALNIC", label: "COPALNIC" },
  { value: "COPALNIC-DEAL", label: "COPALNIC-DEAL" },
  { value: "COPALNIC-MĂNĂȘTUR", label: "COPALNIC-MĂNĂȘTUR" },
  { value: "CORNEȘTI", label: "CORNEȘTI" },
  { value: "CORNI", label: "CORNI" },
  { value: "COROIENI", label: "COROIENI" },
  { value: "CORUIA", label: "CORUIA" },
  { value: "COSTENI", label: "COSTENI" },
  { value: "COȘTIUI", label: "COȘTIUI" },
  { value: "CRĂCIUNEȘTI", label: "CRĂCIUNEȘTI" },
  { value: "CRASNA VIȘEULUI", label: "CRASNA VIȘEULUI" },
  { value: "CUFOAIA", label: "CUFOAIA" },
  { value: "CULCEA", label: "CULCEA" },
  { value: "CUPȘENI", label: "CUPȘENI" },
  { value: "CURTUIUȘU MARE", label: "CURTUIUȘU MARE" },
  { value: "CURTUIUȘU MIC", label: "CURTUIUȘU MIC" },
  { value: "DĂMĂCUȘENI", label: "DĂMĂCUȘENI" },
  { value: "DĂNEȘTI", label: "DĂNEȘTI" },
  { value: "DĂNEȘTII CHIOARULUI", label: "DĂNEȘTII CHIOARULUI" },
  { value: "DEALU CORBULUI", label: "DEALU CORBULUI" },
  { value: "DEALU MARE", label: "DEALU MARE" },
  { value: "DESEȘTI", label: "DESEȘTI" },
  { value: "DOBRICU LĂPUȘULUI", label: "DOBRICU LĂPUȘULUI" },
  { value: "DRĂGHIA", label: "DRĂGHIA" },
  { value: "DRAGOMIREȘTI", label: "DRAGOMIREȘTI" },
  { value: "DUMBRAVA", label: "DUMBRAVA" },
  { value: "DUMBRĂVIȚA", label: "DUMBRĂVIȚA" },
  { value: "DURUȘA", label: "DURUȘA" },
  { value: "FÂNAȚE", label: "FÂNAȚE" },
  { value: "FÂNTÂNELE", label: "FÂNTÂNELE" },
  { value: "FĂRCAȘA", label: "FĂRCAȘA" },
  { value: "FĂUREȘTI", label: "FĂUREȘTI" },
  { value: "FEREȘTI", label: "FEREȘTI" },
  { value: "FERICEA", label: "FERICEA" },
  { value: "FERSIG", label: "FERSIG" },
  { value: "FINTEUȘU MARE", label: "FINTEUȘU MARE" },
  { value: "FINTEUȘU MIC", label: "FINTEUȘU MIC" },
  { value: "FIRIZA", label: "FIRIZA" },
  { value: "FRÂNCENII BOIULUI", label: "FRÂNCENII BOIULUI" },
  { value: "GÂRDANI", label: "GÂRDANI" },
  { value: "GIULEȘTI", label: "GIULEȘTI" },
  { value: "GLOD", label: "GLOD" },
  { value: "GROAPE", label: "GROAPE" },
  { value: "GROȘI", label: "GROȘI" },
  { value: "GROȘII ȚIBLEȘULUI", label: "GROȘII ȚIBLEȘULUI" },
  { value: "HANDALU ILBEI", label: "HANDALU ILBEI" },
  { value: "HĂRNICEȘTI", label: "HĂRNICEȘTI" },
  { value: "HIDEAGA", label: "HIDEAGA" },
  { value: "HOTENI", label: "HOTENI" },
  { value: "HOVRILA", label: "HOVRILA" },
  { value: "IADĂRA", label: "IADĂRA" },
  { value: "IAPA", label: "IAPA" },
  { value: "IEUD", label: "IEUD" },
  { value: "ILBA", label: "ILBA" },
  { value: "INĂU", label: "INĂU" },
  { value: "ÎNTRERÂURI", label: "ÎNTRERÂURI" },
  { value: "IZVOARELE", label: "IZVOARELE" },
  { value: "JUGĂSTRENI", label: "JUGĂSTRENI" },
  { value: "LĂPUȘ", label: "LĂPUȘ" },
  { value: "LĂPUȘEL", label: "LĂPUȘEL" },
  { value: "LARGA", label: "LARGA" },
  { value: "LĂSCHIA", label: "LĂSCHIA" },
  { value: "LAZU BACIULUI", label: "LAZU BACIULUI" },
  { value: "LEORDINA", label: "LEORDINA" },
  { value: "LIBOTIN", label: "LIBOTIN" },
  { value: "LUCĂCEȘTI", label: "LUCĂCEȘTI" },
  { value: "LUNCA LA TISA", label: "LUNCA LA TISA" },
  { value: "MĂGURENI", label: "MĂGURENI" },
  { value: "MĂNĂSTIREA", label: "MĂNĂSTIREA" },
  { value: "MÂNĂU", label: "MÂNĂU" },
  { value: "MARA", label: "MARA" },
  { value: "MERIȘOR", label: "MERIȘOR" },
  { value: "MESTEACĂN", label: "MESTEACĂN" },
  { value: "MIREȘU MARE", label: "MIREȘU MARE" },
  { value: "MOCIRA", label: "MOCIRA" },
  { value: "MOGOȘEȘTI", label: "MOGOȘEȘTI" },
  { value: "MOISEI", label: "MOISEI" },
  { value: "NĂNEȘTI", label: "NĂNEȘTI" },
  { value: "NEGREIA", label: "NEGREIA" },
  { value: "NISTRU", label: "NISTRU" },
  { value: "OARȚA DE JOS", label: "OARȚA DE JOS" },
  { value: "OARȚA DE SUS", label: "OARȚA DE SUS" },
  { value: "OCNA ȘUGATAG", label: "OCNA ȘUGATAG" },
  { value: "OCOLIȘ", label: "OCOLIȘ" },
  { value: "ODEȘTI", label: "ODEȘTI" },
  { value: "ONCEȘTI", label: "ONCEȘTI" },
  { value: "ORȚIȚA", label: "ORȚIȚA" },
  { value: "PETERITEA", label: "PETERITEA" },
  { value: "PETROVA", label: "PETROVA" },
  { value: "PIATRA", label: "PIATRA" },
  { value: "PLOPIȘ", label: "PLOPIȘ" },
  { value: "POIANA BOTIZII", label: "POIANA BOTIZII" },
  { value: "POIENILE DE SUB MUNTE", label: "POIENILE DE SUB MUNTE" },
  { value: "POIENILE IZEI", label: "POIENILE IZEI" },
  { value: "POSTA", label: "POSTA" },
  { value: "PRELUCA NOUĂ", label: "PRELUCA NOUĂ" },
  { value: "PRELUCA VECHE", label: "PRELUCA VECHE" },
  { value: "PRIBILEȘTI", label: "PRIBILEȘTI" },
  { value: "PRISLOP", label: "PRISLOP" },
  { value: "RĂZOARE", label: "RĂZOARE" },
  { value: "RECEA", label: "RECEA" },
  { value: "REMECIOARA", label: "REMECIOARA" },
  { value: "REMETEA CHIOARULUI", label: "REMETEA CHIOARULUI" },
  { value: "REMEȚI", label: "REMEȚI" },
  { value: "REMEȚI PE SOMEȘ", label: "REMEȚI PE SOMEȘ" },
  { value: "REPEDEA", label: "REPEDEA" },
  { value: "RODINA", label: "RODINA" },
  { value: "ROGOZ", label: "ROGOZ" },
  { value: "ROHIA", label: "ROHIA" },
  { value: "ROMĂNEȘTI", label: "ROMĂNEȘTI" },
  { value: "RONA DE JOS", label: "RONA DE JOS" },
  { value: "RONA DE SUS", label: "RONA DE SUS" },
  { value: "ROZAVLEA", label: "ROZAVLEA" },
  { value: "RUS", label: "RUS" },
  { value: "RUSCOVA", label: "RUSCOVA" },
  { value: "RUȘOR", label: "RUȘOR" },
  { value: "SĂBIȘA", label: "SĂBIȘA" },
  { value: "SĂCĂLĂȘENI", label: "SĂCĂLĂȘENI" },
  { value: "SĂCEL", label: "SĂCEL" },
  { value: "SĂLIȘTE", label: "SĂLIȘTE" },
  { value: "SĂLIȘTEA DE SUS", label: "SĂLIȘTEA DE SUS" },
  { value: "SĂLNIȚA", label: "SĂLNIȚA" },
  { value: "SĂLSIG", label: "SĂLSIG" },
  { value: "SALTA", label: "SALTA" },
  { value: "SĂPÂNȚA", label: "SĂPÂNȚA" },
  { value: "SARASĂU", label: "SARASĂU" },
  { value: "SÂRBI / BUDEȘTI", label: "SÂRBI / BUDEȘTI" },
  { value: "SÂRBI / FĂRCAȘA", label: "SÂRBI / FĂRCAȘA" },
  { value: "SĂSAR", label: "SĂSAR" },
  { value: "SAT-ȘUGATAG", label: "SAT-ȘUGATAG" },
  { value: "SATU NOU DE JOS", label: "SATU NOU DE JOS" },
  { value: "SATU NOU DE SUS", label: "SATU NOU DE SUS" },
  { value: "SATULUNG", label: "SATULUNG" },
  { value: "SEINI", label: "SEINI" },
  { value: "ȘIEU", label: "ȘIEU" },
  { value: "SIGHETU MARMAȚIEI", label: "SIGHETU MARMAȚIEI" },
  { value: "ȘINDREȘTI", label: "ȘINDREȘTI" },
  { value: "ȘISEȘTI", label: "ȘISEȘTI" },
  { value: "SLĂTIOARA", label: "SLĂTIOARA" },
  { value: "ȘOMCUTA MARE", label: "ȘOMCUTA MARE" },
  { value: "SOMEȘ-UILEAC", label: "SOMEȘ-UILEAC" },
  { value: "STEJERA", label: "STEJERA" },
  { value: "STOICENI", label: "STOICENI" },
  { value: "STRÂMBU-BĂIUȚ", label: "STRÂMBU-BĂIUȚ" },
  { value: "STRÂMTURA", label: "STRÂMTURA" },
  { value: "STREMȚ", label: "STREMȚ" },
  { value: "SUCIU DE JOS", label: "SUCIU DE JOS" },
  { value: "SUCIU DE SUS", label: "SUCIU DE SUS" },
  { value: "ȘUGĂU", label: "ȘUGĂU" },
  { value: "ȘURDEȘTI", label: "ȘURDEȘTI" },
  { value: "TĂMAIA", label: "TĂMAIA" },
  { value: "TĂMĂȘEȘTI", label: "TĂMĂȘEȘTI" },
  { value: "TÂRGU LĂPUȘ", label: "TÂRGU LĂPUȘ" },
  { value: "TĂUȚII DE SUS", label: "TĂUȚII DE SUS" },
  { value: "TĂUȚII-MĂGHERĂUȘ", label: "TĂUȚII-MĂGHERĂUȘ" },
  { value: "TECEU MIC", label: "TECEU MIC" },
  { value: "ȚICĂU", label: "ȚICĂU" },
  { value: "TISA", label: "TISA" },
  { value: "TOHAT", label: "TOHAT" },
  { value: "TRESTIA", label: "TRESTIA" },
  { value: "TULGHIEȘ", label: "TULGHIEȘ" },
  { value: "ULMENI", label: "ULMENI" },
  { value: "ULMOASA", label: "ULMOASA" },
  { value: "UNGURAȘ", label: "UNGURAȘ" },
  { value: "UNGURENI", label: "UNGURENI" },
  { value: "URMENIȘ", label: "URMENIȘ" },
  { value: "VAD", label: "VAD" },
  { value: "VADU IZEI", label: "VADU IZEI" },
  { value: "VALEA CHIOARULUI", label: "VALEA CHIOARULUI" },
  { value: "VALEA CUFUNDOASĂ", label: "VALEA CUFUNDOASĂ" },
  { value: "VALEA HOTARULUI", label: "VALEA HOTARULUI" },
  { value: "VALEA NEAGRĂ", label: "VALEA NEAGRĂ" },
  { value: "VALEA STEJARULUI", label: "VALEA STEJARULUI" },
  { value: "VALEA VIȘEULUI", label: "VALEA VIȘEULUI" },
  { value: "VĂLENI", label: "VĂLENI" },
  { value: "VĂLENII LĂPUȘULUI", label: "VĂLENII LĂPUȘULUI" },
  { value: "VĂLENII ȘOMCUTEI", label: "VĂLENII ȘOMCUTEI" },
  { value: "VĂRAI", label: "VĂRAI" },
  { value: "VICEA", label: "VICEA" },
  { value: "VIILE APEI", label: "VIILE APEI" },
  { value: "VIMA MARE", label: "VIMA MARE" },
  { value: "VIMA MICĂ", label: "VIMA MICĂ" },
  { value: "VIȘEU DE JOS", label: "VIȘEU DE JOS" },
  { value: "VIȘEU DE MIJLOC", label: "VIȘEU DE MIJLOC" },
  { value: "VIȘEU DE SUS", label: "VIȘEU DE SUS" },
];
