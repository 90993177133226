import { ExpertType } from "../enums/ExpertType";

export const ExpertTypeSelectItems = [
    { label: "Partener", value: ExpertType.Partener },
    { label: "Solicitant", value: ExpertType.Solicitant },
    { label: "Responsabil centru virtual", value: ExpertType.RCV },
    { label: "Responsabil formare", value: ExpertType.ResponsabilFormare },
    { label: "Expert comunicare", value: ExpertType.ExpertComunicare },
    { label: "Expert mediere", value: ExpertType.ExpertMediere },
    { label: "Responsabil GT", value: ExpertType.ResponsabilGT },
];
