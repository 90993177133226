import { Grid } from "@mui/material"
import AutocompleteSearch from "components/shared/autocompleteSearch/AutocompleteSearch";
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router";
import { CompanyUserBriefModel, requestCompanyUsersNames, selectCompanyUsersNames, selectCurrentUser, selectSelectedUser, useAppDispatch, useAppSelector } from "store";
import { DocumentType } from "store/models/enums/DocumentType";
import { UserType } from "store/models/enums/UserType";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { setSelectedUser } from "store/slices/grantContestSlice";
import { setDocuments } from "store/slices/manageDocumentsSlice";
import { mapManageDocumentsTabstoEnum } from "utils/mapManageDocumentsTabstoEnum";
export const UsersSelector: FC<{ documentCategory: DocumentType }> = ({ documentCategory }) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectCurrentUser);
  const companyUsersNames = useAppSelector(selectCompanyUsersNames);
  const selectedUser = useAppSelector(selectSelectedUser);
  const [displayAutocompleteDropdown, setDisplayAutocompleteDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (selectedUser !== null) {
      navigate(`/monitorizare/${mapManageDocumentsTabstoEnum(documentCategory)}/${selectedUser.userId}`)
    }
    if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) {
      dispatch(requestCompanyUsersNames({
        filterByWonGrant: true,
        filterByRegistrationNumber: true,
        token: currentUser?.jwtToken
      })).then((response: any) => {
        if (localStorage.getItem('userId')) {
          response.payload.forEach((user: any) => {
            if (user.userId === parseInt(localStorage.getItem('userId') ?? "0")) {
              dispatch(setSelectedUser({
                userId: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                isGrantApproved: user.isGrantApproved
              }))
            }
          })
        }

      })
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedUser !== null) {
      localStorage.setItem('userId', JSON.stringify(selectedUser.userId));
    } else {
      if (!localStorage.getItem('userId')) {
        dispatch(setDocuments([]))
      }

    }
    //eslint-disable-next-line
  }, [selectedUser])


  const handleSelectUser = (e: any, values: any) => {
    if (values !== null && values !== undefined && typeof values !== "string") {
      setDisplayAutocompleteDropdown(false)
      dispatch(setSelectedUser({
        userId: values.userId,
        firstName: values.firstName,
        lastName: values.lastName,
        isGrantApproved: values.isGrantApproved
      }));
      navigate(`/monitorizare/${mapManageDocumentsTabstoEnum(documentCategory)}/${values.userId}`)
    }
    if (typeof values === "string" && e.key === "Enter") {
      let usersFound = 0;
      let userFound: CompanyUserBriefModel = {}

      for (let user of companyUsersNames) {
        if ((user.lastName + " " + user.firstName).toLowerCase().includes(values.toLowerCase())) {
          usersFound++;
          userFound = {
            userId: user.userId,
            lastName: user.lastName,
            firstName: user.firstName,
            isGrantApproved: user.isGrantApproved,
          };
        }
      }
      if (usersFound === 0) {
        setDisplayAutocompleteDropdown(false)
        dispatch(addAppNotification({
          message: "Nu a fost găsit nici un rezultat pentru căutarea dorită!",
          severity: "warning",
        }))
      }
      if (usersFound === 1) {
        setDisplayAutocompleteDropdown(false)
        dispatch(setSelectedUser({
          userId: userFound.userId,
          firstName: userFound.firstName,
          lastName: userFound.lastName,
          isGrantApproved: userFound.isGrantApproved
        }));
      }
      if (usersFound > 1) {
        dispatch(addAppNotification({
          message: "Selectați unul dintre utilizatori!",
          severity: "warning",
        }))
      }
    }
  };
  const onInputChange = (event: any, value: any) => {
    if (event !== null) {
      if (value === "" && event.type === 'change') {
        localStorage.removeItem('userId')
        dispatch(setSelectedUser(null));
        navigate(`/monitorizare/${mapManageDocumentsTabstoEnum(documentCategory)}/${currentUser?.userId}`)
      }
      if (event.key === "Enter") {
        handleSelectUser(event, selectedUser)
      }
    }

  }
  const onKeyPress = (event: any, value: any) => {
    if (event.key === "Enter") {
      handleSelectUser(event, event.target.value)
    }
  }

  const getOptionLabel = (user: any): string => {
    return (user.lastName || "") + (user.lastName ? " " : "") + (user.firstName || "");
  }

  return (
    <Grid item sx={{ paddingTop: 1 }}>
      <AutocompleteSearch
        width={175}
        options={companyUsersNames}
        value={selectedUser}
        getOptionLabel={getOptionLabel}
        renderOption={(props: any, option: any) => {
          return (
            <li {...props} key={option.userId}>
              {option.lastName + " " + option.firstName}
            </li>
          );
        }}
        onType={handleSelectUser}
        isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
        onInputChange={onInputChange}
        onKeyPress={onKeyPress}
        open={displayAutocompleteDropdown}
        onOpen={(e: any) => { setDisplayAutocompleteDropdown(true) }}
        onClose={(e: any) => { setDisplayAutocompleteDropdown(false) }}
      />
    </Grid >
  )
}