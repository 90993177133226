import { JobPostsDataSort } from "../enums/JobPostsDataSort";

export const jobsSortItems = [
  { label: "Email", value: JobPostsDataSort.Email },
  { label: "Cod CAEN", value: JobPostsDataSort.CaenCode },
  { label: "Nume companie", value: JobPostsDataSort.CompanyName },
  { label: "Județ", value: JobPostsDataSort.County },
  { label: "Localitate", value: JobPostsDataSort.Locality },
  { label: "Data adăugării", value: JobPostsDataSort.UploadTime },
  { label: "Titlu job", value: JobPostsDataSort.JobTitle },
];
