import { DashboardCustomize, Folder, Home } from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { administrationPath, homePath, modulesPath } from "navigation";
import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store";
import { AuthenticationModel } from "store/models/authModel/AuthenticationModel";
import {
  setCurrentUser,
  setIsRefreshing,
  setIsSilentRefresh,
} from "store/slices/authSlice";
import { setDisplayNav } from "store/slices/baseSlice";
import { setSelectedUser } from "store/slices/grantContestSlice";
import { requestRefreshToken } from "store/thunks/authenticationThunks";
import { isAdmin, isCompany, isExpert } from "utils/getUserType";
import "./NavigationMenuComponent.scss";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { selectShowContest } from "store/selectors/manageDocumentsSelectors";

const NavigationMenuComponent: FC = () => {
  const displayNavMenu = useAppSelector((state) => state.base.displayNavMenu);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();
  const showContest = useAppSelector(selectShowContest);

  const handleTokenRefresh = useCallback(
    function (isSilent: boolean) {
      const localStorageToken = localStorage.getItem("user");

      if (localStorageToken) {
        if (isSilent) dispatch(setIsSilentRefresh(true));
        const localStorageTokenParsed = JSON.parse(localStorageToken);
        setCurrentUser({
          ...currentUser,
          jwtToken: localStorageTokenParsed,
        } as AuthenticationModel);
        dispatch(requestRefreshToken(localStorageTokenParsed));
      } else {
        dispatch(setIsRefreshing(false));
      }
    },
    [dispatch, currentUser]
  );

  const handleConcursPlanDeAfaceriClick = () => {
    if (localStorage.getItem("userId")) {
      dispatch(setSelectedUser(null));
      localStorage.removeItem("userId");
    }

    navigate("/planafaceri/" + currentUser?.userId);
    dispatch(setDisplayNav(false))
  };

  const handleAdministrationClick = () => {
    navigate(administrationPath);
    dispatch(setDisplayNav(false))
  };

  useEffect(() => {
    const interval = setInterval(handleTokenRefresh, 1000 * 60 * 14, true);
    handleTokenRefresh(false);
    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  return (
    <div className="nav-menu-component">
      <Drawer
        anchor="left"
        open={displayNavMenu}
        onClose={() => dispatch(setDisplayNav(false))}
      >
        <Box sx={{ width: 300, }}>
          <List>
            <ListItem button onClick={() => {
              dispatch(setDisplayNav(false))
              navigate(homePath)
            }}>
              <ListItemIcon>
                <Home color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Acasă"></ListItemText>
            </ListItem>
            <ListItem button onClick={() => {
              dispatch(setDisplayNav(false))
              navigate(modulesPath)
            }}>
              <ListItemIcon>
                <ViewModuleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Module"></ListItemText>
            </ListItem>
            {(isAdmin(currentUser?.userType) || isExpert(currentUser?.userType) ||
              isCompany(currentUser?.userType)) && showContest && <ListItem button onClick={handleConcursPlanDeAfaceriClick}>
                <ListItemIcon>
                  <Folder color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Concurs plan de afaceri"></ListItemText>
              </ListItem>}
            {(isAdmin(currentUser?.userType)) && (
              <ListItem button onClick={handleAdministrationClick}>
                <ListItemIcon>
                  <DashboardCustomize color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Administrare"></ListItemText>
              </ListItem>
            )}

          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default NavigationMenuComponent;
