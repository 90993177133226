import { RootState } from "store";

export const selectLocalization = (state:RootState):any => state.app.localization;
export const selectSharedLocalization = (state:RootState):any=>state.app.localization.shared;
export const selectAdministrationPageLocalization = (state:RootState):any => state.app.localization.administrationPage;
export const selectCommunicationPageLocalization = (state:RootState):any =>state.app.localization.communicationPage;
export const selectForgotPasswordPageLocalization = (state:RootState):any=>state.app.localization.forgotPasswordPage;
export const selectGrantContestPageLocalization = (state:RootState):any=>state.app.localization.grantContestPage;
export const selectHomePageLocalization =(state:RootState):any=>state.app.localization.homePage;
export const selectLoginPageLocalization=(state:RootState):any=>state.app.localization.loginPage;
export const selectManageDocumentsPageLocalization=(state:RootState):any=>state.app.localization.manageDocumentsPage;
export const selectResetPasswordPageLocalization=(state:RootState):any=>state.app.localization.resetPasswordPage;
export const selectUserDetailsPageLocalization=(state:RootState):any=>state.app.localization.userDetailsPage;
export const selectUsersPageLocalization=(state:RootState):any=>state.app.localization.usersPage;
export const selectVocationalTrainingPageLocalization=(state:RootState):any=>state.app.localization.vocationalTrainingPage;
export const selectCounselingPageLocalization = (state:RootState):any=>state.app.localization.counselingPage;
export const selectMediationPageLocalization= (state:RootState):any=>state.app.localization.mediationPage;