import { CloseRounded } from "@mui/icons-material";
import { Grid, IconButton, MenuItem, TextField } from "@mui/material";
import { FC } from "react";
import "./ItemsSelector.scss";

interface ItemsSelectorProps {
  value: any;
  label: string;
  removeFilterDeleteIcon: boolean;
  removeFilter: Function;
  items: any[];
  setValue: any;
  disabled?: boolean;
  variant?: "standard" | "filled" | "outlined" | undefined;
}

export const ItemsSelector: FC<ItemsSelectorProps> = (props) => {
  const getSelectValue = () => {
    return (
      <Grid className="select-value">
        {!props.removeFilterDeleteIcon && (
          <IconButton
            onClick={(e) => {
              props.removeFilter();
            }}
          >
            <CloseRounded color="error"></CloseRounded>
          </IconButton>
        )}
      </Grid>
    );
  };

  const getValue = () => {
    if (
      props.value === null ||
      props.value === undefined ||
      props.value === 0 ||
      Number.isNaN(props.value)
    )
      return "";
    return props.value;
  };

  return (
    <TextField
      select
      disabled={props.disabled}
      InputProps={{
        endAdornment: getSelectValue(),
        sx: { maxHeight: 29 },
      }}
      fullWidth
      size="small"
      label={props.label}
      value={getValue()}
      onChange={(e) => props.setValue(e)}
      variant={props.variant ?? "standard"}
      SelectProps={{
        MenuProps: {
          disableScrollLock: true,
          PaperProps: { sx: { maxHeight: 300 } },
        },
      }}
    >
      {props.items?.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
