import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import "./HeaderComponent.scss";
import { setDisplayNav } from "store/slices/baseSlice";
import { useAppDispatch, useAppSelector } from "store";
import { AccountCircle, Lock, ManageAccounts } from "@mui/icons-material";
import React, { FC, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { requestLogout } from "store/thunks/authenticationThunks";
import { useNavigate } from "react-router";
import cei_smart from "../../../assets/images/cei_smart.png";
import smis from "../../../assets/images/logo_smis.png";
import xerom from "../../../assets/images/logo-xerom-contact.png";
import { homePath, modulesPath } from "navigation";

const HeaderComponent: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const profileMenuAnchor = useRef<HTMLButtonElement>(null);

  const currentUser = useAppSelector((state) => state.auth.currentUser);

  return (
    <Toolbar variant="dense" className="toolbar">
      {currentUser && (
        <React.Fragment>
          <Grid
            container
            columns={{ xs: 12 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container className="header-part" item xs={4}>
              <Grid item xs={6} sx={{ height: "100%" }}>
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  sx={{ mr: 2, color: "white" }}
                  onClick={() => {
                    dispatch(setDisplayNav(true));
                  }}
                >
                  <MenuIcon fontSize="large" className="function-icon" />
                </IconButton>
              </Grid>
              <Grid className="left-logos" item xs={6}>
                <div
                  onClick={() => navigate(homePath)}
                  className="logo"
                  style={{
                    height: "50%",
                    width: "50%",
                    backgroundImage: `url(${xerom})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                  }}
                ></div>
              </Grid>
            </Grid>
            <Grid
              container
              className="header-part"
              id="header-title"
              item
              xs={4}
              sx={{ height: "100px" }}
            >
              <Box
                component="div"
                onClick={() => navigate(homePath)}
                className="logo"
                sx={{
                  height: "100px",
                  width: "100%",
                  backgroundImage: `url(${smis})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
              ></Box>
            </Grid>
            <Grid container className="header-part" item xs={4}>
              <Grid
                item
                sx={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                xs={6}
              >
                <div
                  onClick={() => navigate(homePath)}
                  style={{
                    height: "50%",
                    width: "50%",
                    backgroundImage: `url(${cei_smart})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                  }}
                ></div>
              </Grid>
              <Grid item xs={6} sx={{ height: "100%" }} className="icon-menu">
                <Button
                  sx={{ mr: 2 }}
                  className="modules-button"
                  onClick={() => {
                    navigate(modulesPath);
                  }}
                >
                  Module
                </Button>
                <IconButton
                  ref={profileMenuAnchor}
                  sx={{ color: "white" }}
                  size="large"
                  onClick={() => setDisplayMenu(true)}
                >
                  <AccountCircle fontSize="large" className="function-icon" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Menu
            open={displayMenu}
            anchorEl={profileMenuAnchor.current}
            onClose={() => setDisplayMenu(false)}
          >
            <MenuItem
              onClick={() => {
                setDisplayMenu(false);
                navigate(`/utilizator/${currentUser.userId}`);
              }}
            >
              <ListItemIcon>
                <ManageAccounts color="secondary" />
              </ListItemIcon>
              <ListItemText>Profilul meu</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDisplayMenu(false);
                dispatch(requestLogout(currentUser?.jwtToken ?? "")).then(
                  () => {
                    navigate(homePath);
                  }
                );
              }}
            >
              <ListItemIcon>
                <Lock color="secondary" />
              </ListItemIcon>
              <ListItemText>Log out</ListItemText>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </Toolbar>
  );
};

export default HeaderComponent;
