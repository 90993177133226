import { Button, Container, Grid, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { DocumentType } from "store/models/enums/DocumentType";
import {
  addNewGroupOfDeconturi,
  addNewEmptyDosarAchizitii,
  deleteDocument,
  deleteSolicitareModificari,
  downloadDocument,
  getAllCategoryDocuments,
  rejectDocument,
  selectCurrentUser,
  unrejectDocument,
  unvalidateDocument,
  uploadAnexa,
  uploadDocument,
  useAppDispatch,
  useAppSelector,
  validateDocument,
  rejectSolicitare,
  rejectDecont,
  unrejectSolicitare,
  unrejectDecont,
  validateDecont,
  unvalidateDecont,
  deleteGroupOfDeconturi,
  deleteDecont,
  uploadDecontCheltuieli,
  updateDecont,
  deleteDosarAchizitii,
  deleteGroupSolicitareModificari,
  addNewSolicitariGroup,
  uploadSolicitareModificari,
  updateSolicitareModificari,
  validateSolicitareModificari,
  unvalidateSolicitareModificari,
  generateDecontValidationNote,
  generateSolicitareValidationNote,
  generateAchizitiiValidationNote,
  generateAnexa1Solicitare,
} from "store";
import {
  selectActionNode,
  selectDocuments,
  selectDocumentsIsLoading,
  selectExpandedKeys,
  selectNewDecontData,
  selectNewRequestData,
} from "store/selectors/manageDocumentsSelectors";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import {
  addNewDecontCheltuieli,
  addRequestInputsNode,
  deleteDecontCheltuieli,
  removeNewRequest,
  setActionNode,
  setDecontSubmitStatus,
  setExpandedKeys,
  setNewDecontData,
  setSimpleExpandKey,
  setSolicitareSubmitStatus,
} from "store/slices/manageDocumentsSlice";
import { isNotNullOrUndefined, isNullOrUndefined } from "utils";
import { UserType } from "store/models/enums/UserType";
import { Subcategory } from "store/models/enums/Subcategory";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { Add, Check, Clear, Download, Edit, Help } from "@mui/icons-material";
import { FileUploadPopout } from "components";
import {
  mapSubcategoryToEnum,
  mapSubcategoryToLabel,
} from "utils/mapManageDocumentsTabstoEnum";
import "./Documents.scss";
import { CaseFileDialogComponent } from "pages/manageDocuments-page/caseFileDialogComponent/CaseFileDialogComponent";
import { RejectDialog } from "../rejectDialog/RejectDialog";
import { InputTableDecontCheltuieliComponent } from "pages/manageDocuments-page/inputTableDecontCheltuieliComponent/InputTableDecontCheltuieliComponent";
import { DeleteConfirmationDialog } from "../deleteConfirmationDialog/DeleteConfirmationDialog";
import { ManageDocumentFiles } from "../manageDocumentFiles/ManageDocumentFiles";
import { InputTableSolicitareModificari } from "../inputTableSolicitareModificari/InputTableSolicitareModificari";
import { ValidationNoteDialog } from "../validationNoteDialog/ValidationNoteDialog";

export const Documents: FC<{
  userId: number | undefined;
  documentCategory: DocumentType;
}> = ({ userId, documentCategory }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const documents = useAppSelector(selectDocuments);
  const documentsIsLoading = useAppSelector(selectDocumentsIsLoading);
  const expandedKeys = useAppSelector(selectExpandedKeys);
  const [isAddingNewDecont, setIsAddingNewDecont] = useState<boolean>(false);
  const [newDecontFiles, setNewDecontFiles] = useState<any>([]);
  const [decontCurrentFiles, setDecontCurrentFiles] = useState<any>([]);
  const [newSolicitareFiles, setNewSolicitareFiles] = useState<any>([]);
  const [solicitareCurrentFiles, setSolicitareCurrentFiles] = useState<any>([]);
  const [displayRejectDialog, setDisplayRejectDialog] = useState(false);
  const [displayConfirmDateDialog, setDisplayConfirmDateDialog] =
    useState(false);
  const [rejectDocumentData, setRejectDocumentData] = useState<any>(null);
  const [editedInputsId, setEditedInputsId] = useState(null);
  const [displayFileManagerDialog, setDisplayFileManagerDialog] =
    useState(false);
  const [fileManagerDialogDisplayMode, setFileManagerDialogDisplayMode] =
    useState("view");
  const [
    decontFileManagerDialogDisplayMode,
    setDecontFileManagerDialogDisplayMode,
  ] = useState("view");
  const [decontFileManagerDialogDisplay, setDecontFileManagerDialogDisplay] =
    useState(false);
  const [displayDosarAchizitiiNameDialog, setDisplayDosarAchizitiiNameDialog] =
    useState(false);
  const [displayRequestNameDialog, setDisplayRequestNameDialog] =
    useState(false);
  const [requestNameDialogData, setRequestNameDialogData] = useState<any>({});
  const [validationNoteNode, setValidationNoteNode] = useState(null);
  const [regNrRequired, setRegNrRequired] = useState(false);
  const [dialogAnexaNr, setDialogAnexaNr] = useState(null);
  const [displayDecontNameDialog, setDisplayDecontNameDialog] =
    useState<any>(false);
  const [displayFileUploadDialog, setDisplayFileUploadDialog] =
    useState<boolean>(false);
  const [
    displayAnexaAchizitiiUploadDialog,
    setDisplayAnexaAchizitiiUploadDialog,
  ] = useState<boolean>(false);
  const [currentNode, setCurrentNode] = useState<any>(null);
  const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] =
    useState<boolean>(false);
  const newDecontData = useAppSelector(selectNewDecontData);
  const newRequestData = useAppSelector(selectNewRequestData);
  const [confirmCancelActionDialog, setConfirmCancelActionDialog] =
    useState<boolean>(false);
  const [confirmCancelAction, setConfirmCancelAction] = useState<Function>();
  const actionNode = useAppSelector(selectActionNode);

  useEffect(() => {
    if (
      userId !== undefined ||
      currentUser?.userType === UserType.COMPANY_USER
    ) {
      dispatch(
        getAllCategoryDocuments({
          documentCategory: documentCategory,
          userId: userId ? userId : currentUser?.userId,
          token: currentUser?.jwtToken,
        })
      );
    }
    //eslint-disable-next-line
  }, [userId, documentCategory]);

  useEffect(() => {
    //eslint-disable-next-line
  }, [actionNode]);

  const showFileUploadDialog = (node: any) => {
    setDisplayFileUploadDialog(true);
    setCurrentNode(node);
  };

  const hideFileUploadDialog = () => {
    setDisplayFileUploadDialog(false);
    setCurrentNode(null);
  };

  const showAnexaAchizitiiUploadDialog = (node: any) => {
    setDisplayAnexaAchizitiiUploadDialog(true);
    setCurrentNode(node);
  };

  const hideAnexaAchizitiiUploadDialog = () => {
    setDisplayAnexaAchizitiiUploadDialog(false);
    setCurrentNode(null);
  };

  const showDeleteConfirmDialog = (data: any) => {
    setDisplayDeleteConfirmDialog(true);
    setCurrentNode(data);
  };
  const hideDeleteConfirmDialog = () => {
    setDisplayDeleteConfirmDialog(false);
    setCurrentNode(null);
  };

  const showFiles = (node: any) => {
    if (isAddingNewDecont) {
      switch (node.data.category) {
        case "decontCheltuieli":
          setConfirmCancelActionDialog(true);
          setConfirmCancelAction(() => handleShowFilesWhenAddingDecont);
          break;
        case "solicitareModificari":
          setConfirmCancelActionDialog(true);
          setConfirmCancelAction(() => handleShowFilesWhenAddingRequest);
      }
    } else {
      switch (node.data.category) {
        case "decontCheltuieli":
          setDecontFileManagerDialogDisplay(true);
          setDecontFileManagerDialogDisplayMode("view");
          setDecontCurrentFiles([...node.data.decontFiles]);
          setNewDecontFiles([]);
          break;
        case "solicitareModificari":
          setDisplayFileManagerDialog(true);
          setIsAddingNewDecont(false);
          setSolicitareCurrentFiles([...node.data.requestFiles]);
          setFileManagerDialogDisplayMode("view");
          setNewSolicitareFiles([]);
      }
    }
  };

  const handleShowFilesWhenAddingDecont = (node: any) => {
    dispatch(deleteDecontCheltuieli(newDecontData));
    setIsAddingNewDecont(false);
    setDecontFileManagerDialogDisplay(true);
    setDecontFileManagerDialogDisplayMode("view");
    setDecontCurrentFiles([...node.data.decontFiles]);
    setNewDecontFiles([]);
    setConfirmCancelActionDialog(false);
    dispatch(setActionNode(null));
  };
  const handleShowFilesWhenAddingRequest = (node: any) => {
    dispatch(removeNewRequest(newRequestData));
    setIsAddingNewDecont(false);
    setDecontFileManagerDialogDisplay(true);
    setDecontFileManagerDialogDisplayMode("view");
    setSolicitareCurrentFiles([...node.data.requestFiles]);
    setNewSolicitareFiles([]);
    setConfirmCancelActionDialog(false);
    dispatch(setActionNode(null));
  };

  const hideDosarAchizitiiNameDialog = () => {
    setDisplayDosarAchizitiiNameDialog(false);
    setCurrentNode(null);
  };

  const showDosarAchizitiiNameDialog = (node: any, expandKey: any) => {
    setCurrentNode(node);
    setDisplayDosarAchizitiiNameDialog(true);
  };

  const hideRequestNameDialog = () => {
    setDisplayRequestNameDialog(false);
  };
  const hideDecontNameDialog = () => {
    setCurrentNode(null);
    setDisplayDecontNameDialog(false);
  };

  const showDecontNameDialog = (node: any, expandKey: any) => {
    if (isAddingNewDecont) {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleAddNewGroupDeconturi);
    } else {
      handleAddNewGroupDeconturi(node);
    }
  };
  const handleAddNewGroupDeconturi = (node: any) => {
    setCurrentNode(node);
    setDisplayDecontNameDialog(true);
    dispatch(setActionNode(null));
    setConfirmCancelActionDialog(false);
  };

  const showRequestNameDialog = (node: any) => {
    if (isAddingNewDecont) {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleAddRequestGroup);
    } else {
      handleAddRequestGroup(node);
    }
  };

  const handleAddRequestGroup = (node: any) => {
    setRequestNameDialogData({
      yearInt: node.data.yearInt,
      key: node.key,
    });
    setConfirmCancelActionDialog(false);
    setDisplayRequestNameDialog(true);
    dispatch(setActionNode(null));
  };

  const willFileListBePopulated = (
    newFiles: any,
    currentFiles: any,
    newFilesToBeRemoved: any
  ): boolean => {
    let isNewFilesNotEmpty = true;
    let isCurrentFilesListNotEmpty = true;
    if (isNullOrUndefined(newFiles) || newFiles.length === 0)
      isNewFilesNotEmpty = false;
    if (isNotNullOrUndefined(currentFiles)) {
      let persistedFiles = currentFiles.filter(
        (currentFile: any) => !currentFile.willBeRemoved
      );
      let toBeRemovedCounter = 0;
      if (isNotNullOrUndefined(newFilesToBeRemoved)) {
        newFilesToBeRemoved.forEach((file: any) => {
          if (
            persistedFiles.some(
              (persistedFile: any) => persistedFile.objectURL === file.objectURL
            )
          )
            toBeRemovedCounter++;
        });
      }
      if (
        persistedFiles.length === 0 ||
        toBeRemovedCounter === persistedFiles.length
      )
        isCurrentFilesListNotEmpty = false;
    } else {
      isCurrentFilesListNotEmpty = false;
    }
    return isNewFilesNotEmpty || isCurrentFilesListNotEmpty;
  };

  const handleUploadAnexaAchizitii = (files: any) => {
    if (files) {
      let userUploadingId = userId !== undefined ? userId : currentUser?.userId;
      dispatch(
        uploadAnexa({
          requestData: {
            companyUserId: userUploadingId!,
            yearCategory: currentNode.data.yearInt,
            documenteDosarAchizitie: files,
            dosarAchizitiiId: currentNode.data.dosarAchizitiiId
              ? currentNode.data.dosarAchizitiiId
              : null,
            anexaNumber: currentNode.data.anexaNumber,
          },
          token: currentUser?.jwtToken,
        })
      );
    }
  };

  const handleAddNewAquisitionFolder = (payload: any) => {
    dispatch(
      addNewEmptyDosarAchizitii({
        requestData: {
          userId: userId ? userId : currentUser?.userId,
          yearCategory: currentNode.data.yearInt,
          dosarName: payload.dosarName,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDosarAchizitiiNameDialog();
    });
  };

  const handleEditInputs = (node: any) => {
    if (editedInputsId !== null) {
      switch (node.data.category) {
        case "decontCheltuieli":
          setEditedInputsId(node.data.decontId);
          setDecontCurrentFiles([...node.data.decontFiles]);
          setNewDecontFiles([]);
          break;
        case "solicitareModificari":
          setEditedInputsId(node.data.id);
          setSolicitareCurrentFiles([...node.data.requestFiles]);
          setNewSolicitareFiles([]);
      }
    } else {
      switch (node.data.category) {
        case "decontCheltuieli":
          setEditedInputsId(node.data.decontId);
          setDecontCurrentFiles([...node.data.decontFiles]);
          setNewDecontFiles([]);
          break;
        case "solicitareModificari":
          setEditedInputsId(node.data.id);
          setSolicitareCurrentFiles([...node.data.requestFiles]);
          setNewSolicitareFiles([]);
          break;
      }
    }
  };

  const getTemplateExplanation = (subCategoryInt: any) => {
    switch (subCategoryInt) {
      case "Procedură achiziții":
        return "Procedura descrie etapele și pașii pe care trebuie să îi parcurgă beneficiarii de ajutor de minimis în vederea realizării achizițiilor prevăzute în cadrul planurilor de afaceri.";
      case "Formulare achiziții":
        return "Instrumentele de lucru prezentate mai jos fac parte din procedura de lucru aferentă proiectului. În vederea validarii dosarului de achiziție se vor completa toate formularele atașate.";
      case "Procedură contract":
        return "Procedura stabilește modul în care se poate realiza modificarea contractului de subvenție de către beneficiarii ajutorului de minimis din cadrul proiectului.";
      case "Formulare contract":
        return "Instrumentele de lucru prezentate mai jos fac parte din procedura de modificare a contractului de subvenție aferentă proiectului. În vederea validarii modificărilor solicitate se vor completa toate formularele atașate.";
      default:
        return "Formulare";
    }
  };
  const nameToYear = (name: any): string => {
    switch (name) {
      case "an2023":
        return "2023";
      case "an2024":
        return "2024";
      case "an2025":
        return "2025";
    }
    return "";
  };

  const getDisplayName = (node: any) => {
    let overlayRef = React.createRef<any>();
    let name = node.data.name;
    let bodyTemplate: any;
    switch (node.data.nodeType) {
      case "year":
        bodyTemplate = <span>{nameToYear(name)}</span>;
        break;
      case "templates":
        bodyTemplate = <span>Documente ajutătoare</span>;
        break;
      case "month":
        bodyTemplate = <span>{name}</span>;
        break;
      case "subCategory":
        if (node.data.year === "templates") {
          bodyTemplate = (
            <Grid
              sx={{
                display: "inline-flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <span className="p-mr-1">{mapSubcategoryToLabel(name)}</span>
              <Tooltip title={getTemplateExplanation(node.data.subcategoryInt)}>
                <Help onClick={(event) => overlayRef.current.toggle(event)} />
              </Tooltip>
            </Grid>
          );
        } else {
          bodyTemplate = <span>{mapSubcategoryToLabel(name)}</span>;
        }
        break;
      default:
        bodyTemplate = <span>{name}</span>;
    }
    return bodyTemplate;
  };

  const downloadFile = (documentId: any) => {
    dispatch(
      downloadDocument({
        requestData: {
          id: documentId,
        },
        token: currentUser?.jwtToken,
      })
    );
  };

  const uploadFile = (files: Array<File>, removeAllFilesCallback: Function) => {
    if (files) {
      let userUploadingId = userId !== undefined ? userId : currentUser?.userId;
      dispatch(
        uploadDocument({
          requestData: {
            companyUserId: userUploadingId!,
            documentType: documentCategory,
            subcategory: mapSubcategoryToEnum(currentNode.data.category),
            monthCategory:
              currentNode.data.year !== "templates"
                ? currentNode.data.monthInt
                : 0,
            yearCategory: currentNode.data.yearInt
              ? currentNode.data.yearInt
              : 0,
            fileToUpload: files,
            notaValidareDosarId: currentNode.data.dosarAchizitiiId
              ? currentNode.data.dosarAchizitiiId
              : null,
            notaValidareSolicitareId: currentNode.data.requestGroupId
              ? currentNode.data.requestGroupId
              : null,
            notaValidareDecontId: currentNode.data.decontGroupId
              ? currentNode.data.decontGroupId
              : null,
          },
          token: currentUser?.jwtToken,
        })
      );
    }
  };

  const refreshDocumentsPage = () => {
    dispatch(
      getAllCategoryDocuments({
        documentCategory: documentCategory,
        userId: userId !== undefined ? userId : currentUser?.userId,
        token: currentUser?.jwtToken,
      })
    );
    setIsAddingNewDecont(false);
    setNewSolicitareFiles([]);
    setSolicitareCurrentFiles([]);
  };

  const checkIfAtLeastOneFile = (node: any): boolean => {
    let hasFiles = false;
    node.children.forEach((anexa: any) => {
      if (anexa.children.length !== 0) {
        hasFiles = true;
      }
    });
    return hasFiles;
  };

  const deleteSimpleDocument = (node: any) => {
    if (!node.data) return;
    dispatch(
      deleteDocument({
        requestData: {
          id: node.data.documentId,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDeleteConfirmDialog();
    });
  };

  const deleteDocumentWithConfirmation = (payload: any) => {
    switch (payload.data.category) {
      case "decontCheltuieli":
        if (payload.data.nodeType === "document") deleteDecontRow(payload);
        if (payload.data.nodeType === "decontGroup") deleteDecontGroup(payload);
        break;
      case "dosareAchizitii":
        handleDeleteDosarAchizitii(payload);
        break;
      case "solicitareModificari":
        if (payload.data.nodeType === "requestGroup")
          deleteRequestGroup(payload.data.id);
        if (payload.data.nodeType === "document")
          handleDeleteSolicitareModificari(payload.data.id);
        break;
      default:
        deleteSimpleDocument(payload);
        break;
    }
  };

  const deleteDecontRow = (node: any) => {
    if (!node.data) return;
    dispatch(
      deleteDecont({
        requestData: {
          id: node.data.decontId,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDeleteConfirmDialog();
    });
  };

  const handleDeleteDosarAchizitii = (node: any) => {
    dispatch(
      deleteDosarAchizitii({
        requestData: {
          id: node.data.dosarAchizitiiId,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDeleteConfirmDialog();
    });
  };

  const handleValidateDocument = (node: any) => {
    if (!node || !node.data) return;
    dispatch(
      validateDocument({
        requestData: {
          id: node.data.documentId,
        },
        token: currentUser?.jwtToken,
      })
    );
  };

  const unValidateDocument = (node: any) => {
    if (!node || !node.data) return;
    setConfirmCancelActionDialog(true);
    setConfirmCancelAction(() => handleUnvalidateDocument);
  };

  const handleUnvalidateDocument = (node: any) => {
    dispatch(
      unvalidateDocument({
        requestData: {
          id: node.data.documentId,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      setConfirmCancelActionDialog(false);
    });
  };

  const showRejectDialog = (node: any) => {
    setRejectDocumentData(node.data);
    setDisplayRejectDialog(true);
  };

  const hideRejectDialog = () => {
    setDisplayRejectDialog(false);
  };
  const hideConfirmDateDialog = () => {
    setDisplayConfirmDateDialog(false);
    setRegNrRequired(false);
    setDialogAnexaNr(null);
  };

  const handleRejectDocument = (payload: any) => {
    let id;
    if (rejectDocumentData !== null) {
      if (rejectDocumentData.nodeType === "document") {
        switch (rejectDocumentData.category) {
          case "decontCheltuieli":
            id = rejectDocumentData.decontId;
            dispatch(
              rejectDecont({
                requestData: {
                  id: id,
                  comment: payload.comment,
                },
                token: currentUser?.jwtToken,
              })
            ).then(() => {
              hideRejectDialog();
            });
            break;
          case "solicitareModificari":
            id = rejectDocumentData.id;
            dispatch(
              rejectSolicitare({
                requestData: {
                  id: id,
                  comment: payload.comment,
                },
                token: currentUser?.jwtToken,
              })
            ).then(() => {
              hideRejectDialog();
            });
            break;
          default:
            id = rejectDocumentData.documentId;
            dispatch(
              rejectDocument({
                requestData: {
                  id: id,
                  comment: payload.comment,
                },
                token: currentUser?.jwtToken,
              })
            ).then(() => {
              hideRejectDialog();
            });
        }
      }
    }
  };

  const handleUnReject = (data: any) => {
    let id;
    if (data.nodeType === "document") {
      switch (data.category) {
        case "decontCheltuieli":
          id = data.decontId;
          dispatch(
            unrejectDecont({
              requestData: {
                id: id,
                comment: "",
              },
              token: currentUser?.jwtToken,
            })
          ).then(() => {
            hideRejectDialog();
          });
          break;
        case "solicitareModificari":
          id = data.id;
          dispatch(
            unrejectSolicitare({
              requestData: {
                id: id,
                comment: "",
              },
              token: currentUser?.jwtToken,
            })
          ).then(() => {
            hideRejectDialog();
          });
          break;
        default:
          id = data.documentId;
          dispatch(
            unrejectDocument({
              requestData: {
                id: id,
                comment: "",
              },
              token: currentUser?.jwtToken,
            })
          ).then(() => {
            hideRejectDialog();
          });
      }
    }
  };

  const downloadValidationNote = ({ node, date, text, anexaNr }: any) => {
    switch (node.data.category) {
      case "decontCheltuieli":
        let documentIds: any = [];
        node.children.forEach((element: any) => {
          if (
            !element.data.isNotSaved &&
            element.data.subcategoryInt !== Subcategory.NOTE_VALIDARE_DECONT
          ) {
            documentIds.push(element.data.decontId);
          }
        });
        dispatch(
          generateDecontValidationNote({
            requestData: {
              decontIds: documentIds,
              registrationDate: date,
              registrationNumber: text,
            },
            token: currentUser?.jwtToken,
          })
        ).then(() => {
          hideConfirmDateDialog();
        });
        break;
      case "solicitareModificari":
        if (isNotNullOrUndefined(anexaNr)) {
          if (anexaNr === 1) {
            downloadAnexa1(node, date, text);
            break;
          } else {
            downloadAnexa2(node, date, text);
            break;
          }
        } else {
          let savedChanges = node.children.filter(
            (modificareNode: any) =>
              !modificareNode.data.isNotSaved &&
              modificareNode.data.subcategoryInt !==
                Subcategory.NOTE_VALIDARE_SOLICITARE
          );
          if (
            savedChanges.some((changeNode: any) => changeNode.data.isValidated)
          ) {
            dispatch(
              generateSolicitareValidationNote({
                requestData: {
                  actAdditionalNo: node.data.id,
                  modificareId: savedChanges?.map(
                    (solicitareNode: any) => solicitareNode.data.id
                  ),
                  registrationDate: date,
                  registrationNumber: text,
                },
                token: currentUser?.jwtToken,
              })
            ).then(() => {
              hideConfirmDateDialog();
            });
            break;
          } else {
            dispatch(
              addAppNotification({
                message: "Este necesară cel puțin o modificare validată!",
                severity: "warning",
              })
            );
            break;
          }
        }
      case "dosareAchizitii":
        dispatch(
          generateAchizitiiValidationNote({
            requestData: {
              dosarAchizitiiId: node.data.dosarAchizitiiId,
              regDate: date,
              nrReg: text,
            },
            token: currentUser?.jwtToken,
          })
        ).then(() => {
          hideConfirmDateDialog();
        });
        break;
      default:
        return;
    }
  };

  const handleValidateDecont = (node: any) => {
    if (!node || !node.data) return;
    if (isAddingNewDecont) {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleDispatchValidateDecont);
    } else {
      handleDispatchValidateDecont(node);
    }
  };
  const handleDispatchValidateDecont = (node: any) => {
    dispatch(
      validateDecont({
        requestData: {
          id: node.data.decontId,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(setActionNode(node));
    });
    setConfirmCancelActionDialog(false);
  };

  const handleUnvalidateDecont = (node: any) => {
    if (!node || !node.data) return;
    if (isAddingNewDecont) {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleDispatchUnValidateDecont);
    } else {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleDispatchUnValidateDecont);
    }
  };
  const handleDispatchUnValidateDecont = (node: any) => {
    dispatch(
      unvalidateDecont({
        requestData: {
          id: node.data.decontId,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(setActionNode(node));
    });
    setConfirmCancelActionDialog(false);
  };
  const handleDeleteDecontCheltuieli = (node: any) => {
    setIsAddingNewDecont(false);
    if (node.data.isNotSaved) {
      dispatch(deleteDecontCheltuieli(newDecontData));
    } else {
      deleteDecontRow(node);
    }
  };

  const isExpert = (): boolean => {
    return (
      isNotNullOrUndefined(currentUser) &&
      currentUser?.userType === UserType.EXPERT
    );
  };

  const isCompanyUser = (): boolean => {
    return currentUser?.userType === UserType.COMPANY_USER;
  };

  const isAdminUser = (): boolean => {
    return currentUser?.userType === UserType.ADMIN;
  };

  const onDocJustificativeUpload = (event: any, node: any) => {
    let newFiles = [...event];
    if (node.data.category === "decontCheltuieli") {
      setNewDecontFiles([...newDecontFiles, ...newFiles]);
    } else {
      setNewSolicitareFiles([...newSolicitareFiles, ...newFiles]);
    }
  };

  const isDocumentResolved = (node: any): boolean => {
    return node.data.isValidated || node.data.isRejected;
  };

  const addRequestGroup = (payload: any) => {
    let userUploadingId;
    if (isAdminUser() || isExpert()) {
      userUploadingId = userId;
    } else {
      userUploadingId = currentUser?.userId;
    }
    dispatch(
      addNewSolicitariGroup({
        requestData: {
          userId: userUploadingId,
          groupName: payload.dosarName,
          yearCategory: requestNameDialogData.yearInt,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      setDisplayRequestNameDialog(false);
    });
    dispatch(setSimpleExpandKey(payload.key));
  };

  const deleteRequestGroup = (id: any) => {
    dispatch(
      deleteGroupSolicitareModificari({
        requestData: {
          id: id,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDeleteConfirmDialog();
    });
  };

  const handleDeleteSolicitareModificari = (id: any) => {
    dispatch(
      deleteSolicitareModificari({
        requestData: {
          id: id,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDeleteConfirmDialog();
    });
  };

  const handleDeleteRequest = (node: any) => {
    setIsAddingNewDecont(false);
    if (node.data.isNotSaved) {
      dispatch(removeNewRequest(node.data));
    }
  };

  const validateRequest = (node: any) => {
    if (isAddingNewDecont) {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleValidateSolicitareModificari);
    } else {
      handleValidateSolicitareModificari(node);
    }
  };

  const handleValidateSolicitareModificari = (node: any) => {
    dispatch(
      validateSolicitareModificari({
        requestData: {
          id: node.data.id,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(setActionNode(null));
    });
    setConfirmCancelActionDialog(false);
  };

  const unValidateRequest = (node: any) => {
    if (isAddingNewDecont) {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleUnValidateSolicitareModificari);
    } else {
      setConfirmCancelActionDialog(true);
      setConfirmCancelAction(() => handleUnValidateSolicitareModificari);
    }
  };
  const handleUnValidateSolicitareModificari = (node: any) => {
    dispatch(
      unvalidateSolicitareModificari({
        requestData: {
          id: node.data.id,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(setActionNode(null));
    });
    setConfirmCancelActionDialog(false);
  };

  const getDownloadButton = (node: any) => {
    let nodeType = node.data.nodeType;
    let category = node.data.category;
    if (category === "executieBugetara" && nodeType === "document")
      return (
        <Grid className="download-buttons-container">
          <Button
            className="doc-action-button"
            endIcon={<Download />}
            variant="contained"
            size="small"
            onClick={() => downloadFile(node.data.fileId)}
          >
            Descarcă documentul
          </Button>
          {!isCompanyUser() && (
            <Button
              id="deleteBtn"
              className="doc-action-button"
              color="error"
              size="small"
              variant="contained"
              onClick={() => showDeleteConfirmDialog(node)}
            >
              <Clear />
            </Button>
          )}
        </Grid>
      );
    if (category === "decontCheltuieli" && nodeType === "document")
      if (node.data.isNotSaved)
        return (
          <Grid className="download-buttons-container">
            <Button
              className="doc-action-button"
              startIcon={<Add />}
              variant="contained"
              size="small"
              onClick={(e) => {
                setDecontFileManagerDialogDisplay(true);
                setDecontFileManagerDialogDisplayMode("add");
              }}
            >
              Adaugă fișier
            </Button>
            <Button
              className="doc-action-button"
              type="button"
              endIcon={<Check />}
              variant="contained"
              size="small"
              onClick={() => dispatch(setDecontSubmitStatus("loading"))}
            >
              Salvează decontul
            </Button>
            <Tooltip title="Șterge">
              <Button
                id="deleteBtn"
                className="doc-action-button"
                color="error"
                size="small"
                variant="contained"
                onClick={() => handleDeleteDecontCheltuieli(node)}
              >
                <Clear />
              </Button>
            </Tooltip>
          </Grid>
        );
      else {
        if (node.data.decontCheltuieliId === editedInputsId) {
          return (
            <Grid className="download-buttons-container">
              <Button
                className="doc-action-button"
                variant="contained"
                size="small"
                onClick={(e) => handleFileManagerDialogEditDecont(node)}
              >
                Schimbă fișierele
              </Button>
              <Button
                className="doc-action-button"
                endIcon={<Check />}
                variant="contained"
                color="warning"
                size="small"
                onClick={() => dispatch(setDecontSubmitStatus("loading"))}
              >
                Actualizează
              </Button>
              <Tooltip title="Oprește editarea">
                <Button
                  id="deleteBtn"
                  className="doc-action-button"
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setEditedInputsId(null);
                    refreshDocumentsPage();
                  }}
                >
                  <Clear />
                </Button>
              </Tooltip>
            </Grid>
          );
        } else {
          return (
            <Grid className="download-buttons-container">
              {!isDocumentResolved(node) ? (
                (isExpert() || isAdminUser()) && (
                  <React.Fragment>
                    <Button
                      className="doc-action-button"
                      endIcon={<Check />}
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => {
                        dispatch(setActionNode(node));
                        handleValidateDecont(node);
                      }}
                    >
                      Validează
                    </Button>
                    <Button
                      className="doc-action-button"
                      endIcon={<Clear />}
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => showRejectDialog(node)}
                    >
                      Respinge
                    </Button>
                  </React.Fragment>
                )
              ) : node.data.isValidated ? (
                <Button
                  className="doc-action-button"
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={() => {
                    dispatch(setActionNode(node));
                    handleUnvalidateDecont(node);
                  }}
                  disabled={isCompanyUser()}
                >
                  Validat
                </Button>
              ) : (
                <Button
                  className="doc-action-button"
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => showRejectDialog(node)}
                >
                  Respins
                </Button>
              )}
              <Button
                className="doc-action-button"
                variant="contained"
                size="small"
                onClick={(e) => {
                  dispatch(setActionNode(node));
                  showFiles(node);
                }}
              >
                Vezi fișierele
              </Button>
              {!isDocumentResolved(node) && !isAddingNewDecont && (
                <Tooltip title="Modifică">
                  <Button
                    className="doc-action-button"
                    color="warning"
                    variant="contained"
                    size="small"
                    onClick={() => handleEditInputs(node)}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              )}
              {!isCompanyUser() && (
                <Tooltip title="Șterge">
                  <Button
                    id="deleteBtn"
                    className="doc-action-button"
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => showDeleteConfirmDialog(node)}
                  >
                    <Clear />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          );
        }
      }
    if (category === "solicitareModificari" && nodeType === "document") {
      if (node.data.isNotSaved)
        return (
          <Grid className="download-buttons-container">
            <Button
              className="doc-action-button"
              startIcon={<Add />}
              variant="contained"
              size="small"
              onClick={(e) => {
                setDisplayFileManagerDialog(true);
                setFileManagerDialogDisplayMode("add");
              }}
            >
              Încarcă
            </Button>
            <Button
              className="doc-action-button"
              endIcon={<Check />}
              variant="contained"
              size="small"
              color="success"
              onClick={() => dispatch(setSolicitareSubmitStatus("loading"))}
            >
              Salvează modificarea
            </Button>
            <Button
              id="deleteBtn"
              className="doc-action-button"
              variant="contained"
              size="small"
              color="error"
              title={"Sterge"}
              onClick={() => handleDeleteRequest(node)}
            >
              <Clear />
            </Button>
          </Grid>
        );
      else {
        if (node.data.id === editedInputsId) {
          return (
            <Grid className="download-buttons-container">
              <Button
                className="doc-action-button"
                variant="contained"
                size="small"
                onClick={(e) => handleFileManagerDialogEdit(node)}
              >
                Schimbă fișierele
              </Button>
              <Button
                className="doc-action-button"
                endIcon={<Check />}
                variant="contained"
                size="small"
                color="warning"
                onClick={() => dispatch(setSolicitareSubmitStatus("loading"))}
              >
                Actualizează
              </Button>
              <Tooltip title="Oprește editarea">
                <Button
                  id="deleteBtn"
                  className="doc-action-button"
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => {
                    setEditedInputsId(null);
                    refreshDocumentsPage();
                  }}
                >
                  <Clear />
                </Button>
              </Tooltip>
            </Grid>
          );
        } else {
          return (
            <Grid className="download-buttons-container">
              {!isDocumentResolved(node) ? (
                (isExpert() || isAdminUser()) && (
                  <React.Fragment>
                    <Button
                      className="doc-action-button"
                      endIcon={<Check />}
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() => {
                        dispatch(setActionNode(node));
                        validateRequest(node);
                      }}
                    >
                      Validează
                    </Button>
                    <Button
                      className="doc-action-button"
                      endIcon={<Clear />}
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => showRejectDialog(node)}
                    >
                      Respinge
                    </Button>
                  </React.Fragment>
                )
              ) : node.data.isValidated ? (
                <Button
                  className="doc-action-button"
                  variant="outlined"
                  size="small"
                  color="success"
                  onClick={() => {
                    dispatch(setActionNode(node));
                    unValidateRequest(node);
                  }}
                  disabled={isCompanyUser()}
                >
                  Validat
                </Button>
              ) : (
                <Button
                  className="doc-action-button"
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={() => showRejectDialog(node)}
                >
                  Respins
                </Button>
              )}
              <Button
                className="doc-action-button"
                variant="contained"
                size="small"
                onClick={(e) => {
                  dispatch(setActionNode(node));
                  showFiles(node);
                }}
              >
                Vezi fișierele
              </Button>

              {!isDocumentResolved(node) && !isAddingNewDecont && (
                <Tooltip title="Modifică">
                  <Button
                    className="doc-action-button"
                    variant="contained"
                    size="small"
                    color="warning"
                    onClick={() => handleEditInputs(node)}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              )}
              {!isCompanyUser() && (
                <Tooltip title="Șterge">
                  <Button
                    id="deleteBtn"
                    className="doc-action-button"
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => showDeleteConfirmDialog(node)}
                  >
                    <Clear />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          );
        }
      }
    }

    if (category.includes("Anexa") && nodeType === "document") {
      return (
        <Grid className="download-buttons-container">
          {!isDocumentResolved(node) ? (
            (isExpert() || isAdminUser()) && (
              <React.Fragment>
                {isNotNullOrUndefined(node.data.dosarAchizitiiId) ? (
                  <Button
                    className="doc-action-button"
                    endIcon={<Check />}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => handleValidateDocument(node)}
                  >
                    Validează
                  </Button>
                ) : (
                  <Button
                    className="doc-action-button"
                    endIcon={<Check />}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => handleValidateDocument(node)}
                  >
                    Validează
                  </Button>
                )}
                <Button
                  className="doc-action-button"
                  endIcon={<Clear />}
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => showRejectDialog(node)}
                >
                  Respinge
                </Button>
              </React.Fragment>
            )
          ) : node.data.isValidated ? (
            <Button
              className="doc-action-button"
              disabled={isCompanyUser()}
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                unValidateDocument(node);
                dispatch(setActionNode(node));
              }}
            >
              Validat
            </Button>
          ) : (
            <Button
              className="doc-action-button"
              variant="outlined"
              color="error"
              size="small"
              onClick={() => showRejectDialog(node)}
            >
              Respins
            </Button>
          )}
          <Button
            className="doc-action-button"
            endIcon={<Download />}
            variant="contained"
            size="small"
            onClick={() => downloadFile(node.data.fileId)}
          >
            Descarcă documentul
          </Button>
          {!isCompanyUser() && (
            <Tooltip title="Șterge documentul">
              <Button
                id="deleteBtn"
                className="doc-action-button"
                variant="contained"
                color="error"
                size="small"
                onClick={() => showDeleteConfirmDialog(node)}
              >
                <Clear />
              </Button>
            </Tooltip>
          )}
        </Grid>
      );
    }

    if (
      category.includes("formulareContract") ||
      category.includes("proceduraContract") ||
      category.includes("formulareAchizitii") ||
      category.includes("proceduraAchizitii")
    ) {
      return (
        <Grid className="download-buttons-container">
          <Button
            className="doc-action-button"
            endIcon={<Download />}
            variant="contained"
            size="small"
            onClick={() => downloadFile(node.data.fileId)}
          >
            Descarcă documentul
          </Button>
          {!isCompanyUser() && (
            <Tooltip title="Șterge documentul">
              <Button
                id="deleteBtn"
                className="doc-action-button"
                variant="contained"
                size="small"
                color="error"
                onClick={() => showDeleteConfirmDialog(node)}
              >
                <Clear />
              </Button>
            </Tooltip>
          )}
        </Grid>
      );
    }

    return (
      <Grid className="download-buttons-container">
        {node.data.requiresValidation && (
          <React.Fragment>
            {!isDocumentResolved(node) ? (
              (isExpert() || isAdminUser()) && (
                <React.Fragment>
                  <Button
                    className="doc-action-button"
                    endIcon={<Check />}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => handleValidateDocument(node)}
                  >
                    Validează
                  </Button>
                  <Button
                    className="doc-action-button"
                    endIcon={<Clear />}
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => showRejectDialog(node)}
                  >
                    Respinge
                  </Button>
                </React.Fragment>
              )
            ) : node.data.isValidated ? (
              <Button
                className="doc-action-button"
                disabled={isCompanyUser()}
                variant="outlined"
                color="success"
                size="small"
                onClick={() => {
                  unValidateDocument(node);
                  dispatch(setActionNode(node));
                }}
              >
                Validat
              </Button>
            ) : (
              <Button
                className="doc-action-button"
                variant="outlined"
                color="error"
                size="small"
                onClick={() => showRejectDialog(node)}
              >
                Respins
              </Button>
            )}
          </React.Fragment>
        )}
        <Button
          className="doc-action-button"
          endIcon={<Download />}
          variant="contained"
          size="small"
          onClick={() => downloadFile(node.data.fileId)}
        >
          Descarcă documentul
        </Button>
        {!isCompanyUser() && (
          <Tooltip title="Șterge documentul">
            <Button
              id="deleteBtn"
              className="doc-action-button"
              variant="contained"
              color="error"
              size="small"
              onClick={() => showDeleteConfirmDialog(node)}
            >
              <Clear />
            </Button>
          </Tooltip>
        )}
      </Grid>
    );
  };

  const handleAddNewDecont = (node: any) => {
    setIsAddingNewDecont(true);
    setEditedInputsId(null);
    setDecontCurrentFiles([]);
    setNewDecontFiles([]);
    dispatch(addNewDecontCheltuieli(node));
  };

  const getYearFromYearLabel = (yearLabel: string): any => {
    switch (yearLabel) {
      case "an2022":
        return 3;
      case "an2023":
        return 3;
      case "an2024":
        return 4;
      case "an2025":
        return 5;
      default:
        return 10;
    }
  };

  const addDecontGroup = (payload: any) => {
    dispatch(
      addNewGroupOfDeconturi({
        requestData: {
          userId: userId ? userId : currentUser?.userId,
          yearCategory: currentNode.data.yearInt,
          monthCategory: currentNode.data.monthInt,
          groupName: payload.dosarName,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(setSimpleExpandKey(currentNode.key));
      hideDecontNameDialog();
    });
  };
  const deleteDecontGroup = (node: any) => {
    dispatch(
      deleteGroupOfDeconturi({
        requestData: {
          id: node.data.id,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      hideDeleteConfirmDialog();
    });
  };

  const handleAddRequestInputsNode = (node: any) => {
    dispatch(addRequestInputsNode(node));
    setIsAddingNewDecont(true);
    setEditedInputsId(null);
    setSolicitareCurrentFiles([]);
    setNewSolicitareFiles([]);
  };

  const getUploadButton = (node: any) => {
    let category = node.data.category;
    if (node.data.nodeType === "dosar") {
      return (
        <Grid className="upload-buttons-container">
          {!isCompanyUser() && checkIfAtLeastOneFile(node) && (
            <Button
              className="doc-action-button"
              variant="contained"
              color="success"
              size="small"
              onClick={() => {
                setValidationNoteNode(node);
                setRegNrRequired(true);
                setDialogAnexaNr(null);
                setDisplayConfirmDateDialog(true);
              }}
            >
              Notă validare
            </Button>
          )}
          {!isCompanyUser() && (
            <Tooltip title="Șterge dosar">
              <Button
                id="deleteBtn"
                className="doc-action-button"
                color="error"
                size="small"
                variant="contained"
                onClick={() => showDeleteConfirmDialog(node)}
              >
                <Clear />
              </Button>
            </Tooltip>
          )}
        </Grid>
      );
    }
    if (node.data.nodeType === "anexaAchizitii") {
      return (
        <Grid className="upload-buttons-container">
          <Button
            className="doc-action-button"
            startIcon={<Add />}
            variant="contained"
            size="small"
            onClick={() => {
              showAnexaAchizitiiUploadDialog(node);
            }}
          >
            Adaugă fișier
          </Button>
        </Grid>
      );
    } else if (category === "dosareAchizitii") {
      return (
        <Grid className="upload-buttons-container">
          <Button
            className="doc-action-button"
            endIcon={<Add />}
            variant="contained"
            size="small"
            color="warning"
            onClick={() => showDosarAchizitiiNameDialog(node, node.key)}
          >
            Dosar de achiziții nou
          </Button>
        </Grid>
      );
    }
    if (node.data.nodeType === "subCategory") {
      if (node.data.subcategoryInt === "Modificări solicitate") {
        return (
          <Grid className="upload-buttons-container">
            <Button
              className="doc-action-button"
              variant="contained"
              size="small"
              onClick={() => {
                dispatch(setActionNode(node));
                showRequestNameDialog(node);
              }}
            >
              Adaugă solicitare
            </Button>
          </Grid>
        );
      } else if (
        !node.children.some(
          (childNode: any) => childNode.data.nodeType === "month"
        )
      ) {
        if (
          (node.data.year === "templates" && !isCompanyUser()) ||
          node.data.year !== "templates"
        ) {
          return (
            (((node.data.name === "noteValidareAchizitii" ||
              node.data.name === "noteValidareSolicitare" ||
              node.data.name === "noteValidareDecont") &&
              !isCompanyUser()) ||
              (node.data.name !== "noteValidareAchizitii" &&
                node.data.name !== "noteValidareSolicitare" &&
                node.data.name !== "noteValidareDecont")) && (
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  className="doc-action-button"
                  startIcon={<Add />}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    showFileUploadDialog(node);
                  }}
                >
                  Adaugă fișier
                </Button>
              </Grid>
            )
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
    if (node.data.nodeType === "month") {
      if (category === "decontCheltuieli")
        return (
          <Grid className="upload-buttons-container">
            <Button
              className="doc-action-button"
              variant="contained"
              size="small"
              onClick={() => {
                dispatch(setActionNode(node));
                showDecontNameDialog(node, node.key);
              }}
            >
              Adaugă dosar
            </Button>
          </Grid>
        );
      else {
        return (
          <Grid className="upload-buttons-container">
            <Button
              className="doc-action-button"
              startIcon={<Add />}
              variant="contained"
              size="small"
              onClick={() => {
                showFileUploadDialog(node);
              }}
            >
              Adaugă fișier
            </Button>
          </Grid>
        );
      }
    }
    if (node.data.nodeType === "requestGroup") {
      return (
        <Grid className="upload-buttons-container">
          {!isCompanyUser() && node.children.length !== 0 && (
            <Button
              className="doc-action-button"
              onClick={() => downloadValidationNote({ node: node, anexaNr: 1 })}
              variant="contained"
              size="small"
              color="success"
            >
              Anexă 1
            </Button>
          )}
          {!isCompanyUser() && false && node.children.length !== 0 && (
            <Button
              className="doc-action-button"
              onClick={() => downloadValidationNote({ node: node, anexaNr: 2 })}
              variant="contained"
              size="small"
              color="success"
            >
              Anexă 2
            </Button>
          )}
          {!isCompanyUser() && node.children.length !== 0 && (
            <Button
              className="doc-action-button"
              onClick={() => {
                setValidationNoteNode(node);
                setRegNrRequired(true);
                setDialogAnexaNr(null);
                setDisplayConfirmDateDialog(true);
              }}
              variant="contained"
              size="small"
              color="success"
            >
              Notă validare
            </Button>
          )}
          {!isAddingNewDecont && (
            <Button
              className="doc-action-button"
              onClick={() => {
                handleAddRequestInputsNode(node);
              }}
              variant="contained"
              size="small"
            >
              Adaugă modificare
            </Button>
          )}
          {(isExpert() || isAdminUser()) && (
            <Tooltip title="Șterge documentul">
              <Button
                id="deleteBtn"
                className="doc-action-button"
                variant="contained"
                size="small"
                color="error"
                onClick={() => showDeleteConfirmDialog(node)}
              >
                <Clear />
              </Button>
            </Tooltip>
          )}
        </Grid>
      );
    }
    if (node.data.nodeType === "decontGroup") {
      return (
        <Grid className="upload-buttons-container">
          {!isCompanyUser() && node.data.isValidated === true && (
            <Button
              className="doc-action-button"
              variant="contained"
              size="small"
              color="success"
              onClick={() => {
                setValidationNoteNode(node);
                setRegNrRequired(true);
                setDialogAnexaNr(null);
                setDisplayConfirmDateDialog(true);
              }}
            >
              Notă validare
            </Button>
          )}
          {!isAddingNewDecont && (
            <Button
              className="doc-action-button"
              endIcon={<Add />}
              variant="contained"
              size="small"
              color="warning"
              onClick={() => handleAddNewDecont(node)}
            >
              Adaugă un decont
            </Button>
          )}
          {(isExpert() || isAdminUser()) && (
            <Tooltip title="Șterge documentul">
              <Button
                id="deleteBtn"
                className="doc-action-button"
                variant="contained"
                size="small"
                color="error"
                onClick={() => showDeleteConfirmDialog(node)}
              >
                <Clear />
              </Button>
            </Tooltip>
          )}
        </Grid>
      );
    }
    return null;
  };

  const downloadAnexa1 = (node: any, date: any, regNr: any) => {
    if (node.children.some((changeNode: any) => changeNode.data.isValidated)) {
      dispatch(
        generateAnexa1Solicitare({
          requestData: {
            groupId: node.data.id,
            actNr: "",
          },
          token: currentUser?.jwtToken,
        })
      );
    } else {
      dispatch(
        addAppNotification({
          message: "Este necesară cel puțin o modificare validată!",
          severity: "warning",
        })
      );
    }
  };

  const downloadAnexa2 = (node: any, date: any, regNr: any) => {
    if (node.children.some((changeNode: any) => changeNode.data.isValidated)) {
      // dispatch(downloadAnexa2()) -TBD
    } else {
      dispatch(
        addAppNotification({
          message: "Este necesară cel puțin o modificare validată!",
          severity: "warning",
        })
      );
    }
  };

  const getRowButton = (node: any): any => {
    if (!node.children) return getDownloadButton(node);
    else return getUploadButton(node);
  };

  const getDecontCheltuieliInputs = (node: any) => {
    return (
      <InputTableDecontCheltuieliComponent
        uploadDocJustificativ={uploadDecont}
        node={node}
        isExpert={isExpert()}
        validateDecont={validateDecont}
        editModeId={editedInputsId}
      />
    );
  };
  const getSolicitareModificariInputs = (node: any) => {
    return (
      <InputTableSolicitareModificari
        data={node.data}
        onUploadSolicitare={uploadModificariContractuale}
        node={node}
        editModeId={editedInputsId}
      />
    );
  };

  const getColumnContent = (node: any): any => {
    let nodeType = node.data.nodeType;
    let category = node.data.category;

    if (nodeType === "document") {
      switch (category) {
        case "decontCheltuieli":
          return getDecontCheltuieliInputs(node);
        case "solicitareModificari":
          return getSolicitareModificariInputs(node);
        default:
          return getDisplayName(node);
      }
    }
    return getDisplayName(node);
  };
  const handleSetExpandedKeys = (key: any) => {
    dispatch(setExpandedKeys(key.value));
  };

  const uploadDecont = (node: any, decontData: any) => {
    if (!node || !decontData) return;
    if (editedInputsId === null) {
      if (newDecontFiles !== undefined && newDecontFiles.length !== 0) {
        dispatch(
          uploadDecontCheltuieli({
            requestData: {
              companyUserId:
                userId !== undefined ? userId : currentUser?.userId,
              documenteJustificative: newDecontFiles,
              tipCheltuiala: decontData.tipCheltuiala,
              nrDocumentJustificativ: decontData.nrDocumentJustificativ,
              ajutorMinimis: decontData.ajutorMinimis,
              contributieProprie: decontData.contributieProprie,
              dataDocumentJustificativ: decontData.dataDocumentJustificativ,
              monthCategory: node.data.monthInt,
              yearCategory: getYearFromYearLabel(node.data.year),
              groupOfDeconturiId: node.data.decontGroupId,
            },
            token: currentUser?.jwtToken,
          })
        ).then(() => {
          setIsAddingNewDecont(false);
          setNewDecontFiles([]);
          dispatch(setSimpleExpandKey(node.key));
        });
      } else {
        dispatch(
          addAppNotification({
            message: "Documentul justificativ nu a fost adăugat!",
            severity: "warning",
          })
        );
      }
    } else {
      dispatch(
        updateDecont({
          requestData: {
            decontCheltuieliId: editedInputsId,
            documenteJustificative: newDecontFiles,
            documenteJustificativeDeleted: decontCurrentFiles.filter(
              (file: any) => file.willBeRemoved === true
            ),
            tipCheltuiala: decontData.tipCheltuiala,
            nrDocumentJustificativ: decontData.nrDocumentJustificativ,
            ajutorMinimis: decontData.ajutorMinimis,
            contributieProprie: decontData.contributieProprie,
            dataDocumentJustificativ: decontData.dataDocumentJustificativ,
            companyUserId: userId !== undefined ? userId : currentUser?.userId,
          },
          token: currentUser?.jwtToken,
        })
      ).then(() => {
        setEditedInputsId(null);
        setNewDecontData([]);
        dispatch(setSimpleExpandKey(node.key));
      });
    }
  };

  const uploadModificariContractuale = (
    node: any,
    solicitareData: any
  ): any => {
    let isUpdating = node.data.id === editedInputsId;
    if (!node || !solicitareData) return;
    if (!isUpdating) {
      if (newSolicitareFiles !== undefined && newSolicitareFiles.length !== 0) {
        dispatch(
          uploadSolicitareModificari({
            requestData: {
              companyUserId:
                userId !== undefined ? userId : currentUser?.userId,
              sectiunePlanAfaceri: solicitareData.sectiunePlanAfaceri,
              files: newSolicitareFiles, //de verificat
              modificareSolicitataPlanAfaceri:
                solicitareData.modificareSolicitataPlanAfaceri,
              justificareModificarePlanAfaceri:
                solicitareData.justificareModificarePlanAfaceri,
              modificareSolicitataBuget:
                solicitareData.modificareSolicitataBuget,
              justificareModificareBuget:
                solicitareData.justificareModificareBuget,
              valoareInitialaPlanAfaceri:
                solicitareData.valoareInitialaPlanAfaceri,
              valoareModificataPlanAfaceri:
                solicitareData.valoareModificataPlanAfaceri,
              valoareInitialaPlanBuget: solicitareData.valoareInitialaPlanBuget,
              valoareModificataPlanBuget:
                solicitareData.valoareModificataPlanBuget,
              linieBuget: solicitareData.linieBuget,
              // de verificat
              yearCategory: getYearFromYearLabel(node.data.year),
              groupOfSolicitareModificareId: node.data.requestGroupId,
              rejectedComment: solicitareData.rejectedComment,
            },
            token: currentUser?.jwtToken,
          })
        ).then(() => {
          setIsAddingNewDecont(false);
          setEditedInputsId(null);
          setNewSolicitareFiles([]);
          dispatch(setSimpleExpandKey(node.key));
        });
      }
    } else {
      dispatch(
        updateSolicitareModificari({
          requestData: {
            solicitareModificariId: node.data.id,
            companyUserId: userId !== undefined ? userId : currentUser?.userId,
            files: newSolicitareFiles,
            alteDocumenteDeleted: solicitareCurrentFiles.filter(
              (file: any) => file.willBeRemoved === true
            ),
            sectiunePlanDeAfaceri: solicitareData.sectiunePlanAfaceri,
            modificareSolicitataPlanDeAfaceri:
              solicitareData.modificareSolicitataPlanAfaceri,
            justificareModificarePlanDeAfaceri:
              solicitareData.justificareModificarePlanAfaceri,
            valoareInitialaPlanDeAfaceri:
              solicitareData.valoareInitialaPlanAfaceri,
            valoareModificataPlanDeAfaceri:
              solicitareData.valoareModificataPlanAfaceri,
            linieBuget: solicitareData.linieBuget,
            modificareSolicitataBuget: solicitareData.modificareSolicitataBuget,
            justificareModificareBuget:
              solicitareData.justificareModificareBuget,
            valoareInitialaPlanBuget: solicitareData.valoareInitialaPlanBuget,
            valoareModificataPlanBuget:
              solicitareData.valoareModificataPlanBuget,
          },
          token: currentUser?.jwtToken,
        })
      ).then(() => {
        setEditedInputsId(null);
        setNewSolicitareFiles([]);
        dispatch(setSimpleExpandKey(node.key));
      });
    }
  };

  const handleFileManagerDialogHide = (initialFilesRemoved: any) => {
    setDisplayFileManagerDialog(false);
    setNewSolicitareFiles(
      initialFilesRemoved
        ? newSolicitareFiles.filter(
            (file: any) => !initialFilesRemoved.includes(file)
          )
        : newSolicitareFiles
    );
  };
  const handleFileManagerDialogHideDecont = (initialFilesRemoved: any) => {
    setDecontFileManagerDialogDisplay(false);
    setNewDecontFiles(
      initialFilesRemoved
        ? newDecontFiles.filter(
            (file: any) => !initialFilesRemoved.includes(file)
          )
        : newDecontFiles
    );
  };

  const handleFileManagerDialogEdit = (node: any) => {
    setFileManagerDialogDisplayMode("edit");
    setDisplayFileManagerDialog(true);
  };
  const handleFileManagerDialogEditDecont = (node: any) => {
    setDecontFileManagerDialogDisplay(true);
    setDecontFileManagerDialogDisplayMode("edit");
  };

  const handleFileManagerRemoveCurrentFile = (fileData: any) => {
    setSolicitareCurrentFiles((solicitareCurrentFiles: any) => {
      solicitareCurrentFiles = solicitareCurrentFiles?.map((file: any) => {
        if (file.fileId === fileData.fileId) {
          return {
            ...file,
            willBeRemoved: true,
          };
        }
        return file;
      });
      return solicitareCurrentFiles;
    });
  };

  const handleFileManagerRemoveCurrentFileDecont = (fileData: any) => {
    setDecontCurrentFiles((decontCurrentFiles: any) => {
      decontCurrentFiles = decontCurrentFiles?.map((file: any) => {
        if (file.fileId === fileData.fileId) {
          return {
            ...file,
            willBeRemoved: true,
          };
        }
        return file;
      });
      return decontCurrentFiles;
    });
  };
  const handleFileManagerCancelRemoveCurrentFile = (fileData: any) => {
    setSolicitareCurrentFiles(
      solicitareCurrentFiles?.map((file: any) => {
        if (file.fileId === fileData.fileId) {
          return {
            ...file,
            willBeRemoved: false,
          };
        }
        return file;
      })
    );
  };
  const handleFileManagerCancelRemoveCurrentFileDecont = (fileData: any) => {
    setDecontCurrentFiles(
      decontCurrentFiles?.map((file: any) => {
        if (file.fileId === fileData.fileId) {
          return {
            ...file,
            willBeRemoved: false,
          };
        }
        return file;
      })
    );
  };

  const handleFileManagerRemoveNewFile = (fileData: any) => {
    setNewSolicitareFiles(
      newSolicitareFiles.filter(
        (file: any) => file.objectURL !== fileData.objectURL
      )
    );
  };

  const handleFileManagerRemoveNewFileDecont = (fileData: any) => {
    setDecontCurrentFiles(
      decontCurrentFiles.filter(
        (file: any) => file.objectURL !== fileData.objectURL
      )
    );
  };

  const handleFileManagerDialogCancel = (
    initialCurrentFiles: any,
    initialNewFiles: any
  ) => {
    setSolicitareCurrentFiles([...initialCurrentFiles]);
    setNewSolicitareFiles([...initialNewFiles]);
    setDisplayFileManagerDialog(false);
  };

  const handleFileManagerDialogCancelDecont = (
    initialCurrentFiles: any,
    initialNewFiles: any
  ) => {
    setDecontCurrentFiles([...initialCurrentFiles]);
    setNewDecontFiles([...initialNewFiles]);
    setDecontFileManagerDialogDisplay(false);
  };

  const handleCancelCancelingAction = () => {
    dispatch(setActionNode(null));
    setConfirmCancelActionDialog(false);
  };

  return (
    <Container maxWidth={false} id="documents-component">
      {
        <Grid sx={{ width: "100%" }}>
          <TreeTable
            value={documents}
            loading={documentsIsLoading}
            selectionMode="single"
            expandedKeys={expandedKeys}
            onToggle={handleSetExpandedKeys}
            scrollable
            emptyMessage="Vă rugăm selectați un utilizator"
          >
            <Column
              field="name"
              header="Nume"
              body={getColumnContent}
              expander
            ></Column>
            <Column body={getRowButton} />
          </TreeTable>
        </Grid>
      }
      {displayRejectDialog && (
        <RejectDialog
          display={displayRejectDialog}
          handleClose={hideRejectDialog}
          handleReject={handleRejectDocument}
          data={rejectDocumentData}
          handleUnreject={handleUnReject}
        />
      )}
      {displayDeleteConfirmDialog && (
        <DeleteConfirmationDialog
          headerText="Confirmare"
          display={displayDeleteConfirmDialog}
          handleClose={hideDeleteConfirmDialog}
          handleConfirm={deleteDocumentWithConfirmation}
          data={currentNode}
        />
      )}
      {displayAnexaAchizitiiUploadDialog && (
        <FileUploadPopout
          displayDialog={displayAnexaAchizitiiUploadDialog}
          handleClose={hideAnexaAchizitiiUploadDialog}
          uploadOnButtonCallback={handleUploadAnexaAchizitii}
        />
      )}
      {displayFileUploadDialog && (
        <FileUploadPopout
          displayDialog={displayFileUploadDialog}
          handleClose={hideFileUploadDialog}
          uploadOnButtonCallback={uploadFile}
        />
      )}
      {displayDecontNameDialog && (
        <CaseFileDialogComponent
          header="Nume dosar decont cheltuieli"
          displayDialog={displayDecontNameDialog}
          handleClose={hideDecontNameDialog}
          confirmAction={addDecontGroup}
        />
      )}
      {displayRequestNameDialog && (
        <CaseFileDialogComponent
          header="Nume solicitare"
          displayDialog={displayRequestNameDialog}
          handleClose={hideRequestNameDialog}
          confirmAction={addRequestGroup}
        />
      )}
      {decontFileManagerDialogDisplay && (
        <ManageDocumentFiles
          display={decontFileManagerDialogDisplay}
          displayMode={decontFileManagerDialogDisplayMode}
          currentFiles={decontCurrentFiles}
          newFiles={newDecontFiles}
          onFileDownload={downloadFile}
          onNewFilesSelect={(e: any) =>
            onDocJustificativeUpload(e, {
              data: { category: "decontCheltuieli" },
            })
          }
          onRemoveCurrentFile={handleFileManagerRemoveCurrentFileDecont}
          onCancelRemoveCurrentFile={
            handleFileManagerCancelRemoveCurrentFileDecont
          }
          onRemoveNewFile={handleFileManagerRemoveNewFileDecont}
          onSave={handleFileManagerDialogHideDecont}
          onCancel={handleFileManagerDialogCancelDecont}
          isRequired
          requiredCondition={willFileListBePopulated}
          requiredWarningText="Este necesar cel puțin un document justificativ!"
        />
      )}
      {displayFileManagerDialog && (
        <ManageDocumentFiles
          display={displayFileManagerDialog}
          displayMode={fileManagerDialogDisplayMode}
          currentFiles={solicitareCurrentFiles}
          newFiles={newSolicitareFiles}
          onFileDownload={downloadFile}
          onNewFilesSelect={(e: any) =>
            onDocJustificativeUpload(e, {
              data: { category: "solicitareModificari" },
            })
          }
          onRemoveCurrentFile={handleFileManagerRemoveCurrentFile}
          onCancelRemoveCurrentFile={handleFileManagerCancelRemoveCurrentFile}
          onRemoveNewFile={handleFileManagerRemoveNewFile}
          onSave={handleFileManagerDialogHide}
          onCancel={handleFileManagerDialogCancel}
          isRequired
          requiredCondition={willFileListBePopulated}
          requiredWarningText="Este necesar cel puțin un document justificativ!"
        />
      )}

      {displayDosarAchizitiiNameDialog && (
        <CaseFileDialogComponent
          header="Nume dosar achiziții"
          displayDialog={displayDosarAchizitiiNameDialog}
          handleClose={hideDosarAchizitiiNameDialog}
          confirmAction={handleAddNewAquisitionFolder}
        />
      )}
      {displayConfirmDateDialog && (
        <ValidationNoteDialog
          display={displayConfirmDateDialog}
          onHide={hideConfirmDateDialog}
          handleConfirm={downloadValidationNote}
          node={validationNoteNode}
          isCompanyUser={isCompanyUser}
          textRequired={regNrRequired}
          anexaNr={dialogAnexaNr}
        />
      )}
      {confirmCancelActionDialog && (
        <DeleteConfirmationDialog
          headerText="Confirmare"
          display={confirmCancelActionDialog}
          handleClose={handleCancelCancelingAction}
          handleConfirm={confirmCancelAction!}
          data={actionNode}
        />
      )}
    </Container>
  );
};
