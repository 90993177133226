import { EmploymentMarketSituation } from "../enums/EmploymentMarketSituation";

export const employmentMarketSituationExtraItems = [
  {
    value: EmploymentMarketSituation.SelfEmployed,
    label: "Angajat pe cont propriu",
  },
  {
    value: EmploymentMarketSituation.LongTermUnemployed,
    label: "Șomer de lungă durată",
  },
  {
    value: EmploymentMarketSituation.OtherInactivePerson,
    label: "Altă categorie de inactivi în afară de cei din educație si formare",
  },
];
