import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FC, useState } from "react";
import { getScoresForPhase2Intermediary, selectCurrentUser, selectGrantContestPageLocalization, selectUserIsUploadingAppeal, uploadAppeal, useAppDispatch, useAppSelector } from "store";
import { PhaseNumber } from "store/models/enums/PhaseNumber";
import { PhaseType } from "store/models/enums/PhaseType";
import { setUserIsUploadingAppeal } from "store/slices/grantEvaluationSlice";
import { FileUploadComponent } from "../fileUpload/FileUploadComponent";

export const AppealComponent: FC = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const userIsUploadingAppeal = useAppSelector(selectUserIsUploadingAppeal);
    const [mandatoryFileToUpload, setMandatoryFileToUpload] = useState<Array<File>>([]);
    const [optionallyFilesToUpload, setOptionallyFilesToUpload] = useState<Array<File>>([]);
    const localization = useAppSelector(selectGrantContestPageLocalization);

    const handleUploadAppeal = () => {
        dispatch(uploadAppeal({
            filter: {
                userAppealingId: currentUser?.userId,
                phaseNumber: PhaseNumber.Phase2,
                phaseType: PhaseType.Intermediary,
                mandatoryFile: mandatoryFileToUpload,
                files: optionallyFilesToUpload,

            },
            token: currentUser?.jwtToken

        })).then(() => {
            dispatch(setUserIsUploadingAppeal(false));
            dispatch(getScoresForPhase2Intermediary({
                filters: {
                    pageNumber: 0,
                },
                token: currentUser?.jwtToken
            }))
        });
    }
    return (
        <div style={{ height: "100%" }}>
            <Dialog
                id="upload-appeal"
                className="dialog"
                open={userIsUploadingAppeal}
                maxWidth="sm"
                fullWidth
                disableScrollLock
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "end" }}>
                    <Tooltip title={localization.phaseRank2Tab.closeTooltip}>
                        <IconButton onClick={() => dispatch(setUserIsUploadingAppeal(false))}><Close /></IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent>
                    <Grid container padding={1} direction="column" justifyContent="center" alignItems="center">
                        <Grid width="90%" marginBottom={1}>
                            <Typography marginBottom={1}>{localization.phaseRank2Tab.appealForm}</Typography>
                            <FileUploadComponent
                                fileLimit={1}
                                onFilesChange={(files: File[]) => {
                                    setMandatoryFileToUpload(files);
                                }}
                            />
                        </Grid>
                        <Grid marginTop={2} width="90%" height={"15vh"} marginBottom={1}>
                            <Typography marginBottom={1}>{localization.phaseRank2Tab.docs}</Typography>
                            <FileUploadComponent
                                fileLimit={10}
                                onFilesChange={(files: File[]) => {
                                    setOptionallyFilesToUpload(files);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={handleUploadAppeal} disabled={mandatoryFileToUpload.length === 0}>{localization.phaseRank2Tab.uploadBtn}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};
