import { Container, Grid, Tab, Tabs } from "@mui/material";
import { UserRepresentativeView } from "./user-representative-view";
import React, { FC, useEffect, useState } from "react";
import "./UserDetailsPage.scss";
import {
  registerCompanyUser,
  registerEmployerUser,
  registerExpertUser,
  requestLogout,
  requestUserDetails,
  selectCurrentUser,
  selectUserDetails,
  updateEmployer,
  updateUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import * as yup from "yup";
import { Formik, Form } from "formik";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import locale from "date-fns/locale/ro";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { UserPersonalDocumentsView } from "./user-personal-documents-view";
import { UserType } from "store/models/enums/UserType";
import { newUserPath, usersPath } from "navigation";
import { resetUserDetails, setUpdateCompanyDetails } from "store/slices/targetGroupSlice";
import { isCompany, isEmployer, isExpert } from "utils/getUserType";
import { EmployerUserView } from "./employer-user-view/EmployerUserView";
import { UserCompanyView } from "./user-company-view";

interface LocationState {
  userType?: UserType;
}

export const UserDetailsPage: FC<any> = () => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userId } = useParams();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const currentUser = useAppSelector(selectCurrentUser);
  const canEdit = currentUser?.userType !== UserType.COMPANY_USER;
  const isSameCompanyUser =
    (userId && currentUser?.userId === parseInt(userId, 10)) || canEdit;

  const location = useLocation();
  let locationState = location.state as LocationState;

  const isAddingNewUser = true ? location.pathname === newUserPath : false;
  const userDetails = useAppSelector(selectUserDetails);

  if (!isSameCompanyUser) {
    dispatch(requestLogout(currentUser?.jwtToken));
  }

  const setCurrentTimeForDates = (values: any) => {
    var date = new Date();
    values?.dataRecrutare?.setHours(date.getHours());
    values?.dataRecrutare?.setMinutes(date.getMinutes());
    values?.dataInformare?.setHours(date.getHours());
    values?.dataInformare?.setMinutes(date.getMinutes());
    values?.dataInregistrare?.setHours(date.getHours());
    values?.dataInregistrare?.setMinutes(date.getMinutes());
    values?.dataNasterii?.setHours(date.getHours());
    values?.dataNasterii?.setMinutes(date.getMinutes());
  };

  useEffect(() => {
    if (isAddingNewUser) {
      var storageValues = localStorage.getItem('registerUserForm');
      if (storageValues !== null && storageValues !== undefined) {
        var values = JSON.parse(storageValues);
        dispatch(setUpdateCompanyDetails(values));
      }
    }
    return () => {
      dispatch(setUpdateCompanyDetails(undefined));
    }
    //eslint-disable-next-line
  }, [])


  useEffect(() => {
    setActiveTab(0);
    if (!isAddingNewUser) {
      let promise = dispatch(
        requestUserDetails({
          userId: parseInt(userId ?? "0"),
          token: currentUser?.jwtToken,
        })
      );

      return () => promise.abort();
    } else {
      dispatch(resetUserDetails());
    }
    //eslint-disable-next-line
  }, [userId]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Introduceți email valid")
      .required("Email obligatoriu")
      .nullable(true),
    firstName: yup
      .string()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => !isEmployer(val),
        then: (schema: any) => schema.required("Prenumele este obligatoriu"),
      }),
    lastName: yup
      .string()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => !isEmployer(val),
        then: (schema: any) => schema.required("Numele este obligatoriu"),
      }),
    userType: yup
      .number()
      .required("Tipul utilizatorului este obligatoriu")
      .nullable(true),
    expertAssignedId: yup
      .number()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => isCompany(val),
        then: (schema: any) =>
          schema.required("Expertul asignat este obligatoriu"),
      }),
    expertType: yup
      .number()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => isExpert(val),
        then: (schema: any) =>
          schema.required("Tipul expertului este obligatoriu"),
      }),
    caen: yup
      .string()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => isEmployer(val),
        then: (schema: any) => schema.required("Codul CAEN este obligatoriu"),
      }),
    cnp: yup
      .string()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => isCompany(val),
        then: (schema: any) =>
          schema
            .required("CNP obligatoriu")
            .test(
              "cnp",
              "CNP incorect",
              (val: string) => val && val.length === 13
            ),
      }),
    companyName: yup
      .string()
      .nullable(true)
      .when("userType", {
        is: (val: UserType) => isEmployer(val),
        then: (schema: any) =>
          schema.required("Numele companiei este obligatoriu"),
      }),
    job: yup.object().shape(
      {
        jobTitle: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobLocality",
              "jobStreet",
              "employmentRequirements",
              "vacancy",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                jobDescription: string,
                county: any,
                locality: string,
                street: string,
                employmentRequirements: string,
                vacancy: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (jobDescription && jobDescription.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (employmentRequirements && employmentRequirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        jobDescription: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobTitle",
              "jobCounty",
              "jobLocality",
              "jobStreet",
              "employmentRequirements",
              "vacancy",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                title: string,
                county: any,
                locality: string,
                street: string,
                requirements: string,
                vacancy: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (title && title.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (requirements && requirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        jobCounty: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobTitle",
              "jobDescription",
              "jobLocality",
              "jobStreet",
              "employmentRequirements",
              "vacancy",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                title: string,
                jobDescription: string,
                locality: string,
                street: string,
                requirements: string,
                vacancy: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (jobDescription && jobDescription.length > 0) ||
                (title && title.length > 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (requirements && requirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        jobLocality: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobTitle",
              "jobStreet",
              "employmentRequirements",
              "vacancy",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                desc: string,
                county: any,
                title: string,
                street: string,
                requirements: string,
                vacancy: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (desc && desc.length > 0) ||
                (county && county !== 0) ||
                (title && title.length > 0) ||
                (street && street.length > 0) ||
                (requirements && requirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        jobStreet: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobLocality",
              "jobTitle",
              "employmentRequirements",
              "vacancy",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                desc: string,
                county: any,
                locality: string,
                title: string,
                requirements: string,
                vacancy: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (desc && desc.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (title && title.length > 0) ||
                (requirements && requirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        employmentRequirements: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobLocality",
              "jobStreet",
              "jobTitle",
              "vacancy",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                desc: string,
                county: any,
                locality: string,
                street: string,
                title: string,
                vacancy: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (desc && desc.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (title && title.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        vacancy: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobLocality",
              "jobStreet",
              "employmentRequirements",
              "jobTitle",
              "vacanciesNumber",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                desc: string,
                county: any,
                locality: string,
                street: string,
                requirements: string,
                title: string,
                vacanciesNr: number,
                deadline: Date
              ) =>
                (desc && desc.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (requirements && requirements.length > 0) ||
                (title && title.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        vacanciesNumber: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobLocality",
              "jobStreet",
              "employmentRequirements",
              "vacancy",
              "jobTitle",
              "fileSubmissionDeadline",
            ],
            {
              is: (
                desc: string,
                county: any,
                locality: string,
                street: string,
                requirements: string,
                vacancy: string,
                title: string,
                deadline: Date
              ) =>
                (desc && desc.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (requirements && requirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (title && title.length > 0) ||
                deadline,
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
        fileSubmissionDeadline: yup
          .string()
          .nullable(true)
          .when(
            [
              "jobDescription",
              "jobCounty",
              "jobLocality",
              "jobStreet",
              "employmentRequirements",
              "vacancy",
              "vacanciesNumber",
              "jobTitle",
            ],
            {
              is: (
                desc: string,
                county: any,
                locality: string,
                street: string,
                requirements: string,
                vacancy: string,
                vacanciesNr: number,
                title: string
              ) =>
                (desc && desc.length > 0) ||
                (county && county !== 0) ||
                (locality && locality.length > 0) ||
                (street && street.length > 0) ||
                (requirements && requirements.length > 0) ||
                (vacancy && vacancy.length > 0) ||
                (vacanciesNr && vacanciesNr > 0) ||
                (title && title.length > 0),
              then: (schema: any) =>
                schema.required(
                  "Vă rugăm să completați toate câmpurile sau să lăsați toate câmpurile necompletate."
                ),
            }
          ),
      },
      [
        ["jobTitle", "jobDescription"],
        ["jobTitle", "jobCounty"],
        ["jobTitle", "jobLocality"],
        ["jobTitle", "jobStreet"],
        ["jobTitle", "employmentRequirements"],
        ["jobTitle", "vacancy"],
        ["jobTitle", "vacanciesNumber"],
        ["jobTitle", "fileSubmissionDeadline"],
        ["jobDescription", "jobCounty"],
        ["jobDescription", "jobLocality"],
        ["jobDescription", "jobStreet"],
        ["jobDescription", "employmentRequirements"],
        ["jobDescription", "vacancy"],
        ["jobDescription", "vacanciesNumber"],
        ["jobDescription", "fileSubmissionDeadline"],
        ["jobCounty", "jobLocality"],
        ["jobCounty", "jobStreet"],
        ["jobCounty", "employmentRequirements"],
        ["jobCounty", "vacancy"],
        ["jobCounty", "vacanciesNumber"],
        ["jobCounty", "fileSubmissionDeadline"],
        ["jobLocality", "jobStreet"],
        ["jobLocality", "employmentRequirements"],
        ["jobLocality", "vacancy"],
        ["jobLocality", "vacanciesNumber"],
        ["jobLocality", "fileSubmissionDeadline"],
        ["jobStreet", "employmentRequirements"],
        ["jobStreet", "vacancy"],
        ["jobStreet", "vacanciesNumber"],
        ["jobStreet", "fileSubmissionDeadline"],
        ["employmentRequirements", "vacancy"],
        ["employmentRequirements", "vacanciesNumber"],
        ["employmentRequirements", "fileSubmissionDeadline"],
        ["vacancy", "vacanciesNumber"],
        ["vacancy", "fileSubmissionDeadline"],
        ["vacanciesNumber", "fileSubmissionDeadline"],
      ]
    ),
  });

  const isJobCompleted = (jobValues: any) => {
    if (
      jobValues.jobTitle ||
      jobValues.jobDescription ||
      jobValues.jobCounty ||
      jobValues.jobLocality ||
      jobValues.jobStreet ||
      jobValues.employmentRequirements ||
      jobValues.vacancy ||
      jobValues.vacanciesNumber ||
      jobValues.fileSubmissionDeadline
    ) {
      return {
        title: jobValues.jobTitle,
        description: jobValues.jobDescription,
        county: jobValues.jobCounty,
        locality: jobValues.jobLocality,
        street: jobValues.jobStreet,
        employmentRequirements: jobValues.employmentRequirements,
        vacancy: jobValues.vacancy,
        vacanciesNumber: jobValues.vacanciesNumber,
        fileSubmissionDeadline: jobValues.fileSubmissionDeadline
          ? jobValues.fileSubmissionDeadline.toISOString()
          : "",
        uploadedByUserId: currentUser?.userId,
        jobFiles: jobValues.jobFiles
      };
    } else return null;
  };

  var initialValues: any = {
    userId: null,
    firstName: null,
    lastName: null,
    dateCreated: null,
    phoneNumber: null,
    companyName: null,
    email: null,
    password: null,
    confirmPassword: null,
    userType: locationState?.userType ?? null,
    CNP: null,
    address: null,
    county: null,
    gen: null,
    expertType: null,
    dataNasterii: null,
    caen: null,
    locality: null,
    street: null,
    companyDescription: null,
    cui: null,
    fax: null,
    website: null,
    zone: null,
    entryDate: null,
    exitDate: null,
    privateEntity: null,
    imm: null,
    socialEntity: null,
    publicEntity: null,
    pocuEntity: null,
    job: {
      jobTitle: null,
      jobDescription: null,
      jobCounty: null,
      jobLocality: null,
      jobStreet: null,
      employmentRequirements: null,
      vacancy: null,
      vacanciesNumber: null,
      fileSubmissionDeadline: null,
      jobFiles: null,
    },
  };

  if (!isAddingNewUser)
    initialValues = {
      ...initialValues,
      ...userDetails,
      expertAssignedId: userDetails.expertUserId
        ? userDetails.expertUserId
        : null,
      dataNasterii: userDetails.dataNasterii
        ? new Date(userDetails.dataNasterii)
        : null,
      dataRecrutare: userDetails.dataRecrutare
        ? new Date(userDetails.dataRecrutare)
        : null,
      dataInformare: userDetails.dataInformare
        ? new Date(userDetails.dataInformare)
        : null,
      dateCreated: userDetails.dateCreated
        ? new Date(userDetails.dateCreated)
        : null,
      dataInregistrare: userDetails.dataInregistrare
        ? new Date(userDetails.dataInregistrare)
        : null,
      entryDate: userDetails.entryDate
        ? new Date(userDetails.entryDate)
        : null,
      exitDate: userDetails.exitDate
        ? new Date(userDetails.exitDate)
        : null,
      phoneNumber: userDetails.numarTelefon ?? userDetails.phoneNumber ?? ""
    };
  return (
    <Container
      id="user-details-page"
      maxWidth="lg"
      sx={{ minHeight: "inherit" }}
      disableGutters
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Grid
          container
          sx={{
            minHeight: "inherit",
          }}
          alignItems="stretch"
        >
          <Grid item xs={12} container direction="column" alignItems="stretch">
            {!isAddingNewUser && (
              <Grid item xs="auto">
                <Tabs value={activeTab} onChange={handleChange}>
                  <Tab label="Informații personale" />
                  {!isAddingNewUser && <Tab label="Documente personale" />}
                </Tabs>
              </Grid>
            )}
            <Grid item flexGrow={1}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                  setCurrentTimeForDates(values);
                  if (isAddingNewUser) {
                    if (isCompany(values?.userType)) {
                      dispatch(
                        registerCompanyUser({
                          userData: {
                            ...values,
                            birthDate: values.birthDate
                              ? values.birthDate.toISOString()
                              : null,
                            dataNasterii: values.dataNasterii
                              ? values.dataNasterii.toISOString()
                              : null,
                            dataRecrutare: values.dataRecrutare
                              ? values.dataRecrutare.toISOString()
                              : null,
                            dataInformare: values.dataInformare
                              ? values.dataInformare.toISOString()
                              : null,
                            dataInregistrare: values.dataInregistrare
                              ? values.dataInregistrare.toISOString()
                              : null,
                          },
                          token: currentUser?.jwtToken,
                        })
                      ).then(() => navigate(usersPath));
                    }
                    if (isExpert(values?.userType)) {
                      dispatch(
                        registerExpertUser({
                          userData: {
                            ...values,
                            dataNasterii: values.dataNasterii
                              ? values.dataNasterii.toISOString()
                              : null,
                          },
                          token: currentUser?.jwtToken,
                        })
                      ).then(() => navigate(usersPath));
                    }
                    if (isEmployer(values?.userType)) {
                      dispatch(
                        registerEmployerUser({
                          userData: {
                            ...values,
                            entryDate: values.entryDate
                              ? values.entryDate.toISOString()
                              : null,
                            exitDate: values.exitDate
                              ? values.exitDate.toISOString()
                              : null,
                            jobPost: isJobCompleted(values.job),
                          },
                          token: currentUser?.jwtToken,
                        })
                      ).unwrap().then((response) => {
                        if (response.success) {
                          navigate("/mediere/angajatori/0")
                        }
                      });
                    }
                  } else {
                    if (isEmployer(values?.userType)) {
                      dispatch(
                        updateEmployer({
                          userData: {
                            ...values,
                            entryDate: values.entryDate
                              ? values.entryDate.toISOString()
                              : null,
                            exitDate: values.exitDate
                              ? values.exitDate.toISOString()
                              : null,
                            userType: values?.userType.toString(),
                          },
                          token: currentUser?.jwtToken,
                        })
                      );
                    } else {
                      dispatch(
                        updateUser({
                          userData: {
                            ...values,
                            userType: values?.userType,
                          },
                          token: currentUser?.jwtToken,
                        })
                      );
                    }
                  }
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({ values, errors, setFieldValue }) => (
                  <Form>
                    {activeTab === 0 &&
                      (isEmployer(values.userType) ? (
                        <EmployerUserView
                          canEdit={canEdit}
                          isNewUser={isAddingNewUser}
                          setFieldValue={setFieldValue}
                        />
                      ) : (
                        !isCompany(values.userType) && (

                          <UserRepresentativeView
                            canEdit={canEdit}
                            isNewUser={isAddingNewUser}
                            userType={values.userType}
                          />
                        )
                      ))}
                    {activeTab === 1 && <UserPersonalDocumentsView />}
                  </Form>
                )}
              </Formik>
              {activeTab === 0 &&
                initialValues.userType === UserType.COMPANY_USER && (
                  <UserCompanyView />
                )}
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Container>
  );
};
