import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyUserBriefModel, GrantContestState } from "store/models";
import { downloadGrantContestPhase1Doc, deleteGrantContestPhase1Doc, approveGrantForUser, requestAnexa2Phase2Documents, requestPhase1Documents, requestCompanyUsersNames, requestAlteDocumentePhase2Documents, requestAnexa1Phase2Documents, downloadAnexa1Phase2Doc, deleteAnexa1Phase2Doc, downloadAnexa2Phase2Document, deleteAnexa2Phase2Document, deleteAlteDocumentePhase2Document, downloadAlteDocumentePhase2Document } from "store/thunks/grantContestThunks";


export const grantContestSlice = createSlice({
  name: "grantContestSlice",
  initialState: {
    actions: {},
    grantSortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
    anexa1Phase2SortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
    anexa2Phase2SortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
    alteDocumentePhase2SortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
    rowCount: 0,
    phase1DocumentsCount: 0,
    anexa1Phase2DocumentsCount: 0,
    anexa2Phase2DocumentsCount: 0,
    alteDocumentePhase2DocumentsCount: 0,
    userPhase1DocumentsListIsLoading: false,
    userAnexa1Phase2DocumentsListIsLoading: false,
    userAnexa2Phase2DocumentsListIsLoading: false,
    userAlteDocsPhase2DocumentsListIsLoading: false,
    userIsDownloadingGrantPhaseFile: false,
    userIsDownloadingAnexa1Phase2File: false,
    userIsDownloadingAnexa2Phase2File: false,
    userIsDownloadingAlteDocsPhase2File: false,
    userPhase1Documents: [],
    anexa1Phase2Documents: [],
    userAnexa2Phase2Documents: [],
    userAlteDocumentePhase2Documents: [],
    companyUsersNames: [],
    selectedUser: null as CompanyUserBriefModel | null,
    isGrantApprovedForSelectedUser: false,
    approvingGrant: false,
    grantApproved: false,
  } as GrantContestState,
  reducers: {
    setGrantSortModel(state, action) {
      state.grantSortModel.columnToSortBy = action.payload.columnToSortBy;
      state.grantSortModel.sortingOrder = action.payload.sortingOrder;
    },
    setAnexa2Phase2SortModel(state, action) {
      state.anexa2Phase2SortModel.columnToSortBy = action.payload.columnToSortBy;
      state.anexa2Phase2SortModel.sortingOrder = action.payload.sortingOrder;
    },
    setUserPhase1DocumentsListIsLoading(state, action) {
      state.userPhase1DocumentsListIsLoading = action.payload;
    },
    setUserIsDownloadingGrantPhaseFile(state, action) {
      state.userIsDownloadingGrantPhaseFile = action.payload;
    },
    setUserPhase1Documents(state, action) {
      state.userPhase1Documents = action.payload.phase1documents;
      state.phase1DocumentsCount = action.payload.rowCount;
    },
    setUserAnexa2Phase2Documents(state, action) {
      state.userAnexa2Phase2Documents = action.payload.anexa2documents;
      state.anexa2Phase2DocumentsCount = action.payload.rowCount;
    },
    setUserAlteDocumentePhase2Documents(state, action) {
      state.userAlteDocumentePhase2Documents = action.payload.altedocumente;
      state.alteDocumentePhase2DocumentsCount = action.payload.rowCount;
    },
    setAlteDocumentePhase2SortModel(state, action) {
      state.alteDocumentePhase2SortModel.columnToSortBy = action.payload.columnToSortBy;
      state.alteDocumentePhase2SortModel.sortingOrder = action.payload.sortingOrder;
    },
    setAnexa1Phase2SortModel(state, action) {
      state.anexa1Phase2SortModel.columnToSortBy = action.payload.columnToSortBy;
      state.anexa1Phase2SortModel.sortingOrder = action.payload.sortingOrder;
    },
    setAnexa1Phase2Documents(state, action) {
      state.anexa1Phase2Documents = action.payload.anexa1documents;
      state.anexa1Phase2DocumentsCount = action.payload.rowCount;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setApprovingGrant(state, action) {
      state.approvingGrant = action.payload;
    },
    setGrantApproved(state, action) {
      state.grantApproved = action.payload;
    },
    setIsGrantApprovedForSelectedUser(state, action) {
      state.isGrantApprovedForSelectedUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestPhase1Documents.pending, (state, action: PayloadAction<any>) => {
      state.userPhase1Documents = [];
      state.userPhase1DocumentsListIsLoading = true;

    });
    builder.addCase(requestPhase1Documents.fulfilled, (state, action: PayloadAction<any>) => {

      action.payload.documents.forEach((x: any) => {
        x.id = x.documentId
      });
      state.userPhase1Documents = action.payload.documents;
      state.userPhase1DocumentsListIsLoading = false;
      state.phase1DocumentsCount = action.payload.rowCount;
    });
    builder.addCase(requestPhase1Documents.rejected, (state, action: PayloadAction<any>) => {
      state.userPhase1DocumentsListIsLoading = false;
    });

    builder.addCase(requestAnexa1Phase2Documents.pending, (state, action: PayloadAction<any>) => {
      state.userAnexa1Phase2DocumentsListIsLoading = true;
    });
    builder.addCase(requestAnexa1Phase2Documents.fulfilled, (state, action: PayloadAction<any>) => {
      action.payload.documents.forEach((x: any) => {
        x.id = x.documentId
      });
      state.anexa1Phase2Documents = action.payload.documents;
      state.userAnexa1Phase2DocumentsListIsLoading = false;
      state.anexa1Phase2DocumentsCount = action.payload.rowCount;
    });
    builder.addCase(requestAnexa1Phase2Documents.rejected, (state, action: PayloadAction<any>) => {
      state.userAnexa1Phase2DocumentsListIsLoading = false;
    });
    builder.addCase(requestAnexa2Phase2Documents.fulfilled, (state, action: PayloadAction<any>) => {
      action.payload.documents.forEach((x: any) => {
        x.id = x.documentId
      });
      state.userAnexa2Phase2Documents = action.payload.documents;
      state.userAnexa2Phase2DocumentsListIsLoading = false;
      state.anexa2Phase2DocumentsCount = action.payload.rowCount;
    });

    builder.addCase(requestAlteDocumentePhase2Documents.fulfilled, (state, action: PayloadAction<any>) => {
      action.payload.documents.forEach((x: any) => {
        x.id = x.documentId
      });
      state.userAlteDocumentePhase2Documents = action.payload.documents;
      state.userAlteDocsPhase2DocumentsListIsLoading = false;
      state.alteDocumentePhase2DocumentsCount = action.payload.rowCount;
    });

    builder.addCase(
      requestCompanyUsersNames.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.companyUsersNames = action.payload.map(
            (elem: any) => ({
              ...elem,
              userId: elem.userId,
              firstName: elem.firstName,
              lastName: elem.lastName
            })
          );
        }
      }
    );


    builder.addCase(approveGrantForUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.approvingGrant = false;
      state.isGrantApprovedForSelectedUser = true;
    })
    builder.addCase(deleteGrantContestPhase1Doc.pending, (state, action: PayloadAction<any>) => {
      state.userPhase1DocumentsListIsLoading = true;
    });
    builder.addCase(deleteGrantContestPhase1Doc.fulfilled, (state, action: PayloadAction<any>) => {
      state.userPhase1DocumentsListIsLoading = false;
    });
    builder.addCase(deleteGrantContestPhase1Doc.rejected, (state, action: PayloadAction<any>) => {
      state.userPhase1DocumentsListIsLoading = false;
    });
    builder.addCase(downloadGrantContestPhase1Doc.fulfilled, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingGrantPhaseFile = false;
    });
    builder.addCase(downloadGrantContestPhase1Doc.pending, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingGrantPhaseFile = true;
    });
    builder.addCase(downloadGrantContestPhase1Doc.rejected, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingGrantPhaseFile = false;
    });


    builder.addCase(downloadAnexa1Phase2Doc.fulfilled, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAnexa1Phase2File = false;
    });
    builder.addCase(downloadAnexa1Phase2Doc.pending, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAnexa1Phase2File = true;
    });
    builder.addCase(downloadAnexa1Phase2Doc.rejected, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAnexa1Phase2File = false;
    });

    builder.addCase(deleteAnexa1Phase2Doc.pending, (state, action: PayloadAction<any>) => {
      state.userAnexa1Phase2DocumentsListIsLoading = true;
    });
    builder.addCase(deleteAnexa1Phase2Doc.fulfilled, (state, action: PayloadAction<any>) => {
      state.userAnexa1Phase2DocumentsListIsLoading = false;
    });

    builder.addCase(deleteAnexa1Phase2Doc.rejected, (state, action: PayloadAction<any>) => {
      state.userAnexa1Phase2DocumentsListIsLoading = false;
    });
    builder.addCase(requestAnexa2Phase2Documents.pending, (state, action: PayloadAction<any>) => {
      state.userAnexa2Phase2DocumentsListIsLoading = true;
    });
    builder.addCase(requestAnexa2Phase2Documents.rejected, (state, action: PayloadAction<any>) => {
      state.userAnexa2Phase2DocumentsListIsLoading = false;
    });
    builder.addCase(downloadAnexa2Phase2Document.fulfilled, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAnexa2Phase2File = false;
    });
    builder.addCase(downloadAnexa2Phase2Document.pending, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAnexa2Phase2File = true;
    });
    builder.addCase(downloadAnexa2Phase2Document.rejected, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAnexa2Phase2File = false;
    });
    builder.addCase(deleteAnexa2Phase2Document.pending, (state, action: PayloadAction<any>) => {
      state.userAnexa2Phase2DocumentsListIsLoading = true;
    });
    builder.addCase(deleteAnexa2Phase2Document.fulfilled, (state, action: PayloadAction<any>) => {
      state.userAnexa2Phase2DocumentsListIsLoading = false;
    });

    builder.addCase(deleteAnexa2Phase2Document.rejected, (state, action: PayloadAction<any>) => {
      state.userAnexa2Phase2DocumentsListIsLoading = false;
    });

    builder.addCase(requestAlteDocumentePhase2Documents.pending, (state, action: PayloadAction<any>) => {
      state.userAlteDocsPhase2DocumentsListIsLoading = true;
    });
    builder.addCase(requestAlteDocumentePhase2Documents.rejected, (state, action: PayloadAction<any>) => {
      state.userAlteDocsPhase2DocumentsListIsLoading = false;
    });
    builder.addCase(deleteAlteDocumentePhase2Document.pending, (state, action: PayloadAction<any>) => {
      state.userAlteDocsPhase2DocumentsListIsLoading = true;
    });
    builder.addCase(deleteAlteDocumentePhase2Document.fulfilled, (state, action: PayloadAction<any>) => {
      state.userAlteDocsPhase2DocumentsListIsLoading = false;
    });

    builder.addCase(deleteAlteDocumentePhase2Document.rejected, (state, action: PayloadAction<any>) => {
      state.userAlteDocsPhase2DocumentsListIsLoading = false;
    });

    builder.addCase(downloadAlteDocumentePhase2Document.fulfilled, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAlteDocsPhase2File = false;
    });
    builder.addCase(downloadAlteDocumentePhase2Document.pending, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAlteDocsPhase2File = true;
    });
    builder.addCase(downloadAlteDocumentePhase2Document.rejected, (state, action: PayloadAction<any>) => {
      state.userIsDownloadingAlteDocsPhase2File = false;
    });
  }
});
export const { setIsGrantApprovedForSelectedUser, setGrantApproved, setApprovingGrant, setSelectedUser, setUserAlteDocumentePhase2Documents, setAlteDocumentePhase2SortModel, setAnexa2Phase2SortModel, setUserPhase1Documents, setUserAnexa2Phase2Documents, setGrantSortModel, setUserPhase1DocumentsListIsLoading, setUserIsDownloadingGrantPhaseFile, setAnexa1Phase2Documents } = grantContestSlice.actions;