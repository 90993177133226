import { Close, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { JobModal } from "components/job-details/job-modal/JobModal";
import { DeleteConfirmationDialog } from "pages/manageDocuments-page/deleteConfirmationDialog/DeleteConfirmationDialog";
import { FC, useState } from "react";
import { useNavigate } from "react-router";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
} from "store";
import { StatusBeneficiarType } from "store/models/enums/StatusBeneficiarType";
import { JobPostModel } from "store/models/mediation";
import { countiesSelectItems } from "store/models/selectItems";
import { selectJobsFilters } from "store/selectors/mediationSelectors";
import { setJobDetails } from "store/slices/mediationSlice";
import {
  deleteJobPost,
  getFilteredPaginatedJobPosts,
  getJobPost,
} from "store/thunks/mediationThunks";
import { getDateLabel, onlyDateFormat } from "utils";
import { isAdmin, isCompany, isEmployer, isExpert } from "utils/getUserType";
import { ApplicateToJobDialog } from "../../applicate-to-job-dialog/ApplicateToJobDialog";
import "./JobCard.scss";

export const JobCard: FC<{ data: JobPostModel }> = ({ data }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectJobsFilters);
  const [displayDeleteDialog, setDisplayDeleteDialog] =
    useState<boolean>(false);
  const [applyDialog, setApplyDialog] = useState<boolean>(false);
  const [jobDialog, setJobDialog] = useState<boolean>(false);
  const ro = useAppSelector(selectMediationPageLocalization);

  const handleEditClick = () => {
    dispatch(setJobDetails(undefined));
    navigate("/anunt/" + data.employerId + "/" + data.jobPostId);
  };

  const handleViewClick = () => {
    if (isExpert(currentUser?.userType) || isAdmin(currentUser?.userType)) {
      navigate("/detalii-anunt/" + data.jobPostId);
    } else {
      dispatch(
        getJobPost({
          jobPostId: data.jobPostId,
          token: currentUser?.jwtToken,
        })
      ).then(() => {
        setJobDialog(true);
      });
    }
  };

  const openDeleteDialog = () => {
    setDisplayDeleteDialog(true);
  };

  const confirmDelete = () => {
    dispatch(
      deleteJobPost({
        jobPostId: data.jobPostId,
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        getFilteredPaginatedJobPosts({
          filters: filters,
          token: currentUser?.jwtToken,
        })
      );
      setDisplayDeleteDialog(false);
    });
  };

  const closeDialog = () => {
    setDisplayDeleteDialog(false);
  };

  const closeJobDialog = () => {
    setJobDialog(false);
  };

  const getActionsButtonsAllowed = () => {
    if (isEmployer(currentUser?.userType)) {
      if (data.employerId === currentUser?.userId) return true;
    }
    if (isCompany(currentUser?.userType)) return false;
    if (isExpert(currentUser?.userType) || isAdmin(currentUser?.userType))
      return true;
  };

  const handleShowApplyDialog = () => {
    setApplyDialog(true);
  };

  const closeApplyDialog = () => {
    setApplyDialog(false);
  };

  return (
    <Grid className="job-card">
      {!data.allocated && (
        <Tooltip title={ro.unallocatedJobsNumber}>
          <span className="jobs-left">{data.jobsLeft}</span>
        </Tooltip>
      )}
      <Card className="card">
        <Grid container className="card-container">
          <Grid container className="title-row" item xs={12}>
            <Grid item xs={8} className="title">
              <Tooltip title={data.title ?? ""}>
                <Typography variant="h5">
                  <span>{data.title ?? ""}</span>
                </Typography>
              </Tooltip>
            </Grid>
            {isCompany(currentUser?.userType) &&
              currentUser?.status !== StatusBeneficiarType.Employed &&
              !data.allocated && (
                <Grid item xs={4} className="apply-button">
                  <Button
                    size="small"
                    variant="contained"
                    className="apply-btn"
                    onClick={handleShowApplyDialog}
                  >
                    {ro.applyBtn}
                  </Button>
                </Grid>
              )}
            {getActionsButtonsAllowed() && (
              <Grid item xs={4} className="action-buttons">
                <Tooltip title={ro.editTooltip}>
                  <IconButton onClick={handleEditClick} color="warning">
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title={ro.deleteTooltip}>
                  <IconButton color="error" onClick={openDeleteDialog}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className="details-row">
            <CardContent>
              <Typography className="company-name">
                {data.companyName ?? ""}
              </Typography>
            </CardContent>
            <CardContent className="address-and-date">
              <Grid container className="county-city-container">
                <Typography variant="subtitle1" className="address">
                  {data.locality ?? "     "},
                </Typography>
                <Typography variant="subtitle1" className="address">
                  {countiesSelectItems?.find((x) => x.value === data.county)
                    ?.label ?? "      "}
                </Typography>
              </Grid>
              <Typography variant="subtitle1" className="upload-time">
                {getDateLabel(data.uploadTime, onlyDateFormat) ?? ""}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={12} className="actions-row">
            <CardActions className="view-and-status">
              <CardContent>
                {data.allocated ? (
                  <Box className="allocated-job">
                    <Typography>{ro.allocatedStatus}</Typography>
                    <span></span>
                  </Box>
                ) : (
                  <Box className="active-job">
                    <Typography>{ro.activeStatus}</Typography>
                    <span></span>
                  </Box>
                )}
              </CardContent>
              <Button className="view-details" onClick={handleViewClick}>
                {ro.seeBtn}
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
      <DeleteConfirmationDialog
        display={displayDeleteDialog}
        headerText={ro.deleteTitle}
        data={data}
        handleClose={closeDialog}
        handleConfirm={confirmDelete}
      />
      {applyDialog && (
        <ApplicateToJobDialog
          display={applyDialog}
          close={closeApplyDialog}
          jobPostId={data.jobPostId}
        />
      )}
      {jobDialog && <JobModal display={jobDialog} close={closeJobDialog} />}
    </Grid>
  );
};
