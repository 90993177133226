import { Grid, Skeleton } from "@mui/material";
import { NoResultsScreen } from "components/shared/noResultsScreen/NoResultsScreen";
import { FC, useEffect } from "react";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { JobPostModel } from "store/models/mediation";
import {
  selectJobs,
  selectJobsFilters,
  selectJobsLoading,
} from "store/selectors/mediationSelectors";
import { setJobsFilters } from "store/slices/mediationSlice";
import { getFilteredPaginatedJobPosts } from "store/thunks/mediationThunks";
import { JobCard } from "./job-card/JobCard";
import "./JobsDisplay.scss";

export const JobsDisplay: FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const jobs = useAppSelector(selectJobs);
  const loading = useAppSelector(selectJobsLoading);
  const filters = useAppSelector(selectJobsFilters);

  useEffect(() => {
    return () => {
      dispatch(
        setJobsFilters({
          searchText: "",
          pageNumber: 0,
          pageSize: 9,
          employerId: 0,
        })
      );
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      getFilteredPaginatedJobPosts({
        filters,
        token: currentUser?.jwtToken,
      })
    );

    //eslint-disable-next-line
  }, [filters]);

  const numberOfSkeletons = 9;
  const skeletons = Array.from({ length: numberOfSkeletons }, (_, i) => i + 1);

  return (
    <Grid id="jobs-display" container>
      {loading &&
        skeletons?.map((e: any) => {
          return (
            <Grid
              key={e}
              item
              xs={12}
              sm={6}
              md={4}
              className="job-card-container"
            >
              <Skeleton width="100%" height="185px"></Skeleton>
            </Grid>
          );
        })}
      {!loading &&
        jobs.length > 0 &&
        jobs?.map((job: JobPostModel) => {
          return (
            <Grid
              key={job.jobPostId}
              item
              xs={12}
              sm={6}
              md={4}
              className="job-card-container"
            >
              <JobCard data={job} />
            </Grid>
          );
        })}
      {jobs.length === 0 && !loading && <NoResultsScreen />}
    </Grid>
  );
};
