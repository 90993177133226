import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  deleteUser,
  deleteUserDocument,
  downloadUserDocument,
  generateUserForm,
  getAllExpertUsers,
  registerCompanyUser,
  requestGetAllCompanyUsers,
  requestUserDetails,
  requestUserDocuments,
  TargetGroupFilesResponse,
  uploadUserDocument,
} from "store";
import { CompanyUserBriefModel } from "store/models";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { UserDocumentType } from "store/models/enums/UserDocumentType";
import { ExpertUserModel } from "store/models/targetGroups/ExpertUserModel";
import { TargetGroupState } from "store/models/targetGroups/TargetGroupState";
import { TargetGroupUserModel } from "store/models/targetGroups/TargetGroupUserModel";
import { TargetUsersPaginatedModel } from "store/models/targetGroups/TargetUsersPaginatedModel";
import {
  CompanyUsersFilter,
  ExpertUsersFilters,
  UserModel,
} from "store/models/user/UserModel";

const initialUserDetailsState = {
  id: null,
  userId: null,
  firstName: null,
  lastName: null,
  dateCreated: null,
  companyName: null,
  email: null,
  phoneNo: null,
  password: null,
  confirmPassword: null,
  userType: null,
  expertUserId: null,
  faxNumber: null,
  CNP: null,
  address: null,
  judet: null,
  zona: null,
  birthDate: null,
  gen: null,
  studii: null,
  statut: null,
  curs: null,
  grupaCurs: null,
  functiaInCompanie: null,
  dataRecrutare: null,
  finalizat: null,
  workshop: null,
  sursaInformare: null,
  dataInformare: null,
  experts: [],
};

const initialState: TargetGroupState = {
  actions: {},
  sortModel: { columnToSortBy: "createdAt", sortingOrder: "desc" },
  rowCount: 0,
  users: [] as TargetGroupUserModel[],
  usersListIsLoading: true,
  targetGroupFilters: {
    pageNumber: 0,
    searchText: "",
    sortingOrder: false,
    columnToSortBy: DataGridColumnNames.LastName,
  } as CompanyUsersFilter,
  expertUsersFilters: {
    pageNumber: 0,
    columnToSortBy: DataGridColumnNames.LastName,
    sortingOrder: false,
    searchText: "",
    pageSize: 100,
  } as ExpertUsersFilters,
  userDocumentsListIsLoading: true,
  userIsDownloadingFile: false,
  userIsUploadingFile: false,
  deletingUser: false,
  deletingUserId: 0,
  userDetailsIsLoading: false,
  selectedTargetGroupUser: null as CompanyUserBriefModel | null,
  userDetails: null,
  experts: {
    rowCount: 0,
    expertUsers: [],
  },
  generatingUserForm: false,
};

export const targetGroupSlice = createSlice({
  name: "targetGroupSlice",
  initialState: initialState,
  reducers: {
    setUsers(state, action: PayloadAction<any>) {
      state.users = action.payload;
    },
    setUserType(state, action) {
      state.userDetails.userType = action.payload;
    },

    setTargetGroupFilters(state, action: PayloadAction<CompanyUsersFilter>) {
      state.targetGroupFilters = action.payload;
    },
    setExpertUsersFilters(state, action: PayloadAction<ExpertUsersFilters>) {
      state.expertUsersFilters = action.payload;
    },
    setSortModel(state, action) {
      state.sortModel.columnToSortBy = action.payload.columnToSortBy;
      state.sortModel.sortingOrder = action.payload.sortingOrder;
    },
    setUserDetails(state, action: PayloadAction<UserModel>) {
      state.userDetails = action.payload;
    },
    resetUserDetails(state) {
      state.userDetails = initialUserDetailsState;
    },
    setUsersListIsLoading(state, action: PayloadAction<boolean>) {
      state.usersListIsLoading = action.payload;
    },
    setUserDocummentsListIsLoading(state, action) {
      state.userDocumentsListIsLoading = action.payload;
    },
    setUserIsDownloadingFile(state, action) {
      state.userIsDownloadingFile = action.payload;
    },
    setUserIsUploadingFile(state, action) {
      state.userIsUploadingFile = action.payload;
    },
    setDeletingUser(state, action) {
      state.deletingUser = action.payload;
    },
    setDeletingUserId(state, action) {
      state.deletingUserId = action.payload;
    },
    setSelectedTargetGroupUser(state, action) {
      state.selectedTargetGroupUser = action.payload;
    },
    setUpdateCompanyDetails(state, action) {
      state.updatedCompanyDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateUserForm.pending, (state, action) => {
      state.generatingUserForm = true;
    });
    builder.addCase(generateUserForm.fulfilled, (state, action) => {
      state.generatingUserForm = false;
    });
    builder.addCase(generateUserForm.rejected, (state, action) => {
      state.generatingUserForm = false;
    });
    builder.addCase(getAllExpertUsers.pending, (state, action) => {});
    builder.addCase(getAllExpertUsers.fulfilled, (state, action) => {
      state.experts.expertUsers = action?.payload?.response?.expertUsers!;
      state.experts.rowCount = action.payload.response.rowCount;
    });
    builder.addCase(getAllExpertUsers.rejected, (state, action) => {
      state.experts = {
        rowCount: 10,
        expertUsers: [],
      };
    });
    builder.addCase(
      requestGetAllCompanyUsers.fulfilled,
      (state, action: PayloadAction<TargetUsersPaginatedModel>) => {
        action.payload.users.forEach((x: any) => {
          x.id = x.userId;
        });
        state.users = action.payload.users;
        state.rowCount = action.payload.rowCount;
        state.usersListIsLoading = false;
      }
    );
    builder.addCase(requestGetAllCompanyUsers.pending, (state, action) => {
      state.usersListIsLoading = true;
    });
    builder.addCase(requestGetAllCompanyUsers.rejected, (state, action) => {
      state.usersListIsLoading = false;
    });
    builder.addCase(registerCompanyUser.fulfilled, (state, action) => {});
    builder.addCase(deleteUserDocument.pending, (state, action) => {
      state.userDocumentsListIsLoading = true;
    });
    builder.addCase(deleteUserDocument.rejected, (state, action) => {
      state.userDocumentsListIsLoading = true;
    });
    builder.addCase(deleteUserDocument.fulfilled, (state, action) => {
      state.userDocumentsListIsLoading = true;
    });
    builder.addCase(downloadUserDocument.pending, (state, action) => {
      state.userIsDownloadingFile = true;
    });
    builder.addCase(downloadUserDocument.rejected, (state, action) => {
      state.userIsDownloadingFile = false;
    });
    builder.addCase(downloadUserDocument.fulfilled, (state, action) => {
      state.userIsDownloadingFile = false;
    });
    builder.addCase(uploadUserDocument.pending, (state, action) => {
      state.userIsUploadingFile = true;
    });
    builder.addCase(uploadUserDocument.rejected, (state, action) => {
      state.userIsUploadingFile = false;
    });
    builder.addCase(uploadUserDocument.fulfilled, (state, action) => {
      state.userIsUploadingFile = false;
    });
    builder.addCase(requestUserDetails.pending, (state, action) => {
      state.userDetailsIsLoading = true;
    });
    builder.addCase(
      requestUserDetails.fulfilled,
      (state, action: PayloadAction<ExpertUserModel | UserModel>) => {
        state.updatedCompanyDetails = action.payload;
        state.userDetails = {
          ...action.payload,
          birthDate: action.payload.dataNasterii,
          phoneNo: action.payload.numarTelefon,
        };
        state.userDetailsIsLoading = false;
      }
    );
    builder.addCase(requestUserDetails.rejected, (state, action) => {
      state.userDetailsIsLoading = false;
    });
    builder.addCase(requestUserDocuments.fulfilled, (state, action) => {
      action.payload.userDocuments.forEach((x: any) => {
        x.id = x.userDocumentId;
      });
      state.userDocumentsListIsLoading = false;
      switch (action.payload.userDocumentType) {
        case UserDocumentType.ActePersonale:
          state.userPersonalDocumentsResponse =
            action.payload as TargetGroupFilesResponse;
          break;
        case UserDocumentType.DosarGt:
          state.userGtDocumentsResponse =
            action.payload as TargetGroupFilesResponse;
          break;
      }
    });
    builder.addCase(requestUserDocuments.pending, (state, action) => {
      state.userDocumentsListIsLoading = true;
    });
    builder.addCase(requestUserDocuments.rejected, (state, action) => {
      state.userDocumentsListIsLoading = false;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.deletingUser = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.deletingUser = false;
    });
  },
});

export const {
  setUpdateCompanyDetails,
  setUserType,
  setTargetGroupFilters,
  setDeletingUserId,
  setDeletingUser,
  setUsers,
  setSortModel,
  setUserDetails,
  resetUserDetails,
  setUsersListIsLoading,
  setUserDocummentsListIsLoading,
  setUserIsDownloadingFile,
  setSelectedTargetGroupUser,
  setExpertUsersFilters,
} = targetGroupSlice.actions;
