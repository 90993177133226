import { ApplicationsSortBy } from "../enums/ApplicationsSortBy";

export const applicationsSortByItems = [
  { field: "userName", value: ApplicationsSortBy.UserName },
  { field: "userEmail", value: ApplicationsSortBy.Email },
  { field: "jobTitle", value: ApplicationsSortBy.JobTitle },
  { field: "jobCounty", value: ApplicationsSortBy.JobCounty },
  { field: "jobLocality", value: ApplicationsSortBy.JobLocality },
  { field: "status", value: ApplicationsSortBy.Status },
  { field: "cvName", value: ApplicationsSortBy.CvName },
  { field: "applicationDate", value: ApplicationsSortBy.ApplicationDate },
  { field: "allocationDate", value: ApplicationsSortBy.AllocationDate },
  {
    field: "allocatedByUsername",
    value: ApplicationsSortBy.AllocatedByUsername,
  },
  { field: "cvUpdateDate", value: ApplicationsSortBy.CvUpdateDate },
];
