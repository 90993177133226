import { County } from "../enums/County";

export const countiesSelectItems = [
  { label: "Alba", value: County.ALBA },
  { label: "Bihor", value: County.BIHOR },
  { label: "Bistrița Năsăud", value: County.BISTRITA_NASAUD },
  { label: "Brașov", value: County.BRASOV },
  { label: "Cluj", value: County.CLUJ },
  { label: "Covasna", value: County.COVASNA },
  { label: "Harghita", value: County.HARGHITA },
  { label: "Maramureș", value: County.MARAMURES },
  { label: "Mureș", value: County.MURES },
  { label: "Satu Mare", value: County.SATU_MARE },
  { label: "Sălaj", value: County.SALAJ },
  { label: "Sibiu", value: County.SIBIU },
];
