export const mapStatusBeneficiarToLabel = (statusBeneficiar: number) => {
  switch (statusBeneficiar) {
    case 1: {
      return "Recrutat";
    }
    case 2: {
      return "în formare";
    }
    case 3: {
      return "Format";
    }
    case 4: {
      return "Angajat";
    }
    default: {
      return "Recrutat";
    }
  }
};
