import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import locale from "date-fns/locale/ro";
import { Container } from "@mui/material";
import { FC, useEffect } from "react";
import { useParams } from "react-router";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { getJobPost } from "store/thunks/mediationThunks";
import { selectJobDetails } from "store/selectors/mediationSelectors";
import { AddOrEditJobForm } from "components/add-or-edit-job/AddOrEditJobForm";
import "./AddOrEditJobPage.scss";
import { setJobDetails } from "store/slices/mediationSlice";

export const AddOrEditJobPage: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const jobDetails = useAppSelector(selectJobDetails);
  useEffect(() => {
    if (params.jobId !== "0" && params.jobId !== ":jobId") {
      let jobId = parseInt(params.jobId!, 10);
      dispatch(
        getJobPost({
          jobPostId: jobId,
          token: currentUser?.jwtToken,
        })
      );
    } else {
      dispatch(
        setJobDetails({
          title: "",
          description: "",
        })
      );
    }
    return () => {
      dispatch(setJobDetails(undefined));
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl" id="add-or-edit-job-page">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        {jobDetails && <AddOrEditJobForm />}
        {/* <ImageCarousel /> */}
      </LocalizationProvider>
    </Container>
  );
};
