import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useState,
  UIEvent,
  useRef,
} from "react";
import {
  addUsersToSeries,
  getUsersOfSeries,
  getUsersOutOfSeries,
  requestCourseSeries,
  selectCourseSeries,
  selectCourseSeriesUserIsLoading,
  selectCurrentUser,
  selectUsersInAndOutOfSeries,
  useAppDispatch,
  useAppSelector,
  useDebounce,
  selectTotalSeriesPages,
  selectSeriesFilters,
  getCourseSeriesWithoutExamSeriesAssigned,
  selectVocationalTrainingPageLocalization,
  selectUsersInSeries,
} from "store";
import { UserModel } from "store/models/user/UserModel";
import { CourseSeries } from "store/models/vocationalTraining/CourseSeries";
import "./SeriesView.scss";

import { AnnexesDownload } from "./AnnexesDownload";

import { SeriesType } from "store/models/enums/SeriesType";
import {
  setCurrentPageNumber,
  setSearch,
  setSearchInsideSeries,
  setUsersInAndOutOfSeries,
  setUsersOfSeries,
} from "store/slices/vocationalTrainingSlice";
import { UpdateSeriesModel } from "store/models/vocationalTraining/UpdateSeriesModel";
import SearchIcon from "@mui/icons-material/Search";
import AddOrEditSeries from "./AddOrEditSeries";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "utils/Droppable";

export const SeriesView: FC<{ type: SeriesType }> = ({ type }) => {
  const [localSearchTextValue, setLocalSearchTextValue] = useState<string>("");
  const [columnState, setColumnState] = useState<
    { name: string; items: UserModel[] }[]
  >([]);
  const [outOfSeries, setOutOfSeries] = useState<UserModel[]>([]);
  const [ofSeries, setOfSeries] = useState<UserModel[]>([]);

  const [courseName, setCourseName] = useState("");

  const dispatch = useAppDispatch();
  const debouncedSearchTerm = useDebounce(localSearchTextValue, 500);
  const currentUser = useAppSelector(selectCurrentUser);
  const filters = useAppSelector(selectSeriesFilters);
  const courseSeries = useAppSelector(selectCourseSeries);
  const ro = useAppSelector(selectVocationalTrainingPageLocalization);

  useEffect(() => {
    dispatch(
      requestCourseSeries({
        type: type,
        token: currentUser?.jwtToken,
      })
    );
    return () => {
      dispatch(
        setUsersInAndOutOfSeries({
          usersOfSeries: [],
          usersOutOfSeries: [],
        })
      );
    };
    //eslint-disable-next-line
  }, []);

  const seriesSelectItems = useMemo(() => {
    if (courseSeries) {
      return courseSeries?.map((series: CourseSeries) => ({
        label: series.seriesName,
        value: series.seriesId,
      }));
    }
  }, [courseSeries]);

  const [selectedSeries, setSelectedSeries] = useState<
    number | null | undefined
  >();

  const [selectedSeriesToEdit, setSelectedSeriesToEdit] =
    useState<UpdateSeriesModel>();

  const usersInAndOutOfSeries = useAppSelector(selectUsersInAndOutOfSeries);
  const usersOfSeries = useAppSelector(selectUsersInSeries);
  const totalSeriesPages = useAppSelector(selectTotalSeriesPages);
  const courseSeriesUserIsLoading = useAppSelector(
    selectCourseSeriesUserIsLoading
  );

  const getUsersOutOfSeriesRequest = () => {
    if (filters.currentPageNumber === 0) {
      const progress = document.querySelector(".droppable-column");
      progress?.scrollTo({
        top: 0,
      });
    }

    dispatch(
      getUsersOutOfSeries({
        filter: {
          seriesId: Number(selectedSeries) ?? 0,
          pageNumber: filters.currentPageNumber,
          search: debouncedSearchTerm,
          type: type,
        },
        token: currentUser?.jwtToken,
      })
    );
  };

  const getInUsers = () => {
    if (selectedSeries) {
      dispatch(
        getUsersOfSeries({
          seriesId: Number(selectedSeries),
          type: type,
          token: currentUser?.jwtToken,
        })
      );
    }
  };

  const handleCheckedRight = (removed: UserModel) => {
    dispatch(
      addUsersToSeries({
        requestData: {
          seriesId: selectedSeries ?? 0,
          userIds: [Number(removed?.userId)],
          type: type,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        setUsersOfSeries([...usersInAndOutOfSeries.usersOfSeries, removed])
      );
      if (filters.currentPageNumber === 0) {
        getUsersOutOfSeriesRequest();
      } else {
        dispatch(setCurrentPageNumber(0));
      }
    });
  };

  const handleCheckedLeft = (removed: UserModel) => {
    dispatch(
      addUsersToSeries({
        requestData: {
          seriesId: 0,
          userIds: [Number(removed?.userId)],
          type: type,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        setUsersOfSeries(
          usersInAndOutOfSeries.usersOfSeries.filter(
            (user) => user.userId !== Number(removed?.userId)
          )
        )
      );

      if (filters.currentPageNumber === 0) {
        getUsersOutOfSeriesRequest();
      } else {
        dispatch(setCurrentPageNumber(0));
      }
    });
  };

  useEffect(() => {
    getInUsers();
    if (filters.currentPageNumber === 0 && selectedSeries) {
      getUsersOutOfSeriesRequest();
    } else {
      dispatch(setCurrentPageNumber(0));
    }
    //eslint-disable-next-line
  }, [selectedSeries]);

  useEffect(() => {
    dispatch(setSearch(debouncedSearchTerm));
    const progress = document.querySelector(".droppable-column");
    progress?.scrollTo({
      top: 0,
    });

    //eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (selectedSeries) getUsersOutOfSeriesRequest();

    //eslint-disable-next-line
  }, [filters]);

  const handleSelectSeries = (e: ChangeEvent<HTMLInputElement>) => {
    let val: string | null | undefined = e.target.value;
    if (val !== null || val !== undefined) {
      setSelectedSeries(parseInt(val, 10));
      const progress = document.querySelector(".droppable-column");
      progress?.scrollTo({
        top: 0,
      });

      selectCourseSeriesNameFromId(parseInt(val, 10));
    }
  };

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value ?? 0;
    });
    return ref.current;
  };
  const prevValue = usePrevious(filters.currentPageNumber);

  const handleScroll = (e: UIEvent<HTMLDivElement>, index: number) => {
    if (index === 0) {
      getTenPercentScrollRemaining(e.target as HTMLDivElement);
    } else return;
  };

  const getTenPercentScrollRemaining = (target: HTMLDivElement) => {
    var scrollAllowed = target.scrollHeight - target.clientHeight;
    var scrollLeft = scrollAllowed - target.scrollTop;
    var TenPercentScrollRemaining = (10 / 100) * scrollAllowed;

    if (totalSeriesPages - Number(prevValue) === 0) {
      return;
    } else if (
      scrollLeft <= TenPercentScrollRemaining &&
      !courseSeriesUserIsLoading
    ) {
      dispatch(setCurrentPageNumber(prevValue! + 1));
      const progress = document.querySelector(".droppable-column");
      progress?.scrollTo({
        top: progress.scrollHeight,
      });
    }
  };

  const [displayDialog, setDisplayDialog] = useState(false);

  const selectCourseSeriesNameFromId = (id: number) => {
    seriesSelectItems?.forEach((element) => {
      if (element.value === id) {
        setCourseName(element.label + ":");
      }
    });
  };

  const onDragEnd = (result: DropResult, columns: any, setColumns: any) => {
    if (result.destination == null) return;
    if (result.destination.droppableId === result.source.droppableId) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];

      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0);
      // destItems.splice(destination.index, 0, removed);
      //this line is if we want to change the order they appear in the list

      const obj = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };

      const arrayOfObjects = Object.values(obj);

      setColumnState(arrayOfObjects as any);

      if (Number(source.droppableId) < Number(destination.droppableId)) {
        handleCheckedRight(removed);
      } else handleCheckedLeft(removed);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
    }
  };

  const columns = [
    {
      name: ro.otherLabel,
      items: outOfSeries,
    },
    {
      name: courseName || ro.nameLabel,
      items: ofSeries,
    },
  ];

  useEffect(() => {
    setOutOfSeries(usersInAndOutOfSeries.usersOutOfSeries);

    //eslint-disable-next-line
  }, [usersInAndOutOfSeries.usersOutOfSeries]);

  useEffect(() => {
    setOfSeries(usersOfSeries);
  }, [usersOfSeries]);

  useEffect(() => {
    setColumnState(columns);

    //eslint-disable-next-line
  }, [outOfSeries, ofSeries]);

  const handleEditSeries = () => {
    var series = courseSeries?.find((x) => x.seriesId === selectedSeries);
    setSelectedSeriesToEdit(
      series
        ? ({
            seriesId: series.seriesId,
            seriesName: series.seriesName,
            adeverintaType: series.adeverintaType,
            type: type,
            examDate: series.examDate,
            courseSeriesId: series.courseSeriesId,
            periodStart: series.periodStart,
            periodEnd: series.periodEnd,
          } as UpdateSeriesModel)
        : undefined
    );
    dispatch(
      getCourseSeriesWithoutExamSeriesAssigned({
        courseSeriesId: series?.courseSeriesId!,
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      setDisplayDialog(true);
    });
  };

  const handleAddSeries = () => {
    if (type === SeriesType.Exam) {
      dispatch(
        getCourseSeriesWithoutExamSeriesAssigned({
          courseSeriesId: 0,
          token: currentUser?.jwtToken,
        })
      ).then(() => {
        setSelectedSeriesToEdit(undefined);
        setDisplayDialog(true);
      });
    } else {
      setSelectedSeriesToEdit(undefined);
      setDisplayDialog(true);
    }
  };

  return (
    <Container
      id="course-series-view"
      maxWidth="md"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingY: 1,
        paddingX: 1,
      }}
      disableGutters
    >
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={7}
          justifyContent="flex-start"
          container
          alignItems="end"
        >
          <TextField
            select
            size="small"
            name="series"
            label={type === SeriesType.Course ? ro.courseSerie : ro.examSerie}
            value={selectedSeries || ""}
            onChange={handleSelectSeries}
            sx={{ width: "150px" }}
          >
            {seriesSelectItems?.map((item: any) => (
              <MenuItem
                sx={{ maxWidth: 200 }}
                key={item.value}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="warning"
            disabled={!selectedSeries}
            onClick={handleEditSeries}
            sx={{ marginLeft: 1 }}
          >
            {ro.editSeriesBtn}
          </Button>
          <Tooltip title={ro.addTooltip}>
            <Button
              variant="contained"
              onClick={handleAddSeries}
              sx={{ marginLeft: 1 }}
            >
              {ro.addSeriesBtn}
            </Button>
          </Tooltip>
        </Grid>
        {type === SeriesType.Exam && (
          <Grid item xs={5} justifyContent="flex-end" container>
            <AnnexesDownload selectedSeries={selectedSeries} />
          </Grid>
        )}
      </Grid>
      <Grid className="droppable-container" container>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columnState, setColumnState)}
        >
          {columnState &&
            columnState?.map((column, columnIndex) => {
              return (
                <Box className="column-name" key={columnIndex}>
                  {columnIndex === 0 ? (
                    <>
                      <Typography className="title"> {column.name}</Typography>

                      <Input
                        className="search-input"
                        placeholder={ro.search}
                        fullWidth
                        onChange={(e) =>
                          setLocalSearchTextValue(e.target.value)
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title={
                          selectedSeries
                            ? seriesSelectItems?.find(
                                (x) => x.value === selectedSeries
                              )?.label + ":"
                            : "Serie:"
                        }
                      >
                        <Typography className="title">
                          {selectedSeries
                            ? seriesSelectItems?.find(
                                (x) => x.value === selectedSeries
                              )?.label + ":"
                            : "Serie:"}
                        </Typography>
                      </Tooltip>
                      <Input
                        className="search-input"
                        placeholder={ro.search}
                        fullWidth
                        onChange={(e) =>
                          dispatch(setSearchInsideSeries(e.target.value))
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </>
                  )}
                  <Box>
                    <StrictModeDroppable
                      droppableId={columnIndex.toString()}
                      key={columnIndex}
                    >
                      {(provided: any, snapshot: any) => {
                        return (
                          <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            onScroll={(e: any) => handleScroll(e, columnIndex)}
                            className={
                              columnIndex === 0
                                ? "droppable-column"
                                : "droppable-column-inside "
                            }
                            sx={{
                              background: snapshot.isDraggingOver
                                ? "lightblue"
                                : "transparent",
                            }}
                          >
                            {!selectedSeries && columnIndex === 1 && (
                              <Grid className="empty-column">
                                <Typography>{ro.selectSeriesLabel}</Typography>
                              </Grid>
                            )}
                            {column?.items?.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.userId?.toString()}
                                  draggableId={item.userId?.toString()!}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="company-name"
                                        sx={{
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <Tooltip
                                          placement="left"
                                          title={
                                            item.lastName + " " + item.firstName
                                          }
                                        >
                                          <Typography className="user-name">
                                            {item.lastName} {item.firstName}
                                          </Typography>
                                        </Tooltip>
                                        {columnIndex === 0 &&
                                          item?.seriesName && (
                                            <Typography className="series-name">
                                              - {item?.seriesName}
                                            </Typography>
                                          )}
                                      </Box>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}

                            {provided.placeholder}
                          </Box>
                        );
                      }}
                    </StrictModeDroppable>
                  </Box>
                </Box>
              );
            })}
        </DragDropContext>
      </Grid>
      <Dialog
        open={displayDialog}
        maxWidth="sm"
        onClose={() => {
          setDisplayDialog(false);
          setSelectedSeriesToEdit(undefined);
        }}
        disableScrollLock
      >
        <AddOrEditSeries
          selectedSeriesToEdit={selectedSeriesToEdit}
          type={type}
          setDisplayDialog={setDisplayDialog}
          setSelectedSeries={setSelectedSeries}
        />
      </Dialog>
    </Container>
  );
};
