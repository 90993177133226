import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";

import { useAppSelector } from "store";
import {
  selectLoadingStatistics,
  selectParticipantsNumbers,
} from "store/selectors/statisticsSelectors";

export const StatisticsTable = () => {
  const statistics = useAppSelector(selectParticipantsNumbers);
  const loading = useAppSelector(selectLoadingStatistics);

  const column: GridColDef[] = [
    {
      field: "employedRequired",
      headerName: "Angajati necesari",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "employedNr",
      headerName: "Angajati",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "formedRequired",
      headerName: "Formați necesari",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "formedNr",
      headerName: "Formați",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "recruitedRequired",
      headerName: "Recrutați necesari",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "recruitedNr",
      headerName: "Recrutați",
      flex: 1,
      minWidth: 150,
    },
  ];

  return (
    <DataGrid
      className="applications-data-grid"
      components={{
        LoadingOverlay: DataLoadingComponent,
      }}
      loading={loading}
      page={1}
      pageSize={10}
      rowsPerPageOptions={[10]}
      hideFooterSelectedRowCount={true}
      disableSelectionOnClick={true}
      paginationMode="server"
      localeText={{
        noRowsLabel: "Fără rezultate",
      }}
      sx={{
        "& .MuiDataGrid-row:hover": {
          background: "aliceblue",
        },
        height: 650,
        boxShadow: 3,
      }}
      getRowId={(row) => "1"}
      rows={[statistics]}
      columns={column}
      //   onPageChange={handlePageChange}
      //   rowCount={totalCount}
      disableColumnMenu={true}
      sortingMode="server"
      //   onSortModelChange={handleSortModelChange}
    ></DataGrid>
  );
};
