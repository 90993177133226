import { CardMedia, Container } from "@mui/material";
import { FC, useState, useEffect } from "react";
import backgroundImage from "../../assets/images/shutterstock_736534063.jpg";
import pliant_fata from "../../assets/images/pliant_a4_fata.jpg";
import pliant_verso from "../../assets/images/pliant_a4_verso.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "./HomePage.scss";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";

export const HomePage: FC = () => {
  const [selectedImage, setSelectedImage] = useState<number>(0);
  let images = [pliant_fata, pliant_verso];
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleNext = () => {
    if (selectedImage === images.length - 1) {
      setSelectedImage(0);
    } else {
      setSelectedImage(selectedImage + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedImage === 0) {
      setSelectedImage(images.length - 1);
    } else {
      setSelectedImage(selectedImage - 1);
    }
  };
  useEffect(() => {
    if (selectedImage > images.length) {
      setSelectedImage((prev) => prev - 1);
    }

    //eslint-disable-next-line
  }, [images]);

  return (
    <Container
      id="home-page"
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: "inherit",
        backgroundImage: `url("${backgroundImage}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ minHeight: "inherit" }}
        className="carousel-container-homepage"
      >
        <div className="main-img-container">
          {images && (
            <>
              {loaded && (
                <ArrowBackIcon
                  className="back-arrow"
                  onClick={() => {
                    handlePrevious();
                  }}
                />
              )}

              {!loaded && <DataLoadingComponent />}
              <CardMedia
                component="img"
                image={images[selectedImage]}
                onLoad={() => setLoaded(true)}
                sx={loaded ? { display: "inline-block" } : { display: "none" }}
              />
              {loaded && (
                <ArrowForwardIcon
                  className="forward-arrow"
                  onClick={() => {
                    handleNext();
                  }}
                />
              )}
            </>
          )}
        </div>
        {loaded && (
          <div className="img-containers">
            {images?.map((img, index) => {
              return (
                <span
                  key={index}
                  onClick={() => setSelectedImage(index)}
                  className="each-span"
                  style={
                    index === selectedImage
                      ? { opacity: "1" }
                      : { opacity: "0.7" }
                  }
                ></span>
              );
            })}
          </div>
        )}
      </Container>
    </Container>
  );
};
