import { Fragment, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserType } from "../../../store/models/enums/UserType";
import { loginPath } from "../../../navigation/constants";
import { getFeatureStatus, requestLogout, selectCurrentUser, selectIsRefreshing, selectIsSilentRefresh, useAppDispatch, useAppSelector } from "store";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface RequireAuthProps {
    roles?: Array<UserType>;
}

export const RequireAuthComponent: React.FC<RequireAuthProps> = ({
    roles,
    children,
}) => {
    const dispatch = useAppDispatch();

    const currentUser = useAppSelector(selectCurrentUser);
    const isRefreshing = useAppSelector(selectIsRefreshing);
    const isSilentRefresh = useAppSelector(selectIsSilentRefresh);

    useEffect(() => {
        dispatch(getFeatureStatus({
            token: currentUser?.jwtToken
        }))
        //eslint-disable-next-line
    }, [])

    let userHasRequiredRole = false;
    userHasRequiredRole =
        currentUser && roles && roles.indexOf(currentUser.userType) !== -1
            ? true
            : false;

    if (isRefreshing && !isSilentRefresh) {
        return (
            <Backdrop
                open={true}
                sx={{
                    color: "#fff",
                    zIndex: 999999,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress color="inherit" />
                    <Typography variant="h6" color="primary.text">
                        Se încarcă
                    </Typography>
                </Box>
            </Backdrop>
        );
    } else {
        if (
            (currentUser && userHasRequiredRole) ||
            roles === undefined ||
            isRefreshing
        ) {
            return <Fragment>{children}</Fragment>;
        }
        if (currentUser?.jwtToken) dispatch(requestLogout(currentUser.jwtToken));
        return <Navigate to={loginPath} />;
    }
};
