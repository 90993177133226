import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { VocationalTrainingFilter } from "store/models/vocationalTraining/VocationalTrainingDocument";
import { baseUrl, getAxiosErrorMessage } from "utils";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { GetUsersInAndOutOfSeriesModel } from "store/models/vocationalTraining/getUsersInAndOutOfSeriesModel";
import { SeriesType } from "store/models/enums/SeriesType";
import { AdeverintaType } from "store/models/enums/AdeverintaType";
import { UpdateSeriesModel } from "store/models/vocationalTraining/UpdateSeriesModel";
const axios = require("axios").default;

export const requestExamResultsDocs = createAsyncThunk<
  //return type
  any,
  //payload type
  { filter: VocationalTrainingFilter; token?: string | null | undefined },
  AppThunkConfig
>(
  "vocationalTraining/requestExamResultsDocs",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: vocationalTrainingFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "ExpertDocuments/GetPaginatedExamResultsDocs",
        {
          params: vocationalTrainingFilter,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadExamResultsDocs = createAsyncThunk<
  //return type
  any,
  //payload type
  any,
  AppThunkConfig
>(
  "vocationalTraining/uploadExamResultsDocs",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ files, seriesId, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      files.forEach((file: string | Blob) => {
        form.append("files", file);
      });
      form.append("seriesId", seriesId);
      let result = await axios.post(
        baseUrl + "ExpertDocuments/UploadExamResultsDoc",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message ?? "Document incarcat!",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteExamResultsDoc = createAsyncThunk<
  //return type
  any,
  //payload type
  { docId: number; token?: string },
  AppThunkConfig
>(
  "vocationalTraining/deleteExamResultsDoc",
  async ({ docId, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("docId", docId.toString());
      let result = await axios.post(
        baseUrl + "ExpertDocuments/DeleteExamResultsDoc",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message?.text ?? "Document eliminat!",
          severity: "success",
        })
      );
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestExtraFilesDocs = createAsyncThunk<
  //return type
  any,
  //payload type
  { filter: VocationalTrainingFilter; token?: string | null | undefined },
  AppThunkConfig
>(
  "vocationalTraining/requestExtraFilesDocs",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: vocationalTrainingFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "ExpertDocuments/GetPaginatedVocationalMaterialDocs",
        {
          params: {
            pageNumber: vocationalTrainingFilter.pageNumber.toString(),
            columnToSortBy: vocationalTrainingFilter.columnToSortBy,
            sortingOrder: vocationalTrainingFilter.sortingOrder,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadExtraFilesDocs = createAsyncThunk<
  //return type
  any,
  //payload type
  any,
  AppThunkConfig
>(
  "vocationalTraining/uploadExtraFilesDocs",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ files, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      files.forEach((file: string | Blob) => {
        form.append("files", file);
      });
      let result = await axios.post(
        baseUrl + "ExpertDocuments/UploadVocationalMaterialDoc",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message ?? "Document incarcat!",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteExtraFilesDoc = createAsyncThunk<
  //return type
  any,
  //payload type
  { docId: number; token?: string },
  AppThunkConfig
>(
  "vocationalTraining/deleteExtraFilesDoc",
  async ({ docId, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("docId", docId.toString());
      let result = await axios.post(
        baseUrl + "ExpertDocuments/DeleteVocationalMaterialDoc",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message?.text ?? "Document eliminat!",
          severity: "success",
        })
      );
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const addNewCourseSeries = createAsyncThunk<
  number,
  {
    seriesName: string;
    adeverintaType: AdeverintaType;
    type: SeriesType;
    examDate?: string;
    courseSeriesId?: number;
    periodStart?: string;
    periodEnd?: string;
    token?: string | null;
  },
  AppThunkConfig
>(
  "vocationalTraining/createCourseSeries",
  async (
    {
      seriesName,
      adeverintaType,
      type,
      examDate,
      courseSeriesId,
      periodStart,
      periodEnd,
      token,
    },
    thunkAPI
  ) => {
    try {
      let { data } = await axios.post(
        baseUrl + "ExpertDocuments/CreateCourseSeries",
        {
          seriesName: seriesName,
          adeverintaType: adeverintaType,
          type: type,
          examDate: examDate,
          courseSeriesId: courseSeriesId,
          periodStart: periodStart,
          periodEnd: periodEnd,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message: data?.message?.text ?? "Serie curs adăugată!",
          severity: "success",
        })
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const updateCourseSeries = createAsyncThunk<
  any,
  { dto: UpdateSeriesModel; token?: string | null },
  AppThunkConfig
>("vocationalTraining/updateCourseSeries", async ({ dto, token }, thunkAPI) => {
  try {
    let { data } = await axios.post(
      baseUrl + "ExpertDocuments/UpdateCourseSeries",
      dto,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message?.text ?? "Serie curs adăugată!",
        severity: "success",
      })
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
    } else {
      throw err;
    }
  }
});

export const requestCourseSeries = createAsyncThunk<
  any,
  { type: SeriesType; token: string | null | undefined },
  AppThunkConfig
>("vocationalTraining/getCourseSeries", async ({ token, type }, thunkAPI) => {
  try {
    let { data } = await axios.get(
      baseUrl + "ExpertDocuments/GetCourseSeries",
      {
        params: {
          type: type,
        },
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
    } else {
      throw err;
    }
  }
});

export const getCourseSeriesWithoutExamSeriesAssigned = createAsyncThunk<
  any,
  { token: string | null | undefined; courseSeriesId: number },
  AppThunkConfig
>(
  "vocationalTraining/getCourseSeriesWithoutExamSeriesAssigned",
  async ({ token, courseSeriesId }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "ExpertDocuments/GetCourseSeriesWithoutExamSeriesAssigned",
        {
          params: {
            courseSeriesId: courseSeriesId,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const getAssignedSeries = createAsyncThunk<
  number,
  { token: string | null | undefined },
  AppThunkConfig
>("vocationalTraining/getAssignedSeries", async ({ token }, thunkAPI) => {
  try {
    let { data } = await axios.get(
      baseUrl + "ExpertDocuments/GetAssignedSeries",
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
    } else {
      throw err;
    }
  }
});

export const generateAdeverinta = createAsyncThunk<
  any,
  { seriesId: number | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAdeverinta",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAdeverinta",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const downloadExamResultsDoc = createAsyncThunk<
  //return type
  any,
  //payload type
  any,
  AppThunkConfig
>(
  "vocationalTraining/downloadExamResultsDocument",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "ExpertDocuments/DownloadExamResultsDocument",
        {
          params: {
            fileId: fileId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);
export const downloadVocationalTrainingExtraFileDoc = createAsyncThunk<
  //return type
  any,
  //payload type
  any,
  AppThunkConfig
>(
  "vocationalTraining/downloadVocationalMaterialDocument",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "ExpertDocuments/DownloadVocationalMaterialDocument",
        {
          params: {
            fileId: fileId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const getUsersOutOfSeries = createAsyncThunk<
  any,
  { filter: GetUsersInAndOutOfSeriesModel; token: string | null | undefined },
  AppThunkConfig
>(
  "vocationalTraining/getUsersOutOfSeries",
  async ({ token, filter }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "ExpertDocuments/GetUsersOutOfSeries",
        {
          params: filter,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const getUsersOfSeries = createAsyncThunk<
  any,
  { seriesId: number; type: SeriesType; token: string | null | undefined },
  AppThunkConfig
>(
  "vocationalTraining/getUsersOfSeries",
  async ({ seriesId, type, token }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "ExpertDocuments/GetUsersOfSeries",
        {
          params: {
            seriesId: seriesId,
            type: type,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const addUsersToSeries = createAsyncThunk<
  any,
  {
    requestData: {
      seriesId: number | null | undefined;
      userIds: number[];
      type: SeriesType;
    };
    token: string | null | undefined;
  },
  AppThunkConfig
>(
  "vocationalTraining/addUserToCourseSeries",
  async ({ token, requestData }, thunkAPI) => {
    try {
      let { data } = await axios.post(
        baseUrl + "ExpertDocuments/AddUserToCourseSeries",
        requestData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message:
            data?.message?.text ?? "Utilizatorii au fost adăugați în serie.",
          severity: "success",
        })
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const downloadUsersAcceptedToCourseAnexa = createAsyncThunk<
  any,
  any,
  AppThunkConfig
>(
  "vocationalTraining/downloadUsersAcceptedToCourseAnexa",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/DownloadUsersAcceptedToCourseAnexa",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa8 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa8",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa8",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa11 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa11",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa11",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa12 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa12",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa12",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa13 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa13",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa13",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa14 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa14",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa14",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa15 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa15",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa15",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa16 = createAsyncThunk<
  any,
  { seriesId: number | null | undefined; token: string | undefined },
  AppThunkConfig
>(
  "vocationalTraining/generateAnexa16",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa16",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const downloadAnexa11Archive = createAsyncThunk<
  any,
  any,
  AppThunkConfig
>(
  "vocationalTraining/downloadAnexa11Archive",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/DownloadAnexa11Archive",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const downloadAnexa13Archive = createAsyncThunk<
  any,
  any,
  AppThunkConfig
>(
  "vocationalTraining/downloadAnexa13Archive",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/DownloadAnexa13Archive",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const downloadAnexa22Archive = createAsyncThunk<
  any,
  any,
  AppThunkConfig
>(
  "vocationalTraining/downloadAnexa22Archive",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/DownloadAnexa22Archive",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa14Old = createAsyncThunk<any, any, AppThunkConfig>(
  "vocationalTraining/generateAnexa14Old",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa14Old",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa23 = createAsyncThunk<any, any, AppThunkConfig>(
  "vocationalTraining/generateAnexa23",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa23",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);

export const generateAnexa24 = createAsyncThunk<any, any, AppThunkConfig>(
  "vocationalTraining/generateAnexa24",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa24",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);
export const generateAnexa25 = createAsyncThunk<any, any, AppThunkConfig>(
  "vocationalTraining/generateAnexa25",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa25",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);
export const generateAnexa26 = createAsyncThunk<any, any, AppThunkConfig>(
  "vocationalTraining/generateAnexa26",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa26",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);
export const generateAnexa27 = createAsyncThunk<any, any, AppThunkConfig>(
  "vocationalTraining/generateAnexa27",
  async ({ seriesId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateAnexa27",
        {
          params: {
            seriesId: seriesId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
      } else {
        throw err;
      }
    }
  }
);
