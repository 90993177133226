import { Container } from "@mui/material";
import { FC, useEffect } from "react";
import { AllocationsTable } from "./allocations-table/AllocationsTable";
import { AllocationsFilters } from "./filters/AllocationsFilters";
import "./AllocationsTab.scss";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { getJobsWithAllocations } from "store/thunks/mediationThunks";

export const AllocationsTab: FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(
      getJobsWithAllocations({
        token: currentUser?.jwtToken,
      })
    );
    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl" id="allocations-tab">
      <AllocationsFilters />
      <AllocationsTable />
    </Container>
  );
};
