import { AllocationsSortBy } from "../enums/AllocationsSortBy";

export const allocationsSortByItems = [
  {field:"username",value:AllocationsSortBy.Username},
  {field:"jobTitle",value:AllocationsSortBy.JobTitle},
  {field:"jobCounty",value:AllocationsSortBy.JobCounty},
  {field:"jobLocality",value:AllocationsSortBy.JobLocality},
  {field:"allocationTime",value:AllocationsSortBy.AllocationTime},
  {field:"email",value:AllocationsSortBy.Email},
  {field:"phoneNumber",value:AllocationsSortBy.PhoneNumber}
]