export const BrasovSelectItemsLabel = [
  { value: "ACRIȘ", label: "ACRIȘ" },
  { value: "APAȚA", label: "APAȚA" },
  { value: "ARINI", label: "ARINI" },
  { value: "AUGUSTIN", label: "AUGUSTIN" },
  { value: "BĂRCUT", label: "BĂRCUT" },
  { value: "BECLEAN", label: "BECLEAN" },
  { value: "BEIA", label: "BEIA" },
  { value: "BERIVOI", label: "BERIVOI" },
  { value: "BOD", label: "BOD" },
  { value: "BOGATA OLTEANĂ", label: "BOGATA OLTEANĂ" },
  { value: "BOHOLȚ", label: "BOHOLȚ" },
  { value: "BRAN", label: "BRAN" },
  { value: "BRAȘOV", label: "BRAȘOV" },
  { value: "BREAZA", label: "BREAZA" },
  { value: "BUCIUM", label: "BUCIUM" },
  { value: "BUDILA", label: "BUDILA" },
  { value: "BUNEȘTI", label: "BUNEȘTI" },
  { value: "BUZĂIEL", label: "BUZĂIEL" },
  { value: "CALBOR", label: "CALBOR" },
  { value: "CĂRPINIȘ", label: "CĂRPINIȘ" },
  { value: "CAȚA", label: "CAȚA" },
  { value: "CHEIA", label: "CHEIA" },
  { value: "CINCȘOR", label: "CINCȘOR" },
  { value: "CINCU", label: "CINCU" },
  { value: "COBOR", label: "COBOR" },
  { value: "CODLEA", label: "CODLEA" },
  { value: "COLONIA 1 MAI", label: "COLONIA 1 MAI" },
  { value: "COLONIA BOD", label: "COLONIA BOD" },
  { value: "COLONIA RECONSTRUCȚIA", label: "COLONIA RECONSTRUCȚIA" },
  { value: "COMANA DE JOS", label: "COMANA DE JOS" },
  { value: "COMANA DE SUS", label: "COMANA DE SUS" },
  { value: "COPĂCEL", label: "COPĂCEL" },
  { value: "CORBI", label: "CORBI" },
  { value: "CRIHALMA", label: "CRIHALMA" },
  { value: "CRISTIAN", label: "CRISTIAN" },
  { value: "CRIȚ", label: "CRIȚ" },
  { value: "CRIZBAV", label: "CRIZBAV" },
  { value: "CUCIULATA", label: "CUCIULATA" },
  { value: "CUTUȘ", label: "CUTUȘ" },
  { value: "DACIA", label: "DACIA" },
  { value: "DĂIȘOARA", label: "DĂIȘOARA" },
  { value: "DĂLGHIU", label: "DĂLGHIU" },
  { value: "DEJANI", label: "DEJANI" },
  { value: "DOPCA", label: "DOPCA" },
  { value: "DRĂGUȘ", label: "DRĂGUȘ" },
  { value: "DRĂUȘENI", label: "DRĂUȘENI" },
  { value: "DRIDIF", label: "DRIDIF" },
  { value: "DRUMUL CARULUI", label: "DRUMUL CARULUI" },
  { value: "DUMBRĂVIȚA", label: "DUMBRĂVIȚA" },
  { value: "FĂGĂRAȘ", label: "FĂGĂRAȘ" },
  { value: "FÂNTÂNA", label: "FÂNTÂNA" },
  { value: "FELDIOARA / FELDIOARA", label: "FELDIOARA / FELDIOARA" },
  { value: "FELDIOARA / UCEA", label: "FELDIOARA / UCEA" },
  { value: "FELMER", label: "FELMER" },
  { value: "FIȘER", label: "FIȘER" },
  { value: "FUNDATA", label: "FUNDATA" },
  { value: "FUNDĂȚICA", label: "FUNDĂȚICA" },
  { value: "GHIMBAV", label: "GHIMBAV" },
  { value: "GRÂNARI", label: "GRÂNARI" },
  { value: "GRID", label: "GRID" },
  { value: "GURA VĂII", label: "GURA VĂII" },
  { value: "HĂLCHIU", label: "HĂLCHIU" },
  { value: "HĂLMEAG", label: "HĂLMEAG" },
  { value: "HĂRMAN", label: "HĂRMAN" },
  { value: "HÂRSENI", label: "HÂRSENI" },
  { value: "HOGHIZ", label: "HOGHIZ" },
  { value: "HOLBAV", label: "HOLBAV" },
  { value: "HOMOROD", label: "HOMOROD" },
  { value: "HUREZ", label: "HUREZ" },
  { value: "IAȘI", label: "IAȘI" },
  { value: "ILENI", label: "ILENI" },
  { value: "IONEȘTI", label: "IONEȘTI" },
  { value: "JIBERT", label: "JIBERT" },
  { value: "JIMBOR", label: "JIMBOR" },
  { value: "LISA", label: "LISA" },
  { value: "LOVNIC", label: "LOVNIC" },
  { value: "LUDIȘOR", label: "LUDIȘOR" },
  { value: "LUNCA CALNICULUI", label: "LUNCA CALNICULUI" },
  { value: "LUPȘA", label: "LUPȘA" },
  { value: "LUȚA", label: "LUȚA" },
  { value: "MĂGURA", label: "MĂGURA" },
  { value: "MĂIERUȘ", label: "MĂIERUȘ" },
  { value: "MĂLINIȘ", label: "MĂLINIȘ" },
  { value: "MÂNDRA", label: "MÂNDRA" },
  { value: "MĂRGINENI", label: "MĂRGINENI" },
  { value: "MATEIAȘ", label: "MATEIAȘ" },
  { value: "MERCHEAȘA", label: "MERCHEAȘA" },
  { value: "MEȘENDORF", label: "MEȘENDORF" },
  { value: "MOIECIU DE JOS", label: "MOIECIU DE JOS" },
  { value: "MOIECIU DE SUS", label: "MOIECIU DE SUS" },
  { value: "OHABA", label: "OHABA" },
  { value: "OLTEȚ", label: "OLTEȚ" },
  { value: "ORMENIȘ", label: "ORMENIȘ" },
  { value: "PALOȘ", label: "PALOȘ" },
  { value: "PALTIN", label: "PALTIN" },
  { value: "PĂRĂU", label: "PĂRĂU" },
  { value: "PÂRÂUL RECE", label: "PÂRÂUL RECE" },
  { value: "PERȘANI", label: "PERȘANI" },
  { value: "PEȘTERA", label: "PEȘTERA" },
  { value: "PODU OLTULUI", label: "PODU OLTULUI" },
  { value: "POIANA BRAȘOV", label: "POIANA BRAȘOV" },
  { value: "POIANA MĂRULUI", label: "POIANA MĂRULUI" },
  { value: "POJORTA", label: "POJORTA" },
  { value: "PREDEAL", label: "PREDEAL" },
  { value: "PREDELUȚ", label: "PREDELUȚ" },
  { value: "PREJMER", label: "PREJMER" },
  { value: "PURCĂRENI", label: "PURCĂRENI" },
  { value: "RACOȘ", label: "RACOȘ" },
  { value: "RÂȘNOV", label: "RÂȘNOV" },
  { value: "RÂUȘOR", label: "RÂUȘOR" },
  { value: "RECEA", label: "RECEA" },
  { value: "ROADEȘ", label: "ROADEȘ" },
  { value: "RODBAV", label: "RODBAV" },
  { value: "ROTBAV", label: "ROTBAV" },
  { value: "RUCĂR", label: "RUCĂR" },
  { value: "RUPEA", label: "RUPEA" },
  { value: "SĂCELE", label: "SĂCELE" },
  { value: "SÂMBĂTA DE JOS", label: "SÂMBĂTA DE JOS" },
  { value: "SÂMBĂTA DE SUS", label: "SÂMBĂTA DE SUS" },
  { value: "SÂNPETRU", label: "SÂNPETRU" },
  { value: "SĂSCIORI", label: "SĂSCIORI" },
  { value: "SATU NOU", label: "SATU NOU" },
  { value: "SĂVĂSTRENI", label: "SĂVĂSTRENI" },
  { value: "SEBEȘ", label: "SEBEȘ" },
  { value: "SELIȘTAT", label: "SELIȘTAT" },
  { value: "ȘERCAIA", label: "ȘERCAIA" },
  { value: "ȘERCĂIȚA", label: "ȘERCĂIȚA" },
  { value: "ȘIMON", label: "ȘIMON" },
  { value: "ȘINCA NOUĂ", label: "ȘINCA NOUĂ" },
  { value: "ȘINCA VECHE", label: "ȘINCA VECHE" },
  { value: "ȘIRNEA", label: "ȘIRNEA" },
  { value: "ȘOARȘ", label: "ȘOARȘ" },
  { value: "SOHODOL", label: "SOHODOL" },
  { value: "ȘONA", label: "ȘONA" },
  {
    value: "STAȚIUNEA CLIMATERICĂ SÂMBĂTA",
    label: "STAȚIUNEA CLIMATERICĂ SÂMBĂTA",
  },
  { value: "STUPINII PREJMERULUI", label: "STUPINII PREJMERULUI" },
  { value: "TĂRLUNGENI", label: "TĂRLUNGENI" },
  { value: "TELIU", label: "TELIU" },
  { value: "TICUȘU NOU", label: "TICUȘU NOU" },
  { value: "TICUȘU VECHI", label: "TICUȘU VECHI" },
  { value: "TIMIȘU DE JOS", label: "TIMIȘU DE JOS" },
  { value: "TIMIȘU DE SUS", label: "TIMIȘU DE SUS" },
  { value: "TOARCLA", label: "TOARCLA" },
  { value: "TODERIȚA", label: "TODERIȚA" },
  { value: "TOHANU NOU", label: "TOHANU NOU" },
  { value: "UCEA DE JOS", label: "UCEA DE JOS" },
  { value: "UCEA DE SUS", label: "UCEA DE SUS" },
  { value: "UNGRA", label: "UNGRA" },
  { value: "VAD", label: "VAD" },
  { value: "VÂLCEA", label: "VÂLCEA" },
  { value: "VĂLENI", label: "VĂLENI" },
  { value: "VAMA BUZĂULUI", label: "VAMA BUZĂULUI" },
  { value: "VENEȚIA DE JOS", label: "VENEȚIA DE JOS" },
  { value: "VENEȚIA DE SUS", label: "VENEȚIA DE SUS" },
  { value: "VICTORIA", label: "VICTORIA" },
  { value: "VISCRI", label: "VISCRI" },
  { value: "VIȘTEA DE JOS", label: "VIȘTEA DE JOS" },
  { value: "VIȘTEA DE SUS", label: "VIȘTEA DE SUS" },
  { value: "VIȘTIȘOARA", label: "VIȘTIȘOARA" },
  { value: "VLĂDENI", label: "VLĂDENI" },
  { value: "VOILA", label: "VOILA" },
  { value: "VOIVODENI", label: "VOIVODENI" },
  { value: "VULCAN", label: "VULCAN" },
  { value: "ZĂRNEȘTI", label: "ZĂRNEȘTI" },
  { value: "ZIZIN", label: "ZIZIN" },
];
