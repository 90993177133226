import { Box, Button, Grid } from "@mui/material";
import React, { FC, useState } from "react";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppSelector,
} from "store";
import {
  selectGeneratingArchive,
  selectJobsFilters,
} from "store/selectors/mediationSelectors";

import { isAdmin, isCompany, isEmployer, isExpert } from "utils/getUserType";
import { JobsFilters } from "./filters-jobs/JobsFilters";
import { JobsDisplay } from "./jobs-display/JobsDisplay";
import "./JobsTab.scss";
import { JobsPagination } from "./pagination-jobs/JobsPagination";
import { useNavigate } from "react-router";

import { GenerateJobsArchiveDialog } from "./generate-jobs-archive-dialog/GenerateJobsArchiveDialog";

export const JobsTab: FC<{ canMount: boolean }> = ({ canMount }) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const filters = useAppSelector(selectJobsFilters);
  const generating = useAppSelector(selectGeneratingArchive);
  const navigate = useNavigate();

  const [archiveDialog, setArchiveDialog] = useState<boolean>(false);
  const ro = useAppSelector(selectMediationPageLocalization);

  const generateJobsArchive = () => {
    setArchiveDialog(true);
  };

  const cantAddJob =
    (filters.employerId === 0 ||
      filters.employerId === undefined ||
      filters.employerId === null ||
      Number.isNaN(filters.employerId)) &&
    !isEmployer(currentUser?.userType);

  const handleAddJobPostClick = () => {
    if (isEmployer(currentUser?.userType)) {
      navigate("/anunt/" + currentUser?.userId + "/0");
    } else {
      navigate("/anunt/" + filters.employerId + "/0");
    }
  };

  const closeArchiveDialog = () => {
    setArchiveDialog(false);
  };

  return (
    <Grid container id="jobs-tab" maxWidth="lg">
      <Grid container item xs={12} className="jobs-tab-buttons">
        {!isCompany(currentUser?.userType) && (
          <Button
            className="add-job-btn"
            variant="contained"
            color="success"
            disabled={cantAddJob}
            onClick={handleAddJobPostClick}
          >
            {ro.addJobBtn}
          </Button>
        )}
        {(isExpert(currentUser?.userType) ||
          isAdmin(currentUser?.userType)) && (
          <Box className="generate-date">
            <Button
              variant="contained"
              color="success"
              disabled={generating}
              onClick={generateJobsArchive}
            >
              {ro.archiveBtn}
            </Button>
          </Box>
        )}
      </Grid>
      {canMount && (
        <React.Fragment>
          <Grid item xs={12} className="filters-container">
            <JobsFilters />
          </Grid>
          <Grid item xs={12} className="jobs-container">
            <JobsDisplay />
          </Grid>
          <Grid item xs={12} className="pagination-container">
            <JobsPagination />
          </Grid>
        </React.Fragment>
      )}
      {archiveDialog && (
        <GenerateJobsArchiveDialog
          display={archiveDialog}
          close={closeArchiveDialog}
        />
      )}
    </Grid>
  );
};
