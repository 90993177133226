import { Box, Grid } from "@mui/material";
import {
  Check,
  CloseOutlined,
  CloseRounded,
  EditOutlined,
  FileDownload,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import locale from "date-fns/locale/ro";
import { ImageCarousel } from "components/shared/ImageCarousel/ImageCarousel";
import React, { FC, useEffect, useState } from "react";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { GetFromFileShareModel } from "store/models/files";
import { AllocatedUserModel } from "store/models/mediation";

import {
  selectAllocationsFilters,
  selectJobDetails,
} from "store/selectors/mediationSelectors";
import { addFile } from "store/slices/fileSlices";
import { downloadFile, getPicture } from "store/thunks/fileThunks";
import { getDateLabel, onlyDateFormat } from "utils";
import { ConvertCountyToLabel } from "utils/mapCountyEnumToLabel";

import "./JobInfo.scss";
import {
  changeAllocationTime,
  getJobPost,
  getUsersAllocatedToJobPost,
  unallocateJobPost,
} from "store/thunks/mediationThunks";
import {
  setAllocationsFilters,
  setPaginatedUnallocatedCompaniesFilters,
} from "store/slices/mediationSlice";
import { isAdmin, isExpert } from "utils/getUserType";

export const JobInfo: FC<{ showClose: boolean; close?: any }> = ({
  showClose,
  close,
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const jobDetails = useAppSelector(selectJobDetails);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const [userIdEditing, setUserIdEditing] = useState<number>();
  const [newAllocationTime, setNewAllocationTime] = useState<Date | null>(null);
  const [dateError, setDateError] = useState<boolean>(false);
  const filters = useAppSelector(selectAllocationsFilters);

  useEffect(() => {
    jobDetails?.jobFiles.forEach((file) => {
      if (file.isImage) {
        dispatch(
          getPicture({
            dto: file.fileDetails,
          })
        ).then((res) => {
          dispatch(
            addFile({
              files: {
                filePath: res.payload,
                fileName: file.fileDetails.fileName,
                jobFileId: file.jobFileId,
                isImage: file.isImage,
              },
            })
          );
        });
      }
    });

    //eslint-disable-next-line
  }, []);

  const handleDownloadFile = (file: GetFromFileShareModel) => {
    setIsDownloading(true);
    dispatch(
      downloadFile({
        dto: {
          filePath: file.filePath,
          fileName: file.fileName,
          contentType: file.contentType,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      setIsDownloading(false);
    });
  };

  const handleEditAllocationTime = (user: AllocatedUserModel) => {
    setUserIdEditing(user.userId);
    var date = new Date(user.allocationTime);

    setNewAllocationTime(date);
  };

  const handleSaveNewAllocationTime = (user: AllocatedUserModel) => {
    var dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;

    //@ts-ignore
    const date = getDateLabel(newAllocationTime!, onlyDateFormat);

    if (!dateRegex.test(date) || date === "01.01.1970") {
      setDateError(true);
      return;
    }

    setDateError(false);
    dispatch(
      changeAllocationTime({
        model: {
          allocationId: user.allocationId,
          allocationTime: newAllocationTime?.toISOString()!,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      setUserIdEditing(undefined);
      setNewAllocationTime(null);
      dispatch(
        getUsersAllocatedToJobPost({
          filters: {
            ...filters,
            jobPostId: jobDetails?.jobPostId!,
          },
          token: currentUser?.jwtToken,
        })
      );
    });
  };
  const handleUnallocateUser = (user: AllocatedUserModel) => {
    dispatch(
      unallocateJobPost({
        model: {
          allocationId: user.allocationId,
          unallocatedByUserId: currentUser?.userId!,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        setPaginatedUnallocatedCompaniesFilters({
          search: "",
          currentPageNumber: 0,
        })
      );
      dispatch(
        getJobPost({
          jobPostId: jobDetails?.jobPostId!,
          token: currentUser?.jwtToken,
        })
      );
    });
  };

  const handleStopEditingAllocationTime = () => {
    setUserIdEditing(undefined);
    setNewAllocationTime(null);
  };

  const getDateOnChange = (e: Date | null | any, start: boolean) => {
    var dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    const date = getDateLabel(e, onlyDateFormat);
    const isValid = dateRegex.test(date);

    if (!isValid) {
      return;
    } else {
      start ? setStartTimeFilter(e) : setEndTimeFilter(e);
    }
  };

  const setStartTimeFilter = (date: Date | null) => {
    date?.setHours(0);
    date?.setMinutes(0);
    dispatch(
      setAllocationsFilters({
        ...filters,
        startDate: date ? date.toISOString() : undefined,
      })
    );
  };

  const setEndTimeFilter = (date: Date | null) => {
    date?.setHours(23);
    date?.setMinutes(59);
    dispatch(
      setAllocationsFilters({
        ...filters,
        endDate: date ? date.toISOString() : undefined,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getUsersAllocatedToJobPost({
        filters: filters,
        token: currentUser?.jwtToken,
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  return (
    <Grid id="job-info">
      {showClose ? (
        <Grid item xs={12} className="title">
          <Typography variant="h5">{jobDetails?.title}</Typography>
          <Grid item>
            <IconButton color="error" onClick={(e) => close()}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Grid container className="job-modal-container">
        <Grid item xs={12} className="jobModal-carousel">
          <ImageCarousel displayOnly={true} />
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            <Typography variant="h6">Titlu:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="body1">{jobDetails?.title}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            <Typography variant="h6">Angajator:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="body1">{jobDetails?.employerName}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            <Typography variant="h6">Descriere:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="body1" className="job-info-description">
              {jobDetails?.description?.split("<br/>").join("\n")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            <Typography variant="h6">Condiții/Oferte la angajare:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            {" "}
            <Typography variant="body1">
              {jobDetails?.employmentRequirements}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            <Typography variant="h6">Adresa:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1">
              {jobDetails?.street +
                ", " +
                jobDetails?.locality +
                ", " +
                ConvertCountyToLabel(jobDetails?.county!)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            <Typography variant="h6">Post vacant:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="body1">{jobDetails?.vacancy}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            {" "}
            <Typography variant="h6">Posturi disponibile:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            {" "}
            <Typography variant="body1">{jobDetails?.jobsLeft}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            {" "}
            <Typography variant="h6">
              Termen limită de depunere a dosarului:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            {" "}
            <Typography variant="body1">
              {getDateLabel(
                jobDetails?.fileSubmissionDeadline!,
                onlyDateFormat
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <Grid item xs={2} className="label-grid">
            {" "}
            <Typography variant="h6">Documente:</Typography>
          </Grid>
          <Grid item xs={12} sm={10} container>
            {jobDetails?.jobFiles?.map(
              (file) =>
                !file.isImage && (
                  <Grid item xs={12} sm={3} key={file.jobFileId}>
                    <Chip
                      label={file.fileDetails.fileName}
                      color="primary"
                      variant="outlined"
                      className="chip"
                      disabled={isDownloading}
                      onDelete={() => {}}
                      onClick={() => handleDownloadFile(file.fileDetails)}
                      deleteIcon={<FileDownload />}
                    />
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="jobModal-description"
          container
          columnSpacing={2}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <Grid item xs={2} className="label-grid">
              <Typography variant="h6">Beneficiari alocați:</Typography>
            </Grid>

            <Grid item xs={12} sm={10}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={3}>
                  <DatePicker
                    value={filters.startDate ?? null}
                    onChange={(e) => getDateOnChange(e, true)}
                    onAccept={setStartTimeFilter}
                    mask="__.__.____"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              {filters.startDate && (
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setStartTimeFilter(null);
                                  }}
                                >
                                  <CloseRounded color="error"></CloseRounded>
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </Grid>
                          ),
                        }}
                        onChange={(e) => getDateOnChange(e, true)}
                        label="După data de"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DatePicker
                    value={filters.endDate ?? null}
                    onChange={(e) => getDateOnChange(e, false)}
                    onAccept={setEndTimeFilter}
                    mask="__.__.____"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              {filters.endDate && (
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setEndTimeFilter(null);
                                  }}
                                >
                                  <CloseRounded color="error"></CloseRounded>
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </Grid>
                          ),
                        }}
                        onChange={(e) => getDateOnChange(e, false)}
                        label="Înainte de"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Table
                aria-label="caption table"
                className="allocations-table"
                sx={{
                  border: "1px solid #f5f5f5",
                  borderCollapse: "inherit",
                  borderTop: "none",
                }}
              >
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell className="table-header-cell">Nume</TableCell>
                    <TableCell className="table-header-cell">Email</TableCell>
                    <TableCell className="table-header-cell">
                      Data alocării
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(!jobDetails?.allocatedUsers ||
                    jobDetails?.allocatedUsers.length === 0) && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1">
                          Niciun beneficiar alocat.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <React.Fragment>
                    {jobDetails?.allocatedUsers?.map(
                      (user: AllocatedUserModel) => (
                        <TableRow key={user.userId}>
                          <TableCell width="30%" component="th" scope="row">
                            {user.username}
                          </TableCell>
                          <TableCell width="30%" component="th" scope="row">
                            {user.email}
                          </TableCell>
                          <TableCell component="th" scope="row" width="40%">
                            <Grid container alignItems="center">
                              <Grid
                                container
                                alignItems="center"
                                item
                                xs={8}
                                className="date-container"
                              >
                                <Box className="date-box">
                                  {userIdEditing !== user.userId ? (
                                    <React.Fragment>
                                      <Grid item xs="auto">
                                        {getDateLabel(
                                          user.allocationTime,
                                          onlyDateFormat
                                        )}
                                      </Grid>
                                      {(isExpert(currentUser?.userType) ||
                                        isAdmin(currentUser?.userType)) && (
                                        <Grid item>
                                          <IconButton
                                            size="small"
                                            color="warning"
                                            onClick={() =>
                                              handleEditAllocationTime(user)
                                            }
                                          >
                                            <EditOutlined />
                                          </IconButton>
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Grid item xs={6}>
                                        <DatePicker
                                          value={newAllocationTime}
                                          onChange={setNewAllocationTime}
                                          mask="__.__.____"
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              fullWidth
                                              value={newAllocationTime}
                                              size="small"
                                              sx={{ backgroundColor: "white" }}
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          color="success"
                                          onClick={(e) =>
                                            handleSaveNewAllocationTime(user)
                                          }
                                        >
                                          <Check />
                                        </IconButton>
                                        <IconButton
                                          color="error"
                                          onClick={(e) => {
                                            handleStopEditingAllocationTime();
                                            setDateError(false);
                                          }}
                                        >
                                          <CloseOutlined />
                                        </IconButton>
                                      </Grid>
                                    </React.Fragment>
                                  )}
                                </Box>
                                {dateError && userIdEditing === user.userId && (
                                  <Typography
                                    color="warning"
                                    className="date-error"
                                    variant="body2"
                                  >
                                    Dată invalidă
                                  </Typography>
                                )}
                              </Grid>
                              {(isExpert(currentUser?.userType) ||
                                isAdmin(currentUser?.userType)) && (
                                <Grid item xs={4} container>
                                  <Button
                                    variant="contained"
                                    onClick={(e) => handleUnallocateUser(user)}
                                    color="error"
                                  >
                                    Dezalocă
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </React.Fragment>
                </TableBody>
              </Table>
            </Grid>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};
