import { Container, Grid, Tab, Tabs } from "@mui/material";
import { AllocationsTab } from "components/mediation/allocations-tab/AllocationsTab";
import { ApplicationsTab } from "components/mediation/ApplicationsTab/ApplicationsTab";
import { EmployersTab } from "components/mediation/employers-tab/EmployersTab";
import { JobsTab } from "components/mediation/jobs-tab/JobsTab";

import { homePath } from "navigation";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
} from "store";
import { selectJobsFilters } from "store/selectors/mediationSelectors";
import { setJobsFilters } from "store/slices/mediationSlice";
import { getEmployersNames } from "store/thunks/mediationThunks";

import { isAdmin, isCompany, isEmployer, isExpert } from "utils/getUserType";
import "./MediationPage.scss";

export const MediationPage: FC = () => {
  const params = useParams();
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const activeTab = params.tab;
  const jobsTab = "joburi";
  const employersTab = "angajatori";
  const applicationsTab = "aplicatii";
  const allocationsTab = "angajari";
  const ro = useAppSelector(selectMediationPageLocalization);
  const [canMount, setCanMount] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectJobsFilters);

  useEffect(() => {
    if (
      params.id?.toString() !== currentUser?.userId.toString() &&
      isEmployer(currentUser?.userType)
    )
      navigate(homePath);
    if (params.tab === ":tab") {
      navigate("/mediere/" + jobsTab + "/0", { replace: true });
    }
    dispatch(
      getEmployersNames({
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        setJobsFilters({
          ...filters,
          dataToSortBy: 7,
          descending: false,
          employerId:
            params.id !== "0" && params.id !== ":id"
              ? parseInt(params.id!, 10)
              : undefined,
        })
      );
      setCanMount(true);
    });

    //eslint-disable-next-line
  }, []);

  const changeTab = (e: any, newValue: string) => {
    if (isEmployer(currentUser?.userType)) {
      navigate("/mediere/" + newValue + `/${currentUser?.userId}`);
    } else navigate("/mediere/" + newValue + "/0");
  };

  return (
    <Container id="mediation-page" maxWidth="xl" disableGutters>
      {canMount && (
        <Grid container className="mediation-page-container">
          {activeTab !== ":tab" && (
            <Grid item xs={12} className="tabs-container">
              <Tabs value={activeTab} onChange={changeTab}>
                <Tab label={ro.jobsTab} value={jobsTab}></Tab>

                {(isAdmin(currentUser?.userType) ||
                  isExpert(currentUser?.userType)) && (
                  <Tab label={ro.employersTab} value={employersTab}></Tab>
                )}

                <Tab
                  label={
                    isCompany(currentUser?.userType)
                      ? ro.simpleUsersTab
                      : isEmployer(currentUser?.userType)
                      ? ro.employerJobApplicantsTab
                      : isAdmin(currentUser?.userType) ||
                        isExpert(currentUser?.userType)
                      ? ro.expertTab
                      : ro.expertTab
                  }
                  value={applicationsTab}
                ></Tab>

                {(isAdmin(currentUser?.userType) ||
                  isExpert(currentUser?.userType)) && (
                  <Tab label={ro.allocationsTab} value={allocationsTab}></Tab>
                )}
              </Tabs>
            </Grid>
          )}
          {activeTab === jobsTab && <JobsTab canMount={canMount} />}
          {activeTab === employersTab && <EmployersTab />}
          {activeTab === applicationsTab && <ApplicationsTab />}
          {activeTab === allocationsTab && <AllocationsTab />}
        </Grid>
      )}
    </Container>
  );
};
