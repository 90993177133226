import { East } from "@mui/icons-material";
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  counselingPath,
  mediationPath,
  statisticsPath,
  usersPath,
  vocationalTrainingPath,
} from "navigation";

import { useNavigate } from "react-router";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { setSelectedTargetGroupUser } from "store/slices/targetGroupSlice";
import { isAdmin, isEmployer, isExpert } from "utils/getUserType";
import { ReactComponent as CommunicationIcon } from "../../assets/images/communication.svg";
import { ReactComponent as Career } from "../../assets/images/career.svg";
import { ReactComponent as Monitor } from "../../assets/images/mediere.svg";
import { ReactComponent as Living } from "../../assets/images/living.svg";
import { ReactComponent as Statistics } from "../../assets/images/statistics.svg";
import backgroundImage from "../../assets/images/shutterstock_736534063.jpg";

import "./Modules.scss";

export const ModulesPage = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleMediereClick = () => {
    if (isEmployer(currentUser?.userType)) {
      navigate("/mediere/joburi/" + currentUser?.userId);
    } else navigate(mediationPath);
  };

  return (
    <Container
      id="modules-page"
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: "inherit",
        backgroundImage: `url("${backgroundImage}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Grid container maxWidth="xl" alignItems="center" justifyContent="center">
        {(isExpert(currentUser?.userType) ||
          isAdmin(currentUser?.userType)) && (
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <Paper
              className="home-item"
              component={Stack}
              direction="column"
              justifyContent="center"
              elevation={15}
              sx={{
                "&:hover": {
                  boxShadow: 5,
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                navigate(usersPath);
                dispatch(setSelectedTargetGroupUser(null));
              }}
            >
              <Box className="illustration-container">
                <Living className="admin-illustration" />
              </Box>
              <Grid
                container
                justifyContent="space-between"
                className="bottom-container"
              >
                <Grid item xs={10} container justifyContent="flex-start">
                  <Typography
                    variant="h5"
                    color="primary"
                    className="label-module"
                    textAlign="center"
                    sx={{ paddingTop: "10px" }}
                  >
                    Grup țintă
                  </Typography>
                </Grid>

                <Grid item xs={2} container justifyContent="flex-end">
                  {" "}
                  <East color="primary" className="east-icon" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {(isAdmin(currentUser?.userType) ||
          isExpert(currentUser?.userType)) && (
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <Paper
              className="home-item"
              component={Stack}
              direction="column"
              justifyContent="center"
              elevation={15}
              sx={{
                "&:hover": {
                  boxShadow: 5,
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(counselingPath)}
            >
              <Box className="illustration-container">
                <CommunicationIcon className="admin-illustration" />
              </Box>
              <Grid
                container
                justifyContent="space-between"
                className="bottom-container"
              >
                <Grid item xs={10} container justifyContent="flex-start">
                  <Typography
                    variant="h5"
                    className="label-module"
                    color="primary"
                    textAlign="center"
                    sx={{ paddingTop: "10px" }}
                  >
                    Consiliere
                  </Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  <East color="primary" className="east-icon" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
        {(isExpert(currentUser?.userType) ||
          isAdmin(currentUser?.userType)) && (
          <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
            <Paper
              className="home-item"
              component={Stack}
              direction="column"
              justifyContent="center"
              elevation={15}
              sx={{
                "&:hover": {
                  boxShadow: 5,
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(vocationalTrainingPath)}
            >
              <Box className="illustration-container">
                <Career className="admin-illustration" />
              </Box>
              <Grid
                container
                justifyContent="space-between"
                className="bottom-container"
              >
                <Grid item xs={10} container justifyContent="flex-start">
                  <Typography
                    variant="h5"
                    color="primary"
                    className="label-module"
                    whiteSpace="nowrap"
                    textAlign="center"
                    sx={{ paddingTop: "10px" }}
                  >
                    Formare profesională
                  </Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  {" "}
                  <East color="primary" className="east-icon" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
          <Paper
            className="home-item"
            component={Stack}
            direction="column"
            justifyContent="center"
            elevation={15}
            sx={{
              "&:hover": {
                boxShadow: 5,
                cursor: "pointer",
              },
            }}
            onClick={handleMediereClick}
          >
            <Box sx={{ marginTop: "10px" }} className="illustration-container">
              <Monitor className="admin-illustration" />
            </Box>
            <Grid
              container
              justifyContent="space-between"
              className="bottom-container"
            >
              <Grid item xs={10} container justifyContent="flex-start">
                <Typography
                  variant="h5"
                  color="primary"
                  className="label-module"
                  textAlign="center"
                  sx={{ paddingTop: "10px" }}
                >
                  Mediere
                </Typography>
              </Grid>

              <Grid item xs={2} container justifyContent="flex-end">
                {" "}
                <East color="primary" className="east-icon" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/*        <Grid item xs={12} md={6} lg={4} sx={{ p: 2 }}>
          <Paper
            className="home-item"
            component={Stack}
            direction="column"
            justifyContent="center"
            elevation={15}
            sx={{
              "&:hover": {
                boxShadow: 5,
                cursor: "pointer",
              },
            }}
            onClick={() => navigate(statisticsPath)}
          >
            <Box sx={{ marginTop: "10px" }} className="illustration-container">
              <Statistics className="admin-illustration" />
            </Box>
            <Grid
              container
              justifyContent="space-between"
              className="bottom-container"
            >
              <Grid item xs={10} container justifyContent="flex-start">
                <Typography
                  variant="h5"
                  color="primary"
                  className="label-module"
                  textAlign="center"
                  sx={{ paddingTop: "10px" }}
                >
                  Statistici
                </Typography>
              </Grid>

              <Grid item xs={2} container justifyContent="flex-end">
                {" "}
                <East color="primary" className="east-icon" />
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
    </Container>
  );
};
