import { Button, ButtonGroup, Container, Grid, Tooltip } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FileUploadPopout } from 'components';
import DownloadIcon from "@mui/icons-material/Download";
import { FC, useEffect, useState } from 'react';
import './GrantContestPhase1Component.scss';
import { Add, Delete } from '@mui/icons-material';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';
import { useNavigate, useParams } from 'react-router';
import { CompanyUserBriefModel, selectCompanyUsersNames, selectCurrentUser, selectGrantApproved, selectGrantContestPageLocalization, selectGrantSortModel, selectPhase1DocumentsCount, selectSelectedUser, selectUserGrantDocumentsListIsLoading, selectUserIsDownloadingGrantPhaseFile, selectUserPhase1Documents, useAppDispatch, useAppSelector } from 'store';
import { deleteGrantContestPhase1Doc, downloadGrantContestPhase1Doc, requestCompanyUsersNames, requestPhase1Documents, uploadGrantPhase1UserDocument } from 'store/thunks/grantContestThunks';
import { setGrantSortModel, setIsGrantApprovedForSelectedUser, setSelectedUser, setUserPhase1Documents } from 'store/slices/grantContestSlice';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { UserType } from 'store/models/enums/UserType';
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { dateTimeFormatOptions } from 'utils';
import { addAppNotification } from "store/slices/appNotificationSlice";
import AutocompleteSearch from 'components/shared/autocompleteSearch/AutocompleteSearch';

export const GrantContestPhase1Component: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { userId } = useParams();
    const companyUsersNames = useAppSelector(selectCompanyUsersNames);
    const userPhase1Documents = useAppSelector(selectUserPhase1Documents);
    const phase1DocumentsCount = useAppSelector(selectPhase1DocumentsCount);
    const selectedUser = useAppSelector(selectSelectedUser);
    const grantApproved = useAppSelector(selectGrantApproved);
    const localization = useAppSelector(selectGrantContestPageLocalization);

    const rowsPerPage = 10;

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const grantSortModel = useAppSelector(selectGrantSortModel);
    const currentUser = useAppSelector(selectCurrentUser);

    const userGrantDocumentsListIsLoading = useAppSelector(selectUserGrantDocumentsListIsLoading);
    const userIsDownloadingGrantPhaseFile = useAppSelector(selectUserIsDownloadingGrantPhaseFile);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);
    const [displayAutocompleteDropdown, setDisplayAutocompleteDropdown] = useState<boolean>(false);

    const canAddDoc = currentUser?.userType !== UserType.COMPANY_USER ? ((selectedUser !== null && selectedUser !== undefined) ? true : false) : true;

    const handleRequestPhase1Docs = (userId: number | null | undefined, pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean, token: string | undefined) => {
        dispatch(requestPhase1Documents({
            filter: {
                userId: userId,
                pageNumber: pageNumber,
                columnToSortBy: columnToSortBy,
                sortingOrder: sortingOrder
            },
            token: token,
        }));
    };

    useEffect(() => {
        dispatch(requestCompanyUsersNames({
            token: currentUser?.jwtToken
        })).then((response: any) => {
            response.payload.forEach((user: any) => {
                if (user.userId === parseInt(userId ?? "0")) {
                    dispatch(setSelectedUser({
                        userId: user.userId,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        isGrantApproved: user.isGrantApproved
                    }))
                }
            })
        })

        if (selectedUser !== null) {
            navigate(`/planafaceri/${selectedUser.userId}`)
        }
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        let column = mapColumnNameToEnum(grantSortModel.columnToSortBy);
        let sort = false;
        if (grantSortModel.sortingOrder === "asc") sort = true;
        if (selectedUser !== null && selectedUser !== undefined) {
            handleRequestPhase1Docs(selectedUser.userId, currentPageNumber, column, sort, currentUser?.jwtToken);
        }
        //eslint-disable-next-line
    }, [currentPageNumber]);

    useEffect(() => {
        if (selectedUser !== null) {
            localStorage.setItem('userId', JSON.stringify(selectedUser.userId));
        }

        if (selectedUser !== null && selectedUser !== undefined) {
            handleRequestPhase1Docs(selectedUser.userId, 0, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
        } else {
            dispatch(setUserPhase1Documents({
                phase1documents: [],
                rowCount: 0,
            }))
        }
        //eslint-disable-next-line
    }, [selectedUser]);

    const rows = userPhase1Documents;

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }


    const handleSelectUser = (e: any, values: any) => {
        if (values !== null && values !== undefined && typeof values !== "string") {
            setDisplayAutocompleteDropdown(false)
            dispatch(setSelectedUser({
                userId: values.userId,
                firstName: values.firstName,
                lastName: values.lastName,
                isGrantApproved: values.isGrantApproved
            }));
            dispatch(setIsGrantApprovedForSelectedUser(values.isGrantApproved));
            navigate(`/planafaceri/${values.userId}`)
        }
        if (typeof values === "string" && e.key === "Enter") {
            let usersFound = 0;
            let userFound: CompanyUserBriefModel = {}

            for (let user of companyUsersNames) {
                if ((user.lastName + " " + user.firstName).toLowerCase().includes(values.toLowerCase())) {
                    usersFound++;
                    userFound = {
                        userId: user.userId,
                        lastName: user.lastName,
                        firstName: user.firstName,
                        isGrantApproved: user.isGrantApproved,
                    };
                }
            }
            if (usersFound === 0) {
                setDisplayAutocompleteDropdown(false)
                dispatch(setUserPhase1Documents({
                    phase1documents: [],
                    rowCount: 0,
                }))
                dispatch(addAppNotification({
                    message: localization.messages.noResult,
                    severity: "warning",
                }))
            }
            if (usersFound === 1) {
                dispatch(setSelectedUser({
                    userId: userFound.userId,
                    firstName: userFound.firstName,
                    lastName: userFound.lastName,
                    isGrantApproved: userFound.isGrantApproved
                }));
                setDisplayAutocompleteDropdown(false)
            }
            if (usersFound > 1) {
                dispatch(addAppNotification({
                    message: localization.messages.selectUser,
                    severity: "warning",
                }))
            }
        }

    };



    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadGrantPhase1UserDocument({
                    requestData: {
                        companyUserId: selectedUser?.userId!,
                        files: files,
                    },
                    token: currentUser?.jwtToken
                })
            ).then(() => {
                removeAllFilesCallback();
                let column = mapColumnNameToEnum(grantSortModel.columnToSortBy);
                let sort = false;
                if (grantSortModel.sortingOrder === "asc") sort = true;
                handleRequestPhase1Docs(selectedUser?.userId, 0, column, sort, currentUser?.jwtToken);
                setCurrentPageNumber(0);
            }
            );
        }
    };
    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: localization.phase1Tab.docNameCol, flex: 1, minWidth: 200 },
        {
            field: "uploadTime",
            headerName: localization.phase1Tab.uploadDateCol,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "fileSize",
            headerName: localization.phase1Tab.fileSizeCol,
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value
                        .toString()
                        .substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "uploadedBy",
            headerName: localization.phase1Tab.uploadedByCol,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: localization.phase1Tab.optionsCol,
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title={localization.phase1Tab.downloadFileTooltip}>
                            <Button
                                disabled={userIsDownloadingGrantPhaseFile}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadGrantContestPhase1Doc({ fileId: params.row.fileId, token: currentUser?.jwtToken })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {!grantApproved &&
                            <Tooltip title={localization.phase1Tab.deleteFileTooltip}>
                                <Button
                                    disabled={userIsDownloadingGrantPhaseFile}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            deleteGrantContestPhase1Doc({
                                                docId: params.row.documentId,
                                                token: currentUser?.jwtToken,
                                            })
                                        ).then(() => {
                                            handleRequestPhase1Docs(selectedUser?.userId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
                                        });

                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setGrantSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            if (selectedUser !== null) {
                handleRequestPhase1Docs(selectedUser.userId, currentPageNumber, column, sort, currentUser?.jwtToken);
            }

        } else {
            dispatch(setGrantSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            if (selectedUser !== null) {
                handleRequestPhase1Docs(selectedUser.userId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
            }
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    }

    const onInputChange = (event: any, value: any) => {

        if (event !== null) {
            if (value === "" && event.type === 'change') {
                localStorage.removeItem('userId')
                dispatch(setSelectedUser(null))
                navigate('/planafaceri/' + currentUser?.userId)
            }
        }


    }

    const getOptionLabel = (user: any): string => {
        return (user.lastName || "") + (user.lastName ? " " : "") + (user.firstName || "");
    }

    const onKeyPress = (event: any, value: any) => {
        if (event.key === "Enter") {
            handleSelectUser(event, event.target.value)
        }
    }

    return (
        <Container
            id="grant-contest-phase1-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                }}
                alignItems="stretch"
            >
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                    {
                        currentUser?.userType !== UserType.COMPANY_USER &&
                        <Grid container>
                            <AutocompleteSearch
                                width={175}
                                options={companyUsersNames}
                                value={selectedUser}
                                getOptionLabel={getOptionLabel}
                                renderOption={(props: any, option: any) => {
                                    return (
                                        <li {...props} key={option.userId}>
                                            {option.lastName + " " + option.firstName}
                                        </li>
                                    );
                                }}
                                onType={handleSelectUser}
                                isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
                                onInputChange={onInputChange}
                                onKeyPress={onKeyPress}
                                open={displayAutocompleteDropdown}
                                onOpen={(e: any) => { setDisplayAutocompleteDropdown(true) }}
                                onClose={(e: any) => { setDisplayAutocompleteDropdown(false) }}
                            />
                        </Grid>
                    }

                    <Grid container justifyContent={"flex-end"}>
                        {
                            !grantApproved && !(currentUser?.userType === UserType.COMPANY_USER) &&
                            <Button disabled={!canAddDoc} variant="contained" startIcon={<Add />} color="success" onClick={() => { setDisplayUploadDialog(true) }}>
                                {localization.phase1Tab.addButton}
                            </Button>
                        }
                    </Grid>

                    {
                        displayUploadDialog &&
                        <FileUploadPopout
                            showSize={true}
                            uploadOnButtonCallback={onFilesUpload}
                            uploadDisabled={!canAddDoc}
                            handleClose={handleClose}
                            displayDialog={displayUploadDialog} />
                    }

                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ marginTop: "10px" }}
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userGrantDocumentsListIsLoading}
                        hideFooterPagination={userGrantDocumentsListIsLoading}
                        rows={rows}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={phase1DocumentsCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}

                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: 630,
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>

                </Grid>

            </Grid>

        </Container>
    );
};