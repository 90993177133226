import { Button, Container, Grid } from "@mui/material";
import React, { FC } from "react";
import "./UserRepresentativeView.scss";
import {
  countiesSelectItems,
  userTypeSelectItems,
} from "store/models/selectItems";
import {
  FormikDatePicker,
  FormikSelectSimple,
  FormikTextField,
} from "components";
import { UserType } from "store/models/enums/UserType";
import { ExpertTypeSelectItems } from "store/models/selectItems/expertTypeSelectItems";

import { isAdmin, isExpert } from "utils/getUserType";
import { genderItems } from "store/models/selectItems/genderItems";
import { selectUserDetailsPageLocalization, useAppSelector } from "store";

interface UserRepresentativeViewProps {
  canEdit?: boolean;
  isNewUser?: boolean;
  userType?: UserType;
}

export const UserRepresentativeView: FC<UserRepresentativeViewProps> = ({
  canEdit = false,
  isNewUser = false,
  userType = UserType.COMPANY_USER,
}) => {
  const expertUserSelected = isExpert(userType);
  const adminUserSelected = isAdmin(userType);
  const ro = useAppSelector(selectUserDetailsPageLocalization);

  return (
    <Container
      id="user-representative-page"
      maxWidth="lg"
      sx={{ minHeight: "inherit" }}
      disableGutters
    >
      <Grid
        container
        sx={{
          minHeight: "inherit",
          p: {
            xs: 1,
          },
        }}
        alignItems="stretch"
      >
        <Grid item xs={12} container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <FormikTextField
              id="firstName"
              name="firstName"
              label="Prenume"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              id="lastName"
              name="lastName"
              label="Nume"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              id="email"
              name="email"
              label="E-mail"
              readOnly={!canEdit || !isNewUser}
            />
          </Grid>
          {expertUserSelected && (
            <React.Fragment>
              <Grid item xs={6}>
                <FormikTextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Număr de telefon"
                  readOnly={!canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikDatePicker
                  label="Data Nașterii"
                  name="dataNasterii"
                  readOnly={!canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelectSimple
                  name="gen"
                  label="Gen"
                  items={genderItems}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  id="address"
                  name="adresa"
                  label="Adresa"
                  readOnly={!canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSelectSimple
                  id="judet"
                  name="county"
                  label="Județ"
                  items={countiesSelectItems}
                  readOnly={!canEdit}
                />
              </Grid>
            </React.Fragment>
          )}

          {!adminUserSelected && (
            <Grid item xs={6}>
              <FormikSelectSimple
                id="userType"
                name="userType"
                label="Tipul utilizatorului"
                items={userTypeSelectItems}
                readOnly={true}
              />
            </Grid>
          )}

          {expertUserSelected && (
            <Grid item xs={6}>
              <FormikSelectSimple
                id="selectu"
                name="expertType"
                label="Tip expert"
                items={ExpertTypeSelectItems}
                readOnly={!canEdit}
              />
            </Grid>
          )}
          {canEdit && (
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="contained" type="submit">
                {ro.save}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
