import { FC } from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

interface FormikTextFieldProps {
    label?: string;
    variant?: "standard" | "filled" | "outlined";
    name: string;
    size?: "medium" | "small";
    apiError?: string | null | undefined;
    id?: string;
    type?: string;
    readOnly?: boolean;
    onClickEvent?: any;
    multiline?: boolean;
    rows?: number;
    disableTyping?: boolean;
    onFocus?: any;
    onFocusCapture?: any;
    onKeyDown?: any;
    ref?: any;
    focused?: any;
    endAdornment?: any;
    validateOnChange?: boolean;
}

export const FormikTextField: FC<FormikTextFieldProps> = (props) => {
    const [field, meta] = useField(props);

    const onChange = (e: any) => {
        if (!props.disableTyping) {
            field.onChange(e);
        }

    }

    return (
        <TextField
            onClick={props.onClickEvent}
            ref={props.ref}
            multiline={props.multiline}
            rows={props.rows}
            id={props.id ? props.id : field.name}
            name={field.name}
            label={props.label}
            type={props.type ? props.type : "text"}
            fullWidth
            value={field.value ?? ''}
            focused={props.focused}
            onChange={onChange}
            sx={{ backgroundColor: "white" }}

            error={
                (meta.touched && Boolean(meta.error)) || Boolean(props.apiError)
            }
            helperText={meta.touched && meta.error}
            variant={props.variant ? props.variant : "outlined"}
            size={props.size ? props.size : "medium"}
            InputProps={{
                disabled: props.readOnly ?? false,
                endAdornment: props.endAdornment
            }}
            onFocus={props.onFocus}
            onFocusCapture={props.onFocusCapture}
            onKeyDown={props.onKeyDown}
        />
    );
};
