import { Container, Typography } from "@mui/material";
import { FormControlComponent } from "components";
import { FC } from "react";
import { selectLocalization, useAppSelector } from "store";

import "./AdministrationPage.scss";
export const AdministrationPage: FC = () => {
  const localization = useAppSelector(selectLocalization);
  return (
    <Container maxWidth="md" id="administration-page">
      <Typography variant="h3" className="monitor-phase-title">
        {localization.administrationPage.title}
      </Typography>

      <FormControlComponent />
    </Container>
  );
};
