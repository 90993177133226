import { Button, Container, Grid, SelectChangeEvent } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RankingStageSelector } from '../RankingStageSelector';
import { GrantRankingFinalPhase2 } from './grant-ranking-final-phase2/GrantRankingFinalPhase2';
import { GrantRankingIntermediaryPhase2 } from './grant-ranking-intermediary-phase2/GrantRankingIntermediaryPhase2';
import { deleteAppeal, generatePhase2FinalResults, generatePhase2IntermediaryResults, getScoresForPhase2Intermediary, requestCompanyUsersNames, selectCurrentUser, selectGrantContestPageLocalization, selectScoresPhase2Intermediary, selectShowPhase2FinallRanking, selectShowPhase2IntermediaryRanking, selectUserIsUploadingAppeal, useAppDispatch, useAppSelector } from 'store';
import { PhaseNumber } from 'store/models/enums/PhaseNumber';
import { UserType } from 'store/models/enums/UserType';
import { EvaluationPageButtons } from 'components/shared/buttonGroups';
import { AppealComponent } from 'components/shared/appealComponent/AppealComponent';
import { setUserIsUploadingAppeal } from 'store/slices/grantEvaluationSlice';

export const GrantEvaluationPhase2RankingPage: FC = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser)
    const [stage, setStage] = useState<number>(1);
    const showPhase2FinallRanking = useAppSelector(selectShowPhase2FinallRanking);
    const showPhase2IntermediaryRanking = useAppSelector(selectShowPhase2IntermediaryRanking);
    const userIsUploadingAppeal = useAppSelector(selectUserIsUploadingAppeal);
    const [currentUserHasAppeal, setCurrentUserHasAppeal] = useState<boolean>(false);
    const [currentUserHasEvaluation, setCurrentUserHasEvaluation] = useState<boolean>(false);
    const scoresPhase2Intermediary = useAppSelector(selectScoresPhase2Intermediary);
    const localization = useAppSelector(selectGrantContestPageLocalization);

    useEffect(() => {
        if (!showPhase2IntermediaryRanking && showPhase2FinallRanking) {
            setStage(2);
        }
        if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) {
            dispatch(requestCompanyUsersNames({
                filterByRegistrationNumber: true,
                token: currentUser?.jwtToken
            }))
        }
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        scoresPhase2Intermediary.forEach((score: any) => {
            if (score.userId === currentUser?.userId) {
                setCurrentUserHasAppeal(score.hasAppeal);
                if (score.grantEvaluationId !== 0) {
                    setCurrentUserHasEvaluation(true);
                }
            }
        })
        //eslint-disable-next-line
    }, [scoresPhase2Intermediary])

    const handleChange = (event: SelectChangeEvent) => {
        setStage(parseInt(event.target.value, 10));
    };

    const isCompanyUser = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER) return true;
        return false;
    }

    const handleDeleteAppeal = () => {
        dispatch(deleteAppeal({
            userAppealingId: currentUser?.userId,
            token: currentUser?.jwtToken
        })).then(() => {
            dispatch(getScoresForPhase2Intermediary({
                filters: {
                    pageNumber: 0,
                },
                token: currentUser?.jwtToken
            }))
        })
    }

    const handleGenerateIntermediaryResults = () => {
        dispatch(generatePhase2IntermediaryResults({
            token: currentUser?.jwtToken,
        }));
    };

    const handleGenerateFinalResults = () => {
        dispatch(generatePhase2FinalResults({
            token: currentUser?.jwtToken,
        }));
    };


    return (
        <Container maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters>
            <Grid container sx={{
                minHeight: "inherit",
                p: {
                    xs: 1,
                    md: 2,
                },
            }}
                alignItems="stretch">
                <Grid container justifyContent="space-between" display={"flex"} flexDirection={"row"}>
                    <Grid item ><RankingStageSelector stage={stage} handleChange={handleChange} /></Grid>
                    <Grid item display={"flex"} flexDirection={"row"}>
                        {(isCompanyUser() && stage === 1) && <Grid item marginRight={1}>{!currentUserHasAppeal ? <Button onClick={() => dispatch(setUserIsUploadingAppeal(true))} variant="contained" disabled={!currentUserHasEvaluation}>{localization.phaseRank2Tab.fileAppealButton}</Button>
                            : <Button onClick={() => handleDeleteAppeal()} variant="contained" color="error">{localization.phaseRank2Tab.cancelAppealButton}</Button>}
                        </Grid>}
                        {userIsUploadingAppeal && < AppealComponent />}
                        {isCompanyUser() && <Grid item >
                            <EvaluationPageButtons
                                appealBtnDisabled={!currentUserHasAppeal} btnSize='medium' userId={currentUser?.userId} phaseNumber={PhaseNumber.Phase2} stage={stage} evaluationBtnDisabled={!currentUserHasEvaluation} />
                        </Grid>}
                        {(!isCompanyUser() && stage === 1) &&
                            <Grid>
                                <Button variant='contained' onClick={handleGenerateIntermediaryResults}>{localization.phaseRank2Tab.intermediaryResultsButton}</Button>
                            </Grid>
                        }
                        {(!isCompanyUser() && stage === 2) &&
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }} >

                                <Button variant='contained' onClick={handleGenerateFinalResults}>{localization.phaseRank2Tab.finalResultsButton}</Button>
                            </Grid>
                        }
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    {stage === 1 && <GrantRankingIntermediaryPhase2 stage={stage} />}
                    {stage === 2 && <GrantRankingFinalPhase2 stage={stage} />}
                </Grid>
            </Grid>

        </Container>);
}