import { EmailOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { UnallocatedCompanyModel } from "store/models/mediation/UnallocatedCompanyModel";
import "./CompanyCard.scss";

export const CompanyCard: FC<{ data: UnallocatedCompanyModel }> = ({
  data,
}) => {
  return (
    <Grid className="company-card" container>
      <Grid item xs={12} className="detail-container" container gap={2}>
        <PersonOutlineOutlined />
        <Typography variant="h6">{data.username}</Typography>
      </Grid>
      <Grid container className="detail-container" gap={2} item xs={12}>
        <EmailOutlined />
        <Typography>{data.email}</Typography>
      </Grid>
    </Grid>
  );
};
