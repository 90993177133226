import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { ReactComponent as NoSearch } from "../../../assets/images/search.svg";
import "./NoResultsScreen.scss";

export const NoResultsScreen: FC = () => {
  return (
    <Grid className="no-results-screen" item xs={12} container>
      <Grid item xs={12} container className="icon">
        <NoSearch className="no-results" />
      </Grid>
      <Grid item xs={12} container className="text">
        <Typography variant="h6" >
          Nu au fost găsite rezultate pentru căutarea dumneavoastră.
        </Typography>
      </Grid>
    </Grid>
  )
}