import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import "./CommunicationPage.scss";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import locale from "date-fns/locale/ro";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ReceivedView } from "./received-view/ReceivedView";
import { SentView } from "./sent-view/SentView";
import { ContactsView } from "./contacts-view/ContactsView";
import {
  useAppSelector,
  useAppDispatch,
  selectCommunicationDetails,
  selectDisplayCommunicationDialog,
  selectCurrentUser,
  requestGetContacts,
  selectCommunicationSortingOrder,
  selectCommunicationColumnToSortBy,
  selectReceivers,
  requestGetAllContacts,
  selectAllContacts,
  selectContactsSelectionModel,
  selectCommunicationPageLocalization,
} from "store";
import { SendOrViewCommunication } from "./send-or-view-communication/SendOrViewCommunication";
import { Drafts } from "@mui/icons-material";
import {
  setCommunicationDetails,
  setContactsSelectionModel,
  setPageNumber,
  setReceivers,
  setSearchedContacts,
} from "store/slices/communicationSlice";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";

export const CommunicationPage: FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useAppDispatch();
  const communicationDetails = useAppSelector(selectCommunicationDetails);
  const displayDialog = useAppSelector(selectDisplayCommunicationDialog);
  const sorting = useAppSelector(selectCommunicationSortingOrder);
  const columnToSortBy = useAppSelector(selectCommunicationColumnToSortBy);
  const currentUser = useAppSelector(selectCurrentUser);
  const allContacts = useAppSelector(selectAllContacts);
  const contactsSelectionModel = useAppSelector(selectContactsSelectionModel);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const localization = useAppSelector(selectCommunicationPageLocalization);

  useEffect(() => {
    dispatch(requestGetAllContacts({ token: currentUser?.jwtToken }));
    dispatch(setContactsSelectionModel([]));
    //eslint-disable-next-line
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    dispatch(setSearchedContacts(""));
  };

  const receivers = useAppSelector(selectReceivers);

  const handleSelectedReceivers = () => {
    let contactsReceiving: any[] = [];
    contactsSelectionModel.forEach((receiverId) => {
      var contact = allContacts?.find((c) => c.userId === receiverId);
      contactsReceiving.push({
        receiverId: contact.userId,
        receiverFirstName: contact.firstName,
        receiverLastName: contact.lastName,
      });
    });
    dispatch(setReceivers(contactsReceiving));
  };

  const handleNewCommunication = () => {
    dispatch(
      setCommunicationDetails({
        ...communicationDetails,
        to: receivers,
        from: currentUser?.userId,
        dateCreated: "",
        subject: "",
        message: "",
        files: [],
        firstName: "",
        lastName: "",
        userCommunicationId: null,
      })
    );
  };

  const handleTextFieldTextChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setSearchedContacts(event.target.value));
    if (event.target.value.length % 3 === 0) {
      dispatch(setPageNumber(0));
      if (columnToSortBy !== "") {
        let column = mapColumnNameToEnum(columnToSortBy);
        let sort = false;
        if (sorting === "asc") sort = true;
        dispatch(
          requestGetContacts({
            filter: {
              userId: currentUser?.userId,
              pageNumber: 0,
              columnToSortBy: column,
              sortingOrder: sort,
              searchString: event.target.value,
            },
            token: currentUser?.jwtToken,
          })
        );
      } else {
        dispatch(
          requestGetContacts({
            filter: {
              userId: currentUser?.userId,
              pageNumber: 0,
              columnToSortBy: DataGridColumnNames.CreatedAt,
              sortingOrder: false,
              searchString: event.target.value,
            },
            token: currentUser?.jwtToken,
          })
        );
      }
    }
  };

  return (
    <Container
      id="communication-page"
      maxWidth="lg"
      sx={{ minHeight: "inherit" }}
      disableGutters
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Grid
          container
          sx={{
            minHeight: "inherit",
          }}
          alignItems="stretch"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            container
            direction="column"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item xs="auto">
              <Tabs value={activeTab} onChange={handleChange}>
                <Tab label={localization.receivedTab.tabLabel} />
                <Tab label={localization.sentTab.tabLabel} />
                <Tab label={localization.contactsTab.tabLabel} />
              </Tabs>
            </Grid>
            <Grid item flexGrow={1}>
              {activeTab === 0 && <ReceivedView />}
              {activeTab === 1 && <SentView />}
              {activeTab === 2 && (
                <React.Fragment>
                  <Grid
                    container
                    height="100%"
                    alignContent="stretch"
                    direction="column"
                  >
                    <Grid
                      item
                      xs="auto"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: 1,
                      }}
                    >
                      <Tooltip
                        title={
                          localization.contactsTab.newCommunicationButtonTooltip
                        }
                      >
                        <Button
                          variant="contained"
                          onClick={handleNewCommunication}
                        >
                          <Drafts />
                        </Button>
                      </Tooltip>
                      <TextField
                        className="search"
                        id="filled-search"
                        label={localization.contactsTab.searchField}
                        type="search"
                        variant="outlined"
                        size="small"
                        onChange={handleTextFieldTextChanged}
                      />
                    </Grid>
                    <Grid item alignSelf="stretch" flexGrow={1}>
                      <ContactsView
                        onContactsSelect={handleSelectedReceivers}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Dialog
        fullScreen={isSmallScreen}
        id="send-or-view-communication"
        className="dialog"
        open={displayDialog}
        maxWidth="md"
        fullWidth
        disableScrollLock
      >
        <DialogContent
          sx={{
            height: "480px",
          }}
        >
          <SendOrViewCommunication />
        </DialogContent>
      </Dialog>
    </Container>
  );
};
