export const CovasnaSelectItemsLabel = [
  { value: "AITA MARE", label: "AITA MARE" },
  { value: "AITA MEDIE", label: "AITA MEDIE" },
  { value: "AITA SEACĂ", label: "AITA SEACĂ" },
  { value: "ALBIȘ", label: "ALBIȘ" },
  { value: "ALUNGENI", label: "ALUNGENI" },
  { value: "ANGHELUȘ", label: "ANGHELUȘ" },
  { value: "ANINOASA", label: "ANINOASA" },
  { value: "ARACI", label: "ARACI" },
  { value: "ARCUȘ", label: "ARCUȘ" },
  { value: "ARIUȘD", label: "ARIUȘD" },
  { value: "BĂCEL", label: "BĂCEL" },
  { value: "BARAOLT", label: "BARAOLT" },
  { value: "BARCANI", label: "BARCANI" },
  { value: "BĂȚANII MARI", label: "BĂȚANII MARI" },
  { value: "BĂȚANII MICI", label: "BĂȚANII MICI" },
  { value: "BELANI", label: "BELANI" },
  { value: "BELIN", label: "BELIN" },
  { value: "BELIN-VALE", label: "BELIN-VALE" },
  { value: "BIBORȚENI", label: "BIBORȚENI" },
  { value: "BICFALĂU", label: "BICFALĂU" },
  { value: "BITA", label: "BITA" },
  { value: "BIXAD", label: "BIXAD" },
  { value: "BODOC", label: "BODOC" },
  { value: "BODOȘ", label: "BODOȘ" },
  { value: "BOROȘNEU MARE", label: "BOROȘNEU MARE" },
  { value: "BOROȘNEU MIC", label: "BOROȘNEU MIC" },
  { value: "BRĂDET", label: "BRĂDET" },
  { value: "BRĂDUȚ", label: "BRĂDUȚ" },
  { value: "BRATEȘ", label: "BRATEȘ" },
  { value: "BREȚCU", label: "BREȚCU" },
  { value: "CALNIC", label: "CALNIC" },
  { value: "CĂPENI", label: "CĂPENI" },
  { value: "CĂRPINENII", label: "CĂRPINENII" },
  { value: "CAȘINU MIC", label: "CAȘINU MIC" },
  { value: "CATALINA", label: "CATALINA" },
  { value: "CERNAT", label: "CERNAT" },
  { value: "CHICHIȘ", label: "CHICHIȘ" },
  { value: "CHILIENI", label: "CHILIENI" },
  { value: "CHIURUȘ", label: "CHIURUȘ" },
  { value: "COMANDĂU", label: "COMANDĂU" },
  { value: "COȘENI", label: "COȘENI" },
  { value: "COVASNA", label: "COVASNA" },
  { value: "CRASNA", label: "CRASNA" },
  { value: "DALNIC", label: "DALNIC" },
  { value: "DOBÂRLĂU", label: "DOBÂRLĂU" },
  { value: "DOBOLII DE JOS", label: "DOBOLII DE JOS" },
  { value: "DOBOLII DE SUS", label: "DOBOLII DE SUS" },
  { value: "DOBOȘENI", label: "DOBOȘENI" },
  { value: "ESTELNIC", label: "ESTELNIC" },
  { value: "FILIA", label: "FILIA" },
  { value: "FLOROAIA", label: "FLOROAIA" },
  { value: "FOTOȘ", label: "FOTOȘ" },
  { value: "GHELINȚA", label: "GHELINȚA" },
  { value: "GHIDFALĂU", label: "GHIDFALĂU" },
  { value: "HĂGHIG", label: "HĂGHIG" },
  { value: "HARALE", label: "HARALE" },
  { value: "HĂTUICA", label: "HĂTUICA" },
  { value: "HERCULIAN", label: "HERCULIAN" },
  { value: "HETEA", label: "HETEA" },
  { value: "HILIB", label: "HILIB" },
  { value: "IARĂȘ", label: "IARĂȘ" },
  { value: "ICAFALĂU", label: "ICAFALĂU" },
  { value: "ILIENI", label: "ILIENI" },
  { value: "IMENI", label: "IMENI" },
  { value: "ÎNTORSURA BUZĂULUI", label: "ÎNTORSURA BUZĂULUI" },
  { value: "LĂDĂUȚI", label: "LĂDĂUȚI" },
  { value: "LEMNIA", label: "LEMNIA" },
  { value: "LEȚ", label: "LEȚ" },
  { value: "LISNĂU", label: "LISNĂU" },
  { value: "LISNĂU-VALE", label: "LISNĂU-VALE" },
  { value: "LUNCA MĂRCUȘULUI", label: "LUNCA MĂRCUȘULUI" },
  { value: "LUNCA OZUNULUI", label: "LUNCA OZUNULUI" },
  { value: "LUNGA", label: "LUNGA" },
  { value: "LUTOASA", label: "LUTOASA" },
  { value: "MĂGHERUȘ", label: "MĂGHERUȘ" },
  { value: "MALNAȘ", label: "MALNAȘ" },
  { value: "MALNAȘ-BĂI", label: "MALNAȘ-BĂI" },
  { value: "MĂRCUȘ", label: "MĂRCUȘ" },
  { value: "MĂRCUȘA", label: "MĂRCUȘA" },
  { value: "MĂRTĂNUȘ", label: "MĂRTĂNUȘ" },
  { value: "MĂRTINENI", label: "MĂRTINENI" },
  { value: "MERENI", label: "MERENI" },
  { value: "MERIȘOR", label: "MERIȘOR" },
  { value: "MICFALĂU", label: "MICFALĂU" },
  { value: "MICLOȘOARA", label: "MICLOȘOARA" },
  { value: "MOACȘA", label: "MOACȘA" },
  { value: "OITUZ", label: "OITUZ" },
  { value: "OJDULA", label: "OJDULA" },
  { value: "OLTENI", label: "OLTENI" },
  { value: "OZUN", label: "OZUN" },
  { value: "OZUNCA-BĂI", label: "OZUNCA-BĂI" },
  { value: "PACHIA", label: "PACHIA" },
  { value: "PĂDURENI", label: "PĂDURENI" },
  { value: "PĂPĂUȚI", label: "PĂPĂUȚI" },
  { value: "PETENI", label: "PETENI" },
  { value: "PETRICENI", label: "PETRICENI" },
  { value: "POIAN", label: "POIAN" },
  { value: "RACOȘUL DE SUS", label: "RACOȘUL DE SUS" },
  { value: "RECI", label: "RECI" },
  { value: "SACIOVA", label: "SACIOVA" },
  { value: "SÂNCRAIU", label: "SÂNCRAIU" },
  { value: "SÂNTIONLUNCA", label: "SÂNTIONLUNCA" },
  { value: "SÂNZIENI", label: "SÂNZIENI" },
  { value: "SĂRĂMĂȘ", label: "SĂRĂMĂȘ" },
  { value: "SCRĂDOASA", label: "SCRĂDOASA" },
  { value: "SFÂNTU GHEORGHE", label: "SFÂNTU GHEORGHE" },
  { value: "SITA BUZĂULUI", label: "SITA BUZĂULUI" },
  { value: "SURCEA", label: "SURCEA" },
  { value: "TĂLIȘOARA", label: "TĂLIȘOARA" },
  { value: "TAMAȘFALĂU", label: "TAMAȘFALĂU" },
  { value: "TÂRGU SECUIESC", label: "TÂRGU SECUIESC" },
  { value: "TELECHIA", label: "TELECHIA" },
  { value: "ȚUFALĂU", label: "ȚUFALĂU" },
  { value: "TURIA", label: "TURIA" },
  { value: "VÂLCELE", label: "VÂLCELE" },
  { value: "VALEA CRIȘULUI", label: "VALEA CRIȘULUI" },
  { value: "VALEA DOBÂRLĂULUI", label: "VALEA DOBÂRLĂULUI" },
  { value: "VALEA MARE", label: "VALEA MARE" },
  { value: "VALEA MICĂ", label: "VALEA MICĂ" },
  { value: "VALEA SCURTĂ", label: "VALEA SCURTĂ" },
  { value: "VALEA SEACĂ", label: "VALEA SEACĂ" },
  { value: "VALEA ZĂLANULUI", label: "VALEA ZĂLANULUI" },
  { value: "VÂRGHIȘ", label: "VÂRGHIȘ" },
  { value: "ZĂBALA", label: "ZĂBALA" },
  { value: "ZĂBRĂTĂU", label: "ZĂBRĂTĂU" },
  { value: "ZAGON", label: "ZAGON" },
  { value: "ZĂLAN", label: "ZĂLAN" },
  { value: "ZOLTAN", label: "ZOLTAN" },
];
