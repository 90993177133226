export const SatumareSelectItemsLabel = [
  { value: "ACÂȘ", label: "ACÂȘ" },
  { value: "ACIUA", label: "ACIUA" },
  { value: "ADRIAN", label: "ADRIAN" },
  { value: "ADY ENDRE", label: "ADY ENDRE" },
  { value: "AGRIȘ", label: "AGRIȘ" },
  { value: "ALICENI", label: "ALICENI" },
  { value: "ALIZA", label: "ALIZA" },
  { value: "AMAȚI", label: "AMAȚI" },
  { value: "AMBUD", label: "AMBUD" },
  { value: "ANDRID", label: "ANDRID" },
  { value: "APA", label: "APA" },
  { value: "APATEU", label: "APATEU" },
  { value: "ARDUD", label: "ARDUD" },
  { value: "ARDUD-VII", label: "ARDUD-VII" },
  { value: "ATEA", label: "ATEA" },
  { value: "BABA NOVAC", label: "BABA NOVAC" },
  { value: "BĂBĂȘEȘTI", label: "BĂBĂȘEȘTI" },
  { value: "BĂBEȘTI", label: "BĂBEȘTI" },
  { value: "BABȚA", label: "BABȚA" },
  { value: "BÂRSĂU DE JOS", label: "BÂRSĂU DE JOS" },
  { value: "BÂRSĂU DE SUS", label: "BÂRSĂU DE SUS" },
  { value: "BĂTARCI", label: "BĂTARCI" },
  { value: "BECHENI", label: "BECHENI" },
  { value: "BELTIUG", label: "BELTIUG" },
  { value: "BERCU", label: "BERCU" },
  { value: "BERCU NOU", label: "BERCU NOU" },
  { value: "BEREA", label: "BEREA" },
  { value: "BERINDAN", label: "BERINDAN" },
  { value: "BERVENI", label: "BERVENI" },
  { value: "BICĂU", label: "BICĂU" },
  { value: "BIXAD", label: "BIXAD" },
  { value: "BLAJA", label: "BLAJA" },
  { value: "BOCICĂU", label: "BOCICĂU" },
  { value: "BOGDAND", label: "BOGDAND" },
  { value: "BOGHIȘ", label: "BOGHIȘ" },
  { value: "BOINEȘTI", label: "BOINEȘTI" },
  { value: "BOLDA", label: "BOLDA" },
  { value: "BORLEȘTI", label: "BORLEȘTI" },
  { value: "BOTIZ", label: "BOTIZ" },
  { value: "CĂLINEȘTI-OAȘ", label: "CĂLINEȘTI-OAȘ" },
  { value: "CĂMĂRZANA", label: "CĂMĂRZANA" },
  { value: "CĂMIN", label: "CĂMIN" },
  { value: "CĂPLENI", label: "CĂPLENI" },
  { value: "CĂRĂȘEU", label: "CĂRĂȘEU" },
  { value: "CAREI", label: "CAREI" },
  { value: "CĂUAȘ", label: "CĂUAȘ" },
  { value: "CEAN", label: "CEAN" },
  { value: "CEHAL", label: "CEHAL" },
  { value: "CEHĂLUȚ", label: "CEHĂLUȚ" },
  { value: "CERTEZE", label: "CERTEZE" },
  { value: "CHEGEA", label: "CHEGEA" },
  { value: "CHEREUȘA", label: "CHEREUȘA" },
  { value: "CHILIA", label: "CHILIA" },
  { value: "CHISĂU", label: "CHISĂU" },
  { value: "CIDREAG", label: "CIDREAG" },
  { value: "CIG", label: "CIG" },
  { value: "CIONCHEȘTI", label: "CIONCHEȘTI" },
  { value: "CIUMEȘTI", label: "CIUMEȘTI" },
  { value: "CIUPERCENI", label: "CIUPERCENI" },
  { value: "COCA", label: "COCA" },
  { value: "COMLĂUȘA", label: "COMLĂUȘA" },
  { value: "COROD", label: "COROD" },
  { value: "CORUND", label: "CORUND" },
  { value: "CRAIDOROLȚ", label: "CRAIDOROLȚ" },
  { value: "CRIȘENI", label: "CRIȘENI" },
  { value: "CRUCIȘOR", label: "CRUCIȘOR" },
  { value: "CUCU", label: "CUCU" },
  { value: "CULCIU MARE", label: "CULCIU MARE" },
  { value: "CULCIU MIC", label: "CULCIU MIC" },
  { value: "CUȚA", label: "CUȚA" },
  { value: "DACIA", label: "DACIA" },
  { value: "DARA", label: "DARA" },
  { value: "DECEBAL", label: "DECEBAL" },
  { value: "DINDEȘTI", label: "DINDEȘTI" },
  { value: "DINDEȘTIU MIC", label: "DINDEȘTIU MIC" },
  { value: "DOBA", label: "DOBA" },
  { value: "DOBOLȚ", label: "DOBOLȚ" },
  { value: "DOBRA", label: "DOBRA" },
  { value: "DOMĂNEȘTI", label: "DOMĂNEȘTI" },
  { value: "DOROLȚ", label: "DOROLȚ" },
  { value: "DRĂGUȘENI", label: "DRĂGUȘENI" },
  { value: "DUMBRAVA", label: "DUMBRAVA" },
  { value: "ERIU SÂNCRAI", label: "ERIU SÂNCRAI" },
  { value: "ETENI", label: "ETENI" },
  { value: "FOIENI", label: "FOIENI" },
  { value: "GANAȘ", label: "GANAȘ" },
  { value: "GELU", label: "GELU" },
  { value: "GERĂUȘA", label: "GERĂUȘA" },
  { value: "GHENCI", label: "GHENCI" },
  { value: "GHERȚA MARE", label: "GHERȚA MARE" },
  { value: "GHERȚA MICĂ", label: "GHERȚA MICĂ" },
  { value: "GHILEȘTI", label: "GHILEȘTI" },
  { value: "GHILVACI", label: "GHILVACI" },
  { value: "GHIRIȘA", label: "GHIRIȘA" },
  { value: "GHIROLT", label: "GHIROLT" },
  { value: "GIOROCUTA", label: "GIOROCUTA" },
  { value: "GIUNGI", label: "GIUNGI" },
  { value: "GIURTELECU HODODULUI", label: "GIURTELECU HODODULUI" },
  { value: "HALMEU", label: "HALMEU" },
  { value: "HALMEU VII", label: "HALMEU VII" },
  { value: "HODIȘA", label: "HODIȘA" },
  { value: "HODOD", label: "HODOD" },
  { value: "HOMORODU DE JOS", label: "HOMORODU DE JOS" },
  { value: "HOMORODU DE MIJLOC", label: "HOMORODU DE MIJLOC" },
  { value: "HOMORODU DE SUS", label: "HOMORODU DE SUS" },
  { value: "HOREA", label: "HOREA" },
  { value: "HOTOAN", label: "HOTOAN" },
  { value: "HRIP", label: "HRIP" },
  { value: "HUREZU MARE", label: "HUREZU MARE" },
  { value: "HUTA CERTEZE", label: "HUTA CERTEZE" },
  { value: "IANCULEȘTI", label: "IANCULEȘTI" },
  { value: "IEGHERIȘTE", label: "IEGHERIȘTE" },
  { value: "IOJIB", label: "IOJIB" },
  { value: "IRINA", label: "IRINA" },
  { value: "ISTRĂU", label: "ISTRĂU" },
  { value: "LAZURI", label: "LAZURI" },
  { value: "LECHINȚA", label: "LECHINȚA" },
  { value: "LELEI", label: "LELEI" },
  { value: "LIPĂU", label: "LIPĂU" },
  { value: "LIVADA", label: "LIVADA" },
  { value: "LIVADA MICĂ", label: "LIVADA MICĂ" },
  { value: "LUCĂCENI", label: "LUCĂCENI" },
  { value: "LUNA", label: "LUNA" },
  { value: "LUNCA APEI", label: "LUNCA APEI" },
  { value: "MĂDĂRAS", label: "MĂDĂRAS" },
  { value: "MĂRIUȘ", label: "MĂRIUȘ" },
  { value: "MARNA NOUĂ", label: "MARNA NOUĂ" },
  { value: "MĂRTINEȘTI", label: "MĂRTINEȘTI" },
  { value: "MEDIEȘ RÂTURI", label: "MEDIEȘ RÂTURI" },
  { value: "MEDIEȘ VII", label: "MEDIEȘ VII" },
  { value: "MEDIEȘU AURIT", label: "MEDIEȘU AURIT" },
  { value: "MEDIȘA", label: "MEDIȘA" },
  { value: "MESTEACĂN", label: "MESTEACĂN" },
  { value: "MICULA", label: "MICULA" },
  { value: "MICULA NOUĂ", label: "MICULA NOUĂ" },
  { value: "MIHĂIENI", label: "MIHĂIENI" },
  { value: "MOFTINU MARE", label: "MOFTINU MARE" },
  { value: "MOFTINU MIC", label: "MOFTINU MIC" },
  { value: "MOIȘENI", label: "MOIȘENI" },
  { value: "NADIȘU HODODULUI", label: "NADIȘU HODODULUI" },
  { value: "NECOPOI", label: "NECOPOI" },
  { value: "NEGREȘTI-OAȘ", label: "NEGREȘTI-OAȘ" },
  { value: "NISIPENI", label: "NISIPENI" },
  { value: "NOROIENI", label: "NOROIENI" },
  { value: "OAR", label: "OAR" },
  { value: "ODOREU", label: "ODOREU" },
  { value: "ORAȘU NOU", label: "ORAȘU NOU" },
  { value: "ORAȘU NOU-VII", label: "ORAȘU NOU-VII" },
  { value: "ORBĂU", label: "ORBĂU" },
  { value: "PĂȘUNEA MARE", label: "PĂȘUNEA MARE" },
  { value: "PĂULEȘTI", label: "PĂULEȘTI" },
  { value: "PAULIAN", label: "PAULIAN" },
  { value: "PELEȘ", label: "PELEȘ" },
  { value: "PELIȘOR", label: "PELIȘOR" },
  { value: "PETEA", label: "PETEA" },
  { value: "PETIN", label: "PETIN" },
  { value: "PETREȘTI", label: "PETREȘTI" },
  { value: "PIR", label: "PIR" },
  { value: "PIRU NOU", label: "PIRU NOU" },
  { value: "PIȘCARI", label: "PIȘCARI" },
  { value: "PIȘCOLT", label: "PIȘCOLT" },
  { value: "POIANA CODRULUI", label: "POIANA CODRULUI" },
  { value: "POMI", label: "POMI" },
  { value: "PORTIȚA", label: "PORTIȚA" },
  { value: "PORUMBEȘTI", label: "PORUMBEȘTI" },
  { value: "POTĂU", label: "POTĂU" },
  { value: "PRILOG", label: "PRILOG" },
  { value: "PRILOG VII", label: "PRILOG VII" },
  { value: "RACOVA", label: "RACOVA" },
  { value: "RACȘA", label: "RACȘA" },
  { value: "RACȘA VII", label: "RACȘA VII" },
  { value: "RĂDULEȘTI", label: "RĂDULEȘTI" },
  { value: "RĂTEȘTI", label: "RĂTEȘTI" },
  { value: "RAȚIU", label: "RAȚIU" },
  { value: "REMETEA OAȘULUI", label: "REMETEA OAȘULUI" },
  { value: "RESIGHEA", label: "RESIGHEA" },
  { value: "ROMÂNEȘTI", label: "ROMÂNEȘTI" },
  { value: "ROȘIORI", label: "ROȘIORI" },
  { value: "RUȘENI", label: "RUȘENI" },
  { value: "SĂCĂȘENI", label: "SĂCĂȘENI" },
  { value: "SÂI", label: "SÂI" },
  { value: "ȘANDRA", label: "ȘANDRA" },
  { value: "SANISLĂU", label: "SANISLĂU" },
  { value: "SÂNMICLĂUȘ", label: "SÂNMICLĂUȘ" },
  { value: "SANTĂU", label: "SANTĂU" },
  { value: "SĂRĂTURA", label: "SĂRĂTURA" },
  { value: "SĂRĂUAD", label: "SĂRĂUAD" },
  { value: "SĂRVĂZEL", label: "SĂRVĂZEL" },
  { value: "SĂTMĂREL", label: "SĂTMĂREL" },
  { value: "SATU MARE", label: "SATU MARE" },
  { value: "SATU MIC", label: "SATU MIC" },
  { value: "SĂUCA", label: "SĂUCA" },
  { value: "SCĂRIȘOARA NOUĂ", label: "SCĂRIȘOARA NOUĂ" },
  { value: "SECHEREȘA", label: "SECHEREȘA" },
  { value: "SER", label: "SER" },
  { value: "SILVAȘ", label: "SILVAȘ" },
  { value: "ȘIRLĂU", label: "ȘIRLĂU" },
  { value: "SOCOND", label: "SOCOND" },
  { value: "SOCONZEL", label: "SOCONZEL" },
  { value: "SOLDUBA", label: "SOLDUBA" },
  { value: "SOMEȘENI", label: "SOMEȘENI" },
  { value: "STÂNA", label: "STÂNA" },
  { value: "SUDURĂU", label: "SUDURĂU" },
  { value: "SUPURU DE JOS", label: "SUPURU DE JOS" },
  { value: "SUPURU DE SUS", label: "SUPURU DE SUS" },
  { value: "TĂMĂȘENI", label: "TĂMĂȘENI" },
  { value: "TARNA MARE", label: "TARNA MARE" },
  { value: "TÂRȘOLȚ", label: "TÂRȘOLȚ" },
  { value: "TĂȘNAD", label: "TĂȘNAD" },
  { value: "TĂTĂREȘTI", label: "TĂTĂREȘTI" },
  { value: "ȚEGHEA", label: "ȚEGHEA" },
  { value: "TEREBEȘTI", label: "TEREBEȘTI" },
  { value: "TIREAC", label: "TIREAC" },
  { value: "TIREAM", label: "TIREAM" },
  { value: "TRAIAN", label: "TRAIAN" },
  { value: "TRIP", label: "TRIP" },
  { value: "TUR", label: "TUR" },
  { value: "TURȚ", label: "TURȚ" },
  { value: "TURȚ BĂI", label: "TURȚ BĂI" },
  { value: "TURULUNG", label: "TURULUNG" },
  { value: "TURULUNG VII", label: "TURULUNG VII" },
  { value: "UNIMĂT", label: "UNIMĂT" },
  { value: "URZICENI", label: "URZICENI" },
  { value: "URZICENI PĂDURE", label: "URZICENI PĂDURE" },
  { value: "VĂGAȘ", label: "VĂGAȘ" },
  { value: "VALEA MORII", label: "VALEA MORII" },
  { value: "VALEA SEACĂ", label: "VALEA SEACĂ" },
  { value: "VALEA VINULUI", label: "VALEA VINULUI" },
  { value: "VAMA", label: "VAMA" },
  { value: "VÂNĂTOREȘTI", label: "VÂNĂTOREȘTI" },
  { value: "VETIȘ", label: "VETIȘ" },
  { value: "VEZENDIU", label: "VEZENDIU" },
  { value: "VIILE SATU MARE", label: "VIILE SATU MARE" },
  { value: "VIIȘOARA", label: "VIIȘOARA" },
];
