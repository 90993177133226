import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";

export const mapColumnNameToEnum = function (
  columnName: any
): DataGridColumnNames {
  switch (columnName) {
    case "firstName": {
      return DataGridColumnNames.FirstName;
    }
    case "lastName": {
      return DataGridColumnNames.LastName;
    }
    case "createdAt": {
      return DataGridColumnNames.CreatedAt;
    }
    case "fileTitle": {
      return DataGridColumnNames.DocumentName;
    }
    case "fileSize": {
      return DataGridColumnNames.DocumentSize;
    }
    case "size": {
      return DataGridColumnNames.DocumentSize;
    }
    case "uploadTime": {
      return DataGridColumnNames.UploadTime;
    }
    case "from": {
      return DataGridColumnNames.Sender;
    }
    case "to": {
      return DataGridColumnNames.Receivers;
    }
    case "subject": {
      return DataGridColumnNames.Subject;
    }
    case "sentTime": {
      return DataGridColumnNames.SentTime;
    }
    case "email": {
      return DataGridColumnNames.Email;
    }
    case "author": {
      return DataGridColumnNames.UploadedByUser;
    }
    case "uniqueId": {
      return DataGridColumnNames.UniqueId;
    }
    case "score": {
      return DataGridColumnNames.Score;
    }
    case "comment": {
      return DataGridColumnNames.Comment;
    }
    case "finalScorePhase2": {
      return DataGridColumnNames.FinalScorePhase2;
    }
    case "finalScorePhase3": {
      return DataGridColumnNames.FinalScorePhase3;
    }
    case "commentsPhase2": {
      return DataGridColumnNames.CommentsPhase2;
    }
    case "entryDate": {
      return DataGridColumnNames.EntryDate;
    }
    case "employmentMarketSituation": {
      return DataGridColumnNames.EmploymentMarketSituation;
    }
    case "targetGroupCategory": {
      return DataGridColumnNames.TargetGroupCategory;
    }
    case "age": {
      return DataGridColumnNames.Age;
    }
    case "county": {
      return DataGridColumnNames.County;
    }
    case "locality": {
      return DataGridColumnNames.Locality;
    }
    case "phoneNumber": {
      return DataGridColumnNames.PhoneNumber;
    }
    case "expertUserId": {
      return DataGridColumnNames.ExpertUserId;
    }
    case "courseSeriesName": {
      return DataGridColumnNames.CourseSeriesName;
    }
    case "statusBeneficiar": {
      return DataGridColumnNames.StatusBeneficiar;
    }
    case "allocationTime": {
      return DataGridColumnNames.AllocationTime;
    }
    default: {
      return DataGridColumnNames.CreatedAt;
    }
  }
};
