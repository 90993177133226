import { Search } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import { ItemsSelector } from "components/shared/itemsSelector/ItemsSelector";
import { newUserPath } from "navigation";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { selectMediationPageLocalization, useAppDispatch, useAppSelector, useDebounce } from "store";
import { UserType } from "store/models/enums/UserType";
import { countiesSelectItems } from "store/models/selectItems";
import { selectEmployersFilters } from "store/selectors/mediationSelectors";
import { setEmployersFilters } from "store/slices/mediationSlice";
import { setSelectedTargetGroupUser } from "store/slices/targetGroupSlice";
import "./EmployersFilters.scss";

export const EmployersFilters: FC = () => {

  const filters = useAppSelector(selectEmployersFilters);
  const [search, setSearch] = useState<string>(filters.searchText);
  const debouncedValue = useDebounce(search, 500);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ro = useAppSelector(selectMediationPageLocalization);

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  }

  const handleCountyFilterChange = (e: any) => {
    dispatch(setEmployersFilters({
      ...filters,
      countyFilter: e.target.value
    }))
  }

  useEffect(() => {
    if (debouncedValue !== filters.searchText) {
      dispatch(setEmployersFilters({
        ...filters,
        searchText: debouncedValue
      }))
    }

    //eslint-disable-next-line
  }, [debouncedValue])

  const removeCountyFilter = () => {
    dispatch(setEmployersFilters({
      ...filters,
      countyFilter: undefined
    }))
  }


  const goToRegisterEmployer = () => {
    navigate(newUserPath, { state: { userType: UserType.EMPLOYER } });
    dispatch(setSelectedTargetGroupUser(null));
  }

  return (
    <Grid container id="employers-filters" >
      <Grid item xs={10} container columnSpacing={4} >
        <Grid item xs={12} sm={3}  >
          <TextField label={ro.search} value={search} fullWidth
            onChange={handleSearchChange} variant="standard" size="small"
            InputProps={{
              endAdornment: (
                <Search />
              )
            }} />
        </Grid>
        <Grid item xs={12} sm={3} >
          <ItemsSelector
            removeFilter={removeCountyFilter}
            label={ro.county}
            removeFilterDeleteIcon={filters.countyFilter === undefined}
            items={countiesSelectItems}
            value={filters.countyFilter ?? ""}
            setValue={handleCountyFilterChange}
          />
        </Grid>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Button variant="contained" size="small" color="success" onClick={goToRegisterEmployer} >{ro.addEmployerBtn}</Button>
      </Grid>
    </Grid>
  )
}