import { Container } from "@mui/material";
import { useEffect } from "react";
import {
  selectCurrentUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { isCompany } from "utils/getUserType";
import { ApplicationsFilter } from "./filter-applications/ApplicationsFilter";
import { ApplicationsTable } from "./table-applications/ApplicationsTable";
import "./ApplicationTab.scss";
import { setApplicationsFilters } from "store/slices/mediationSlice";
import { ApplicationStatus } from "store/models/enums/ApplicationStatus";

export const ApplicationsTab = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setApplicationsFilters({
        pageNumber: 0,
        pageSize: 10,
        applicationStatus: [ApplicationStatus.Interested],
      }))
    }
    //eslint-disable-next-line
  }, [])

  return (
    <Container className="applications-container">
      {isCompany(currentUser?.userType) ? (
        <ApplicationsTable />
      ) : (
        <>
          <ApplicationsFilter />
          <ApplicationsTable />
        </>
      )}
    </Container>
  );
};
