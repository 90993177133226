import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { CounselingFileModel } from "store/models/counseling/counselingFileModel";
import { CounselingFilterModel } from "store/models/counseling/counselingFiltersModel";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";

const axios = require("axios").default;

export const getPaginatedCounselingFiles = createAsyncThunk<
  CounselingFileModel[],
  { token: string | undefined; filters: CounselingFilterModel },
  AppThunkConfig
>("Counseling/GetFiles", async ({ token, filters }, thunkAPI) => {
  try {
    let result = await axios.get(baseUrl + "Counseling/GetFiles", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: filters.searchText,
        pageNumber: filters.pageNumber,
        sortingOrder: filters.sortingOrder,
        ColumnToSortBy: filters.columnToSortBy,
      },
    });

    return result.data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const addCounselingFile = createAsyncThunk<
  any,
  { uploadedFiles: File[]; token: string | undefined },
  AppThunkConfig
>("Counseling/UploadFile", async ({ uploadedFiles, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let formData = new FormData();

    uploadedFiles.forEach((file) => {
      formData.append("uploadedFiles", file);
    });
    let result = await axios.post(baseUrl + "Counseling/UploadFile", formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: result?.data?.message.text ?? "Fișiere incarcate cu succes",
        severity: "success",
      })
    );
    return result.data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const deleteCounselingFile = createAsyncThunk<
  any,
  { fileId: string; token: string | undefined },
  AppThunkConfig
>("Counseling/RemoveFile", async ({ fileId, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let form = new FormData();
    form.append("fileId", fileId);
    let result = await axios.post(baseUrl + "Counseling/RemoveFile", form, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: result?.data?.message?.text ?? "Documentul a fost sters",
        severity: "success",
      })
    );
    return result.data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const downloadCounselingFile = createAsyncThunk<
  any,
  { fileId: string },
  AppThunkConfig
>("Counseling/DownloadFile", async ({ fileId }, thunkAPI) => {
  try {
    let form = new FormData();
    form.append("fileId", fileId);

    let result = await axios.post(baseUrl + "Counseling/DownloadFile", form, {
      responseType: "blob",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    let blob = new Blob([result.data], {
      type: result.headers["content-type"],
    });
    let url = window.URL.createObjectURL(blob);

    let fileLink = document.createElement("a");
    fileLink.href = url;

    let filename = "";
    let disposition = result.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});
