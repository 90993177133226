import { createSlice } from "@reduxjs/toolkit";
import { CounselingState } from "store/models/counseling/counselingState";
import { getPaginatedCounselingFiles } from "store/thunks";

const initialState: CounselingState = {
  files: [],
  loadingFiles: false,
  counselingFilters: {
    searchText: "",
    pageNumber: 0,
    pageSize: 9,
    sortingOrder: false,
    columnToSortBy: 0,
  },
  filesCount: 0,
};

export const counselingSlice = createSlice({
  name: "counselingSlice",
  initialState: initialState,
  reducers: {
    resetCounselingState: () => initialState,
    setCounselingFilters(state, action) {
      state.counselingFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaginatedCounselingFiles.pending, (state) => {
      state.loadingFiles = true;
    });
    builder.addCase(getPaginatedCounselingFiles.fulfilled, (state, action) => {
      state.loadingFiles = false;
      state.files = action.payload;
      //@ts-ignore
      state.filesCount = action.payload.rowCount;
    });
    builder.addCase(getPaginatedCounselingFiles.rejected, (state) => {
      state.loadingFiles = false;
    });
  },
});

export const { resetCounselingState, setCounselingFilters } =
  counselingSlice.actions;
