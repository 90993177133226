import { FC, useState, useEffect } from "react";
import { MenuItem, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

interface FormikSelectSimpleProps {
  items: any[];
  label?: string;
  variant?: "standard" | "filled" | "outlined";
  name: string;
  size?: "medium" | "small";
  apiError?: string | null | undefined;
  id?: string;
  type?: string;
  readOnly?: boolean;
  validateOnChange?: boolean;
  onChangeCallback?: Function;
}

export const FormikSelectSimple: FC<FormikSelectSimpleProps> = ({
  items,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [isMounted, setIsMounted] = useState(false);
  const onChange = (e: any) => {
    field.onChange(e);
    if (props.onChangeCallback) {
      props.onChangeCallback(e);
    }
  };

  const formik = useFormikContext();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && props.validateOnChange) {
      formik.validateField(field.name);
    }
    // eslint-disable-next-line
  }, [field.value]);

  return (
    <TextField
      select
      name={field.name}
      label={props.label}
      value={
        field.value !== 0 && field.value !== null && field.value !== undefined
          ? field.value
          : ""
      }
      onChange={onChange}
      error={meta.touched && (Boolean(meta.error) || Boolean(props.apiError))}
      helperText={meta.touched && meta.error}
      fullWidth
      InputProps={{
        disabled: props.readOnly ?? false,
      }}
      SelectProps={{
        MenuProps: {
          disableScrollLock: true,
          PaperProps: { sx: { maxHeight: 300 } },
        },
      }}
    >
      {items?.map((item: any) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
