import { Pagination, Stack } from "@mui/material";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { selectJobsFilters, selectJobsTotalPages } from "store/selectors/mediationSelectors";
import { setJobsFilters } from "store/slices/mediationSlice";
import "./JobsPagination.scss";

export const JobsPagination: FC = () => {

  const totalPages = useAppSelector(selectJobsTotalPages);
  const filters = useAppSelector(selectJobsFilters);
  const dispatch = useAppDispatch();

  const handlePageChange = (event: any, page: number) => {
    if (page - 1 !== filters.pageNumber) {
      dispatch(setJobsFilters({
        ...filters,
        pageNumber: page - 1
      }))
    }

  }

  return (
    <Stack spacing={2}>
      <Pagination page={filters.pageNumber + 1} count={totalPages + 1} onChange={handlePageChange} variant="outlined" color="primary" size="large" />

    </Stack>
  )
}