export const SibiuSelectItemsLabel = [
  { value: "ACILIU", label: "ACILIU" },
  { value: "AGÂRBICIU", label: "AGÂRBICIU" },
  { value: "AGNITA", label: "AGNITA" },
  { value: "ALĂMOR", label: "ALĂMOR" },
  { value: "ALBI", label: "ALBI" },
  { value: "ALMA", label: "ALMA" },
  { value: "ALMA VII", label: "ALMA VII" },
  { value: "ALȚINA", label: "ALȚINA" },
  { value: "AMNAȘ", label: "AMNAȘ" },
  { value: "APOLDU DE JOS", label: "APOLDU DE JOS" },
  { value: "APOLDU DE SUS", label: "APOLDU DE SUS" },
  { value: "APOȘ", label: "APOȘ" },
  { value: "ARMENI", label: "ARMENI" },
  { value: "ARPAȘU DE JOS", label: "ARPAȘU DE JOS" },
  { value: "ARPAȘU DE SUS", label: "ARPAȘU DE SUS" },
  { value: "AȚEL", label: "AȚEL" },
  { value: "AVRIG", label: "AVRIG" },
  { value: "AXENTE SEVER", label: "AXENTE SEVER" },
  { value: "BÂRGHIȘ", label: "BÂRGHIȘ" },
  { value: "BAZNA", label: "BAZNA" },
  { value: "BENEȘTI", label: "BENEȘTI" },
  { value: "BIERTAN", label: "BIERTAN" },
  { value: "BLĂJEL", label: "BLĂJEL" },
  { value: "BOARTA", label: "BOARTA" },
  { value: "BOGATU ROMÂN", label: "BOGATU ROMÂN" },
  { value: "BOIAN", label: "BOIAN" },
  { value: "BOIȚA", label: "BOIȚA" },
  { value: "BRĂDENI", label: "BRĂDENI" },
  { value: "BRADU", label: "BRADU" },
  { value: "BRATEIU", label: "BRATEIU" },
  { value: "BROȘTENI", label: "BROȘTENI" },
  { value: "BRUIU", label: "BRUIU" },
  { value: "BUIA", label: "BUIA" },
  { value: "BUNGARD", label: "BUNGARD" },
  { value: "BUZD", label: "BUZD" },
  { value: "CÂRȚA", label: "CÂRȚA" },
  { value: "CÂRȚIȘOARA", label: "CÂRȚIȘOARA" },
  { value: "CAȘOLȚ", label: "CAȘOLȚ" },
  { value: "CHESLER", label: "CHESLER" },
  { value: "CHIRPĂR", label: "CHIRPĂR" },
  { value: "CISNĂDIE", label: "CISNĂDIE" },
  { value: "CISNĂDIOARA", label: "CISNĂDIOARA" },
  { value: "COLONIA TĂLMACIU", label: "COLONIA TĂLMACIU" },
  { value: "COLONIA TÂRNAVA", label: "COLONIA TÂRNAVA" },
  { value: "COLUN", label: "COLUN" },
  { value: "COPȘA MARE", label: "COPȘA MARE" },
  { value: "COPȘA MICĂ", label: "COPȘA MICĂ" },
  { value: "CORNĂȚEL", label: "CORNĂȚEL" },
  { value: "COVEȘ", label: "COVEȘ" },
  { value: "CRINȚ", label: "CRINȚ" },
  { value: "CRISTIAN", label: "CRISTIAN" },
  { value: "CURCIU", label: "CURCIU" },
  { value: "DAIA", label: "DAIA" },
  { value: "DÂRLOS", label: "DÂRLOS" },
  { value: "DEALU FRUMOS", label: "DEALU FRUMOS" },
  { value: "DOBÂRCA", label: "DOBÂRCA" },
  { value: "DUMBRĂVENI", label: "DUMBRĂVENI" },
  { value: "DUPUȘ", label: "DUPUȘ" },
  { value: "ERNEA", label: "ERNEA" },
  { value: "FÂNTÂNELE", label: "FÂNTÂNELE" },
  { value: "FLOREȘTI", label: "FLOREȘTI" },
  { value: "FOFELDEA", label: "FOFELDEA" },
  { value: "GALEȘ", label: "GALEȘ" },
  { value: "GHERDEAL", label: "GHERDEAL" },
  { value: "GHIJASA DE JOS", label: "GHIJASA DE JOS" },
  { value: "GHIJASA DE SUS", label: "GHIJASA DE SUS" },
  { value: "GIACĂȘ", label: "GIACĂȘ" },
  { value: "GLÂMBOACA", label: "GLÂMBOACA" },
  { value: "GURA RÂULUI", label: "GURA RÂULUI" },
  { value: "GUSU", label: "GUSU" },
  { value: "HAMBA", label: "HAMBA" },
  { value: "HAȘAG", label: "HAȘAG" },
  { value: "HOGHILAG", label: "HOGHILAG" },
  { value: "HOSMAN", label: "HOSMAN" },
  { value: "IACOBENI", label: "IACOBENI" },
  { value: "IGHIȘU NOU", label: "IGHIȘU NOU" },
  { value: "IGHIȘU VECHI", label: "IGHIȘU VECHI" },
  { value: "ILIMBAV", label: "ILIMBAV" },
  { value: "JINA", label: "JINA" },
  { value: "LASLEA", label: "LASLEA" },
  { value: "LAZARET", label: "LAZARET" },
  { value: "LOAMNEȘ", label: "LOAMNEȘ" },
  { value: "LOTRIOARA", label: "LOTRIOARA" },
  { value: "LUDOȘ", label: "LUDOȘ" },
  { value: "MAG", label: "MAG" },
  { value: "MĂLÂNCRAV", label: "MĂLÂNCRAV" },
  { value: "MÂNDRA", label: "MÂNDRA" },
  { value: "MARPOD", label: "MARPOD" },
  { value: "MÂRȘA", label: "MÂRȘA" },
  { value: "MEDIAȘ", label: "MEDIAȘ" },
  { value: "MERGHINDEAL", label: "MERGHINDEAL" },
  { value: "METIȘ", label: "METIȘ" },
  { value: "MICĂSASA", label: "MICĂSASA" },
  { value: "MIERCUREA SIBIULUI", label: "MIERCUREA SIBIULUI" },
  { value: "MIGHINDOALA", label: "MIGHINDOALA" },
  { value: "MIHĂILENI", label: "MIHĂILENI" },
  { value: "MOARDĂȘ", label: "MOARDĂȘ" },
  { value: "MOHU", label: "MOHU" },
  { value: "MOȘNA", label: "MOȘNA" },
  { value: "MOTIȘ", label: "MOTIȘ" },

  { value: "MOVILE", label: "MOVILE" },
  { value: "NEMȘA", label: "NEMȘA" },
  { value: "NETUȘ", label: "NETUȘ" },
  { value: "NOCRICH", label: "NOCRICH" },
  { value: "NOIȘTAT", label: "NOIȘTAT" },
  { value: "NOU", label: "NOU" },
  { value: "NOU SĂSESC", label: "NOU SĂSESC" },
  { value: "NOUL ROMÂN", label: "NOUL ROMÂN" },
  { value: "NUCET", label: "NUCET" },
  { value: "OCNA SIBIULUI", label: "OCNA SIBIULUI" },
  { value: "ORLAT", label: "ORLAT" },
  { value: "PĂDURENI", label: "PĂDURENI" },
  { value: "PALTIN", label: "PALTIN" },
  { value: "PĂLTINIȘ", label: "PĂLTINIȘ" },
  { value: "PĂUCA", label: "PĂUCA" },
  { value: "PĂUCEA", label: "PĂUCEA" },
  { value: "PELIȘOR", label: "PELIȘOR" },
  { value: "PETIȘ", label: "PETIȘ" },
  { value: "POIANA SIBIULUI", label: "POIANA SIBIULUI" },
  { value: "POIENIȚA", label: "POIENIȚA" },
  { value: "POPLACA", label: "POPLACA" },
  { value: "PORUMBACU DE JOS", label: "PORUMBACU DE JOS" },
  { value: "PORUMBACU DE SUS", label: "PORUMBACU DE SUS" },
  { value: "PRESACA", label: "PRESACA" },
  { value: "PRISLOP", label: "PRISLOP" },
  { value: "PROD", label: "PROD" },
  { value: "RACOVIȚA", label: "RACOVIȚA" },
  { value: "RĂȘINARI", label: "RĂȘINARI" },
  { value: "RÂU SADULUI", label: "RÂU SADULUI" },
  { value: "RĂVĂȘEL", label: "RĂVĂȘEL" },
  { value: "RETIȘ", label: "RETIȘ" },
  { value: "RICHIȘ", label: "RICHIȘ" },
  { value: "ROANDOLA", label: "ROANDOLA" },
  { value: "ROD", label: "ROD" },
  { value: "ROMANEȘTI", label: "ROMANEȘTI" },
  { value: "ROȘIA", label: "ROȘIA" },
  { value: "RUJA", label: "RUJA" },
  { value: "RUSCIORI", label: "RUSCIORI" },
  { value: "RUȘI", label: "RUȘI" },
  { value: "SĂCĂDATE", label: "SĂCĂDATE" },
  { value: "SĂCEL", label: "SĂCEL" },
  { value: "SĂDINCA", label: "SĂDINCA" },
  { value: "SADU", label: "SADU" },
  { value: "ȘALCĂU", label: "ȘALCĂU" },
  { value: "SĂLIȘTE", label: "SĂLIȘTE" },
  { value: "SÂNGĂTIN", label: "SÂNGĂTIN" },
  { value: "SĂRATA", label: "SĂRATA" },
  { value: "ȘAROȘ PE TÂRNAVE", label: "ȘAROȘ PE TÂRNAVE" },
  { value: "SĂSĂUȘ", label: "SĂSĂUȘ" },
  { value: "SCOREIU", label: "SCOREIU" },
  { value: "SEBEȘU DE JOS", label: "SEBEȘU DE JOS" },
  { value: "SEBEȘU DE SUS", label: "SEBEȘU DE SUS" },
  { value: "ȘEICA MARE", label: "ȘEICA MARE" },
  { value: "ȘEICA MICĂ", label: "ȘEICA MICĂ" },
  { value: "ȘELIMBĂR", label: "ȘELIMBĂR" },
  { value: "SIBIEL", label: "SIBIEL" },
  { value: "SIBIU", label: "SIBIU" },
  { value: "SLIMNIC", label: "SLIMNIC" },
  { value: "ȘMIG", label: "ȘMIG" },
  { value: "ȘOALA", label: "ȘOALA" },
  { value: "ȘOMARTIN", label: "ȘOMARTIN" },
  { value: "SOROȘTIN", label: "SOROȘTIN" },
  { value: "STEJĂRIȘU", label: "STEJĂRIȘU" },
  { value: "ȘTENEA", label: "ȘTENEA" },
  { value: "ȘURA MARE", label: "ȘURA MARE" },
  { value: "ȘURA MICĂ", label: "ȘURA MICĂ" },
  { value: "TĂLMĂCEL", label: "TĂLMĂCEL" },
  { value: "TĂLMACIU", label: "TĂLMACIU" },
  { value: "ȚAPU", label: "ȚAPU" },
  { value: "TÂRNAVA", label: "TÂRNAVA" },
  { value: "ȚELINE", label: "ȚELINE" },
  { value: "ȚICHINDEAL", label: "ȚICHINDEAL" },
  { value: "TILIȘCA", label: "TILIȘCA" },
  { value: "TOPÂRCEA", label: "TOPÂRCEA" },
  { value: "TURNU ROȘU", label: "TURNU ROȘU" },
  { value: "VALCHID", label: "VALCHID" },
  { value: "VALE", label: "VALE" },
  { value: "VALEA LUNGĂ", label: "VALEA LUNGĂ" },
  { value: "VALEA VIILOR", label: "VALEA VIILOR" },
  { value: "VĂLENI", label: "VĂLENI" },
  { value: "VĂRD", label: "VĂRD" },
  { value: "VECERD", label: "VECERD" },
  { value: "VELȚ", label: "VELȚ" },
  { value: "VESEUD / CHIRPĂR", label: "VESEUD / CHIRPĂR" },
  { value: "VESEUD / SLIMNIC", label: "VESEUD / SLIMNIC" },
  { value: "VEȘTEM", label: "VEȘTEM" },
  { value: "VURPĂR", label: "VURPĂR" },
  { value: "ZLAGNA", label: "ZLAGNA" },
];
