import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Form, Formik, } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { FormikTextField } from "../../../components/shared/formikInputs/FormikTextField";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "store";
import { selectSolicitareSubmitStatus } from "store/selectors/manageDocumentsSelectors";
import { setSolicitareSubmitStatus } from "store/slices/manageDocumentsSlice";
import Popover from "@mui/material/Popover";
import "./InputTableSolicitareModificari.scss";

interface InputTableSolicitareProps {
    data: any;
    onUploadSolicitare: Function;
    node: any;
    editModeId: number | null;
}

export const InputTableSolicitareModificari: FC<InputTableSolicitareProps> = ({ data, onUploadSolicitare, node, editModeId }) => {
    const formRef = useRef<any>();
    const dispatch = useAppDispatch();
    const prevProps = useRef<any>();

    const justificareBugetRef = useRef<any>();
    const justificarePlanRef = useRef<any>();

    const solicitareSubmitStatus = useAppSelector(selectSolicitareSubmitStatus);

    const [anchorElBuget, setAnchorElBuget] = useState<any>(null);
    const [bugetTextArea, setBugetTextArea] = useState<any>("");

    const handleOpenBugetPopover = (currentTarget: any, textArea: any) => {
        setBugetTextArea(textArea);
        setAnchorElBuget(currentTarget);
    };
    const openBuget = Boolean(anchorElBuget);
    const handleCloseBugetPopover = () => {
        setAnchorElBuget(null);
    };
    const [anchorElPlanAfaceri, setAnchorElPlanAfaceri] = useState<any>(null);
    const [justificarePlanTextArea, setJustificarePlanTextArea] = useState<any>("");

    const handleOpenPlanAfaceriPopover = (currentTarget: any, textArea: any) => {
        setJustificarePlanTextArea(textArea)
        setAnchorElPlanAfaceri(currentTarget);
    };
    const openPlanAfaceri = Boolean(anchorElPlanAfaceri);

    const handleClosePlanAfaceriPopover = () => {
        setAnchorElPlanAfaceri(null);
    };

    var initialValues = {
        sectiunePlanAfaceri: data.id ? data.sectiunePlanAfaceri : null,
        modificareSolicitataPlanAfaceri: data.id ? data.modificareSolicitataPlanAfaceri : null,
        justificareModificarePlanAfaceri: data.id ? data.justificareModificarePlanAfaceri : null,
        valoareInitialaPlanAfaceri: data.id ? data.valoareInitialaPlanAfaceri : null,
        valoareModificataPlanAfaceri: data.id ? data.valoareModificataPlanAfaceri : null,
        linieBuget: data.id ? data.linieBuget : null,
        modificareSolicitataBuget: data.id ? data.modificareSolicitataBuget : null,
        justificareModificareBuget: data.id ? data.justificareModificareBuget : null,
        valoareInitialaPlanBuget: data.id ? data.valoareInitialaPlanBuget : null,
        valoareModificataPlanBuget: data.id ? data.valoareModificataPlanBuget : null,
    }

    useEffect(() => {
        if (prevProps.current && (editModeId || editModeId !== prevProps.current)) {
            formRef.current.resetForm();
        }
        prevProps.current = editModeId;
        //eslint-disable-next-line
    }, [editModeId]);

    useEffect(() => {
        if (solicitareSubmitStatus === "loading") {
            handleSubmit();
        } else {
            dispatch(setSolicitareSubmitStatus("idle"));
        }
        //eslint-disable-next-line
    }, [solicitareSubmitStatus]);

    const validationSchema = yup.object({
        sectiunePlanAfaceri: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        modificareSolicitataPlanAfaceri: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        justificareModificarePlanAfaceri: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        valoareInitialaPlanAfaceri: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        valoareModificataPlanAfaceri: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        linieBuget: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        modificareSolicitataBuget: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        justificareModificareBuget: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        valoareInitialaPlanBuget: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
        valoareModificataPlanBuget: yup
            .string()
            .required("Câmp obligatoriu!").nullable(true),
    });

    const handleSubmit = () => {
        if (node.data.isNotSaved || (editModeId === node.data.id)) {
            if (solicitareSubmitStatus === "loading") {
                formRef.current.handleSubmit();
                if (formRef.current.errors.length === 0) {
                    onUploadSolicitare(node, formRef.current.values);
                }
                dispatch(setSolicitareSubmitStatus("idle"));
            }
        }
    }

    const handleJustificareKeyPress = (e: any) => {
        if (e.key === "Tab") {
            setAnchorElPlanAfaceri(null);
        }
    }

    const handleBugetJustificareKeyPress = (e: any) => {
        if (e.key === "Tab") {
            setAnchorElBuget(null);
        }
    }

    const handleTabPress = (e: any, textArea: any) => {
        if (e.key === "Tab") {
            handleOpenBugetPopover(justificareBugetRef.current, textArea);
        }
    }
    const handlePlanAfaceriTabPress = (e: any, textArea: any) => {
        if (e.key === "Tab") {
            handleOpenPlanAfaceriPopover(justificarePlanRef.current, textArea);
        }
    }


    return (
        <Grid container display={"inline-flex"} flexDirection={"column"} id="solicitare-modificari" >
            <Formik enableReinitialize
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={(values: any) => {
                    onUploadSolicitare(node, values);
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}>
                {({ values, errors, setFieldValue }) =>

                    <Form>
                        <Divider textAlign="left" sx={{ marginBottom: 1, "&::before": { borderTop: "thin solid black" }, "&::after": { borderTop: "thin solid black" }, maxWidth: 900 }}>

                            <Typography sx={{ backgroundColor: "white", paddingLeft: 1, paddingRight: 1, fontWeight: "bold" }} >Plan afaceri</Typography>

                        </Divider>
                        <Grid sx={{ display: "flex", flexWrap: "wrap" }}>

                            <Grid className="formik-input-container">

                                <FormikTextField
                                    label="Secțiune"
                                    name="sectiunePlanAfaceri"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />

                            </Grid>
                            <Grid className="formik-input-container" >
                                <FormikTextField
                                    label="Modificare solicitată"
                                    name="modificareSolicitataPlanAfaceri"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true}
                                    onKeyDown={(e: any) => { handlePlanAfaceriTabPress(e, values.justificareModificarePlanAfaceri) }}
                                />

                            </Grid>

                            <Grid ref={justificarePlanRef} className="formik-input-container">
                                <Grid className="justificare-container">
                                    <FormikTextField
                                        label="Justificare modificare"
                                        name="justificareModificarePlanAfaceri"
                                        size="small"
                                        focused={openPlanAfaceri}
                                        readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true}
                                        disableTyping={true}
                                        onClickEvent={(e: any) => handleOpenPlanAfaceriPopover(e.currentTarget, values.justificareModificarePlanAfaceri)}
                                    />
                                </Grid>
                                <Popover
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={openPlanAfaceri}
                                    anchorEl={anchorElPlanAfaceri}
                                    onClose={(e) => { setFieldValue('justificareModificarePlanAfaceri', justificarePlanTextArea); handleClosePlanAfaceriPopover() }}
                                >    <Grid sx={{ width: 600 }}>
                                        <TextField
                                            fullWidth
                                            value={justificarePlanTextArea}
                                            onChange={(e) => setJustificarePlanTextArea(e.target.value)}
                                            name="justificareModificarePlanAfaceri"
                                            disabled={node.data.isNotSaved || editModeId === node.data.id ? false : true}
                                            size="medium"
                                            multiline={true}
                                            onKeyDown={(e: any) => { setFieldValue('justificareModificarePlanAfaceri', justificarePlanTextArea); handleJustificareKeyPress(e); }}
                                            rows={10}


                                        />
                                    </Grid>
                                </Popover>

                            </Grid>
                            <Grid className="formik-input-container">

                                <FormikTextField
                                    label="Valoare inițială"
                                    name="valoareInitialaPlanAfaceri"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />

                            </Grid>
                            <Grid className="formik-input-container">

                                <FormikTextField
                                    label="Valoare modificată"
                                    name="valoareModificataPlanAfaceri"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />

                            </Grid>
                        </Grid>
                        <Divider textAlign="left" sx={{ marginTop: 1, marginBottom: 1, "&::before": { borderTop: "thin solid black" }, "&::after": { borderTop: "thin solid black" }, maxWidth: 900 }}>

                            <Typography sx={{ backgroundColor: "white", paddingLeft: 1, paddingRight: 1, fontWeight: "bold" }} >Buget</Typography>

                        </Divider>
                        <Grid sx={{ display: "flex", flexWrap: "wrap" }}>

                            <Grid className="formik-input-container">

                                <FormikTextField
                                    label="Linie"
                                    name="linieBuget"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />

                            </Grid>
                            <Grid className="formik-input-container" >

                                <FormikTextField
                                    label="Modificare solicitată"
                                    name="modificareSolicitataBuget"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true}
                                    onKeyDown={(e: any) => handleTabPress(e, values.justificareModificareBuget)}
                                />

                            </Grid>

                            <Grid ref={justificareBugetRef} className="formik-input-container">
                                <Grid className="justificare-container">
                                    <FormikTextField
                                        focused={openBuget}
                                        label="Justificare modificare"
                                        name="justificareModificareBuget"
                                        size="small"
                                        disableTyping={true}
                                        onClickEvent={(e: any) => handleOpenBugetPopover(e.currentTarget, values.justificareModificareBuget)}
                                        readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />
                                </Grid>
                                <Popover
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={openBuget}
                                    anchorEl={anchorElBuget}
                                    onClose={(e) => { setFieldValue('justificareModificareBuget', bugetTextArea); handleCloseBugetPopover() }}
                                >    <Grid sx={{ width: 600 }}>
                                        <TextField
                                            fullWidth
                                            value={bugetTextArea}
                                            onKeyDown={(e: any) => { handleBugetJustificareKeyPress(e); setFieldValue('justificareModificareBuget', bugetTextArea) }}
                                            onChange={(e) => setBugetTextArea(e.target.value)}
                                            name="justificareModificareBuget"
                                            disabled={node.data.isNotSaved || editModeId === node.data.id ? false : true}
                                            size="medium"
                                            autoFocus
                                            multiline={true}
                                            rows={10} />
                                    </Grid>
                                </Popover>
                            </Grid>
                            <Grid className="formik-input-container">

                                <FormikTextField
                                    label="Valoare inițială"
                                    name="valoareInitialaPlanBuget"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />

                            </Grid>
                            <Grid className="formik-input-container">

                                <FormikTextField
                                    label="Valoare modificată"
                                    name="valoareModificataPlanBuget"
                                    size="small"
                                    readOnly={node.data.isNotSaved || editModeId === node.data.id ? false : true} />

                            </Grid>
                        </Grid>

                    </Form>}
            </Formik>

        </Grid >
    )
}