import { RootState } from "store";
import { CounselingFileModel } from "store/models/counseling/counselingFileModel";
import { CounselingFilterModel } from "store/models/counseling/counselingFiltersModel";

export const selectCounselingFiles = (
  state: RootState
): CounselingFileModel[] => state.counseling.files;

export const selectCounselingLoading = (state: RootState): boolean =>
  state.counseling.loadingFiles;

export const selectCounselingFilters = (
  state: RootState
): CounselingFilterModel => state.counseling.counselingFilters;

export const selectCounselingFilesCount = (state: RootState): number =>
  state.counseling.filesCount;
