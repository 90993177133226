import { Button, ButtonGroup, Container, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid';
import DownloadIcon from "@mui/icons-material/Download";
import { FileUploadPopout } from 'components';
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { FC, useEffect, useState } from 'react';
import { deleteEvaluationGrid, downloadEvaluationGridById, requestFinalPhase2EvaluationGrids, selectCurrentUser, selectGrantApproved, selectGrantContestPageLocalization, selectPhase2FinalSortModel, selectUserPhase2FinalEvaluationGrids, selectUserPhase2FinalEvaluationGridsCount, selectUserPhase2TemporaryEvaluationGridsCount, selectUserPhase2TemporaryEvaluationGridsFileIsDownloading, selectUserPhase2TemporaryEvaluationGridsListIsLoading, uploadFinalPhase2EvaluationGrid, useAppDispatch, useAppSelector } from 'store';
import { dateTimeFormatOptions } from 'utils';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';
import { Add, Delete } from '@mui/icons-material';
import { UserType } from 'store/models/enums/UserType';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { setPhase2FinalSortModel } from 'store/slices/grantEvaluationSlice';
import { addAppNotification } from 'store/slices/appNotificationSlice';

export const Phase2FinallyEvaluationPage: FC<{ selectedUserId: number | undefined }> = ({ selectedUserId }) => {
    const dispatch = useAppDispatch();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const grantApproved = useAppSelector(selectGrantApproved);
    const currentUser = useAppSelector(selectCurrentUser);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);
    const phase2FinalSortModel = useAppSelector(selectPhase2FinalSortModel);
    const userPhase2FinalEvaluationGrids = useAppSelector(selectUserPhase2FinalEvaluationGrids);

    const rowsPerPage = 10;
    const userPhase2FinalEvaluationGridsCount = useAppSelector(selectUserPhase2FinalEvaluationGridsCount);
    const userPhase2TemporaryEvaluationGridsListIsLoading = useAppSelector(selectUserPhase2TemporaryEvaluationGridsListIsLoading);
    const userPhase2TemporaryEvaluationGridsFileIsDownloading = useAppSelector(selectUserPhase2TemporaryEvaluationGridsFileIsDownloading);
    const userPhase2TemporaryEvaluationGridsCount = useAppSelector(selectUserPhase2TemporaryEvaluationGridsCount);
    const localization = useAppSelector(selectGrantContestPageLocalization);

    const canAddDoc = currentUser?.userType !== UserType.COMPANY_USER ? ((selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) ? true : false) : true;

    useEffect(() => {
        setCurrentPageNumber(0);
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            getFinalPhase2EvaluationGrids(selectedUserId, 0, DataGridColumnNames.UploadTime, false);
        }
        //eslint-disable-next-line
    }, [selectedUserId]);

    const getFinalPhase2EvaluationGrids = (selectedUserId: number, pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean) => {
        dispatch(requestFinalPhase2EvaluationGrids({
            filter: {
                userId: selectedUserId,
                pageNumber: pageNumber,
                columnToSortBy: columnToSortBy,
                sortingOrder: sortingOrder
            },
            token: currentUser?.jwtToken,
        }))
    }


    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadFinalPhase2EvaluationGrid({
                    filter: {
                        userId: selectedUserId!,
                        files: files,
                        uploadedByUserId: currentUser?.userId!
                    },
                    token: currentUser?.jwtToken
                })
            ).then(() => {
                removeAllFilesCallback();
                let column = mapColumnNameToEnum(phase2FinalSortModel.columnToSortBy);
                let sort = false;
                if (phase2FinalSortModel.sortingOrder === "asc") sort = true;
                getFinalPhase2EvaluationGrids(selectedUserId!, currentPageNumber, column, sort);
                setCurrentPageNumber(0);
            }
            );

        }
    };


    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: localization.evaluationPhase2Tab.fileNameCol, flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: localization.evaluationPhase2Tab.uploadTimeCol,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "evaluatorName",
            headerName: localization.evaluationPhase2Tab.evaluatedByCol,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: localization.evaluationPhase2Tab.optionsCol,
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 100,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title={localization.evaluationPhase2Tab.downloadFileTooltip}>
                            <Button
                                disabled={userPhase2TemporaryEvaluationGridsFileIsDownloading}
                                onClick={(e) => {

                                    e.stopPropagation();
                                    dispatch(
                                        downloadEvaluationGridById({
                                            evaluationId: params.row.grantEvaluationId,
                                            token: currentUser?.jwtToken
                                        })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {
                            !grantApproved &&
                            <Tooltip title={localization.evaluationPhase2Tab.deleteFileTooltip}>
                                <Button
                                    disabled={userPhase2TemporaryEvaluationGridsFileIsDownloading}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            deleteEvaluationGrid({
                                                docId: params.row.grantEvaluationId,
                                                token: currentUser?.jwtToken,
                                            })
                                        ).then(() => {
                                            let column = mapColumnNameToEnum(phase2FinalSortModel.columnToSortBy);
                                            let sort = false;
                                            if (phase2FinalSortModel.sortingOrder === "asc") sort = true;
                                            getFinalPhase2EvaluationGrids(selectedUserId!, currentPageNumber, column, sort);
                                        });

                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>
                        }
                    </ButtonGroup >
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setPhase2FinalSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            getFinalPhase2EvaluationGrids(selectedUserId!, currentPageNumber, column, sort);
        } else {
            dispatch(setPhase2FinalSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            getFinalPhase2EvaluationGrids(selectedUserId!, 0, DataGridColumnNames.UploadTime, false);
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    return (
        <Container
            id="grant-evaluation-phase2-temporary-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{ backgroundColor: "lightgrey", padding: 1 }}
            >
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Grid container sx={{ justifyContent: "flex-start" }}>
                        <Typography variant='h6' color={"white"} textAlign="center">
                            {localization.evaluationPhase2Tab.finalTitle}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: "flex-end" }}>
                        {
                            !grantApproved && !(currentUser?.userType === UserType.COMPANY_USER) &&
                            <Button disabled={!canAddDoc || userPhase2TemporaryEvaluationGridsCount === 0} variant="contained" startIcon={<Add />} color="success"
                                onClick={() => {
                                    if (userPhase2FinalEvaluationGridsCount < 2) {
                                        setDisplayUploadDialog(true)
                                    } else {
                                        dispatch(addAppNotification({
                                            message: localization.evaluationPhase2Tab.only2GridsMessage,
                                            severity: "info",
                                        }));
                                    }
                                }}>
                                {localization.evaluationPhase2Tab.addButton}
                            </Button>
                        }
                    </Grid>
                    {
                        displayUploadDialog &&
                        <FileUploadPopout
                            showSize={true}
                            uploadOnButtonCallback={onFilesUpload}
                            uploadDisabled={!canAddDoc}
                            handleClose={handleClose}
                            displayDialog={displayUploadDialog}
                            fileLimit={1}
                            acceptedFileformats={[".doc", ".docx"]}
                        />
                    }
                </Grid>
                <Grid item
                    xs={12}
                    style={{ marginTop: "10px", backgroundColor: "white" }}
                    id="phase2-temporary-datagrid-container"
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userPhase2TemporaryEvaluationGridsListIsLoading}
                        hideFooterPagination={userPhase2TemporaryEvaluationGridsListIsLoading}
                        rows={userPhase2FinalEvaluationGrids}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={userPhase2FinalEvaluationGridsCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        rowHeight={49}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: "35vh",
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>
                </Grid>
            </Grid>
        </Container>
    );

}