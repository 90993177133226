import { Button, ButtonGroup, Container, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid';
import DownloadIcon from "@mui/icons-material/Download";
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { FC, useEffect, useState } from 'react';
import { deleteEvaluationGrid, requestFinalPhase3EvaluationGrids, selectCurrentUser, selectGrantApproved, selectPhase3FinalSortModel, selectUserPhase3FinalEvaluationGrids, selectUserPhase3FinalEvaluationGridsCount, selectUserPhase3EvaluationGridsListIsLoading, selectUserPhase3EvaluationGridsFileIsDownloading, useAppDispatch, useAppSelector, downloadEvaluationGridById, selectGrantContestPageLocalization } from 'store';
import { dateTimeFormatOptions } from 'utils';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';
import { Add, Delete } from '@mui/icons-material';
import { UserType } from 'store/models/enums/UserType';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { setPhase3FinalSortModel } from 'store/slices/grantEvaluationSlice';
import { UploadPhase3Evaluation } from './UploadPhase3Evaluation';
import { addAppNotification } from 'store/slices/appNotificationSlice';

export const Phase3FinallyEvaluationPage: FC<{ selectedUserId: number | undefined }> = ({ selectedUserId }) => {
    const dispatch = useAppDispatch();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const grantApproved = useAppSelector(selectGrantApproved);
    const currentUser = useAppSelector(selectCurrentUser);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);
    const phase3FinalSortModel = useAppSelector(selectPhase3FinalSortModel);
    const userPhase3FinalEvaluationGrids = useAppSelector(selectUserPhase3FinalEvaluationGrids);

    const rowsPerPage = 10;
    const userPhase3FinalEvaluationGridsCount = useAppSelector(selectUserPhase3FinalEvaluationGridsCount);
    const userPhase3EvaluationGridsListIsLoading = useAppSelector(selectUserPhase3EvaluationGridsListIsLoading);
    const userPhase3EvaluationGridsFileIsDownloading = useAppSelector(selectUserPhase3EvaluationGridsFileIsDownloading);

    const canAddDoc = currentUser?.userType !== UserType.COMPANY_USER ? ((selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) ? true : false) : true;
    const localization = useAppSelector(selectGrantContestPageLocalization);

    useEffect(() => {
        setCurrentPageNumber(0);
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            getFinalPhase3EvaluationGrids(selectedUserId, 0, DataGridColumnNames.UploadTime, false);
        }
        //eslint-disable-next-line
    }, [selectedUserId]);

    const getFinalPhase3EvaluationGrids = (selectedUserId: number | undefined, pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean) => {
        dispatch(requestFinalPhase3EvaluationGrids({
            filter: {
                userId: selectedUserId!,
                pageNumber: pageNumber,
                columnToSortBy: columnToSortBy,
                sortingOrder: sortingOrder
            },
            token: currentUser?.jwtToken,
        }))
    }


    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }




    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: localization.evaluationPhase3Tab.docNameCol, flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: localization.evaluationPhase3Tab.uploadTimeCol,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "evaluatorName",
            headerName: localization.evaluationPhase3Tab.evaluatedByCol,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: localization.evaluationPhase3Tab.optionsCol,
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title={localization.evaluationPhase3Tab.downloadFileTooltip}>
                            <Button
                                disabled={userPhase3EvaluationGridsFileIsDownloading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadEvaluationGridById({
                                            evaluationId: params.row.grantEvaluationId,
                                            token: currentUser?.jwtToken
                                        })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {!grantApproved &&
                            <Tooltip title={localization.evaluationPhase3Tab.deleteFileTooltip}>
                                <Button
                                    disabled={userPhase3EvaluationGridsFileIsDownloading}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            deleteEvaluationGrid({
                                                docId: params.row.grantEvaluationId,
                                                token: currentUser?.jwtToken,
                                            })
                                        ).then(() => {
                                            let column = mapColumnNameToEnum(phase3FinalSortModel.columnToSortBy);
                                            let sort = false;
                                            if (phase3FinalSortModel.sortingOrder === "asc") sort = true;
                                            getFinalPhase3EvaluationGrids(selectedUserId, currentPageNumber, column, sort);
                                        });

                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setPhase3FinalSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            getFinalPhase3EvaluationGrids(selectedUserId, currentPageNumber, column, sort);
        } else {
            dispatch(setPhase3FinalSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            getFinalPhase3EvaluationGrids(selectedUserId, 0, DataGridColumnNames.UploadTime, false);
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    return (
        <Container
            id="grant-evaluation-phase2-temporary-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{ backgroundColor: "lightgrey", padding: 1 }}
            >
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Grid container sx={{ justifyContent: "flex-start" }}>
                        <Typography variant='h6' color={"white"} textAlign="center">
                            {localization.evaluationPhase3Tab.finalEvaluation}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: "flex-end" }}>
                        {
                            !grantApproved && !(currentUser?.userType === UserType.COMPANY_USER) &&
                            <Button disabled={!canAddDoc} variant="contained" startIcon={<Add />} color="success"
                                onClick={() => {
                                    if (userPhase3FinalEvaluationGridsCount < 2) {
                                        setDisplayUploadDialog(true)
                                    } else {
                                        dispatch(addAppNotification({
                                            message: localization.evaluationPhase3Tab.only2GridsMessage,
                                            severity: "info",
                                        }));
                                    }
                                }}>
                                Adaugă
                            </Button>
                        }
                    </Grid>
                    {
                        displayUploadDialog &&
                        <UploadPhase3Evaluation
                            selectedUserId={selectedUserId}
                            handleClose={handleClose}
                            display={displayUploadDialog}
                        />
                    }
                </Grid>
                <Grid item
                    xs={12}
                    style={{ marginTop: "10px", backgroundColor: "white" }}
                    id="phase2-temporary-datagrid-container"
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userPhase3EvaluationGridsListIsLoading}
                        hideFooterPagination={userPhase3EvaluationGridsListIsLoading}
                        rows={userPhase3FinalEvaluationGrids}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={userPhase3FinalEvaluationGridsCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        rowHeight={49}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: "35vh",
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>
                </Grid>
            </Grid>
        </Container>
    );

}