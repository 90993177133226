import { Container, Grid } from '@mui/material';
import { EvaluationPageButtons } from 'components/shared/buttonGroups';
import { FC, useEffect, useState } from 'react';
import { requestCompanyUsersNames, selectCurrentUser, selectScoresPhase3Final, useAppDispatch, useAppSelector } from 'store';
import { PhaseNumber } from 'store/models/enums/PhaseNumber';
import { UserType } from 'store/models/enums/UserType';
import { GrantRankingFinalPhase3 } from './grant-ranking-final-phase3/GrantRankingFinalPhase3';


export const GrantEvaluationPhase3RankingPage: FC = () => {
    const currentUser = useAppSelector(selectCurrentUser)
    const dispatch = useAppDispatch();
    const [stage] = useState<number>(1);
    const scoresPhase3Final = useAppSelector(selectScoresPhase3Final);
    const [currentUserHasEvaluation, setCurrentUserHasEvaluation] = useState<boolean>(false);
    useEffect(() => {
        if (currentUser?.userType === UserType.EXPERT || currentUser?.userType === UserType.ADMIN) {
            dispatch(requestCompanyUsersNames({
                filterByRegistrationNumber: true,
                token: currentUser?.jwtToken
            }))
        }
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        scoresPhase3Final.forEach((score: any) => {
            if (score.userId === currentUser?.userId && score.grantEvaluationId !== 0) {
                setCurrentUserHasEvaluation(true);
            }
        })
        //eslint-disable-next-line
    }, [scoresPhase3Final])

    const isCompanyUser = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER) return true;
        return false;
    }

    return (
        <Container maxWidth={false}
            sx={{ minHeight: "inherit" }}
            disableGutters>
            <Grid container sx={{
                minHeight: "inherit",
                p: {
                    xs: 1,
                    md: 2,
                },
            }}
                alignItems="stretch">
                <Grid container justifyContent="flex-end" >
                    {isCompanyUser() && <Grid item >
                        <EvaluationPageButtons
                            btnSize='medium' userId={currentUser?.userId} phaseNumber={PhaseNumber.Phase3} stage={stage} evaluationBtnDisabled={!currentUserHasEvaluation} />
                    </Grid>}

                </Grid>
                <Grid item xs={12}>
                    <GrantRankingFinalPhase3 stage={stage} />
                </Grid>
            </Grid>

        </Container>);
}