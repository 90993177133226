import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";
import { useDebounce } from "store";

interface FormikDebouncedTextFieldProps {
  label?: string;
  variant?: "standard" | "filled" | "outlined";
  name: string;
  size?: "medium" | "small";
  apiError?: string | null | undefined;
  id?: string;
  type?: string;
  readOnly?: boolean;
  onClickEvent?: any;
  multiline?: boolean;
  rows?: number;
  minRows?: number | string;
  disableTyping?: boolean;
  onFocus?: any;
  onFocusCapture?: any;
  onKeyDown?: any;
  ref?: any;
  focused?: any;
  value?: any;
  inputRef?: any;
  className?: string;
  placeholder?: string;
  endAdornment?: any;
  text?: string;
  validateOnChange?: boolean;
}

export const FormikDebouncedTextField: FC<FormikDebouncedTextFieldProps> = (
  props
) => {
  const [field, meta] = useField(props);
  const [value, setValue] = useState<string>();
  const debouncedFieldValue = useDebounce(value, 400);
  const [isMounted, setIsMounted] = useState(false);

  const onChange = (e: any) => {
    if (!props.disableTyping) {
      setValue(e.target.value);
    }
  };
  const formik = useFormikContext();

  useEffect(() => {
    if (debouncedFieldValue !== null && debouncedFieldValue !== undefined) {
      formik.setFieldValue(props.name, debouncedFieldValue);
    }

    // eslint-disable-next-line
  }, [debouncedFieldValue]);

  useEffect(() => {
    if (value !== field.value) {
      setValue(field.value ?? "");
    }

    if (isMounted && props.validateOnChange) {
      formik.validateField(field.name);
    }

    // eslint-disable-next-line
  }, [field.value]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <TextField
      inputRef={props.ref}
      onClick={props.onClickEvent}
      ref={props.ref}
      multiline={props.multiline}
      rows={props.rows}
      minRows={props.minRows}
      placeholder={props.placeholder}
      id={props.id ? props.id : field.name}
      name={field.name}
      label={props.label}
      type={props.type ? props.type : "text"}
      fullWidth
      value={props.text ? value + " " + props.text ?? "" : value ?? ""}
      focused={props.focused}
      onChange={onChange}
      sx={{
        backgroundColor: "white" ? "white" : "black",
        color: props.readOnly ? "gray" : "none",
      }}
      error={(meta.touched && Boolean(meta.error)) || Boolean(props.apiError)}
      helperText={meta.touched && meta.error}
      variant={props.variant ? props.variant : "standard"}
      size={props.size ? props.size : "medium"}
      InputProps={{
        disabled: props.readOnly ?? false,
        readOnly: props.readOnly ?? false,
        endAdornment: props.endAdornment,
      }}
      onFocus={props.onFocus}
      onFocusCapture={props.onFocusCapture}
      onKeyDown={props.onKeyDown}
    />
  );
};
