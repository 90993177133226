import { Button, DialogContent, Grid } from "@mui/material";
import { FormikDatePicker } from "components/shared/formikInputs/FormikDatePicker";
import { FormikSelectSimple } from "components/shared/formikInputs/FormikSelectSimple";
import { FormikTextField } from "components/shared/formikInputs/FormikTextField";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import {
  addNewCourseSeries,
  requestCourseSeries,
  selectCourseSeriesItems,
  selectCurrentUser,
  selectVocationalTrainingPageLocalization,
  updateCourseSeries,
  useAppDispatch,
  useAppSelector,
} from "store";
import { AdeverintaType } from "store/models/enums/AdeverintaType";
import { SeriesType } from "store/models/enums/SeriesType";
import { adeverintaTypesItems } from "store/models/selectItems/adeverintaTypesItems";
import { UpdateSeriesModel } from "store/models/vocationalTraining/UpdateSeriesModel";
import * as yup from "yup";

const AddOrEditSeries: FC<{
  selectedSeriesToEdit: UpdateSeriesModel | undefined;
  type: SeriesType;
  setDisplayDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSeries: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
}> = ({ selectedSeriesToEdit, type, setDisplayDialog, setSelectedSeries }) => {
  const dispatch = useAppDispatch();
  const courseSeriesItems = useAppSelector(selectCourseSeriesItems);
  const currentUser = useAppSelector(selectCurrentUser);
  const ro = useAppSelector(selectVocationalTrainingPageLocalization);

  const validationSchema = yup.object({
    seriesName: yup.string().required(ro.nameValidation),
    adeverintaType: yup.number().when("type", {
      is: (val: SeriesType) => val === SeriesType.Course,
      then: yup
        .number()
        .integer()
        .min(1, ro.adeverintaValidation)
        .required(ro.adeverintaValidation),
    }),
    courseSeriesId: yup.number().when("type", {
      is: (val: SeriesType) => val === SeriesType.Exam,
      then: yup
        .number()
        .integer()
        .min(1, ro.courseValidation)
        .required(ro.courseValidation),
    }),
    examDate: yup
      .date()
      .nullable(true)
      .when("type", {
        is: (val: SeriesType) => val === SeriesType.Exam,
        then: (schema: any) => schema.required(ro.examDateValidation),
      }),
    periodStart: yup
      .date()
      .nullable(true)
      .when("type", {
        is: (val: SeriesType) => val === SeriesType.Course,
        then: (schema: any) =>
          schema
            .required("Data de început este obligatorie")
            .typeError("Data de început este obligatorie"),
      }),
    periodEnd: yup
      .date()
      .nullable(true)
      .when("type", {
        is: (val: SeriesType) => val === SeriesType.Course,
        then: (schema: any) =>
          schema
            .required("Data de sfârșit este obligatorie")
            .typeError("Data de sfârșit este obligatorie"),
      }),
  });

  return (
    <DialogContent>
      <Formik
        onSubmit={(values) => {
          if (selectedSeriesToEdit) {
            dispatch(
              updateCourseSeries({
                dto: {
                  ...values,
                  adeverintaType:
                    type === SeriesType.Course
                      ? values.adeverintaType
                      : courseSeriesItems?.find(
                          (x) => x.value === values.courseSeriesId
                        )?.adeverintaType!,
                  seriesId: selectedSeriesToEdit.seriesId,
                  examDate: values.examDate?.toISOString(),
                  periodStart: values.periodStart?.toISOString(),
                  periodEnd: values.periodEnd?.toISOString(),
                  type: type,
                },
                token: currentUser?.jwtToken,
              })
            ).then(() => {
              setDisplayDialog(false);
              dispatch(
                requestCourseSeries({
                  token: currentUser?.jwtToken,
                  type: type,
                })
              );
            });
          } else {
            dispatch(
              addNewCourseSeries({
                ...values,
                type: type,
                token: currentUser?.jwtToken,
                examDate: values.examDate?.toISOString(),
                periodStart: values.periodStart?.toISOString(),
                periodEnd: values.periodEnd?.toISOString(),
              })
            )
              .unwrap()
              .then((response) => {
                setDisplayDialog(false);
                dispatch(
                  requestCourseSeries({
                    token: currentUser?.jwtToken,
                    type: type,
                  })
                ).then(() => {
                  setSelectedSeries(response);
                });
              });
          }
        }}
        initialValues={{
          type: type,
          seriesName: selectedSeriesToEdit
            ? selectedSeriesToEdit.seriesName
            : "",
          adeverintaType: selectedSeriesToEdit
            ? selectedSeriesToEdit.adeverintaType
            : (0 as AdeverintaType),
          examDate: selectedSeriesToEdit
            ? new Date(selectedSeriesToEdit.examDate!)
            : null,
          courseSeriesId: selectedSeriesToEdit
            ? selectedSeriesToEdit.courseSeriesId
            : 0,
          periodStart: selectedSeriesToEdit
            ? new Date(selectedSeriesToEdit.periodStart!)
            : null,
          periodEnd: selectedSeriesToEdit
            ? new Date(selectedSeriesToEdit.periodEnd!)
            : null,
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Grid container alignItems="center" alignContent="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormikTextField label={ro.seriesNameLabel} name="seriesName" />
            </Grid>
            {type === SeriesType.Exam && (
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <FormikSelectSimple
                    label={ro.courseSeriesLabel}
                    name="courseSeriesId"
                    items={courseSeriesItems}
                  />
                </Grid>
                <Grid item xs={12} sm={12} container justifyContent="center">
                  <Grid item xs={12} sm={6}>
                    <FormikDatePicker
                      label={ro.examDateLabel}
                      name="examDate"
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {type === SeriesType.Course && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormikSelectSimple
                    label={ro.adeverintaTypeLabel}
                    name="adeverintaType"
                    items={adeverintaTypesItems}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikDatePicker name="periodStart" label={ro.startDate} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikDatePicker name="periodEnd" label={ro.endDate} />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setDisplayDialog(true)}
                type="submit"
              >
                {selectedSeriesToEdit ? ro.updateSeriesBtn : ro.addSeriesBtn}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </DialogContent>
  );
};

export default AddOrEditSeries;
