import { StatusBeneficiarType } from "../enums/StatusBeneficiarType";

export const statusBeneficiarSelectItems = [
  {
    label: "Recrutat",
    value: StatusBeneficiarType.Recruited,
  },
  { label: "În formare", value: StatusBeneficiarType.InFormation },
  { label: "Format", value: StatusBeneficiarType.Formed },
  { label: "Angajat", value: StatusBeneficiarType.Employed },
];
