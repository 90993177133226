import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationStatus } from "store/models/enums/ApplicationStatus";
import {
  AllocatedUserModel,
  AllocationsFiltersModel,
  EmployerModel,
  EmployersFiltersModel,
  JobPostDetailsModel,
  JobPostModel,
  JobPostsFiltersModel,
  PaginatedJobPostsModel,
} from "store/models/mediation";
import { AllocationModel } from "store/models/mediation/AllocationModel";
import { ApplicationModel } from "store/models/mediation/ApplicationModel";
import { EmployerNameModel } from "store/models/mediation/EmployerNameModel";
import { JobTitleModel } from "store/models/mediation/JobTitleModel";
import { MediationState } from "store/models/mediation/MediationState";
import { PaginatedAllocationsModel } from "store/models/mediation/PaginatedAllocationsModel";
import { PaginatedApplicationsModel } from "store/models/mediation/PaginatedApplicationsModel";
import { UnallocatedCompanyModel } from "store/models/mediation/UnallocatedCompanyModel";
import {
  generateCountiesJobsArchive,
  getEmployersNames,
  getFilteredPaginatedEmployers,
  getFilteredPaginatedJobPosts,
  getJobPost,
  getJobsWithAllocations,
  getJobsWithApplications,
  getPaginatedAllocations,
  getPaginatedApplications,
  getUnallocatedCompanies,
  getUsersAllocatedToJobPost,
} from "store/thunks/mediationThunks";

export const mediationSlice = createSlice({
  name: "mediationSlice",
  initialState: {
    actions: {},
    jobs: new Array<JobPostModel>(),
    totalPagesJobs: 0,
    jobsLoading: false,
    jobDetails: undefined,
    jobLoading: false,
    jobPostsFilters: {
      searchText: "",
      pageNumber: 0,
      pageSize: 9,
      employerId: 0,
    },
    employers: new Array<EmployerModel>(),
    employersLoading: false,
    employersCount: 0,
    employersFilters: {
      searchText: "",
      pageNumber: 0,
      pageSize: 10,
    },
    paginatedUnallocatedCompaniesFilters: {
      search: "",
      currentPageNumber: 0,
      pageNumber: 0,
    },
    totalPages: 0,
    employersNames: new Array<EmployerNameModel>(),
    employersNamesLoading: false,
    selectedCompanyId: undefined,
    companies: new Array<UnallocatedCompanyModel>(),
    companiesLoading: false,
    allocationsFilters: {
      pageNumber: 0,
      pageSize: 10,
      jobPostId: 0,
    },
    generatingArchive: false,
    allocations: [],
    allocationsTotalCount: 0,
    allocationsTotalPages: 0,
    applications: new Array<ApplicationModel>(),
    applicationsTotalCount: 0,
    applicationsTotalPages: 0,
    applicationsFilters: {
      pageNumber: 0,
      pageSize: 10,
      applicationStatus: [ApplicationStatus.Interested],
    },
    allocationsLoading: false,
    applicationsLoading: false,
    jobsWithAllocations: new Array<JobTitleModel>(),
    jobsWithApplications: new Array<JobTitleModel>(),
    jobsWithAllocationsLoading: false,
  } as MediationState,
  reducers: {
    setAllocationsFilters(
      state,
      action: PayloadAction<AllocationsFiltersModel>
    ) {
      state.allocationsFilters = action.payload;
    },
    setEmployersFilters(state, action: PayloadAction<EmployersFiltersModel>) {
      state.employersFilters = action.payload;
    },
    setJobsFilters(state, action: PayloadAction<JobPostsFiltersModel>) {
      state.jobPostsFilters = action.payload;
    },
    setJobDetails(state, action) {
      state.jobDetails = action.payload;
    },
    setSelectedCompany(state, action) {
      state.selectedCompanyId = action.payload;
    },
    setPaginatedUnallocatedCompaniesFilters(state, action) {
      if (
        state.paginatedUnallocatedCompaniesFilters.search !==
        action.payload.search
      )
        state.companies = [];
      state.paginatedUnallocatedCompaniesFilters = action.payload;
    },

    setCurrentPageNumber(state, action) {
      state.paginatedUnallocatedCompaniesFilters.currentPageNumber =
        action.payload;
    },
    removeCompanyFromUnallocated(state, action) {
      state.companies = state.companies.filter(
        (x) => x.userId !== action.payload
      );
    },
    setUnallocatedCompanies(state, action) {
      state.companies = action.payload;
    },
    setApplicationsFilters(state, action) {
      state.applicationsFilters = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      generateCountiesJobsArchive.pending,
      (state: MediationState) => {
        state.generatingArchive = true;
      }
    );
    builder.addCase(
      generateCountiesJobsArchive.rejected,
      (state: MediationState) => {
        state.generatingArchive = false;
      }
    );
    builder.addCase(
      generateCountiesJobsArchive.fulfilled,
      (state: MediationState) => {
        state.generatingArchive = false;
      }
    );
    builder.addCase(
      getFilteredPaginatedEmployers.pending,
      (state: MediationState, action: any) => {
        state.employersLoading = true;
      }
    );
    builder.addCase(
      getFilteredPaginatedEmployers.fulfilled,
      (state: MediationState, action: any) => {
        state.employersLoading = false;
        state.employers = action.payload.employers;
        state.employersCount = action.payload.totalCount;
      }
    );
    builder.addCase(
      getFilteredPaginatedEmployers.rejected,
      (state: MediationState, action: any) => {
        state.employersLoading = false;
        state.employers = [];
      }
    );
    builder.addCase(
      getFilteredPaginatedJobPosts.pending,
      (state: MediationState, action: PayloadAction<any>) => {
        state.jobsLoading = true;
      }
    );
    builder.addCase(
      getFilteredPaginatedJobPosts.fulfilled,
      (
        state: MediationState,
        action: PayloadAction<PaginatedJobPostsModel>
      ) => {
        state.jobs = action.payload.jobPosts;
        state.totalPagesJobs = action.payload.totalPages;
        state.jobsLoading = false;
      }
    );
    builder.addCase(
      getFilteredPaginatedJobPosts.rejected,
      (state: MediationState, action: PayloadAction<any>) => {
        state.jobsLoading = false;
        state.jobs = [];
      }
    );
    builder.addCase(
      getEmployersNames.pending,
      (state: MediationState, action: PayloadAction<any>) => {
        state.employersNamesLoading = true;
      }
    );
    builder.addCase(
      getEmployersNames.fulfilled,
      (state: MediationState, action: PayloadAction<any>) => {
        state.employersNames = action.payload;
        state.employersNamesLoading = false;
      }
    );
    builder.addCase(
      getEmployersNames.rejected,
      (state: MediationState, action: PayloadAction<any>) => {
        state.employersNames = [];
        state.employersNamesLoading = false;
      }
    );
    builder.addCase(
      getJobPost.pending,
      (state: MediationState, action: PayloadAction<any>) => {
        state.jobDetails = undefined;
        state.jobLoading = true;
      }
    );
    builder.addCase(
      getJobPost.fulfilled,
      (state: MediationState, action: PayloadAction<JobPostDetailsModel>) => {
        state.jobDetails = action.payload;
        state.allocationsFilters = {
          ...state.allocationsFilters,
          jobPostId: action.payload.jobPostId,
        };
        state.jobLoading = false;
      }
    );
    builder.addCase(
      getJobPost.rejected,
      (state: MediationState, action: PayloadAction<any>) => {
        state.jobLoading = false;
      }
    );
    builder.addCase(
      getUnallocatedCompanies.pending,
      (state: MediationState, action: PayloadAction<any>) => {
        state.companiesLoading = true;
      }
    );
    builder.addCase(
      getUnallocatedCompanies.fulfilled,
      (state: MediationState, action: PayloadAction<any>) => {
        if (action.payload.pageNumber === 0) {
          state.companies = action.payload.companies;

          state.companiesLoading = false;
          state.totalPages = action.payload.totalPages;
        } else
          state.companies = [...state.companies, ...action.payload.companies];

        state.companiesLoading = false;
        state.totalPages = action.payload.totalPages;
      }
    );
    builder.addCase(
      getUnallocatedCompanies.rejected,
      (state: MediationState, action: PayloadAction<any>) => {
        state.companies = [];
        state.companiesLoading = false;
      }
    );
    builder.addCase(
      getUsersAllocatedToJobPost.pending,
      (state: MediationState, action: PayloadAction<any>) => {}
    );
    builder.addCase(
      getUsersAllocatedToJobPost.fulfilled,
      (state: MediationState, action: PayloadAction<AllocatedUserModel[]>) => {
        state.jobDetails = {
          ...state.jobDetails!,
          allocatedUsers: action.payload,
        };
      }
    );
    builder.addCase(
      getUsersAllocatedToJobPost.rejected,
      (state: MediationState, action: PayloadAction<any>) => {}
    );
    builder.addCase(
      getPaginatedAllocations.pending,
      (state: MediationState, action: PayloadAction<any>) => {
        state.allocationsLoading = true;
        state.allocations = [];
      }
    );
    builder.addCase(
      getPaginatedAllocations.fulfilled,
      (
        state: MediationState,
        action: PayloadAction<PaginatedAllocationsModel>
      ) => {
        state.allocationsTotalCount = action.payload.totalCount;
        state.allocationsTotalPages = action.payload.totalPages;
        state.allocations = action.payload.allocations.map(
          (value: AllocationModel) => {
            return {
              ...value,
              id: value.allocationId,
            };
          }
        );
        state.allocationsLoading = false;
      }
    );
    builder.addCase(
      getPaginatedAllocations.rejected,
      (state: MediationState, action: PayloadAction<any>) => {
        state.allocationsLoading = false;
      }
    );

    builder.addCase(
      getPaginatedApplications.pending,
      (state: MediationState, action: PayloadAction<any>) => {
        state.applicationsLoading = true;
        state.applications = [];
      }
    );
    builder.addCase(
      getPaginatedApplications.fulfilled,
      (
        state: MediationState,
        action: PayloadAction<PaginatedApplicationsModel>
      ) => {
        state.applicationsTotalCount = action.payload.totalCount;
        state.applicationsTotalPages = action.payload.totalPages;
        state.applications = action.payload.applications.map(
          (value: ApplicationModel) => {
            return {
              ...value,
              id: value.applicationId,
            };
          }
        );
        state.applicationsLoading = false;
      }
    );
    builder.addCase(
      getPaginatedApplications.rejected,
      (state: MediationState, action: PayloadAction<any>) => {
        state.applicationsLoading = false;
      }
    );
    builder.addCase(
      getJobsWithAllocations.fulfilled,
      (state: MediationState, action: PayloadAction<any>) => {
        state.jobsWithAllocations = action.payload;
      }
    );
    builder.addCase(
      getJobsWithApplications.pending,
      (state: MediationState) => {
        state.jobsWithAllocationsLoading = true;
      }
    );
    builder.addCase(
      getJobsWithApplications.fulfilled,
      (state: MediationState, action: PayloadAction<any>) => {
        state.jobsWithApplications = action.payload;
        state.jobsWithAllocationsLoading = false;
      }
    );
    builder.addCase(
      getJobsWithApplications.rejected,
      (state: MediationState) => {
        state.jobsWithAllocationsLoading = false;
      }
    );
  },
});

export const {
  setEmployersFilters,
  setJobsFilters,
  setJobDetails,
  setSelectedCompany,
  setPaginatedUnallocatedCompaniesFilters,
  setAllocationsFilters,
  setCurrentPageNumber,
  removeCompanyFromUnallocated,
  setUnallocatedCompanies,
  setApplicationsFilters,
} = mediationSlice.actions;
