import { Grid, Input, InputAdornment } from "@mui/material";
import { FC, useEffect, useState, UIEvent, useRef } from "react";
import {
  CompanyUserBriefModel,
  selectCurrentUser,
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "store";
import { UnallocatedCompanyModel } from "store/models/mediation/UnallocatedCompanyModel";
import {
  selectCompanies,
  selectCompaniesLoading,
  selectPaginatedUnallocatedCompaniesFilters,
  selectTotalPages,
} from "store/selectors/mediationSelectors";
import {
  setCurrentPageNumber,
  setPaginatedUnallocatedCompaniesFilters,
  setSelectedCompany,
} from "store/slices/mediationSlice";
import { getUnallocatedCompanies } from "store/thunks/mediationThunks";
import { CompanyCard } from "./card-company/CompanyCard";
import SearchIcon from "@mui/icons-material/Search";

import "./CompaniesList.scss";

export const CompaniesList: FC = () => {
  const [localSearchTextValue, setLocalSearchTextValue] = useState<string>("");

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const users = useAppSelector(selectCompanies);
  const loading = useAppSelector(selectCompaniesLoading);
  const debouncedSearchTerm = useDebounce(localSearchTextValue, 500);
  const filters = useAppSelector(selectPaginatedUnallocatedCompaniesFilters);
  const totalPages = useAppSelector(selectTotalPages);

  useEffect(() => {
    if (loading) return;

    dispatch(
      getUnallocatedCompanies({
        token: currentUser?.jwtToken,
        filters: {
          search: filters.search,
          pageNumber: filters.currentPageNumber!,
        },
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value ?? 0;
    });
    return ref.current;
  };
  const prevValue = usePrevious(filters.currentPageNumber);
  useEffect(() => {
    if (loading) return;

    dispatch(
      setPaginatedUnallocatedCompaniesFilters({
        search: debouncedSearchTerm,
        currentPageNumber: 0,
      })
    );

    //eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const showCompany = (user: CompanyUserBriefModel) => {
    dispatch(setSelectedCompany(user.userId));
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    getTenPercentScrollRemaining(e.currentTarget);
  };

  const getTenPercentScrollRemaining = (target: HTMLDivElement) => {
    var scrollAllowed = target.scrollHeight - target.clientHeight;
    var scrollLeft = scrollAllowed - target.scrollTop;
    var TenPercentScrollRemaining = (10 / 100) * scrollAllowed;

    if (totalPages === filters.currentPageNumber) return;

    if (scrollLeft <= TenPercentScrollRemaining && !loading) {
      dispatch(setCurrentPageNumber(prevValue! + 1));
    }
  };

  return (
    <Grid id="companies-list" item xs={12}>
      <Input
        className="search-input"
        placeholder="Caută"
        onChange={(e) => setLocalSearchTextValue(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Grid
        className="companies-list-container"
        onScroll={handleScroll}
        id="container"
      >
        {users?.map((user: UnallocatedCompanyModel, index) => {
          return (
            <div key={index} onClick={(e) => showCompany(user)}>
              <CompanyCard data={user}></CompanyCard>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};
