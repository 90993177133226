import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { ApproveGrantModel, CompanyUserBriefModel, PhaseDocumentsFilterModel, PhaseDocumentsPaginatedModel, UploadPhase1DocumentModel, UploadPhase2DocumentModel } from "store/models";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
const axios = require("axios").default;


export const toggleContest = createAsyncThunk<any,{generated:boolean,token:string|undefined},AppThunkConfig>("grantContest/toggleContest",
async ({token,generated}, thunkAPI)=>{
	try{
		let {data} = await axios.post(baseUrl + "GrantApplicationDocuments/ToggleContest",generated,{
			withCredentials:true,
			headers:{
				"Content-Type":"application/json",
				Authorization:`Bearer ${token}`
			}
		});
		thunkAPI.dispatch(
			addAppNotification({
				message:
					data?.message.text ??
					"Concurs plan de afaceri afisat.",
				severity: "success",
			})
		);
		return data.response;
	}
	catch(err:any){
			let errorMessage = getAxiosErrorMessage(err);
			if (errorMessage) {
				thunkAPI.dispatch(
					addAppNotification({
						message: errorMessage,
						severity: "error",
					})
				);
			return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
		} else {
			throw err;
		}
	}
}

)

export const requestPhase1Documents = createAsyncThunk<
  PhaseDocumentsPaginatedModel,
  { filter: PhaseDocumentsFilterModel; token?: string | null },
  AppThunkConfig
>(
  "grantContest/requestPhase1Documents",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: phaseDocumentFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/GetPhase1DocumentsPaginated",
        {
          params: {
            pageNumber: phaseDocumentFilter?.pageNumber,
            columnToSortBy: phaseDocumentFilter?.columnToSortBy,
            sortingOrder: phaseDocumentFilter?.sortingOrder,
            userId: phaseDocumentFilter.userId
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestCompanyUsersNames = createAsyncThunk<
  Array<CompanyUserBriefModel>,
  { filterByWonGrant?: boolean, filterByRegistrationNumber?: boolean, token?: string | null },
  AppThunkConfig
>(
  "grantContest/requestCompanyUsersNames",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filterByWonGrant, filterByRegistrationNumber, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "Users/GetCompanyUsersNames",
        {
          params: {
            filterByRegistrationNumber: filterByRegistrationNumber,
            filterByWonGrant: filterByWonGrant,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadGrantPhase1UserDocument = createAsyncThunk<
  any,
  { requestData: UploadPhase1DocumentModel, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/uploadGrantPhase1UserDocument",
  async (
    { requestData: data, token },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      data.files.forEach((file: string | Blob) => {
        form.append("Files", file);
      });
      form.append("companyUserId", data.companyUserId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/UploadPhase1Documents",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message ??
            "Document incarcat cu succes",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteGrantContestPhase1Doc = createAsyncThunk<
  any,
  { docId: number, token?: string },
  AppThunkConfig
>(
  "grantContest/deleteGrantContestPhase1Doc",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ docId, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("documentId", docId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/DeletePhase1Document",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message?.text ??
            "Documentul a fost sters",
          severity: "success",
        })
      );
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteAnexa2Phase2Document = createAsyncThunk<
  any,
  { docId: number, token?: string },
  AppThunkConfig
>(
  "grantContest/deleteAnexa2Phase2Document",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ docId, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("documentId", docId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/DeleteAnexa2Phase2Document",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message?.text ??
            "Documentul a fost sters",
          severity: "success",
        })
      );
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteAlteDocumentePhase2Document = createAsyncThunk<
  any,
  { docId: number, token?: string },
  AppThunkConfig
>(
  "grantContest/deleteAlteDocumentePhase2Document",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ docId, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("documentId", docId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/DeleteAlteDocumentePhase2Document",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message?.text ??
            "Documentul a fost sters",
          severity: "success",
        })
      );
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const downloadGrantContestPhase1Doc = createAsyncThunk<
  any,
  { fileId: number, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/downloadGrantContestPhase1Doc",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/DownloadPhase1Document",
        {
          params: {
            fileId: fileId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        },
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();

    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const downloadAnexa2Phase2Document = createAsyncThunk<
  any,
  { fileId: number, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/downloadAnexa2Phase2Document",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/DownloadAnexa2Phase2Document",
        {
          params: {
            fileId: fileId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        },
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();

    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const downloadAlteDocumentePhase2Document = createAsyncThunk<
  any,
  { fileId: number, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/downloadAlteDocumentePhase2Document",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/DownloadAlteDocumentePhase2Document",
        {
          params: {
            fileId: fileId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        },
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();

    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);


export const requestAnexa1Phase2Documents = createAsyncThunk<
  PhaseDocumentsPaginatedModel,
  { filter: PhaseDocumentsFilterModel; token?: string | null },
  AppThunkConfig
>(
  "grantContest/requestAnexa1Phase2Documents",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: phaseDocumentFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/GetAnexa1Phase2DocumentsPaginated",
        {
          params: {
            pageNumber: phaseDocumentFilter.pageNumber,
            columnToSortBy: phaseDocumentFilter.columnToSortBy,
            sortingOrder: phaseDocumentFilter.sortingOrder,
            userId: phaseDocumentFilter?.userId
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestAnexa2Phase2Documents = createAsyncThunk<
  PhaseDocumentsPaginatedModel,
  { filter: PhaseDocumentsFilterModel; token?: string | null },
  AppThunkConfig
>(
  "grantContest/requestAnexa2Phase2Documents",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: phaseDocumentFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/GetAnexa2Phase2DocumentsPaginated",
        {
          params: {
            pageNumber: phaseDocumentFilter.pageNumber,
            columnToSortBy: phaseDocumentFilter.columnToSortBy,
            sortingOrder: phaseDocumentFilter.sortingOrder,
            userId: phaseDocumentFilter.userId
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadAnexa2Phase2UserDocuments = createAsyncThunk<
  any,
  { requestData: UploadPhase2DocumentModel, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/uploadAnexa2Phase2UserDocuments",
  async (
    { requestData: data, token },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      data.files.forEach((file: string | Blob) => {
        form.append("Files", file);
      });
      form.append("companyUserId", data.companyUserId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/UploadAnexa2Phase2Documents",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message ??
            "Document incarcat cu succes",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestAlteDocumentePhase2Documents = createAsyncThunk<
  PhaseDocumentsPaginatedModel,
  { filter: PhaseDocumentsFilterModel; token?: string | null },
  AppThunkConfig
>(
  "grantContest/requestAlteDocumentePhase2Documents",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: phaseDocumentFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/GetAlteDocumentePhase2DocumentsPaginated",
        {
          params: {
            pageNumber: phaseDocumentFilter.pageNumber,
            columnToSortBy: phaseDocumentFilter.columnToSortBy,
            sortingOrder: phaseDocumentFilter.sortingOrder,
            userId: phaseDocumentFilter.userId
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const downloadAnexa1Phase2Doc = createAsyncThunk<
  any,
  { fileId: number, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/downloadAnexa1Phase2Doc",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GrantApplicationDocuments/DownloadAnexa1Phase2Document",
        {
          params: {
            fileId: fileId,
          },
          responseType: "blob",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        },
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();

    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteAnexa1Phase2Doc = createAsyncThunk<
  any,
  { docId: number, token?: string },
  AppThunkConfig
>(
  "grantContest/deleteAnexa1Phase2Doc",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ docId, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("documentId", docId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/DeleteAnexa1Phase2Document",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message?.text ??
            "Documentul a fost sters",
          severity: "success",
        })
      );
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadAnexa1Phase2UserDocument = createAsyncThunk<
  any,
  { requestData: UploadPhase2DocumentModel, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/uploadAnexa1Phase2UserDocument",
  async (
    { requestData: data, token },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      data.files.forEach((file: string | Blob) => {
        form.append("Files", file);
      });
      form.append("companyUserId", data.companyUserId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/UploadAnexa1Phase2Documents",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message ??
            "Document incarcat cu succes",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadAlteDocumentePhase2UserDocuments = createAsyncThunk<
  any,
  { requestData: UploadPhase2DocumentModel, token: string | undefined },
  AppThunkConfig
>(
  "grantContest/uploadAlteDocumentePhase2UserDocuments",
  async (
    { requestData: data, token },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      data.files.forEach((file: string | Blob) => {
        form.append("Files", file);
      });
      form.append("companyUserId", data.companyUserId.toString());
      let result = await axios.post(
        baseUrl + "GrantApplicationDocuments/UploadAlteDocumentePhase2Documents",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            result?.data?.message ??
            "Document incarcat cu succes",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const approveGrantForUser = createAsyncThunk<
  any,
  { approveGrantData: ApproveGrantModel, token: string | null | undefined },
  AppThunkConfig
>(
  "grantContest/approveGrantForUser",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ approveGrantData, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      let { data } = await axios.post(baseUrl + "Users/ApproveGrantForUser",
        approveGrantData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            data?.message?.text ??
            "Grant-ul a fost aprobat!",
          severity: "success",
        })
      );
      return data.response;
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);  