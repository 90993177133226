import { Minority } from "../enums/Minority";

export const minoritySelectItems = [
  {
    value: Minority.YesRoms,
    label: "Da - Etnie romă",
  },
  {
    value: Minority.YesOther,
    label: "Da - Altă minoritate decât cea romă",
  },
  {
    value: Minority.No,
    label: "Nu",
  },
  {
    value: Minority.DoNotDeclare,
    label: "Nu doresc să declar",
  },
];
