export const annexesSelectItems = [
  {
    label: "Anexa 8",
    value: 8,
  },
  {
    label: "Anexa 11",
    value: 11,
  },
  {
    label: "Anexa 12",
    value: 12,
  },
  {
    label: "Anexa 13",
    value: 13,
  },
  {
    label: "Anexa 14",
    value: 14,
  },
  {
    label: "Anexa 15",
    value: 15,
  },
  {
    label: "Anexa 16",
    value: 16,
  },

]