import { Grid, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { EvaluationPageButtons } from "components/shared/buttonGroups";
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { FC, useEffect, useState } from 'react';
import { getScoresForPhase2Final, selectCurrentUser, selectGrantContestPageLocalization, selectScoresPhase2Final, selectScoresPhase2FinalCount, selectScoresPhase2FinalIsLoading, useAppDispatch, useAppSelector } from 'store';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { PhaseNumber } from "store/models/enums/PhaseNumber";
import { UserType } from 'store/models/enums/UserType';
import { setScoresPhase2FinalSortModel } from 'store/slices/grantEvaluationSlice';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';

export const GrantRankingFinalPhase2: FC<{ stage: number }> = ({ stage }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const scoresPhase2Final = useAppSelector(selectScoresPhase2Final);
  const scoresPhase2FinalCount = useAppSelector(selectScoresPhase2FinalCount);
  const scoresPhase2FinalIsLoading = useAppSelector(selectScoresPhase2FinalIsLoading);
  const localization = useAppSelector(selectGrantContestPageLocalization);

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const handleGetScores = (pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean, token: string | undefined) => {
    dispatch(
      getScoresForPhase2Final({
        filters: {
          pageNumber: pageNumber,
          columnToSortBy: columnToSortBy,
          sortingOrder: sortingOrder,
        },
        token: token,
      })
    );
  };

  const columns: GridColDef[] = [
    { field: "uniqueId", headerName: localization.phaseRank2Tab.seriesCol, flex: 1, minWidth: 150 },
    {
      field: "commentPhase2FirstGrid", headerName: localization.phaseRank2Tab.firstGridObsCol, flex: 1, sortable: false, minWidth: 150,
      renderCell: (params: any) => (
        <Tooltip title={params.row.commentPhase2FirstGrid ? params.row.commentPhase2FirstGrid : "N/A"} >
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.commentPhase2FirstGrid ? params.row.commentPhase2FirstGrid : "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "commentPhase2SecondGrid", headerName: localization.phaseRank2Tab.secondGridObsCol, flex: 1, sortable: false, minWidth: 150,
      renderCell: (params: any) => (
        <Tooltip title={params.row.commentPhase2SecondGrid ? params.row.commentPhase2SecondGrid : "N/A"} >
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{params.row.commentPhase2SecondGrid ? params.row.commentPhase2SecondGrid : "N/A"}</span>
        </Tooltip>
      )
    },
    {
      field: "score", headerName: localization.phaseRank2Tab.scoreCol, flex: 1, minWidth: 85,
      renderCell: (params: any) => {
        return (
          <span>{params.row.score && params.row.score > 0 ? params.row.score : "N/A"}</span>
        );
      }
    },
    {
      field: "", headerName: localization.phaseRank2Tab.optionsCol, sortable: false, align: "right", minWidth: 150, hide: currentUser?.userType === UserType.COMPANY_USER,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <EvaluationPageButtons appealBtnDisabled={!params.row.hasAppeal} btnSize="small" userId={params.row.userId} phaseNumber={PhaseNumber.Phase2} stage={stage} evaluationBtnDisabled={params.row.grantEvaluationId === 0} />
        )
      }
    }
  ]
  useEffect(() => {
    handleGetScores(currentPageNumber, DataGridColumnNames.CreatedAt, false, currentUser?.jwtToken);
    //eslint-disable-next-line
  }, [currentPageNumber])


  const handlePageChange = (pageNumber: any) => {
    setCurrentPageNumber(pageNumber);
  }

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0) {
      dispatch(setScoresPhase2FinalSortModel({
        columnToSortBy: newModel[0].field,
        sortingOrder: newModel[0].sort
      }));
      let column = mapColumnNameToEnum(newModel[0].field);
      let sort = false;
      if (newModel[0].sort === "asc") sort = true;
      handleGetScores(currentPageNumber, column, sort, currentUser?.jwtToken);

    } else {
      dispatch(setScoresPhase2FinalSortModel({
        columnToSortBy: "createdAt",
        sortingOrder: "desc"
      }));
      handleGetScores(currentPageNumber, DataGridColumnNames.CreatedAt, false, currentUser?.jwtToken);

    }
  };


  return (<Grid paddingTop={2}>
    <DataGrid
      components={{
        LoadingOverlay: DataLoadingComponent
      }}
      loading={scoresPhase2FinalIsLoading}
      rows={scoresPhase2Final}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      sx={{ flexGrow: 1, height: 630 }}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      disableColumnMenu={true}
      paginationMode="server"
      onPageChange={handlePageChange}
      page={currentPageNumber}
      rowCount={scoresPhase2FinalCount}
    ></DataGrid>

  </Grid>
  );
}