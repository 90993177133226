import { Search } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ItemsSelector } from "components/shared/itemsSelector/ItemsSelector";

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "store";
import { ApplicationStatus } from "store/models/enums/ApplicationStatus";

import { applicationStatusItems } from "store/models/selectItems/applicationStatusItems";
import {
  selectApplicationsFilters,
  selectEmployersNames,
  selectJobsWithApplicationsItems,
  selectLoadingJobsWithAllocations,
} from "store/selectors/mediationSelectors";
import { setApplicationsFilters } from "store/slices/mediationSlice";
import { getJobsWithApplications } from "store/thunks/mediationThunks";
import { isAdmin, isEmployer, isExpert } from "utils/getUserType";

import "./ApplicationFilters.scss";

export const ApplicationsFilter = () => {
  const filters = useAppSelector(selectApplicationsFilters);
  const [search, setSearch] = useState<string>(filters.searchText!);
  const [status, setStatus] = useState<string[]>(["Interesat"]);
  const [jobSearch, setJobSearch] = useState<string>("");
  const loadingJobTitles = useAppSelector(selectLoadingJobsWithAllocations);
  const debouncedValue = useDebounce(search, 500);
  const debouncedJobSearch = useDebounce(jobSearch, 500);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const { id } = useParams();
  const jobsWithApplicationsItems = useAppSelector(
    selectJobsWithApplicationsItems
  );
  const ro = useAppSelector(selectMediationPageLocalization);
  const employers = useAppSelector(selectEmployersNames);
  const handleSelectJobStatus = (event: SelectChangeEvent<typeof status>) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (debouncedValue !== filters.searchText) {
      dispatch(
        setApplicationsFilters({
          ...filters,
          searchText: debouncedValue,
        })
      );
    }

    //eslint-disable-next-line
  }, [debouncedValue]);

  useEffect(() => {
    if (debouncedJobSearch.length < 3) return;
    else
      dispatch(
        getJobsWithApplications({
          token: currentUser?.jwtToken,
          employerId: Number(id),
          jobTitle: debouncedJobSearch,
        })
      );

    //eslint-disable-next-line
  }, [debouncedJobSearch]);

  useEffect(() => {
    let statusArray: number[] = [];
    status?.forEach((stat: string) => {
      statusArray.push(
        applicationStatusItems?.find((item) => item.field === stat)?.value!
      );
    });
    dispatch(
      setApplicationsFilters({
        ...filters,
        applicationStatus:
          statusArray.length > 0
            ? statusArray!
            : [ApplicationStatus.Interested],
      })
    );

    //eslint-disable-next-line
  }, [status]);

  const handleSelectJob = (jobId: string) => {
    if (jobSearch.length < 3) return;
    if (isEmployer(currentUser?.userType)) {
      dispatch(
        setApplicationsFilters({
          ...filters,
          employerId: currentUser?.userId,
          jobId: jobId,
        })
      );
    } else {
      dispatch(
        setApplicationsFilters({
          ...filters,
          jobId: jobId,
        })
      );
    }
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleJobSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setJobSearch(e.target.value);
    if (e.target.value.length === 0) {
      dispatch(
        setApplicationsFilters({
          ...filters,
          searchText: "",
          jobId: null,
        })
      );
    }
  };

  const setEmployer = (e: any) => {
    dispatch(
      setApplicationsFilters({
        ...filters,
        pageNumber: 0,
        employerId: e.target.value,
      })
    );
  };

  const setEmployerNull = () => {
    dispatch(
      setApplicationsFilters({
        ...filters,
        pageNumber: 0,
        employerId: undefined,
      })
    );
  };

  return (
    <Container id="application-filters">
      <Grid
        className="filters"
        item
        xs={12}
        container
        columnSpacing={4}
        rowSpacing={4}
      >
        <Grid item xs={12} md={2} className="filter-item">
          <TextField
            label="Caută după nume"
            value={search}
            fullWidth
            onChange={handleSearchChange}
            variant="standard"
            size="small"
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Grid>

        <Grid item xs={12} md={2} className="filter-item autocomplete-filter">
          <TextField
            autoComplete="off"
            label="Caută după job"
            value={jobSearch || ""}
            fullWidth
            onChange={(e) => handleJobSearch(e)}
            variant="standard"
            size="small"
            InputProps={{
              endAdornment: loadingJobTitles ? (
                <CircularProgress size={20} />
              ) : (
                <Search />
              ),
            }}
          />
          {jobSearch.length >= 3 && (
            <Paper className="options-paper" elevation={2}>
              <Box className="labels">
                <Typography variant="caption">Companie</Typography>
                <Typography variant="caption">Job</Typography>
              </Box>
              {jobsWithApplicationsItems.length > 0
                ? jobsWithApplicationsItems?.map((job: any) => {
                    return (
                      <Box
                        className="each-job"
                        onClick={() => {
                          handleSelectJob(job.value);
                          setJobSearch(job.label);
                        }}
                      >
                        <Box className="company-name">
                          <Tooltip title={job.employerName}>
                            <div>{job.employerName}</div>
                          </Tooltip>
                        </Box>
                        <span className="divider"></span>
                        <Tooltip title={job.label}>
                          <Box className="job-name">
                            <div>{job.label}</div>
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })
                : !loadingJobTitles && (
                    <Box className="no-jobs">{ro.noJobsFound}</Box>
                  )}
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          <ItemsSelector
            label={ro.employer}
            value={filters.employerId}
            items={employers?.map((employer) => {
              return { label: employer.name, value: employer.employerId };
            })}
            setValue={setEmployer}
            removeFilter={setEmployerNull}
            removeFilterDeleteIcon={
              filters.employerId === undefined || filters.employerId === 0
            }
          />
        </Grid>

        {(isAdmin(currentUser?.userType) ||
          isExpert(currentUser?.userType)) && (
          <Grid item xs={12} md={2} className="filter-item status-job">
            <FormControl variant="standard" fullWidth>
              <InputLabel
                id="demo-multiple-checkbox-label"
                htmlFor="uncontrolled-native"
              >
                Status Job
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                label="Status Job"
                multiple
                fullWidth
                value={status || ""}
                onChange={handleSelectJobStatus}
                renderValue={(selected) => selected.join(", ")}
              >
                {applicationStatusItems?.map((stat) => (
                  <MenuItem key={stat.value} value={stat.field}>
                    <Checkbox checked={status.includes(stat.field)} />
                    <ListItemText primary={stat.field} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
