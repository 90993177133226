import { CloseRounded, Search } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "store";
import {
  selectAllocationsFilters,
  selectEmployersNames,
  selectJobsWithApplicationsItems,
  selectLoadingJobsWithAllocations,
} from "store/selectors/mediationSelectors";
import { setAllocationsFilters } from "store/slices/mediationSlice";
import { getDateLabel, onlyDateFormat } from "utils";
import locale from "date-fns/locale/ro";

import { getJobsWithApplications } from "store/thunks/mediationThunks";
import { useParams } from "react-router";
import { ItemsSelector } from "components/shared/itemsSelector/ItemsSelector";

export const AllocationsFilters: FC = () => {
  const filters = useAppSelector(selectAllocationsFilters);
  const [search, setSearch] = useState<string>(filters.searchText!);
  const [jobSearch, setJobSearch] = useState<string>("");
  const debouncedValue = useDebounce(search, 500);
  const debouncedJobSearch = useDebounce(jobSearch, 500);
  const dispatch = useAppDispatch();
  const ro = useAppSelector(selectMediationPageLocalization);
  const jobs = useAppSelector(selectJobsWithApplicationsItems);
  const loading = useAppSelector(selectLoadingJobsWithAllocations);
  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };
  const { id } = useParams();
  const employers = useAppSelector(selectEmployersNames);
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (debouncedValue !== filters.searchText) {
      dispatch(
        setAllocationsFilters({
          ...filters,
          searchText: debouncedValue,
        })
      );
    }

    //eslint-disable-next-line
  }, [debouncedValue]);

  useEffect(() => {
    if (debouncedJobSearch.length < 3) return;
    else
      dispatch(
        getJobsWithApplications({
          token: currentUser?.jwtToken,
          employerId: Number(id),
          jobTitle: debouncedJobSearch,
        })
      );

    //eslint-disable-next-line
  }, [debouncedJobSearch]);

  const setStartTimeFilter = (date: Date | null) => {
    date?.setHours(0);
    date?.setMinutes(0);
    dispatch(
      setAllocationsFilters({
        ...filters,
        startDate: date ? date.toISOString() : undefined,
      })
    );
  };

  const setEndTimeFilter = (date: Date | null) => {
    date?.setHours(23);
    date?.setMinutes(59);
    dispatch(
      setAllocationsFilters({
        ...filters,
        endDate: date ? date.toISOString() : undefined,
      })
    );
  };

  const getDateOnChange = (e: Date | null | any, start: boolean) => {
    var dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    const date = getDateLabel(e, onlyDateFormat);
    const isValid = dateRegex.test(date);

    if (!isValid) {
      return;
    } else {
      start ? setStartTimeFilter(e) : setEndTimeFilter(e);
    }
  };

  const setJobValue = (jobId: number) => {
    dispatch(
      setAllocationsFilters({
        ...filters,
        jobPostId: jobId,
      })
    );
  };

  const handleJobSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setJobSearch(e.target.value);
    if (e.target.value.length === 0) {
      dispatch(
        setAllocationsFilters({
          ...filters,
          searchText: "",
          jobPostId: undefined,
        })
      );
    }
  };

  const setEmployer = (e: any) => {
    dispatch(
      setAllocationsFilters({
        ...filters,
        pageNumber: 0,
        employerId: e.target.value,
      })
    );
  };

  const setEmployerNull = () => {
    dispatch(
      setAllocationsFilters({
        ...filters,
        pageNumber: 0,
        employerId: undefined,
      })
    );
  };

  return (
    <Grid container id="allocations-filters" justifyContent="start">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Grid
          className="filters"
          item
          xs={12}
          container
          columnSpacing={4}
          rowSpacing={4}
        >
          <Grid item xs={12} md={2.4}>
            <TextField
              label={ro.search}
              value={search}
              fullWidth
              onChange={handleSearchChange}
              variant="standard"
              size="small"
              InputProps={{
                endAdornment: <Search />,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={2.4}
            className="filter-item autocomplete-filter"
          >
            <TextField
              autoComplete="off"
              label="Caută după job"
              value={jobSearch || ""}
              fullWidth
              onChange={(e) => handleJobSearch(e)}
              variant="standard"
              size="small"
              InputProps={{
                endAdornment: loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Search />
                ),
              }}
            />
            {jobSearch.length >= 3 && (
              <Paper className="options-paper" elevation={2}>
                <Box className="labels">
                  <Typography variant="caption">Companie</Typography>
                  <Typography variant="caption">Job</Typography>
                </Box>
                {jobs.length > 0
                  ? jobs?.map((job: any) => {
                      return (
                        <Box
                          className="each-job"
                          onClick={() => {
                            setJobValue(job.value);
                            setJobSearch(job.label);
                          }}
                        >
                          <Tooltip title={job.employerName}>
                            <Box className="company-name">
                              <div>{job.employerName}</div>
                            </Box>
                          </Tooltip>
                          <span className="divider"></span>
                          <Tooltip title={job.label}>
                            <Box className="job-name">
                              <div>{job.label}</div>
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    })
                  : !loading && <Box className="no-jobs">{ro.noJobsFound}</Box>}
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={2.4}>
            <ItemsSelector
              label={ro.employer}
              value={filters.employerId}
              items={employers?.map((employer) => {
                return { label: employer.name, value: employer.employerId };
              })}
              setValue={setEmployer}
              removeFilter={setEmployerNull}
              removeFilterDeleteIcon={
                filters.employerId === undefined || filters.employerId === 0
              }
            />
          </Grid>
          <Grid item xs={12} md={2.4}>
            <DatePicker
              value={filters.startDate ?? null}
              onChange={(e) => getDateOnChange(e, true)}
              onAccept={setStartTimeFilter}
              mask="__.__.____"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        {filters.startDate && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setStartTimeFilter(null);
                            }}
                          >
                            <CloseRounded color="error"></CloseRounded>
                          </IconButton>
                        )}
                        {params.InputProps?.endAdornment}
                      </Grid>
                    ),
                  }}
                  onChange={(e) => getDateOnChange(e, true)}
                  label="După data de"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2.4}>
            <DatePicker
              value={filters.endDate ?? null}
              onChange={(e) => getDateOnChange(e, false)}
              onAccept={setEndTimeFilter}
              mask="__.__.____"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        {filters.endDate && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setEndTimeFilter(null);
                            }}
                          >
                            <CloseRounded color="error"></CloseRounded>
                          </IconButton>
                        )}
                        {params.InputProps?.endAdornment}
                      </Grid>
                    ),
                  }}
                  onChange={(e) => getDateOnChange(e, false)}
                  label="Înainte de"
                  variant="standard"
                />
              )}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};
