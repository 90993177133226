import { FormResponse } from "../enums/FormResponse";

export const formResponseSelectItems = [
  {
    value: FormResponse.Yes,
    label: "Da",
  },
  {
    value: FormResponse.No,
    label: "Nu",
  },
  {
    value: FormResponse.DoNotDeclare,
    label: "Nu doresc să declar",
  },
];
