import {
  Close,
  EmailOutlined,
  LocationOnOutlined,
  PersonOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import ConfirmationDialog from "components/shared/confirmationDialog/ConfirmationDialog";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  requestUserDetails,
  selectCurrentUser,
  selectUserDetails,
  selectUserDetailsIsLoading,
  useAppDispatch,
  useAppSelector,
} from "store";
import {
  selectJobDetails,
  selectSelectedCompany,
} from "store/selectors/mediationSelectors";
import { removeCompanyFromUnallocated, setSelectedCompany } from "store/slices/mediationSlice";
import {
  allocateJobPost,
  getJobPost,
} from "store/thunks/mediationThunks";
import "./CompanyDetails.scss";

export const CompanyDetails: FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const companyId = useAppSelector(selectSelectedCompany);
  const userDetails = useAppSelector(selectUserDetails);
  const jobDetails = useAppSelector(selectJobDetails);
  const navigate = useNavigate();
  const userDetailsLoading = useAppSelector(selectUserDetailsIsLoading);
  const [displayAllocateDialog, setDisplayAllocatDialog] =
    useState<boolean>(false);


  useEffect(() => {
    if (companyId) {
      dispatch(
        requestUserDetails({
          userId: companyId,
          token: currentUser?.jwtToken,
        })
      );
    }
    //eslint-disable-next-line
  }, [companyId]);

  const closeCompanyDetails = () => {
    dispatch(setSelectedCompany(undefined));
  };

  const goToCompanyDetails = () => {
    navigate("/utilizator/" + companyId);
  };

  const handleAllocate = () => {
    setDisplayAllocatDialog(true);
  };

  const closeAllocateDialog = () => {
    setDisplayAllocatDialog(false);
  };

  const confirmAllocate = () => {
    dispatch(
      allocateJobPost({
        model: {
          jobPostId: jobDetails?.jobPostId,
          companyUserId: companyId,
          allocatedByUserId: currentUser?.userId,
        },
        token: currentUser?.jwtToken,
      })
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(removeCompanyFromUnallocated(companyId))
        dispatch(
          getJobPost({
            token: currentUser?.jwtToken,
            jobPostId: jobDetails?.jobPostId!,
          })
        );
      }

      setDisplayAllocatDialog(false);
      closeCompanyDetails();
    });
  };
  return (
    <Grid id="company-details">
      <Card className="company-details-container">
        <Grid item xs={12} container className="card-title">
          <CardHeader title="Detalii beneficiar"></CardHeader>
          <IconButton
            color="error"
            className="close-icon"
            onClick={closeCompanyDetails}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item xs={12} className="card-content">
          <Grid item xs={12} className="detail">
            <PersonOutlined className="icon" />
            {userDetailsLoading ? (
              <Skeleton width="50%" height="30px" />
            ) : (
              <Typography variant="h5">
                {userDetails.lastName + " " + userDetails.firstName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className="details-container" container>
            <Grid item xs={12} lg={4} className="detail">
              <EmailOutlined className="icon" />
              {userDetailsLoading ? (
                <Skeleton width="80%" />
              ) : (
                <Typography variant="body1">{userDetails.email}</Typography>
              )}
            </Grid>
            <Grid item xs={12} lg={4} className="detail">
              <PhoneOutlined className="icon" />
              {userDetailsLoading ? (
                <Skeleton width="80%" />
              ) : (
                <Typography variant="body1">
                  {userDetails.phoneNumber}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} lg={4} className="detail">
              <LocationOnOutlined className="icon" />
              {userDetailsLoading ? (
                <Skeleton width="80%" />
              ) : (
                <Typography variant="body1">{userDetails.adresa}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="actions-container">
          <Button
            variant="contained"
            onClick={goToCompanyDetails}
            className="view-details"
          >
            Vezi detalii
          </Button>
          <Button
            variant="contained"
            onClick={handleAllocate}
            disabled={jobDetails?.allocated}
            className="allocate-button"
          >
            Alocă
          </Button>
        </Grid>
      </Card>
      <React.Fragment>
        {displayAllocateDialog && (
          <ConfirmationDialog
            display={displayAllocateDialog}
            cancelAction={closeAllocateDialog}
            confirmAction={confirmAllocate}
            message="Confirmarea acțiunii va duce la alocarea beneficiarului la jobul selectat."
          />
        )}
      </React.Fragment>
    </Grid>
  );
};
