import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useAppSelector, selectCurrentUser } from "store";
import { UserType } from "store/models/enums/UserType";
export const RejectDialog: FC<{ display: boolean, handleClose: Function, handleReject: Function, handleUnreject: Function, data: any }> = ({ display, handleClose, handleReject, handleUnreject, data }) => {
  const [rejectionComment, setRejectionComment] = useState<string>("");
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (data.rejectedComment !== null && data.rejectedComment !== undefined && data.rejectedComment !== "undefined") {
      setRejectionComment(data.rejectedComment);
    }
    //eslint-disable-next-line
  }, [])

  return (
    <Dialog
      open={display}
      onClose={() => handleClose()}
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        Justificare respingere document
        <IconButton onClick={() => handleClose()}><Close /></IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex" }}>
        <TextField disabled={currentUser?.userType === UserType.COMPANY_USER} rows={6} value={rejectionComment ?? ""} multiline sx={{ width: "100%", resize: "none" }} id="outlined-basic" variant="outlined" placeholder="Mesaj" onChange={(e) => setRejectionComment(e.target.value)} />
      </DialogContent>
      <DialogActions>
        {data.isRejected && currentUser?.userType !== UserType.COMPANY_USER && <Button variant="contained" size="small" color="warning" autoFocus onClick={() => handleUnreject(data)}>
          Anulează respingerea
        </Button>}
        <Button variant="contained" autoFocus size="small" onClick={() => handleClose()}>
          Închide
        </Button>
        <Button variant="contained" color="success" size="small" onClick={() => handleReject({ comment: rejectionComment })} autoFocus>
          Salvează
        </Button>
      </DialogActions>
    </Dialog>
  )

}