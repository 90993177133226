import { EmploymentMarketSituation } from "../enums/EmploymentMarketSituation";

export const employmentMarketSituationItems = [
  { value: EmploymentMarketSituation.Somer, label: "Șomer" },
  { value: EmploymentMarketSituation.Angajat, label: "Angajat" },
  {
    value: EmploymentMarketSituation.InactivePerson,
    label:
      "Persoană inactivă inclusiv copii antepreșcolari, preșcolari, elevi, etc",
  },
];
