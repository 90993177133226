import { CloseOutlined, CloseRounded } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Dialog, Grid, IconButton, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import locale from "date-fns/locale/ro";
import "./GenerateJobsArchiveDialog.scss";
import { selectCurrentUser, selectMediationPageLocalization, useAppDispatch, useAppSelector } from "store";
import { generateCountiesJobsArchive } from "store/thunks/mediationThunks";

export const GenerateJobsArchiveDialog: FC<{ display: boolean, close: Function }> = ({ display, close }) => {


  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const ro = useAppSelector(selectMediationPageLocalization);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const setStartTime = (date: Date | null) => {
    setStartDate(date);
  }

  const setEndTime = (date: Date | null) => {
    setEndDate(date);
  }

  const doNothing = () => {

  }
  const generateArchive = () => {
    dispatch(generateCountiesJobsArchive({
      token: currentUser?.jwtToken,
      periodStart: startDate?.toISOString() ?? null,
      periodEnd: endDate?.toISOString() ?? null
    }))
    close();
  }

  return (
    <Dialog open={display} maxWidth="sm" fullWidth id="generate-jobs-archive-dialog">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Grid container className="dialog-container" rowGap={3}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className="dialog-title">
              Generează arhivă posturi vacante
            </Typography>
            <IconButton color="error" onClick={() => close()}>
              <CloseOutlined />
            </IconButton>
          </Grid>
          <Grid container columnSpacing={3} justifyContent="center" >
            <Grid item xs={12} sm={4}>
              <DatePicker
                value={startDate ?? null}
                onChange={doNothing}
                maxDate={
                  endDate
                    ? endDate
                    : undefined
                }
                onAccept={setStartTime}
                mask="__.__.____"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {startDate && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setStartTime(null)
                              }}
                            >
                              <CloseRounded color="error"></CloseRounded>
                            </IconButton>
                          )}
                          {params.InputProps?.endAdornment}
                        </Grid>
                      ),
                    }}
                    label={ro.archiveStartLabel}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                value={endDate ?? null}
                onChange={doNothing}
                minDate={
                  startDate
                    ? startDate
                    : undefined
                }
                onAccept={setEndTime}
                mask="__.__.____"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {endDate && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setEndTime(null)
                              }}
                            >
                              <CloseRounded color="error"></CloseRounded>
                            </IconButton>
                          )}
                          {params.InputProps?.endAdornment}
                        </Grid>
                      ),
                    }}
                    label={ro.archiveEndLabel}
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" columnSpacing={3}>
            <Grid item>
              <Button variant="contained" color="error" onClick={(e) => close()}>
                {ro.cancelBtn}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="success" onClick={generateArchive} >
                {ro.generateBtn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Dialog>
  )
}