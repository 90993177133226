import { ApplicationStatus } from "../enums/ApplicationStatus";

export const applicationStatusItems = [
  { field: "Interesat", value: ApplicationStatus.Interested },
  { field: "Neinteresat", value: ApplicationStatus.Uninterested },
  { field: "Alocat", value: ApplicationStatus.Allocated },
  {
    field: "Job indisponibil",
    value: ApplicationStatus.JobNotAvailable,
  },
  {
    field: "Alocat altui job",
    value: ApplicationStatus.AllocatedToAnotherJob,
  },
];
