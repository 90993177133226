export const BistritaSelectItemsLabel = [
  { value: "AGRIEȘ", label: "AGRIEȘ" },
  { value: "AGRIEȘEL", label: "AGRIEȘEL" },
  { value: "AGRIȘU DE JOS", label: "AGRIȘU DE JOS" },
  { value: "AGRIȘU DE SUS", label: "AGRIȘU DE SUS" },
  { value: "ALBEȘTII BISTRIȚEI", label: "ALBEȘTII BISTRIȚEI" },
  { value: "ALUNIȘUL", label: "ALUNIȘUL" },
  { value: "ANIEȘ", label: "ANIEȘ" },
  { value: "APATIU", label: "APATIU" },
  { value: "ARCALIA", label: "ARCALIA" },
  { value: "ARCHIUD", label: "ARCHIUD" },
  { value: "ARDAN", label: "ARDAN" },
  { value: "ARȘIȚA", label: "ARȘIȚA" },
  { value: "BÂRLA", label: "BÂRLA" },
  { value: "BAȚA", label: "BAȚA" },
  { value: "BECLEAN", label: "BECLEAN" },
  { value: "BEUDIU", label: "BEUDIU" },
  { value: "BICHIGIU", label: "BICHIGIU" },
  { value: "BIDIU", label: "BIDIU" },
  { value: "BISTRIȚA", label: "BISTRIȚA" },
  { value: "BISTRIȚA BÂRGĂULUI", label: "BISTRIȚA BÂRGĂULUI" },
  { value: "BLĂJENII DE JOS", label: "BLĂJENII DE JOS" },
  { value: "BLĂJENII DE SUS", label: "BLĂJENII DE SUS" },
  { value: "BORLEASA", label: "BORLEASA" },
  { value: "BOZIEȘ", label: "BOZIEȘ" },
  { value: "BRANIȘTEA", label: "BRANIȘTEA" },
  { value: "BRĂTENI", label: "BRĂTENI" },
  { value: "BREAZA", label: "BREAZA" },
  { value: "BRETEA", label: "BRETEA" },
  { value: "BUDACU DE JOS", label: "BUDACU DE JOS" },
  { value: "BUDACU DE SUS", label: "BUDACU DE SUS" },
  { value: "BUDEȘTI", label: "BUDEȘTI" },
  { value: "BUDEȘTI-FÂNAȚE", label: "BUDEȘTI-FÂNAȚE" },
  { value: "BUDURLENI", label: "BUDURLENI" },
  { value: "BUDUȘ", label: "BUDUȘ" },
  { value: "BUNGARD", label: "BUNGARD" },
  { value: "BUZA CĂTUN", label: "BUZA CĂTUN" },
  { value: "CĂIANU MARE", label: "CĂIANU MARE" },
  { value: "CĂIANU MIC", label: "CĂIANU MIC" },
  { value: "CAILA", label: "CAILA" },
  { value: "CÂMP", label: "CÂMP" },
  { value: "CEPARI", label: "CEPARI" },
  { value: "CHEȚIU", label: "CHEȚIU" },
  { value: "CHINTELNIC", label: "CHINTELNIC" },
  { value: "CHIOCHIȘ", label: "CHIOCHIȘ" },
  { value: "CHIRALEȘ", label: "CHIRALEȘ" },
  { value: "CHIUZA", label: "CHIUZA" },
  { value: "CICEU-CORABIA", label: "CICEU-CORABIA" },
  { value: "CICEU-GIURGEȘTI", label: "CICEU-GIURGEȘTI" },
  { value: "CICEU-MIHĂIEȘTI", label: "CICEU-MIHĂIEȘTI" },
  { value: "CICEU-POIENI", label: "CICEU-POIENI" },
  { value: "CIOSA", label: "CIOSA" },
  { value: "CIREAȘI", label: "CIREAȘI" },
  { value: "CIREȘOAIA", label: "CIREȘOAIA" },
  { value: "COASTA", label: "COASTA" },
  { value: "COCIU", label: "COCIU" },
  { value: "COLDĂU", label: "COLDĂU" },
  { value: "COLIBIȚA", label: "COLIBIȚA" },
  { value: "COMLOD", label: "COMLOD" },
  { value: "CORMAIA", label: "CORMAIA" },
  { value: "CORVINEȘTI", label: "CORVINEȘTI" },
  { value: "COȘBUC", label: "COȘBUC" },
  { value: "COȘERIU", label: "COȘERIU" },
  { value: "CRAINIMĂT", label: "CRAINIMĂT" },
  { value: "CRISTEȘTII CICEULUI", label: "CRISTEȘTII CICEULUI" },
  { value: "CRISTUR-ȘIEU", label: "CRISTUR-ȘIEU" },
  { value: "CUȘMA", label: "CUȘMA" },
  { value: "DEALU ȘTEFĂNIȚEI", label: "DEALU ȘTEFĂNIȚEI" },
  { value: "DELURENI", label: "DELURENI" },
  { value: "DIPȘA", label: "DIPȘA" },
  { value: "DOBRIC", label: "DOBRIC" },
  { value: "DOBRICEL", label: "DOBRICEL" },
  { value: "DOMNEȘTI", label: "DOMNEȘTI" },
  { value: "DOROLEA", label: "DOROLEA" },
  { value: "DRAGA", label: "DRAGA" },
  { value: "DUMBRAVA / LIVEZILE", label: "DUMBRAVA / LIVEZILE" },
  { value: "DUMBRAVA / NUȘENI", label: "DUMBRAVA / NUȘENI" },
  { value: "DUMBRĂVENI", label: "DUMBRĂVENI" },
  { value: "DUMBRĂVIȚA", label: "DUMBRĂVIȚA" },
  { value: "DUMITRA", label: "DUMITRA" },
  { value: "DUMITRIȚA", label: "DUMITRIȚA" },
  { value: "DUPA DEAL", label: "DUPA DEAL" },
  { value: "ENCIU", label: "ENCIU" },
  { value: "FÂNAȚE", label: "FÂNAȚE" },
  { value: "FÂNAȚELE SILIVAȘULUI", label: "FÂNAȚELE SILIVAȘULUI" },
  { value: "FÂNTÂNELE", label: "FÂNTÂNELE" },
  { value: "FÂNTÂNIȚA", label: "FÂNTÂNIȚA" },
  { value: "FELDRU", label: "FELDRU" },
  { value: "FELEAC", label: "FELEAC" },
  { value: "FIAD", label: "FIAD" },
  { value: "FIGA", label: "FIGA" },
  { value: "FLOREȘTI", label: "FLOREȘTI" },
  { value: "GALAȚII BISTRIȚEI", label: "GALAȚII BISTRIȚEI" },
  { value: "GERSA I", label: "GERSA I" },
  { value: "GERSA II", label: "GERSA II" },
  { value: "GHEMEȘ", label: "GHEMEȘ" },
  { value: "GHINDA", label: "GHINDA" },
  { value: "GLEDIN", label: "GLEDIN" },
  { value: "HĂLMĂSĂU", label: "HĂLMĂSĂU" },
  { value: "HĂȘMAȘU CICEULUI", label: "HĂȘMAȘU CICEULUI" },
  { value: "HERINA", label: "HERINA" },
  { value: "HIREAN", label: "HIREAN" },
  { value: "ILIȘUA", label: "ILIȘUA" },
  { value: "ILVA MARE", label: "ILVA MARE" },
  { value: "ILVA MICA", label: "ILVA MICA" },
  { value: "IVĂNEASA", label: "IVĂNEASA" },
  { value: "JEICA", label: "JEICA" },
  { value: "JELNA", label: "JELNA" },
  { value: "JIMBOR", label: "JIMBOR" },
  { value: "JOSENII BÂRGĂULUI", label: "JOSENII BÂRGĂULUI" },
  { value: "LA CURTE", label: "LA CURTE" },
  { value: "LECHINȚA", label: "LECHINȚA" },
  { value: "LELEȘTI", label: "LELEȘTI" },
  { value: "LEȘU", label: "LEȘU" },
  { value: "LIVEZILE", label: "LIVEZILE" },
  { value: "LIVIU REBREANU", label: "LIVIU REBREANU" },
  { value: "LUNCA", label: "LUNCA" },
  { value: "LUNCA BORLESEI", label: "LUNCA BORLESEI" },
  { value: "LUNCA ILVEI", label: "LUNCA ILVEI" },
  { value: "LUNCA LEȘULUI", label: "LUNCA LEȘULUI" },
  { value: "LUNCA SĂTEASCA", label: "LUNCA SĂTEASCA" },
  { value: "LUȘCA", label: "LUȘCA" },
  { value: "MĂGURA ILVEI", label: "MĂGURA ILVEI" },
  { value: "MĂGURELE", label: "MĂGURELE" },
  { value: "MAIERU", label: "MAIERU" },
  { value: "MALIN", label: "MALIN" },
  { value: "MĂLUȚ", label: "MĂLUȚ" },
  { value: "MANIC", label: "MANIC" },
  { value: "MĂRIȘELU", label: "MĂRIȘELU" },
  { value: "MATEI", label: "MATEI" },
  { value: "MICEȘTII DE CÂMPIE", label: "MICEȘTII DE CÂMPIE" },
  { value: "MIJLOCENII BÂRGĂULUI", label: "MIJLOCENII BÂRGĂULUI" },
  { value: "MILAȘ", label: "MILAȘ" },
  { value: "MINTIU", label: "MINTIU" },
  { value: "MIREȘ", label: "MIREȘ" },
  { value: "MITITEI", label: "MITITEI" },
  { value: "MOCOD", label: "MOCOD" },
  { value: "MOGOȘENI", label: "MOGOȘENI" },
  { value: "MOLIȘET", label: "MOLIȘET" },
  { value: "MONARIU", label: "MONARIU" },
  { value: "MONOR", label: "MONOR" },
  { value: "MORUȚ", label: "MORUȚ" },
  { value: "MUREȘENII BÂRGĂULUI", label: "MUREȘENII BÂRGĂULUI" },
  { value: "NĂSĂUD", label: "NĂSĂUD" },
  { value: "NEGRILEȘTI", label: "NEGRILEȘTI" },
  { value: "NEPOS", label: "NEPOS" },
  { value: "NEȚENI", label: "NEȚENI" },
  { value: "NIMIGEA DE JOS", label: "NIMIGEA DE JOS" },
  { value: "NIMIGEA DE SUS", label: "NIMIGEA DE SUS" },
  { value: "NUȘENI", label: "NUȘENI" },
  { value: "OARZINA", label: "OARZINA" },
  { value: "OCNIȚA", label: "OCNIȚA" },
  { value: "ORHEIU BISTRIȚEI", label: "ORHEIU BISTRIȚEI" },
  { value: "OROSFAIA", label: "OROSFAIA" },
  { value: "PĂLTINEASA", label: "PĂLTINEASA" },
  { value: "PARVA", label: "PARVA" },
  { value: "PERIȘOR", label: "PERIȘOR" },
  { value: "PETRIȘ", label: "PETRIȘ" },
  { value: "PIATRA", label: "PIATRA" },
  { value: "PIATRA FÂNTÂNELE", label: "PIATRA FÂNTÂNELE" },
  { value: "PINTICU", label: "PINTICU" },
  { value: "PODENII", label: "PODENII" },
  { value: "PODEREI", label: "PODEREI" },
  { value: "PODIREI", label: "PODIREI" },
  { value: "POIANA ILVEI", label: "POIANA ILVEI" },
  { value: "POIENILE ZAGREI", label: "POIENILE ZAGREI" },
  { value: "PORUMBENII", label: "PORUMBENII" },
  { value: "POSMUȘ", label: "POSMUȘ" },
  { value: "PRUNDU BÂRGĂULUI", label: "PRUNDU BÂRGĂULUI" },
  { value: "PURCĂRETE", label: "PURCĂRETE" },
  { value: "RĂCĂTEȘU", label: "RĂCĂTEȘU" },
  { value: "RAGLA", label: "RAGLA" },
  { value: "REBRA", label: "REBRA" },
  { value: "REBRIȘOARA", label: "REBRIȘOARA" },
  { value: "RETEAG", label: "RETEAG" },
  { value: "RODNA", label: "RODNA" },
  { value: "ROMULI", label: "ROMULI" },
  { value: "RUNCU SALVEI", label: "RUNCU SALVEI" },
  { value: "RUȘTIOR", label: "RUȘTIOR" },
  { value: "RUSU BÂRGĂULUI", label: "RUSU BÂRGĂULUI" },
  { value: "RUSU DE JOS", label: "RUSU DE JOS" },
  { value: "RUSU DE SUS", label: "RUSU DE SUS" },
  { value: "SĂLCUȚA", label: "SĂLCUȚA" },
  { value: "SALVA", label: "SALVA" },
  { value: "SÂNGEORZ-BĂI", label: "SÂNGEORZ-BĂI" },
  { value: "SÂNGEORZU NOU", label: "SÂNGEORZU NOU" },
  { value: "SÂNIACOB", label: "SÂNIACOB" },
  { value: "SÂNMIHAIU DE CÂMPIE", label: "SÂNMIHAIU DE CÂMPIE" },
  { value: "SÂNNICOARĂ", label: "SÂNNICOARĂ" },
  { value: "ȘANȚ", label: "ȘANȚ" },
  { value: "SÂNTIOANA", label: "SÂNTIOANA" },
  { value: "SĂRATA", label: "SĂRATA" },
  { value: "SĂRĂȚEL", label: "SĂRĂȚEL" },
  { value: "SĂSARM", label: "SĂSARM" },
  { value: "SATU NOU", label: "SATU NOU" },
  { value: "SCOABE", label: "SCOABE" },
  { value: "SEBIȘ", label: "SEBIȘ" },
  { value: "ȘENDROAIA", label: "ȘENDROAIA" },
  { value: "ȘEȘURI SPERMEZEU-VALE", label: "ȘEȘURI SPERMEZEU-VALE" },
  { value: "ȘIEU", label: "ȘIEU" },
  { value: "ȘIEU-MĂGHERUȘ", label: "ȘIEU-MĂGHERUȘ" },
  { value: "ȘIEU-ODORHEI", label: "ȘIEU-ODORHEI" },
  { value: "ȘIEU-SFÂNTU", label: "ȘIEU-SFÂNTU" },
  { value: "ȘIEUȚ", label: "ȘIEUȚ" },
  { value: "SIGMIR", label: "SIGMIR" },
  { value: "SILIVAȘU DE CÂMPIE", label: "SILIVAȘU DE CÂMPIE" },
  { value: "SIMIONEȘTI", label: "SIMIONEȘTI" },
  { value: "ȘINTEREAG", label: "ȘINTEREAG" },
  { value: "ȘINTEREAG-GARĂ", label: "ȘINTEREAG-GARĂ" },
  { value: "ȘIRIOARA", label: "ȘIRIOARA" },
  { value: "SITA", label: "SITA" },
  { value: "SLĂTINIȚA", label: "SLĂTINIȚA" },
  { value: "ȘOIMUȘ", label: "ȘOIMUȘ" },
  { value: "ȘOPTERIU", label: "ȘOPTERIU" },
  { value: "SPERMEZEU", label: "SPERMEZEU" },
  { value: "STRÂMBA", label: "STRÂMBA" },
  { value: "STRUGURENI", label: "STRUGURENI" },
  { value: "STUPINI", label: "STUPINI" },
  { value: "SUPLAI", label: "SUPLAI" },
  { value: "SUSENII BÂRGĂULUI", label: "SUSENII BÂRGĂULUI" },
  { value: "ȚĂGȘORU", label: "ȚĂGȘORU" },
  { value: "ȚAGU", label: "ȚAGU" },
  { value: "TÂRLIȘUA", label: "TÂRLIȘUA" },
  { value: "TĂRPIU", label: "TĂRPIU" },
  { value: "TĂURE", label: "TĂURE" },
  { value: "TEACA", label: "TEACA" },
  { value: "TELCIȘOR", label: "TELCIȘOR" },
  { value: "TELCIU", label: "TELCIU" },
  { value: "ȚENTEA", label: "ȚENTEA" },
  { value: "ȚIGĂU", label: "ȚIGĂU" },
  { value: "TIHA BÂRGĂULUI", label: "TIHA BÂRGĂULUI" },
  { value: "TONCIU", label: "TONCIU" },
  { value: "TUREAC", label: "TUREAC" },
  { value: "UNIREA", label: "UNIREA" },
  { value: "URIU", label: "URIU" },
  { value: "URMENIȘ", label: "URMENIȘ" },
  { value: "VALEA", label: "VALEA" },
  { value: "VALEA BORCUTULUI", label: "VALEA BORCUTULUI" },
  { value: "VALEA MĂGHERUȘULUI", label: "VALEA MĂGHERUȘULUI" },
  { value: "VALEA MARE / ȘANȚ", label: "VALEA MARE / ȘANȚ" },
  { value: "VALEA MARE / URMENIȘ", label: "VALEA MARE / URMENIȘ" },
  { value: "VALEA POENII", label: "VALEA POENII" },
  { value: "VALEA VINULUI", label: "VALEA VINULUI" },
  { value: "VERMEȘ", label: "VERMEȘ" },
  { value: "VIILE TECII", label: "VIILE TECII" },
  { value: "VIIȘOARA", label: "VIIȘOARA" },
  { value: "VISUIA", label: "VISUIA" },
  { value: "VIȚA", label: "VIȚA" },
  { value: "ZAGRA", label: "ZAGRA" },
  { value: "ZORENI", label: "ZORENI" },
];
