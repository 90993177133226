import { FC, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import locale from "date-fns/locale/ro";
import { LocalizationProvider } from "@mui/lab";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { mapManageDocumentsTabstoEnum, mapManageDocumentsTabstoInt } from "utils/mapManageDocumentsTabstoEnum";
import { selectCurrentUser, selectSelectedUser, useAppDispatch, useAppSelector } from "store";
import { UsersSelector } from "./users-selector/UsersSelector";
import { Documents } from "./documents/Documents";
import { UserType } from "store/models/enums/UserType";
import { setSelectedUser } from "store/slices/grantContestSlice";
import { selectShowMonitoring } from "store/selectors/manageDocumentsSelectors";

export const ManageDocumentsComponent: FC = () => {
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const navParams = useParams();
    const currentUser = useAppSelector(selectCurrentUser);
    const selectedUser = useAppSelector(selectSelectedUser);
    const showMonitoring = useAppSelector(selectShowMonitoring);

    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (localStorage.getItem('userId')) {
            setSelectedUserId(parseInt(localStorage.getItem('userId') ?? "0"))
        } else {
            setSelectedUserId(selectedUser?.userId);
        }
        //eslint-disable-next-line
    }, [selectedUser])

    useEffect(() => {
        setActiveTab(mapManageDocumentsTabstoInt(navParams.tab));
        //eslint-disable-next-line
    }, [activeTab]);

    useEffect(() => {
        if (!showMonitoring) {
            navigate("/")
        }
        // if ((currentUser?.userType === UserType.COMPANY_USER && !currentUser?.wonGrant) || !showMonitoring) {
        //     navigate("/");
        // } for the sake of testing enable monitoring functionality

        return (() => {
            if (localStorage.getItem('userId'))
                localStorage.removeItem("userId");
            dispatch(setSelectedUser(null));
        })
        //eslint-disable-next-line
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        if (selectedUser !== null && selectedUser !== undefined) {
            navigate("/monitorizare/" + mapManageDocumentsTabstoEnum(newValue) + "/" + selectedUser?.userId);
        } else {
            navigate("/monitorizare/" + mapManageDocumentsTabstoEnum(newValue) + "/" + currentUser?.userId);
        }

    };

    return (
        <Container
            id="manage-documents-page"
            maxWidth={false}
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <Grid
                    container
                    sx={{
                        minHeight: "inherit",
                    }}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Container maxWidth="lg" disableGutters>
                            <Tabs value={activeTab} onChange={handleChange} variant="scrollable">
                                <Tab label="None" sx={{ display: 'none' }} />
                                <Tab label="Tehnic" />
                                <Tab label="Financiar" />
                                {<Tab label="Social" />}
                                {<Tab label="Achiziții" />}
                                {<Tab label="Modificări contractuale" />}
                                {<Tab label="Suplimentar" />}
                            </Tabs>
                            {currentUser?.userType !== UserType.COMPANY_USER && <UsersSelector documentCategory={activeTab} />}
                        </Container>
                        <Grid item flexGrow={1} padding={1} >
                            <Documents userId={selectedUserId} documentCategory={activeTab} />
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Container>
    )
}