import { TextField, Button, MenuItem, CircularProgress } from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import {
  generateAnexa11,
  generateAnexa12,
  generateAnexa13,
  generateAnexa14,
  generateAnexa15,
  generateAnexa16,
  generateAnexa8,
  selectCurrentUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import DownloadIcon from "@mui/icons-material/Download";
import { annexesSelectItems } from "store/models/selectItems";

export const AnnexesDownload: FC<{
  selectedSeries: number | null | undefined;
}> = ({ selectedSeries }) => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUser);
  const [selectedAnexa, setSelectedAnexa] = useState<
    number | null | undefined
  >();
  const [loading, setLoading] = useState(false);

  const handleSelectAnexa = (e: ChangeEvent<HTMLInputElement>) => {
    let val: string | null | undefined = e.target.value;
    if (val !== null || val !== undefined) {
      setSelectedAnexa(parseInt(val, 10));
    }
  };

  const checkSelectedSeriesForNull = () => {
    if (selectedSeries === null || selectedSeries === undefined) return true;
    else return false;
  };

  const anexaIsNull = () => {
    if (selectedAnexa === null || selectedAnexa === undefined) return true;
    return false;
  };

  const handleDownloadAnexa8 = () => {
    setLoading(true);
    dispatch(
      generateAnexa8({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };
  const handleDownloadAnexa11 = () => {
    setLoading(true);
    dispatch(
      generateAnexa11({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };
  const handleDownloadAnexa12 = () => {
    setLoading(true);
    dispatch(
      generateAnexa12({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };
  const handleDownloadAnexa13 = () => {
    setLoading(true);
    dispatch(
      generateAnexa13({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };
  const handleDownloadAnexa14 = () => {
    setLoading(true);
    dispatch(
      generateAnexa14({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };
  const handleDownloadAnexa15 = () => {
    setLoading(true);
    dispatch(
      generateAnexa15({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };
  const handleDownloadAnexa16 = () => {
    setLoading(true);
    dispatch(
      generateAnexa16({
        seriesId: selectedSeries,
        token: currentUser?.jwtToken,
      })
    ).then(() => setLoading(false));
  };

  const downloadAnexa = () => {
    switch (selectedAnexa) {
      case 8:
        handleDownloadAnexa8();
        break;
      case 11:
        handleDownloadAnexa11();
        break;
      case 12:
        handleDownloadAnexa12();
        break;
      case 13:
        handleDownloadAnexa13();
        break;
      case 14:
        handleDownloadAnexa14();
        break;
      case 15:
        handleDownloadAnexa15();
        break;
      case 16:
        handleDownloadAnexa16();
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <TextField
        select
        size="small"
        name="anexe"
        label="Anexă"
        value={selectedAnexa || ""}
        onChange={handleSelectAnexa}
        sx={{ minWidth: "120px" }}
      >
        {annexesSelectItems?.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="contained"
        disabled={checkSelectedSeriesForNull() || loading || anexaIsNull()}
        onClick={() => downloadAnexa()}
        sx={{ marginLeft: 1 }}
        startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
      >
        Descarcă
      </Button>
    </div>
  );
};
