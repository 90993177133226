import { Box, Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import "./FileUploadComponent.scss";
import { useAppDispatch } from "store";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { Close } from "@mui/icons-material";

interface FileUploadProps {
  onFilesChange?: Function;
  showSize?: boolean;
  uploadOnButtonCallback?: Function;
  uploadDisabled?: boolean;
  fileLimit?: number;
  fontSize?: string;
  acceptedFileformats?: Array<string>;
}

export const FileUploadComponent: FC<FileUploadProps> = ({
  onFilesChange,
  showSize,
  uploadOnButtonCallback,
  uploadDisabled,
  fileLimit,
  fontSize,
  acceptedFileformats,
}) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [acceptedFiles, setAcceptedFiles] = useState<Array<File>>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (onFilesChange) onFilesChange(files);
    //eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    if (fileLimit !== undefined) {
      if (acceptedFiles.length + files.length <= fileLimit) {
        setFiles((prev) => [...prev, ...acceptedFiles]);
      } else {
        displayMaximumFilesLimitReached();
      }
    } else {
      setFiles((prev) => [...prev, ...acceptedFiles]);
    }
    //eslint-disable-next-line
  }, [acceptedFiles]);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-invalid-type") {
          displayAcceptedFileformatMessage();
        }
        if (fileRejections[0].errors[0].code === "too-many-files") {
          displayMaximumFilesLimitReached();
        }
      }
      setAcceptedFiles(acceptedFiles);
    },
    //eslint-disable-next-line
    []
  );

  const displayMaximumFilesLimitReached = () => {
    dispatch(
      addAppNotification({
        message: `Se pot încărca doar ${fileLimit} document(e).`,
        severity: "warning",
      })
    );
  };

  const displayAcceptedFileformatMessage = () => {
    dispatch(
      addAppNotification({
        message: `Se pot încărca doar documente cu extensiile ${acceptedFileformats}.`,
        severity: "warning",
      })
    );
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: fileLimit,
    accept: acceptedFileformats,
  });
  const hasFiles = files.length > 0;

  const removeFile = (fileToRemove: File) => {
    let newFiles: Array<File> = files.filter((file) => file !== fileToRemove);
    setFiles(newFiles);
  };

  const openExplorerDialog = (event: any) => {
    if (event.target.className instanceof SVGAnimatedString) return;
    open();
  };

  const removeAllFiles = () => {
    setFiles([]);
  };

  return (
    <Grid sx={{ height: "100%" }}>
      <Grid sx={{ height: "90%" }}>
        <Paper
          className="upload-file-component"
          {...getRootProps()}
          //   elevation={isDragActive ? 15 : 5}
          onClick={(e: any) => openExplorerDialog(e)}
        >
          <input {...getInputProps()} />
          {!hasFiles && (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignContent="center"
              sx={{ height: "100%" }}
            >
              <Grid item>
                {isDragActive ? (
                  <Typography variant="h5" color="secondary" textAlign="center">
                    Plasați aici
                  </Typography>
                ) : (
                  <Grid
                    item
                    xs={12}
                    className="upload-file-component-container"
                  >
                    <Typography
                      variant="h5"
                      textAlign="center"
                      sx={{ fontSize: fontSize }}
                      className="upload-file-component__text"
                    >
                      Plasați fișierele aici sau click pentru a selecta
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid className="files-container" container>
            {files?.map((file, index) => (
              <Grid
                item
                xs={fileLimit === 1 ? 12 : 5.8}
                className="each-file-box"
                key={`file-key-${index}`}
              >
                <Grid item xs={11} className="file-name">
                  <Typography>
                    {showSize
                      ? file.name +
                        "  " +
                        (file.size / 1024 / 1024).toFixed(2) +
                        "MB"
                      : file.name}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1} container justifyContent="flex-end">
                  <Close
                    onClick={() => removeFile(file)}
                    sx={{ cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid>
        <Box sx={{ height: "10%" }}>
          {uploadOnButtonCallback && (
            <Grid
              sx={{
                justifyContent: "flex-end",
                marginTop: 1,
                display: "flex",
              }}
            >
              <Grid sx={{ paddingRight: 1 }}>
                <Button
                  disabled={!hasFiles}
                  color={"error"}
                  variant="contained"
                  onClick={(e) => {
                    if (!uploadDisabled) removeAllFiles();
                  }}
                >
                  Șterge
                </Button>
              </Grid>
              <Grid>
                <Tooltip title="Încarcă Fișierele">
                  <Button
                    disabled={uploadDisabled}
                    variant="contained"
                    color={"success"}
                    onClick={(e) => {
                      if (!uploadDisabled && hasFiles)
                        uploadOnButtonCallback(files, removeAllFiles);
                    }}
                  >
                    Încarcă
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
