import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";

import {
  AddJobPostModel,
  AllocatedUserModel,
  AllocateJobPostModel,
  AllocationsFiltersModel,
  ChangeAllocationTimeModel,
  EmployersFiltersModel,
  JobPostDetailsModel,
  JobPostsFiltersModel,
  PaginatedJobPostsModel,
  UnallocateJobPostModel,
  UpdateJobPostModel,
} from "store/models/mediation";
import { ApplicateToJobModel } from "store/models/mediation/ApplicateToJobModel";
import { ApplicationsFiltersModel } from "store/models/mediation/ApplicationsFiltersModel";
import { ChangeApplicationStatusModel } from "store/models/mediation/ChangeApplicationStatusModel";
import { EmployerNameModel } from "store/models/mediation/EmployerNameModel";
import { JobTitleModel } from "store/models/mediation/JobTitleModel";
import { PaginatedAllocationsModel } from "store/models/mediation/PaginatedAllocationsModel";
import { PaginatedApplicationsModel } from "store/models/mediation/PaginatedApplicationsModel";
import { UnallocatedCompaniesFiltersModel } from "store/models/mediation/UnallocatedCompaniesFiltersModel";
import { UnallocatedCompanyModel } from "store/models/mediation/UnallocatedCompanyModel";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";

const axios = require("axios").default;

export const addJobPost = createAsyncThunk<
  any,
  { jobData: AddJobPostModel; token: string | undefined },
  AppThunkConfig
>("mediation/addJobPost", async ({ jobData, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let form = new FormData();
    form.append("title", jobData.title.trim());
    form.append("description", jobData?.description ?? "");
    form.append("employerId", jobData.employerId.toString());
    form.append("uploadedByUserId", jobData.uploadedByUserId.toString());
    form.append("county", jobData.county?.toString() ?? "");
    form.append("locality", jobData.locality.trim() ?? "");
    form.append("street", jobData.street.trim() ?? "");
    form.append(
      "employmentRequirements",
      jobData.employmentRequirements.trim() ?? ""
    );
    form.append("vacancy", jobData.vacancy.trim() ?? "");
    form.append("vacanciesNumber", jobData?.vacanciesNumber.toString() ?? "");
    form.append("fileSubmissionDeadline", jobData.fileSubmissionDeadline ?? "");
    jobData.jobFiles?.forEach((file: string | Blob) => {
      form.append("jobFiles", file);
    });
    let { data } = await axios.post(baseUrl + "Mediation/AddJobPost", form, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message.text ?? "Monitorizare afisata.",
        severity: "success",
      })
    );
    return data;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getJobPost = createAsyncThunk<
  JobPostDetailsModel,
  { jobPostId: number; token: string | undefined },
  AppThunkConfig
>("mediation/getJobPost", async ({ jobPostId, token }, thunkAPI) => {
  try {
    // thunkAPI.dispatch(showOverlay());

    let { data } = await axios.get(baseUrl + "Mediation/GetJobPost", {
      params: {
        jobPostId: jobPostId,
      },
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // thunkAPI.dispatch(hideOverlay());
    return data.response;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const updateJobPost = createAsyncThunk<
  any,
  { jobData: UpdateJobPostModel; token: string | undefined },
  AppThunkConfig
>("mediation/updateJobPost", async ({ jobData, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let form = new FormData();
    form.append("jobPostId", jobData.jobPostId);
    form.append("title", jobData.title);
    form.append("description", jobData?.description ?? "");
    form.append("employerId", jobData.employerId.toString());
    form.append("updatedByUserId", jobData.updatedByUserId!.toString());
    jobData.newFiles?.forEach((file: string | Blob) => {
      form.append("newFiles", file);
    });
    jobData.deletedFiles?.forEach((file: number) => {
      form.append("deletedFiles", file.toString());
    });
    form.append("county", jobData.county?.toString() ?? "");
    form.append("locality", jobData.locality ?? "");
    form.append("street", jobData.street ?? "");
    form.append("employmentRequirements", jobData.employmentRequirements ?? "");
    form.append("vacancy", jobData.vacancy ?? "");
    form.append("vacanciesNumber", jobData?.vacanciesNumber.toString() ?? "");
    form.append("fileSubmissionDeadline", jobData.fileSubmissionDeadline ?? "");
    let { data } = await axios.post(baseUrl + "Mediation/UpdateJobPost", form, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message.text ?? "Monitorizare afisata.",
        severity: "success",
      })
    );
    return data;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const deleteJobPost = createAsyncThunk<
  any,
  { jobPostId: number; token: string | undefined },
  AppThunkConfig
>("mediation/deleteJobPost", async ({ jobPostId, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let form = new FormData();
    form.append("jobPostId", jobPostId.toString());
    let { data } = await axios.post(baseUrl + "Mediation/DeleteJobPost", form, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    thunkAPI.dispatch(hideOverlay());
    return data.response;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getFilteredPaginatedJobPosts = createAsyncThunk<
  PaginatedJobPostsModel,
  { filters: JobPostsFiltersModel; token: string | undefined },
  AppThunkConfig
>(
  "mediation/getFilteredPaginatedJobPosts",
  async ({ filters, token }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "Mediation/GetFilteredPaginatedJobPosts",
        {
          params: filters,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const allocateJobPost = createAsyncThunk<
  any,
  { model: AllocateJobPostModel; token: string | undefined },
  AppThunkConfig
>("mediation/allocateJobPost", async ({ model, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let { data } = await axios.post(
      baseUrl + "Mediation/AllocateJobPost",
      model,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message.text ?? "Monitorizare afisata.",
        severity: "success",
      })
    );
    return data.response;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const unallocateJobPost = createAsyncThunk<
  any,
  { model: UnallocateJobPostModel; token: string | undefined },
  AppThunkConfig
>("mediation/unallocateJobPost", async ({ model, token }, thunkAPI) => {
  try {
    let { data } = await axios.post(
      baseUrl + "Mediation/UnallocateJobPost",
      model,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message.text ?? "Monitorizare afisata.",
        severity: "success",
      })
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const changeAllocationTime = createAsyncThunk<
  any,
  { model: ChangeAllocationTimeModel; token: string | undefined },
  AppThunkConfig
>("mediation/changeAllocationTime", async ({ model, token }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let { data } = await axios.post(
      baseUrl + "Mediation/ChangeAllocationTime",
      model,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message.text ?? "Monitorizare afisata.",
        severity: "success",
      })
    );
    return data.response;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getFilteredPaginatedEmployers = createAsyncThunk<
  PaginatedJobPostsModel,
  { filters: EmployersFiltersModel; token: string | undefined },
  AppThunkConfig
>(
  "mediation/getFilteredPaginatedEmployers",
  async ({ filters, token }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "Mediation/GetFilteredPaginatedEmployers",
        {
          params: filters,
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const getEmployersNames = createAsyncThunk<
  EmployerNameModel[],
  { token: string | undefined },
  AppThunkConfig
>("mediation/getEmployersNames", async ({ token }, thunkAPI) => {
  try {
    let { data } = await axios.get(baseUrl + "Mediation/GetEmployersNames", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getUnallocatedCompanies = createAsyncThunk<
  UnallocatedCompanyModel[],
  { token: string | undefined; filters: UnallocatedCompaniesFiltersModel },
  AppThunkConfig
>("mediation/getUnallocatedCompanies", async ({ token, filters }, thunkAPI) => {
  try {
    let { data } = await axios.get(
      baseUrl + "Mediation/GetUnallocatedCompanies",
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filters,
        },
      }
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getUsersAllocatedToJobPost = createAsyncThunk<
  AllocatedUserModel[],
  { token: string | undefined; filters: AllocationsFiltersModel },
  AppThunkConfig
>(
  "mediation/getUsersAllocatedToJobPost",
  async ({ token, filters }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "Mediation/GetUsersAllocatedToJobPost",
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            ...filters,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const generateCountiesJobsArchive = createAsyncThunk<
  any,
  {
    token: string | undefined;
    periodStart: string | null;
    periodEnd: string | null;
  },
  AppThunkConfig
>(
  "mediation/generateCountiesJobsArchive",
  async ({ token, periodStart, periodEnd }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateCountiesJobsArchive",
        {
          params: {
            periodStart: periodStart,
            periodEnd: periodEnd,
          },

          withCredentials: true,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const getPaginatedAllocations = createAsyncThunk<
  PaginatedAllocationsModel,
  { token: string | undefined; filters: AllocationsFiltersModel },
  AppThunkConfig
>("mediation/getPaginatedAllocations", async ({ token, filters }, thunkAPI) => {
  try {
    let { data } = await axios.get(
      baseUrl + "Mediation/GetPaginatedAllocations",
      {
        params: filters,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getPaginatedApplications = createAsyncThunk<
  PaginatedApplicationsModel,
  { token: string | undefined; filters: ApplicationsFiltersModel },
  AppThunkConfig
>(
  "mediation/getPaginatedApplications",
  async ({ token, filters }, thunkAPI) => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("pageNumber", filters.pageNumber!.toString());
      queryParams.append("pageSize", filters.pageSize!.toString());
      if (filters.sortBy) {
        queryParams.append("sortBy", filters.sortBy.toString());
      }
      if (
        filters.employerId !== 0 &&
        filters.employerId !== undefined &&
        filters.employerId !== null
      ) {
        queryParams.append("employerId", filters.employerId!.toString());
      }
      if (filters.userId) {
        queryParams.append("userId", filters.userId.toString());
      }
      if (filters.jobId) {
        queryParams.append("jobId", filters.jobId.toString());
      }

      if (filters.descending) {
        queryParams.append("descending", filters.descending.toString());
      }
      if (filters.searchText) {
        queryParams.append("searchText", filters.searchText);
      }

      filters.applicationStatus?.forEach((status, index) => {
        queryParams.append(`applicationStatus[${index}]`, status.toString());
      });

      let { data } = await axios.get(
        baseUrl + "Mediation/GetPaginatedApplications",
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: queryParams,
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const applicateToJob = createAsyncThunk<
  any,
  { token: string | undefined; dto: ApplicateToJobModel },
  AppThunkConfig
>("mediation/applicateToJob", async ({ token, dto }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let form = new FormData();
    form.append("jobId", dto.jobId.toString());
    form.append("userId", dto.userId.toString());
    form.append("cv", dto.cv);
    let { data } = await axios.post(
      baseUrl + "Mediation/ApplicateToJob",
      form,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    thunkAPI.dispatch(hideOverlay());
    thunkAPI.dispatch(
      addAppNotification({
        message: data?.message.text ?? "Succes.",
        severity: "success",
      })
    );
    return data;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const changeApplicationsStatus = createAsyncThunk<
  any,
  { token: string | undefined; dto: ChangeApplicationStatusModel },
  AppThunkConfig
>("Mediation/ChangeApplicationStatus", async ({ token, dto }, thunkAPI) => {
  try {
    thunkAPI.dispatch(showOverlay());
    let { data } = await axios.post(
      baseUrl + "Mediation/ChangeApplicationStatus",
      dto,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    thunkAPI.dispatch(hideOverlay());
    return data.response;
  } catch (err: any) {
    thunkAPI.dispatch(hideOverlay());
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const getJobsWithAllocations = createAsyncThunk<
  JobTitleModel[],
  { token: string | undefined },
  AppThunkConfig
>("mediation/getJobsWithAllocations", async ({ token }, thunkAPI) => {
  try {
    let { data } = await axios.get(
      baseUrl + "Mediation/GetJobsWithAllocations",
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});
export const getJobsWithApplications = createAsyncThunk<
  JobTitleModel[],
  { token: string | undefined; employerId: number; jobTitle: string },
  AppThunkConfig
>(
  "mediation/GetJobsWithApplications",
  async ({ token, employerId, jobTitle }, thunkAPI) => {
    try {
      let { data } = await axios.get(
        baseUrl + "Mediation/GetJobsWithApplications",
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            employerId: employerId,
            jobTitle: jobTitle,
          },
        }
      );
      return data.response;
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);
