import { Container, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { useAppDispatch } from "store";

import { getParticipantsNumbers } from "store/thunks/statisticsThunks";
import { StatisticsTable } from "./statistics-table/StatisticsTable";
import { Trends } from "./trends/Trends";

export const StatisticsPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(getParticipantsNumbers(""));

    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl">
      <Tabs value={activeTab} onChange={handleChange}>
        <Tab label="Statistici" />
        <Tab label="Trends" />
      </Tabs>
      {activeTab === 0 && <StatisticsTable />}
      {activeTab === 1 && <Trends />}
    </Container>
  );
};
