export enum DataGridColumnNames {
  CreatedAt = 1,
  FirstName,
  LastName,
  Sender,
  Subject,
  SentTime,
  Receivers,
  Email,
  DocumentName,
  UploadTime,
  DocumentSize,
  UploadedByUser,
  LastUpdateTime,
  UniqueId,
  Comment,
  Score,
  FinalScorePhase2,
  FinalScorePhase3,
  CommentsPhase2,
  ContentType,
  EntryDate,
  TargetGroupCategory,
  EmploymentMarketSituation,
  Age,
  PhoneNumber,
  Locality,
  County,
  ExpertUserId,
  CourseSeriesName,
  StatusBeneficiar,
  AllocationTime,
}
