export enum County {
  ALBA = 1,
  CLUJ,
  SIBIU,
  MURES,
  SATU_MARE,
  MARAMURES,
  BIHOR,
  SALAJ,
  COVASNA,
  BISTRITA_NASAUD,
  HARGHITA,
  BRASOV,
}
