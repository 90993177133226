export const ClujSelectItemsLabel = [
  { value: "AGÂRBICIU", label: "AGÂRBICIU" },
  { value: "AGHIREȘU", label: "AGHIREȘU" },
  { value: "AGHIREȘU-FABRICI", label: "AGHIREȘU-FABRICI" },
  { value: "AGRIȘ", label: "AGRIȘ" },
  { value: "AITON", label: "AITON" },
  { value: "ALUNIȘ", label: "ALUNIȘ" },
  { value: "ALUNIȘU", label: "ALUNIȘU" },
  { value: "ANDICI", label: "ANDICI" },
  { value: "ANTĂȘ", label: "ANTĂȘ" },
  { value: "APAHIDA", label: "APAHIDA" },
  { value: "ARDEOVA", label: "ARDEOVA" },
  { value: "ARGHIȘU", label: "ARGHIȘU" },
  { value: "ARUNCUTA", label: "ARUNCUTA" },
  { value: "AȘCHILEU MARE", label: "AȘCHILEU MARE" },
  { value: "AȘCHILEU MIC", label: "AȘCHILEU MIC" },
  { value: "BĂBDIU", label: "BĂBDIU" },
  { value: "BĂBUȚIU", label: "BĂBUȚIU" },
  { value: "BACIU", label: "BACIU" },
  { value: "BĂDENI", label: "BĂDENI" },
  { value: "BĂDEȘTI", label: "BĂDEȘTI" },
  { value: "BĂGACIU", label: "BĂGACIU" },
  { value: "BĂGARA", label: "BĂGARA" },
  { value: "BĂIȘOARA", label: "BĂIȘOARA" },
  { value: "BĂIȚA", label: "BĂIȚA" },
  { value: "BĂLCEȘTI / BELIȘ", label: "BĂLCEȘTI / BELIȘ" },
  { value: "BĂLCEȘTI / CĂPUȘU MARE", label: "BĂLCEȘTI / CĂPUȘU MARE" },
  { value: "BĂRĂI", label: "BĂRĂI" },
  { value: "BÂRLEA", label: "BÂRLEA" },
  { value: "BATIN", label: "BATIN" },
  { value: "BEDECIU", label: "BEDECIU" },
  { value: "BELIȘ", label: "BELIȘ" },
  { value: "BERCHIEȘU", label: "BERCHIEȘU" },
  { value: "BERINDU", label: "BERINDU" },
  { value: "BICA", label: "BICA" },
  { value: "BICĂLATU", label: "BICĂLATU" },
  { value: "BLIDĂREȘTI", label: "BLIDĂREȘTI" },
  { value: "BOBÂLNA", label: "BOBÂLNA" },
  { value: "BOCIU", label: "BOCIU" },
  { value: "BODROG", label: "BODROG" },
  { value: "BOGATA", label: "BOGATA" },
  { value: "BOGATA DE JOS", label: "BOGATA DE JOS" },
  { value: "BOGATA DE SUS", label: "BOGATA DE SUS" },
  { value: "BOIAN", label: "BOIAN" },
  { value: "BOJ-CĂTUN", label: "BOJ-CĂTUN" },
  { value: "BOJU", label: "BOJU" },
  { value: "BOLDUȚ", label: "BOLDUȚ" },
  { value: "BOLOGA", label: "BOLOGA" },
  { value: "BONȚ", label: "BONȚ" },
  { value: "BONȚIDA", label: "BONȚIDA" },
  { value: "BORȘA", label: "BORȘA" },
  { value: "BORȘA-CĂTUN", label: "BORȘA-CĂTUN" },
  { value: "BORȘA-CRESTAIA", label: "BORȘA-CRESTAIA" },
  { value: "BORZEȘTI", label: "BORZEȘTI" },
  { value: "BOTENI", label: "BOTENI" },
  { value: "BRĂIȘORU", label: "BRĂIȘORU" },
  { value: "BUCEA", label: "BUCEA" },
  { value: "BUNEȘTI", label: "BUNEȘTI" },
  { value: "BURU", label: "BURU" },
  { value: "BUTENI", label: "BUTENI" },
  { value: "BUZA", label: "BUZA" },
  { value: "CACOVA IERII", label: "CACOVA IERII" },
  { value: "CĂIANU", label: "CĂIANU" },
  { value: "CĂIANU MIC", label: "CĂIANU MIC" },
  { value: "CĂIANU-VAMĂ", label: "CĂIANU-VAMĂ" },
  { value: "CĂLĂRAȘI", label: "CĂLĂRAȘI" },
  { value: "CĂLĂRAȘI-GARĂ", label: "CĂLĂRAȘI-GARĂ" },
  { value: "CĂLATA", label: "CĂLATA" },
  { value: "CĂLĂȚELE", label: "CĂLĂȚELE" },
  { value: "CALNA", label: "CALNA" },
  { value: "CĂMĂRAȘU", label: "CĂMĂRAȘU" },
  { value: "CÂMPENEȘTI", label: "CÂMPENEȘTI" },
  { value: "CÂMPIA TURZII", label: "CÂMPIA TURZII" },
  { value: "CĂPRIOARA", label: "CĂPRIOARA" },
  { value: "CĂPUȘU MARE", label: "CĂPUȘU MARE" },
  { value: "CĂPUȘU MIC", label: "CĂPUȘU MIC" },
  { value: "CARA", label: "CARA" },
  { value: "CĂȘEIU", label: "CĂȘEIU" },
  { value: "CASELE MICEȘTI", label: "CASELE MICEȘTI" },
  { value: "CĂTĂLINA", label: "CĂTĂLINA" },
  { value: "CÂȚCĂU", label: "CÂȚCĂU" },
  { value: "CĂTINA", label: "CĂTINA" },
  { value: "CEABA", label: "CEABA" },
  { value: "CEANU MARE", label: "CEANU MARE" },
  { value: "CEANU MIC", label: "CEANU MIC" },
  { value: "CERBEȘTI", label: "CERBEȘTI" },
  { value: "CERC", label: "CERC" },
  { value: "CETAN", label: "CETAN" },
  { value: "CHEIA", label: "CHEIA" },
  { value: "CHESĂU", label: "CHESĂU" },
  { value: "CHIDEA", label: "CHIDEA" },
  { value: "CHINTENI", label: "CHINTENI" },
  { value: "CHIRIȘ", label: "CHIRIȘ" },
  { value: "CHIUIEȘTI", label: "CHIUIEȘTI" },
  { value: "CIUBANCA", label: "CIUBANCA" },
  { value: "CIUBĂNCUȚA", label: "CIUBĂNCUȚA" },
  { value: "CIUCEA", label: "CIUCEA" },
  { value: "CIULENI", label: "CIULENI" },
  { value: "CIUMĂFAIA", label: "CIUMĂFAIA" },
  { value: "CIURGĂU", label: "CIURGĂU" },
  { value: "CIURILA", label: "CIURILA" },
  { value: "CLAPA", label: "CLAPA" },
  { value: "CLUJ-NAPOCA", label: "CLUJ-NAPOCA" },
  { value: "COASTA", label: "COASTA" },
  { value: "CODOR", label: "CODOR" },
  { value: "COJOCNA", label: "COJOCNA" },
  { value: "COLONIA", label: "COLONIA" },
  { value: "COMORÂȚA", label: "COMORÂȚA" },
  { value: "COMȘEȘTI", label: "COMȘEȘTI" },
  { value: "COPĂCENI", label: "COPĂCENI" },
  { value: "COPLEAN", label: "COPLEAN" },
  { value: "COPRU", label: "COPRU" },
  { value: "CORNENI", label: "CORNENI" },
  { value: "CORNEȘTI / MIHAI VITEAZU", label: "CORNEȘTI / MIHAI VITEAZU" },
  { value: "CORNEȘTI / CORNEȘTI", label: "CORNEȘTI / CORNEȘTI" },
  { value: "CORNEȘTI / GÂRBĂU", label: "CORNEȘTI / GÂRBĂU" },
  { value: "CORPADEA", label: "CORPADEA" },
  { value: "CORUȘU", label: "CORUȘU" },
  { value: "CRĂEȘTI", label: "CRĂEȘTI" },
  { value: "CRAIRÂT", label: "CRAIRÂT" },
  { value: "CREMENEA", label: "CREMENEA" },
  { value: "CRIȘENI", label: "CRIȘENI" },
  { value: "CRISTOREL", label: "CRISTOREL" },
  { value: "CUBLEȘU SOMEȘAN", label: "CUBLEȘU SOMEȘAN" },
  { value: "CURTUIUȘU DEJULUI", label: "CURTUIUȘU DEJULUI" },
  { value: "CUSTURA", label: "CUSTURA" },
  { value: "CUTCA", label: "CUTCA" },
  { value: "CUZDRIOARA", label: "CUZDRIOARA" },
  { value: "DĂBÂCA", label: "DĂBÂCA" },
  { value: "DÂMBU MARE", label: "DÂMBU MARE" },
  { value: "DÂMBURILE", label: "DÂMBURILE" },
  { value: "DÂNCU", label: "DÂNCU" },
  { value: "DÂNGĂU MARE", label: "DÂNGĂU MARE" },
  { value: "DÂNGĂU MIC", label: "DÂNGĂU MIC" },
  { value: "DÂRJA", label: "DÂRJA" },
  { value: "DAROȚ", label: "DAROȚ" },
  { value: "DEALU BOTII", label: "DEALU BOTII" },
  { value: "DEALU MARE", label: "DEALU MARE" },
  { value: "DEALU NEGRU", label: "DEALU NEGRU" },
  { value: "DEJ", label: "DEJ" },
  { value: "DELENI", label: "DELENI" },
  { value: "DEUȘU", label: "DEUȘU" },
  { value: "DEZMIR", label: "DEZMIR" },
  { value: "DIVICIORII MARI", label: "DIVICIORII MARI" },
  { value: "DIVICIORII MICI", label: "DIVICIORII MICI" },
  { value: "DOMOȘU", label: "DOMOȘU" },
  { value: "DORNA", label: "DORNA" },
  { value: "DOROLȚU", label: "DOROLȚU" },
  { value: "DOSU BRICII", label: "DOSU BRICII" },
  { value: "DOSU NAPULUI", label: "DOSU NAPULUI" },
  { value: "DRETEA", label: "DRETEA" },
  { value: "DUMBRAVA", label: "DUMBRAVA" },
  { value: "ELCIU", label: "ELCIU" },
  { value: "ESCU", label: "ESCU" },
  { value: "FĂGETU IERII", label: "FĂGETU IERII" },
  { value: "FALCA", label: "FALCA" },
  { value: "FÂNAȚE", label: "FÂNAȚE" },
  { value: "FĂURENI", label: "FĂURENI" },
  { value: "FEIURDENI", label: "FEIURDENI" },
  { value: "FELDIOARA", label: "FELDIOARA" },
  { value: "FELEACU", label: "FELEACU" },
  { value: "FILEA DE JOS", label: "FILEA DE JOS" },
  { value: "FILEA DE SUS", label: "FILEA DE SUS" },
  { value: "FINCIU", label: "FINCIU" },
  { value: "FINIȘEL", label: "FINIȘEL" },
  { value: "FIZEȘU GHERLII", label: "FIZEȘU GHERLII" },
  { value: "FLOREȘTI", label: "FLOREȘTI" },
  { value: "FODORA", label: "FODORA" },
  { value: "FRĂSINET", label: "FRĂSINET" },
  { value: "FRATA", label: "FRATA" },
  { value: "FUNDĂTURA", label: "FUNDĂTURA" },
  { value: "GĂDĂLIN", label: "GĂDĂLIN" },
  { value: "GÂRBĂU", label: "GÂRBĂU" },
  { value: "GÂRBĂU DEJULUI", label: "GÂRBĂU DEJULUI" },
  { value: "GEACA", label: "GEACA" },
  { value: "GHEORGHIENI", label: "GHEORGHIENI" },
  { value: "GHERLA", label: "GHERLA" },
  { value: "GHIRIȘU ROMÂN", label: "GHIRIȘU ROMÂN" },
  { value: "GHIROLT", label: "GHIROLT" },
  { value: "GILĂU", label: "GILĂU" },
  { value: "GIULA", label: "GIULA" },
  { value: "GIURCUȚA DE JOS", label: "GIURCUȚA DE JOS" },
  { value: "GIURCUȚA DE SUS", label: "GIURCUȚA DE SUS" },
  { value: "GLIGOREȘTI", label: "GLIGOREȘTI" },
  { value: "GUGA", label: "GUGA" },
  { value: "HAGĂU", label: "HAGĂU" },
  {
    value: "HĂȘDATE / MUNICIPIUL GHERLA",
    label: "HĂȘDATE / MUNICIPIUL GHERLA",
  },
  { value: "HĂȘDATE / SĂVĂDISLA", label: "HĂȘDATE / SĂVĂDISLA" },
  { value: "HODĂI BOIAN", label: "HODĂI BOIAN" },
  { value: "HODAIE", label: "HODAIE" },
  { value: "HODIȘU", label: "HODIȘU" },
  { value: "HORLACEA", label: "HORLACEA" },
  { value: "HUCI", label: "HUCI" },
  { value: "HUEDIN", label: "HUEDIN" },
  { value: "HUTA", label: "HUTA" },
  { value: "IACOBENI", label: "IACOBENI" },
  { value: "IARA", label: "IARA" },
  { value: "ICLOD", label: "ICLOD" },
  { value: "ICLOZEL", label: "ICLOZEL" },
  { value: "IGRIȚIA", label: "IGRIȚIA" },
  { value: "INUCU", label: "INUCU" },
  { value: "IURIU DE CÂMPIE", label: "IURIU DE CÂMPIE" },
  { value: "IZVORU CRIȘULUI", label: "IZVORU CRIȘULUI" },
  { value: "JICHIȘU DE JOS", label: "JICHIȘU DE JOS" },
  { value: "JICHIȘU DE SUS", label: "JICHIȘU DE SUS" },
  { value: "JUC-HERGHELIE", label: "JUC-HERGHELIE" },
  { value: "JUCU DE MIJLOC", label: "JUCU DE MIJLOC" },
  { value: "JUCU DE SUS", label: "JUCU DE SUS" },
  { value: "JURCA", label: "JURCA" },
  { value: "LACU", label: "LACU" },
  { value: "LĂPUȘTEȘTI", label: "LĂPUȘTEȘTI" },
  { value: "LEGHIA", label: "LEGHIA" },
  { value: "LEGII", label: "LEGII" },
  { value: "LEURDA", label: "LEURDA" },
  { value: "LITA", label: "LITA" },
  { value: "LITENI", label: "LITENI" },
  { value: "LIVADA / ICLOD", label: "LIVADA / ICLOD" },
  { value: "LIVADA / PETREȘTII DE JOS", label: "LIVADA / PETREȘTII DE JOS" },
  { value: "LOBODAȘ", label: "LOBODAȘ" },
  { value: "LUJERDIU", label: "LUJERDIU" },
  { value: "LUNA", label: "LUNA" },
  { value: "LUNA DE JOS", label: "LUNA DE JOS" },
  { value: "LUNA DE SUS", label: "LUNA DE SUS" },
  { value: "LUNCA BONȚULUI", label: "LUNCA BONȚULUI" },
  { value: "LUNCA VIȘAGULUI", label: "LUNCA VIȘAGULUI" },
  { value: "LUNCANI", label: "LUNCANI" },
  { value: "LUNGEȘTI", label: "LUNGEȘTI" },
  { value: "MACĂU", label: "MACĂU" },
  { value: "MĂCICAȘU", label: "MĂCICAȘU" },
  { value: "MĂGOAJA", label: "MĂGOAJA" },
  { value: "MĂGURA IERII", label: "MĂGURA IERII" },
  { value: "MĂGURI", label: "MĂGURI" },
  { value: "MĂGURI-RĂCĂTĂU", label: "MĂGURI-RĂCĂTĂU" },
  { value: "MĂHAL", label: "MĂHAL" },
  { value: "MAIA", label: "MAIA" },
  { value: "MĂNĂSTIREA", label: "MĂNĂSTIREA" },
  { value: "MĂNĂSTIRENI", label: "MĂNĂSTIRENI" },
  { value: "MĂNĂȘTUREL", label: "MĂNĂȘTUREL" },
  { value: "MĂNĂȘTURU ROMÂNESC", label: "MĂNĂȘTURU ROMÂNESC" },
  { value: "MĂRCEȘTI", label: "MĂRCEȘTI" },
  { value: "MĂRGĂU", label: "MĂRGĂU" },
  { value: "MĂRIȘEL", label: "MĂRIȘEL" },
  { value: "MĂRTINEȘTI", label: "MĂRTINEȘTI" },
  { value: "MAȘCA", label: "MAȘCA" },
  { value: "MERA", label: "MERA" },
  { value: "MICA", label: "MICA" },
  { value: "MICEȘTI", label: "MICEȘTI" },
  { value: "MIHAI VITEAZU", label: "MIHAI VITEAZU" },
  { value: "MIHĂIEȘTI", label: "MIHĂIEȘTI" },
  { value: "MINTIU GHERLII", label: "MINTIU GHERLII" },
  { value: "MOARA DE PĂDURE", label: "MOARA DE PĂDURE" },
  { value: "MOCIU", label: "MOCIU" },
  { value: "MOLDOVENEȘTI", label: "MOLDOVENEȘTI" },
  { value: "MORĂU", label: "MORĂU" },
  { value: "MORIȘTI", label: "MORIȘTI" },
  { value: "MORLACA", label: "MORLACA" },
  { value: "MORȚEȘTI", label: "MORȚEȘTI" },
  { value: "MUNCEL", label: "MUNCEL" },
  { value: "MUNTELE BĂIȘORII", label: "MUNTELE BĂIȘORII" },
  { value: "MUNTELE BOCULUI", label: "MUNTELE BOCULUI" },
  { value: "MUNTELE CACOVEI", label: "MUNTELE CACOVEI" },
  { value: "MUNTELE FILII", label: "MUNTELE FILII" },
  { value: "MUNTELE RECE", label: "MUNTELE RECE" },
  { value: "MUNTELE SĂCELULUI", label: "MUNTELE SĂCELULUI" },
  { value: "MUREȘENII DE CÂMPIE", label: "MUREȘENII DE CÂMPIE" },
  { value: "NĂDĂȘELU", label: "NĂDĂȘELU" },
  { value: "NADĂȘU", label: "NADĂȘU" },
  { value: "NĂOIU", label: "NĂOIU" },
  { value: "NĂSAL", label: "NĂSAL" },
  { value: "NEARȘOVA", label: "NEARȘOVA" },
  { value: "NEGRENI", label: "NEGRENI" },
  { value: "NICULA", label: "NICULA" },
  { value: "NIMA", label: "NIMA" },
  { value: "NIREȘ", label: "NIREȘ" },
  { value: "OAȘ", label: "OAȘ" },
  { value: "OCNA DEJULUI", label: "OCNA DEJULUI" },
  { value: "OCOLIȘEL", label: "OCOLIȘEL" },
  { value: "OLĂRIU", label: "OLĂRIU" },
  { value: "ORMAN", label: "ORMAN" },
  { value: "OSOI", label: "OSOI" },
  { value: "OȘORHEL", label: "OȘORHEL" },
  { value: "PĂDUREA IACOBENI", label: "PĂDUREA IACOBENI" },
  { value: "PĂDURENI / CHINTENI", label: "PĂDURENI / CHINTENI" },
  { value: "PĂDURENI / CIURILA", label: "PĂDURENI / CIURILA" },
  { value: "PĂDURENI / TRITENII DE JOS", label: "PĂDURENI / TRITENII DE JOS" },
  { value: "PĂDURENII", label: "PĂDURENII" },
  { value: "PÂGLIȘA", label: "PÂGLIȘA" },
  { value: "PĂLATCA", label: "PĂLATCA" },
  { value: "PĂNICENI", label: "PĂNICENI" },
  { value: "PANTICEU", label: "PANTICEU" },
  { value: "PATA", label: "PATA" },
  { value: "PEȘTERA", label: "PEȘTERA" },
  { value: "PETEA", label: "PETEA" },
  { value: "PETREȘTI", label: "PETREȘTI" },
  { value: "PETREȘTII DE JOS", label: "PETREȘTII DE JOS" },
  { value: "PETREȘTII DE MIJLOC", label: "PETREȘTII DE MIJLOC" },
  { value: "PETREȘTII DE SUS", label: "PETREȘTII DE SUS" },
  { value: "PIETROASA", label: "PIETROASA" },
  { value: "PINTIC", label: "PINTIC" },
  { value: "PLĂIEȘTI", label: "PLĂIEȘTI" },
  { value: "PLAIURI", label: "PLAIURI" },
  { value: "PLOPI", label: "PLOPI" },
  { value: "PLOSCOȘ", label: "PLOSCOȘ" },
  { value: "PODENI", label: "PODENI" },
  { value: "POIANA FRĂȚII", label: "POIANA FRĂȚII" },
  { value: "POIANA HOREA", label: "POIANA HOREA" },
  { value: "POIENI", label: "POIENI" },
  { value: "POPEȘTI", label: "POPEȘTI" },
  { value: "PRELUCELE", label: "PRELUCELE" },
  { value: "PRUNENI", label: "PRUNENI" },
  { value: "PRUNI", label: "PRUNI" },
  { value: "PRUNIȘ", label: "PRUNIȘ" },
  { value: "PUINI", label: "PUINI" },
  { value: "PUSTUȚA", label: "PUSTUȚA" },
  { value: "RĂCHIȚELE", label: "RĂCHIȚELE" },
  { value: "RĂDAIA", label: "RĂDAIA" },
  { value: "RÂȘCA", label: "RÂȘCA" },
  { value: "RĂSCRUCI", label: "RĂSCRUCI" },
  { value: "RĂZBUNENI", label: "RĂZBUNENI" },
  { value: "RĂZOARE", label: "RĂZOARE" },
  { value: "RECEA-CRISTUR", label: "RECEA-CRISTUR" },
  { value: "REDIU", label: "REDIU" },
  { value: "ROGOJEL", label: "ROGOJEL" },
  { value: "ROȘIENI", label: "ROȘIENI" },
  { value: "ROTUNDA", label: "ROTUNDA" },
  { value: "RUGĂȘEȘTI", label: "RUGĂȘEȘTI" },
  { value: "SĂCĂLAIA", label: "SĂCĂLAIA" },
  { value: "SĂCEL", label: "SĂCEL" },
  { value: "SĂCUIEU", label: "SĂCUIEU" },
  { value: "SALATIU", label: "SALATIU" },
  { value: "SĂLĂTRUC", label: "SĂLĂTRUC" },
  { value: "SĂLICEA", label: "SĂLICEA" },
  { value: "SĂLIȘCA", label: "SĂLIȘCA" },
  { value: "SĂLIȘTE", label: "SĂLIȘTE" },
  { value: "SĂLIȘTEA NOUĂ", label: "SĂLIȘTEA NOUĂ" },
  { value: "SĂLIȘTEA VECHE", label: "SĂLIȘTEA VECHE" },
  { value: "SÂMBOIENI", label: "SÂMBOIENI" },
  { value: "SÂMBOLENI", label: "SÂMBOLENI" },
  { value: "SÂNCRAIU", label: "SÂNCRAIU" },
  { value: "SĂNDULEȘTI", label: "SĂNDULEȘTI" },
  { value: "SÂNMĂRGHITA", label: "SÂNMĂRGHITA" },
  { value: "SÂNMĂRTIN / CHINTENI", label: "SÂNMĂRTIN / CHINTENI" },
  { value: "SÂNMĂRTIN / SÂNMĂRTIN", label: "SÂNMĂRTIN / SÂNMĂRTIN" },
  { value: "SÂNNICOARĂ", label: "SÂNNICOARĂ" },
  { value: "SÂNPAUL", label: "SÂNPAUL" },
  { value: "SÂNTEJUDE", label: "SÂNTEJUDE" },
  { value: "SÂNTEJUDE VALE", label: "SÂNTEJUDE VALE" },
  { value: "SÂNTIOANA", label: "SÂNTIOANA" },
  { value: "SĂRĂDIȘ", label: "SĂRĂDIȘ" },
  { value: "SĂRATA", label: "SĂRATA" },
  { value: "ȘARDU", label: "ȘARDU" },
  { value: "SATU LUNG", label: "SATU LUNG" },
  { value: "ȘAULA", label: "ȘAULA" },
  { value: "SAVA", label: "SAVA" },
  { value: "SĂVĂDISLA", label: "SĂVĂDISLA" },
  { value: "SCRIND-FRĂSINET", label: "SCRIND-FRĂSINET" },
  { value: "SIC", label: "SIC" },
  { value: "SICFA", label: "SICFA" },
  { value: "ȘIGĂU", label: "ȘIGĂU" },
  { value: "SILIVAȘ", label: "SILIVAȘ" },
  { value: "SMIDA", label: "SMIDA" },
  { value: "ȘOIMENI", label: "ȘOIMENI" },
  { value: "ȘOMCUTU MIC", label: "ȘOMCUTU MIC" },
  { value: "SOMEȘU CALD", label: "SOMEȘU CALD" },
  { value: "SOMEȘU RECE", label: "SOMEȘU RECE" },
  { value: "SOPORU DE CÂMPIE", label: "SOPORU DE CÂMPIE" },
  { value: "STÂRCU", label: "STÂRCU" },
  { value: "STEJERIȘ", label: "STEJERIȘ" },
  { value: "STOIANA", label: "STOIANA" },
  { value: "STOLNA", label: "STOLNA" },
  { value: "STRAJA / CĂPUȘU MARE", label: "STRAJA / CĂPUȘU MARE" },
  { value: "STRAJA / COJOCNA", label: "STRAJA / COJOCNA" },
  { value: "STRÂMBU", label: "STRÂMBU" },
  { value: "STRUCUT", label: "STRUCUT" },
  { value: "SUARĂȘ", label: "SUARĂȘ" },
  { value: "SUATU", label: "SUATU" },
  { value: "SUB COASTĂ", label: "SUB COASTĂ" },
  { value: "SUCEAGU", label: "SUCEAGU" },
  { value: "SUCUTARD", label: "SUCUTARD" },
  { value: "SUMURDUCU", label: "SUMURDUCU" },
  { value: "SURDUC", label: "SURDUC" },
  { value: "ȘUTU", label: "ȘUTU" },
  { value: "ȚAGA", label: "ȚAGA" },
  { value: "TÂRGUȘOR", label: "TÂRGUȘOR" },
  { value: "TĂRPIU", label: "TĂRPIU" },
  { value: "TĂUȘENI", label: "TĂUȘENI" },
  { value: "TĂUȚI", label: "TĂUȚI" },
  { value: "TICU", label: "TICU" },
  { value: "TICU-COLONIE", label: "TICU-COLONIE" },
  { value: "TIOCU DE JOS", label: "TIOCU DE JOS" },
  { value: "TIOCU DE SUS", label: "TIOCU DE SUS" },
  { value: "TIOLTIUR", label: "TIOLTIUR" },
  { value: "TOPA MICĂ", label: "TOPA MICĂ" },
  { value: "TRANIȘU", label: "TRANIȘU" },
  { value: "TRITENII DE JOS", label: "TRITENII DE JOS" },
  { value: "TRITENII DE SUS", label: "TRITENII DE SUS" },
  { value: "TRITENII-HOTAR", label: "TRITENII-HOTAR" },
  { value: "TURDA", label: "TURDA" },
  { value: "TUREA", label: "TUREA" },
  { value: "TURENI", label: "TURENI" },
  { value: "TURMAȘI", label: "TURMAȘI" },
  { value: "UNGURAȘ", label: "UNGURAȘ" },
  { value: "URCA", label: "URCA" },
  { value: "URIȘOR", label: "URIȘOR" },
  { value: "VAD", label: "VAD" },
  { value: "VAIDA-CĂMĂRAȘ", label: "VAIDA-CĂMĂRAȘ" },
  { value: "VÂLCELE", label: "VÂLCELE" },
  { value: "VÂLCELELE", label: "VÂLCELELE" },
  { value: "VALE", label: "VALE" },
  { value: "VALEA AGRIȘULUI", label: "VALEA AGRIȘULUI" },
  { value: "VALEA CALDĂ", label: "VALEA CALDĂ" },
  { value: "VALEA CĂȘEIELULUI", label: "VALEA CĂȘEIELULUI" },
  { value: "VALEA CIREȘOII", label: "VALEA CIREȘOII" },
  { value: "VALEA DRĂGANULUI", label: "VALEA DRĂGANULUI" },
  { value: "VALEA FLORILOR", label: "VALEA FLORILOR" },
  { value: "VALEA GÂRBOULUI", label: "VALEA GÂRBOULUI" },
  { value: "VALEA GROȘILOR", label: "VALEA GROȘILOR" },
  { value: "VALEA IERII", label: "VALEA IERII" },
  { value: "VALEA LUI CATI", label: "VALEA LUI CATI" },
  { value: "VALEA LUI OPRIȘ", label: "VALEA LUI OPRIȘ" },
  { value: "VALEA LUNCII", label: "VALEA LUNCII" },
  { value: "VALEA UNGURAȘULUI", label: "VALEA UNGURAȘULUI" },
  { value: "VALEA VADULUI", label: "VALEA VADULUI" },
  { value: "VĂLENI / CĂIANU", label: "VĂLENI / CĂIANU" },
  { value: "VĂLENI / CĂLĂȚELE", label: "VĂLENI / CĂLĂȚELE" },
  { value: "VĂLIȘOARA", label: "VĂLIȘOARA" },
  { value: "VÂNĂTORI", label: "VÂNĂTORI" },
  { value: "VECHEA", label: "VECHEA" },
  { value: "VIIȘOARA", label: "VIIȘOARA" },
  { value: "VIȘAGU", label: "VIȘAGU" },
  { value: "VIȘEA", label: "VIȘEA" },
  { value: "VIȘTEA", label: "VIȘTEA" },
  { value: "VLAHA", label: "VLAHA" },
  { value: "VULTURENI", label: "VULTURENI" },
  { value: "ZORENII DE VALE", label: "ZORENII DE VALE" },
];
