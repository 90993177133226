import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useState } from "react";
import "./FileUploadPopout.scss";
import { FileUploadComponent } from "../fileUpload/FileUploadComponent";
import { Close } from "@mui/icons-material";

interface FileUploadProps {
  onFilesChange?: Function;
  onUpload?: Function;
  showSize?: boolean;
  uploadOnButtonCallback?: Function;
  uploadDisabled?: boolean;
  displayDialog: boolean;
  handleClose: Function;
  fileLimit?: number;
  acceptedFileformats?: Array<string>;
}

export const FileUploadPopout: FC<FileUploadProps> = ({
  onFilesChange,
  showSize,
  uploadOnButtonCallback,
  uploadDisabled,
  onUpload,
  displayDialog,
  handleClose,
  fileLimit,
  acceptedFileformats,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  //eslint-disable-next-line
  const [files, setFiles] = useState<File[]>(new Array<File>());

  return (
    <div>
      <Dialog
        fullScreen={isSmallScreen}
        id="send-or-view-communication"
        className="dialog"
        open={displayDialog}
        maxWidth="sm"
        fullWidth
        disableScrollLock
        onClose={() => handleClose()}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip title="Inchide">
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent sx={{ height: "350px" }}>
          <FileUploadComponent
            onFilesChange={(files: File[]) => {
              if (onFilesChange) onFilesChange(files);
              setFiles(files);
            }}
            showSize={showSize}
            uploadOnButtonCallback={(...params: any[]) => {
              if (uploadOnButtonCallback) {
                uploadOnButtonCallback(...params);
                handleClose();
              }
            }}
            uploadDisabled={uploadDisabled}
            fileLimit={fileLimit}
            acceptedFileformats={acceptedFileformats}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
