import { Button, ButtonGroup, Container, Grid, Tooltip } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import {
    deleteExtraFilesDoc,
    downloadVocationalTrainingExtraFileDoc,
    requestExtraFilesDocs,
    selectCurrentUser,
    selectVocationalTrainingExtraFiles,
    selectVocationalTrainingExtraFilesRowCount,
    selectExtraFilesListIsLoading,
    uploadExtraFilesDocs,
    useAppDispatch,
    useAppSelector,
    selectVocationalFileIsDownloading,
    selectVocationalTrainingPageLocalization,
    selectExtraFilesFilters,
} from "store";
import { Add, Delete } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { FileUploadPopout } from "components";
import "./VocationalTrainingExtraFiles.scss";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { UserType } from "store/models/enums/UserType";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { dateTimeFormatOptions } from "utils";
import { setExtraFilesFilters } from "store/slices/vocationalTrainingSlice";

export const VocationalTrainingExtraFiles: FC = () => {
    const rowsPerPage = 10;
    const dispatch = useAppDispatch();
    const extraFiles = useAppSelector(selectVocationalTrainingExtraFiles);
    const filters = useAppSelector(selectExtraFilesFilters);
    const extraFilesFormRowCount = useAppSelector(
        selectVocationalTrainingExtraFilesRowCount
    );
    const currentUser = useAppSelector(selectCurrentUser);
    const extraFilesListIsLoading = useAppSelector(selectExtraFilesListIsLoading);
    const ro = useAppSelector(selectVocationalTrainingPageLocalization);
    const vocationalFileIsDownloading = useAppSelector(
        selectVocationalFileIsDownloading
    );
    const [displayUploadDialog, setDisplayUploadDialog] =
        useState<boolean>(false);

    useEffect(() => {
        return (() => {
            dispatch(setExtraFilesFilters({
                pageNumber: 0,
                sortingOrder: false,
                columnToSortBy: DataGridColumnNames.UploadTime
            }))
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        let promise = dispatch(
            requestExtraFilesDocs({
                filter: filters,
                token: currentUser?.jwtToken,
            })
        );
        return (() => {
            promise.abort();

        })
        //eslint-disable-next-line
    }, [filters]);

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadExtraFilesDocs({ files: files, token: currentUser?.jwtToken })
            ).then(() => {
                removeAllFilesCallback();
                if (filters.pageNumber === 0) {
                    dispatch(
                        requestExtraFilesDocs({
                            filter: filters,
                            token: currentUser?.jwtToken,
                        })
                    );
                } else {
                    dispatch(setExtraFilesFilters({
                        ...filters,
                        pageNumber: 0
                    }))
                }

            });
        }
    };

    const handlePageChange = (pageNumber: any) => {
        dispatch(setExtraFilesFilters({
            ...filters,
            pageNumber: pageNumber
        }))
    };

    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: ro.docNameCol, flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: ro.uploadTimeCol,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "size",
            headerName: ro.sizeCol,
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value.toString().substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "author",
            headerName: ro.authorCol,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: ro.optionsCol,
            sortable: false,
            flex: 1,
            minWidth: 150,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title={ro.downloadFileTooltip}>
                            <Button
                                disabled={vocationalFileIsDownloading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadVocationalTrainingExtraFileDoc({
                                            fileId: params.row.fileId,
                                            token: currentUser?.jwtToken,
                                        })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {currentUser?.userType !== UserType.COMPANY_USER && (
                            <Tooltip title={ro.deleteFileTooltip}>
                                <Button
                                    disabled={vocationalFileIsDownloading}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            deleteExtraFilesDoc({
                                                docId: params.row.vocDocumentId,
                                                token: currentUser?.jwtToken,
                                            })
                                        ).then(() => {
                                            dispatch(
                                                requestExtraFilesDocs({
                                                    filter: filters,
                                                    token: currentUser?.jwtToken,
                                                })
                                            );
                                        });
                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length === 0) {
            dispatch(setExtraFilesFilters({
                columnToSortBy: undefined,
                sortingOrder: false,
                pageNumber: 0
            }))
        } else {
            dispatch(setExtraFilesFilters({
                columnToSortBy: mapColumnNameToEnum(newModel[0].field),
                sortingOrder: newModel[0].sort === "desc" ? true : false,
                pageNumber: 0
            }))
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    return (
        <Container
            id="vocational-training-extra-files-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{
                    minHeight: "inherit",
                    p: {
                        xs: 1,
                        md: 2,
                    },
                }}
                alignItems="stretch"
            >
                {currentUser?.userType !== UserType.COMPANY_USER && (
                    <Grid container sx={{ justifyContent: "flex-end" }}>
                        {
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                color="success"
                                onClick={() => {
                                    setDisplayUploadDialog(true);
                                }}
                            >
                                {ro.addBtn}
                            </Button>
                        }
                    </Grid>
                )}
                {displayUploadDialog && (
                    <FileUploadPopout
                        showSize={true}
                        uploadOnButtonCallback={onFilesUpload}
                        handleClose={handleClose}
                        displayDialog={displayUploadDialog}
                    />
                )}
                <Grid
                    item
                    xs={12}
                    style={{ marginTop: "10px" }}
                    className="vocational-training-extra-files-table"
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent,
                        }}
                        loading={extraFilesListIsLoading}
                        hideFooterPagination={extraFilesListIsLoading}
                        rows={extraFiles}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={extraFilesFormRowCount}
                        onPageChange={handlePageChange}
                        page={filters.pageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: 630,
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>
                </Grid>
            </Grid>
        </Container>
    );
};
