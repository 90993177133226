import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, IconButton, TextField } from "@mui/material";
import { ItemsSelector } from "components/shared/itemsSelector/ItemsSelector";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "store";
import { countiesSelectItems } from "store/models/selectItems";
import locale from "date-fns/locale/ro";
import {
  selectEmployersNames,
  selectJobsFilters,
} from "store/selectors/mediationSelectors";
import { setJobsFilters } from "store/slices/mediationSlice";
import "./JobsFilters.scss";
import { jobsSortItems } from "store/models/selectItems/jobsSortItems";
import { sortOrderItems } from "store/models/selectItems/sortOrderItems";
import { CloseRounded, Search } from "@mui/icons-material";
import { isEmployer } from "utils/getUserType";
import { getDateLabel, onlyDateFormat } from "utils";

export const JobsFilters: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectJobsFilters);
  const currentUser = useAppSelector(selectCurrentUser);
  const employers = useAppSelector(selectEmployersNames);
  const [search, setSearch] = useState<string>(filters.searchText);
  const debouncedValue = useDebounce(search, 500);
  const ro = useAppSelector(selectMediationPageLocalization);

  const employer = isEmployer(currentUser?.userType);

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue !== filters.searchText) {
      dispatch(
        setJobsFilters({
          ...filters,
          pageNumber: 0,
          searchText: debouncedValue,
        })
      );
    }
    //eslint-disable-next-line
  }, [debouncedValue]);

  const setEmployerNull = () => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        employerId: undefined,
      })
    );
    navigate("/mediere/joburi/0", { replace: true });
  };

  const setEmployer = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        employerId: e.target.value,
      })
    );
    navigate("/mediere/joburi/" + e.target.value.toString(), { replace: true });
  };

  const setCountyFilter = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        county: e.target.value,
      })
    );
  };

  const removeCountyFilter = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        county: undefined,
      })
    );
  };

  const getDateOnChange = (e: Date | null | any, start: boolean) => {
    var dateRegex = /^\d{2}\.\d{2}\.\d{4}$/;
    const date = getDateLabel(e, onlyDateFormat);
    const isValid = dateRegex.test(date);

    if (!isValid) {
      return;
    } else {
      start ? setFirstUploadTimeFilter(e) : setSecondUploadTimeFilter(e);
    }
  };

  const setFirstUploadTimeFilter = (e: Date | null) => {
    dispatch(
      setJobsFilters({
        ...filters,
        firstUploadTime: e ? e.toISOString() : undefined,
        pageNumber: 0,
      })
    );
  };

  const setSecondUploadTimeFilter = (e: Date | null) => {
    dispatch(
      setJobsFilters({
        ...filters,
        secondUploadTime: e ? e.toISOString() : undefined,
        pageNumber: 0,
      })
    );
  };

  const setSortByFilter = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        dataToSortBy: e.target.value,
        descending: filters.descending ? filters.descending : true,
      })
    );
  };

  const removeSortByFilter = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        dataToSortBy: undefined,
      })
    );
  };

  const setDescendingFilter = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        descending: e.target.value,
      })
    );
  };
  const removeDescendingFilter = (e: any) => {
    dispatch(
      setJobsFilters({
        ...filters,
        pageNumber: 0,
        descending: undefined,
      })
    );
  };
  return (
    <Grid container id="jobs-filters" rowSpacing={2}>
      <TextField
        placeholder={ro.search}
        value={search}
        fullWidth
        onChange={handleSearchChange}
        variant="standard"
        size="small"
        InputProps={{
          startAdornment: <Search className="search-icon" />,
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems={"center"}
          columnSpacing={4}
          rowSpacing={2}
        >
          {!employer && (
            <Grid item xs={12} sm={4} md={4}>
              <ItemsSelector
                label={ro.employer}
                value={filters.employerId}
                items={employers?.map((employer) => {
                  return { label: employer.name, value: employer.employerId };
                })}
                setValue={setEmployer}
                removeFilter={setEmployerNull}
                removeFilterDeleteIcon={
                  filters.employerId === undefined || filters.employerId === 0
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={employer ? 6 : 4}>
            <ItemsSelector
              label={ro.sortBy}
              value={filters.dataToSortBy ?? ""}
              items={jobsSortItems}
              setValue={setSortByFilter}
              removeFilter={removeSortByFilter}
              removeFilterDeleteIcon={filters.dataToSortBy === undefined}
            />
          </Grid>
          <Grid item xs={12} sm={employer ? 6 : 4}>
            <ItemsSelector
              label={ro.order}
              value={filters.descending ?? ""}
              items={sortOrderItems}
              setValue={setDescendingFilter}
              removeFilter={removeDescendingFilter}
              removeFilterDeleteIcon={filters.descending === undefined}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          columnSpacing={4}
          rowSpacing={2}
        >
          {!employer && (
            <Grid item xs={12} sm={4}>
              <DatePicker
                value={filters.firstUploadTime ?? null}
                onChange={(e) => getDateOnChange(e, true)}
                maxDate={
                  filters.secondUploadTime
                    ? new Date(filters.secondUploadTime)
                    : undefined
                }
                onAccept={setFirstUploadTimeFilter}
                mask="__.__.____"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {filters.firstUploadTime && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setFirstUploadTimeFilter(null);
                              }}
                            >
                              <CloseRounded color="error"></CloseRounded>
                            </IconButton>
                          )}
                          {params.InputProps?.endAdornment}
                        </Grid>
                      ),
                    }}
                    onChange={(e) => getDateOnChange(e, true)}
                    label={ro.after}
                    variant="standard"
                  />
                )}
              />
            </Grid>
          )}
          {!employer && (
            <Grid item xs={12} sm={4}>
              <DatePicker
                value={filters.secondUploadTime ?? null}
                onChange={(e) => getDateOnChange(e, false)}
                minDate={
                  filters.firstUploadTime
                    ? new Date(filters.firstUploadTime)
                    : undefined
                }
                onAccept={setSecondUploadTimeFilter}
                mask="__.__.____"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          {filters.secondUploadTime && (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setSecondUploadTimeFilter(null);
                              }}
                            >
                              <CloseRounded color="error"></CloseRounded>
                            </IconButton>
                          )}
                          {params.InputProps?.endAdornment}
                        </Grid>
                      ),
                    }}
                    onChange={(e) => getDateOnChange(e, false)}
                    label={ro.before}
                    variant="standard"
                  />
                )}
              />
            </Grid>
          )}
          {!employer && (
            <Grid item xs={12} sm={4}>
              <ItemsSelector
                label={ro.county}
                value={filters.county ?? ""}
                items={countiesSelectItems}
                setValue={setCountyFilter}
                removeFilter={removeCountyFilter}
                removeFilterDeleteIcon={filters.county === undefined}
              />
            </Grid>
          )}
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};
