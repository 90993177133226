export const homePath = "/";
export const loginPath = "/autentificare";
export const usersPath = "/grup-tinta";
export const userDetailsPath = "/utilizator/:userId";
export const newUserPath = "/utilizator-nou";
export const communicationPath = "/comunicari";
export const vocationalTrainingPath = "/formare-profesionala";
export const forgotPasswordPath = "/am-uitat-parola";
export const resetPasswordPath = "/utilizatori/resetare-parola";
export const grantContestPath = "/planafaceri/:userId";
export const verifyEmailPath = "/utilizatori/verificare-email/";
export const manageDocumentsPath = "/monitorizare/:tab/:userId";
export const administrationPath = "/administrare";
export const counselingPath = "/consiliere";
export const mediationPath = "/mediere/:tab/:id";
export const jobPostPath = "/anunt/:employerId/:jobId";
export const jobDetailsPath = "/detalii-anunt/:jobId";
export const modulesPath = "/module";
export const statisticsPath = "/statistici";
