export enum EducationLevel {
  InscrisProgramEducatie = 1,
  InscrisProgramFormare = 2,
  ISCED0 = 3,
  ISCED1 = 4,
  ISCED2 = 5,
  ISCED3 = 6,
  ISCED4 = 7,
  ISCED5 = 8,
  ISCED6 = 9,
  ISCED7 = 10,
  ISCED8 = 11,
}
