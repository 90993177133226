import { FC, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./ImageCarousel.scss";
import { useAppDispatch, useAppSelector } from "store";
import { selectFiles, selectLoadingFiles } from "store/selectors/fileSelectors";
import RestoreIcon from "@mui/icons-material/Restore";
import { resetFiles } from "store/slices/fileSlices";
import { CardMedia, Tooltip } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

export const ImageCarousel: FC<{
  newImages?: any | undefined;
  handleDelete?: Function;
  deletedFiles?: number[];
  displayOnly: boolean;
}> = ({ newImages, handleDelete, deletedFiles, displayOnly }) => {
  const imagesArray = useAppSelector(selectFiles);
  const [selectedImage, setSelectedImage] = useState<number>(0);

  const [images, setImages] = useState<
    {
      path: string;
      jobFileId: number | null;
    }[]
  >([]);

  const loading = useAppSelector(selectLoadingFiles);
  const dispatch = useAppDispatch();
  const handleNext = () => {
    if (selectedImage === images.length - 1) {
      setSelectedImage(0);
    } else {
      setSelectedImage(selectedImage + 1);
    }
  };

  const handlePrevious = () => {
    if (selectedImage === 0) {
      setSelectedImage(images.length - 1);
    } else {
      setSelectedImage(selectedImage - 1);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetFiles());
    };

    //eslint-disable-next-line
  }, []);
  //imagesArray comes from the store, and it represents the images that are already in the database
  useEffect(() => {
    let existingImages: any[] = [];
    if (imagesArray.length) {
      imagesArray?.forEach((img: any) => {
        if (img.files.isImage)
          existingImages.push({
            path: img.files.filePath,
            jobFileId: img.files.jobFileId,
            isImage: img.files.isImage,
          });
      });
    }
    if (newImages) {
      setImages(existingImages.concat(newImages));
    } else {
      setImages(existingImages);
    }
  }, [imagesArray, newImages]);

  useEffect(() => {
    if (selectedImage + 1 > images.length) {
      setSelectedImage(0);
    }
    //eslint-disable-next-line
  }, [images]);

  let uniqueImages = images.filter((item, index) => {
    return item.jobFileId
      ? images?.findIndex((i) => i.jobFileId === item.jobFileId) === index
      : item;
  });

  return (
    <div className="carousel-container">
      <div className="main-img-container">
        {uniqueImages[selectedImage]?.path && !loading && (
          <>
            <ArrowBackIcon
              className="back-arrow"
              onClick={() => {
                handlePrevious();
              }}
            />
            <CardMedia
              component="img"
              image={uniqueImages[selectedImage]?.path}
              className="main-image-carousel"
              sx={
                deletedFiles?.includes(uniqueImages[selectedImage]?.jobFileId!)
                  ? {
                      filter: "grayscale(100%)",
                    }
                  : { filter: "none" }
              }
            />
            <ArrowForwardIcon
              className="forward-arrow"
              onClick={() => {
                handleNext();
              }}
            />
          </>
        )}
        {loading && <div>Se incarcă...</div>}
        {!uniqueImages[0]?.path && !loading && <div>Nu există poze</div>}

        {!displayOnly &&
          (uniqueImages[selectedImage]?.jobFileId &&
          deletedFiles?.includes(uniqueImages[selectedImage]?.jobFileId!) ? (
            <Tooltip title="Restaurează imaginea">
              <RestoreIcon
                className="restore-image"
                onClick={() =>
                  handleDelete &&
                  handleDelete(uniqueImages[selectedImage]?.jobFileId)
                }
              />
            </Tooltip>
          ) : (
            uniqueImages[selectedImage]?.jobFileId && (
              <Tooltip title="Șterge imaginea">
                <CloseOutlined
                  className="delete-image"
                  onClick={() =>
                    handleDelete &&
                    handleDelete(uniqueImages[selectedImage]?.jobFileId)
                  }
                />
              </Tooltip>
            )
          ))}
      </div>
      <div className="images-container">
        {uniqueImages?.map(
          (item: any, index: number) =>
            item.isImage && (
              <img
                onClick={() => setSelectedImage(index)}
                src={item?.path}
                alt={item.files?.fileTitle}
                loading="lazy"
                className="each-image"
                style={{
                  border:
                    selectedImage === index ? "2px solid #1976d2" : "none",
                }}
                key={index}
              />
            )
        )}
      </div>
    </div>
  );
};
