import { FindInPage, ManageAccounts, PersonRemove } from "@mui/icons-material";
import { Button, ButtonGroup, Grid, Tooltip } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import ConfirmationDialog from "components/shared/confirmationDialog/ConfirmationDialog";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    deleteUser,
    selectCurrentUser,
    selectMediationPageLocalization,
    useAppDispatch,
    useAppSelector,
} from "store";
import { countiesSelectItems } from "store/models/selectItems";
import { employersDataSortSelectItems } from "store/models/selectItems/employersDataSortSelectItems";
import {
    selectEmployers,
    selectEmployersCount,
    selectEmployersFilters,
    selectEmployersLoading,
} from "store/selectors/mediationSelectors";
import { setEmployersFilters } from "store/slices/mediationSlice";
import { getFilteredPaginatedEmployers } from "store/thunks/mediationThunks";
import { isAdmin } from "utils/getUserType";
import "./EmployersTable.scss";

export const EmployersTable: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentUser = useAppSelector(selectCurrentUser);
    const employers = useAppSelector(selectEmployers);
    const filters = useAppSelector(selectEmployersFilters);
    const loading = useAppSelector(selectEmployersLoading);
    const totalCount = useAppSelector(selectEmployersCount);
    const [displayConfirmationDialog, setDisplayConfirmationDialog] =
        useState<boolean>(false);
    const [userSelectedId, setUserSelectedId] = useState<number>();
    const ro = useAppSelector(selectMediationPageLocalization);

    const getEmployers = () => {
        return dispatch(
            getFilteredPaginatedEmployers({
                filters: filters,
                token: currentUser?.jwtToken,
            })
        );
    };

    useEffect(() => {
        let promise = getEmployers();

        return () => promise.abort();
        //eslint-disable-next-line
    }, [filters]);

    const columns: GridColDef[] = [
        {
            field: "companyName",
            headerName: ro.companyNameCol,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "email",
            headerName: ro.emailCol,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "caen",
            headerName: ro.caenCol,
            minWidth: 50,
            flex: 1,
        },

        {
            field: "county",
            headerName: ro.countyCol,
            flex: 1,
            minWidth: 75,
            valueFormatter: (params: GridValueFormatterParams) => {
                return (
                    countiesSelectItems?.find((x) => x.value === params.value)?.label ??
                    ""
                );
            },
        },
        {
            field: "locality",
            headerName: ro.localityCol,
            flex: 1,
            minWidth: 75,
        },
        {
            field: "jobsCount",
            headerName: ro.jobsCountCol,
            flex: 1,
            minWidth: 50,
        },
        {
            field: "",
            headerName: ro.optionsCol,
            sortable: false,
            flex: 1,
            minWidth: 150,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title={ro.updateTooltip}>
                            <Button
                                className="update-employer-button"
                                color="secondary"
                                onClick={() => handleUpdateEmployerClick(params)}
                            >
                                <ManageAccounts />
                            </Button>
                        </Tooltip>
                        <Tooltip title={ro.seeJobsTooltip}>
                            <Button color="primary" onClick={() => goToEmployerJobs(params)}>
                                <FindInPage />
                            </Button>
                        </Tooltip>
                        {isAdmin(currentUser?.userType) && (
                            <Tooltip
                                color="error"
                                onClick={() => displayDeleteConfirmationDialog(params)}
                                title={ro.deleteTooltip}
                            >
                                <Button>
                                    <PersonRemove />
                                </Button>
                            </Tooltip>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleUpdateEmployerClick = (params: any) => {
        navigate("/utilizator/" + params.rowNode.id);
    };

    const goToEmployerJobs = (params: any) => {
        navigate("/mediere/joburi/" + params.rowNode.id);
    };

    const displayDeleteConfirmationDialog = (params: any) => {
        setUserSelectedId(params.row.employerId);
        setDisplayConfirmationDialog(true);
    };

    const confirmDialogAction = () => {
        dispatch(
            deleteUser({
                userId: userSelectedId!,
                token: currentUser?.jwtToken,
            })
        ).then(() => {
            getEmployers();
            setDisplayConfirmationDialog(false);
            setUserSelectedId(undefined);
        });
    };

    const closeDeleteConfirmationDialog = () => {
        setDisplayConfirmationDialog(false);
    };

    const handlePageChange = (page: number) => {
        dispatch(
            setEmployersFilters({
                ...filters,
                pageNumber: page,
            })
        );
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length === 0) {
            dispatch(
                setEmployersFilters({
                    ...filters,
                    dataToSortBy: undefined,
                    descending: false,
                    pageNumber: 0,
                })
            );
        } else {
            dispatch(
                setEmployersFilters({
                    ...filters,
                    dataToSortBy: employersDataSortSelectItems?.find(
                        (x) => x.field === newModel[0].field
                    )?.value,
                    descending: newModel[0].sort === "desc" ? true : false,
                    pageNumber: 0,
                })
            );
        }
    };

    return (
        <Grid id="employers-table">
            <DataGrid
                className="employers-data-grid"
                components={{
                    LoadingOverlay: DataLoadingComponent,
                }}
                loading={loading}
                page={filters.pageNumber}
                pageSize={filters.pageSize}
                rowsPerPageOptions={[filters.pageSize]}
                hideFooterSelectedRowCount={true}
                disableSelectionOnClick={true}
                paginationMode="server"
                localeText={{
                    noRowsLabel: "Fără rezultate",
                }}
                sx={{
                    "& .MuiDataGrid-row:hover": {
                        background: "aliceblue",
                        cursor: "pointer",
                    },
                    height: 650,
                    boxShadow: 3,
                }}
                rows={employers}
                columns={columns}
                onPageChange={handlePageChange}
                rowCount={totalCount}
                disableColumnMenu={true}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            ></DataGrid>
            {displayConfirmationDialog && (
                <ConfirmationDialog
                    display={displayConfirmationDialog}
                    message={ro.deleteConfirmation}
                    confirmAction={confirmDialogAction}
                    cancelAction={closeDeleteConfirmationDialog}
                />
            )}
        </Grid>
    );
};
