import { ApplicationStatus } from "store/models/enums/ApplicationStatus";

export const mapApplicationStatusToLabel = (
  applicationStatus: ApplicationStatus
) => {
  switch (applicationStatus) {
    case ApplicationStatus.Interested: {
      return "Interesat";
    }
    case ApplicationStatus.Uninterested: {
      return "Neinteresat";
    }
    case ApplicationStatus.Allocated: {
      return "Alocat";
    }
    case ApplicationStatus.JobNotAvailable: {
      return "Job indisponibil";
    }
    case ApplicationStatus.AllocatedToAnotherJob: {
      return "Alocat altui job";
    }
    default: {
      return "Interesat";
    }
  }
};
