import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig, TargetGroupFilesResponse } from "store";
import { ResponseMessageModel } from "store/models/ResponseMessageModel";
import { UserDocumentType } from "store/models/enums/UserDocumentType";
import { UserType } from "store/models/enums/UserType";
import { CompanyUserDetailsModel } from "store/models/targetGroups/CompanyUserDetailsModel";
import { ExpertUserModel } from "store/models/targetGroups/ExpertUserModel";
import { ExpertUsersPaginatedModel } from "store/models/targetGroups/ExpertUsersPaginatedModel";
import { RegisterExpertUserModel } from "store/models/targetGroups/RegisterExpertUserModel";
import { TargetUsersPaginatedModel } from "store/models/targetGroups/TargetUsersPaginatedModel";
import { UpdateUserModel } from "store/models/targetGroups/UpdateUserModel";
import { RegisterEmployerUserModel } from "store/models/user/RegisterEmployerUserModel";
import { UpdateEmployerUserModel } from "store/models/user/UpdateEmployerUserModel";
import { UserModel } from "store/models/user/UserModel";
import {
  UserDocumentsFilter,
  CompanyUsersFilter,
  ExpertUsersFilters,
} from "store/models/user/UserModel";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { hideOverlay, showOverlay } from "store/slices/overlayLoaderSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";
const axios = require("axios").default;

export const getAllExpertUsers = createAsyncThunk<
  //return type
  {
    response: ExpertUsersPaginatedModel;
    success: boolean;
    message: ResponseMessageModel;
  },
  { token?: string | null | undefined; filter: ExpertUsersFilters }
>(
  "targetGroup/getAllExpertUsers",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ token, filter }, thunkAPI) => {
    try {
      let users = await axios.get(baseUrl + "Users/GetAllExpertUsers", {
        params: filter,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return users.data;
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestGetAllCompanyUsers = createAsyncThunk<
  //return type
  TargetUsersPaginatedModel,
  { filter: CompanyUsersFilter; token?: string | null | undefined }
>(
  "targetGroup/getAllCompanyUsers",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: companyUsersFilter, token }, thunkAPI) => {
    try {
      let users = await axios.get(baseUrl + "Users/GetAllCompanyUsers", {
        params: companyUsersFilter,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return users.data.response;
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestUserDetails = createAsyncThunk<
  //return type

  ExpertUserModel | UserModel,
  //payload type
  { userId: number; token?: string | null },
  AppThunkConfig
>(
  "targetGroup/requestUserDetails",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userId, token }, thunkAPI) => {
    try {
      let user = await axios.get(baseUrl + "Users/GetUser", {
        params: {
          id: userId,
        },
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return user.data.response;
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const registerCompanyUser = createAsyncThunk<
  //return type
  {
    response: null;
    success: boolean;
    message: ResponseMessageModel;
  },
  //payload type
  { userData: CompanyUserDetailsModel; token: string | null | undefined },
  AppThunkConfig
>(
  "targetGroup/registerCompanyUser",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userData, token }, thunkAPI) => {
    try {
      let goodIndi: any = [];

      userData.indicators.forEach((indi: any) => {
        if (indi.name) {
          indi = `${indi.name}{${indi.isIncluded}}`;
          goodIndi.push(indi);
        }
      });

      var requestData = {
        ...userData,
        firstName: userData.firstName.trim(),
        lastName: userData.lastName.trim(),
        email: userData.email.trim(),
        numarTelefon: userData.numarTelefon,
        homeLocality: userData.homeLocality.trim(),
        homeAddress: userData.homeAddress.trim(),
        locality: userData.locality.trim(),
        adresa: userData.adresa.trim(),
        otherNationality: userData.otherNationality.trim(),
        entryDate: userData.entryDate,
        cnp: userData.cnp.trim(),
        region: userData.region.trim(),
        administrativeUnity: userData.administrativeUnity.trim(),
        indicators: goodIndi.join("/").length > 0 ? goodIndi.join("/") : null,
        age: parseInt(userData.age.toString(), 10),
        grade: parseInt(userData.grade.toString(), 10) ?? 0,
      };

      thunkAPI.dispatch(showOverlay());
      let { data } = await axios.post(
        baseUrl + "Users/RegisterCompanyUser",
        requestData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            data?.response?.message?.text ??
            "Utilizator înregistrat cu succces",
          severity: "success",
        })
      );
      return data;
    } catch (err: unknown) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const registerEmployerUser = createAsyncThunk<
  //return type
  { response: null; success: boolean; message: ResponseMessageModel },
  //payload type
  { userData: RegisterEmployerUserModel; token: string | null | undefined },
  AppThunkConfig
>(
  "targetGroup/registerEmployerUser",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userData, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      let form = new FormData();

      form.append("companyName", userData.companyName);
      form.append("email", userData.email);
      form.append("phoneNumber", userData?.phoneNumber ?? "");
      form.append("caen", userData?.caen ?? "");
      form.append("county", userData?.county?.toString() ?? "");
      form.append("locality", userData?.locality ?? "");
      form.append("street", userData?.street ?? "");
      form.append("companyDescription", userData?.companyDescription ?? "");
      form.append("cui", userData?.cui ?? "");
      form.append("fax", userData?.fax ?? "");
      form.append("website", userData?.website ?? "");
      form.append("zone", userData?.zone?.toString() ?? "");
      form.append("entryDate", userData?.entryDate ?? "");
      form.append("exitDate", userData?.exitDate ?? "");
      form.append("privateEntity", userData?.privateEntity?.toString() ?? "");
      form.append("imm", userData?.imm?.toString() ?? "");
      form.append("socialEntity", userData?.socialEntity?.toString() ?? "");
      form.append("publicEntity", userData?.publicEntity?.toString() ?? "");
      form.append("pocuEntity", userData?.pocuEntity?.toString() ?? "");

      if (userData?.jobPost) {
        form.append("jobPost.title", userData?.jobPost?.title ?? "");
        form.append(
          "jobPost.description",
          userData?.jobPost?.description ?? ""
        );
        form.append(
          "jobPost.uploadedByUserId",
          userData?.jobPost?.uploadedByUserId.toString() ?? ""
        );
        form.append(
          "jobPost.county",
          userData?.jobPost?.county?.toString() ?? ""
        );
        form.append("jobPost.locality", userData?.jobPost?.locality ?? "");
        form.append("jobPost.street", userData?.jobPost?.street ?? "");
        form.append(
          "jobPost.employmentRequirements",
          userData?.jobPost?.employmentRequirements ?? ""
        );
        form.append("jobPost.vacancy", userData?.jobPost?.vacancy ?? "");
        form.append(
          "jobPost.vacanciesNumber",
          userData?.jobPost?.vacanciesNumber
            ? userData?.jobPost?.vacanciesNumber?.toString()
            : ""
        );
        form.append(
          "jobPost.fileSubmissionDeadline",
          userData?.jobPost?.fileSubmissionDeadline ?? ""
        );
        if (
          userData?.jobPost?.jobFiles &&
          userData?.jobPost?.jobFiles.length > 0
        ) {
          userData.jobPost?.jobFiles?.forEach((file) => {
            form.append("jobPost.jobFiles", file);
          });
        }
      }
      let { data } = await axios.post(
        baseUrl + "Users/RegisterEmployerUser",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message: data?.message?.text ?? "Utilizator înregistrat cu succces",
          severity: data?.message?.messageType,
        })
      );
      return data;
    } catch (err: unknown) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const registerExpertUser = createAsyncThunk<
  //return type
  {
    response: RegisterExpertUserModel;
    success: boolean;
    message: ResponseMessageModel;
  },
  //payload type
  { userData: RegisterExpertUserModel; token: string | null | undefined },
  AppThunkConfig
>(
  "targetGroup/registerExpertUser",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userData, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      let { data } = await axios.post(
        baseUrl + "Users/RegisterExpertUser",
        userData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            data?.response?.message?.text ?? "Expert înregistrat cu succces",
          severity: "success",
        })
      );

      return data.response;
    } catch (err: unknown) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const updateUser = createAsyncThunk<
  //return type
  {
    response: UpdateUserModel;
    success: boolean;
    message: ResponseMessageModel;
  },
  //payload type
  { userData: UpdateUserModel; token: string | null | undefined },
  AppThunkConfig
>(
  "targetGroup/updateUser",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userData, token }, thunkAPI) => {
    try {
      let goodIndi: any = [];

      userData.indicators.forEach((indi: any) => {
        if (indi.name) {
          indi = `${indi.name}{${indi.isIncluded}}`;
          goodIndi.push(indi);
        }
      });
      userData.indicators =
        goodIndi.join("/").length > 0 ? goodIndi.join("/") : null;

      if (userData.userType === UserType.COMPANY_USER) {
        if (userData.grade?.toString() === "") {
          userData.grade = 0;
        }
        if (userData.age) userData.age = parseInt(userData.age.toString(), 10);
      }

      thunkAPI.dispatch(showOverlay());
      let { data } = await axios.post(baseUrl + "Users/UpdateUser", userData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            data?.response?.message?.text ?? "Utilizator actualizat cu succes",
          severity: "success",
        })
      );
      return data.response;
    } catch (err: unknown) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const updateEmployer = createAsyncThunk<
  //return type
  {
    response: null;
    success: boolean;
    message: ResponseMessageModel;
  },
  //payload type
  { userData: UpdateEmployerUserModel; token: string | null | undefined },
  AppThunkConfig
>(
  "targetGroup/updateEmployer",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userData, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      let { data } = await axios.post(
        baseUrl + "Users/UpdateEmployer",
        userData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message:
            data?.response?.message?.text ?? "Utilizator actualizat cu succes",
          severity: "success",
        })
      );
      return data.response;
    } catch (err: unknown) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const requestUserDocuments = createAsyncThunk<
  //return type
  TargetGroupFilesResponse,
  //payload type
  { filter: UserDocumentsFilter; token?: string | null },
  AppThunkConfig
>(
  "targetGroup/requestUserDocuments",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ filter: userDocumentsFilter, token }, thunkAPI) => {
    try {
      let result = await axios.get(baseUrl + "UserDocuments/GetUserDocuments", {
        params: {
          pageNumber: userDocumentsFilter.pageNumber.toString(),
          columnToSortBy: userDocumentsFilter.columnToSortBy,
          sortingOrder: userDocumentsFilter.sortingOrder,
          userDocumentType: userDocumentsFilter.userDocumentType,
          userId: userDocumentsFilter.userId.toString(),
        },
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data.response;
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const downloadUserDocument = createAsyncThunk<
  //return type
  any,
  //payload type
  { fileId: number; token?: string | null },
  AppThunkConfig
>(
  "targetGroup/downloadUserDocument",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ fileId, token }, thunkAPI) => {
    try {
      let result = await axios.get(baseUrl + "UserDocuments/DownloadDocument", {
        params: {
          fileId: fileId,
        },
        responseType: "blob",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteUserDocument = createAsyncThunk<
  //return type
  any,
  //payload type
  {
    requestData: { userDocId: number };
    token: string | null | undefined;
  },
  AppThunkConfig
>(
  "targetGroup/deleteUserDocument",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ requestData: { userDocId }, token }, thunkAPI) => {
    try {
      const form = new FormData();
      form.append("userDocId", userDocId.toString());
      let result = await axios.post(
        baseUrl + "UserDocuments/DeleteDocument",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message?.text ?? "Document sters!",
          severity: "success",
        })
      );
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const uploadUserDocument = createAsyncThunk<
  //return type
  any,
  //payload type
  {
    requestData: {
      files: File[];
      userId: string | undefined;
      userDocumentType: UserDocumentType;
    };
    token: string | null | undefined;
  },
  AppThunkConfig
>(
  "targetGroup/uploadUserDocument",
  async (
    { requestData: { files, userDocumentType, userId }, token },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      files.forEach((file: string | Blob) => {
        form.append("Files", file);
      });
      if (userId) form.append("UserId", userId.toString());

      form.append("UserDocumentType", userDocumentType.toString());
      let result = await axios.post(
        baseUrl + "UserDocuments/UploadUserDocuments",
        form,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message ?? "Document incarcat!",
          severity: "success",
        })
      );
    } catch (err: any) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const deleteUser = createAsyncThunk<
  //return type
  | {
      response: null;
      success: boolean;
      message: ResponseMessageModel;
    }
  | any,
  //payload type
  { userId: number; token: string | undefined },
  AppThunkConfig
>(
  "targetGroup/deleteUser",
  //signal is to be used later, now just leave it there to avoid lint warnings
  async ({ userId, token }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showOverlay());
      const form = new FormData();
      form.append("id", userId.toString());
      let result = await axios.post(baseUrl + "Users/DeleteUser", form, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      thunkAPI.dispatch(hideOverlay());
      thunkAPI.dispatch(
        addAppNotification({
          message: result?.data?.message?.text ?? "Utilizator sters!",
          severity: "success",
        })
      );
    } catch (err: unknown) {
      thunkAPI.dispatch(hideOverlay());
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const generateUserForm = createAsyncThunk<
  any,
  { token: string | undefined; userId: number },
  AppThunkConfig
>("targetGroup/generateUserForm", async ({ token, userId }, thunkAPI) => {
  try {
    let result = await axios.get(
      baseUrl + "GenerateDocument/GenerateUserForm",
      {
        params: {
          userId: userId,
        },
        withCredentials: true,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let blob = new Blob([result.data], {
      type: result.headers["content-type"],
    });
    let url = window.URL.createObjectURL(blob);

    let fileLink = document.createElement("a");
    fileLink.href = url;

    let filename = "";
    let disposition = result.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
  } catch (err: unknown) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});

export const generateTargetGroupExcel = createAsyncThunk<
  any,
  { token: string | undefined; filters: CompanyUsersFilter },
  AppThunkConfig
>(
  "targetGroup/generateTargetGroupExcel",
  async ({ token, filters }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateTargetGroupExcel",
        {
          params: filters,
          withCredentials: true,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: unknown) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const generateIndicatorsRegister = createAsyncThunk<
  any,
  { token: string | undefined; filters: CompanyUsersFilter },
  AppThunkConfig
>(
  "generateDocument/GenerateIndicatorsRegister",
  async ({ token, filters }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateIndicatorsRegister",
        {
          params: filters,
          withCredentials: true,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);

export const generateTargetGroupRegister = createAsyncThunk<
  any,
  { token: string | undefined; filters: CompanyUsersFilter },
  AppThunkConfig
>(
  "targetGroup/GenerateTargetGroupRegisterr",
  async ({ token, filters }, thunkAPI) => {
    try {
      let result = await axios.get(
        baseUrl + "GenerateDocument/GenerateTargetGroupRegister",
        {
          params: filters,
          withCredentials: true,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let blob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      let url = window.URL.createObjectURL(blob);

      let fileLink = document.createElement("a");
      fileLink.href = url;

      let filename = "";
      let disposition = result.headers["content-disposition"];

      if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } catch (err: any) {
      let errorMessage = getAxiosErrorMessage(err);
      if (errorMessage) {
        thunkAPI.dispatch(
          addAppNotification({
            message: errorMessage,
            severity: "error",
          })
        );
        return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
      } else {
        throw err;
      }
    }
  }
);
