import { EmployersDataSort } from "../enums/EmployersDataSort";

export const employersDataSortSelectItems=[
  { field:'email', value:EmployersDataSort.Email, label:'Email' },
  { field:'companyName', value:EmployersDataSort.CompanyName, label:'Nume companie' },
  { field:'companyDescription', value:EmployersDataSort.CompanyDescription, label:'Descriere companie' },
  { field:'county', value:EmployersDataSort.County, label:'Județ' },
  { field:'locality', value:EmployersDataSort.Locality, label:'Localitate' },
  { field:'jobsCount', value:EmployersDataSort.JobsCount, label:'Număr anunțuri' },
  { field:'createdAt', value:EmployersDataSort.CreatedAt, label:'Data înregistrării' },
  { field:'caen', value:EmployersDataSort.Caen, label:'CAEN' },
]