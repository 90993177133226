import { Button, ButtonGroup, Container, Grid, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import {
  deleteExamResultsDoc,
  downloadExamResultsDoc,
  requestExamResultsDocs,
  selectCurrentUser,
  selectVocationalExamResultsDocs,
  selectVocationalExamResultsDocsRowCount,
  selectExamResultsListIsLoading,
  uploadExamResultsDocs,
  useAppDispatch,
  useAppSelector,
  selectVocationalFileIsDownloading,
  selectCourseSeries,
  selectExamResultsFilters,
  getAssignedSeries,
  requestCourseSeries,
  generateAdeverinta,
  selectGeneratingAdeverinta,
  selectVocationalTrainingPageLocalization,
} from "store";
import { Add, Delete } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { FileUploadPopout } from "components";
import "./VocationalTrainingExamResultsView.scss";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import { UserType } from "store/models/enums/UserType";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { dateTimeFormatOptions } from "utils";
import { isAdmin, isCompany, isExpert } from "utils/getUserType";
import { ItemsSelector } from "components/shared/itemsSelector/ItemsSelector";
import {
  setExamResultsFilters,
  setVocationalTrainingExamResultsResponse,
} from "store/slices/vocationalTrainingSlice";
import { SeriesType } from "store/models/enums/SeriesType";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";

export const VocationalTrainingExamResultsView: FC = () => {
  const rowsPerPage = 10;
  const dispatch = useAppDispatch();
  const examResultsDocs = useAppSelector(selectVocationalExamResultsDocs);
  const ro = useAppSelector(selectVocationalTrainingPageLocalization);
  const examResultsDocsRowCount = useAppSelector(
    selectVocationalExamResultsDocsRowCount
  );
  const filters = useAppSelector(selectExamResultsFilters);
  const currentUser = useAppSelector(selectCurrentUser);
  const generatingAdeverinta = useAppSelector(selectGeneratingAdeverinta);
  const examResultsListIsLoading = useAppSelector(
    selectExamResultsListIsLoading
  );
  const series = useAppSelector(selectCourseSeries);
  const vocationalFileIsDownloading = useAppSelector(
    selectVocationalFileIsDownloading
  );
  const [displayUploadDialog, setDisplayUploadDialog] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(
      requestCourseSeries({
        type: SeriesType.Exam,
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      if (isCompany(currentUser?.userType)) {
        dispatch(
          getAssignedSeries({
            token: currentUser?.jwtToken,
          })
        );
      }
    });
    return () => {
      dispatch(
        setExamResultsFilters({
          pageNumber: 0,
          examSeriesId: 0,
          sortingOrder: false,
          columnToSortBy: DataGridColumnNames.UploadTime,
        })
      );
      dispatch(setVocationalTrainingExamResultsResponse([]));
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filters.examSeriesId && filters.examSeriesId !== 0) {
      let promise = dispatch(
        requestExamResultsDocs({
          filter: filters,
          token: currentUser?.jwtToken,
        })
      );

      return () => promise.abort();
    }

    //eslint-disable-next-line
  }, [filters]);

  const handlePageChange = (pageNumber: any) => {
    dispatch(
      setExamResultsFilters({
        ...filters,
        pageNumber: pageNumber,
      })
    );
  };

  const onFilesUpload = (
    files: Array<File>,
    removeAllFilesCallback: Function
  ) => {
    if (files.length > 0) {
      dispatch(
        uploadExamResultsDocs({
          files: files,
          seriesId: filters.examSeriesId,
          token: currentUser?.jwtToken,
        })
      ).then(() => {
        removeAllFilesCallback();
        dispatch(
          requestExamResultsDocs({
            filter: filters,
            token: currentUser?.jwtToken,
          })
        );
      });
    }
  };

  const columns: GridColDef[] = [
    { field: "fileTitle", headerName: ro.docNameCol, flex: 1, minWidth: 150 },
    {
      field: "uploadTime",
      headerName: ro.uploadTimeCol,
      minWidth: 150,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
        }
      },
    },
    {
      field: "size",
      headerName: ro.sizeCol,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let sizeToBeDisplayed = params.value.toString().substring(0, 4);
          return sizeToBeDisplayed + " MB";
        }
      },
    },
    {
      field: "author",
      headerName: ro.authorCol,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "",
      headerName: ro.optionsCol,
      sortable: false,
      flex: 1,
      minWidth: 150,
      align: "right",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ButtonGroup variant="contained" size="small">
            <Tooltip title={ro.downloadFileTooltip}>
              <Button
                disabled={vocationalFileIsDownloading}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    downloadExamResultsDoc({
                      fileId: params.row.fileId,
                      token: currentUser?.jwtToken,
                    })
                  );
                }}
              >
                <DownloadIcon />
              </Button>
            </Tooltip>
            {currentUser?.userType !== UserType.COMPANY_USER && (
              <Tooltip title={ro.deleteFileTooltip}>
                <Button
                  disabled={vocationalFileIsDownloading}
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      deleteExamResultsDoc({
                        docId: params.row.vocDocumentId,
                        token: currentUser?.jwtToken,
                      })
                    ).then(() => {
                      dispatch(
                        requestExamResultsDocs({
                          filter: filters,
                          token: currentUser?.jwtToken,
                        })
                      );
                    });
                  }}
                >
                  <Delete />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length === 0) {
      dispatch(
        setExamResultsFilters({
          ...filters,
          columnToSortBy: undefined,
          sortingOrder: false,
          pageNumber: 0,
        })
      );
    } else {
      dispatch(
        setExamResultsFilters({
          ...filters,
          columnToSortBy: mapColumnNameToEnum(newModel[0].field),
          sortingOrder: newModel[0].sort === "desc" ? true : false,
          pageNumber: 0,
        })
      );
    }
  };

  const handleClose = () => {
    setDisplayUploadDialog(false);
  };

  const handleSelectSerie = (e: any) => {
    dispatch(
      setExamResultsFilters({
        ...filters,
        pageNumber: 0,
        examSeriesId: e.target.value,
      })
    );
  };

  const handleGenerateAdeverinta = () => {
    dispatch(
      generateAdeverinta({
        seriesId: filters.examSeriesId,
        token: currentUser?.jwtToken,
      })
    );
  };

  return (
    <Container
      id="vocational-training-exam-results-view"
      maxWidth="lg"
      sx={{ minHeight: "inherit" }}
      disableGutters
    >
      <Grid
        container
        sx={{
          minHeight: "inherit",
          p: {
            xs: 1,
            md: 2,
          },
        }}
        alignItems="stretch"
      >
        <Grid container justifyContent="space-between" alignItems="end">
          <Grid item xs={6} container justifyContent="flex-start">
            <Grid item xs={5}>
              <ItemsSelector
                value={filters.examSeriesId}
                label={ro.series}
                disabled={
                  !(
                    isExpert(currentUser?.userType) ||
                    isAdmin(currentUser?.userType)
                  )
                }
                setValue={handleSelectSerie}
                removeFilterDeleteIcon={true}
                removeFilter={handleSelectSerie}
                items={series?.map((serie) => {
                  return { label: serie.seriesName, value: serie.seriesId };
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end" columnGap={2}>
            {!isCompany(currentUser?.userType) && (
              <Grid item>
                <Button
                  disabled={filters.examSeriesId === 0}
                  variant="contained"
                  startIcon={<Add />}
                  color="success"
                  onClick={() => {
                    setDisplayUploadDialog(true);
                  }}
                >
                  {ro.addBtn}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                disabled={examResultsDocsRowCount === 0 || generatingAdeverinta}
                className="generate-adeverinta"
                variant="contained"
                color="secondary"
                onClick={handleGenerateAdeverinta}
              >
                {ro.generateBtn}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {displayUploadDialog && (
          <FileUploadPopout
            showSize={true}
            uploadOnButtonCallback={onFilesUpload}
            handleClose={handleClose}
            displayDialog={displayUploadDialog}
          />
        )}

        <Grid
          item
          xs={12}
          style={{ marginTop: "10px" }}
          className="exam-results-table"
        >
          <DataGrid
            components={{
              LoadingOverlay: DataLoadingComponent,
            }}
            loading={examResultsListIsLoading}
            hideFooterPagination={examResultsListIsLoading}
            rows={examResultsDocs}
            columns={columns}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            paginationMode="server"
            rowCount={examResultsDocsRowCount}
            onPageChange={handlePageChange}
            page={filters.pageNumber}
            hideFooterSelectedRowCount={true}
            disableSelectionOnClick={true}
            sx={{
              "& .MuiDataGrid-row:hover": {
                background: "aliceblue",
                cursor: "pointer",
              },
              height: 630,
              boxShadow: 3,
              maxWidth: "100vw",
            }}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            disableColumnMenu={true}
          ></DataGrid>
        </Grid>
      </Grid>
    </Container>
  );
};
