import { DisadvantagedPerson } from "../enums/DisadvantagedPerson";

export const disadvantagedPersonSelectItems = [
  {
    value: DisadvantagedPerson.Yes,
    label: "Da",
  },
  {
    value: DisadvantagedPerson.No,
    label: "Nu",
  },
  {
    value: DisadvantagedPerson.NoBusyPersonsHome,
    label: "Participanți care trăiesc în gospodării fără persoane ocupate",
  },
  {
    value: DisadvantagedPerson.WithChildrenHome,
    label:
      "Participanți care trăiesc în godpodării fără persoane ocupate cu copii aflați în întreținere",
  },
  {
    value: DisadvantagedPerson.UniqueParentWithChildrenHome,
    label:
      "Participanți care trăiesc în gospodării alcătuite dintr-un părinte unic cu copil aflat în întrținere",
  },
];
