import { Container, Grid, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
import "./VocationalTrainingPage.scss";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import locale from "date-fns/locale/ro";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { VocationalTrainingExamResultsView } from "./vocational-training-exam-results-view";
import { VocationalTrainingExtraFiles } from "./vocational-training-extra-files";
import { SeriesView } from "../../components/vocational-training";
import { SeriesType } from "store/models/enums/SeriesType";
import { isAdmin, isExpert } from "utils/getUserType";
import { selectCurrentUser, selectVocationalTrainingPageLocalization, useAppSelector } from "store";

export const VocationalTrainingPage: FC = () => {
    const currentUser = useAppSelector(selectCurrentUser);
    const ro = useAppSelector(selectVocationalTrainingPageLocalization);
    const [activeTab, setActiveTab] = useState<number>((isExpert(currentUser?.userType) || isAdmin(currentUser?.userType)) ? 0 : 2);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };


    return (
        <Container
            id="vocational-training-page"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <Grid
                    container
                    sx={{
                        minHeight: "inherit",
                    }}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Grid item xs="auto" className="tabs">
                            <Tabs value={activeTab} onChange={handleChange}>
                                {(isExpert(currentUser?.userType) || isAdmin(currentUser?.userType)) ? <Tab label={ro.courseSeries} /> : <Tab sx={{ display: 'none' }} />}
                                {(isExpert(currentUser?.userType) || isAdmin(currentUser?.userType)) ? <Tab label={ro.examSeries} /> : <Tab sx={{ display: 'none' }} />}
                                <Tab label={ro.examResults} />
                                <Tab label={ro.vocTrain} />
                            </Tabs>
                        </Grid>
                        <Grid item flexGrow={1}>
                            {activeTab === 0 && <SeriesView type={SeriesType.Course} />}
                            {activeTab === 1 && <SeriesView type={SeriesType.Exam} />}
                            {activeTab === 2 && <VocationalTrainingExamResultsView />}
                            {activeTab === 3 && <VocationalTrainingExtraFiles />}
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Container>
    );
};
