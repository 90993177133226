import { Button, Container, Divider, Grid } from "@mui/material";
import { FileUploadComponent, FormikCheckBox, FormikDatePicker, FormikSelectSimple, FormikTextArea, FormikTextField } from "components";
import React, { FC } from "react";
import { countiesSelectItems, userTypeSelectItems, zoneSelectItems } from "store/models/selectItems";

export const EmployerUserView: FC<{ canEdit: boolean, isNewUser: boolean, setFieldValue: any }> = ({ canEdit, isNewUser, setFieldValue }) => {
  return (
    <Container id="employer-user-view"
      maxWidth="md"
      sx={{ minHeight: "inherit" }}
      disableGutters>
      <Grid container sx={{
        minHeight: "inherit",
        p: {
          xs: 1,
        },
      }}
        alignItems="stretch">
        <Grid
          item
          xs={12}
          container
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} className="divider">
            <Divider textAlign="left" > Detalii angajator </Divider>
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="email"
              label="Email"
              readOnly={!isNewUser}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="companyName"
              label="Denumire companie"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="phoneNumber"
              label="Număr de telefon"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="caen"
              label="Cod CAEN"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikSelectSimple
              id="userType"
              name="userType"
              label="Tipul utilizatorului"
              items={userTypeSelectItems}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikSelectSimple
              name="county"
              items={countiesSelectItems}
              label="Județ"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="locality"
              label="Localitate"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="street"
              label="Strada"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              name="companyDescription"
              label="Descrierea companiei"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="cui"
              label="CUI"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="fax"
              label="Fax"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="website"
              label="Website"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikSelectSimple
              name="zone"
              label="Mediul"
              items={zoneSelectItems}
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDatePicker
              name="entryDate"
              label="Data intrării în operațiune"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikDatePicker
              name="exitDate"
              label="Data ieșirii din operațiune"
              readOnly={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikCheckBox
              name="privateEntity"
              label="Entitate de drept privat"
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikCheckBox
              name="imm"
              label="IMM, inclusiv  microîntreprinderi"
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikCheckBox
              name="socialEntity"
              label="Entitate de economie socială"
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikCheckBox
              name="publicEntity"
              label="Entitate de drept public"
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikCheckBox
              name="pocuEntity"
              label="Entitate nou creată prin intervențiile POCU"
              disabled={!canEdit}
            />
          </Grid>
          {isNewUser && <React.Fragment> <Grid item xs={12} className="divider">
            <Divider textAlign="left" > Detalii job </Divider>
          </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="job.jobTitle"
                label="Titlu"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="job.vacancy"
                label="Post de lucru vacant"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextArea
                name="job.jobDescription"
                rows={3}
                label="Descriere"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikSelectSimple
                name="job.jobCounty"
                items={countiesSelectItems}
                label="Județ"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="job.jobLocality"
                label="Localitate"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="job.jobStreet"
                label="Strada"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField
                name="job.employmentRequirements"
                label="Condiții de angajare/Oferte la angajare"
                readOnly={!canEdit}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikTextField
                name="job.vacanciesNumber"
                type="number"
                label="Nr. locuri de muncă vacante"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikDatePicker
                name="job.fileSubmissionDeadline"
                label="Termen limită de depunere a dosarului"
                readOnly={!canEdit}
              />
            </Grid>
            <Grid item xs={12}>
              <FileUploadComponent fileLimit={5} onFilesChange={(files: any) => setFieldValue('job.jobFiles', files)} />
            </Grid>
          </React.Fragment>}
          {canEdit && <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" type="submit">Salvează</Button>
          </Grid>}
        </Grid>
      </Grid>
    </Container>
  )
}