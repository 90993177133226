import { RootState } from "store";
import {
  AllocationsFiltersModel,
  EmployerModel,
  EmployersFiltersModel,
  JobPostDetailsModel,
  JobPostModel,
  JobPostsFiltersModel,
} from "store/models/mediation";
import { AllocationModel } from "store/models/mediation/AllocationModel";
import { ApplicationModel } from "store/models/mediation/ApplicationModel";
import { ApplicationsFiltersModel } from "store/models/mediation/ApplicationsFiltersModel";
import { EmployerNameModel } from "store/models/mediation/EmployerNameModel";
import { JobTitleModel } from "store/models/mediation/JobTitleModel";
import { UnallocatedCompaniesFiltersModel } from "store/models/mediation/UnallocatedCompaniesFiltersModel";
import { UnallocatedCompanyModel } from "store/models/mediation/UnallocatedCompanyModel";

export const selectEmployers = (state: RootState): EmployerModel[] =>
  state.mediation.employers?.map((employer: EmployerModel) => {
    return {
      ...employer,
      id: employer.employerId,
    };
  });
export const selectEmployersFilters = (
  state: RootState
): EmployersFiltersModel => state.mediation.employersFilters;
export const selectEmployersLoading = (state: RootState): boolean =>
  state.mediation.employersLoading;
export const selectEmployersCount = (state: RootState): number =>
  state.mediation.employersCount;
export const selectEmployersList = (state: RootState): any[] =>
  state.mediation.employers?.map((employer: EmployerModel) => {
    return {
      id: employer.employerId,
      email: employer.email,
      companyName: employer.companyName,
    };
  });

export const selectJobs = (state: RootState): JobPostModel[] =>
  state.mediation.jobs;
export const selectJobsFilters = (state: RootState): JobPostsFiltersModel =>
  state.mediation.jobPostsFilters;
export const selectJobsLoading = (state: RootState): boolean =>
  state.mediation.jobsLoading;

export const selectEmployersNames = (state: RootState): EmployerNameModel[] =>
  state.mediation.employersNames;
export const selectEmployersNamesLoading = (state: RootState): boolean =>
  state.mediation.employersNamesLoading;

export const selectJobDetails = (
  state: RootState
): JobPostDetailsModel | undefined => state.mediation.jobDetails;
export const selectJobDetailsLoading = (state: RootState): boolean =>
  state.mediation.jobLoading;
export const selectJobsTotalPages = (state: RootState): number =>
  state.mediation.totalPagesJobs;

export const selectSelectedCompany = (state: RootState): number | undefined =>
  state.mediation.selectedCompanyId;

export const selectCompanies = (state: RootState): UnallocatedCompanyModel[] =>
  state.mediation.companies;
export const selectCompaniesLoading = (state: RootState): boolean =>
  state.mediation.companiesLoading;

export const selectPaginatedUnallocatedCompaniesFilters = (
  state: RootState
): UnallocatedCompaniesFiltersModel =>
  state.mediation.paginatedUnallocatedCompaniesFilters;
export const selectAllocationsFilters = (
  state: RootState
): AllocationsFiltersModel => state.mediation.allocationsFilters;

export const selectTotalPages = (state: RootState): number =>
  state.mediation.totalPages;

export const selectGeneratingArchive = (state: RootState): boolean =>
  state.mediation.generatingArchive;
export const selectAllocations = (state: RootState): AllocationModel[] =>
  state.mediation.allocations;
export const selectAllocationsTotalCount = (state: RootState): number =>
  state.mediation.allocationsTotalCount;
export const selectAllocationsTotalPages = (state: RootState): number =>
  state.mediation.allocationsTotalPages;
export const selectAllocationsLoading = (state: RootState): boolean =>
  state.mediation.allocationsLoading;
export const selectApplications = (state: RootState): ApplicationModel[] =>
  state.mediation.applications;
export const selectApplicationsTotalCount = (state: RootState): number =>
  state.mediation.applicationsTotalCount;
export const selectApplicationsTotalPages = (state: RootState): number =>
  state.mediation.applicationsTotalPages;
export const selectApplicationsLoading = (state: RootState): boolean =>
  state.mediation.applicationsLoading;
export const selectApplicationsFilters = (
  state: RootState
): ApplicationsFiltersModel => state.mediation.applicationsFilters;
export const selectJobsWithAllocations = (state: RootState): JobTitleModel[] =>
  state.mediation.jobsWithAllocations;
export const selectJobsWithAllocationsItems = (
  state: RootState
): { label: string; value: any; employerName: string }[] =>
  state.mediation.jobsWithAllocations?.map((value) => {
    return {
      label: value.title,
      value: value.jobPostId,
      employerName: value.employerName,
    };
  });
// export const selectJobsWithAllocationsLoading = (state:RootState):boolean=>state.mediation.jobsWithAllocationsLoading;
export const selectJobsWithApplications = (state: RootState): JobTitleModel[] =>
  state.mediation.jobsWithApplications;
export const selectJobsWithApplicationsItems = (
  state: RootState
): { label: string; value: any; employerName: string }[] =>
  state.mediation.jobsWithApplications?.map((value) => {
    return {
      label: value.title,
      value: value.jobPostId,
      employerName: value.employerName,
    };
  });

export const selectLoadingJobsWithAllocations = (state: RootState): boolean =>
  state.mediation.jobsWithAllocationsLoading;
