import { County } from "store/models/enums/County";

export const ConvertCountyToLabel = (county: County) => {
  switch (county) {
    case County.ALBA:
      return "Alba";
    case County.CLUJ:
      return "Cluj";
    case County.SIBIU:
      return "Sibiu";
    case County.MURES:
      return "Mureș";
    case County.SATU_MARE:
      return "Satu Mare";
    case County.MARAMURES:
      return "Maramureș";
    case County.BIHOR:
      return "Bihor";
    case County.SALAJ:
      return "Sălaj";
    case County.COVASNA:
      return "Covasna";
    case County.BISTRITA_NASAUD:
      return "Bistrița Năsăud";
    default: {
      return "N/A";
    }
  }
};
