import { Button, ButtonGroup, Container, Grid, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridValueFormatterParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import DownloadIcon from "@mui/icons-material/Download";
import { FileUploadPopout } from 'components';
import { FC, useEffect, useState } from 'react';
import { Add, Delete } from '@mui/icons-material';
import { deleteAnexa1Phase2Doc, downloadAnexa1Phase2Doc, requestAnexa1Phase2Documents, selectAnexa1Phase2Documents, selectAnexa1Phase2DocumentsCount, selectAnexa1Phase2SortModel, selectCurrentUser, selectGrantApproved, selectGrantContestPageLocalization, selectUserAnexa1Phase2DocumentsListIsLoading, selectUserIsDownloadingAnexa1Phase2File, uploadAnexa1Phase2UserDocument, useAppDispatch, useAppSelector } from 'store';
import { DataGridColumnNames } from 'store/models/enums/DataGridColumnNames';
import { mapColumnNameToEnum } from 'utils/mapColumnNametoEnum';
import { setAnexa1Phase2Documents, setGrantSortModel } from 'store/slices/grantContestSlice';
import "./Annexe1Component.scss";
import DataLoadingComponent from 'components/shared/dataLoadingComponent/DataLoadingComponent';
import { UserType } from 'store/models/enums/UserType';
import { dateTimeFormatOptions } from 'utils';

export const Annexe1Component: FC<{ selectedUserId: number | null | undefined }> = ({ selectedUserId }) => {
    const dispatch = useAppDispatch();
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const currentUser = useAppSelector(selectCurrentUser);
    const rowsPerPage = 10;
    const userIsDownloadingAnexa1Phase2File = useAppSelector(selectUserIsDownloadingAnexa1Phase2File);
    const anexa1Phase2SortModel = useAppSelector(selectAnexa1Phase2SortModel);
    const anexa1Phase2Documents = useAppSelector(selectAnexa1Phase2Documents);
    const userAnexa1Phase2DocumentsListIsLoading = useAppSelector(selectUserAnexa1Phase2DocumentsListIsLoading);
    const anexa1Phase2DocumentsCount = useAppSelector(selectAnexa1Phase2DocumentsCount);
    const grantApproved = useAppSelector(selectGrantApproved);
    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false);

    const canAddDoc = currentUser?.userType !== UserType.COMPANY_USER ? ((selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) ? true : false) : true;
    const localization = useAppSelector(selectGrantContestPageLocalization);

    const handleRequestAnexa1Phase2Docs = (userId: number | null | undefined, pageNumber: number, columnToSortBy: DataGridColumnNames, sortingOrder: boolean, token: string | undefined) => {
        dispatch(requestAnexa1Phase2Documents({
            filter: {
                userId: userId,
                pageNumber: pageNumber,
                columnToSortBy: columnToSortBy,
                sortingOrder: sortingOrder,
            },
            token: token,
        }));
    };


    useEffect(() => {
        setCurrentPageNumber(0);
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            handleRequestAnexa1Phase2Docs(selectedUserId, 0, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);

        } else {
            dispatch(setAnexa1Phase2Documents({
                anexa1documents: [],
                rowCount: 0,
            }))
        }
        //eslint-disable-next-line
    }, [selectedUserId]);

    useEffect(() => {
        let column = mapColumnNameToEnum(anexa1Phase2SortModel.columnToSortBy);
        let sort = false;
        if (anexa1Phase2SortModel.sortingOrder === "asc") sort = true;
        if (selectedUserId !== null && selectedUserId !== undefined && selectedUserId !== 0) {
            handleRequestAnexa1Phase2Docs(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);
        }
        //eslint-disable-next-line
    }, [currentPageNumber]);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPageNumber(pageNumber);
    }

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                uploadAnexa1Phase2UserDocument({
                    requestData: {
                        companyUserId: selectedUserId!,
                        files: files
                    },
                    token: currentUser?.jwtToken
                })
            ).then(() => {
                removeAllFilesCallback();
                let column = mapColumnNameToEnum(anexa1Phase2SortModel.columnToSortBy);
                let sort = false;
                if (anexa1Phase2SortModel.sortingOrder === "asc") sort = true;
                handleRequestAnexa1Phase2Docs(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);
                setCurrentPageNumber(0);
            }
            );
        }
    };


    const columns: GridColDef[] = [
        { field: "fileTitle", headerName: localization.phase2Tab.annexeTables.fileNameCol, flex: 1, minWidth: 150 },
        {
            field: "uploadTime",
            headerName: localization.phase2Tab.annexeTables.uploadTimeCol,
            minWidth: 150,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let newDate = new Date(params.value as string);
                    return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
                }
            },
        },
        {
            field: "fileSize",
            headerName: localization.phase2Tab.annexeTables.fileSizeCol,
            flex: 1,
            minWidth: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    let sizeToBeDisplayed = params.value
                        .toString()
                        .substring(0, 4);
                    return sizeToBeDisplayed + " MB";
                }
            },
        },
        {
            field: "uploadedBy",
            headerName: localization.phase2Tab.annexeTables.uploadedByCol,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: localization.phase2Tab.annexeTables.optionsCol,
            sortable: false,
            flex: 1,
            align: "right",
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <Tooltip title={localization.phase2Tab.annexeTables.downloadFileTooltip}>
                            <Button
                                disabled={userIsDownloadingAnexa1Phase2File}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                        downloadAnexa1Phase2Doc({ fileId: params.row.fileId, token: currentUser?.jwtToken })
                                    );
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {!grantApproved &&
                            <Tooltip title={localization.phase2Tab.annexeTables.deleteFileTooltip}>
                                <Button
                                    disabled={userIsDownloadingAnexa1Phase2File}
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            deleteAnexa1Phase2Doc({
                                                docId: params.row.documentId,
                                                token: currentUser?.jwtToken,
                                            })
                                        ).then(() => {
                                            handleRequestAnexa1Phase2Docs(selectedUserId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
                                        });
                                    }}
                                >
                                    <Delete />
                                </Button>
                            </Tooltip>}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length > 0) {
            dispatch(setGrantSortModel({
                columnToSortBy: newModel[0].field,
                sortingOrder: newModel[0].sort
            }));
            let column = mapColumnNameToEnum(newModel[0].field);
            let sort = false;
            if (newModel[0].sort === "asc") sort = true;
            handleRequestAnexa1Phase2Docs(selectedUserId, currentPageNumber, column, sort, currentUser?.jwtToken);

        } else {
            dispatch(setGrantSortModel({
                columnToSortBy: "createdAt",
                sortingOrder: "desc"
            }));
            handleRequestAnexa1Phase2Docs(selectedUserId, currentPageNumber, DataGridColumnNames.UploadTime, false, currentUser?.jwtToken);
        }
    };

    const handleClose = () => {
        setDisplayUploadDialog(false);
    };

    return (
        <Container
            id="grant-contest-phase2-anexa1-view"
            maxWidth="lg"
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <Grid
                container
                sx={{ backgroundColor: "lightgrey", padding: 1 }}
            >
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Grid container sx={{ justifyContent: "flex-start" }}>
                        <Typography variant='h6' color={"white"} textAlign="center">
                            {localization.phase2Tab.annexeTables.annexe1Title}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ justifyContent: "flex-end" }}>
                        {
                            !grantApproved && !(currentUser?.userType === UserType.COMPANY_USER) &&
                            <Button disabled={!canAddDoc} variant="contained" startIcon={<Add />} color="success" onClick={() => { setDisplayUploadDialog(true) }}>
                                {localization.phase2Tab.annexeTables.addButton}
                            </Button>
                        }
                    </Grid>
                    {
                        displayUploadDialog &&
                        <FileUploadPopout
                            showSize={true}
                            uploadOnButtonCallback={onFilesUpload}
                            uploadDisabled={!canAddDoc}
                            handleClose={handleClose}
                            displayDialog={displayUploadDialog} />
                    }
                </Grid>
                <Grid item
                    xs={12}
                    style={{ marginTop: "10px", backgroundColor: "white" }}
                    id="anexa1-datagrid-container"
                >
                    <DataGrid
                        components={{
                            LoadingOverlay: DataLoadingComponent
                        }}
                        loading={userAnexa1Phase2DocumentsListIsLoading}
                        hideFooterPagination={userAnexa1Phase2DocumentsListIsLoading}
                        rows={anexa1Phase2Documents}
                        columns={columns}
                        pageSize={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        paginationMode="server"
                        rowCount={anexa1Phase2DocumentsCount}
                        onPageChange={handlePageChange}
                        page={currentPageNumber}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick={true}
                        rowHeight={49}
                        sx={{
                            "& .MuiDataGrid-row:hover": {
                                background: "aliceblue",
                                cursor: "pointer",
                            },
                            height: "100%",
                            boxShadow: 3,
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        disableColumnMenu={true}
                    ></DataGrid>

                </Grid>

            </Grid>

        </Container>
    )
}