import { UserType } from "store/models/enums/UserType";

export const isAdmin = (userType: UserType | undefined) => {
  if (userType === UserType.ADMIN) return true;
  return false;
};
export const isCompany = (userType: UserType | undefined) => {
  if (userType === UserType.COMPANY_USER) return true;
  return false;
};
export const isExpert = (userType: UserType | undefined) => {
  if (userType === UserType.EXPERT) return true;
  return false;
};
export const isEmployer = (userType:UserType|undefined)=>{
  if(userType === UserType.EMPLOYER) return true;
  return false;
}