import { HomePage } from "pages/home-page";
import { LoginPage } from "pages/login-page";
import { UsersPage } from "pages/users-page";
import { UserDetailsPage } from "pages/user-details-page";
import { CommunicationPage } from "pages/communication-page/CommunicationPage";
import { VocationalTrainingPage } from "pages/vocational-training-page/VocationalTrainingPage";
import { ForgotPasswordPage } from "pages/forgot-password-page/ForgotPasswordPage";
import { ResetPasswordPage } from "pages/reset-password-page/ResetPasswordPage";
import { StatisticsPage } from "pages/statistics-page/StatisticsPage";
import {
  communicationPath,
  forgotPasswordPath,
  grantContestPath,
  homePath,
  loginPath,
  newUserPath,
  resetPasswordPath,
  userDetailsPath,
  usersPath,
  verifyEmailPath,
  vocationalTrainingPath,
  manageDocumentsPath,
  administrationPath,
  counselingPath,
  mediationPath,
  jobPostPath,
  jobDetailsPath,
  modulesPath,
  statisticsPath,
} from "./constants";
import { UserType } from "../store/models/enums/UserType";
import { GrantContestPage } from "pages/grant-contest-page";
import { VerifyTokenRedirectComponent } from "components/shared/verifyTokenRedirect/VerifyTokenRedirectComponent";
import { ManageDocumentsComponent } from "pages/manageDocuments-page/ManageDocumentsComponent";
import { AdministrationPage } from "pages/administration-page/AdministrationPage";
import { CounselingPage } from "pages/counseling-page/CounselingPage";
import { MediationPage } from "pages/mediation-page/MediationPage";
import { AddOrEditJobPage } from "pages/add-or-edit-job-page/AddOrEditJobPage";
import { JobDetailsPage } from "pages/job-details-page/JobDetailsPage";
import { ModulesPage } from "pages/modules-page/ModulesPage";

export interface BaseRoute {
  path: string;
  component: any;
  approvedRoles?: Array<UserType>;
}

export const homePageRoute: BaseRoute = {
  path: homePath,
  component: HomePage,
  approvedRoles: [
    UserType.ADMIN,
    UserType.EXPERT,
    UserType.COMPANY_USER,
    UserType.EMPLOYER,
  ],
};

export const loginPageRoute: BaseRoute = {
  path: loginPath,
  component: LoginPage,
};

export const forgotPasswordRoute: BaseRoute = {
  path: forgotPasswordPath,
  component: ForgotPasswordPage,
};

export const resetPasswordRoute: BaseRoute = {
  path: resetPasswordPath,
  component: ResetPasswordPage,
};

export const usersPageRoute: BaseRoute = {
  path: usersPath,
  component: UsersPage,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const userDetailsPageRoute: BaseRoute = {
  path: userDetailsPath,
  component: UserDetailsPage,
  approvedRoles: [
    UserType.ADMIN,
    UserType.EXPERT,
    UserType.COMPANY_USER,
    UserType.EMPLOYER,
  ],
};

export const newUserPageRoute: BaseRoute = {
  path: newUserPath,
  component: UserDetailsPage,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const communicationPageRoute: BaseRoute = {
  path: communicationPath,
  component: CommunicationPage,
  approvedRoles: [
    UserType.ADMIN,
    UserType.EXPERT,
    UserType.COMPANY_USER,
    UserType.EMPLOYER,
  ],
};

export const vocationalTrainingRoute: BaseRoute = {
  path: vocationalTrainingPath,
  component: VocationalTrainingPage,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const grantContestRoute: BaseRoute = {
  path: grantContestPath,
  component: GrantContestPage,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};

export const verifyEmailRoute: BaseRoute = {
  path: verifyEmailPath,
  component: VerifyTokenRedirectComponent,
};

export const manageDocumentsRoute: BaseRoute = {
  path: manageDocumentsPath,
  component: ManageDocumentsComponent,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT, UserType.COMPANY_USER],
};

export const administrationRoute: BaseRoute = {
  path: administrationPath,
  component: AdministrationPage,
  approvedRoles: [UserType.ADMIN],
};

export const counselingRoute: BaseRoute = {
  path: counselingPath,
  component: CounselingPage,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const mediationRoute: BaseRoute = {
  path: mediationPath,
  component: MediationPage,
  approvedRoles: [
    UserType.ADMIN,
    UserType.EXPERT,
    UserType.EMPLOYER,
    UserType.COMPANY_USER,
  ],
};

export const jobPostRoute: BaseRoute = {
  path: jobPostPath,
  component: AddOrEditJobPage,
  approvedRoles: [UserType.ADMIN, UserType.EMPLOYER, UserType.EXPERT],
};

export const jobDetailsRoute: BaseRoute = {
  path: jobDetailsPath,
  component: JobDetailsPage,
  approvedRoles: [
    UserType.ADMIN,
    UserType.EXPERT,
    UserType.COMPANY_USER,
    UserType.EMPLOYER,
  ],
};

export const modulesRoute: BaseRoute = {
  path: modulesPath,
  component: ModulesPage,
  approvedRoles: [
    UserType.ADMIN,
    UserType.EXPERT,
    UserType.COMPANY_USER,
    UserType.EMPLOYER,
  ],
};
export const statisticsRoute: BaseRoute = {
  path: statisticsPath,
  component: StatisticsPage,
  approvedRoles: [UserType.ADMIN, UserType.EXPERT],
};

export const routes = [
  homePageRoute,
  loginPageRoute,
  usersPageRoute,
  userDetailsPageRoute,
  communicationPageRoute,
  vocationalTrainingRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  newUserPageRoute,
  grantContestRoute,
  verifyEmailRoute,
  manageDocumentsRoute,
  administrationRoute,
  counselingRoute,
  mediationRoute,
  jobPostRoute,
  jobDetailsRoute,
  modulesRoute,
  statisticsRoute,
];
