export const SalajSelectItemsLabel = [
  { value: "ADALIN", label: "ADALIN" },
  { value: "AGHIREȘ", label: "AGHIREȘ" },
  { value: "AGRIJ", label: "AGRIJ" },
  { value: "ALEUȘ", label: "ALEUȘ" },
  { value: "ALMAȘU", label: "ALMAȘU" },
  { value: "ALUNIȘ", label: "ALUNIȘ" },
  { value: "ARCHID", label: "ARCHID" },
  { value: "BĂBENI", label: "BĂBENI" },
  { value: "BĂBIU", label: "BĂBIU" },
  { value: "BĂDĂCIN", label: "BĂDĂCIN" },
  { value: "BADON", label: "BADON" },
  { value: "BAICA", label: "BAICA" },
  { value: "BĂLAN", label: "BĂLAN" },
  { value: "BAN", label: "BAN" },
  { value: "BĂNIȘOR", label: "BĂNIȘOR" },
  { value: "BÂRSA", label: "BÂRSA" },
  { value: "BÂRSĂU MARE", label: "BÂRSĂU MARE" },
  { value: "BÂRSĂUȚA", label: "BÂRSĂUȚA" },
  { value: "BENESAT", label: "BENESAT" },
  { value: "BERCEA", label: "BERCEA" },
  { value: "BEZDED", label: "BEZDED" },
  { value: "BIC", label: "BIC" },
  { value: "BILGHEZ", label: "BILGHEZ" },
  { value: "BIUȘA", label: "BIUȘA" },
  { value: "BIZUȘA-BĂI", label: "BIZUȘA-BĂI" },
  { value: "BOBOTA", label: "BOBOTA" },
  { value: "BOCȘA", label: "BOCȘA" },
  { value: "BOCȘIȚA", label: "BOCȘIȚA" },
  { value: "BODIA", label: "BODIA" },
  { value: "BOGDANA", label: "BOGDANA" },
  { value: "BOGHIȘ", label: "BOGHIȘ" },
  { value: "BORLA", label: "BORLA" },
  { value: "BORZA", label: "BORZA" },
  { value: "BOZIEȘ", label: "BOZIEȘ" },
  { value: "BOZNA", label: "BOZNA" },
  { value: "BRÂGLEZ", label: "BRÂGLEZ" },
  { value: "BREBI", label: "BREBI" },
  { value: "BRUSTURI", label: "BRUSTURI" },
  { value: "BUCIUMI", label: "BUCIUMI" },
  { value: "BULGARI", label: "BULGARI" },
  { value: "BUZAȘ", label: "BUZAȘ" },
  { value: "CĂLACEA", label: "CĂLACEA" },
  { value: "CAMĂR", label: "CAMĂR" },
  { value: "CÂMPIA", label: "CÂMPIA" },
  { value: "CĂPÂLNA", label: "CĂPÂLNA" },
  { value: "CARASTELEC", label: "CARASTELEC" },
  { value: "CEACA", label: "CEACA" },
  { value: "CEHEI", label: "CEHEI" },
  { value: "CEHU SILVANIEI", label: "CEHU SILVANIEI" },
  { value: "CERIȘA", label: "CERIȘA" },
  { value: "CERNUC", label: "CERNUC" },
  { value: "CHECHIȘ", label: "CHECHIȘ" },
  { value: "CHENDREA", label: "CHENDREA" },
  { value: "CHENDREMAL", label: "CHENDREMAL" },
  { value: "CHEUD", label: "CHEUD" },
  { value: "CHICHIȘA", label: "CHICHIȘA" },
  { value: "CHIEȘD", label: "CHIEȘD" },
  { value: "CHILIOARA", label: "CHILIOARA" },
  { value: "CHIZENI", label: "CHIZENI" },
  { value: "CIGLEAN", label: "CIGLEAN" },
  { value: "CIOCMANI", label: "CIOCMANI" },
  { value: "CIULA", label: "CIULA" },
  { value: "CIUMĂRNA", label: "CIUMĂRNA" },
  { value: "CIURENI", label: "CIURENI" },
  { value: "CIZER", label: "CIZER" },
  { value: "CLIȚ", label: "CLIȚ" },
  { value: "COLONIA SIGHETU SILVANIEI", label: "COLONIA SIGHETU SILVANIEI" },
  { value: "CORMENIȘ", label: "CORMENIȘ" },
  { value: "COȘEIU", label: "COȘEIU" },
  { value: "COSNICIU DE JOS", label: "COSNICIU DE JOS" },
  { value: "COSNICIU DE SUS", label: "COSNICIU DE SUS" },
  { value: "COZLA", label: "COZLA" },
  { value: "CRASNA", label: "CRASNA" },
  { value: "CREACA", label: "CREACA" },
  { value: "CRIȘENI", label: "CRIȘENI" },
  { value: "CRIȘTELEC", label: "CRIȘTELEC" },
  { value: "CRISTOLȚ", label: "CRISTOLȚ" },
  { value: "CRISTOLȚEL", label: "CRISTOLȚEL" },
  { value: "CRISTUR-CRIȘENI", label: "CRISTUR-CRIȘENI" },
  { value: "CUBLEȘU", label: "CUBLEȘU" },
  { value: "CUCEU", label: "CUCEU" },
  { value: "CUCIULAT", label: "CUCIULAT" },
  { value: "CUTIȘ", label: "CUTIȘ" },
  { value: "CUZĂPLAC", label: "CUZĂPLAC" },
  { value: "DĂBÂCENI", label: "DĂBÂCENI" },
  { value: "DEJA", label: "DEJA" },
  { value: "DELENI", label: "DELENI" },
  { value: "DERȘIDA", label: "DERȘIDA" },
  { value: "DIOȘOD", label: "DIOȘOD" },
  { value: "DOBA", label: "DOBA" },
  { value: "DOBRIN", label: "DOBRIN" },
  { value: "DOBROCINA", label: "DOBROCINA" },
  { value: "DOH", label: "DOH" },
  { value: "DOLHENI", label: "DOLHENI" },
  { value: "DOLU", label: "DOLU" },
  { value: "DOMNIN", label: "DOMNIN" },
  { value: "DRAGU", label: "DRAGU" },
  { value: "DRIGHIU", label: "DRIGHIU" },
  { value: "DUMUSLĂU", label: "DUMUSLĂU" },
  { value: "FABRICA", label: "FABRICA" },
  { value: "FĂGETU", label: "FĂGETU" },
  { value: "FĂLCUȘA", label: "FĂLCUȘA" },
  { value: "FÂNTÂNELE", label: "FÂNTÂNELE" },
  { value: "FÂNTÂNELE-RUS", label: "FÂNTÂNELE-RUS" },
  { value: "FETINDIA", label: "FETINDIA" },
  { value: "FILDU DE JOS", label: "FILDU DE JOS" },
  { value: "FILDU DE MIJLOC", label: "FILDU DE MIJLOC" },
  { value: "FILDU DE SUS", label: "FILDU DE SUS" },
  { value: "FIRMINIȘ", label: "FIRMINIȘ" },
  { value: "FIZEȘ", label: "FIZEȘ" },
  { value: "FODORA", label: "FODORA" },
  { value: "FRÂNCENII DE PIATRĂ", label: "FRÂNCENII DE PIATRĂ" },
  { value: "FUFEZ", label: "FUFEZ" },
  { value: "GĂLĂȘENI", label: "GĂLĂȘENI" },
  { value: "GÂLGĂU", label: "GÂLGĂU" },
  { value: "GÂLGĂU ALMAȘULUI", label: "GÂLGĂU ALMAȘULUI" },
  { value: "GĂLPÂIA", label: "GĂLPÂIA" },
  { value: "GÂRBOU", label: "GÂRBOU" },
  { value: "GÂRCEIU", label: "GÂRCEIU" },
  { value: "GIURTELECU ȘIMLEULUI", label: "GIURTELECU ȘIMLEULUI" },
  { value: "GLOD", label: "GLOD" },
  { value: "GOSTILA", label: "GOSTILA" },
  { value: "GURA VLĂDESEI", label: "GURA VLĂDESEI" },
  { value: "GURUSLĂU", label: "GURUSLĂU" },
  { value: "HALMĂȘD", label: "HALMĂȘD" },
  { value: "HĂȘMAȘ", label: "HĂȘMAȘ" },
  { value: "HERECLEAN", label: "HERECLEAN" },
  { value: "HIDA", label: "HIDA" },
  { value: "HOROATU CEHULUI", label: "HOROATU CEHULUI" },
  { value: "HOROATU CRASNEI", label: "HOROATU CRASNEI" },
  { value: "HUREZ", label: "HUREZ" },
  { value: "HUSENI", label: "HUSENI" },
  { value: "HUSIA", label: "HUSIA" },
  { value: "HUTA", label: "HUTA" },
  { value: "IAZ", label: "IAZ" },
  { value: "ILEANDA", label: "ILEANDA" },
  { value: "ILIȘUA", label: "ILIȘUA" },
  { value: "INĂU", label: "INĂU" },
  { value: "IP", label: "IP" },
  { value: "JAC", label: "JAC" },
  { value: "JEBUCU", label: "JEBUCU" },
  { value: "JIBOU", label: "JIBOU" },
  { value: "LAZURI", label: "LAZURI" },
  { value: "LEMNIU", label: "LEMNIU" },
  { value: "LEȘMIR", label: "LEȘMIR" },
  { value: "LETCA", label: "LETCA" },
  { value: "LOMPIRT", label: "LOMPIRT" },
  { value: "LOZNA", label: "LOZNA" },
  { value: "LUMINIȘU", label: "LUMINIȘU" },
  { value: "LUPOAIA", label: "LUPOAIA" },
  { value: "MĂERIȘTE", label: "MĂERIȘTE" },
  { value: "MĂGURA", label: "MĂGURA" },
  { value: "MAL", label: "MAL" },
  { value: "MĂLĂDIA", label: "MĂLĂDIA" },
  { value: "MĂLENI", label: "MĂLENI" },
  { value: "MARCA", label: "MARCA" },
  { value: "MARCA-HUTA", label: "MARCA-HUTA" },
  { value: "MARIN", label: "MARIN" },
  { value: "MESEȘENII DE JOS", label: "MESEȘENII DE JOS" },
  { value: "MESEȘENII DE SUS", label: "MESEȘENII DE SUS" },
  { value: "MESTEACĂNU", label: "MESTEACĂNU" },
  { value: "MIERȚA", label: "MIERȚA" },
  { value: "MILUANI", label: "MILUANI" },
  { value: "MINEU", label: "MINEU" },
  { value: "MIRȘID", label: "MIRȘID" },
  { value: "MOIAD", label: "MOIAD" },
  { value: "MOIGRAD-POROLISSUM", label: "MOIGRAD-POROLISSUM" },
  { value: "MOTIȘ", label: "MOTIȘ" },
  { value: "MUNCEL", label: "MUNCEL" },
  { value: "NADIȘ", label: "NADIȘ" },
  { value: "NAIMON", label: "NAIMON" },
  { value: "NĂPRADEA", label: "NĂPRADEA" },
  { value: "NEGRENI", label: "NEGRENI" },
  { value: "NOȚIG", label: "NOȚIG" },
  { value: "NUȘFALĂU", label: "NUȘFALĂU" },
  { value: "PĂDURENI", label: "PĂDURENI" },
  { value: "PĂDURIȘ", label: "PĂDURIȘ" },
  { value: "PANIC", label: "PANIC" },
  { value: "PĂUȘA", label: "PĂUȘA" },
  { value: "PECEIU", label: "PECEIU" },
  { value: "PERICEI", label: "PERICEI" },
  { value: "PERICEIU MIC", label: "PERICEIU MIC" },
  { value: "PERII VADULUI", label: "PERII VADULUI" },
  { value: "PETRINDU", label: "PETRINDU" },
  { value: "PETRINZEL", label: "PETRINZEL" },
  { value: "PIROȘA", label: "PIROȘA" },
  { value: "PLESCA", label: "PLESCA" },
  { value: "PLOPIȘ", label: "PLOPIȘ" },
  { value: "POARTA SĂLAJULUI", label: "POARTA SĂLAJULUI" },
  { value: "PODIȘU", label: "PODIȘU" },
  { value: "POIANA BLENCHII", label: "POIANA BLENCHII" },
  { value: "POIANA MĂGURA", label: "POIANA MĂGURA" },
  { value: "POIANA ONȚII", label: "POIANA ONȚII" },
  { value: "POIENIȚA", label: "POIENIȚA" },
  { value: "POPENI", label: "POPENI" },
  { value: "POPTELEAC", label: "POPTELEAC" },
  { value: "PORȚ", label: "PORȚ" },
  { value: "PRELUCI", label: "PRELUCI" },
  { value: "PREOTEASA", label: "PREOTEASA" },
  { value: "PRIA", label: "PRIA" },
  { value: "PRODĂNEȘTI", label: "PRODĂNEȘTI" },
  { value: "PURCĂREȚ", label: "PURCĂREȚ" },
  { value: "PUSTA", label: "PUSTA" },
  { value: "RACÂȘ", label: "RACÂȘ" },
  { value: "RĂSTOCI", label: "RĂSTOCI" },
  { value: "RĂSTOLȚ", label: "RĂSTOLȚ" },
  { value: "RĂSTOLȚU-DEȘERT", label: "RĂSTOLȚU-DEȘERT" },
  { value: "RATIN", label: "RATIN" },
  { value: "RATOVEI", label: "RATOVEI" },
  { value: "RECEA", label: "RECEA" },
  { value: "RECEA MICĂ", label: "RECEA MICĂ" },
  { value: "ROGNA", label: "ROGNA" },
  { value: "ROMÂNAȘI", label: "ROMÂNAȘI" },
  { value: "ROMITA", label: "ROMITA" },
  { value: "RONA", label: "RONA" },
  { value: "RUGINOASA", label: "RUGINOASA" },
  { value: "RUS", label: "RUS" },
  { value: "SÂG", label: "SÂG" },
  { value: "SĂLĂJENI", label: "SĂLĂJENI" },
  { value: "SĂLĂȚIG", label: "SĂLĂȚIG" },
  { value: "ȘAMȘUD", label: "ȘAMȘUD" },
  { value: "SÂNCRAIU ALMAȘULUI", label: "SÂNCRAIU ALMAȘULUI" },
  { value: "SÂNCRAIU SILVANIEI", label: "SÂNCRAIU SILVANIEI" },
  { value: "SÂNGEORGIU DE MESEȘ", label: "SÂNGEORGIU DE MESEȘ" },
  { value: "SÂNMIHAIU ALMAȘULUI", label: "SÂNMIHAIU ALMAȘULUI" },
  { value: "SÂNPETRU ALMAȘULUI", label: "SÂNPETRU ALMAȘULUI" },
  { value: "SÂNTĂ MĂRIA", label: "SÂNTĂ MĂRIA" },
  { value: "SÂRBI", label: "SÂRBI" },
  { value: "ȘĂRMĂȘAG", label: "ȘĂRMĂȘAG" },
  { value: "ȘASA", label: "ȘASA" },
  { value: "ȘEREDEIU", label: "ȘEREDEIU" },
  { value: "SFĂRAȘ", label: "SFĂRAȘ" },
  { value: "SICI", label: "SICI" },
  { value: "SIGHETU SILVANIEI", label: "SIGHETU SILVANIEI" },
  { value: "ȘIMIȘNA", label: "ȘIMIȘNA" },
  { value: "ȘIMLEU SILVANIEI", label: "ȘIMLEU SILVANIEI" },
  { value: "ȘOIMUȘ", label: "ȘOIMUȘ" },
  { value: "ȘOIMUȘENI", label: "ȘOIMUȘENI" },
  { value: "SOLOMON", label: "SOLOMON" },
  { value: "SOLONA", label: "SOLONA" },
  { value: "SOMEȘ-GURUSLĂU", label: "SOMEȘ-GURUSLĂU" },
  { value: "SOMEȘ-ODORHEI", label: "SOMEȘ-ODORHEI" },
  { value: "STANA", label: "STANA" },
  { value: "STÂNA", label: "STÂNA" },
  { value: "STÂRCIU", label: "STÂRCIU" },
  { value: "STOBORU", label: "STOBORU" },
  { value: "STUPINI", label: "STUPINI" },
  { value: "SUB CETATE", label: "SUB CETATE" },
  { value: "ȘUMAL", label: "ȘUMAL" },
  { value: "SURDUC", label: "SURDUC" },
  { value: "SUTORU", label: "SUTORU" },
  { value: "TĂMASA", label: "TĂMASA" },
  { value: "ȚĂRMURE", label: "ȚĂRMURE" },
  { value: "ȚĂUDU", label: "ȚĂUDU" },
  { value: "TEȘTIOARA", label: "TEȘTIOARA" },
  { value: "TETIȘU", label: "TETIȘU" },
  { value: "TIHĂU", label: "TIHĂU" },
  { value: "TOPLIȚA", label: "TOPLIȚA" },
  { value: "TRANIȘ", label: "TRANIȘ" },
  { value: "TRESTIA", label: "TRESTIA" },
  { value: "TREZNEA", label: "TREZNEA" },
  { value: "TURBUȚA", label: "TURBUȚA" },
  { value: "TUSA", label: "TUSA" },
  { value: "UGRUȚIU", label: "UGRUȚIU" },
  { value: "UILEACU ȘIMLEULUI", label: "UILEACU ȘIMLEULUI" },
  { value: "ULCIUG", label: "ULCIUG" },
  { value: "VĂDURELE", label: "VĂDURELE" },
  { value: "VALCĂU DE JOS", label: "VALCĂU DE JOS" },
  { value: "VALCĂU DE SUS", label: "VALCĂU DE SUS" },
  { value: "VALEA CIURENILOR", label: "VALEA CIURENILOR" },
  { value: "VALEA HRANEI", label: "VALEA HRANEI" },
  { value: "VALEA LEȘULUI", label: "VALEA LEȘULUI" },
  { value: "VALEA LOZNEI", label: "VALEA LOZNEI" },
  { value: "VALEA LUNGĂ", label: "VALEA LUNGĂ" },
  { value: "VALEA POMILOR", label: "VALEA POMILOR" },
  { value: "VĂLENI", label: "VĂLENI" },
  { value: "VĂLIȘOARA", label: "VĂLIȘOARA" },
  { value: "VAR", label: "VAR" },
  { value: "VÂRȘOLȚ", label: "VÂRȘOLȚ" },
  { value: "VÂRTEȘCA", label: "VÂRTEȘCA" },
  { value: "VERVEGHIU", label: "VERVEGHIU" },
  { value: "VIILE JACULUI", label: "VIILE JACULUI" },
  { value: "VOIVODENI", label: "VOIVODENI" },
  { value: "ZALĂU", label: "ZALĂU" },
  { value: "ZALHA", label: "ZALHA" },
  { value: "ZALNOC", label: "ZALNOC" },
  { value: "ZĂUAN", label: "ZĂUAN" },
  { value: "ZĂUAN-BĂI", label: "ZĂUAN-BĂI" },
  { value: "ZIMBOR", label: "ZIMBOR" },
];
