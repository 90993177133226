import { createSlice } from "@reduxjs/toolkit";
import { StatisticsState } from "store/models/statistics/StatisticsState";
import { getParticipantsNumbers } from "store/thunks/statisticsThunks";

const initialState: StatisticsState = {
  actions: {},
  participantsNumbers: {
    recruitedNr: 0,
    formedNr: 0,
    employedNr: 0,
    recruitedRequired: 0,
    formedRequired: 0,
    employedRequired: 0,
  },
  statisticsIsLoading: false,
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParticipantsNumbers.pending, (state) => {
      state.statisticsIsLoading = true;
    });
    builder.addCase(getParticipantsNumbers.fulfilled, (state, action) => {
      state.participantsNumbers = action.payload;
      state.statisticsIsLoading = false;
    });
    builder.addCase(getParticipantsNumbers.rejected, (state) => {
      state.statisticsIsLoading = false;
    });
  },
});
