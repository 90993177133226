import { Grid, ThemeProvider } from "@mui/material";
import { AppSnackbar } from "components/shared/appSnackbar";
import { OverlayLoader } from "components/shared/overlayLoader/OverlayLoader";
import { routes } from "navigation/routes";
import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from "store";
import { theme } from "utils/theme";
import "./App.scss";

import {
  FooterComponent,
  HeaderComponent,
  NavigationMenuComponent,
  RequireAuthComponent,
} from "./components/index";

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppSnackbar />
          <OverlayLoader />
          <NavigationMenuComponent />
          <Grid
            container
            alignItems="stretch"
            sx={{ minHeight: "calc(100vh - 100px)" }}
          >
            <Grid item xs={12}>
              <HeaderComponent />
              <div
                style={{
                  minHeight: "calc(100vh - 200px)",
                  marginTop: "100px",
                }}
              >
                <Routes>
                  {routes.map((it, index) => (
                    <Route
                      key={index}
                      path={it.path}
                      element={
                        <RequireAuthComponent roles={it.approvedRoles}>
                          <it.component />
                        </RequireAuthComponent>
                      }
                    />
                  ))}
                </Routes>
              </div>
            </Grid>
          </Grid>
          <FooterComponent />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
