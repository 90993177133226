export const BihorSelectItemsLabel = [
  {
    value: "ABRAM",
    label: "ABRAM",
  },
  {
    value: "ABRĂMUȚ",
    label: "ABRĂMUȚ",
  },
  {
    value: "ADONI",
    label: "ADONI",
  },
  {
    value: "ALBEȘTI",
    label: "ALBEȘTI",
  },
  {
    value: "ALBIȘ",
    label: "ALBIȘ",
  },
  {
    value: "ALEȘD",
    label: "ALEȘD",
  },
  {
    value: "ALMAȘU MARE",
    label: "ALMAȘU MARE",
  },
  {
    value: "ALMAȘU MIC / BALC",
    label: "ALMAȘU MIC / BALC",
  },
  {
    value: "ALMAȘU MIC / SÂRBI",
    label: "ALMAȘU MIC / SÂRBI",
  },
  {
    value: "ALPAREA",
    label: "ALPAREA",
  },
  {
    value: "ANT",
    label: "ANT",
  },
  {
    value: "APATEU",
    label: "APATEU",
  },
  {
    value: "ARPĂȘEL",
    label: "ARPĂȘEL",
  },
  {
    value: "AȘTILEU",
    label: "AȘTILEU",
  },
  {
    value: "ATEAȘ",
    label: "ATEAȘ",
  },
  {
    value: "AUȘEU",
    label: "AUȘEU",
  },
  {
    value: "AVRAM IANCU",
    label: "AVRAM IANCU",
  },
  {
    value: "BĂILE FELIX",
    label: "BĂILE FELIX",
  },
  {
    value: "BĂIȚA",
    label: "BĂIȚA",
  },
  {
    value: "BĂLAIA",
    label: "BĂLAIA",
  },
  {
    value: "BALC",
    label: "BALC",
  },
  {
    value: "BÂLC",
    label: "BÂLC",
  },
  {
    value: "BĂLENI",
    label: "BĂLENI",
  },
  {
    value: "BĂLNACA",
    label: "BĂLNACA",
  },
  {
    value: "BĂLNACA-GROȘI",
    label: "BĂLNACA-GROȘI",
  },
  {
    value: "BATĂR",
    label: "BATĂR",
  },
  {
    value: "BEIUȘ",
    label: "BEIUȘ",
  },
  {
    value: "BEIUȘELE",
    label: "BEIUȘELE",
  },
  {
    value: "BELEJENI",
    label: "BELEJENI",
  },
  {
    value: "BELFIR",
    label: "BELFIR",
  },
  {
    value: "BERECHIU",
    label: "BERECHIU",
  },
  {
    value: "BETFIA",
    label: "BETFIA",
  },
  {
    value: "BEZNEA",
    label: "BEZNEA",
  },
  {
    value: "BICĂCEL",
    label: "BICĂCEL",
  },
  {
    value: "BICACI",
    label: "BICACI",
  },
  {
    value: "BIHARIA",
    label: "BIHARIA",
  },
  {
    value: "BIRTIN",
    label: "BIRTIN",
  },
  {
    value: "BOGEI",
    label: "BOGEI",
  },
  {
    value: "BOIANU MARE",
    label: "BOIANU MARE",
  },
  {
    value: "BOIU",
    label: "BOIU",
  },
  {
    value: "BOROD",
    label: "BOROD",
  },
  {
    value: "BOROZEL",
    label: "BOROZEL",
  },
  {
    value: "BORȘ",
    label: "BORȘ",
  },
  {
    value: "BORȘA",
    label: "BORȘA",
  },
  {
    value: "BORUMLACA",
    label: "BORUMLACA",
  },
  {
    value: "BORZ",
    label: "BORZ",
  },
  {
    value: "BOTEAN",
    label: "BOTEAN",
  },
  {
    value: "BRĂDET",
    label: "BRĂDET",
  },
  { value: "BRATCA", label: "BRATCA" },
  { value: "BRĂTEȘTI", label: "BRĂTEȘTI" },
  { value: "BRIHENI", label: "BRIHENI" },
  { value: "BRUSTURI / BRUSTURI", label: "BRUSTURI / BRUSTURI" },
  { value: "BRUSTURI / FINIȘ", label: "BRUSTURI / FINIȘ" },
  { value: "BUCIUM", label: "BUCIUM" },
  { value: "BUCUROAIA", label: "BUCUROAIA" },
  { value: "BUDOI", label: "BUDOI" },
  { value: "BUDUREASA", label: "BUDUREASA" },
  { value: "BUDUSLĂU", label: "BUDUSLĂU" },
  { value: "BULZ", label: "BULZ" },
  { value: "BUNTEȘTI", label: "BUNTEȘTI" },
  { value: "BURDA", label: "BURDA" },
  { value: "BURZUC", label: "BURZUC" },
  { value: "BUTANI", label: "BUTANI" },
  { value: "CĂBEȘTI", label: "CĂBEȘTI" },
  { value: "CACUCIU NOU", label: "CACUCIU NOU" },
  { value: "CACUCIU VECHI", label: "CACUCIU VECHI" },
  { value: "CADEA", label: "CADEA" },
  { value: "CĂLACEA", label: "CĂLACEA" },
  { value: "CĂLĂTANI", label: "CĂLĂTANI" },
  { value: "CĂLĂȚEA", label: "CĂLĂȚEA" },
  { value: "CALEA MARE", label: "CALEA MARE" },
  { value: "CĂLUGĂRI", label: "CĂLUGĂRI" },
  { value: "CÂMP", label: "CÂMP" },
  { value: "CÂMPANI", label: "CÂMPANI" },
  { value: "CÂMPANI DE POMEZEU", label: "CÂMPANI DE POMEZEU" },
  { value: "CÂMP-MOȚI", label: "CÂMP-MOȚI" },
  { value: "CĂPÂLNA", label: "CĂPÂLNA" },
  { value: "CĂRĂNDENI", label: "CĂRĂNDENI" },
  { value: "CĂRĂNZEL", label: "CĂRĂNZEL" },
  { value: "CĂRĂSĂU", label: "CĂRĂSĂU" },
  { value: "CĂRPINET", label: "CĂRPINET" },
  { value: "CAUACEU", label: "CAUACEU" },
  { value: "CĂUAȘD", label: "CĂUAȘD" },
  { value: "CEFA", label: "CEFA" },
  { value: "CEICA", label: "CEICA" },
  { value: "CEIȘOARA", label: "CEIȘOARA" },
  { value: "CENALOȘ", label: "CENALOȘ" },
  { value: "CETARIU", label: "CETARIU" },
  { value: "CETEA", label: "CETEA" },
  { value: "CHERECHIU", label: "CHERECHIU" },
  { value: "CHERESIG", label: "CHERESIG" },
  { value: "CHERIU", label: "CHERIU" },
  { value: "CHEȘA", label: "CHEȘA" },
  { value: "CHEȘEREU", label: "CHEȘEREU" },
  { value: "CHEȚ", label: "CHEȚ" },
  { value: "CHIJIC", label: "CHIJIC" },
  { value: "CHIOAG", label: "CHIOAG" },
  { value: "CHIRALEU", label: "CHIRALEU" },
  { value: "CHIRIBIȘ", label: "CHIRIBIȘ" },
  { value: "CHIȘCĂU", label: "CHIȘCĂU" },
  { value: "CHIȘIRID", label: "CHIȘIRID" },
  { value: "CHIȘLAZ", label: "CHIȘLAZ" },
  { value: "CHISTAG", label: "CHISTAG" },
  { value: "CIHEI", label: "CIHEI" },
  { value: "CIOCAIA", label: "CIOCAIA" },
  { value: "CIUHOI", label: "CIUHOI" },
  { value: "CIULEȘTI", label: "CIULEȘTI" },
  { value: "CIUMEGHIU", label: "CIUMEGHIU" },
  { value: "CIUTELEC", label: "CIUTELEC" },
  { value: "COCIUBA MARE", label: "COCIUBA MARE" },
  { value: "COCIUBA MICĂ", label: "COCIUBA MICĂ" },
  { value: "CODRIȘORU", label: "CODRIȘORU" },
  { value: "CODRU", label: "CODRU" },
  { value: "COHANI", label: "COHANI" },
  { value: "COLEȘTI", label: "COLEȘTI" },
  { value: "COPĂCEL", label: "COPĂCEL" },
  { value: "COPĂCENI", label: "COPĂCENI" },
  { value: "CORBEȘTI", label: "CORBEȘTI" },
  { value: "CORBOAIA", label: "CORBOAIA" },
  { value: "CORDĂU", label: "CORDĂU" },
  { value: "CORNIȘEȘTI", label: "CORNIȘEȘTI" },
  { value: "CORNIȚEL", label: "CORNIȚEL" },
  { value: "COȘDENI", label: "COȘDENI" },
  { value: "COTIGLET", label: "COTIGLET" },
  { value: "CRÂNCEȘTI", label: "CRÂNCEȘTI" },
  { value: "CRESTUR", label: "CRESTUR" },
  { value: "CRESUIA", label: "CRESUIA" },
  { value: "CRIȘTIORU DE JOS", label: "CRIȘTIORU DE JOS" },
  { value: "CRIȘTIORU DE SUS", label: "CRIȘTIORU DE SUS" },
  { value: "CUBULCUT", label: "CUBULCUT" },
  { value: "CUCUCENI", label: "CUCUCENI" },
  { value: "CUIEȘD", label: "CUIEȘD" },
  { value: "CURĂȚELE", label: "CURĂȚELE" },
  { value: "CURTUIȘENI", label: "CURTUIȘENI" },
  { value: "CUSUIUȘ", label: "CUSUIUȘ" },
  { value: "CUZAP", label: "CUZAP" },
  { value: "DAMIȘ", label: "DAMIȘ" },
  { value: "DELANI", label: "DELANI" },
  { value: "DERNA", label: "DERNA" },
  { value: "DERNIȘOARA", label: "DERNIȘOARA" },
  { value: "DICĂNEȘTI", label: "DICĂNEȘTI" },
  { value: "DIJIR", label: "DIJIR" },
  { value: "DIOSIG", label: "DIOSIG" },
  { value: "DOBREȘTI", label: "DOBREȘTI" },
  { value: "DOBRICIONEȘTI", label: "DOBRICIONEȘTI" },
  { value: "DOLEA", label: "DOLEA" },
  { value: "DRĂGĂNEȘTI", label: "DRĂGĂNEȘTI" },
  { value: "DRĂGEȘTI", label: "DRĂGEȘTI" },
  { value: "DRĂGOTENI", label: "DRĂGOTENI" },
  { value: "DUMBRAVA", label: "DUMBRAVA" },
  { value: "DUMBRĂVANI", label: "DUMBRĂVANI" },
  { value: "DUMBRĂVIȚA", label: "DUMBRĂVIȚA" },
  { value: "DUMBRĂVIȚA DE CODRU", label: "DUMBRĂVIȚA DE CODRU" },
  { value: "DUȘEȘTI", label: "DUȘEȘTI" },
  { value: "FÂNAȚE", label: "FÂNAȚE" },
  { value: "FĂNCICA", label: "FĂNCICA" },
  { value: "FÂȘCA", label: "FÂȘCA" },
  { value: "FEGERNIC", label: "FEGERNIC" },
  { value: "FEGERNICU NOU", label: "FEGERNICU NOU" },
  { value: "FELCHERIU", label: "FELCHERIU" },
  { value: "FENERIȘ", label: "FENERIȘ" },
  { value: "FERICE", label: "FERICE" },
  { value: "FINIȘ", label: "FINIȘ" },
  { value: "FIZIȘ", label: "FIZIȘ" },
  { value: "FOGLAȘ", label: "FOGLAȘ" },
  { value: "FONĂU", label: "FONĂU" },
  { value: "FORĂU", label: "FORĂU" },
  { value: "FOROSIG", label: "FOROSIG" },
  { value: "FUGHIU", label: "FUGHIU" },
  { value: "GĂLĂȘENI", label: "GĂLĂȘENI" },
  { value: "GALOȘPETREU", label: "GALOȘPETREU" },
  { value: "GEPIȘ", label: "GEPIȘ" },
  { value: "GEPIU", label: "GEPIU" },
  { value: "GHEGHIE", label: "GHEGHIE" },
  { value: "GHENETEA", label: "GHENETEA" },
  { value: "GHIDA", label: "GHIDA" },
  { value: "GHIGHIȘENI", label: "GHIGHIȘENI" },
  { value: "GHIORAC", label: "GHIORAC" },
  { value: "GINTA", label: "GINTA" },
  { value: "GIRIȘU DE CRIȘ", label: "GIRIȘU DE CRIȘ" },
  { value: "GIRIȘU NEGRU", label: "GIRIȘU NEGRU" },
  { value: "GIULEȘTI", label: "GIULEȘTI" },
  { value: "GOILA", label: "GOILA" },
  { value: "GRĂDINARI", label: "GRĂDINARI" },
  { value: "GROȘI", label: "GROȘI" },
  { value: "GRUILUNG", label: "GRUILUNG" },
  { value: "GURANI", label: "GURANI" },
  { value: "GURBEDIU", label: "GURBEDIU" },
  { value: "GURBEȘTI / CĂBEȘTI", label: "GURBEȘTI / CĂBEȘTI" },
  { value: "GURBEȘTI / SPINUȘ", label: "GURBEȘTI / SPINUȘ" },
  { value: "HAIEU", label: "HAIEU" },
  { value: "HÂRSEȘTI", label: "HÂRSEȘTI" },
  { value: "HĂUCEȘTI", label: "HĂUCEȘTI" },
  { value: "HIDIȘ", label: "HIDIȘ" },
  { value: "HIDIȘEL", label: "HIDIȘEL" },
  { value: "HIDIȘELU DE JOS", label: "HIDIȘELU DE JOS" },
  { value: "HIDIȘELU DE SUS", label: "HIDIȘELU DE SUS" },
  { value: "HINCHIRIȘ", label: "HINCHIRIȘ" },
  { value: "HODIȘ", label: "HODIȘ" },
  { value: "HODIȘEL", label: "HODIȘEL" },
  { value: "HODOȘ", label: "HODOȘ" },
  { value: "HOLOD", label: "HOLOD" },
  { value: "HOMOROG", label: "HOMOROG" },
  { value: "HOTAR", label: "HOTAR" },
  { value: "HOTĂREL", label: "HOTĂREL" },
  { value: "HUSASĂU DE CRIȘ", label: "HUSASĂU DE CRIȘ" },
  { value: "HUSASĂU DE TINCA", label: "HUSASĂU DE TINCA" },
  { value: "HUTA", label: "HUTA" },
  { value: "HUTA VOIVOZI", label: "HUTA VOIVOZI" },
  { value: "IANCA", label: "IANCA" },
  { value: "IANOȘDA", label: "IANOȘDA" },
  { value: "INAND", label: "INAND" },
  { value: "INCEȘTI", label: "INCEȘTI" },
  { value: "INEU", label: "INEU" },
  { value: "IOANIȘ", label: "IOANIȘ" },
  { value: "ITEU", label: "ITEU" },
  { value: "ITEU NOU", label: "ITEU NOU" },
  { value: "IZBUC", label: "IZBUC" },
  { value: "IZVOARELE", label: "IZVOARELE" },
  { value: "JOSANI / CĂBEȘTI", label: "JOSANI / CĂBEȘTI" },
  { value: "JOSANI / MĂGEȘTI", label: "JOSANI / MĂGEȘTI" },
  { value: "LACU SĂRAT", label: "LACU SĂRAT" },
  { value: "LĂZĂRENI", label: "LĂZĂRENI" },
  { value: "LAZURI", label: "LAZURI" },
  { value: "LAZURI DE BEIUȘ", label: "LAZURI DE BEIUȘ" },
  { value: "LEHECENI", label: "LEHECENI" },
  { value: "LELEȘTI", label: "LELEȘTI" },
  { value: "LEȘ", label: "LEȘ" },
  { value: "LIVADA BEIUȘULUI", label: "LIVADA BEIUȘULUI" },
  { value: "LIVADA DE BIHOR", label: "LIVADA DE BIHOR" },
  { value: "LORANTA", label: "LORANTA" },
  { value: "LORĂU", label: "LORĂU" },
  { value: "LUGAȘU DE JOS", label: "LUGAȘU DE JOS" },
  { value: "LUGAȘU DE SUS", label: "LUGAȘU DE SUS" },
  { value: "LUNCA", label: "LUNCA" },
  { value: "LUNCASPRIE", label: "LUNCASPRIE" },
  { value: "LUNCȘOARA", label: "LUNCȘOARA" },
  { value: "LUPOAIA", label: "LUPOAIA" },
  { value: "MĂDĂRAS", label: "MĂDĂRAS" },
  { value: "MĂGEȘTI", label: "MĂGEȘTI" },
  { value: "MĂGURA", label: "MĂGURA" },
  { value: "MARGHITA", label: "MARGHITA" },
  { value: "MARGINE", label: "MARGINE" },
  { value: "MARȚIHAZ", label: "MARȚIHAZ" },
  { value: "MEZIAD", label: "MEZIAD" },
  { value: "MIERAG", label: "MIERAG" },
  { value: "MIERLĂU", label: "MIERLĂU" },
  { value: "MIERSIG", label: "MIERSIG" },
  { value: "MIHAI BRAVU", label: "MIHAI BRAVU" },
  { value: "MIHELEU", label: "MIHELEU" },
  { value: "MIȘCA", label: "MIȘCA" },
  { value: "MIZIEȘ", label: "MIZIEȘ" },
  { value: "MOȚEȘTI", label: "MOȚEȘTI" },
  { value: "MUNTENI", label: "MUNTENI" },
  { value: "NĂDAR", label: "NĂDAR" },
  { value: "NIMĂIEȘTI", label: "NIMĂIEȘTI" },
  { value: "NIUVED", label: "NIUVED" },
  { value: "NOJORID", label: "NOJORID" },
  { value: "NUCET", label: "NUCET" },
  { value: "OGEȘTI", label: "OGEȘTI" },
  { value: "OLCEA", label: "OLCEA" },
  { value: "OLOSIG", label: "OLOSIG" },
  { value: "ORADEA", label: "ORADEA" },
  { value: "ORTITEAG", label: "ORTITEAG" },
  { value: "ORVIȘELE", label: "ORVIȘELE" },
  { value: "OȘAND", label: "OȘAND" },
  { value: "OȘORHEI", label: "OȘORHEI" },
  { value: "OTOMANI", label: "OTOMANI" },
  { value: "PĂCĂLEȘTI", label: "PĂCĂLEȘTI" },
  { value: "PĂDUREA NEAGRĂ", label: "PĂDUREA NEAGRĂ" },
  { value: "PĂDURENI", label: "PĂDURENI" },
  { value: "PĂGAIA", label: "PĂGAIA" },
  { value: "PALEU", label: "PALEU" },
  { value: "PALOTA", label: "PALOTA" },
  { value: "PĂNTĂȘEȘTI", label: "PĂNTĂȘEȘTI" },
  { value: "PARHIDA", label: "PARHIDA" },
  { value: "PĂULEȘTI", label: "PĂULEȘTI" },
  { value: "PĂUȘA", label: "PĂUȘA" },
  { value: "PEȘTERE", label: "PEȘTERE" },
  { value: "PEȘTIȘ", label: "PEȘTIȘ" },
  { value: "PETID", label: "PETID" },
  { value: "PETRANI", label: "PETRANI" },
  { value: "PETREASA", label: "PETREASA" },
  { value: "PETREU", label: "PETREU" },
  { value: "PETRILENI", label: "PETRILENI" },
  { value: "PICLEU", label: "PICLEU" },
  { value: "PIETROASA", label: "PIETROASA" },
  { value: "POCIOVELIȘTE", label: "POCIOVELIȘTE" },
  { value: "POCLUȘA DE BARCĂU", label: "POCLUȘA DE BARCĂU" },
  { value: "POCLUȘA DE BEIUȘ", label: "POCLUȘA DE BEIUȘ" },
  { value: "POCOLA", label: "POCOLA" },
  { value: "POIANA / CRIȘTIORU DE JOS", label: "POIANA / CRIȘTIORU DE JOS" },
  { value: "POIANA / TĂUTEU", label: "POIANA / TĂUTEU" },
  { value: "POIANA TĂȘAD", label: "POIANA TĂȘAD" },
  { value: "POIENII DE JOS", label: "POIENII DE JOS" },
  { value: "POIENII DE SUS", label: "POIENII DE SUS" },
  { value: "POIETARI", label: "POIETARI" },
  { value: "POMEZEU", label: "POMEZEU" },
  { value: "PONOARĂ", label: "PONOARĂ" },
  { value: "POPEȘTI", label: "POPEȘTI" },
  { value: "POȘOLOACA", label: "POȘOLOACA" },
  { value: "PRISACA", label: "PRISACA" },
  { value: "RĂBĂGANI", label: "RĂBĂGANI" },
  { value: "RĂCAȘ", label: "RĂCAȘ" },
  { value: "RÂPA", label: "RÂPA" },
  { value: "REGHEA", label: "REGHEA" },
  { value: "REMETEA", label: "REMETEA" },
  { value: "REMEȚI", label: "REMEȚI" },
  { value: "RIENI", label: "RIENI" },
  { value: "ROGOZ", label: "ROGOZ" },
  { value: "ROHANI", label: "ROHANI" },
  { value: "ROIT", label: "ROIT" },
  { value: "RONTĂU", label: "RONTĂU" },
  { value: "ROȘIA", label: "ROȘIA" },
  { value: "ROȘIORI", label: "ROȘIORI" },
  { value: "ROTĂREȘTI", label: "ROTĂREȘTI" },
  { value: "RUGEA", label: "RUGEA" },
  { value: "SĂBOLCIU", label: "SĂBOLCIU" },
  { value: "SACA", label: "SACA" },
  { value: "SĂCĂDAT", label: "SĂCĂDAT" },
  { value: "SACALASĂU", label: "SACALASĂU" },
  { value: "SACALASĂU NOU", label: "SACALASĂU NOU" },
  { value: "SĂCUENI", label: "SĂCUENI" },
  { value: "SĂLACEA", label: "SĂLACEA" },
  { value: "SĂLARD", label: "SĂLARD" },
  { value: "SĂLDĂBAGIU DE BARCĂU", label: "SĂLDĂBAGIU DE BARCĂU" },
  { value: "SĂLDĂBAGIU DE MUNTE", label: "SĂLDĂBAGIU DE MUNTE" },
  { value: "SĂLDĂBAGIU MIC", label: "SĂLDĂBAGIU MIC" },
  { value: "SĂLIȘTE", label: "SĂLIȘTE" },
  { value: "SĂLIȘTE DE BEIUȘ", label: "SĂLIȘTE DE BEIUȘ" },
  { value: "SALIȘTE DE POMEZEU", label: "SALIȘTE DE POMEZEU" },
  { value: "SĂLIȘTE DE VAȘCĂU", label: "SĂLIȘTE DE VAȘCĂU" },
  { value: "SALONTA", label: "SALONTA" },
  { value: "SÂMBĂTA", label: "SÂMBĂTA" },
  { value: "SÂNIOB", label: "SÂNIOB" },
  { value: "SÂNLAZĂR", label: "SÂNLAZĂR" },
  { value: "SÂNMARTIN", label: "SÂNMARTIN" },
  { value: "SÂNMARTIN DE BEIUȘ", label: "SÂNMARTIN DE BEIUȘ" },
  { value: "SÂNNICOLAU DE BEIUȘ", label: "SÂNNICOLAU DE BEIUȘ" },
  { value: "SÂNNICOLAU DE MUNTE", label: "SÂNNICOLAU DE MUNTE" },
  { value: "SÂNNICOLAU ROMÂN", label: "SÂNNICOLAU ROMÂN" },
  { value: "SÂNTANDREI", label: "SÂNTANDREI" },
  { value: "SANTĂUL MARE", label: "SANTĂUL MARE" },
  { value: "SANTĂUL MIC", label: "SANTĂUL MIC" },
  { value: "SÂNTELEC", label: "SÂNTELEC" },
  { value: "SÂNTIMREU", label: "SÂNTIMREU" },
  { value: "SÂNTION", label: "SÂNTION" },
  { value: "SĂRAND", label: "SĂRAND" },
  { value: "SÂRBEȘTI", label: "SÂRBEȘTI" },
  { value: "SÂRBI", label: "SÂRBI" },
  { value: "SARCĂU", label: "SARCĂU" },
  { value: "SĂRSIG", label: "SĂRSIG" },
  { value: "SATU BARBĂ", label: "SATU BARBĂ" },
  { value: "SATU NOU", label: "SATU NOU" },
  { value: "ȘAUAIEU", label: "ȘAUAIEU" },
  { value: "SĂUCANI", label: "SĂUCANI" },
  { value: "SĂUD", label: "SĂUD" },
  { value: "ȘEBIȘ", label: "ȘEBIȘ" },
  { value: "SEGHIȘTE", label: "SEGHIȘTE" },
  { value: "ȘERANI", label: "ȘERANI" },
  { value: "ȘERGHIȘ", label: "ȘERGHIȘ" },
  { value: "SFÂRNAȘ", label: "SFÂRNAȘ" },
  { value: "SIGHIȘTEL", label: "SIGHIȘTEL" },
  { value: "ȘILINDRU", label: "ȘILINDRU" },
  { value: "ȘIMIAN", label: "ȘIMIAN" },
  { value: "ȘINTEU", label: "ȘINTEU" },
  { value: "ȘIȘTEREA", label: "ȘIȘTEREA" },
  { value: "SITANI", label: "SITANI" },
  { value: "SITITELEC", label: "SITITELEC" },
  { value: "SOCET", label: "SOCET" },
  { value: "SOHODOL", label: "SOHODOL" },
  { value: "ȘOIMI", label: "ȘOIMI" },
  { value: "SOIMUȘ", label: "SOIMUȘ" },
  { value: "SPINUȘ", label: "SPINUȘ" },
  { value: "SPINUȘ DE POMEZEU", label: "SPINUȘ DE POMEZEU" },
  { value: "STÂNCEȘTI", label: "STÂNCEȘTI" },
  { value: "ȘTEI", label: "ȘTEI" },
  { value: "STRACOȘ", label: "STRACOȘ" },
  { value: "SUBPIATRĂ", label: "SUBPIATRĂ" },
  { value: "SUDRIGIU", label: "SUDRIGIU" },
  { value: "SUIUG", label: "SUIUG" },
  { value: "ȘUMUGIU", label: "ȘUMUGIU" },
  { value: "ȘUNCUIȘ", label: "ȘUNCUIȘ" },
  { value: "ȘUNCUIUȘ", label: "ȘUNCUIUȘ" },
  { value: "SUPLACU DE BARCĂU", label: "SUPLACU DE BARCĂU" },
  { value: "SUPLACU DE TINCA", label: "SUPLACU DE TINCA" },
  { value: "SURDUC", label: "SURDUC" },
  { value: "SURDUCEL", label: "SURDUCEL" },
  { value: "ȘUȘTIU", label: "ȘUȘTIU" },
  { value: "ȘUȘTUROGI", label: "ȘUȘTUROGI" },
  { value: "TALPE", label: "TALPE" },
  { value: "TALPOȘ", label: "TALPOȘ" },
  { value: "TĂMAȘDA", label: "TĂMAȘDA" },
  { value: "TĂMĂȘEU", label: "TĂMĂȘEU" },
  { value: "TĂRCAIA", label: "TĂRCAIA" },
  { value: "TĂRCĂIȚA", label: "TĂRCĂIȚA" },
  { value: "TARCEA", label: "TARCEA" },
  { value: "TÂRGUȘOR", label: "TÂRGUȘOR" },
  { value: "TĂRIAN", label: "TĂRIAN" },
  { value: "TĂȘAD", label: "TĂȘAD" },
  { value: "TĂUT", label: "TĂUT" },
  { value: "TĂUTELEC", label: "TĂUTELEC" },
  { value: "TĂUTEU", label: "TĂUTEU" },
  { value: "TELEAC", label: "TELEAC" },
  { value: "TELECHIU", label: "TELECHIU" },
  { value: "ȚEȚCHEA", label: "ȚEȚCHEA" },
  { value: "ȚIGĂNEȘTII DE BEIUȘ", label: "ȚIGĂNEȘTII DE BEIUȘ" },
  { value: "ȚIGĂNEȘTII DE CRIȘ", label: "ȚIGĂNEȘTII DE CRIȘ" },
  { value: "TILEAGD", label: "TILEAGD" },
  { value: "TILECUȘ", label: "TILECUȘ" },
  { value: "TINĂUD", label: "TINĂUD" },
  { value: "TINCA", label: "TINCA" },
  { value: "TOBOLIU", label: "TOBOLIU" },
  { value: "TOMNATIC", label: "TOMNATIC" },
  { value: "TOPA DE CRIȘ", label: "TOPA DE CRIȘ" },
  { value: "TOPA DE JOS", label: "TOPA DE JOS" },
  { value: "TOPA DE SUS", label: "TOPA DE SUS" },
  { value: "TOPEȘTI", label: "TOPEȘTI" },
  { value: "TOTORENI", label: "TOTORENI" },
  { value: "TRIA", label: "TRIA" },
  { value: "TULCA", label: "TULCA" },
  { value: "UCURIȘ", label: "UCURIȘ" },
  { value: "UILEACU DE BEIUȘ", label: "UILEACU DE BEIUȘ" },
  { value: "UILEACU DE CRIȘ", label: "UILEACU DE CRIȘ" },
  { value: "UILEACU DE MUNTE", label: "UILEACU DE MUNTE" },
  { value: "URSAD", label: "URSAD" },
  { value: "URVIND", label: "URVIND" },
  { value: "URVIȘ DE BEIUȘ", label: "URVIȘ DE BEIUȘ" },
  { value: "VADU CRIȘULUI", label: "VADU CRIȘULUI" },
  { value: "VAIDA", label: "VAIDA" },
  { value: "VĂLANI DE POMEZEU", label: "VĂLANI DE POMEZEU" },
  { value: "VĂLANII DE BEIUȘ", label: "VĂLANII DE BEIUȘ" },
  { value: "VÂLCELELE", label: "VÂLCELELE" },
  { value: "VALEA CERULUI", label: "VALEA CERULUI" },
  { value: "VALEA CRIȘULUI", label: "VALEA CRIȘULUI" },
  { value: "VALEA DE JOS", label: "VALEA DE JOS" },
  { value: "VALEA DE SUS", label: "VALEA DE SUS" },
  { value: "VALEA LUI MIHAI", label: "VALEA LUI MIHAI" },
  { value: "VALEA MARE DE CODRU", label: "VALEA MARE DE CODRU" },
  { value: "VALEA MARE DE CRIȘ", label: "VALEA MARE DE CRIȘ" },
  { value: "VALEA TÂRNEI", label: "VALEA TÂRNEI" },
  { value: "VARASĂU", label: "VARASĂU" },
  { value: "VĂRĂȘENI", label: "VĂRĂȘENI" },
  { value: "VÂRCIOROG", label: "VÂRCIOROG" },
  { value: "VARVIZ", label: "VARVIZ" },
  { value: "VĂRZARI", label: "VĂRZARI" },
  { value: "VĂRZARII DE JOS", label: "VĂRZARII DE JOS" },
  { value: "VĂRZARII DE SUS", label: "VĂRZARII DE SUS" },
  { value: "VĂȘAD", label: "VĂȘAD" },
  { value: "VAȘCĂU", label: "VAȘCĂU" },
  { value: "VIIȘOARA", label: "VIIȘOARA" },
  { value: "VINTERE", label: "VINTERE" },
  { value: "VOIVOZI / POPEȘTI", label: "VOIVOZI / POPEȘTI" },
  { value: "VOIVOZI / ȘIMIAN", label: "VOIVOZI / ȘIMIAN" },
  { value: "ZĂVOIU", label: "ZĂVOIU" },
  { value: "ZECE HOTARE", label: "ZECE HOTARE" },
];
