import {

  Dialog,

} from "@mui/material";

import { FC } from "react";


import "./JobModal.scss";

import { JobInfo } from "../job-info/JobInfo";

export const JobModal: FC<{ display: boolean; close: Function }> = ({
  display,
  close,
}) => {

  return (
    <Dialog id="job-modal" open={display} maxWidth="lg" fullWidth>
      <JobInfo showClose={true} close={close} />
    </Dialog >
  );
};
