import React, { FC, useEffect } from "react";
import { Box, Grid, List, ListItem, ListItemText } from "@mui/material";
import {
  generateRanking,
  getFeatureStatus,
  requestGetGeneratedRankings,
  selectCurrentUser,
  selectLocalization,
  selectShowPhase2FinallRanking,
  selectShowPhase2IntermediaryRanking,
  selectShowPhase3Ranking,
  toggleContest,
  toggleMonitoring,
  useAppDispatch,
  useAppSelector,
} from "store";
import { PhaseNumber } from "store/models/enums/PhaseNumber";
import { PhaseType } from "store/models/enums/PhaseType";
import { CheckBox, DoNotDisturbAlt } from "@mui/icons-material";

import "./FormControlComponent.scss";
import { selectShowContest, selectShowMonitoring } from "store/selectors/manageDocumentsSelectors";

export const FormControlComponent: FC<any> = () => {
  const dispatch = useAppDispatch();
  const showPhase2IntermediaryRanking = useAppSelector(
    selectShowPhase2IntermediaryRanking
  );
  const showPhase2FinallRanking = useAppSelector(selectShowPhase2FinallRanking);
  const showPhase3Ranking = useAppSelector(selectShowPhase3Ranking);
  const currentUser = useAppSelector(selectCurrentUser);
  const showMonitoring = useAppSelector(selectShowMonitoring);
  const showContest = useAppSelector(selectShowContest);
  const localization = useAppSelector(selectLocalization);

  useEffect(() => {
    dispatch(
      requestGetGeneratedRankings({
        token: currentUser?.jwtToken,
      })
    );
    //eslint-disable-next-line
  }, []);

  const handleChangePhase2Intermediary = () => {
    dispatch(
      generateRanking({
        filter: {
          generated: !showPhase2IntermediaryRanking,
          generatedByUserId: currentUser?.userId!,
          phaseNumber: PhaseNumber.Phase2,
          phaseType: PhaseType.Intermediary,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        requestGetGeneratedRankings({
          token: currentUser?.jwtToken,
        })
      );
    });
  };
  const handleChangePhase2Finally = () => {
    dispatch(
      generateRanking({
        filter: {
          generated: !showPhase2FinallRanking,
          generatedByUserId: currentUser?.userId!,
          phaseNumber: PhaseNumber.Phase2,
          phaseType: PhaseType.Final,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        requestGetGeneratedRankings({
          token: currentUser?.jwtToken,
        })
      );
    });
  };
  const handleChangePhase3Finally = () => {
    dispatch(
      generateRanking({
        filter: {
          generated: !showPhase3Ranking,
          generatedByUserId: currentUser?.userId!,
          phaseNumber: PhaseNumber.Phase3,
          phaseType: PhaseType.Final,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        requestGetGeneratedRankings({
          token: currentUser?.jwtToken,
        })
      );
    });
  };

  const handleShowMonitoringClick = () => {
    dispatch(
      toggleMonitoring({
        generated: !showMonitoring,
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        getFeatureStatus({
          token: currentUser?.jwtToken,
        })
      );
    });
  };

  const handleShowContestClick = () => {
    dispatch(
      toggleContest({
        generated: !showContest,
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      dispatch(
        getFeatureStatus({
          token: currentUser?.jwtToken,
        })
      );
    });
  };


  return (
    <List className="list-container" sx={{ gridTemplateColumns: showContest ? "repeat(3,1fr)" : "repeat(2,1fr)" }} >
      {showContest && <React.Fragment> <ListItem
        className="phase-container"
        onClick={handleChangePhase2Intermediary}
      >
        <Grid container direction={"column"} alignItems="center" height="100%">
          <ListItemText className="phase-title">
            {localization.administrationPage.phase2Intermediary}
          </ListItemText>
          <Box className="show-rank-button" color="success">
            {showPhase2IntermediaryRanking ? (
              <CheckBox className="phase-start" />
            ) : (
              <DoNotDisturbAlt className="phase-stop" />
            )}
          </Box>
        </Grid>
      </ListItem>
        <ListItem className="phase-container" onClick={handleChangePhase2Finally}>
          <Grid container direction={"column"} alignItems="center" height="100%">
            <ListItemText>{localization.administrationPage.phase2Final}</ListItemText>
            <Box className="show-rank-button" color="success">
              {showPhase2FinallRanking ? (
                <CheckBox className="phase-start" />
              ) : (
                <DoNotDisturbAlt className="phase-stop" />
              )}
            </Box>

          </Grid>
        </ListItem>
        <ListItem className="phase-container" onClick={handleChangePhase3Finally}>
          <Grid container direction={"column"} alignItems="center" height="100%">
            <ListItemText>{localization.administrationPage.phase3}</ListItemText>
            <Box className="show-rank-button" color="success">
              {showPhase3Ranking ? (
                <CheckBox className="phase-start" />
              ) : (
                <DoNotDisturbAlt className="phase-stop" />
              )}
            </Box>

          </Grid>
        </ListItem> </React.Fragment>}
      <ListItem
        className="phase-container"
        onClick={handleShowMonitoringClick}
      >
        <Grid container direction="column" alignItems="center" height="100%">
          <ListItemText>{localization.administrationPage.monitorizationPhase}</ListItemText>
          <Box color="success" className="show-rank-button">
            {showMonitoring ? (
              <CheckBox className="phase-start" />
            ) : (
              <DoNotDisturbAlt className="phase-stop" />
            )}
          </Box>
        </Grid>
      </ListItem>


      <ListItem
        className="phase-container"
        onClick={handleShowContestClick}
      >
        <Grid container direction="column" alignItems="center" height="100%">
          <ListItemText>{localization.administrationPage.contestPhase}</ListItemText>
          <Box color="success" className="show-rank-button">
            {showContest ? (
              <CheckBox className="phase-start" />
            ) : (
              <DoNotDisturbAlt className="phase-stop" />
            )}
          </Box>
        </Grid>
      </ListItem>

    </List>
  );
};
