export const albaSelectItemsLabel = [
  { value: "ABRUD", label: "ABRUD" },

  { value: "ABRUD-SAT", label: "ABRUD-SAT" },

  { value: "ACHIMEȚEȘTI", label: "ACHIMEȚEȘTI" },

  { value: "ACMARIU", label: "ACMARIU" },

  { value: "AIUD", label: "AIUD" },

  { value: "AIUDUL DE SUS", label: "AIUDUL DE SUS" },

  { value: "ALBA IULIA", label: "ALBA IULIA" },

  { value: "ALBAC", label: "ALBAC" },
  { value: "ALECUȘ", label: "ALECUȘ" },
  { value: "ALMAȘU DE MIJLOC", label: "ALMAȘU DE MIJLOC" },
  { value: "ALMAȘU MARE", label: "ALMAȘU MARE" },
  { value: "AMPOIȚA", label: "AMPOIȚA" },
  { value: "ANGHELEȘTI", label: "ANGHELEȘTI" },
  { value: "ARIEȘENI", label: "ARIEȘENI" },
  { value: "ARONEȘTI", label: "ARONEȘTI" },
  { value: "ARȚI", label: "ARȚI" },

  { value: "ASINIP", label: "ASINIP" },
  { value: "AVRAM IANCU", label: "AVRAM IANCU" },
  { value: "AVRĂMEȘTI / ARIEȘENI", label: "AVRĂMEȘTI / ARIEȘENI" },
  { value: "AVRĂMEȘTI / AVRAM IANCU", label: "AVRĂMEȘTI / AVRAM IANCU" },
  { value: "BABA", label: "BABA" },
  { value: "BĂCĂINȚI", label: "BĂCĂINȚI" },
  { value: "BĂDĂI", label: "BĂDĂI" },
  { value: "BĂGĂU", label: "BĂGĂU" },
  { value: "BĂI", label: "BĂI" },
  { value: "BAIA DE ARIEȘ", label: "BAIA DE ARIEȘ" },
  { value: "BĂLCACIU", label: "BĂLCACIU" },
  { value: "BĂLEȘTI", label: "BĂLEȘTI" },
  { value: "BĂLEȘTI-CĂTUN", label: "BĂLEȘTI-CĂTUN" },
  { value: "BĂLMOȘEȘTI", label: "BĂLMOȘEȘTI" },
  { value: "BALOMIRU DE CÂMP", label: "BALOMIRU DE CÂMP" },
  { value: "BĂRĂBANȚ", label: "BĂRĂBANȚ" },
  { value: "BĂRĂȘTI", label: "BĂRĂȘTI" },
  { value: "BĂRBEȘTI", label: "BĂRBEȘTI" },
  { value: "BÂRDEȘTI", label: "BÂRDEȘTI" },
  { value: "BÂRLEȘTI / BISTRA", label: "BÂRLEȘTI / BISTRA" },
  { value: "BÂRLEȘTI / MOGOȘ", label: "BÂRLEȘTI / MOGOȘ" },
  { value: "BÂRLEȘTI / SCĂRIȘOARA", label: "BÂRLEȘTI / SCĂRIȘOARA" },
  { value: "BÂRLEȘTI-CĂTUN", label: "BÂRLEȘTI-CĂTUN" },
  { value: "BÂRSANA", label: "BÂRSANA" },
  { value: "BÂRZAN", label: "BÂRZAN" },
  { value: "BÂRZOGANI", label: "BÂRZOGANI" },
  { value: "BĂZEȘTI", label: "BĂZEȘTI" },
  { value: "BELDIU", label: "BELDIU" },
  { value: "BENIC", label: "BENIC" },
  { value: "BERGHIN", label: "BERGHIN" },
  { value: "BEȚA", label: "BEȚA" },
  { value: "BIDIGEȘTI", label: "BIDIGEȘTI" },
  { value: "BIHARIA", label: "BIHARIA" },
  { value: "BIIA", label: "BIIA" },
  { value: "BILĂNEȘTI", label: "BILĂNEȘTI" },
  { value: "BISERICANI", label: "BISERICANI" },
  { value: "BISTRA", label: "BISTRA" },
  { value: "BLAJ", label: "BLAJ" },
  { value: "BLANDIANA", label: "BLANDIANA" },
  { value: "BLIDEȘTI", label: "BLIDEȘTI" },
  { value: "BOBĂREȘTI / SOHODOL", label: "BOBĂREȘTI / SOHODOL" },
  { value: "BOBĂREȘTI / VIDRA", label: "BOBĂREȘTI / VIDRA" },
  { value: "BOCEȘTI", label: "BOCEȘTI" },
  { value: "BOCȘITURA", label: "BOCȘITURA" },
  { value: "BODEȘTI", label: "BODEȘTI" },
  { value: "BODREȘTI", label: "BODREȘTI" },
  { value: "BOGDĂNEȘTI / MOGOȘ", label: "BOGDĂNEȘTI / MOGOȘ" },
  { value: "BOGDĂNEȘTI / VIDRA", label: "BOGDĂNEȘTI / VIDRA" },
  { value: "BOGLEȘTI", label: "BOGLEȘTI" },
  { value: "BOLDEȘTI", label: "BOLDEȘTI" },
  { value: "BOLOVĂNEȘTI", label: "BOLOVĂNEȘTI" },
  { value: "BONCEȘTI", label: "BONCEȘTI" },
  { value: "BORDEȘTII POIENI", label: "BORDEȘTII POIENI" },
  { value: "BORLEȘTI", label: "BORLEȘTI" },
  { value: "BOȚANI", label: "BOȚANI" },
  { value: "BOTEȘTI / ORAȘ CÂMPENI", label: "BOTEȘTI / ORAȘ CÂMPENI" },
  { value: "BOTEȘTI / ORAȘ ZLATNA", label: "BOTEȘTI / ORAȘ ZLATNA" },
  { value: "BOTEȘTI / SCĂRIȘOARA", label: "BOTEȘTI / SCĂRIȘOARA" },
  { value: "BOZ", label: "BOZ" },
  { value: "BRĂDEANA", label: "BRĂDEANA" },
  { value: "BRĂDEȘTI", label: "BRĂDEȘTI" },
  { value: "BRĂDET", label: "BRĂDET" },
  { value: "BRĂZEȘTI", label: "BRĂZEȘTI" },
  { value: "BUBEȘTI", label: "BUBEȘTI" },
  { value: "BUCERDEA GRÂNOASĂ", label: "BUCERDEA GRÂNOASĂ" },
  { value: "BUCERDEA VÂNOASĂ", label: "BUCERDEA VÂNOASĂ" },
  { value: "BUCIUM", label: "BUCIUM" },
  { value: "BUCIUM-SAT", label: "BUCIUM-SAT" },
  { value: "BUCURU", label: "BUCURU" },
  { value: "BUDĂIEȘTI", label: "BUDĂIEȘTI" },
  { value: "BUDENI", label: "BUDENI" },
  { value: "BULBUC", label: "BULBUC" },
  { value: "BUNINGINEA", label: "BUNINGINEA" },
  { value: "BUNTA", label: "BUNTA" },
  { value: "BURZEȘTI", label: "BURZEȘTI" },
  { value: "BURZONEȘTI", label: "BURZONEȘTI" },
  { value: "BUTEȘTI / HOREA", label: "BUTEȘTI / HOREA" },
  { value: "BUTEȘTI / MOGOȘ", label: "BUTEȘTI / MOGOȘ" },
  { value: "CĂLENE", label: "CĂLENE" },
  { value: "CÂLNIC", label: "CÂLNIC" },
  { value: "CĂLUGĂREȘTI", label: "CĂLUGĂREȘTI" },
  { value: "CÂMPENI", label: "CÂMPENI" },
  { value: "CÂMPU GOBLII", label: "CÂMPU GOBLII" },
  { value: "CÂNDEȘTI", label: "CÂNDEȘTI" },
  { value: "CĂPÂLNA", label: "CĂPÂLNA" },
  { value: "CĂPÂLNA DE JOS", label: "CĂPÂLNA DE JOS" },
  { value: "CĂPTĂLAN", label: "CĂPTĂLAN" },
  { value: "CAPU DEALULUI", label: "CAPU DEALULUI" },
  { value: "CĂPUD", label: "CĂPUD" },
  { value: "CÂRĂȘTI", label: "CÂRĂȘTI" },
  { value: "CARPEN", label: "CARPEN" },
  { value: "CARPENII DE SUS", label: "CARPENII DE SUS" },
  { value: "CĂRPINIȘ / GÂRBOVA", label: "CĂRPINIȘ / GÂRBOVA" },
  { value: "CĂRPINIȘ / ROȘIA MONTANĂ", label: "CĂRPINIȘ / ROȘIA MONTANĂ" },
  { value: "CÂRȚULEȘTI", label: "CÂRȚULEȘTI" },
  { value: "CASA DE PIATRĂ", label: "CASA DE PIATRĂ" },
  { value: "CĂSOAIA", label: "CĂSOAIA" },
  { value: "CENADE", label: "CENADE" },
  { value: "CERBU", label: "CERBU" },
  { value: "CERGĂU MARE", label: "CERGĂU MARE" },
  { value: "CERGĂU MIC", label: "CERGĂU MIC" },
  { value: "CERTEGE", label: "CERTEGE" },
  { value: "CERU-BĂCĂINȚI", label: "CERU-BĂCĂINȚI" },
  { value: "CETATEA DE BALTĂ", label: "CETATEA DE BALTĂ" },
  { value: "CETEA", label: "CETEA" },
  { value: "CHEIA", label: "CHEIA" },
  { value: "CHEILE CIBULUI", label: "CHEILE CIBULUI" },
  { value: "CHELETENI", label: "CHELETENI" },
  { value: "CIB", label: "CIB" },
  { value: "CICÂRD", label: "CICÂRD" },
  { value: "CICĂU", label: "CICĂU" },
  { value: "CIOARA DE SUS", label: "CIOARA DE SUS" },
  { value: "CIOCAȘU", label: "CIOCAȘU" },
  { value: "CIONEȘTI", label: "CIONEȘTI" },
  { value: "CISTEI", label: "CISTEI" },
  { value: "CISTEIU DE MUREȘ", label: "CISTEIU DE MUREȘ" },
  { value: "CIUCULEȘTI", label: "CIUCULEȘTI" },
  { value: "CIUGUD", label: "CIUGUD" },
  { value: "CIUGUDU DE JOS", label: "CIUGUDU DE JOS" },
  { value: "CIUGUDU DE SUS", label: "CIUGUDU DE SUS" },
  { value: "CIUGUZEL", label: "CIUGUZEL" },
  { value: "CIULDEȘTI", label: "CIULDEȘTI" },
  { value: "CIUMBRUD", label: "CIUMBRUD" },
  { value: "CIURULEASA", label: "CIURULEASA" },
  { value: "COASTA HENȚII", label: "COASTA HENȚII" },
  { value: "COASTA VÂSCULUI", label: "COASTA VÂSCULUI" },
  { value: "COBLEȘ", label: "COBLEȘ" },
  { value: "COCEȘTI", label: "COCEȘTI" },
  { value: "COCOȘEȘTI", label: "COCOȘEȘTI" },
  { value: "COJOCANI", label: "COJOCANI" },
  { value: "COLEȘENI", label: "COLEȘENI" },
  { value: "COLIBI", label: "COLIBI" },
  { value: "COLȚEȘTI", label: "COLȚEȘTI" },
  { value: "COPAND", label: "COPAND" },
  { value: "CORNA", label: "CORNA" },
  { value: "CORNU", label: "CORNU" },
  { value: "COROIEȘTI", label: "COROIEȘTI" },
  { value: "CORȚEȘTI", label: "CORȚEȘTI" },
  { value: "COȘLARIU", label: "COȘLARIU" },
  { value: "COȘLARIU NOU", label: "COȘLARIU NOU" },
  { value: "COSTEȘTI / ALBAC", label: "COSTEȘTI / ALBAC" },
  { value: "COSTEȘTI / POIANA VADULUI", label: "COSTEȘTI / POIANA VADULUI" },
  { value: "COTORĂȘTI", label: "COTORĂȘTI" },
  { value: "CRĂCIUNELU DE JOS", label: "CRĂCIUNELU DE JOS" },
  { value: "CRACIUNELU DE SUS", label: "CRACIUNELU DE SUS" },
  { value: "CRAIVA", label: "CRAIVA" },
  { value: "CREȚEȘTI", label: "CREȚEȘTI" },
  { value: "CRICĂU", label: "CRICĂU" },
  { value: "CRIȘENI", label: "CRIȘENI" },
  { value: "CRISTEȘTI", label: "CRISTEȘTI" },
  { value: "CUCUTA", label: "CUCUTA" },
  { value: "CUGIR", label: "CUGIR" },
  { value: "CULDEȘTI", label: "CULDEȘTI" },
  { value: "CUNȚA", label: "CUNȚA" },
  { value: "CURĂTURI", label: "CURĂTURI" },
  { value: "CURMĂTURĂ", label: "CURMĂTURĂ" },
  { value: "CURPENI", label: "CURPENI" },
  { value: "CUT", label: "CUT" },
  { value: "DAIA ROMÂNĂ", label: "DAIA ROMÂNĂ" },
  { value: "DÂMBURENI", label: "DÂMBURENI" },
  { value: "DĂNDUȚ", label: "DĂNDUȚ" },
  { value: "DÂRLEȘTI", label: "DÂRLEȘTI" },
  { value: "DĂROAIA", label: "DĂROAIA" },
  { value: "DEAL", label: "DEAL" },
  { value: "DEALU BAJULUI", label: "DEALU BAJULUI" },
  { value: "DEALU BISTRII", label: "DEALU BISTRII" },
  { value: "DEALU CAPSEI", label: "DEALU CAPSEI" },
  { value: "DEALU CASELOR", label: "DEALU CASELOR" },
  { value: "DEALU CRIȘULUI", label: "DEALU CRIȘULUI" },
  { value: "DEALU DOȘTATULUI", label: "DEALU DOȘTATULUI" },
  { value: "DEALU FERULUI", label: "DEALU FERULUI" },
  {
    value: "DEALU FRUMOS / GÂRDA DE SUS",
    label: "DEALU FRUMOS / GÂRDA DE SUS",
  },
  {
    value: "DEALU FRUMOS / VADU MOȚILOR",
    label: "DEALU FRUMOS / VADU MOȚILOR",
  },
  { value: "DEALU GEOAGIULUI", label: "DEALU GEOAGIULUI" },
  { value: "DEALU GOIEȘTI", label: "DEALU GOIEȘTI" },
  { value: "DEALU LĂMĂȘOI", label: "DEALU LĂMĂȘOI" },
  { value: "DEALU MUNTELUI", label: "DEALU MUNTELUI" },
  { value: "DEALU ORDÂNCUȘII", label: "DEALU ORDÂNCUȘII" },
  { value: "DEALU ROATEI", label: "DEALU ROATEI" },
  { value: "DECEA", label: "DECEA" },
  { value: "DELENI-OBÂRȘIE", label: "DELENI-OBÂRȘIE" },
  { value: "DEONCEȘTI", label: "DEONCEȘTI" },
  { value: "DEVE", label: "DEVE" },
  { value: "DILIMANI", label: "DILIMANI" },
  { value: "DOBRA", label: "DOBRA" },
  { value: "DOBREȘTI", label: "DOBREȘTI" },
  { value: "DOBROT", label: "DOBROT" },
  { value: "DOGĂREȘTI", label: "DOGĂREȘTI" },
  { value: "DOLEȘTI", label: "DOLEȘTI" },
  { value: "DOPTĂU", label: "DOPTĂU" },
  { value: "DOS", label: "DOS" },
  { value: "DOȘTAT", label: "DOȘTAT" },
  { value: "DOSU LUNCII", label: "DOSU LUNCII" },
  { value: "DOSU VĂSEȘTI", label: "DOSU VĂSEȘTI" },
  { value: "DRĂGOIEȘTI-LUNCĂ", label: "DRĂGOIEȘTI-LUNCĂ" },
  { value: "DRÂMBAR", label: "DRÂMBAR" },
  { value: "DRAȘOV", label: "DRAȘOV" },
  { value: "DRIC", label: "DRIC" },
  { value: "DUDUIENI", label: "DUDUIENI" },
  { value: "DUMĂCEȘTI", label: "DUMĂCEȘTI" },
  { value: "DUMBRAVA / ORAȘ ZLATNA", label: "DUMBRAVA / ORAȘ ZLATNA" },
  { value: "DUMBRAVA / SĂSCIORI", label: "DUMBRAVA / SĂSCIORI" },
  { value: "DUMBRAVA / UNIREA", label: "DUMBRAVA / UNIREA" },
  { value: "DUMBRĂVIȚA", label: "DUMBRĂVIȚA" },
  { value: "DUMEȘTI", label: "DUMEȘTI" },
  { value: "DUMITRA", label: "DUMITRA" },
  { value: "DUPA DEAL", label: "DUPA DEAL" },
  { value: "DUPĂ DEAL", label: "DUPĂ DEAL" },
  { value: "DUPĂ PLEȘE", label: "DUPĂ PLEȘE" },
  { value: "DURĂȘTI", label: "DURĂȘTI" },
  { value: "FĂGET", label: "FĂGET" },
  { value: "FĂGETU DE JOS", label: "FĂGETU DE JOS" },
  { value: "FĂGETU DE SUS", label: "FĂGETU DE SUS" },
  { value: "FÂNTÂNELE", label: "FÂNTÂNELE" },
  { value: "FĂRĂU", label: "FĂRĂU" },
  { value: "FAȚA", label: "FAȚA" },
  { value: "FAȚA ABRUDULUI", label: "FAȚA ABRUDULUI" },
  { value: "FAȚA CRISTESEI", label: "FAȚA CRISTESEI" },
  { value: "FAȚA LĂPUȘULUI", label: "FAȚA LĂPUȘULUI" },
  { value: "FAȚA PIETRII", label: "FAȚA PIETRII" },
  { value: "FAȚA-LĂZEȘTI", label: "FAȚA-LĂZEȘTI" },
  { value: "FEISA", label: "FEISA" },
  { value: "FENEȘ", label: "FENEȘ" },
  { value: "FEREȘTI", label: "FEREȘTI" },
  { value: "FERICET", label: "FERICET" },
  { value: "FEȚENI", label: "FEȚENI" },
  { value: "FICĂREȘTI", label: "FICĂREȘTI" },
  { value: "FLITEȘTI", label: "FLITEȘTI" },
  { value: "FLOREȘTI / ORAȘ CÂMPENI", label: "FLOREȘTI / ORAȘ CÂMPENI" },
  { value: "FLOREȘTI / BUCIUM", label: "FLOREȘTI / BUCIUM" },
  { value: "FLOREȘTI / RÂMEȚ", label: "FLOREȘTI / RÂMEȚ" },
  { value: "FLOREȘTI / SCĂRIȘOARA", label: "FLOREȘTI / SCĂRIȘOARA" },
  { value: "FURDUIEȘTI / ORAȘ CÂMPENI", label: "FURDUIEȘTI / ORAȘ CÂMPENI" },
  { value: "FURDUIEȘTI / SOHODOL", label: "FURDUIEȘTI / SOHODOL" },
  { value: "GĂBUD", label: "GĂBUD" },
  { value: "GALAȚI", label: "GALAȚI" },
  { value: "GALBENA", label: "GALBENA" },
  { value: "GALDA DE JOS", label: "GALDA DE JOS" },
  { value: "GALDA DE SUS", label: "GALDA DE SUS" },
  { value: "GALTIU", label: "GALTIU" },
  { value: "GÂMBAȘ", label: "GÂMBAȘ" },
  { value: "GĂNEȘTI", label: "GĂNEȘTI" },
  { value: "GÂRBOVA", label: "GÂRBOVA" },
  { value: "GÂRBOVA DE JOS", label: "GÂRBOVA DE JOS" },
  { value: "GÂRBOVA DE SUS", label: "GÂRBOVA DE SUS" },
  { value: "GÂRBOVIȚA", label: "GÂRBOVIȚA" },
  { value: "GÂRDA DE SUS", label: "GÂRDA DE SUS" },
  { value: "GÂRDA SEACĂ", label: "GÂRDA SEACĂ" },
  { value: "GÂRDA-BĂRBULEȘTI", label: "GÂRDA-BĂRBULEȘTI" },
  { value: "GÂRDE", label: "GÂRDE" },
  { value: "GEAMĂNA", label: "GEAMĂNA" },
  { value: "GEOAGIU DE SUS", label: "GEOAGIU DE SUS" },
  { value: "GEOGEL", label: "GEOGEL" },
  { value: "GEOMAL", label: "GEOMAL" },
  { value: "GHEDULEȘTI", label: "GHEDULEȘTI" },
  { value: "GHEȚARI", label: "GHEȚARI" },
  { value: "GHIONCANI", label: "GHIONCANI" },
  { value: "GHIRBOM", label: "GHIRBOM" },
  { value: "GIURGIUȚ", label: "GIURGIUȚ" },
  { value: "GLIGOREȘTI", label: "GLIGOREȘTI" },
  { value: "GLOD", label: "GLOD" },
  { value: "GLOGOVEȚ", label: "GLOGOVEȚ" },
  { value: "GOAȘELE", label: "GOAȘELE" },
  { value: "GOIEȘTI", label: "GOIEȘTI" },
  { value: "GOJEIEȘTI", label: "GOJEIEȘTI" },
  { value: "GORGAN", label: "GORGAN" },
  { value: "GROȘI", label: "GROȘI" },
  { value: "GURA ARIEȘULUI", label: "GURA ARIEȘULUI" },
  { value: "GURA CORNEI", label: "GURA CORNEI" },
  { value: "GURA CUȚULUI", label: "GURA CUȚULUI" },
  { value: "GURA IZBITEI", label: "GURA IZBITEI" },
  { value: "GURA ROȘIEI", label: "GURA ROȘIEI" },
  { value: "GURA SOHODOL", label: "GURA SOHODOL" },
  { value: "HĂDĂRĂU", label: "HĂDĂRĂU" },
  { value: "HAIDUCEȘTI", label: "HAIDUCEȘTI" },
  { value: "HANĂȘEȘTI", label: "HANĂȘEȘTI" },
  { value: "HĂNĂȘEȘTI", label: "HĂNĂȘEȘTI" },
  { value: "HĂPRIA", label: "HĂPRIA" },
  { value: "HĂRĂȘTI", label: "HĂRĂȘTI" },
  { value: "HAȚEGANA", label: "HAȚEGANA" },
  { value: "HELEREȘTI", label: "HELEREȘTI" },
  { value: "HELEȘTI", label: "HELEȘTI" },
  { value: "HENIG", label: "HENIG" },
  { value: "HERIA", label: "HERIA" },
  { value: "HOANCĂ / SOHODOL", label: "HOANCĂ / SOHODOL" },
  { value: "HOANCĂ / VIDRA", label: "HOANCĂ / VIDRA" },
  { value: "HODIȘEȘTI", label: "HODIȘEȘTI" },
  { value: "HODOBANA", label: "HODOBANA" },
  { value: "HOLOBANI", label: "HOLOBANI" },
  { value: "HOPÂRTA", label: "HOPÂRTA" },
  { value: "HOREA", label: "HOREA" },
  { value: "HUDRICEȘTI", label: "HUDRICEȘTI" },
  { value: "HUZĂREȘTI", label: "HUZĂREȘTI" },
  { value: "IACOBEȘTI", label: "IACOBEȘTI" },
  { value: "IBRU", label: "IBRU" },
  { value: "ICLOD", label: "ICLOD" },
  { value: "IGHIEL", label: "IGHIEL" },
  { value: "IGHIU", label: "IGHIU" },
  { value: "IGNĂȚEȘTI", label: "IGNĂȚEȘTI" },
  { value: "ILIEȘTI", label: "ILIEȘTI" },
  { value: "INCEȘTI / AVRAM IANCU", label: "INCEȘTI / AVRAM IANCU" },
  { value: "INCEȘTI / POȘAGA", label: "INCEȘTI / POȘAGA" },
  { value: "INOC", label: "INOC" },
  { value: "ÎNTREGALDE", label: "ÎNTREGALDE" },
  { value: "INURI", label: "INURI" },
  { value: "ISCA", label: "ISCA" },
  { value: "IVĂNIȘ", label: "IVĂNIȘ" },
  { value: "IZBICIOARA", label: "IZBICIOARA" },
  { value: "IZBITA", label: "IZBITA" },
  { value: "IZLAZ", label: "IZLAZ" },
  {
    value: "IZVOARELE / MUNICIPIUL BLAJ",
    label: "IZVOARELE / MUNICIPIUL BLAJ",
  },
  { value: "IZVOARELE / GÂRDA DE SUS", label: "IZVOARELE / GÂRDA DE SUS" },
  { value: "IZVOARELE / LIVEZILE", label: "IZVOARELE / LIVEZILE" },
  { value: "IZVORU AMPOIULUI", label: "IZVORU AMPOIULUI" },
  { value: "JEFLEȘTI", label: "JEFLEȘTI" },
  { value: "JIDOȘTINA", label: "JIDOȘTINA" },
  { value: "JIDVEI", label: "JIDVEI" },
  { value: "JOJEI", label: "JOJEI" },
  { value: "JOLDIȘEȘTI", label: "JOLDIȘEȘTI" },
  { value: "JURCUIEȘTI", label: "JURCUIEȘTI" },
  { value: "LANCRĂM", label: "LANCRĂM" },
  { value: "LAZ / SĂSCIORI", label: "LAZ / SĂSCIORI" },
  { value: "LAZ / VINȚU DE JOS", label: "LAZ / VINȚU DE JOS" },
  { value: "LĂZEȘTI / SCĂRIȘOARA", label: "LĂZEȘTI / SCĂRIȘOARA" },
  { value: "LĂZEȘTI / VADU MOȚILOR", label: "LĂZEȘTI / VADU MOȚILOR" },
  { value: "LAZURI / LUPȘA", label: "LAZURI / LUPȘA" },
  { value: "LAZURI / SOHODOL", label: "LAZURI / SOHODOL" },
  { value: "LEHEȘTI", label: "LEHEȘTI" },
  { value: "LEORINȚ", label: "LEORINȚ" },
  { value: "LESPEZEA", label: "LESPEZEA" },
  { value: "LIMBA", label: "LIMBA" },
  { value: "LIPAIA", label: "LIPAIA" },
  { value: "LIVEZILE", label: "LIVEZILE" },
  { value: "LODROMAN", label: "LODROMAN" },
  { value: "LOMAN", label: "LOMAN" },
  { value: "LOPADEA NOUĂ", label: "LOPADEA NOUĂ" },
  { value: "LOPADEA VECHE", label: "LOPADEA VECHE" },
  { value: "LUMINEȘTI", label: "LUMINEȘTI" },
  { value: "LUNCA / LUPȘA", label: "LUNCA / LUPȘA" },
  { value: "LUNCA / POȘAGA", label: "LUNCA / POȘAGA" },
  { value: "LUNCA / VALEA LUNGĂ", label: "LUNCA / VALEA LUNGĂ" },
  { value: "LUNCA / VIDRA", label: "LUNCA / VIDRA" },
  { value: "LUNCA AMPOIȚEI", label: "LUNCA AMPOIȚEI" },
  { value: "LUNCA BISERICII", label: "LUNCA BISERICII" },
  { value: "LUNCA DE JOS", label: "LUNCA DE JOS" },
  { value: "LUNCA GOIEȘTI", label: "LUNCA GOIEȘTI" },
  { value: "LUNCA LARGĂ / BISTRA", label: "LUNCA LARGĂ / BISTRA" },
  { value: "LUNCA LARGĂ / OCOLIȘ", label: "LUNCA LARGĂ / OCOLIȘ" },
  { value: "LUNCA MERILOR", label: "LUNCA MERILOR" },
  { value: "LUNCA METEȘULUI", label: "LUNCA METEȘULUI" },
  { value: "LUNCA MUREȘULUI", label: "LUNCA MUREȘULUI" },
  { value: "LUNCA TÂRNAVEI", label: "LUNCA TÂRNAVEI" },
  { value: "LUNCA VESEȘTI", label: "LUNCA VESEȘTI" },
  { value: "LUPĂIEȘTI", label: "LUPĂIEȘTI" },
  { value: "LUPȘA", label: "LUPȘA" },
  { value: "LUPȘENI", label: "LUPȘENI" },
  { value: "LUPU", label: "LUPU" },
  { value: "LUPULEȘTI", label: "LUPULEȘTI" },
  { value: "MĂCĂREȘTI", label: "MĂCĂREȘTI" },
  { value: "MĂGHIERAT", label: "MĂGHIERAT" },
  { value: "MĂGINA", label: "MĂGINA" },
  { value: "MĂGURA / BUCIUM", label: "MĂGURA / BUCIUM" },
  { value: "MĂGURA / GALDA DE JOS", label: "MĂGURA / GALDA DE JOS" },
  { value: "MĂHĂCENI", label: "MĂHĂCENI" },
  { value: "MĂMĂLIGANI", label: "MĂMĂLIGANI" },
  { value: "MĂNĂRADE", label: "MĂNĂRADE" },
  { value: "MĂNĂSTIRE", label: "MĂNĂSTIRE" },
  { value: "MĂNCEȘTI", label: "MĂNCEȘTI" },
  { value: "MĂRGAIA", label: "MĂRGAIA" },
  { value: "MĂRGINENI", label: "MĂRGINENI" },
  { value: "MĂRINEȘTI", label: "MĂRINEȘTI" },
  { value: "MĂRTEȘTI", label: "MĂRTEȘTI" },
  { value: "MĂRTINIE", label: "MĂRTINIE" },
  { value: "MĂTĂCINA", label: "MĂTĂCINA" },
  { value: "MAȚEI", label: "MAȚEI" },
  { value: "MĂTIȘEȘTI / CIURULEASA", label: "MĂTIȘEȘTI / CIURULEASA" },
  { value: "MĂTIȘEȘTI / HOREA", label: "MĂTIȘEȘTI / HOREA" },
  { value: "MEDREȘTI", label: "MEDREȘTI" },
  { value: "MEDVEȘ", label: "MEDVEȘ" },
  { value: "MERETEU", label: "MERETEU" },
  { value: "MEȘCREAC", label: "MEȘCREAC" },
  { value: "MESENTEA", label: "MESENTEA" },
  { value: "METEȘ", label: "METEȘ" },
  { value: "MICEȘTI", label: "MICEȘTI" },
  { value: "MICOȘLACA", label: "MICOȘLACA" },
  { value: "MIHĂIEȘTI", label: "MIHĂIEȘTI" },
  { value: "MIHALȚ", label: "MIHALȚ" },
  { value: "MIHOEȘTI", label: "MIHOEȘTI" },
  { value: "MIRĂSLĂU", label: "MIRĂSLĂU" },
  { value: "MODOLEȘTI / ÎNTREGALDE", label: "MODOLEȘTI / ÎNTREGALDE" },
  { value: "MODOLEȘTI / VIDRA", label: "MODOLEȘTI / VIDRA" },
  { value: "MOGOȘ", label: "MOGOȘ" },
  { value: "MORĂREȘTI / CIURULEASA", label: "MORĂREȘTI / CIURULEASA" },
  { value: "MORĂREȘTI / SOHODOL", label: "MORĂREȘTI / SOHODOL" },
  { value: "MORCĂNEȘTI", label: "MORCĂNEȘTI" },
  { value: "MOTORĂȘTI", label: "MOTORĂȘTI" },
  { value: "MUGEȘTI", label: "MUGEȘTI" },
  { value: "MUNCELU", label: "MUNCELU" },
  { value: "MUNEȘTI", label: "MUNEȘTI" },
  { value: "MUNTARI", label: "MUNTARI" },
  { value: "MUNUNĂ", label: "MUNUNĂ" },
  { value: "MUȘCA", label: "MUȘCA" },
  { value: "NĂDĂȘTIA", label: "NĂDĂȘTIA" },
  { value: "NĂMAȘ", label: "NĂMAȘ" },
  { value: "NĂPĂIEȘTI", label: "NĂPĂIEȘTI" },
  { value: "NECRILEȘTI", label: "NECRILEȘTI" },
  { value: "NECȘEȘTI", label: "NECȘEȘTI" },
  { value: "NEGEȘTI", label: "NEGEȘTI" },
  { value: "NEGREȘTI", label: "NEGREȘTI" },
  { value: "NELEGEȘTI", label: "NELEGEȘTI" },
  { value: "NEMEȘI", label: "NEMEȘI" },
  { value: "NICOREȘTI", label: "NICOREȘTI" },
  { value: "NICULEȘTI", label: "NICULEȘTI" },
  { value: "NOȘLAC", label: "NOȘLAC" },
  { value: "NOVĂCEȘTI", label: "NOVĂCEȘTI" },
  { value: "OARDA", label: "OARDA" },
  { value: "OBREJA", label: "OBREJA" },
  { value: "OCNA MUREȘ", label: "OCNA MUREȘ" },
  { value: "OCNIȘOARA", label: "OCNIȘOARA" },
  { value: "OCOALE", label: "OCOALE" },
  { value: "OCOLIȘ", label: "OCOLIȘ" },
  { value: "ODVEREM", label: "ODVEREM" },
  { value: "OHABA", label: "OHABA" },
  { value: "OIDEȘTI", label: "OIDEȘTI" },
  { value: "OIEJDEA", label: "OIEJDEA" },
  { value: "OLTENI", label: "OLTENI" },
  { value: "ONCEȘTI", label: "ONCEȘTI" },
  { value: "ORĂȘTI", label: "ORĂȘTI" },
  { value: "ORGEȘTI", label: "ORGEȘTI" },
  { value: "ORMENIȘ", label: "ORMENIȘ" },
  { value: "PÂCLIȘA", label: "PÂCLIȘA" },
  { value: "PĂDURE", label: "PĂDURE" },
  { value: "PĂDUREA", label: "PĂDUREA" },
  { value: "PĂGIDA", label: "PĂGIDA" },
  { value: "PĂNADE", label: "PĂNADE" },
  { value: "PÂNCA", label: "PÂNCA" },
  { value: "PĂNTEȘTI", label: "PĂNTEȘTI" },
  { value: "PÂRĂU GRUIULUI", label: "PÂRĂU GRUIULUI" },
  { value: "PÂRĂU LUI MIHAI", label: "PÂRĂU LUI MIHAI" },
  { value: "PÂRÂU-CĂRBUNĂRI", label: "PÂRÂU-CĂRBUNĂRI" },
  { value: "PĂȘTEȘTI", label: "PĂȘTEȘTI" },
  { value: "PĂTRĂHĂIȚEȘTI", label: "PĂTRĂHĂIȚEȘTI" },
  { value: "PĂTRÂNGENI", label: "PĂTRÂNGENI" },
  { value: "PĂTRUȘEȘTI", label: "PĂTRUȘEȘTI" },
  { value: "PĂTRUȚEȘTI", label: "PĂTRUȚEȘTI" },
  { value: "PELEȘ", label: "PELEȘ" },
  { value: "PERJEȘTI", label: "PERJEȘTI" },
  { value: "PESTE VALEA BISTRII", label: "PESTE VALEA BISTRII" },
  { value: "PEȚELCA", label: "PEȚELCA" },
  { value: "PETELEI", label: "PETELEI" },
  { value: "PETREASA", label: "PETREASA" },
  { value: "PETRENI", label: "PETRENI" },
  { value: "PETREȘTI", label: "PETREȘTI" },
  { value: "PETRISAT", label: "PETRISAT" },
  { value: "PIANU DE JOS", label: "PIANU DE JOS" },
  { value: "PIANU DE SUS", label: "PIANU DE SUS" },
  { value: "PIRITA", label: "PIRITA" },
  { value: "PITĂRCEȘTI", label: "PITĂRCEȘTI" },
  { value: "PIȚIGA", label: "PIȚIGA" },
  { value: "PLAI / AVRAM IANCU", label: "PLAI / AVRAM IANCU" },
  { value: "PLAI / GÂRDA DE SUS", label: "PLAI / GÂRDA DE SUS" },
  { value: "PLAIURI", label: "PLAIURI" },
  { value: "PLEȘCUȚA", label: "PLEȘCUȚA" },
  { value: "PLEȘEȘTI", label: "PLEȘEȘTI" },
  { value: "PLEȘI", label: "PLEȘI" },
  { value: "PLIȘTI", label: "PLIȘTI" },
  { value: "PODU LUI PAUL", label: "PODU LUI PAUL" },
  { value: "PODURI", label: "PODURI" },
  { value: "PODURI-BRICEȘTI", label: "PODURI-BRICEȘTI" },
  { value: "POIANA / BISTRA", label: "POIANA / BISTRA" },
  { value: "POIANA / BUCIUM", label: "POIANA / BUCIUM" },
  { value: "POIANA / SOHODOL", label: "POIANA / SOHODOL" },
  { value: "POIANA AIUDULUI", label: "POIANA AIUDULUI" },
  { value: "POIANA AMPOIULUI", label: "POIANA AMPOIULUI" },
  { value: "POIANA GALDEI", label: "POIANA GALDEI" },
  { value: "POIANA URSULUI", label: "POIANA URSULUI" },
  { value: "POIANA VADULUI", label: "POIANA VADULUI" },
  { value: "POIENI / BLANDIANA", label: "POIENI / BLANDIANA" },
  { value: "POIENI / BUCIUM", label: "POIENI / BUCIUM" },
  { value: "POIENI / VIDRA", label: "POIENI / VIDRA" },
  { value: "POIENILE-MOGOȘ", label: "POIENILE-MOGOȘ" },
  { value: "POIENIȚA / ARIEȘENI", label: "POIENIȚA / ARIEȘENI" },
  { value: "POIENIȚA / VINȚU DE JOS", label: "POIENIȚA / VINȚU DE JOS" },
  { value: "POIU", label: "POIU" },
  { value: "PONOR", label: "PONOR" },
  { value: "PONOREL", label: "PONOREL" },
  { value: "POPEȘTI", label: "POPEȘTI" },
  { value: "POPEȘTII DE JOS", label: "POPEȘTII DE JOS" },
  { value: "POPEȘTII DE SUS", label: "POPEȘTII DE SUS" },
  { value: "POȘAGA DE JOS", label: "POȘAGA DE JOS" },
  { value: "POȘAGA DE SUS", label: "POȘAGA DE SUS" },
  { value: "POȘOGANI", label: "POȘOGANI" },
  { value: "POTIONCI", label: "POTIONCI" },
  { value: "PRELUCA", label: "PRELUCA" },
  { value: "PRELUCĂ", label: "PRELUCĂ" },
  { value: "PRESACA AMPOIULUI", label: "PRESACA AMPOIULUI" },
  { value: "PUIULEȚEȘTI", label: "PUIULEȚEȘTI" },
  { value: "PURCĂREȚI", label: "PURCĂREȚI" },
  { value: "PUȘELEȘTI", label: "PUȘELEȘTI" },
  { value: "RĂCĂTĂU", label: "RĂCĂTĂU" },
  { value: "RACHIȘ", label: "RACHIȘ" },
  { value: "RĂCHITA", label: "RĂCHITA" },
  { value: "RĂDEȘTI", label: "RĂDEȘTI" },
  { value: "RĂHĂU", label: "RĂHĂU" },
  { value: "RĂICANI", label: "RĂICANI" },
  { value: "RÂMEȚ", label: "RÂMEȚ" },
  { value: "RÂMETEA", label: "RÂMETEA" },
  { value: "RĂTITIȘ", label: "RĂTITIȘ" },
  { value: "RAVICEȘTI", label: "RAVICEȘTI" },
  { value: "RĂZBOIENI-CETATE", label: "RĂZBOIENI-CETATE" },
  { value: "RECIU", label: "RECIU" },
  { value: "REMETEA", label: "REMETEA" },
  { value: "ROBEȘTI", label: "ROBEȘTI" },
  { value: "ROGOZ", label: "ROGOZ" },
  { value: "ROȘEȘTI", label: "ROȘEȘTI" },
  { value: "ROȘIA DE SECAȘ", label: "ROȘIA DE SECAȘ" },
  { value: "ROȘIA MONTANĂ", label: "ROȘIA MONTANĂ" },
  { value: "RUNC / ORAȘ ZLATNA", label: "RUNC / ORAȘ ZLATNA" },
  { value: "RUNC / OCOLIȘ", label: "RUNC / OCOLIȘ" },
  { value: "RUNC / SCĂRIȘOARA", label: "RUNC / SCĂRIȘOARA" },
  { value: "RUNC / VIDRA", label: "RUNC / VIDRA" },
  { value: "RUNCURI", label: "RUNCURI" },
  { value: "RUSEȘTI", label: "RUSEȘTI" },
  { value: "RUȘI", label: "RUȘI" },
  { value: "SAGAGEA", label: "SAGAGEA" },
  { value: "SĂLĂGEȘTI", label: "SĂLĂGEȘTI" },
  { value: "SĂLCIUA DE JOS", label: "SĂLCIUA DE JOS" },
  { value: "SĂLCIUA DE SUS", label: "SĂLCIUA DE SUS" },
  { value: "SĂLIȘTEA", label: "SĂLIȘTEA" },
  { value: "SĂLIȘTEA-DEAL", label: "SĂLIȘTEA-DEAL" },
  { value: "SÂNBENEDIC", label: "SÂNBENEDIC" },
  { value: "SÂNCEL", label: "SÂNCEL" },
  { value: "SÂNCRAI", label: "SÂNCRAI" },
  { value: "SÂNMICLĂUȘ", label: "SÂNMICLĂUȘ" },
  { value: "SÂNTĂMĂRIE", label: "SÂNTĂMĂRIE" },
  { value: "SÂNTIMBRU", label: "SÂNTIMBRU" },
  { value: "SĂRĂCSĂU", label: "SĂRĂCSĂU" },
  { value: "ȘARD", label: "ȘARD" },
  { value: "SARTĂȘ", label: "SARTĂȘ" },
  { value: "ȘASA", label: "ȘASA" },
  { value: "SĂSCIORI", label: "SĂSCIORI" },
  { value: "SCĂRIȘOARA", label: "SCĂRIȘOARA" },
  { value: "SCOARȚA", label: "SCOARȚA" },
  { value: "SEBEȘ", label: "SEBEȘ" },
  { value: "SEBEȘEL", label: "SEBEȘEL" },
  { value: "SEBIȘEȘTI", label: "SEBIȘEȘTI" },
  { value: "SECĂȘEL", label: "SECĂȘEL" },
  { value: "SEGAJ", label: "SEGAJ" },
  { value: "ȘEUȘA", label: "ȘEUȘA" },
  { value: "SFÂRCEA", label: "SFÂRCEA" },
  { value: "SFOARTEA", label: "SFOARTEA" },
  { value: "ȘIBOT", label: "ȘIBOT" },
  { value: "SICOIEȘTI", label: "SICOIEȘTI" },
  { value: "ȘILEA", label: "ȘILEA" },
  { value: "SILIVAȘ", label: "SILIVAȘ" },
  { value: "ȘIMOCEȘTI", label: "ȘIMOCEȘTI" },
  { value: "SIMULEȘTI", label: "SIMULEȘTI" },
  { value: "SNIDE", label: "SNIDE" },
  { value: "ȘOAL", label: "ȘOAL" },
  { value: "SOHARU", label: "SOHARU" },
  { value: "SOHODOL / ALBAC", label: "SOHODOL / ALBAC" },
  { value: "SOHODOL / SOHODOL", label: "SOHODOL / SOHODOL" },
  { value: "ȘOICEȘTI", label: "ȘOICEȘTI" },
  { value: "ȘOIMUȘ", label: "ȘOIMUȘ" },
  { value: "ȘONA", label: "ȘONA" },
  { value: "SORLIȚA", label: "SORLIȚA" },
  { value: "ȘPĂLNACA", label: "ȘPĂLNACA" },
  { value: "SPĂTAC", label: "SPĂTAC" },
  { value: "ȘPRING", label: "ȘPRING" },
  { value: "STÂLNIȘOARA", label: "STÂLNIȘOARA" },
  { value: "STÂNA DE MUREȘ", label: "STÂNA DE MUREȘ" },
  { value: "STĂNEȘTI", label: "STĂNEȘTI" },
  { value: "STĂUINI", label: "STĂUINI" },
  { value: "ȘTEFANCA", label: "ȘTEFANCA" },
  { value: "ȘTEI-ARIEȘENI", label: "ȘTEI-ARIEȘENI" },
  { value: "ȘTERTEȘTI", label: "ȘTERTEȘTI" },
  { value: "ȘTIULEȚI", label: "ȘTIULEȚI" },
  { value: "STRAJA", label: "STRAJA" },
  { value: "STREMȚ", label: "STREMȚ" },
  { value: "STRUNGARI", label: "STRUNGARI" },
  { value: "STURU", label: "STURU" },
  { value: "SUB PIATRĂ", label: "SUB PIATRĂ" },
  { value: "SUCEȘTI", label: "SUCEȘTI" },
  { value: "ȘUGAG", label: "ȘUGAG" },
  { value: "SURDEȘTI", label: "SURDEȘTI" },
  { value: "SUSENI", label: "SUSENI" },
  { value: "TAMBOREȘTI", label: "TAMBOREȘTI" },
  { value: "ȚĂRĂNEȘTI", label: "ȚĂRĂNEȘTI" },
  { value: "ȚARINA", label: "ȚARINA" },
  { value: "TÂRSA", label: "TÂRSA" },
  { value: "TÂRSA-PLAI", label: "TÂRSA-PLAI" },
  { value: "TĂRTĂRIA", label: "TĂRTĂRIA" },
  { value: "TĂTÂRLAUA", label: "TĂTÂRLAUA" },
  { value: "TĂU", label: "TĂU" },
  { value: "TĂU BISTRA", label: "TĂU BISTRA" },
  { value: "TĂUNI", label: "TĂUNI" },
  { value: "TĂUȚI", label: "TĂUȚI" },
  { value: "TECȘEȘTI", label: "TECȘEȘTI" },
  { value: "TEIU", label: "TEIU" },
  { value: "TEIUȘ", label: "TEIUȘ" },
  { value: "TELEAC", label: "TELEAC" },
  { value: "ȚELNA", label: "ȚELNA" },
  { value: "TIBRU", label: "TIBRU" },
  { value: "ȚIFRA", label: "ȚIFRA" },
  { value: "TIUR", label: "TIUR" },
  { value: "TOCI", label: "TOCI" },
  { value: "TOLĂCEȘTI", label: "TOLĂCEȘTI" },
  { value: "TOMEȘTI", label: "TOMEȘTI" },
  { value: "TOMNATEC", label: "TOMNATEC" },
  { value: "TOMUȘEȘTI", label: "TOMUȘEȘTI" },
  { value: "TOMUȚEȘTI", label: "TOMUȚEȘTI" },
  { value: "TONEA", label: "TONEA" },
  { value: "TOȚEȘTI", label: "TOȚEȘTI" },
  { value: "TOTOI", label: "TOTOI" },
  { value: "TRÂMPOIELE", label: "TRÂMPOIELE" },
  { value: "TRÂNCEȘTI", label: "TRÂNCEȘTI" },
  { value: "TRIFEȘTI / HOREA", label: "TRIFEȘTI / HOREA" },
  { value: "TRIFEȘTI / LUPȘA", label: "TRIFEȘTI / LUPȘA" },
  { value: "TRIȘOREȘTI", label: "TRIȘOREȘTI" },
  { value: "TURDAȘ", label: "TURDAȘ" },
  { value: "UIOARA DE JOS", label: "UIOARA DE JOS" },
  { value: "UIOARA DE SUS", label: "UIOARA DE SUS" },
  { value: "UNGUREI", label: "UNGUREI" },
  { value: "UNIREA", label: "UNIREA" },
  { value: "URDEȘ", label: "URDEȘ" },
  { value: "VADU MOȚILOR", label: "VADU MOȚILOR" },
  { value: "VĂI", label: "VĂI" },
  { value: "VÂLCĂNEASA", label: "VÂLCĂNEASA" },
  { value: "VÂLCEA", label: "VÂLCEA" },
  { value: "VÂLCEȘTI", label: "VÂLCEȘTI" },
  { value: "VALE IN JOS", label: "VALE IN JOS" },
  { value: "VALEA ABRUZEL", label: "VALEA ABRUZEL" },
  { value: "VALEA ALBĂ", label: "VALEA ALBĂ" },
  { value: "VALEA BÂRLUȚEȘTI", label: "VALEA BÂRLUȚEȘTI" },
  { value: "VALEA BARNII", label: "VALEA BARNII" },
  { value: "VALEA BISTRII", label: "VALEA BISTRII" },
  { value: "VALEA BUCURULUI", label: "VALEA BUCURULUI" },
  { value: "VALEA CASELOR", label: "VALEA CASELOR" },
  { value: "VALEA CERBULUI", label: "VALEA CERBULUI" },
  { value: "VALEA CIUCIULUI", label: "VALEA CIUCIULUI" },
  { value: "VALEA COCEȘTI", label: "VALEA COCEȘTI" },
  { value: "VALEA FĂGETULUI", label: "VALEA FĂGETULUI" },
  { value: "VALEA GIOGEȘTI", label: "VALEA GIOGEȘTI" },
  { value: "VALEA GOBLII", label: "VALEA GOBLII" },
  { value: "VALEA HOLHORII", label: "VALEA HOLHORII" },
  { value: "VALEA INZELULUI", label: "VALEA INZELULUI" },
  { value: "VALEA LARGĂ", label: "VALEA LARGĂ" },
  { value: "VALEA LUI MIHAI", label: "VALEA LUI MIHAI" },
  { value: "VALEA LUNGĂ", label: "VALEA LUNGĂ" },
  { value: "VALEA LUPȘII", label: "VALEA LUPȘII" },
  { value: "VALEA MACIULUI", label: "VALEA MACIULUI" },
  { value: "VALEA MĂNĂSTIRII", label: "VALEA MĂNĂSTIRII" },
  { value: "VALEA MARE", label: "VALEA MARE" },
  { value: "VALEA MICĂ", label: "VALEA MICĂ" },
  { value: "VALEA MLACII", label: "VALEA MLACII" },
  { value: "VALEA MORII", label: "VALEA MORII" },
  { value: "VALEA NEGRILESII", label: "VALEA NEGRILESII" },
  { value: "VALEA POIENII / BUCIUM", label: "VALEA POIENII / BUCIUM" },
  { value: "VALEA POIENII / RÂMEȚ", label: "VALEA POIENII / RÂMEȚ" },
  { value: "VALEA SASULUI", label: "VALEA SASULUI" },
  { value: "VALEA ȘESII / BUCIUM", label: "VALEA ȘESII / BUCIUM" },
  { value: "VALEA ȘESII / LUPȘA", label: "VALEA ȘESII / LUPȘA" },
  { value: "VALEA ȚUPILOR", label: "VALEA ȚUPILOR" },
  { value: "VALEA UȚULUI", label: "VALEA UȚULUI" },
  { value: "VALEA UZEI", label: "VALEA UZEI" },
  { value: "VALEA VERDE", label: "VALEA VERDE" },
  { value: "VALEA VINȚULUI", label: "VALEA VINȚULUI" },
  { value: "VĂLENI / BUCIUM", label: "VĂLENI / BUCIUM" },
  { value: "VĂLENI / METEȘ", label: "VĂLENI / METEȘ" },
  { value: "VĂLIȘOARA", label: "VĂLIȘOARA" },
  { value: "VÂLTORI / ORAȘ ZLATNA", label: "VÂLTORI / ORAȘ ZLATNA" },
  { value: "VÂLTORI / VADU MOȚILOR", label: "VÂLTORI / VADU MOȚILOR" },
  { value: "VAMA SEACĂ", label: "VAMA SEACĂ" },
  { value: "VANVUCEȘTI", label: "VANVUCEȘTI" },
  { value: "VÂRȘI", label: "VÂRȘI" },
  { value: "VÂRȘII MARI", label: "VÂRȘII MARI" },
  { value: "VÂRȘII MICI", label: "VÂRȘII MICI" },
  { value: "VÂRȘI-RONTU", label: "VÂRȘI-RONTU" },
  { value: "VÂRTĂNEȘTI", label: "VÂRTĂNEȘTI" },
  { value: "VÂRTOP", label: "VÂRTOP" },
  { value: "VĂSEȘTI", label: "VĂSEȘTI" },
  { value: "VERDEȘTI", label: "VERDEȘTI" },
  { value: "VESEUȘ", label: "VESEUȘ" },
  { value: "VEZA", label: "VEZA" },
  { value: "VIDOLM", label: "VIDOLM" },
  { value: "VIDRA", label: "VIDRA" },
  { value: "VIDRIȘOARA", label: "VIDRIȘOARA" },
  { value: "VIEZURI", label: "VIEZURI" },
  { value: "VINEREA", label: "VINEREA" },
  { value: "VINGARD", label: "VINGARD" },
  { value: "VINȚA", label: "VINȚA" },
  { value: "VINȚU DE JOS", label: "VINȚU DE JOS" },
  { value: "VLĂDEȘTI", label: "VLĂDEȘTI" },
  { value: "VLĂDOȘEȘTI", label: "VLĂDOȘEȘTI" },
  { value: "VULCAN", label: "VULCAN" },
  { value: "VURPĂR", label: "VURPĂR" },
  { value: "ZĂGRIȘ", label: "ZĂGRIȘ" },
  { value: "ZÂNZEȘTI", label: "ZÂNZEȘTI" },
  { value: "ZĂRIEȘ", label: "ZĂRIEȘ" },
  { value: "ZLATNA", label: "ZLATNA" },
];
