import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkConfig } from "store";
import { ParticipantsNumbers } from "store/models/statistics/ParticipantsNumbers";
import { addAppNotification } from "store/slices/appNotificationSlice";
import { baseUrl, getAxiosErrorMessage } from "utils";

const axios = require("axios").default;

export const getParticipantsNumbers = createAsyncThunk<
  ParticipantsNumbers,
  any,
  AppThunkConfig
>("statistics/getParticipantsNumbers", async (thunkAPI) => {
  try {
    const response = await axios.get(
      baseUrl + "Statistics/GetParticipantsNumbers"
    );

    return response.data.response;
  } catch (err: any) {
    let errorMessage = getAxiosErrorMessage(err);
    if (errorMessage) {
      thunkAPI.dispatch(
        addAppNotification({
          message: errorMessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(getAxiosErrorMessage(err));
    } else {
      throw err;
    }
  }
});
