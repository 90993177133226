export var baseUrl = "";

const prodUrl = " https://procompetentaapi.azurewebsites.net/";
const devUrl = "https://localhost:5001/";

if (process.env.NODE_ENV === "development") {
    baseUrl = devUrl;
}
if (process.env.NODE_ENV === "production") {
    baseUrl = prodUrl;
}