import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Input,
    InputAdornment,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
    addCounselingFile,
    deleteCounselingFile,
    downloadCounselingFile,
    getPaginatedCounselingFiles,
    selectCounselingFiles,
    selectCounselingFilesCount,
    selectCounselingFilters,
    selectCounselingLoading,
    selectCounselingPageLocalization,
    selectCurrentUser,
    useAppDispatch,
    useAppSelector,
    useDebounce,
} from "store";


import DownloadIcon from "@mui/icons-material/Download";
import { Delete } from "@mui/icons-material";
import { FileUploadPopout } from "components";
import { DeleteConfirmationDialog } from "pages/manageDocuments-page/deleteConfirmationDialog/DeleteConfirmationDialog";

import "./CounselingTable.scss";
import { setCounselingFilters } from "store/slices/counselingSlice";
import { counselingFilesDataSortSelectItems } from "store/models/selectItems/counselingFilesDataSortSelectItems";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { isAdmin, isExpert } from "utils/getUserType";
import { getExtension } from "utils/getExtension";

export const CounselingTable = () => {
    const [displayDialog, setDisplayDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deletedName, setDeletedName] = useState<string>("");
    const [deletedId, setDeletedId] = useState<string>("");
    const [filesWithId, setFilesWithId] = useState<any>([]);
    const [localSearchTextValue, setLocalSearchTextValue] = useState<string>("");
    const debouncedSearchTerm = useDebounce(localSearchTextValue, 500);
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const counselingFiles = useAppSelector(selectCounselingFiles);
    const loading = useAppSelector(selectCounselingLoading);
    const filters = useAppSelector(selectCounselingFilters);
    const totalCount = useAppSelector(selectCounselingFilesCount);
    const localization = useAppSelector(selectCounselingPageLocalization);

    useEffect(() => {
        if (debouncedSearchTerm !== null && debouncedSearchTerm !== undefined) {
            dispatch(
                setCounselingFilters({
                    ...filters,
                    searchText: debouncedSearchTerm,
                    pageNumber: 0,
                })
            );
        }
        // eslint-disable-next-line
    }, [debouncedSearchTerm]);

    useEffect(() => {
        //@ts-ignore
        const AddIdToCounselingFiles = counselingFiles?.counselingFiles?.map(
            (file: any) => {
                return {
                    ...file,
                    id: file.fileId,
                };
            }
        );
        setFilesWithId(AddIdToCounselingFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counselingFiles]);

    const columns: GridColDef[] = [
        {
            field: "fileName",
            headerName: localization.tableColumns.fileName,
            flex: 1,
            minWidth: 200,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tooltip title={params.value}>
                        <Typography variant="body2" className="file-name-column">
                            {params.value}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "contentType",
            headerName: localization.tableColumns.contentType,
            flex: 1,
            minWidth: 50,
            renderCell: (params: GridRenderCellParams) => {
                if (params.value) {
                    return getExtension(params.row.fileName as string);
                }
            },
        },
        {
            field: "uploadedByUsername",
            headerName: localization.tableColumns.uploadedByUsername,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "",
            headerName: localization.tableColumns.options,
            sortable: false,
            flex: 1,
            minWidth: 50,
            align: "right",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <ButtonGroup variant="contained" size="small">
                        <React.Fragment>
                            <Tooltip title={localization.downloadTooltip}>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(
                                            downloadCounselingFile({
                                                fileId: params.row.fileId,
                                            })
                                        );
                                    }}
                                >
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                            {(isExpert(currentUser?.userType) ||
                                isAdmin(currentUser?.userType)) && ((
                                    <Tooltip title={localization.deleteTooltip}>
                                        <Button
                                            color="error"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <Delete
                                                onClick={() => {
                                                    setDeletedName(params.row.fileName);
                                                    setDeletedId(params.row.id);
                                                    setDeleteDialog(true);
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                ))}
                        </React.Fragment>
                    </ButtonGroup>
                );
            },
        },
    ];

    const onFilesUpload = (
        files: Array<File>,
        removeAllFilesCallback: Function
    ) => {
        if (files.length > 0) {
            dispatch(
                addCounselingFile({
                    uploadedFiles: files,
                    token: currentUser?.jwtToken,
                })
            ).then(() => {
                handleGetAllFiles();
                removeAllFilesCallback();
            });
        }
    };

    const handleClose = () => {
        setDisplayDialog(false);
    };

    const handleCloseDelete = () => {
        setDeleteDialog(false);
    };

    const handleGetAllFiles = () => {
        let promise = dispatch(
            getPaginatedCounselingFiles({
                filters: filters,
                token: currentUser?.jwtToken,
            })
        );

        return () => promise.abort();
    };

    useEffect(() => {
        handleGetAllFiles();

        //eslint-disable-next-line
    }, [filters]);

    const handleConfirmDelete = () => {
        dispatch(
            deleteCounselingFile({
                fileId: deletedId,
                token: currentUser?.jwtToken,
            })
        ).then(() => {
            setDeleteDialog(false);
            handleGetAllFiles();
        });
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (newModel.length === 0) {
            dispatch(
                setCounselingFilters({
                    ...filters,
                    columnToSortBy: undefined,
                    sortingOrder: false,
                    pageNumber: 0,
                })
            );
        } else {
            dispatch(
                setCounselingFilters({
                    ...filters,
                    columnToSortBy: counselingFilesDataSortSelectItems?.find(
                        (x) => x.field === newModel[0].field
                    )?.value,
                    sortingOrder: newModel[0].sort === "desc" ? true : false,
                    pageNumber: 0,
                })
            );
        }
    };

    const handlePageChange = (page: number) => {
        dispatch(
            setCounselingFilters({
                ...filters,
                pageNumber: page,
            })
        );
    };

    function CustomNoRowsOverlay() {
        return (
            <Box className="printer-icon">
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1 }}>{localization.noFiles}</Box>
            </Box>
        );
    }

    return (
        <Container maxWidth="xl" className="counseling-table">
            {displayDialog && (
                <FileUploadPopout
                    showSize={true}
                    uploadOnButtonCallback={onFilesUpload}
                    handleClose={handleClose}
                    displayDialog={displayDialog}
                />
            )}
            <DeleteConfirmationDialog
                display={deleteDialog}
                headerText={`Ștergeți fișierul ${deletedName}?`}
                handleClose={handleCloseDelete}
                handleConfirm={handleConfirmDelete}
                data=""
            />
            <Box className="table-search-box">
                <Input
                    placeholder={localization.search}
                    onChange={(e) => setLocalSearchTextValue(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                />
                {(isExpert(currentUser?.userType) ||
                    isAdmin(currentUser?.userType)) && (
                        <Button
                            variant="contained"
                            onClick={() => setDisplayDialog(true)}
                            startIcon={<AddIcon />}
                        >
                            {localization.addFiles}
                        </Button>
                    )}
            </Box>
            <DataGrid
                components={{
                    LoadingOverlay: DataLoadingComponent,
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                loading={loading}
                rows={filesWithId ?? []}
                columns={columns}
                pageSize={filters.pageSize}
                rowsPerPageOptions={[filters.pageSize]}
                paginationMode="server"
                rowCount={totalCount}
                onPageChange={handlePageChange}
                page={filters.pageNumber}
                hideFooterSelectedRowCount={true}
                disableSelectionOnClick={true}
                sx={{
                    "& .MuiDataGrid-row:hover": {
                        background: "aliceblue",
                        cursor: "pointer",
                    },
                    height: 630,
                    boxShadow: 3,
                    maxWidth: "100vw",
                }}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableColumnMenu={true}
            ></DataGrid>
        </Container>
    );
};
