import { EmploymentMarketSituation } from "../enums/EmploymentMarketSituation";

export const EmploymentMarketSituationAll = [
  { value: EmploymentMarketSituation.Somer, label: "Șomer" },
  {
    value: EmploymentMarketSituation.LongTermUnemployed,
    label: "Șomer de lungă durată",
  },
  { value: EmploymentMarketSituation.Angajat, label: "Angajat" },
  {
    value: EmploymentMarketSituation.SelfEmployed,
    label: "Angajat pe cont propriu",
  },
  {
    value: EmploymentMarketSituation.InactivePerson,
    label:
      "Persoană inactivă inclusiv copii antepreșcolari, preșcolari, elevi, etc",
  },
  {
    value: EmploymentMarketSituation.OtherInactivePerson,
    label: "Altă categorie de inactivi în afară de cei din educație si formare",
  },
];
