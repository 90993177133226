import { CloseRounded, Search } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import locale from "date-fns/locale/ro";
import {
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  selectCurrentUser,
  selectExpertUsersFilters,
  selectTargetGroupFilters,
  selectUsersPageLocalization,
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "store";
import {
  setExpertUsersFilters,
  setTargetGroupFilters,
} from "store/slices/targetGroupSlice";
import "./TargetGroupFilters.scss";

import { ItemsSelector } from "components/shared/itemsSelector/ItemsSelector";
import { employmentMarketSituationItems } from "store/models/selectItems/employmentMarketSituationItems";
import { countiesSelectItems } from "store/models/selectItems";
import { statusBeneficiarSelectItems } from "store/models/selectItems/statusBeneficiarSelectItems";
import { SerieModel } from "store/models/targetGroups/SerieModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { employmentMarketSituationExtraItems } from "store/models/selectItems/employmentMarketSiuationExtraItems";
import { UserType } from "store/models/enums/UserType";
import { ExpertUserModel } from "store/models/targetGroups/ExpertUserModel";

export const TargetGroupFilters: FC<{
  expertsList: ExpertUserModel[];
  seriesList: SerieModel[];
  setShowExperts: Function;
  showExperts: boolean;
}> = ({ expertsList, seriesList, setShowExperts, showExperts }) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectTargetGroupFilters);
  const expertUsersFilters = useAppSelector(selectExpertUsersFilters);
  const [search, setSearch] = useState<string>(
    !showExperts ? filters.searchText : expertUsersFilters.searchText
  );
  const debouncedValue = useDebounce(search, 500);
  const localization = useAppSelector(selectUsersPageLocalization);
  const currentUser = useAppSelector(selectCurrentUser);

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const isAdminUser = () => {
    if (currentUser?.userType === UserType.ADMIN) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (showExperts && expertUsersFilters.searchText !== debouncedValue) {
      dispatch(
        setExpertUsersFilters({
          ...expertUsersFilters,
          pageNumber: 0,
          searchText: debouncedValue,
          pageSize: 10,
        })
      );
    } else if (debouncedValue !== filters.searchText) {
      dispatch(
        setTargetGroupFilters({
          ...filters,
          pageNumber: 0,
          searchText: debouncedValue,
        })
      );
    }
    //eslint-disable-next-line
  }, [debouncedValue]);

  useEffect(() => {
    if (!showExperts) {
      setSearch(filters.searchText);
    }

    //eslint-disable-next-line
  }, [showExperts]);

  const handleSetExpert = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        expertId: e.target.value,
      })
    );
  };
  const handleSetExpertNull = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        expertId: 0,
      })
    );
  };
  const handleSetStatus = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        status: e.target.value,
      })
    );
  };
  const handleSetStatusNull = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        status: undefined,
      })
    );
  };
  const handleSetCourseSeriesId = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        courseSeriesId: e.target.value,
      })
    );
  };
  const handleSetCourseSeriesIdNull = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        courseSeriesId: undefined,
      })
    );
  };

  const handleSetEmployment = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        employmentMarketSituation: e.target.value,
      })
    );
  };
  const handleSetEmploymentNull = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        employmentMarketSituation: undefined,
      })
    );
  };

  const handleSetEmploymentOther = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        employmentMarketSituationOther: e.target.value,
      })
    );
  };
  const handleSetEmploymentOtherNull = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        employmentMarketSituationOther: undefined,
      })
    );
  };

  const handleSetCounty = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        county: e.target.value,
      })
    );
  };
  const handleSetCountyNull = (e: any) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        pageNumber: 0,
        county: undefined,
      })
    );
  };

  const doNothing = () => {};
  const setFirstEntryTimeFilter = (e: Date | null) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        firstEntryDate: e ? e.toISOString() : undefined,
        pageNumber: 0,
      })
    );
  };

  const setSecondEntryTimeFilter = (e: Date | null) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        secondEntryDate: e ? e.toISOString() : undefined,
        pageNumber: 0,
      })
    );
  };
  const setFirstAllocationTimeFilter = (e: Date | null) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        firstAllocationDate: e ? e.toISOString() : undefined,
        pageNumber: 0,
      })
    );
  };

  const setSecondAllocationTimeFilter = (e: Date | null) => {
    dispatch(
      setTargetGroupFilters({
        ...filters,
        secondAllocationDate: e ? e.toISOString() : undefined,
        pageNumber: 0,
      })
    );
  };

  const expertLabelAndValue = () => {
    return expertsList?.map((expert) => {
      return {
        label: expert.firstName + " " + expert.lastName,
        value: expert.userId,
      };
    });
  };

  const seriesLabelAndValue = () => {
    return seriesList?.map((series) => {
      return {
        label: series.seriesName,
        value: series.seriesId,
      };
    });
  };

  const handleEmploymentMarketSituations = (value: number) => {
    switch (value) {
      case 1:
        return employmentMarketSituationExtraItems[1].label;
      case 2:
        return employmentMarketSituationExtraItems[0].label;
      case 5:
        return employmentMarketSituationExtraItems[2].label;
    }
  };

  return (
    <Grid container id="target-group-filters" maxWidth="lg" rowSpacing={2}>
      <Button
        onClick={() => setOpenFilters(!openFilters)}
        className="open-filters"
      >
        Filtre {openFilters ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </Button>
      <Collapse in={openFilters} className="collapse">
        <Grid
          container
          item
          xs={12}
          columnSpacing={4}
          rowSpacing={2}
          className="container"
        >
          <Grid
            item
            xs={12}
            sm={isAdminUser() ? 3.5 : 4}
            className="search-input"
          >
            <TextField
              placeholder={localization.search}
              value={search}
              fullWidth
              onChange={handleSearchChange}
              variant="standard"
              size="small"
              InputProps={{
                startAdornment: <Search className="search-icon" />,
              }}
            />
          </Grid>

          {!showExperts && (
            <Grid item xs={12} sm={isAdminUser() ? 3.5 : 4}>
              <ItemsSelector
                label={localization.status}
                value={filters.status}
                items={statusBeneficiarSelectItems}
                setValue={handleSetStatus}
                removeFilter={handleSetStatusNull}
                removeFilterDeleteIcon={
                  filters.status === undefined || filters.status === 0
                }
              />
            </Grid>
          )}

          {!showExperts && (
            <Grid item xs={12} sm={isAdminUser() ? 3.5 : 4}>
              <ItemsSelector
                label={localization.serie}
                value={filters.courseSeriesId}
                items={seriesLabelAndValue()}
                setValue={handleSetCourseSeriesId}
                removeFilter={handleSetCourseSeriesIdNull}
                removeFilterDeleteIcon={
                  filters.courseSeriesId === undefined ||
                  filters.courseSeriesId === 0
                }
              />
            </Grid>
          )}

          {isAdminUser() && (
            <Grid item xs={12} sm={1.5} className="switch-experts">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch onChange={() => setShowExperts(!showExperts)} />
                  }
                  label="Experți"
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
        {!showExperts && (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems={"center"}
              columnSpacing={4}
              rowSpacing={2}
            >
              {/* <Grid item xs={12} sm={3} md={3}>
              <ItemsSelector
                label={localization.targetGroupCategory}
                value={filters.targetGroupCategory}
                items={targetGroupCategoriesItems}
                setValue={handleSetCategory}
                removeFilter={handleSetCategoryNull}
                removeFilterDeleteIcon={
                  filters.targetGroupCategory === undefined
                }
              />
            </Grid> */}
              <Grid
                item
                xs={12}
                sm={3}
                md={filters.employmentMarketSituation ? 3 : 4}
              >
                <ItemsSelector
                  label={localization.employmentSituation}
                  value={filters.employmentMarketSituation}
                  items={employmentMarketSituationItems}
                  setValue={handleSetEmployment}
                  removeFilter={handleSetEmploymentNull}
                  removeFilterDeleteIcon={
                    filters.employmentMarketSituation === undefined
                  }
                />
              </Grid>
              {filters.employmentMarketSituation && (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={filters.employmentMarketSituation ? 3 : 4}
                >
                  <ItemsSelector
                    //@ts-ignore
                    label={handleEmploymentMarketSituations(
                      filters.employmentMarketSituation!
                    )}
                    value={filters.employmentMarketSituationOther}
                    items={[
                      { label: "Da", value: true },
                      { label: "Nu", value: false },
                    ]}
                    setValue={handleSetEmploymentOther}
                    removeFilter={handleSetEmploymentOtherNull}
                    removeFilterDeleteIcon={
                      filters.employmentMarketSituationOther === undefined
                    }
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={3}
                md={filters.employmentMarketSituation ? 3 : 4}
              >
                <DatePicker
                  value={filters.firstEntryDate ?? null}
                  onChange={doNothing}
                  maxDate={
                    filters.secondEntryDate
                      ? new Date(filters.secondEntryDate)
                      : undefined
                  }
                  onAccept={setFirstEntryTimeFilter}
                  mask="__.__.____"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            {filters.firstEntryDate && (
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setFirstEntryTimeFilter(null);
                                }}
                              >
                                <CloseRounded color="error"></CloseRounded>
                              </IconButton>
                            )}
                            {params.InputProps?.endAdornment}
                          </Grid>
                        ),
                      }}
                      onChange={doNothing}
                      label={localization.beforeEntryDate}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={filters.employmentMarketSituation ? 3 : 4}
              >
                <DatePicker
                  value={filters.secondEntryDate ?? null}
                  onChange={doNothing}
                  minDate={
                    filters.firstEntryDate
                      ? new Date(filters.firstEntryDate)
                      : undefined
                  }
                  onAccept={setSecondEntryTimeFilter}
                  mask="__.__.____"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            {filters.secondEntryDate && (
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setSecondEntryTimeFilter(null);
                                }}
                              >
                                <CloseRounded color="error"></CloseRounded>
                              </IconButton>
                            )}
                            {params.InputProps?.endAdornment}
                          </Grid>
                        ),
                      }}
                      onChange={doNothing}
                      label={localization.afterEntryDate}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ItemsSelector
                  label={localization.expert}
                  value={filters.expertId}
                  items={expertLabelAndValue()}
                  setValue={handleSetExpert}
                  removeFilter={handleSetExpertNull}
                  removeFilterDeleteIcon={
                    filters.expertId === undefined || filters.expertId === 0
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ItemsSelector
                  label={localization.county}
                  value={filters.county}
                  items={countiesSelectItems}
                  setValue={handleSetCounty}
                  removeFilter={handleSetCountyNull}
                  removeFilterDeleteIcon={filters.county === undefined}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <DatePicker
                  value={filters.firstAllocationDate ?? null}
                  onChange={doNothing}
                  maxDate={
                    filters.secondAllocationDate
                      ? new Date(filters.secondAllocationDate)
                      : undefined
                  }
                  onAccept={setFirstAllocationTimeFilter}
                  mask="__.__.____"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            {filters.firstAllocationDate && (
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setFirstAllocationTimeFilter(null);
                                }}
                              >
                                <CloseRounded color="error"></CloseRounded>
                              </IconButton>
                            )}
                            {params.InputProps?.endAdornment}
                          </Grid>
                        ),
                      }}
                      onChange={doNothing}
                      label={localization.beforeAllocationDate}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <DatePicker
                  value={filters.secondAllocationDate ?? null}
                  onChange={doNothing}
                  minDate={
                    filters.firstAllocationDate
                      ? new Date(filters.firstAllocationDate)
                      : undefined
                  }
                  onAccept={setSecondAllocationTimeFilter}
                  mask="__.__.____"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            {filters.secondAllocationDate && (
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  setSecondAllocationTimeFilter(null);
                                }}
                              >
                                <CloseRounded color="error"></CloseRounded>
                              </IconButton>
                            )}
                            {params.InputProps?.endAdornment}
                          </Grid>
                        ),
                      }}
                      onChange={doNothing}
                      label={localization.afterAllocationDate}
                      variant="standard"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        )}
      </Collapse>
    </Grid>
  );
};
