import { RootState } from "store";

export const selectDocuments = (state: RootState): any => state.manageDocuments.documents;
export const selectDocumentsIsLoading = (state: RootState): any => state.manageDocuments.documentsIsLoading;
export const selectExpandedKeys = (state: RootState): any => state.manageDocuments.expandedKeys;
export const selectInitialDocuments = (state: RootState): any => state.manageDocuments.initialDocuments;
export const selectNewDecontData = (state: RootState): any => state.manageDocuments.newDecontData;
export const selectDecontSubmitStatus = (state: RootState): any => state.manageDocuments.decontSubmitStatus;
export const selectSolicitareSubmitStatus = (state: RootState): any => state.manageDocuments.solicitareSubmitStatus;
export const selectNewRequestData = (state: RootState): any => state.manageDocuments.newRequestData;
export const selectActionNode = (state: RootState): any => state.manageDocuments.actionNode;
export const selectShowMonitoring = (state:RootState):boolean =>state.manageDocuments.showMonitoring;
export const selectShowContest = (state:RootState):boolean =>state.manageDocuments.showContest;