import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { FileUploadComponent } from "components/shared/fileUpload/FileUploadComponent";
import { FormikDatePicker } from "components/shared/formikInputs/FormikDatePicker";
import { FormikSelectSimple } from "components/shared/formikInputs/FormikSelectSimple";
import { FormikTextArea } from "components/shared/formikInputs/FormikTextArea";
import { FormikTextField } from "components/shared/formikInputs/FormikTextField";
import { ImageCarousel } from "components/shared/ImageCarousel/ImageCarousel";
import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { selectCurrentUser, useAppDispatch, useAppSelector } from "store";
import { countiesSelectItems } from "store/models/selectItems";
import { selectJobDetails } from "store/selectors/mediationSelectors";
import { addFile } from "store/slices/fileSlices";
import { getPicture } from "store/thunks/fileThunks";
import { addJobPost, updateJobPost } from "store/thunks/mediationThunks";
import * as yup from "yup";

import "./AddOrEditJobPost.scss";

export const AddOrEditJobForm: FC = () => {
  const [jobFiles, setJobFiles] = useState<File[]>();
  const [imgSrcs, setImgSrcs] = useState<string[]>([]);
  const [deletedFiles, setDeletedFiles] = useState<number[]>([]);

  const jobDetails = useAppSelector(selectJobDetails);
  const currentUser = useAppSelector(selectCurrentUser);
  const params = useParams();
  const dispatch = useAppDispatch();
  const employerId = parseInt(params.employerId!, 10);
  const navigate = useNavigate();

  var initialValues = {
    title: jobDetails?.title ?? "",
    description: jobDetails?.description ?? "",
    county: jobDetails?.county ?? "",
    locality: jobDetails?.locality ?? "",
    street: jobDetails?.street ?? "",
    employmentRequirements: jobDetails?.employmentRequirements ?? "",
    vacancy: jobDetails?.vacancy ?? "",
    vacanciesNumber: jobDetails?.vacanciesNumber ?? "",
    fileSubmissionDeadline: jobDetails?.fileSubmissionDeadline ?? null,
  };

  const error = yup
    .string()
    .required("Acest câmp este obligatoriu")
    .nullable(true);

  const validationSchema = yup.object({
    title: error,
    description: error,
    county: yup.number().required("Acest câmp este obligatoriu").nullable(true),
    locality: error,
    street: error,
    employmentRequirements: error,
    vacancy: error,
    vacanciesNumber: yup
      .number()
      .required("Acest câmp este obligatoriu")
      .nullable(true),
    fileSubmissionDeadline: yup
      .date()
      .required("Acest câmp este obligatoriu")
      .nullable(true),
  });

  const handleFileUpload = (files: any) => {
    let images: any[] = [];

    setJobFiles(files);

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes("image")) {
        const url = URL.createObjectURL(files[i]);
        images.push({
          path: url,
          jobFileId: null,
          isImage: true,
        });
      }
    }

    setImgSrcs(images);
  };

  const handleDelete = (jobFileId: number) => {
    let arr: number[] = [...deletedFiles];

    if (!arr.includes(jobFileId)) {
      arr.push(jobFileId);
    } else {
      arr.splice(arr.indexOf(jobFileId), 1);
    }
    setDeletedFiles(arr);
  };

  const handleSubmit = (values: any) => {
    params.jobId === "0"
      ? dispatch(
          addJobPost({
            jobData: {
              ...values,
              fileSubmissionDeadline:
                values.fileSubmissionDeadline.toISOString(),
              jobFiles: jobFiles,
              employerId: employerId,
              uploadedByUserId: currentUser?.userId,
            },
            token: currentUser?.jwtToken,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.success) {
              navigate("/mediere/joburi/" + employerId);
            }
          })
      : dispatch(
          updateJobPost({
            jobData: {
              ...values,
              jobPostId: jobDetails!.jobPostId.toString(),
              fileSubmissionDeadline: new Date(
                values.fileSubmissionDeadline
              ).toISOString(),
              employerId: employerId.toString(),
              newFiles: jobFiles,
              deletedFiles: deletedFiles,
              updatedByUserId: currentUser?.userId,
            },
            token: currentUser?.jwtToken,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.success) {
              navigate("/mediere/joburi/" + employerId);
            }
          });
  };

  useEffect(() => {
    return () => {
      imgSrcs.forEach((imgSrc) => {
        URL.revokeObjectURL(imgSrc);
      });
    };
  }, [imgSrcs]);
  useEffect(() => {
    if (params.jobId === "0") return;
    jobDetails?.jobFiles.forEach((file) => {
      if (file.isImage) {
        dispatch(
          getPicture({
            dto: file.fileDetails,
          })
        ).then((res) => {
          dispatch(
            addFile({
              files: {
                filePath: res.payload,
                fileName: file.fileDetails.fileName,
                jobFileId: file.jobFileId,
                isImage: file.isImage,
              },
            })
          );
        });
      }
    });

    //eslint-disable-next-line
  }, []);

  const handleToggleDeleteDoc = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setDeletedFiles((prev) => [...prev, Number(event.target.value)]);
    } else {
      setDeletedFiles((prev) =>
        prev.filter((id) => id !== Number(event.target.value))
      );
    }
  };

  return (
    <Box className="job-post-box">
      <Box className="job-post-form">
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container className="form-container" spacing={2}>
              <Grid item xs={12} sm={6} className="grid-item">
                <FormikTextField name="title" label="Titlu" />
              </Grid>
              <Grid item xs={12} sm={6} className="grid-item">
                <FormikTextField name="vacancy" label="Post de lucru vacant" />
              </Grid>
              <Grid item xs={12} sm={12} className="grid-item">
                <FormikTextArea
                  name="description"
                  label="Descriere anunț"
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="grid-item">
                <FormikTextArea
                  name="employmentRequirements"
                  label="Condiții de angajare/oferte la angajare"
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="grid-item">
                <FormikTextField name="street" label="Strada" />
              </Grid>
              <Grid item xs={12} sm={6} className="grid-item">
                <FormikSelectSimple
                  name="county"
                  items={countiesSelectItems}
                  label="Județ"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="grid-item">
                <FormikTextField name="locality" label="Localitate" />
              </Grid>

              <Grid item xs={12} sm={6} className="grid-item">
                <FormikTextField
                  name="vacanciesNumber"
                  label="Nr. locuri de muncă vacante"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="grid-item">
                <FormikDatePicker
                  name="fileSubmissionDeadline"
                  label="Termen limită de depunere a dosarului"
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  className="job-form-send-btn"
                  fullWidth
                >
                  {jobDetails?.title ? "Actualizează anunț" : "Adaugă anunț"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
        <Grid item xs={12} className="new-docs">
          <Typography
            variant="h6"
            sx={{ textDecoration: "underline", textAlign: "center" }}
          >
            Documente noi
          </Typography>

          <FileUploadComponent
            onFilesChange={handleFileUpload}
            fileLimit={
              jobDetails?.jobFiles?.length!
                ? 5 - jobDetails?.jobFiles?.length! + deletedFiles.length
                : 5
            }
          />
        </Grid>
        {params.jobId !== "0" && (
          <Box className="existing-docs-box">
            <Typography
              variant="h6"
              sx={{ textDecoration: "underline", textAlign: "center" }}
            >
              Documente existente
            </Typography>
            {jobDetails?.jobFiles?.map((file) => {
              return !file.isImage ? (
                <FormGroup className="existing-docs">
                  <Typography variant="subtitle2">
                    {file.fileDetails.fileName}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        color="warning"
                        value={file.jobFileId}
                        onChange={handleToggleDeleteDoc}
                      />
                    }
                    label="Șterge"
                  />
                </FormGroup>
              ) : null;
            })}
          </Box>
        )}
      </Box>
      <Box className="all-filesBox">
        <ImageCarousel
          newImages={imgSrcs}
          handleDelete={handleDelete}
          deletedFiles={deletedFiles}
          displayOnly={false}
        />
      </Box>
    </Box>
  );
};
