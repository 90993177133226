export enum ExpertType {
    Expert = 0,
    Partener,
    Solicitant,
    RCV,
    ResponsabilFormare,
    ExpertComunicare,
    ExpertMediere,
    ResponsabilGT
}
