import { Grid } from "@mui/material";
import { FC } from "react";
import { EmployersFilters } from "./filters-employers/EmployersFilters";
import { EmployersTable } from "./table-employers/EmployersTable";
import "./EmployersTab.scss";
export const EmployersTab: FC = () => {
  return (
    <Grid container id="employers-tab">
      <Grid item xs={12}>
        <EmployersFilters />
      </Grid>

      <Grid item xs={12}>
        <EmployersTable />
      </Grid>
    </Grid>
  )
}