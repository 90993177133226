import { RootState } from "store";
import { AdeverintaType } from "store/models/enums/AdeverintaType";
import { UserModel } from "store/models/user/UserModel";
import { CourseSeries } from "store/models/vocationalTraining/CourseSeries";
import { VocationalTrainingFilter } from "store/models/vocationalTraining/VocationalTrainingDocument";

export const selectVocationalExamResultsDocs = (state: RootState): any =>
  state.vocationalTraining.vocationalTrainingExamResultsResponse
    ?.vocDocuments ?? [];
export const selectVocationalExamResultsDocsRowCount = (
  state: RootState
): any =>
  state.vocationalTraining.vocationalTrainingExamResultsResponse?.rowCount ?? 0;
export const selectVocationalTrainingExtraFiles = (state: RootState): any =>
  state.vocationalTraining.vocationalTraininExtraFilesResponse?.vocDocuments ??
  [];
export const selectVocationalTrainingExtraFilesRowCount = (
  state: RootState
): any =>
  state.vocationalTraining.vocationalTraininExtraFilesResponse?.rowCount ?? 0;
export const selectCourseSeries = (state: RootState): CourseSeries[] =>
  state.vocationalTraining.courseSeries;
export const selectUsersInAndOutOfSeries = (
  state: RootState
): {
  usersOfSeries: UserModel[];
  usersOutOfSeries: UserModel[];
} => state.vocationalTraining.usersInAndOutOfSeries;
export const selectUsersInSeries = (state:RootState):UserModel[] => state.vocationalTraining.usersInAndOutOfSeries.usersOfSeries.filter(x=> (x.lastName + " " + x.firstName).toLowerCase().includes(state.vocationalTraining.searchInsideSeries.toLowerCase()));
export const selectExamResultsListIsLoading = (state: RootState): boolean => state.vocationalTraining.examResultsListIsLoading;
export const selectExtraFilesListIsLoading = (state: RootState): boolean => state.vocationalTraining.extraFilesListIsLoading;
export const selectVocationalFileIsDownloading = (state: RootState): boolean => state.vocationalTraining.vocationalFileIsDownloading;
export const selectVocationalFileIsUploading = (state: RootState): boolean => state.vocationalTraining.vocationalFileIsUploading;
export const selectCourseSeriesUserIsLoading = (state: RootState): boolean => state.vocationalTraining.courseSeriesUserIsLoading;
export const selectUserIsChangedBetweenCourseSeries = (state: RootState): boolean => state.vocationalTraining.userIsChangedBetweenCourseSeries;
export const selectExamResultsFilters = (state:RootState) : VocationalTrainingFilter => state.vocationalTraining.examResultsFilters;
export const selectGeneratingAdeverinta = (state:RootState):boolean =>state.vocationalTraining.generatingAdeverinta;

export const selectSeriesFilters = (state: RootState): any =>
  state.vocationalTraining.seriesFilters;
export const selectTotalSeriesPages = (state: RootState): number =>
  state.vocationalTraining.totalSeriesPages;

export const selectCourseSeriesItems = (state:RootState):{label:string, value:number,adeverintaType:AdeverintaType}[] => state.vocationalTraining.courseSeriesItems;
export const selectExtraFilesFilters = (state:RootState):VocationalTrainingFilter =>state.vocationalTraining.extraFilesFilters;