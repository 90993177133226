export const IndicatorsSelectItems = [
  {
    value:
      "4S201) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă",
    label:
      "4S201) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă",
  },
  {
    value:
      "4S201.1) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi",
    label:
      "4S201.1) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi",
  },
  {
    value:
      "4S201.1.1) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Șomeri pe termen lung",
    label:
      "4S201.1.1) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Șomeri pe termen lung",
  },
  {
    value:
      "4S201.1.2) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: -Persoane cu dizabilități",
    label:
      "4S201.1.2) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: -Persoane cu dizabilități",
  },
  {
    value:
      "4S201.1.3) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu vârsta peste 54 de ani",
    label:
      "4S201.1.3) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu vârsta peste 54 de ani",
  },
  {
    value:
      "4S201.1.4) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
    label:
      "4S201.1.4) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
  },
  {
    value:
      "4S201.1.5) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
    label:
      "4S201.1.5) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
  },
  {
    value:
      "4S201.2) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Roma",
    label:
      "4S201.2) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: - Roma",
  },
  {
    value:
      "4S201.3) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: Din zona rurală",
    label:
      "4S201.3) Persoane care primesc un loc de muncă, inclusiv cele care desfășoară o activitate independentă, din care: Din zona rurală",
  },
  {
    value:
      "4S6) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare",
    label:
      "4S6) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare",
  },
  {
    value:
      "4S6.1) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi",
    label:
      "4S6.1) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi",
  },
  {
    value:
      "4S6.1.1) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Șomeri pe termen lung",
    label:
      "4S6.1.1) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Șomeri pe termen lung",
  },
  {
    value:
      "4S6.1.2) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu dizabilități",
    label:
      "4S6.1.2) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu dizabilități",
  },
  {
    value:
      "4S6.1.3) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu vârsta peste 54 de ani ",
    label:
      "4S6.1.3) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu vârsta peste 54 de ani ",
  },
  {
    value:
      "4S6.1.4) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
    label:
      "4S6.1.4) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
  },
  {
    value:
      "4S6.1.5) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
    label:
      "4S6.1.5) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Șomeri și inactivi, din care: - Persoane cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
  },
  {
    value:
      "4S6.2) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Roma",
    label:
      "4S6.2) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Roma",
  },
  {
    value:
      "4S6.3) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Din zona rurală",
    label:
      "4S6.3) Participanţii care,  la încetarea calităţii de participant, dobândesc o calificare, din care: - Din zona rurală",
  },
  {
    value:
      "4S7) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă",
    label:
      "4S7) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă",
  },
  {
    value:
      "4S7.1) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi",
    label:
      "4S7.1) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi",
  },
  {
    value:
      "4S7.1.1) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Șomeri pe termen lung",
    label:
      "4S7.1.1) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Șomeri pe termen lung",
  },
  {
    value:
      "4S7.1.2) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: -Persoane cu dizabilități",
    label:
      "4S7.1.2) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: -Persoane cu dizabilități",
  },
  {
    value:
      "4S7.1.3) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu vârsta peste 54 de ani",
    label:
      "4S7.1.3) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu vârsta peste 54 de ani",
  },
  {
    value:
      "4S7.1.4) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
    label:
      "4S7.1.4) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
  },
  {
    value:
      "4S7.1.5) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
    label:
      "4S7.1.5) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Şomeri şi inactivi, din care: - Persoane cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
  },
  {
    value:
      "4S7.2) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Roma",
    label:
      "4S7.2) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: - Roma",
  },
  {
    value:
      "4S7.3) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: Din zona rurală",
    label:
      "4S7.3) Persoane care, la încetarea calităţii de participant, au un loc de muncă, inclusiv cele care desfăşoară o activitate independentă, din care: Din zona rurală",
  },
  {
    value: "4S8) Persoanele care beneficiază de sprijin",
    label: "4S8) Persoanele care beneficiază de sprijin",
  },
  {
    value:
      "4S8.1) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi",
    label:
      "4S8.1) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi",
  },
  {
    value:
      "4S8.1.1) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Șomeri pe termen lung",
    label:
      "4S8.1.1) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Șomeri pe termen lung",
  },
  {
    value:
      "4S8.1.2) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoane cu dizabilități",
    label:
      "4S8.1.2) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoane cu dizabilități",
  },
  {
    value:
      "4S8.1.3) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoanele cu vârsta peste 54 de ani ",
    label:
      "4S8.1.3) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoanele cu vârsta peste 54 de ani ",
  },
  {
    value:
      "4S8.1.4) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoanele cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
    label:
      "4S8.1.4) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoanele cu studii primare (ISCED 1) sau gimnaziale (ISCED 2)",
  },
  {
    value:
      "4S8.1.5) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoanele cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
    label:
      "4S8.1.5) Persoanele care beneficiază de sprijin, din care: - Șomeri și inactivi, din care: - Persoanele cu studii liceale (ISCED 3) sau postliceale (ISCED 4)",
  },
  {
    value: "4S8.2) Persoanele care beneficiază de sprijin, din care: - Roma",
    label: "4S8.2) Persoanele care beneficiază de sprijin, din care: - Roma",
  },
  {
    value:
      "4S8.3) Persoanele care beneficiază de sprijin, din care: - Din zona rurală",
    label:
      "4S8.3) Persoanele care beneficiază de sprijin, din care: - Din zona rurală",
  },
];
