export enum ApplicationsSortBy{
  UserName=1,
  Email=2,
  JobTitle=3,
  JobCounty,
  JobLocality,
  Status,
  CvName,
  ApplicationDate,
  AllocationDate,
  AllocatedByUsername,
  CvUpdateDate
}