import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { getDateLabel, onlyDateFormat } from "utils";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectJobDetails,
  selectJobDetailsLoading,
} from "store/selectors/mediationSelectors";
import { getPicture } from "store/thunks/fileThunks";
import { ConvertCountyToLabel } from "utils/mapCountyEnumToLabel";
import { JobModal } from "../job-modal/JobModal";
import "./JobDetails.scss";
import BusinessIcon from "@mui/icons-material/Business";

export const JobDetails: FC = () => {
  const [jobDialog, setJobDialog] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>("");
  const dispatch = useAppDispatch();
  const jobDetails = useAppSelector(selectJobDetails);
  const jobDetailsLoading = useAppSelector(selectJobDetailsLoading);

  const handleJobDetailsClick = () => {
    setJobDialog(true);
  };

  const closeJobModal = () => {
    setJobDialog(false);
  };
  const image = jobDetails?.jobFiles?.find((file: any) => file.isImage);
  useEffect(() => {
    if (image) {
      dispatch(
        getPicture({
          dto: {
            filePath: image.fileDetails.filePath,
            fileName: image.fileDetails.fileName,
            contentType: image.fileDetails.contentType,
          },
        })
      ).then((res) => setLogo(res.payload));
    }

    //eslint-disable-next-line
  }, [image]);
  return (
    <Grid id="job-details" item xs={12}>
      {jobDetails && !jobDetailsLoading && (
        <Grid className="job-details-container" container>
          <Grid item xs={12} className="details-container">
            {logo ? (
              <CardMedia
                component="img"
                image={logo}
                className="job-details-logo"
              />
            ) : (
              <BusinessIcon className="job-details-logo" />
            )}

            <Box className="details-container-box">
              <Typography className="details-container-date">
                {getDateLabel(
                  jobDetails?.fileSubmissionDeadline,
                  onlyDateFormat
                )}
              </Typography>
              <Typography className="details-container-title">
                {jobDetails?.title}
              </Typography>
              <Typography className="details-container-employer">
                {jobDetails?.employerName}
              </Typography>
              <Typography className="details-container-address">
                {jobDetails?.locality},
                <span>{ConvertCountyToLabel(jobDetails!.county!)}</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} className="actions-container">
            <Box className="jobs-statistics">
              <Box className="jobs-statistics-label">
                <Typography className="number-stats">
                  Nr. total posturi:
                </Typography>
                <Typography>Nr. posturi alocate: </Typography>
                <Typography>Nr. posturi disponibile: </Typography>
              </Box>
              <Box className="jobs-statistics-number">
                <span className="vacancies-number">
                  {jobDetails?.vacanciesNumber}
                </span>

                <span className="allocated-number">
                  {jobDetails?.allocatedUsers.length}
                </span>

                <span className="jobs-left-number">{jobDetails?.jobsLeft}</span>
              </Box>
            </Box>
          </Grid>
          <Box className="view-details-box">
            <Button
              variant="contained"
              onClick={handleJobDetailsClick}
              className="view-detailsBTN"
            >
              Vezi detalii
            </Button>
          </Box>
        </Grid>
      )}
      {jobDialog && <JobModal display={jobDialog} close={closeJobModal} />}
    </Grid>
  );
};
