import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import locale from "date-fns/locale/ro";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { requestGetGeneratedRankings, selectCurrentUser, selectGrantContestPageLocalization, selectSelectedUser, selectShowPhase2FinallRanking, selectShowPhase2IntermediaryRanking, selectShowPhase3Ranking, useAppSelector } from "store";
import { UserType } from "store/models/enums/UserType";
import { setGrantApproved, setSelectedUser } from "store/slices/grantContestSlice";
import { setUserPhase2FinalEvaluationGrids, setUserPhase2TemporaryEvaluationGrids, setUserPhase3FinalEvaluationGrids } from "store/slices/grantEvaluationSlice";
import { GrantContestPhase1Component } from "./grant-contest-phase-1";
import { GrantContestPhase2Component } from "./grant-contest-phase-2";
import { GrantEvaluationPhase2Page } from "./grant-evaluation-phase-2/GrantEvaluationPhase2Page";
import { GrantEvaluationPhase3Page } from "./grant-evaluation-phase-3/GrantEvaluationPhase3Page";
import { GrantEvaluationPhase2RankingPage } from "./grant-ranking-phase-2/GrantEvaluationPhase2RankingPage";
import { GrantEvaluationPhase3RankingPage } from "./grant-ranking-phase-3/GrantEvaluationPhase3RankingPage";
import './GrantContestPage.scss';
import { InterviewSchedules } from "./interview-schedules-phase-3/InterviewSchedules";
export const GrantContestPage: FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const currentUser = useAppSelector(selectCurrentUser);
    const navigate = useNavigate();
    const { userId } = useParams();
    const showPhase3Ranking = useAppSelector(selectShowPhase3Ranking);
    const showPhase2FinallRanking = useAppSelector(selectShowPhase2FinallRanking);
    const showPhase2IntermediaryRanking = useAppSelector(selectShowPhase2IntermediaryRanking);
    const selectedUser = useAppSelector(selectSelectedUser);
    const localization = useAppSelector(selectGrantContestPageLocalization);

    useEffect(() => {
        dispatch(setGrantApproved(checkUserForGrantApproved()))

        if (currentUser?.userType !== UserType.COMPANY_USER) {
            dispatch(setSelectedUser(null));
            navigate("/planafaceri/" + currentUser?.userId);
        }
        if (localStorage.getItem('userId')) {
            navigate("/planafaceri/" + parseInt(localStorage.getItem("userId") ?? "0"))
        }
        dispatch(requestGetGeneratedRankings({
            token: currentUser?.jwtToken,
        }));
        return (() => {
            dispatch(setUserPhase3FinalEvaluationGrids([]))
            dispatch(setUserPhase2FinalEvaluationGrids([]))
            dispatch(setUserPhase2TemporaryEvaluationGrids([]));
        })
        //eslint-disable-next-line
    }, [])



    const checkUserForGrantApproved = () => {
        if (currentUser?.userType !== UserType.COMPANY_USER) {
            return false;
        }
        else {
            if (currentUser?.isGrantApproved && currentUser?.userType === UserType.COMPANY_USER) {
                return true;
            }
        }
    }

    useEffect(() => {
        if (!currentUser || (currentUser.userType === UserType.COMPANY_USER && currentUser?.userId !== parseInt(userId ?? "0"))) {
            navigate("/planafaceri/" + currentUser?.userId);
        }
        //eslint-disable-next-line
    }, [userId])

    useEffect(() => {
        if (currentUser?.userId === parseInt(userId ?? "0") && currentUser?.userType !== UserType.COMPANY_USER) {
            dispatch(setSelectedUser(null));
        } else {

        }
        //eslint-disable-next-line
    }, [navigate])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const displayPhase2EvaluationTab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER) return false;
        if (selectedUser === null || (selectedUser !== null && selectedUser?.isGrantApproved)) return true;
        if (!selectedUser?.isGrantApproved) return false;
        return true;
    }
    const displayRankingPhase2Tab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER && !currentUser?.isGrantApproved) return false;
        if (showPhase2FinallRanking || showPhase2IntermediaryRanking) return true;
        return false;
    }

    const displayInterviewSchedules = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER && currentUser?.isGrantApproved && showPhase2FinallRanking) return true;
        if (currentUser?.userType !== UserType.COMPANY_USER && (!selectedUser?.isGrantApproved && selectedUser !== null)) return false;
        if (showPhase2FinallRanking) return true;
        if (showPhase3Ranking) return true;
        return false;
    }

    const displayPhase3EvaluationTab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER || (!selectedUser?.isGrantApproved && selectedUser !== null)) {
            return false;
        } else {
            if (showPhase2FinallRanking) return true;
        }
        return false;
    }

    const displayRankingPhase3Tab = (): boolean => {
        if (currentUser?.userType === UserType.COMPANY_USER && !currentUser?.isGrantApproved) return false;
        if (showPhase3Ranking) return true;
        return false;
    }


    return (
        <Container
            id="grant-contest-page"
            maxWidth={false}
            sx={{ minHeight: "inherit" }}
            disableGutters
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <Grid
                    container
                    sx={{
                        minHeight: "inherit",
                    }}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="stretch"
                    >
                        <Container maxWidth="lg" disableGutters>
                            <Tabs value={activeTab} onChange={handleChange} variant="scrollable">
                                <Tab label={localization.phase1Tab.title} />
                                <Tab label={localization.phase2Tab.title} />
                                {displayPhase2EvaluationTab() ? <Tab label={localization.evaluationPhase2Tab.title} /> : <Tab sx={{ display: "none" }} />}
                                {displayRankingPhase2Tab() ? <Tab label={localization.phaseRank2Tab.title} /> : <Tab sx={{ display: "none" }} />}
                                {displayInterviewSchedules() ? <Tab label={localization.interviewScheduleTab.title} /> : <Tab sx={{ display: "none" }} />}
                                {displayPhase3EvaluationTab() ? <Tab label={localization.evaluationPhase3Tab.title} /> : <Tab sx={{ display: "none" }} />}
                                {displayRankingPhase3Tab() ? < Tab label={localization.phase3RankTab.title} /> : <Tab sx={{ display: "none" }} />}
                            </Tabs>
                        </Container>
                        <Grid item flexGrow={1} >
                            {activeTab === 0 && <GrantContestPhase1Component />}
                            {activeTab === 1 && <GrantContestPhase2Component />}
                            {activeTab === 2 && <GrantEvaluationPhase2Page />}
                            {activeTab === 3 && <GrantEvaluationPhase2RankingPage />}
                            {activeTab === 4 && <InterviewSchedules />}
                            {activeTab === 5 && <GrantEvaluationPhase3Page />}
                            {activeTab === 6 && <GrantEvaluationPhase3RankingPage />}
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Container>
    )
}