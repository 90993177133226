import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  generateAdeverinta,
  getAssignedSeries,
  addUsersToSeries,
  deleteExamResultsDoc,
  deleteExtraFilesDoc,
  downloadExamResultsDoc,
  downloadVocationalTrainingExtraFileDoc,
  getUsersOfSeries,
  getUsersOutOfSeries,
  requestCourseSeries,
  requestExamResultsDocs,
  requestExtraFilesDocs,
  uploadExamResultsDocs,
  uploadExtraFilesDocs,
  getCourseSeriesWithoutExamSeriesAssigned,
} from "store";
import { CourseSeries } from "../models/vocationalTraining/CourseSeries";
import { VocationalTrainingState } from "../models/vocationalTraining/VocationalTrainingState";
import { VocationalTrainingDocument } from "../models/vocationalTraining/VocationalTrainingDocument";
import { UserModel } from "store/models/user/UserModel";
import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { AdeverintaType } from "store/models/enums/AdeverintaType";

const initialState: VocationalTrainingState = {
  actions: {},
  documents: new Array<VocationalTrainingDocument>(),
  courseSeries: new Array<CourseSeries>(),
  usersInAndOutOfSeries: {
    usersOfSeries: new Array<UserModel>(),
    usersOutOfSeries: new Array<UserModel>(),
  },
  courseSeriesItems:new Array<{label:string,value:number,adeverintaType:AdeverintaType}>(),
  seriesFilters: {
    currentPageNumber: 0,
    search: "",
  },
  searchInsideSeries:"",
  totalSeriesPages: 0,
  vocationalTrainingExamResultsResponse: {},
  vocationalTraininExtraFilesResponse: {},
  examResultsListIsLoading: false,
  extraFilesListIsLoading: false,
  vocationalFileIsDownloading: false,
  vocationalFileIsUploading: false,
  courseSeriesUserIsLoading: false,
  userIsChangedBetweenCourseSeries: false,
  examResultsFilters: {
    pageNumber: 0,
    sortingOrder: false,
    columnToSortBy: DataGridColumnNames.UploadTime,
    examSeriesId: 0,
  },
  extraFilesFilters:{
    pageNumber:0,
    sortingOrder: false,
    columnToSortBy: DataGridColumnNames.UploadTime,
  },
  generatingAdeverinta: false,
};

export const vocationalTrainingSlice = createSlice({
  name: "vocationalTrainingSlice",
  initialState: initialState,
  reducers: {
    setSearchInsideSeries(state,action){
      state.searchInsideSeries = action.payload;
    },
    setCourseSeries(state, action) {
      state.courseSeries = action.payload;
    },
    setUsersOfSeries(state,action){
      state.usersInAndOutOfSeries.usersOfSeries = action.payload;
    },
    setUsersInAndOutOfSeries(state, action) {
      state.usersInAndOutOfSeries = action.payload;
    },
    setExamResultsFilters(state, action) {
      state.examResultsFilters = action.payload;
    },
    setExtraFilesFilters(state,action){
      state.extraFilesFilters = action.payload;
    },
    setVocationalTrainingExamResultsResponse(state,action){
      state.vocationalTrainingExamResultsResponse = action.payload
    },
    setCurrentPageNumber(state, action) {
      if(action.payload===0) state.usersInAndOutOfSeries.usersOutOfSeries = [];
      state.seriesFilters.currentPageNumber = action.payload;
    },
    setSeriesFilters(state,action){
      if(state.seriesFilters.search!==action.payload.search) state.usersInAndOutOfSeries.usersOutOfSeries = [];
      state.seriesFilters = action.payload;
    },
    setSearch(state, action) {
      if(state.seriesFilters.search!==action.payload) state.usersInAndOutOfSeries.usersOutOfSeries = [];
      state.seriesFilters={
        search:action.payload,
        currentPageNumber:0
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCourseSeriesWithoutExamSeriesAssigned.pending,
    (state, action: PayloadAction<any>) => {
      state.courseSeriesItems = [];
    }
  );
  builder.addCase(
    getCourseSeriesWithoutExamSeriesAssigned.fulfilled,
    (state, action: PayloadAction<CourseSeries[]>) => {
      state.courseSeriesItems = action.payload.map((series:CourseSeries)=>{
        return { label:series.seriesName, value:series.seriesId, adeverintaType:series.adeverintaType};
      });
    }
  );
    builder.addCase(
      requestCourseSeries.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.courseSeries = action.payload;
      }
    );
    builder.addCase(generateAdeverinta.pending, (state) => {
      state.generatingAdeverinta = true;
    });
    builder.addCase(generateAdeverinta.fulfilled, (state, action) => {
      state.generatingAdeverinta = false;
    });
    builder.addCase(generateAdeverinta.rejected, (state, action) => {
      state.generatingAdeverinta = false;
    });
    builder.addCase(getAssignedSeries.pending, (state) => {});
    builder.addCase(getAssignedSeries.fulfilled, (state, action) => {
      state.examResultsFilters.examSeriesId = action.payload;
    });

    builder.addCase(
      requestExamResultsDocs.fulfilled,
      (state, action: PayloadAction<any>) => {
        action.payload.vocDocuments.forEach((x: any) => {
          x.id = x.vocDocumentId;
        });
        state.vocationalTrainingExamResultsResponse = action.payload;
        state.examResultsListIsLoading = false;
      }
    );
    builder.addCase(requestExamResultsDocs.pending, (state, action) => {
      state.examResultsListIsLoading = true;
    });

    builder.addCase(
      getUsersOutOfSeries.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (state.seriesFilters.currentPageNumber === 0) {
          state.usersInAndOutOfSeries.usersOutOfSeries = action.payload.users;
          state.courseSeriesUserIsLoading = false;
          state.totalSeriesPages = action.payload.totalPages;
        } else
          state.usersInAndOutOfSeries.usersOutOfSeries = [
            ...state.usersInAndOutOfSeries.usersOutOfSeries,
            ...action.payload.users,
          ];
        state.courseSeriesUserIsLoading = false;
      }
    );
    builder.addCase(
      getUsersOfSeries.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.usersInAndOutOfSeries.usersOfSeries = action.payload;
        state.courseSeriesUserIsLoading = false;
      }
    );

    builder.addCase(
      requestExtraFilesDocs.fulfilled,
      (state, action: PayloadAction<any>) => {
        action.payload.vocDocuments.forEach((x: any) => {
          x.id = x.vocDocumentId;
        });
        state.vocationalTraininExtraFilesResponse = action.payload;
        state.extraFilesListIsLoading = false;
      }
    );
    builder.addCase(requestExtraFilesDocs.pending, (state, action) => {
      state.extraFilesListIsLoading = true;
    });
    builder.addCase(requestExtraFilesDocs.rejected, (state, action) => {
      state.extraFilesListIsLoading = false;
    });
    builder.addCase(downloadExamResultsDoc.pending, (state, action) => {
      state.vocationalFileIsDownloading = true;
    });
    builder.addCase(downloadExamResultsDoc.fulfilled, (state, action) => {
      state.vocationalFileIsDownloading = false;
    });
    builder.addCase(downloadExamResultsDoc.rejected, (state, action) => {
      state.vocationalFileIsDownloading = false;
    });
    builder.addCase(deleteExamResultsDoc.pending, (state, action) => {
      state.examResultsListIsLoading = true;
    });
    builder.addCase(deleteExamResultsDoc.fulfilled, (state, action) => {
      state.examResultsListIsLoading = false;
    });
    builder.addCase(deleteExamResultsDoc.rejected, (state, action) => {
      state.examResultsListIsLoading = false;
    });
    builder.addCase(uploadExamResultsDocs.pending, (state, action) => {
      state.vocationalFileIsUploading = true;
    });
    builder.addCase(uploadExamResultsDocs.fulfilled, (state, action) => {
      state.vocationalFileIsUploading = false;
    });
    builder.addCase(uploadExamResultsDocs.rejected, (state, action) => {
      state.vocationalFileIsUploading = false;
    });
    builder.addCase(
      downloadVocationalTrainingExtraFileDoc.pending,
      (state, action) => {
        state.vocationalFileIsDownloading = true;
      }
    );
    builder.addCase(
      downloadVocationalTrainingExtraFileDoc.fulfilled,
      (state, action) => {
        state.vocationalFileIsDownloading = false;
      }
    );
    builder.addCase(
      downloadVocationalTrainingExtraFileDoc.rejected,
      (state, action) => {
        state.vocationalFileIsDownloading = false;
      }
    );
    builder.addCase(deleteExtraFilesDoc.pending, (state, action) => {
      state.extraFilesListIsLoading = true;
    });
    builder.addCase(deleteExtraFilesDoc.fulfilled, (state, action) => {
      state.extraFilesListIsLoading = false;
    });
    builder.addCase(deleteExtraFilesDoc.rejected, (state, action) => {
      state.extraFilesListIsLoading = false;
    });
    builder.addCase(uploadExtraFilesDocs.pending, (state, action) => {
      state.vocationalFileIsUploading = true;
    });
    builder.addCase(uploadExtraFilesDocs.fulfilled, (state, action) => {
      state.vocationalFileIsUploading = false;
    });
    builder.addCase(uploadExtraFilesDocs.rejected, (state, action) => {
      state.vocationalFileIsUploading = false;
    });

    builder.addCase(getUsersOutOfSeries.pending, (state, action) => {
       if(state.seriesFilters.currentPageNumber===0){
          state.usersInAndOutOfSeries.usersOutOfSeries = [];
        }
      state.courseSeriesUserIsLoading = true;
    });
    builder.addCase(getUsersOutOfSeries.rejected, (state, action) => {
      state.courseSeriesUserIsLoading = false;
    });
    builder.addCase(addUsersToSeries.fulfilled, (state, action) => {
      state.userIsChangedBetweenCourseSeries = false;
    });
    builder.addCase(addUsersToSeries.pending, (state, action) => {
      state.userIsChangedBetweenCourseSeries = true;
    });
    builder.addCase(addUsersToSeries.rejected, (state, action) => {
      state.userIsChangedBetweenCourseSeries = false;
    });
  },
});

export const {
  setUsersInAndOutOfSeries,
  setExamResultsFilters,
  setCurrentPageNumber,
  setSearch,
  setSeriesFilters,
  setSearchInsideSeries,
  setUsersOfSeries,setVocationalTrainingExamResultsResponse,setExtraFilesFilters
} = vocationalTrainingSlice.actions;
