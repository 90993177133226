import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./UserCompanyView.scss";
import {
  countiesSelectItems,
  disadvantagedPersonSelectItems,
  educationLevelSelectItems,
  formResponseSelectItems,
  minoritySelectItems,
  targetGroupCategoriesItems,
  zoneSelectItems,
} from "store/models/selectItems";
import {
  FormikDatePicker,
  FormikSelectSimple,
  FormikTextField,
} from "components";
import { CompanyUserDetailsModel } from "store/models/targetGroups/CompanyUserDetailsModel";
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  useFormikContext,
} from "formik";
import { nationalitySelectItems } from "store/models/selectItems/nationalitySelectItems";
import { Nationality } from "store/models/enums/Nationality";
import { employmentMarketSituationItems } from "store/models/selectItems/employmentMarketSituationItems";
import * as yup from "yup";

import {
  generateUserForm,
  registerCompanyUser,
  selectCurrentUser,
  selectExpertsItems,
  selectGeneratingUserForm,
  selectUpdatedCompanyDetails,
  selectUserDetailsPageLocalization,
  updateUser,
  useAppDispatch,
  useAppSelector,
} from "store";
import { usersPath } from "navigation";
import { FormikDebouncedTextField } from "components/shared/formikDebouncedTextField/FormikDebouncedTextField";
import { genderItems } from "store/models/selectItems/genderItems";
import { LoadingButton } from "@mui/lab";

import { employmentMarketSituationExtraItems } from "store/models/selectItems/employmentMarketSiuationExtraItems";
import {
  BihorSelectItemsLabel,
  BistritaSelectItemsLabel,
  ClujSelectItemsLabel,
  CovasnaSelectItemsLabel,
  HarghitaSelectItemsLabel,
  MaramuresSelectItemsLabel,
  MuresSelectItemsLabel,
  SalajSelectItemsLabel,
  SatumareSelectItemsLabel,
  SibiuSelectItemsLabel,
  albaSelectItemsLabel,
  BrasovSelectItemsLabel,
} from "store/models/selectItems/county";
import { County } from "store/models/enums/County";
import { HighlightOffRounded } from "@mui/icons-material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { IndicatorsSelectItems } from "store/models/selectItems/indicatorsSelectItems";

export const UserCompanyView: FC = () => {
  const [sameAddress, setSameAddress] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [parsedIndicators, setParsedIndicators] = useState<any>([]);
  const [selectedCounty, setSelectedCounty] = useState(County.ALBA);
  const [selectedHomeCounty, setSelectedHomeCounty] = useState(County.ALBA);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const companyDetails = useAppSelector(selectUpdatedCompanyDetails);
  const { userId } = useParams();
  const formRef = useRef<any>();
  const [formValues, setFormValues] = useState<CompanyUserDetailsModel>();
  const valuesRef = useRef<any>(formValues);
  const generatingForm = useAppSelector(selectGeneratingUserForm);
  const ro = useAppSelector(selectUserDetailsPageLocalization);

  const navigate = useNavigate();

  useEffect(() => {
    isCheckBoxSelected();

    if (userId) setSameAddress(false);
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    valuesRef.current = formValues;
    //eslint-disable-next-line
  }, [formValues]);

  useEffect(() => {
    return () => {
      if (!userId) {
        localStorage.setItem(
          "registerUserForm",
          JSON.stringify(valuesRef.current)
        );
      }
    };
    //eslint-disable-next-line
  }, []);

  function GetValues() {
    const { values } = useFormikContext<CompanyUserDetailsModel>();

    setSelectedCounty(values.county);
    setSelectedHomeCounty(values.homeCounty);

    useEffect(() => {
      if (!userId) {
        setFormValues(values);
      }
    }, [values]);
    return null;
  }

  const initialValues: CompanyUserDetailsModel = {
    firstName: companyDetails?.firstName || "",
    lastName: companyDetails?.lastName || "",
    email: companyDetails?.email || "",
    expertUserId: companyDetails?.expertUserId || 0,
    numarTelefon: companyDetails?.numarTelefon || "",
    homeCounty: companyDetails?.homeCounty || 0,
    homeLocality: companyDetails?.homeLocality || "",
    homeAddress: companyDetails?.homeAddress || "",
    county: companyDetails?.county || 0,
    locality: companyDetails?.locality || "",
    adresa: companyDetails?.adresa || "",
    nationality: companyDetails?.nationality || 0,
    otherNationality: companyDetails?.otherNationality || "",
    entryDate: companyDetails?.entryDate || "",
    cnp: companyDetails?.cnp || "",
    zona: companyDetails?.zona || 0,
    region: companyDetails?.region || "",
    localizationCounty: companyDetails?.localizationCounty || 0,
    administrativeUnity: companyDetails?.administrativeUnity || "",
    gen: companyDetails?.gen || "",
    age: companyDetails?.age || 0,
    otherActivities: companyDetails?.otherActivities || false,
    targetGroupCategory: companyDetails?.targetGroupCategory || "",
    employmentMarketSituation: companyDetails?.employmentMarketSituation || 0,
    employmentMarketSituationOther:
      companyDetails?.employmentMarketSituationOther || false,
    educationLevel: companyDetails?.educationLevel || 0,
    isced0Over11: companyDetails?.isced0Over11 || false,
    disadvantagedPerson: companyDetails?.disadvantagedPerson || 0,
    migrant: companyDetails?.migrant || 0,
    foreignOrigins: companyDetails?.foreignOrigins || 0,
    minority: companyDetails?.minority || 0,
    disabilityPerson: companyDetails?.disabilityPerson || 0,
    noShelterPerson: companyDetails?.noShelterPerson || 0,
    sidelinedCommunityPerson: companyDetails?.sidelinedCommunityPerson || 0,
    otherSidelinedCategories: companyDetails?.otherSidelinedCategories || 0,
    date: companyDetails?.date || "",
    grade: companyDetails?.grade || "",
    indicators:
      parsedIndicators.length === 0
        ? [{ name: "", isIncluded: false }]
        : parsedIndicators,
    phoneNumber: companyDetails?.phoneNumber || "",
  };

  const validationSchemaStepOne = () =>
    yup.object().shape({
      firstName: yup.string().required("Numele este obligatoriu"),
      lastName: yup.string().required("Prenumele este obligatoriu"),
      email: yup
        .string()
        .email("Email invalid")
        .required("Email-ul este obligatoriu"),
      numarTelefon: yup
        .string()
        .required("Numarul de telefon este obligatoriu"),
      homeCounty: yup
        .number()
        .min(1, "Județul este obligatoriu")
        .required("Județul este obligatoriu"),
      homeLocality: yup.string().required("Localitatea este obligatorie"),
      homeAddress: yup.string().required("Adresa este obligatorie"),
      county: yup
        .number()
        .min(1, "Județul este obligatoriu")
        .required("Județul este obligatoriu"),
      // locality: yup.string().required("Localitatea este obligatorie"),
      adresa: yup.string().required("Adresa este obligatorie"),
      nationality: yup
        .number()
        .min(1, "Naționalitatea este obligatorie")
        .required("Naționalitatea este obligatorie"),

      otherNationality: yup.string().when(["nationality"], {
        is: (nationality: number) => nationality === 2,
        then: yup.string().required("Naționalitatea este obligatorie"),
        otherwise: yup.string().notRequired(),
      }),
      entryDate: yup
        .date()
        .required("Data este obligatorie")
        .typeError("Data este obligatorie"),
      cnp: yup
        .string()
        .matches(/^\d+$/, "Trebuie să conțină doar cifre")
        .test("len", "Trebuie sa conțină 13 cifre", (val) => val?.length === 13)
        .required("CNP-ul este obligatoriu"),
      zona: yup
        .number()
        .min(1, "Zona este obligatorie")
        .required("Zona este obligatorie"),
      region: yup.string().required("Regiunea este obligatorie"),
      localizationCounty: yup
        .number()
        .min(1, "Județul este obligatoriu")
        .required("Județul este obligatoriu"),
      administrativeUnity: yup
        .string()
        .required("Unitatea administrativă este obligatorie"),
      gen: yup
        .number()
        .min(1, "Județul este obligatoriu")
        .required("Genul este obligatoriu"),
      age: yup
        .number()
        .min(1, "Vârsta este obligatorie")
        .required("Vârsta este obligatorie"),
    });

  const validationSchemaStepTwo = () =>
    yup.object().shape({
      targetGroupCategory: yup
        .string()
        .nullable()
        .required("Categoria este obligatorie"),

      employmentMarketSituation: yup
        .number()
        .min(1, "Situația pe piața muncii este obligatorie")
        .required("Situația pe piața muncii este obligatorie"),
      educationLevel: yup
        .number()
        .min(1, "Nivelul de educație este obligatorie")
        .required("Nivelul de educație este obligatorie"),
      disadvantagedPerson: yup
        .number()
        .min(1, "Persoana este obligatorie")
        .required("Persoana este obligatorie"),
      migrant: yup
        .number()
        .min(1, "Migrantul este obligatoriu")
        .required("Migrantul este obligatoriu"),
      foreignOrigins: yup
        .number()
        .min(1, "Originea este obligatorie")
        .required("Originea este obligatorie"),
      minority: yup
        .number()
        .min(1, "Minoritatea este obligatorie")
        .required("Minoritatea este obligatorie"),
      disabilityPerson: yup
        .number()
        .min(1, "Persoana cu dizabilitate este obligatorie")
        .required("Persoana cu dizabilitate este obligatorie"),
      noShelterPerson: yup
        .number()
        .min(1, "Persoana fără adăpost este obligatorie")
        .required("Persoana fără adăpost este obligatorie"),
      sidelinedCommunityPerson: yup
        .number()
        .min(1, "Persoana din comunitatea marginalizată este obligatorie")
        .required("Persoana din comunitatea marginalizată este obligatorie"),
      otherSidelinedCategories: yup
        .number()
        .min(1, "Câmp obligatoriu")
        .required("Câmp obligatoriu"),

      date: yup
        .date()
        .required("Data este obligatorie")
        .typeError("Data este obligatorie"),
      expertUserId: yup
        .number()
        .min(1, "Selectați un expert")
        .required("Selectați un expert"),
    });

  const handleSubmit = (values: CompanyUserDetailsModel) => {
    if (currentStep === 1) {
      if (userId) {
        dispatch(
          updateUser({
            userData: {
              ...values,
              userId: Number(userId),
              userType: companyDetails?.userType,
            },
            token: currentUser?.jwtToken,
          })
        ).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            navigate(usersPath);
          }
        });
      } else
        dispatch(
          registerCompanyUser({
            userData: values,
            token: currentUser?.jwtToken,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.success) {
              navigate(usersPath);
              localStorage.removeItem("registerUserForm");
            }
          });
    } else setCurrentStep((previndex) => previndex + 1);
  };

  const experts = useAppSelector(selectExpertsItems);
  const expertsItems = experts?.map((exp) => {
    return {
      label: exp.firstName + " " + exp.lastName,
      value: exp.userId,
    };
  });

  const handleSendDateAndHomeAddress = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    values: CompanyUserDetailsModel
  ) => {
    if (sameAddress) {
      if (currentStep === 0) {
        setFieldValue("homeLocality", values.locality);
        setFieldValue("homeAddress", values.adresa);
        setFieldValue("homeCounty", values.county);
      }
    }
    if (!userId) {
      setFieldValue("date", values.entryDate);
    }
  };

  const validationSchema = () => {
    switch (currentStep) {
      case 0:
        return validationSchemaStepOne();
      case 1:
        return validationSchemaStepTwo();
    }
  };

  const handleGenerateForm = () => {
    dispatch(
      generateUserForm({
        token: currentUser?.jwtToken,
        userId: parseInt(userId!, 10),
      })
    );
  };

  const handleEmploymentMarketSituations = (value: number) => {
    switch (value) {
      case 1:
        return employmentMarketSituationExtraItems[1].label;
      case 2:
        return employmentMarketSituationExtraItems[0].label;
      case 5:
        return employmentMarketSituationExtraItems[2].label;
    }
  };

  const localitySwitch = (selectedCounty?: County) => {
    switch (selectedCounty) {
      case County.ALBA:
        return albaSelectItemsLabel;
      case County.BIHOR:
        return BihorSelectItemsLabel;
      case County.BISTRITA_NASAUD:
        return BistritaSelectItemsLabel;
      case County.BRASOV:
        return BrasovSelectItemsLabel;
      case County.CLUJ:
        return ClujSelectItemsLabel;
      case County.COVASNA:
        return CovasnaSelectItemsLabel;
      case County.HARGHITA:
        return HarghitaSelectItemsLabel;
      case County.MARAMURES:
        return MaramuresSelectItemsLabel;
      case County.MURES:
        return MuresSelectItemsLabel;
      case County.SATU_MARE:
        return SatumareSelectItemsLabel;
      case County.SALAJ:
        return SalajSelectItemsLabel;
      case County.SIBIU:
        return SibiuSelectItemsLabel;
      default:
        return albaSelectItemsLabel;
    }
  };

  const homeLocalitySwitch = (selectedHomeCounty?: County) => {
    switch (selectedHomeCounty) {
      case County.ALBA:
        return albaSelectItemsLabel;
      case County.BIHOR:
        return BihorSelectItemsLabel;
      case County.BISTRITA_NASAUD:
        return BistritaSelectItemsLabel;
      case County.BRASOV:
        return BrasovSelectItemsLabel;
      case County.CLUJ:
        return ClujSelectItemsLabel;
      case County.COVASNA:
        return CovasnaSelectItemsLabel;
      case County.HARGHITA:
        return HarghitaSelectItemsLabel;
      case County.MARAMURES:
        return MaramuresSelectItemsLabel;
      case County.MURES:
        return MuresSelectItemsLabel;
      case County.SATU_MARE:
        return SatumareSelectItemsLabel;
      case County.SALAJ:
        return SalajSelectItemsLabel;
      case County.SIBIU:
        return SibiuSelectItemsLabel;
      default:
        return albaSelectItemsLabel;
    }
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue("locality", companyDetails?.locality || "");
      formRef.current.setFieldValue(
        "homeLocality",
        companyDetails?.homeLocality || ""
      );
    }

    //eslint-disable-next-line
  }, [companyDetails?.locality, companyDetails?.homeLocality]);

  useEffect(() => {
    const localityFieldValue = formRef.current.values.locality;

    const localitiesForCounty = localitySwitch(selectedCounty);
    if (selectedCounty && localityFieldValue === "") {
      if (formRef.current) {
        formRef.current.setFieldValue("locality", localitiesForCounty[0].value);
      }
    } else {
      if (formRef.current && localityFieldValue !== "") {
        formRef.current.setFieldValue("locality", localityFieldValue);
      }
    }

    //eslint-disable-next-line
  }, [selectedCounty]);
  useEffect(() => {
    const homeLocalityFieldValue = formRef.current.values.homeLocality;
    const localitiesForCounty = homeLocalitySwitch(selectedHomeCounty);
    if (selectedHomeCounty && homeLocalityFieldValue === "") {
      if (formRef.current) {
        formRef.current.setFieldValue(
          "homeLocality",
          localitiesForCounty[0].value
        );
      }
    } else {
      if (formRef.current && homeLocalityFieldValue !== "") {
        formRef.current.setFieldValue("homeLocality", homeLocalityFieldValue);
      }
    }

    //eslint-disable-next-line
  }, [selectedHomeCounty]);

  const isCheckBoxSelected = () => {
    const arr: string[] = companyDetails?.indicators?.split("/");
    const cleanedArr = arr?.map((elem) =>
      elem.substring(elem.indexOf("/") + 1)
    );
    let arrayOfObjects: any[] = [];
    cleanedArr?.forEach((elem, index) => {
      if (elem.includes("true")) {
        arrayOfObjects.push({
          name: elem.replace("{true}", ""),
          isIncluded: true,
        });
      } else {
        arrayOfObjects.push({
          name: elem.replace("{false}", ""),
          isIncluded: false,
        });
      }
    });
    setParsedIndicators(arrayOfObjects);
  };

  return (
    <Container
      id="user-company-view"
      maxWidth="lg"
      sx={{ minHeight: "inherit", padding: "0 10px" }}
      disableGutters
    >
      {userId && (
        <Grid item container xs={12} justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            color="primary"
            loading={generatingForm}
            onClick={handleGenerateForm}
            disabled={generatingForm}
          >
            {ro.generateForm}
          </LoadingButton>
        </Grid>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={(values: any) => {
          handleSubmit(values);
        }}
        validationSchema={validationSchema()}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue }) => (
          <Form className="company-form">
            <Grid
              item
              xs={12}
              container
              justifyContent="space-around"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <GetValues />

              {currentStep === 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography className="title">
                      {ro.identificationData}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="subtitle" textAlign="left">
                      {ro.contactData}
                    </Divider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="lastName"
                      name="lastName"
                      label="Nume"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="firstName"
                      name="firstName"
                      label="Prenume"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="numarTelefon"
                      name="numarTelefon"
                      label="Telefon"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="Email"
                      name="email"
                      label="Email"
                      readOnly={userId !== undefined}
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="subtitle" textAlign="left">
                      {ro.home}
                    </Divider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="county"
                      items={countiesSelectItems}
                      label="Județ"
                      validateOnChange
                      onChangeCallback={() => {
                        currentStep === 0 && setFieldValue("locality", "");
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      options={localitySwitch(selectedCounty)}
                      value={{
                        value: values.locality,
                        label: values.locality,
                      }}
                      onChange={(event, value) => {
                        setFieldValue("locality", value?.value || "");
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            name="locality"
                            label="Localitate"
                            fullWidth
                          />
                        );
                      }}
                      key={values.locality}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="address"
                      name="adresa"
                      label="Adresa"
                      validateOnChange
                    />
                  </Grid>

                  <Grid item xs={12} className="same-address-checkbox">
                    <Checkbox
                      checked={sameAddress}
                      onChange={(e) => {
                        setSameAddress(e.target.checked);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                    <Typography> {ro.sameResidence}</Typography>
                  </Grid>

                  {!sameAddress && (
                    <>
                      <Grid item xs={12}>
                        <Divider className="subtitle" textAlign="left">
                          {ro.residence}
                        </Divider>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormikSelectSimple
                          name="homeCounty"
                          items={countiesSelectItems}
                          label="Județ"
                          validateOnChange
                          onChangeCallback={() =>
                            currentStep === 0 &&
                            setFieldValue("homeLocality", "")
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          disableClearable
                          disablePortal
                          id="combo-box-demo"
                          options={homeLocalitySwitch(selectedHomeCounty)}
                          value={{
                            value: values.homeLocality,
                            label: values.homeLocality,
                          }}
                          onChange={(event, value) => {
                            setFieldValue("homeLocality", value?.value || "");
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                name="homeLocality"
                                label="Localitate"
                                fullWidth
                              />
                            );
                          }}
                          key={values.homeLocality}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormikDebouncedTextField
                          variant="outlined"
                          id="address"
                          name="homeAddress"
                          label="Adresa"
                          validateOnChange
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="nationality"
                      items={nationalitySelectItems}
                      label="Naționalitate"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {values.nationality === Nationality.Other && (
                      <FormikDebouncedTextField
                        variant="outlined"
                        id="otherNationality"
                        name="otherNationality"
                        label="Nume naționalitate"
                        validateOnChange
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikDatePicker
                      name="entryDate"
                      label="Data intrării în operațiune"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="cnp"
                      name="cnp"
                      label="CNP"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="subtitle" textAlign="left">
                      {ro.geographicLocation}
                    </Divider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="zona"
                      items={zoneSelectItems}
                      label="Zonă"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="region"
                      name="region"
                      label="Regiune"
                      validateOnChange
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="localizationCounty"
                      items={countiesSelectItems}
                      label="Județ"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="administrativeUnity"
                      name="administrativeUnity"
                      label="Unitate teritorial administrativă"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="gen"
                      label="Gen"
                      items={genderItems}
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDebouncedTextField
                      variant="outlined"
                      id="age"
                      name="age"
                      label="Vârsta(Ani împliniți la intrarea în operațiune)"
                      type="number"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} className="same-address-checkbox">
                    <Checkbox
                      checked={values.otherActivities}
                      onChange={(e) =>
                        setFieldValue("otherActivities", e.target.checked)
                      }
                    />
                    <Typography>{ro.isEmployedInOrganization}</Typography>
                  </Grid>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <Grid item xs={12}>
                    <Typography className="title">{ro.sectionA}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormikSelectSimple
                      name="expertUserId"
                      items={expertsItems || []}
                      label="Expertul atribuit"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikSelectSimple
                      name="targetGroupCategory"
                      items={targetGroupCategoriesItems}
                      label="Categoria de grup țintă"
                      validateOnChange
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="employmentMarketSituation"
                      items={employmentMarketSituationItems}
                      label="Situația pe piața forței de muncă"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {values.employmentMarketSituation > 0 && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={values.employmentMarketSituationOther}
                          onChange={(e) =>
                            setFieldValue(
                              "employmentMarketSituationOther",
                              e.target.checked
                            )
                          }
                        />
                        <Typography>
                          {handleEmploymentMarketSituations(
                            values.employmentMarketSituation
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="educationLevel"
                      items={educationLevelSelectItems}
                      label="Nivel de educație"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="disadvantagedPerson"
                      items={disadvantagedPersonSelectItems}
                      label="Persoană dezavantajată"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} className="same-address-checkbox">
                    <Checkbox
                      checked={values.isced0Over11}
                      onChange={(e) =>
                        setFieldValue("isced0Over11", e.target.checked)
                      }
                    />
                    <Typography>{ro.isced0}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="migrant"
                      items={formResponseSelectItems}
                      label="Migranți"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="foreignOrigins"
                      items={formResponseSelectItems}
                      label="Participanți de origine străină"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="minority"
                      items={minoritySelectItems}
                      label="Participanți de origine străină"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="disabilityPerson"
                      items={formResponseSelectItems}
                      label="Participanți cu dizabilități"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="noShelterPerson"
                      items={formResponseSelectItems}
                      label="Persoane fără adăpost sau care sunt afectate de excluziunea locativă"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="sidelinedCommunityPerson"
                      items={formResponseSelectItems}
                      label="Persoane din comunități marginalizate"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikSelectSimple
                      name="otherSidelinedCategories"
                      items={formResponseSelectItems}
                      label="Alte categorii defavorizate"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikDatePicker
                      name="date"
                      label="Data"
                      validateOnChange
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="title">{ro.sectionD}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      //@ts-ignore
                      <FieldArray name="indicators" className="field-array">
                        {(fieldArrayProps: FieldArrayRenderProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { indicators } = values;

                          return (
                            <Box className="answers-card-list">
                              {indicators?.map((t: string, index: number) => (
                                <Box key={index} className="answer-card">
                                  <TextField
                                    select
                                    name={`indicators[${index}]`}
                                    label="Indicator"
                                    value={indicators[index]?.name}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `indicators[${index}].name`,
                                        e.target.value
                                      );

                                      setFieldValue(
                                        `indicators[${index}].isIncluded`,
                                        false
                                      );
                                    }}
                                    className="textfield"
                                  >
                                    <MenuItem>Fara Indicator</MenuItem>
                                    {IndicatorsSelectItems?.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.indicators[index].isIncluded
                                          }
                                          onChange={(e) =>
                                            setFieldValue(
                                              `indicators[${index}].isIncluded`,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                      label="Persoană inclusă în calcularea indicatorului"
                                    />
                                  </FormGroup>

                                  {index > 0 ? (
                                    <Tooltip title={"Șterge indicator"}>
                                      <>
                                        <IconButton
                                          type="button"
                                          className="delete-answer-button"
                                          onClick={() => remove(index)}
                                          // disabled={sectionIndex === -1}
                                        >
                                          <HighlightOffRounded />
                                        </IconButton>
                                      </>
                                    </Tooltip>
                                  ) : null}
                                </Box>
                              ))}

                              <Tooltip title="Adaugă indicator">
                                <>
                                  <IconButton
                                    type="button"
                                    onClick={() =>
                                      push({ name: "", isIncluded: false })
                                    }
                                    className="add-indicator"
                                    // disabled={sectionIndex === -1}
                                  >
                                    <ControlPointIcon />
                                  </IconButton>
                                </>
                              </Tooltip>
                            </Box>
                          );
                        }}
                      </FieldArray>
                    }
                  </Grid>

                  {userId && (
                    <>
                      <Grid item xs={12}>
                        <Typography className="title">
                          NOTA DE LA EXAMEN
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormikTextField
                          name="grade"
                          label="Nota"
                          type="number"
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}

              <Grid item xs={12} sm={6} className="back-btn">
                <Button
                  variant="outlined"
                  disabled={currentStep === 0}
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                >
                  {ro.back}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className="next-btn">
                {currentStep === 0 ? (
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={(e) => {
                      if (!userId) {
                        localStorage.setItem(
                          "registerUserForm",
                          JSON.stringify(valuesRef.current)
                        );
                      }
                      handleSendDateAndHomeAddress(setFieldValue, values);
                      isCheckBoxSelected();
                    }}
                  >
                    {ro.forrward}
                  </Button>
                ) : (
                  <Button variant="contained" type="submit">
                    {userId ? "Actualizează" : "Adaugă utilizator"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
