import { Article, Delete, Download, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  requestGetAllCompanyUsers,
  selectCurrentUser,
  selectUsersListIsLoading,
  selectUsers,
  selectUsersRowCount,
  useAppDispatch,
  useAppSelector,
  selectTargetGroupFilters,
  selectUsersPageLocalization,
  getAllExpertUsers,
  generateTargetGroupExcel,
  generateUserForm,
  requestCourseSeries,
  generateIndicatorsRegister,
  generateTargetGroupRegister,
  selectExpertUsersFilters,
  selectExpertsItems,
  selectExpertsRowCount,
} from "store";
import { mapColumnNameToEnum } from "utils/mapColumnNametoEnum";
import "./UsersPage.scss";
import {
  setDeletingUser,
  setDeletingUserId,
  setExpertUsersFilters,
  setSelectedTargetGroupUser,
  setTargetGroupFilters,
  setUpdateCompanyDetails,
} from "store/slices/targetGroupSlice";
import { newUserPath } from "navigation";
import { UserType } from "store/models/enums/UserType";

import { UserDeleteConfirmationDialog } from "pages/users-page/confirmationDialog/UserDeleteConfirmationDialog";
import { onlyDateFormat } from "utils";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import { isAdmin, isExpert } from "utils/getUserType";
import { TargetGroupFilters } from "components/users/filters/TargetGroupFilters";
import { countiesSelectItems } from "store/models/selectItems";

import { DataGridColumnNames } from "store/models/enums/DataGridColumnNames";
import { mapStatusBeneficiarToLabel } from "utils/mapStatusBeneficiarToLabel";
import { EmploymentMarketSituationAll } from "store/models/selectItems/employmentMarketSituationAll";
import { employmentMarketSituationExtraItems } from "store/models/selectItems/employmentMarketSiuationExtraItems";

import { SeriesType } from "store/models/enums/SeriesType";
import { ExpertTypeSelectItems } from "store/models/selectItems/expertTypeSelectItems";
import { useWindowWidth } from "utils/useWindowWidth";

export const UsersPage: FC = () => {
  const [seriesList, setSeriesList] = useState<any[]>([]);
  const [loadingUserForm, setLoadingUserForm] = useState("");
  const [loadingTargetGroup, setLoadingTargetGroup] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<string>("");
  const [showExperts, setShowExperts] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const users = useAppSelector(selectUsers);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const usersRowCount = useAppSelector(selectUsersRowCount);
  const usersListIsLoading = useAppSelector(selectUsersListIsLoading);
  const filters = useAppSelector(selectTargetGroupFilters);
  const localization = useAppSelector(selectUsersPageLocalization);
  const expertUsersFilters = useAppSelector(selectExpertUsersFilters);
  const expertsList = useAppSelector(selectExpertsItems);
  const expertsRowCount = useAppSelector(selectExpertsRowCount);
  const rows = showExperts ? expertsList : users;

  const rowCount = showExperts ? expertsRowCount : usersRowCount;
  const windowWidth = useWindowWidth();

  const getExpertUsers = () => {
    dispatch(
      getAllExpertUsers({
        token: currentUser?.jwtToken,
        filter: {
          pageNumber: 0,
          columnToSortBy: DataGridColumnNames.LastName,
          sortingOrder: false,
          searchText: "",
          pageSize: showExperts ? 10 : 100,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      requestCourseSeries({
        type: SeriesType.Course,
        token: currentUser?.jwtToken,
      })
    ).then((res) => {
      setSeriesList(res.payload);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHasMounted(true);

    if (hasMounted) getExpertUsers();

    //eslint-disable-next-line
  }, [showExperts]);

  useEffect(() => {
    dispatch(
      getAllExpertUsers({
        token: currentUser?.jwtToken,
        filter: expertUsersFilters,
      })
    );
    //eslint-disable-next-line
  }, [expertUsersFilters]);

  useEffect(() => {
    dispatch(
      requestGetAllCompanyUsers({
        filter: filters,
        token: currentUser?.jwtToken,
      })
    );
    //eslint-disable-next-line
  }, [filters]);

  const isAdminUser = () => {
    if (currentUser?.userType === UserType.ADMIN) {
      return true;
    } else return false;
  };
  const isExpertUser = () => {
    if (currentUser?.userType === UserType.EXPERT) {
      return true;
    } else return false;
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0 && !showExperts) {
      dispatch(
        setTargetGroupFilters({
          ...filters,
          pageNumber: 0,
          columnToSortBy: mapColumnNameToEnum(newModel[0].field),
          sortingOrder: newModel[0].sort === "asc" ? true : false,
        })
      );
    } else if (newModel.length < 1 && !showExperts) {
      dispatch(
        setTargetGroupFilters({
          ...filters,
          columnToSortBy: DataGridColumnNames.LastName,
          pageNumber: 0,
          sortingOrder: false,
        })
      );
    }
    if (newModel.length > 0 && showExperts) {
      dispatch(
        setExpertUsersFilters({
          ...expertUsersFilters,
          pageNumber: 0,
          columnToSortBy: mapColumnNameToEnum(newModel[0].field),
          sortingOrder: newModel[0].sort === "asc" ? true : false,
        })
      );
    } else if (newModel.length < 1 && showExperts) {
      dispatch(
        setExpertUsersFilters({
          ...expertUsersFilters,
          columnToSortBy: DataGridColumnNames.LastName,
          pageNumber: 0,
          sortingOrder: false,
        })
      );
    }
  };

  const handleGenerateUserForm = (userId: any) => {
    setLoadingUserForm(userId);
    dispatch(
      generateUserForm({
        token: currentUser?.jwtToken,
        userId: userId,
      })
    ).then(() => setLoadingUserForm(""));
  };

  const handleGenerateTargetGroup = () => {
    setLoadingTargetGroup(true);

    switch (selectedDocument) {
      case "excel-target-group": {
        return dispatch(
          generateTargetGroupExcel({
            token: currentUser?.jwtToken,
            filters: filters,
          })
        ).then(() => setLoadingTargetGroup(false));
      }

      case "indicators": {
        return dispatch(
          generateIndicatorsRegister({
            token: currentUser?.jwtToken,
            filters: filters,
          })
        ).then(() => setLoadingTargetGroup(false));
      }
      case "register-target-group": {
        return dispatch(
          generateTargetGroupRegister({
            token: currentUser?.jwtToken,
            filters: filters,
          })
        ).then(() => setLoadingTargetGroup(false));
      }
    }
  };

  const handleEmploymentMarketSituations = (value: number) => {
    switch (value) {
      case 1:
        return employmentMarketSituationExtraItems[1].label;
      case 2:
        return employmentMarketSituationExtraItems[0].label;
      case 5:
        return employmentMarketSituationExtraItems[2].label;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "lastName",
      headerName: localization.lastNameCol,
      flex: 1,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "firstName",
      headerName: localization.firstNameCol,
      flex: 1,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: showExperts ? "numarTelefon" : "phoneNumber",
      headerName: localization.phoneCol,
      flex: 1,
      hide: windowWidth < 800,
    },
    {
      field: "email",
      headerName: localization.emailCol,
      flex: 1,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "expertType",
      headerName: localization.expertTypeCol,
      flex: 1,
      hide: !showExperts,
      valueFormatter: (params: GridValueFormatterParams) => {
        return ExpertTypeSelectItems?.find((x) => x.value === params.value)
          ?.label;
      },
    },
    {
      field: "age",
      headerName: localization.ageCol,
      flex: 0.2,
      minWidth: 50,
      hide: showExperts || windowWidth < 1368,
    },
    {
      field: "locality",
      headerName: localization.localityCol,
      flex: 1,
      hide: showExperts || windowWidth < 1100,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "county",
      headerName: localization.countyCol,
      flex: 1,

      valueFormatter: (params: GridValueFormatterParams) => {
        return countiesSelectItems?.find((x) => x.value === params.value)
          ?.label;
      },
    },
    {
      field: "entryDate",
      headerName: localization.entryCol,
      hide: showExperts || windowWidth < 1100,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", onlyDateFormat);
        }
      },
    },
    {
      field: "allocationTime",
      headerName: localization.allocationDate,
      hide: showExperts || windowWidth < 1368,
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", onlyDateFormat);
        }
      },
    },
    {
      field: "courseSeriesName",
      headerName: localization.serie,
      hide: showExperts || windowWidth < 1100,
      flex: 1,
    },
    {
      field: "statusBeneficiar",
      headerName: localization.status,
      hide: showExperts || windowWidth < 800,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return mapStatusBeneficiarToLabel(Number(params.value));
        }
      },
    },
    // {
    //   field: "targetGroupCategory",
    //   headerName: localization.targetCol,
    // minWidth: 100,
    //   flex: 1,
    //   valueFormatter: (params: GridValueFormatterParams) => {
    //     return targetGroupCategoriesItems((x) => x.value === params.value)
    //       ?.label;
    //   },
    // },
    {
      field: "employmentMarketSituation",
      headerName: localization.employmentCol,
      flex: 1,
      hide: showExperts || windowWidth < 1100,
      renderCell: (params: GridRenderCellParams) => {
        if (!filters.employmentMarketSituationOther) {
          return (
            <Tooltip
              title={
                EmploymentMarketSituationAll?.find(
                  (x) => x.value === params.value
                )?.label!
              }
            >
              <Typography variant="body2" className="tooltip-column">
                {
                  EmploymentMarketSituationAll?.find(
                    (x) => x.value === params.value
                  )?.label
                }
              </Typography>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={
                handleEmploymentMarketSituations(params?.value! as number) ?? ""
              }
            >
              <Typography variant="body2" className="tooltip-column">
                {handleEmploymentMarketSituations(params.value as number)}
              </Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      field: "expertUserId",
      headerName: localization.recruitedBy,
      flex: 1,
      hide: showExperts || windowWidth < 1368,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let expert = expertsList?.find((x) => x.userId === params.value);

          if (expert) {
            return expert.firstName + " " + expert.lastName;
          }
        }
      },
    },
    {
      field: "",
      headerName: localization.optionsCol,
      sortable: false,
      flex: 1,
      minWidth: windowWidth < 800 ? 90 : 110,
      align: "right",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ButtonGroup
            size="small"
            sx={{
              "& > *:not(:last-child)": {
                marginRight: 0.5,
              },
            }}
          >
            {!showExperts && (
              <Tooltip title={localization.downloadUserInfo}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={
                    windowWidth < 800
                      ? {
                          borderRadius: "4px !important",
                          width: 30,
                          height: 30,
                        }
                      : { borderRadius: "4px !important" }
                  }
                  disabled={loadingUserForm === params.rowNode.id}
                  onClick={(e) => {
                    handleGenerateUserForm(params.rowNode.id);
                  }}
                >
                  {loadingUserForm === params.rowNode.id ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Article sx={{ color: "white" }} />
                  )}
                </Button>
              </Tooltip>
            )}

            <Tooltip title={localization.viewUserTooltip}>
              <Button
                variant="contained"
                sx={
                  windowWidth < 800
                    ? {
                        borderRadius: "4px !important",
                        width: 30,
                        height: 30,
                      }
                    : { borderRadius: "4px !important" }
                }
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/utilizator/" + params.rowNode.id);
                  dispatch(setSelectedTargetGroupUser(null));
                }}
              >
                <Visibility />
              </Button>
            </Tooltip>
            {(isAdminUser() || isExpertUser()) && (
              <Tooltip title={localization.deleteUserTooltip}>
                <Button
                  variant="contained"
                  color="error"
                  sx={
                    windowWidth < 800
                      ? {
                          borderRadius: "4px !important",
                          width: 30,
                          height: 30,
                        }
                      : { borderRadius: "4px !important" }
                  }
                  onClick={() => {
                    dispatch(setDeletingUserId(params.rowNode.id));
                    dispatch(setDeletingUser(true));
                  }}
                >
                  <Delete />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const handlePageChange = (pageNumber: number) => {
    if (showExperts) {
      dispatch(
        setExpertUsersFilters({
          ...expertUsersFilters,
          pageNumber: pageNumber,
          pageSize: 10,
        })
      );
      return;
    } else
      dispatch(
        setTargetGroupFilters({
          ...filters,
          pageNumber: pageNumber,
        })
      );
  };

  const handleNewUserClick = (userType: UserType) => {
    dispatch(setUpdateCompanyDetails(undefined));
    navigate(newUserPath, { state: { userType: userType } });
  };

  const handleGenerateDocuments = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedDocument(e.target.value as string);
  };

  return (
    <Container id="users-page" disableGutters>
      <Grid container className="users-page-container">
        <Grid container display="flex" justifyContent="center">
          <Grid container className="register-buttons" maxWidth="lg">
            <Box className="register-buttons-container">
              <Button
                className="register-button"
                variant="contained"
                onClick={() => handleNewUserClick(UserType.COMPANY_USER)}
              >
                {localization.addUserBtn}
              </Button>
              {isAdmin(currentUser?.userType) && (
                <Button
                  className="register-button"
                  variant="contained"
                  color="warning"
                  onClick={() => handleNewUserClick(UserType.EXPERT)}
                >
                  {localization.addExpertBtn}
                </Button>
              )}
              {(isAdmin(currentUser?.userType) ||
                isExpert(currentUser?.userType)) && (
                <Button
                  className="register-button"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleNewUserClick(UserType.EMPLOYER)}
                >
                  {localization.addEmployerBtn}
                </Button>
              )}
            </Box>
            {!showExperts && (
              <Box>
                <TextField
                  select
                  size="small"
                  name="anexe"
                  label={localization.generateDocuments}
                  sx={{ minWidth: "210px", paddingRight: "10px" }}
                  onChange={(e) => handleGenerateDocuments(e)}
                  value={selectedDocument || ""}
                >
                  <MenuItem value="excel-target-group">
                    {localization.excelTargetGroup}
                  </MenuItem>
                  <MenuItem value="indicators">
                    {localization.excelIndicatorsRegister}
                  </MenuItem>
                  <MenuItem value="register-target-group">
                    {localization.excelTargetGroupRegister}
                  </MenuItem>
                </TextField>

                {selectedDocument && (
                  <Button
                    onClick={() => handleGenerateTargetGroup()}
                    variant="contained"
                    startIcon={
                      loadingTargetGroup ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <Download />
                      )
                    }
                    disabled={loadingTargetGroup}
                  >
                    {localization.generate}
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          display="flex"
          justifyContent="center"
          // alignItems="center"
        >
          <TargetGroupFilters
            expertsList={expertsList || []}
            seriesList={seriesList}
            setShowExperts={setShowExperts}
            showExperts={showExperts}
          />
          <Box className="generate-documents-buttons"></Box>
        </Grid>
        <Grid
          item
          className="users-page-table"
          sx={{
            display: "flex",
            alignItems: "stretch",
            flexGrow: 1,
            marginBottom: 5,
          }}
        >
          <DataGrid
            components={{
              LoadingOverlay: DataLoadingComponent,
            }}
            style={{ maxWidth: "100%" }}
            loading={usersListIsLoading}
            rows={rows ?? []}
            getRowId={(row) => row.userId}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            sx={{ flexGrow: 1, height: 630 }}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            disableColumnMenu={true}
            paginationMode="server"
            onPageChange={handlePageChange}
            page={
              showExperts ? expertUsersFilters.pageNumber : filters.pageNumber
            }
            rowCount={rowCount}
          ></DataGrid>
        </Grid>
      </Grid>
      <UserDeleteConfirmationDialog />
    </Container>
  );
};
