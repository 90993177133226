import { Box, Button, Container, Dialog, Grid, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CompanyDetails } from "components/job-details/company-details/CompanyDetails";
import { CompaniesList } from "components/job-details/company-users-list/CompaniesList";
import { JobDetails } from "components/job-details/job-details/JobDetails";
import React, {
  FC,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from "react";
import {
  selectCurrentUser,
  useAppDispatch,
  useAppSelector,
  useWindowSize,
} from "store";
import {
  selectJobDetails,
  selectSelectedCompany,
} from "store/selectors/mediationSelectors";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import "./JobDetailsPage.scss";
import { Close } from "@mui/icons-material";
import { JobInfo } from "components/job-details/job-info/JobInfo";
import { useNavigate, useParams } from "react-router";
import { getJobPost } from "store/thunks/mediationThunks";
import { homePath } from "navigation";
import { setJobDetails } from "store/slices/mediationSlice";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const JobDetailsPage: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const selectedCompanyId = useAppSelector(selectSelectedCompany);
  const jobDetails = useAppSelector(selectJobDetails);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const navigate = useNavigate();
  const params = useParams();

  const { width } = useWindowSize();

  const closeDialog = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      params.jobId === "0" ||
      params.jobId === null ||
      params.jobId === ":jobId"
    )
      navigate(homePath);
    else {
      var jobId = parseInt(params.jobId!, 10);
      dispatch(
        getJobPost({
          jobPostId: jobId,
          token: currentUser?.jwtToken,
        })
      );
    }
    return () => {
      dispatch(setJobDetails(undefined));
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl" disableGutters id="job-details-page">
      {jobDetails?.allocated ? (
        <Grid sx={{ marginTop: 2 }}>
          <JobInfo showClose={false} />
        </Grid>
      ) : (
        <React.Fragment>
          {" "}
          <Grid container item xs={3} className="companies-list">
            {width && width < 900 ? (
              <>
                <Button
                  className="candidates-modal"
                  onClick={() => setOpen(true)}
                >
                  <GroupAddIcon sx={{ paddingLeft: "5px" }} />
                </Button>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  onClose={closeDialog}
                  className="companies-dialog"
                >
                  <Box className="close-companies-dialog">
                    <Close onClick={closeDialog} />
                  </Box>

                  <CompaniesList />
                </Dialog>
              </>
            ) : (
              <CompaniesList />
            )}
          </Grid>
          <Grid
            container
            item
            xs={9}
            flexDirection="column"
            className="right-container"
          >
            <Grid item xs={6} className="right">
              <JobDetails />
            </Grid>
            <Grid item xs={6} className="right">
              {selectedCompanyId && <CompanyDetails />}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Container>
  );
};
