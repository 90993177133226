import { CloseOutlined } from "@mui/icons-material";
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { FileUploadComponent } from "components/shared/fileUpload/FileUploadComponent";
import { FC, useState } from "react";
import { selectCurrentUser, selectMediationPageLocalization, useAppDispatch, useAppSelector } from "store";
import { applicateToJob } from "store/thunks/mediationThunks";
import "./ApplicateToJobDialog.scss";

export const ApplicateToJobDialog: FC<{ jobPostId: number, display: boolean, close: Function }> = ({ jobPostId, display, close }) => {

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [file, setFile] = useState<File>();
  const ro = useAppSelector(selectMediationPageLocalization);


  const handleChangeFiles = (files: File[]) => {
    setFile(files[0])
  }

  const handleApplyButton = () => {
    dispatch(applicateToJob({
      token: currentUser?.jwtToken,
      dto: {
        userId: currentUser?.userId!,
        jobId: jobPostId,
        cv: file!
      }
    })).unwrap().then((data) => {
      if (data.success) {
        close();
      }
    })
  }



  return (
    <Dialog open={display} maxWidth="xs" fullWidth id="applicate-to-job-dialog">
      <Grid container justifyContent="space-between" alignItems="center" className="title">
        <Typography className="dialog-title">
          {ro.applicateDialogTitle}
        </Typography>
        <IconButton color="error" onClick={() => { close() }}>
          <CloseOutlined />
        </IconButton>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} className="file-upload-container">
          <FileUploadComponent fileLimit={1} onFilesChange={handleChangeFiles} />
        </Grid>
      </Grid>
      <Grid container className="actions" justifyContent="flex-end" columnSpacing={3} >
        <Grid item>
          <Button variant="contained" color="error" onClick={(e) => close()}>
            {ro.cancelBtn}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" disabled={file === undefined} onClick={handleApplyButton}>
            {ro.applyBtn}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}