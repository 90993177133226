export const HarghitaSelectItemsLabel = [
  { value: "ALDEA", label: "ALDEA" },
  { value: "ALEXANDRIȚA", label: "ALEXANDRIȚA" },
  { value: "ALUNIȘ", label: "ALUNIȘ" },
  { value: "ANDREENI", label: "ANDREENI" },
  { value: "ARMĂȘENI", label: "ARMĂȘENI" },
  { value: "ARMĂȘENII NOI", label: "ARMĂȘENII NOI" },
  { value: "ARVĂȚENI", label: "ARVĂȚENI" },
  { value: "ATIA", label: "ATIA" },
  { value: "ATID", label: "ATID" },
  { value: "AVRĂMEȘTI", label: "AVRĂMEȘTI" },
  { value: "BĂDENI", label: "BĂDENI" },
  { value: "BĂILE CHIRUI", label: "BĂILE CHIRUI" },
  { value: "BĂILE HOMOROD", label: "BĂILE HOMOROD" },
  { value: "BĂILE TUȘNAD", label: "BĂILE TUȘNAD" },
  { value: "BĂLAN", label: "BĂLAN" },
  { value: "BANCU", label: "BANCU" },
  { value: "BARAȚCOȘ", label: "BARAȚCOȘ" },
  { value: "BÂRZAVA", label: "BÂRZAVA" },
  { value: "BECAȘ", label: "BECAȘ" },
  { value: "BENȚID", label: "BENȚID" },
  { value: "BETA", label: "BETA" },
  { value: "BETEȘTI", label: "BETEȘTI" },
  { value: "BILBOR", label: "BILBOR" },
  { value: "BISERICANI", label: "BISERICANI" },
  { value: "BODOGAIA", label: "BODOGAIA" },
  { value: "BORSEC", label: "BORSEC" },
  { value: "BORZONT", label: "BORZONT" },
  { value: "BRĂDEȘTI", label: "BRĂDEȘTI" },
  { value: "BUCIN / JOSENI", label: "BUCIN / JOSENI" },
  { value: "BUCIN / PRAID", label: "BUCIN / PRAID" },
  { value: "BULGĂRENI", label: "BULGĂRENI" },
  { value: "CĂDACIU MARE", label: "CĂDACIU MARE" },
  { value: "CĂDACIU MIC", label: "CĂDACIU MIC" },
  { value: "CĂLIMĂNEL", label: "CĂLIMĂNEL" },
  { value: "CĂLNACI", label: "CĂLNACI" },
  { value: "CALONDA", label: "CALONDA" },
  { value: "CĂLUGĂRENI", label: "CĂLUGĂRENI" },
  { value: "CĂPÂLNIȚA", label: "CĂPÂLNIȚA" },
  { value: "CAPU CORBULUI", label: "CAPU CORBULUI" },
  { value: "CARPITUS", label: "CARPITUS" },
  { value: "CÂRȚA", label: "CÂRȚA" },
  { value: "CASINU NOU", label: "CASINU NOU" },
  { value: "CECHEȘTI", label: "CECHEȘTI" },
  { value: "CEHEȚEL", label: "CEHEȚEL" },
  { value: "CETĂȚUIA", label: "CETĂȚUIA" },
  { value: "CHEDIA MARE", label: "CHEDIA MARE" },
  { value: "CHEDIA MICĂ", label: "CHEDIA MICĂ" },
  { value: "CHILENI", label: "CHILENI" },
  { value: "CHINUȘU", label: "CHINUȘU" },
  { value: "CIARACIO", label: "CIARACIO" },
  { value: "CIBA", label: "CIBA" },
  { value: "CICEU", label: "CICEU" },
  { value: "CIOBĂNIȘ", label: "CIOBĂNIȘ" },
  { value: "CIREȘENI", label: "CIREȘENI" },
  { value: "CIUCANI", label: "CIUCANI" },
  { value: "CIUCSÂNGEORGIU", label: "CIUCSÂNGEORGIU" },
  { value: "CIUMANI", label: "CIUMANI" },
  { value: "COBĂTEȘTI", label: "COBĂTEȘTI" },
  { value: "COMĂNEȘTI", label: "COMĂNEȘTI" },
  { value: "COMIAT", label: "COMIAT" },
  { value: "CORBU", label: "CORBU" },
  { value: "CORUND", label: "CORUND" },
  { value: "COTORMANI", label: "COTORMANI" },
  { value: "COVACIPETER", label: "COVACIPETER" },
  { value: "COZMENI", label: "COZMENI" },
  { value: "CRĂCIUNEL", label: "CRĂCIUNEL" },
  { value: "CRIȘENI", label: "CRIȘENI" },
  { value: "CRISTURU SECUIESC", label: "CRISTURU SECUIESC" },
  { value: "CUȘMED", label: "CUȘMED" },
  { value: "DAIA", label: "DAIA" },
  { value: "DĂNEȘTI", label: "DĂNEȘTI" },
  { value: "DÂRJIU", label: "DÂRJIU" },
  { value: "DEALU", label: "DEALU" },
  { value: "DEALU ARMANULUI", label: "DEALU ARMANULUI" },
  { value: "DEJUȚIU", label: "DEJUȚIU" },
  { value: "DELNIȚA", label: "DELNIȚA" },
  { value: "DESAG", label: "DESAG" },
  { value: "DITRĂU", label: "DITRĂU" },
  { value: "DOBENI", label: "DOBENI" },
  { value: "DUDA", label: "DUDA" },
  { value: "EGHERSEC", label: "EGHERSEC" },
  { value: "ELISENI", label: "ELISENI" },
  { value: "FĂGEȚEL / FRUMOASA", label: "FĂGEȚEL / FRUMOASA" },
  { value: "FĂGEȚEL / REMETEA", label: "FĂGEȚEL / REMETEA" },
  { value: "FÂNCEL", label: "FÂNCEL" },
  { value: "FÂNTÂNA BRAZILOR", label: "FÂNTÂNA BRAZILOR" },
  { value: "FELICENI", label: "FELICENI" },
  { value: "FILIAȘ", label: "FILIAȘ" },
  { value: "FILPEA", label: "FILPEA" },
  { value: "FIRTĂNUȘ", label: "FIRTĂNUȘ" },
  { value: "FIRTUȘU", label: "FIRTUȘU" },
  { value: "FITOD", label: "FITOD" },
  { value: "FORȚENI", label: "FORȚENI" },
  { value: "FRUMOASA", label: "FRUMOASA" },
  { value: "FUNDOAIA", label: "FUNDOAIA" },
  { value: "GĂLĂUȚAȘ", label: "GĂLĂUȚAȘ" },
  { value: "GĂLĂUȚAȘ-PÂRÂU", label: "GĂLĂUȚAȘ-PÂRÂU" },
  { value: "GÂRCIU", label: "GÂRCIU" },
  { value: "GHEORGHENI", label: "GHEORGHENI" },
  { value: "GHIDUȚ", label: "GHIDUȚ" },

  { value: "GHIPEȘ", label: "GHIPEȘ" },
  { value: "GHIURCHE", label: "GHIURCHE" },
  { value: "GOAGIU", label: "GOAGIU" },
  { value: "HAGOTA", label: "HAGOTA" },
  { value: "HARGHITA-BĂI", label: "HARGHITA-BĂI" },
  { value: "HODOȘA", label: "HODOȘA" },
  { value: "HOGHIA", label: "HOGHIA" },
  { value: "HOSASĂU", label: "HOSASĂU" },
  { value: "IACOBENI", label: "IACOBENI" },
  { value: "IAȘU", label: "IAȘU" },
  { value: "IGHIU", label: "IGHIU" },
  { value: "IMPER", label: "IMPER" },
  { value: "INEU", label: "INEU" },
  { value: "INLĂCENI", label: "INLĂCENI" },
  { value: "IZVOARE", label: "IZVOARE" },
  { value: "IZVORU MUREȘULUI", label: "IZVORU MUREȘULUI" },
  { value: "IZVORUL TROTUȘULUI", label: "IZVORUL TROTUȘULUI" },
  { value: "JIGODIN-BĂI", label: "JIGODIN-BĂI" },
  { value: "JOLOTCA", label: "JOLOTCA" },
  { value: "JOSENI", label: "JOSENI" },
  { value: "LACU ROȘU", label: "LACU ROȘU" },
  { value: "LĂZAREA", label: "LĂZAREA" },
  { value: "LĂZĂREȘTI", label: "LĂZĂREȘTI" },
  { value: "LAZ-FIRTĂNUȘ", label: "LAZ-FIRTĂNUȘ" },
  { value: "LAZ-ȘOIMUȘ", label: "LAZ-ȘOIMUȘ" },
  { value: "LELICENI", label: "LELICENI" },
  { value: "LIBAN", label: "LIBAN" },
  { value: "LIVEZI", label: "LIVEZI" },
  { value: "LOCODENI", label: "LOCODENI" },
  { value: "LUETA", label: "LUETA" },
  { value: "LUNCA DE JOS", label: "LUNCA DE JOS" },
  { value: "LUNCA DE SUS", label: "LUNCA DE SUS" },
  { value: "LUNCANI", label: "LUNCANI" },
  { value: "LUPENI", label: "LUPENI" },
  { value: "LUTIȚA", label: "LUTIȚA" },
  { value: "MĂDĂRAȘ", label: "MĂDĂRAȘ" },
  { value: "MĂGHERUȘ", label: "MĂGHERUȘ" },
  { value: "MĂRTINIȘ", label: "MĂRTINIȘ" },
  { value: "MARTONCA", label: "MARTONCA" },
  { value: "MĂTIȘENI", label: "MĂTIȘENI" },
  { value: "MEDIȘORU MARE", label: "MEDIȘORU MARE" },
  { value: "MEDIȘORU MIC", label: "MEDIȘORU MIC" },
  { value: "MEREȘTI", label: "MEREȘTI" },
  { value: "MIERCUREA CIUC", label: "MIERCUREA CIUC" },
  { value: "MIHĂILENI / MIHĂILENI", label: "MIHĂILENI / MIHĂILENI" },
  { value: "MIHĂILENI / ȘIMONEȘTI", label: "MIHĂILENI / ȘIMONEȘTI" },
  { value: "MINELE LUETA", label: "MINELE LUETA" },
  { value: "MISENTEA", label: "MISENTEA" },
  { value: "MOGLĂNEȘTI", label: "MOGLĂNEȘTI" },
  { value: "MORĂRENI", label: "MORĂRENI" },
  { value: "MUGENI", label: "MUGENI" },
  { value: "MUJNA", label: "MUJNA" },
  { value: "NĂDEJDEA", label: "NĂDEJDEA" },
  { value: "NICOLENI", label: "NICOLENI" },
  { value: "NICOLEȘTI / FRUMOASA", label: "NICOLEȘTI / FRUMOASA" },
  { value: "NICOLEȘTI / ULIEȘ", label: "NICOLEȘTI / ULIEȘ" },
  { value: "NUȚENI", label: "NUȚENI" },
  { value: "OBRĂNEȘTI", label: "OBRĂNEȘTI" },
  { value: "OCLAND", label: "OCLAND" },
  { value: "OCNA DE JOS", label: "OCNA DE JOS" },
  { value: "OCNA DE SUS", label: "OCNA DE SUS" },
  { value: "ODORHEIU SECUIESC", label: "ODORHEIU SECUIESC" },
  { value: "ORĂȘENI", label: "ORĂȘENI" },
  { value: "OȚENI", label: "OȚENI" },
  { value: "PĂLTINIȘ", label: "PĂLTINIȘ" },
  { value: "PĂLTINIȘ-CIUC", label: "PĂLTINIȘ-CIUC" },
  { value: "PĂULENI", label: "PĂULENI" },
  { value: "PĂULENI-CIUC", label: "PĂULENI-CIUC" },
  { value: "PETECU", label: "PETECU" },
  { value: "PETRENI", label: "PETRENI" },
  { value: "PINTIC", label: "PINTIC" },
  { value: "PLĂIEȘII DE JOS", label: "PLĂIEȘII DE JOS" },
  { value: "PLĂIEȘII DE SUS", label: "PLĂIEȘII DE SUS" },
  { value: "PLATONEȘTI", label: "PLATONEȘTI" },
  { value: "PLOPIȘ", label: "PLOPIȘ" },
  { value: "POIANA FAGULUI", label: "POIANA FAGULUI" },
  { value: "POIANA TÂRNAVEI", label: "POIANA TÂRNAVEI" },
  { value: "POLONIȚA", label: "POLONIȚA" },
  { value: "PORUMBENII MARI", label: "PORUMBENII MARI" },
  { value: "PORUMBENII MICI", label: "PORUMBENII MICI" },
  { value: "POTIOND", label: "POTIOND" },
  { value: "PRAID", label: "PRAID" },
  { value: "PRELUCA", label: "PRELUCA" },
  { value: "PUNTEA LUPULUI", label: "PUNTEA LUPULUI" },
  { value: "RĂCHITIȘ", label: "RĂCHITIȘ" },
  { value: "RACU", label: "RACU" },
  { value: "RAREȘ", label: "RAREȘ" },
  { value: "RECEA", label: "RECEA" },
  { value: "REMETEA", label: "REMETEA" },
  { value: "RUGĂNEȘTI", label: "RUGĂNEȘTI" },
  { value: "RUNC", label: "RUNC" },
  { value: "SĂCEL", label: "SĂCEL" },
  { value: "SÂNCEL", label: "SÂNCEL" },
  { value: "SÂNCRAI", label: "SÂNCRAI" },
  { value: "SÂNCRĂIENI", label: "SÂNCRĂIENI" },
  { value: "SÂNDOMINIC", label: "SÂNDOMINIC" },
  { value: "SÂNMARTIN", label: "SÂNMARTIN" },
  { value: "SÂNPAUL", label: "SÂNPAUL" },
  { value: "SÂNSIMION", label: "SÂNSIMION" },
  { value: "SÂNTIMBRU", label: "SÂNTIMBRU" },
  { value: "SÂNTIMBRU-BĂI", label: "SÂNTIMBRU-BĂI" },
  { value: "SĂRMAȘ", label: "SĂRMAȘ" },
  { value: "ȘAȘVEREȘ", label: "ȘAȘVEREȘ" },
  { value: "SATU MARE", label: "SATU MARE" },
  { value: "SATU MIC", label: "SATU MIC" },
  { value: "SATU NOU", label: "SATU NOU" },
  { value: "SECU", label: "SECU" },
  { value: "SECUIENI", label: "SECUIENI" },
  { value: "SENETEA", label: "SENETEA" },
  { value: "ȘICASĂU", label: "ȘICASĂU" },
  { value: "ȘICLOD", label: "ȘICLOD" },
  { value: "SICULENI", label: "SICULENI" },
  { value: "ȘIMONEȘTI", label: "ȘIMONEȘTI" },
  { value: "SINEU", label: "SINEU" },
  { value: "ȘOIMENI", label: "ȘOIMENI" },
  { value: "ȘOIMUȘU MARE", label: "ȘOIMUȘU MARE" },
  { value: "ȘOIMUȘU MIC", label: "ȘOIMUȘU MIC" },
  { value: "SUB CETATE", label: "SUB CETATE" },
  { value: "SUBCETATE", label: "SUBCETATE" },
  { value: "SUSENI", label: "SUSENI" },
  { value: "TĂIETURA", label: "TĂIETURA" },
  { value: "TĂMAȘU", label: "TĂMAȘU" },
  { value: "TĂRCEȘTI", label: "TĂRCEȘTI" },
  { value: "TÂRNOVIȚA", label: "TÂRNOVIȚA" },
  { value: "TĂURENI", label: "TĂURENI" },
  { value: "TELEAC", label: "TELEAC" },
  { value: "ȚENGHELER", label: "ȚENGHELER" },
  { value: "TIBOD", label: "TIBOD" },
  { value: "TOLEȘENI", label: "TOLEȘENI" },
  { value: "TOMEȘTI", label: "TOMEȘTI" },
  { value: "TOPLIȚA", label: "TOPLIȚA" },
  { value: "TULGHEȘ", label: "TULGHEȘ" },
  { value: "TURDENI", label: "TURDENI" },
  { value: "TUȘNAD", label: "TUȘNAD" },
  { value: "TUȘNADU NOU", label: "TUȘNADU NOU" },
  { value: "UILAC", label: "UILAC" },
  { value: "ULCANI", label: "ULCANI" },
  { value: "ULIEȘ", label: "ULIEȘ" },
  { value: "VĂCĂREȘTI", label: "VĂCĂREȘTI" },
  { value: "VÂGANI", label: "VÂGANI" },
  { value: "VALE", label: "VALE" },
  { value: "VALEA BOROȘ", label: "VALEA BOROȘ" },
  { value: "VALEA CAPELEI", label: "VALEA CAPELEI" },
  { value: "VALEA GÂRBEA", label: "VALEA GÂRBEA" },
  { value: "VALEA ÎNTUNECOASĂ", label: "VALEA ÎNTUNECOASĂ" },
  { value: "VALEA LUI ANTALOC", label: "VALEA LUI ANTALOC" },
  { value: "VALEA LUI PAVEL", label: "VALEA LUI PAVEL" },
  { value: "VALEA RECE", label: "VALEA RECE" },
  { value: "VALEA ROTUNDĂ", label: "VALEA ROTUNDĂ" },
  { value: "VALEA STRÂMBĂ", label: "VALEA STRÂMBĂ" },
  { value: "VALEA UGRA", label: "VALEA UGRA" },
  { value: "VALEA UZULUI", label: "VALEA UZULUI" },
  { value: "VĂLENI", label: "VĂLENI" },
  { value: "VARGATAC", label: "VARGATAC" },
  { value: "VĂRȘAG", label: "VĂRȘAG" },
  { value: "VASILENI", label: "VASILENI" },
  { value: "VIDACUT", label: "VIDACUT" },
  { value: "VISAFOLIO", label: "VISAFOLIO" },
  { value: "VLĂHIȚA", label: "VLĂHIȚA" },
  { value: "VOȘLĂBENI", label: "VOȘLĂBENI" },
  { value: "VRABIA", label: "VRABIA" },
  { value: "ZĂPODEA", label: "ZĂPODEA" },
  { value: "ZENCANI", label: "ZENCANI" },
  { value: "ZETEA", label: "ZETEA" },
];
