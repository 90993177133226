export const targetGroupCategoriesItems = [
  {
    value:
      "Şomeri şi persoane inactive, cu accent pe şomerii de lungă durată, lucrătorii vârstnici (55-64 ani), persoanele cu dizabilităţi, persoanele cu nivel redus de educație",
    label:
      "Şomeri şi persoane inactive, cu accent pe şomerii de lungă durată, lucrătorii vârstnici (55-64 ani), persoanele cu dizabilităţi, persoanele cu nivel redus de educație",
  },
  {
    value: "Cetățeni români aparținând minorităţii roma",
    label: "Cetățeni români aparținând minorităţii roma",
  },
  {
    value:
      "Persoanele din mediul rural în special cele din agricultura de subzistență și semi-subzistență",
    label:
      "Persoanele din mediul rural în special cele din agricultura de subzistență și semi-subzistență",
  },
  {
    value: "Şomeri şi persoane inactive - persoane cu dizabilităţi",
    label: "Şomeri şi persoane inactive - persoane cu dizabilităţi",
  },
  {
    value: "Persoane din mediul rural",
    label: "Persoane din mediul rural",
  },
  {
    value:
      "Persoane din mediul rural în special cele din agricultura de subzistență și semi-subzistență",
    label:
      "Persoane din mediul rural în special cele din agricultura de subzistență și semi-subzistență",
  },
];
