import { createSlice } from "@reduxjs/toolkit";
import { getPicture } from "store/thunks/fileThunks";

export const fileSlice = createSlice({
  name: "file",
  initialState: {
    files: [],
    loadingFiles: false,
  } as any,
  reducers: {
    resetFiles: (state) => {
      state.files = [];
    },
    addFile: (state, action) => {
      state.files.push(action.payload);
    },
    removeFile: (state, action) => {
      state.files = state.files.filter(
        (file: any) => file.FileTitle !== action.payload
      );
    },
    loadingFiles: (state, action) => {
      state.loadingFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPicture.pending,(state,action)=>{
      state.loadingFiles = true;
    })
    builder.addCase(getPicture.fulfilled,(state,action)=>{
      state.loadingFiles = false;
    })
    builder.addCase(getPicture.rejected,(state,action)=>{
      state.loadingFiles = false;
    })
  }
});

export const { addFile, removeFile, resetFiles, loadingFiles } =
  fileSlice.actions;
