import { createSlice } from "@reduxjs/toolkit";
import { BaseState } from "store/models";
import ro from "../../utils/localization/ro.json";

export interface AppState extends BaseState {
  localization: any;
}

export const appSlice = createSlice({
  name: "appSlice",
  initialState: {
    localization: ro,
  } as AppState,
  reducers: {},
});
