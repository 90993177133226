import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAppSelector, selectSharedLocalization } from "../../../store";
import "./ConfirmationDialog.scss";

interface ConfirmationDialogProps {
  display: boolean;
  confirmAction: any;
  cancelAction: any;
  message?: string;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  display,
  confirmAction,
  cancelAction,
  message,
}) => {
  const ro = useAppSelector(selectSharedLocalization);

  return (
    <Dialog maxWidth="xs" open={display}>
      <DialogTitle>{ro.confirmationDialogTitle}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="cancel-button"
          color="error"
          autoFocus
          onClick={cancelAction}
        >
          {ro.cancelBtn}
        </Button>
        <Button
          variant="contained"
          className="confirm-button"
          color="success"
          onClick={confirmAction}
        >
          {ro.confirmBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
