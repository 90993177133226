import { Download, NotInterested, PersonAdd } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Button,
  ButtonGroup,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import {
  selectCurrentUser,
  selectMediationPageLocalization,
  useAppDispatch,
  useAppSelector,
} from "store";

import { countiesSelectItems } from "store/models/selectItems";
import {
  selectApplicationsFilters,
  selectApplications,
  selectApplicationsLoading,
  selectApplicationsTotalCount,
} from "store/selectors/mediationSelectors";
import {
  allocateJobPost,
  changeApplicationsStatus,
  getPaginatedApplications,
} from "store/thunks/mediationThunks";
import { dateTimeFormatOptions } from "utils";
import { isAdmin, isCompany, isExpert } from "utils/getUserType";
import locale from "date-fns/locale/ro";
import DataLoadingComponent from "components/shared/dataLoadingComponent/DataLoadingComponent";
import {
  removeCompanyFromUnallocated,
  setApplicationsFilters,
} from "store/slices/mediationSlice";
import { applicationsSortByItems } from "store/models/selectItems/applicationsSortBy";
import { UserType } from "store/models/enums/UserType";
import "../ApplicationTab.scss";
import { GetFromFileShareModel } from "store/models/files";
import { downloadFile } from "store/thunks/fileThunks";
import ConfirmationDialog from "components/shared/confirmationDialog/ConfirmationDialog";
import { ApplicationStatus } from "store/models/enums/ApplicationStatus";
import { mapApplicationStatusToLabel } from "utils/mapApplicationStatusToLabel";
import { useWindowWidth } from "utils/useWindowWidth";

export const ApplicationsTable = () => {
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState<boolean>(false);
  const [applyDialog, setApplyDialog] = useState<boolean>(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState<number>();
  const [selectedApplicantInfo, setSelectedApplicantInfo] = useState({
    jobPostId: 0,
    companyUserId: 0,
  });
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [loadingDownloadFile, setLoadingDownloadFile] = useState<string>("");

  const ro = useAppSelector(selectMediationPageLocalization);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const filters = useAppSelector(selectApplicationsFilters);
  const applications = useAppSelector(selectApplications);
  const loading = useAppSelector(selectApplicationsLoading);
  const totalCount = useAppSelector(selectApplicationsTotalCount);
  const windowWidth = useWindowWidth();

  const setPaginatedApplications = () => {
    switch (currentUser?.userType) {
      case UserType.COMPANY_USER:
        return dispatch(
          setApplicationsFilters({
            ...filters,
            userId: currentUser?.userId,
          })
        );
      case UserType.EMPLOYER:
        return dispatch(
          setApplicationsFilters({
            ...filters,
            employerId: currentUser?.userId,
          })
        );
      case UserType.ADMIN:
        return dispatch(
          setApplicationsFilters({
            ...filters,
            employerId: 0,
          })
        );
      case UserType.EXPERT:
        return dispatch(
          setApplicationsFilters({
            ...filters,
            employerId: 0,
          })
        );
    }
  };

  useEffect(() => {
    setPaginatedApplications();

    //eslint-disable-next-line
  }, []);

  const getApplications = () => {
    return dispatch(
      getPaginatedApplications({
        filters: filters,
        token: currentUser?.jwtToken,
      })
    );
  };

  useEffect(() => {
    if (isMounted) {
      let promise = getApplications();
      return () => promise?.abort();
    }
    setIsMounted(true);

    // eslint-disable-next-line
  }, [filters]);

  const handleDownloadCV = (File: GetFromFileShareModel) => {
    dispatch(
      downloadFile({
        token: currentUser?.jwtToken,
        dto: {
          fileName: File.fileName,
          filePath: File.filePath,
          contentType: File.contentType,
        },
      })
    ).then(() => setLoadingDownloadFile(""));
  };

  const simpleUserColumns: GridColDef[] = [
    {
      field: "userName",
      headerName: ro.usernameCol,
      flex: 1,
      minWidth: 150,
      hide: isCompany(currentUser?.userType),
    },
    {
      field: "userEmail",
      headerName: ro.emailCol,
      flex: 1,
      minWidth: 150,
      hide: isCompany(currentUser?.userType),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: ro.titleCol,
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "employerName",
      headerName: ro.employerNameCol,
      flex: 1,
      minWidth: 150,

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <Typography variant="body2" className="tooltip-column">
              {params.value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: ro.status,
      flex: 1,
      minWidth: 100,
      hide: isCompany(currentUser?.userType),

      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip
            title={mapApplicationStatusToLabel(
              params.value as ApplicationStatus
            )}
          >
            <Typography variant="body2" className="tooltip-column">
              {mapApplicationStatusToLabel(params.value as ApplicationStatus)}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: "applicationDate",
      headerName: ro.applicationDateCol,
      flex: 1,
      minWidth: 105,
      hide: windowWidth < 1600,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
        }
      },
    },
    {
      field: "allocationDate",
      headerName: ro.allocationDateCol,
      flex: 1,
      minWidth: 105,

      hide: isCompany(currentUser?.userType) || windowWidth < 1600,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
        }
      },
    },
    {
      field: "allocatedByUsername",
      headerName: ro.allocatedByCol,
      flex: 1,
      minWidth: 150,
      hide: isCompany(currentUser?.userType),
    },
    {
      field: "jobLocality",
      headerName: ro.localityCol,
      flex: 1,
      minWidth: 150,
      hide: windowWidth < 1300,
    },
    {
      field: "jobCounty",
      headerName: ro.countyCol,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return (
          countiesSelectItems?.find((x) => x.value === params.value)?.label ??
          ""
        );
      },
    },

    {
      field: "cvUpdateDate",
      headerName: ro.cvUpdateDateCol,
      flex: 1,
      minWidth: 105,
      hide: windowWidth < 1600,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          let newDate = new Date(params.value as string);
          return newDate.toLocaleDateString("ro-RO", dateTimeFormatOptions);
        }
      },
    },

    {
      field: "",
      headerName: ro.optionsCol,
      sortable: false,
      flex: 1,
      minWidth: 150,
      align: "right",

      renderCell: (params: GridRenderCellParams) => {
        return (
          <ButtonGroup variant="contained" size="small">
            {(isExpert(currentUser?.userType) ||
              isAdmin(currentUser?.userType)) && (
              <Tooltip title={ro.allocateUser}>
                <span className="allocate-user-span">
                  <Button
                    className="allocate-user-button"
                    color="primary"
                    onClick={() => {
                      setApplyDialog(true);
                      setSelectedApplicantInfo({
                        jobPostId: params.row.jobId,
                        companyUserId: params.row.userId,
                      });
                    }}
                    disabled={
                      params.row.status !== ApplicationStatus.Interested
                    }
                  >
                    <PersonAdd />
                  </Button>
                </span>
              </Tooltip>
            )}

            <Tooltip title={ro.downloadCv}>
              <Button
                className="download-cv-button"
                color="secondary"
                onClick={() => {
                  setLoadingDownloadFile(params.row.userId);
                  handleDownloadCV({
                    fileName: params.row.cvName,
                    filePath: params.row.filePath,
                    contentType: params.row.contentType,
                  });
                }}
                disabled={loadingDownloadFile === params.row.userId}
              >
                <Download />
              </Button>
            </Tooltip>
            {isCompany(currentUser?.userType) && (
              <Tooltip title={ro.notInterestedTooltip}>
                <Button
                  color="warning"
                  onClick={() => {
                    setSelectedApplicationId(params.row.applicationId);
                    setDisplayConfirmationDialog(true);
                  }}
                >
                  <NotInterested />
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const closeApplyDialog = () => {
    setApplyDialog(false);
  };

  const confirmDialogAction = () => {
    dispatch(
      changeApplicationsStatus({
        dto: {
          applicationId: selectedApplicationId!,
          newStatus: ApplicationStatus.Uninterested,
        },
        token: currentUser?.jwtToken,
      })
    ).then(() => {
      getApplications();
      setDisplayConfirmationDialog(false);
      setSelectedApplicationId(undefined);
    });
  };

  const closeDeleteConfirmationDialog = () => {
    setDisplayConfirmationDialog(false);
    setSelectedApplicationId(undefined);
  };

  const handlePageChange = (page: number) => {
    dispatch(
      setApplicationsFilters({
        ...filters,
        pageNumber: page,
      })
    );
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length === 0) {
      dispatch(
        setApplicationsFilters({
          ...filters,
          sortBy: undefined,
          descending: false,
          pageNumber: 0,
        })
      );
    } else {
      dispatch(
        setApplicationsFilters({
          ...filters,
          sortBy: applicationsSortByItems?.find(
            (x) => x.field === newModel[0].field
          )?.value,
          descending: newModel[0].sort === "desc" ? true : false,
          pageNumber: 0,
        })
      );
    }
  };

  const confirmAllocate = () => {
    dispatch(
      allocateJobPost({
        model: {
          jobPostId: selectedApplicantInfo?.jobPostId!,
          companyUserId: selectedApplicantInfo?.companyUserId!,
          allocatedByUserId: currentUser?.userId,
        },
        token: currentUser?.jwtToken,
      })
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(
          removeCompanyFromUnallocated(selectedApplicantInfo?.companyUserId!)
        );
        getApplications();
      }

      setApplyDialog(false);
    });
  };

  return (
    <Container id="applications-table">
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DataGrid
          className="applications-data-grid"
          components={{
            LoadingOverlay: DataLoadingComponent,
          }}
          loading={loading}
          page={filters.pageNumber}
          pageSize={filters.pageSize}
          rowsPerPageOptions={[filters.pageSize!]}
          hideFooterSelectedRowCount={true}
          disableSelectionOnClick={true}
          paginationMode="server"
          localeText={{
            noRowsLabel: "Fără rezultate",
          }}
          sx={{
            "& .MuiDataGrid-row:hover": {
              background: "aliceblue",
            },
            height: 650,
            boxShadow: 3,
          }}
          rows={applications}
          columns={simpleUserColumns}
          onPageChange={handlePageChange}
          rowCount={totalCount}
          disableColumnMenu={true}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        ></DataGrid>
      </LocalizationProvider>
      {displayConfirmationDialog && (
        <ConfirmationDialog
          display={displayConfirmationDialog}
          message={ro.confirmUninterested}
          confirmAction={confirmDialogAction}
          cancelAction={closeDeleteConfirmationDialog}
        />
      )}
      <ConfirmationDialog
        display={applyDialog}
        cancelAction={closeApplyDialog}
        confirmAction={confirmAllocate}
        message="Confirmarea acțiunii va duce la alocarea beneficiarului la jobul selectat."
      />
    </Container>
  );
};
